import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import i18n from '../i18n';
import { Input } from './Input';

import ThemeContext from '../contexts/ThemeContext';

const StyledErrorMessage = styled.div`
  color: ${({ color }) => (color)};
  padding: 5px 0;
`;

const StyledInput = styled(Input)`
  background-color: #ffffff;
  margin-bottom: 15px;
`;

const StyledInputConfirm = styled(Input)`
  background-color: #ffffff;
`;

const initialErrors = {
  old: null,
  new: null,
};

const ResetUserPassword = ({ setPasswordToUpdate, setPasswordChanges, setPasswordError }) => {
  const themeContext = useContext(ThemeContext);

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [errors, setErrors] = useState(initialErrors);
  const colors = themeContext.colors;
  const newPasswordPattern = new RegExp(/^(?=.*\d)(?=.*\p{Ll})(?=.*\p{Lu})[\p{Ll}\p{Lu}0-9\W_]{8,}$/gu);

  const errorKeys = {
    old_password_is_required: 'old_password_is_required',
    password_requirement: 'password_requirement',
    repeat_new_password: 'repeat_new_password',
    passwords_do_not_match: 'passwords_do_not_match'
  };
  
  const checkPasswords = (oPassword, aPassword, aConfirmationPassword) => {
    const isValidPassword = newPasswordPattern.test(aPassword);
    if (!oPassword && !aPassword && !aConfirmationPassword) {
      setPasswordToUpdate('', '');
      setErrors(initialErrors);
      setPasswordChanges(false);
      return;
    }
    if (oPassword && isValidPassword && aPassword === aConfirmationPassword) {
      setPasswordToUpdate(oPassword, aPassword);
      setPasswordChanges(true);
      setErrors(initialErrors);
      return;
    }
    if (!oPassword) {
      setPasswordToUpdate('', '');
      if (!isValidPassword) {
        setErrors(prev => ({...prev, ['old']: errorKeys.old_password_is_required, ['new']: errorKeys.password_requirement }));
      } else if (isValidPassword && !aConfirmationPassword) {
        setErrors(prev => ({...prev, ['old']: errorKeys.old_password_is_required, ['new']: errorKeys.repeat_new_password }));
      } else if (isValidPassword && (aPassword !== aConfirmationPassword)) {
        setErrors(prev => ({...prev, ['old']: errorKeys.old_password_is_required, ['new']: errorKeys.passwords_do_not_match }));
      } else {
        setErrors(prev => ({...prev, ['old']: errorKeys.old_password_is_required, ['new']: null }));
      }
      setPasswordChanges(true);
      return;
    }
    if (oPassword) {
      setPasswordToUpdate('', '');
      if (!isValidPassword) {
        setErrors(prev => ({...prev, ['old']: null, ['new']: errorKeys.password_requirement }));
      } else if (isValidPassword && !aConfirmationPassword) {
        setErrors(prev => ({...prev, ['old']: null, ['new']: errorKeys.repeat_new_password }));
      } else if (isValidPassword && (aPassword !== aConfirmationPassword)) {
        setErrors(prev => ({...prev, ['old']: null, ['new']: errorKeys.passwords_do_not_match }));
      } else {
        setErrors(initialErrors);
      }
      setPasswordChanges(true);
      return;
    } 
  };

  useEffect(() => {
    checkPasswords(oldPassword, password, confirmationPassword);
  }, [oldPassword, password, confirmationPassword]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPasswordError(!Object.keys(errors).some(e => e));
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {errors.old ? (
        <StyledErrorMessage
          color={colors.error}
        >
          {i18n(errors.old)}
        </StyledErrorMessage>
      ) : null}
       <StyledInput
        type="password"
        placeholder={i18n('please_enter_current_password')}
        value={oldPassword}
        onChange={setOldPassword}
        onBlur={() => {
          checkPasswords(oldPassword, password, confirmationPassword);
        }}
        data-test='changeOldPasswordInput'
      />
      <StyledInput
        type="password"
        placeholder={i18n('please_enter_new_password')}
        value={password}
        onChange={setPassword}
        onBlur={() => {
          checkPasswords(oldPassword, password, confirmationPassword);
        }}
        data-test='changePasswordInput'
      />
      <StyledInputConfirm
        type="password"
        placeholder={i18n('please_repeat_new_password')}
        value={confirmationPassword}
        onChange={setConfirmationPassword}
        onBlur={() => {
          checkPasswords(oldPassword, password, confirmationPassword);
        }}
        data-test='confirmChangePasswordInput'
      />
      {errors.new ? (
        <StyledErrorMessage
          color={colors.error}
        >
          {i18n(errors.new)}
        </StyledErrorMessage>
      ) : null}
    </div>
  );
};

export default ResetUserPassword;
