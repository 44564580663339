import React, { useContext } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';

import ChatWizardHeader from './ChatWizardHeader';
import { Input } from './Input';
import ContentBox from './ContentBox';

import i18n from '../i18n';

import ThemeContext from '../contexts/ThemeContext';

const StyledInput = styled(Input)`
  margin-bottom: ${({ margin }) => (margin)}px;
`;

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LocationPage = ({
  active,
  city,
  onChangedCity,
  street,
  onChangedStreet,
  postcode,
  onChangedPostcode,
  message,
  onMessage
}) => {
  const themeContext = useContext(ThemeContext);

  const layout = themeContext.layout;

  return (
    <StyledContainer>
      <ChatWizardHeader
        title={i18n('location_question')}
        helpId='location'
      />
      <ContentBox
        centered={true}
      >
        <StyledInput
          data-test='locationPlaceholderCityInput'
          type="text"
          placeholder={i18n('location_placeholder_city')}
          value={city}
          onChange={onChangedCity}
          maxLength="250"
          margin={layout.padding * 2}
          active={active}
        />
        <StyledInput
          data-test='locationPlaceholderAddressInput'
          type="text"
          placeholder={i18n('location_placeholder_address')}
          value={street}
          onChange={onChangedStreet}
          maxLength="250"
          margin={layout.padding * 2}
        />
        <StyledInput
          data-test='locationPlaceholderPostcodeInput'
          type="text"
          placeholder={i18n('location_placeholder_postcode')}
          value={postcode}
          onChange={onChangedPostcode}
          maxLength="250"
          margin={layout.padding * 2}
        />
        <Input
          data-test='messageToInterpreterInput'
          title={i18n('interpretation_location_details')}
          required={true}
          inlineTitle={true}
          type="textarea"
          value={message}
          maxLength="1024"
          onChange={onMessage}
          onFocus={() => {
            amplitude.getInstance().logEvent('Edit prebooking message to interpreter');
          }}
        />
      </ContentBox>
    </StyledContainer>
  );
};

export default LocationPage;
