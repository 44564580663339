import { useState, useCallback, useEffect } from 'react';
import moment from 'moment';

export default function useChat({ room }) {
  const [ messages, setMessages ] = useState([]);
  const sendSignal = useCallback((data, type) => {
    console.log('[useSignal] - sendSignal');
    if (room) {
      room.signal({ type: type, data: data })
        .catch(e => e);
    }
  }, [room]);

  const signalListener = ({ data, isSentByMe, from }) => {
    const date = moment(new Date().getTime());
    setMessages([{ data, isSentByMe, from: from?.name, date }]);
  };

  useEffect(() => {
    if (room) {
      room.on('signal:text', signalListener);
    }
    return function cleanup() {
      if (room) {
        room.off('signal:text', signalListener);
      }
    };
  }, [room]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    sendSignal,
    messages
  };
}
