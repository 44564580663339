import { useEffect, useReducer } from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import { SELFREGISTRATION_ROUTES } from '../utils/redirectUtils';
import withHeader from '../hocs/withHeader';
import { FullBackgroundImageContainer } from '../components/basicComponents';
import background from '../images/background.png';
import { EmailVerificationPage, PhoneVerificationPage } from '../components/ContactInfoStep';
import { ConfirmEmailStep, ConfirmPhoneStep} from '../components/ConfirmEmailStep';
import SelectCostCenterStep from '../components/SelectCostCenterStep';
import SetPasswordStep from '../components/SetPasswordStep';
import FinalStep from '../components/FinalStep';

const StyledWrapper = styled.div`
  display: flex;
  height: 80%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 8px 16px 8px rgba(21, 21, 41, 0.25);
  max-width: 600px;
  min-width: 288px;
  & > div {
    flex: 1;
    display: flex;
    width: 100%;
    & > div {
      flex: 1;
      display: flex;
      justify-content: 'flex-start';
    }
  }
`;

const ImageContainer = styled(FullBackgroundImageContainer)`
  flex: 1;  
  padding: 21px;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const SelfRegistrationWizard = () => {
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0); // eslint-disable-line no-unused-vars
  useEffect(() => {
    const func = async () => {
      forceUpdate();
    };
    document.addEventListener('changeLang', func);
    return () => {
      document.removeEventListener('changeLang', func);
    };
  }, []);

  return (
      <ImageContainer
        blendMode='transparent'
        backgroundImage={background}
      >
        <StyledWrapper>
          <Switch>
            <Route exact path={SELFREGISTRATION_ROUTES.email} component={EmailVerificationPage}/>
            <Route exact path={SELFREGISTRATION_ROUTES.check_email} component={ConfirmEmailStep}/>
            <Route path={SELFREGISTRATION_ROUTES.phone} component={PhoneVerificationPage}/>
            <Route exact path={SELFREGISTRATION_ROUTES.check_phone} component={ConfirmPhoneStep}/>
            <Route path={SELFREGISTRATION_ROUTES.cost_center} component={SelectCostCenterStep}/>
            <Route path={SELFREGISTRATION_ROUTES.password} component={SetPasswordStep}/>
            <Route path={SELFREGISTRATION_ROUTES.auth_complete} component={FinalStep}/>
          </Switch>
        </StyledWrapper>
      </ImageContainer>
  );
};

export default withHeader(SelfRegistrationWizard);
