import {
  useState,
  useContext,
  Fragment,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import ThemeContext from '../contexts/ThemeContext';
import luona_logo from '../images/luona_logo.svg';
import { FullBackgroundImageContainer } from '../components/basicComponents';
import { LuonaButton } from '../components/Buttons';
import {
  LuonaInput,
  LuonaAgreementBlock,
  LuonaInputLabel,
  LuonaForm,
  LuonaFormTitle,
} from '../components/Input';
import styled from 'styled-components';
import i18n from '../i18n';
import luona_background from '../images/luona_back.jpg';
import {
  validateFieldName,
  validateFieldPhone,
  validateFieldEmail,
} from '../utils/validateFields';
import { getUserData, postUserData } from '../services/authLuona';
import { showError } from '../services/toastService';

const ImagePageContainer = styled(FullBackgroundImageContainer)`
  height: 100%;
  padding: 30px 0;
`;

const StyledButton = styled(LuonaButton)`
  width: 100%;
  margin-top: 12px;
`;
const StyledFormWrapper = styled.div`
  margin: auto;
  font-family: ${({ font }) => font};
`;

const Form = ({
  inputs,
  handleChange,
  agreeTerms,
  handleAgreeChange,
  handleSubmit,
  errors,
  disabled,
}) => {
  return (
    <LuonaForm handleSubmit={handleSubmit}>
      {inputs?.length &&
        inputs.map((el, idx) => {
          return (
            <Fragment key={idx}>
              <LuonaInputLabel
                data-test={`${el.name}LabelSignupLuona`}
                htmlFor={el.name}
                label={i18n(`label_${el.name}`)}
              />
              <LuonaInput
                data-test={`${el.name}InputSignupLuona`}
                name={el.name}
                type={el.type}
                title={i18n(`validate_${el.name}`)}
                value={el.value}
                placeholder={i18n(`placeholder_${el.name}`)}
                error={!!errors[el.name]}
                handleChange={handleChange}
              />
            </Fragment>
          );
        })}
      <LuonaAgreementBlock
        componentName='SignupLuona'
        agreeText={i18n('agreePrivacyPolicy')}
        linkName={i18n('privacyPolicy')}
        linkPath='https://tulka.com/terms-of-use-privacy-policy/'
        handleAgreeChange={handleAgreeChange}
        agreeTerms={agreeTerms}
      />
      <StyledButton data-test='luonaSignupButton' disabled={disabled} label={i18n('luonaSignupButton')} />
    </LuonaForm>
  );
};
const SignupLuona = () => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const locale = new URLSearchParams(search).get('locale');
  const [userData, setUserData] = useState({});
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [errors, setErrors] = useState({});
  const [successSubmit, setSuccessSubmit] = useState(false);
  const { colors } = useContext(ThemeContext);
  const getNameFromBot = (data) => {
    return data.name || '' || data.firstName || '' + data.lastName || '';
  };
  const getDataFromBot = useCallback((data) => {
    return {
      name: getNameFromBot(data),
      phone: data.phone || '',
      email: data.email || '',
    };
  }, []);
  useEffect(() => {
    getUserData(id)
      .then((data) => {
        if (data) {
          setUserData(getDataFromBot(data));
        }
      })
      .catch((err) => console.error(err));
  }, [id, getDataFromBot]);
  const validateFields = (target) => {
    switch (target.name) {
      case 'name':
      case 'city':
        updateErrors(validateFieldName, target);
        break;
      case 'phone':
        updateErrors(validateFieldPhone, target);
        break;
      case 'email':
        updateErrors(validateFieldEmail, target);
        break;
    }
  };
  const updateErrors = (validateHandler, input) => {
    if (validateHandler(input.value)) {
      setErrors((prev) => ({
        ...prev,
        [input.name]: '',
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [input.name]: `${input.name} field is not valid`,
      }));
    }
  };
  const handleChangeUserData = ({ target }) => {
    setUserData((prev) => ({ ...prev, [target.name]: target.value }));
    validateFields(target);
  };
  const isDisabled = useMemo(() => {
    const disableValue =
      !Object.values(errors).some(el => el) &&
      Object.values(userData).every(el => el) &&
      agreeTerms;
    return !disableValue;
  }, [errors, userData, agreeTerms]);
  const handleSubmitUserData = (e) => { // eslint-disable-line consistent-return
    e.preventDefault();
    if (agreeTerms) {
      postUserData({ ...userData, id, locale })
      .then(() => {
        setUserData({});
        setSuccessSubmit(true);
      })
      .catch((error) => {
        const match = error.message.match(/Error:\snot\sunique\susername.*/);
        showError(i18n(match ? 'requestErrorMessageUserExist' : 'requestErrorMessage'));
      });
      return userData;
    } 
  };
  const inputsData = [
    {
      type: 'text',
      name: 'name',
      value: userData.name || '',
    },
    {
      type: 'tel',
      name: 'phone',
      value: userData.phone || '',
    },
    {
      type: 'email',
      name: 'email',
      value: userData.email || '',
    },
  ];
  return (
    <ImagePageContainer
      blendMode='rgba(255, 255, 255, 0.75)'
      backgroundImage={luona_background}
    >
      <StyledFormWrapper font={colors.fontLuona}>
        <img src={luona_logo} alt='luona_logo' width='200' />
        {successSubmit ? (
          <LuonaButton
            handleClick={() =>
              (window.location.href = 'https://t.me/Tulkapreprod_bot')
            }
            label={i18n('back_to_telegram')}
            data-test='backToTelegramButton'
          />
        ) : (
          <>
            <LuonaFormTitle title={i18n('luonaSignupForm')} />
            <Form
              inputs={inputsData}
              handleChange={handleChangeUserData}
              agreeTerms={agreeTerms}
              handleAgreeChange={() => setAgreeTerms(!agreeTerms)}
              handleSubmit={handleSubmitUserData}
              errors={errors}
              disabled={isDisabled}
            />
          </>
        )}
      </StyledFormWrapper>
    </ImagePageContainer>
  );
};

export default SignupLuona;
