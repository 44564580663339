import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Modal from 'react-modal';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import withLeftSideBar from '../hocs/withLeftSideBar';
import IconButton from '../components/IconButton';
import SearchSelect from '../components/SearchSelect';
import Icon from '../components/Icon';
import HeaderText from '../components/HeaderText';
import Spinner from '../components/Spinner';
import InterpreterCalendar from '../components/InterpreterCalendar';
import {
  MainHeader,
  MainContentTopBar,
  ContainerWrapper,
} from '../components/basicComponents';

import {useParams} from 'react-router-dom';

import { showError } from '../services/toastService';
import {
  getPrebookings,
} from '../services/prebookService';
import {
  getBlockedInterpreterTime,
} from '../services/interpreterBlockTimeService';
import {
  getInterpreters,
} from '../services/interpretersService';

import i18n from '../i18n';

import {
  redirectToPrebookPreview
} from '../utils/redirectUtils';

Modal.setAppElement('#main');

const SearchBlock = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  & > button:not(:last-child) {
    margin-right: 20px;
  }
`;

const StyledHomeContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
`;

const StyledContainerWrapper = styled(ContainerWrapper)`
  padding: 15px;
`;

const SearchContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 8px;
  z-index: 100;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: 10px;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const StyledCalendarContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InterpretersCalendarView = ({ toggleSideBar, sidebarDocked, ...rest }) => {
  const { user, history } = useContext(AppContext);
  const { colors, layout } = useContext(ThemeContext);
  const {
    sessionExpired
  } = useContext(FunctionsContext);
  const { langId, interpreterId } = useParams();
  const [indicator, setIndicator] = useState(false);
  const [isInterpretersLoaded, setIsInterpretersLoaded] = useState(true);
  const [prebooks, setPrebooks] = useState([]);
  const [unavailability, setunavailability] = useState([]);

  const [languages, setLanguages] = useState([]);
  const [interpreters, setInterpreters] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedInterpreter, setSelectedInterpreter] = useState(null);

  const _isMounted = useRef(true);

  const getPreparedPrebookings = async (interpreterUserId, dateFrom, dateTo) => {
    try {
      setIndicator(true);
      const result = await Promise.all([
        getPrebookings(false, 'Interpreter', interpreterUserId, dateFrom, dateTo),
        getBlockedInterpreterTime(interpreterUserId),
      ]);
      if (_isMounted.current) {
        setPrebooks(result[0].prebookings || []);
        setunavailability(result[1]);
        setIndicator(false);
      }
    } catch (e) {
      console.log('Failed to get prebooking.', e);

      if (e.response !== undefined && e.response.status === 400) {
        amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
        if (_isMounted.current) {
          showError(i18n('error500'));
        }
      } else if (e.message === 'Session expired') {
        sessionExpired();
      } else {
        amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
        if (_isMounted.current) {
          showError(i18n('error500'));
        }
      }
      setIndicator(false);
    }
  };

  const getLanguages = async () => {
    try {
      return await getInterpreters(true);
    } catch (e) {
      console.log('Failed to get interpreters languages.', e);
      if (e.response !== undefined && e.response.status === 400) {
        showError(i18n('error500'));
      } else if (e.message === 'Session expired') {
        sessionExpired();
      } else {
        amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });

        showError(i18n('error500'));
      }
      return [];
    }
  };

  const getInterpretersList = async (languageId) => {
    try {
      return await getInterpreters(false, languageId);
    } catch (e) {
      console.log('Failed to get interpreters.', e);
      if (e.response !== undefined && e.response.status === 400) {
        showError(i18n('error500'));
      } else if (e.message === 'Session expired') {
        sessionExpired();
      } else {
        amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });

        showError(i18n('error500'));
      }
      return [];
    }
  };

  useEffect(() => {
    async function fetchLanguages() {
      const response = await getLanguages();
      const sorted = response.languages.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setLanguages(sorted || []);
      if (langId) {
        const lang = sorted.find(l=>l._id === langId);
        if (lang) {
          setSelectedLanguage(lang);
        }
      }
    }
    fetchLanguages();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchInterpreters() {
      setIsInterpretersLoaded(false);
      const response = await getInterpretersList(selectedLanguage?._id);
      if (interpreterId && !selectedInterpreter) {
        const interpreter = response.find(i=>i._id === interpreterId);
        if (interpreter) {
          setSelectedInterpreter(interpreter);
        }
      }
      setInterpreters(response || []);
      setIsInterpretersLoaded(true);
    }
    fetchInterpreters();
  }, [selectedLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedInterpreter?._id) {
      getPreparedPrebookings(selectedInterpreter?._id);
      history.replace(`/interpreters/language/${selectedLanguage?._id}/interpreter/${selectedInterpreter?._id}`);
    }
  }, [selectedInterpreter]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNavigation = (dateFrom, dateTo) => {
    getPreparedPrebookings(selectedInterpreter?._id, dateFrom, dateTo);
  };

  if (!user.id) {
    return null;
  }

  return (
    <StyledHomeContainer {...rest}>
      <MainContentTopBar
        borderBottomColor={colors.webDeviderColor}
      >
        {sidebarDocked ? null : (<IconButton
          icon={'menu'}
          iconLib={'material'}
          iconColor={colors.tulkaMainColor}
          onClick={toggleSideBar}
          data-tip="React-tooltip"
          data-event="click"
        />)}
        <MainHeader
          sidebarDocked={sidebarDocked}
          padding={layout.padding}
          color={colors.webDarkBlue}
        >
          {i18n('calendars')}
        </MainHeader>
      </MainContentTopBar>
      <StyledContainerWrapper>
        <SearchBlock>
          <SearchContainer>
            <SearchSelect
              placeholder={i18n('please_select_language')}
              selected={selectedLanguage && {value: selectedLanguage?._id, label: selectedLanguage?.name}}
              onChange={(obj) => setSelectedLanguage({
                _id: obj.value,
                name: obj.label
              })}
              selectableItems={languages.map((lang) => ({
                value: lang._id,
                label: lang.name
              }))}
              icon={(
                <StyledIcon
                  icon='language'
                  iconLib='material'
                  iconColor={colors.webDarkBlue}
                  iconSize={24}
                  hoverColor={colors.webDarkBlue}
                />
              )}
            />
          </SearchContainer>
          <SearchContainer>
            <SearchSelect
              placeholder={i18n('please_select_interpreter')}
              selected={selectedInterpreter && {value: selectedInterpreter?._id, label: selectedInterpreter?.name}}
              disabled={!selectedLanguage && isInterpretersLoaded}
              onChange={(obj) => setSelectedInterpreter({
                _id: obj.value,
                name: obj.label
              })}
              selectableItems={interpreters.map((interpreter) => ({
                value: interpreter._id,
                label: interpreter.name
              }))}
            />
          </SearchContainer>
        </SearchBlock>
        {selectedInterpreter ? (
          <StyledCalendarContainer>
            <InterpreterCalendar
              prebooks={prebooks}
              unavailability={unavailability}
              openPreview={redirectToPrebookPreview}
              interpreterId={selectedInterpreter?._id}
              handleNavigation={handleNavigation}
            />
            {indicator ? (
              <Spinner
                overlay={true}
              />
            ) : null}
          </StyledCalendarContainer>
        ) : (
          <StyledTextContainer>
            <HeaderText
              text={i18n('please_select_language_and_interpreter_to_see_the_calendar')}
              fontSize={layout.headerFontSize}
            />
          </StyledTextContainer>
        )}
      </StyledContainerWrapper>
    </StyledHomeContainer>
  );
};

export default withLeftSideBar(InterpretersCalendarView);
