import i18n from '../i18n';

export function getSkillsName(skills = []) {
  if (skills?.length > 0) {
    return skills.reduce((memo, skill) => {
      if (!memo) {
        return i18n(skill.tag);
      }
      return memo.concat(' ' + skill.tag);
    }, '');
  }
  return '';
}