import { useState, useContext, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import styled from 'styled-components';
import i18n, { getIsRightToLeft } from '../i18n';
import { LuonaWizardButton } from '../components/Buttons';
import { FullBackgroundImageContainer } from '../components/basicComponents';
import luona_background from '../images/luona_back.jpg';
import { postChatFeedback } from '../services/feedbackService';
import { isLuonaCustomer } from '../utils/luonaSpecificData';

const FeedbackPage = styled(FullBackgroundImageContainer)`
  padding: 20px;
  height: auto;
  min-height: 100vh;
`;

const FeedbackContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 30px;
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 10px auto auto;
`;

const FeedbackTitle = styled.h2`
  color: ${({ color }) => color};
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: normal;
`;

const FeedbackDescription = styled.p`
  margin: 0;
  ${({rtl}) => rtl && 'text-align: right;'}
`;

const FeedbackForm = styled.form`
  display: flex;
  flex-flow: column;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
`;

const ScoresContainer = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 10px 0;
  margin: 0;

  .score.selected {
    color: #fff;
    background-color: ${({ color }) => color};
  }

  ${({rtl}) => rtl && 'flex-flow: row-reverse;'}
`;

const ScoreItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px; 
  border: ${({ color }) => '2px solid ' + color};
  border-radius: 50%;
  color: ${({ color }) => color};
  background-color: transparent;
  cursor: pointer;
`;

const RequiredSign = styled.span`
  color: ${({ color }) => color};
`;

const LabelText = styled.span`
  ${({rtl}) => rtl && 'text-align: right;'}
`;

const FeedbackFormLabel = styled.label`
  display: flex;
  color: black;
  position: static;
  margin: 20px 0;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  height: auto;
  ${({rtl}) => rtl && 'flex-flow: row-reverse;'}
`;

const FeedbackText = styled.textarea`
  resize: none;
  border-radius: 6px;
  background-color: transparent;
  border: ${({ colors }) => '1px solid ' + colors.luonaBorderColor};
  box-shadow: ${({colors}) => colors.shadowColor + '0px 8px 24px'};
  padding: 10px;
  height: 150px;
  ${({rtl}) => rtl && 'text-align: right;'}
`;

const SubmitButton = styled(LuonaWizardButton)`
  background-color: ${({ background }) => background};
  color: #fff;
  border-radius: 16px;
  width: 50%;
  margin: 30px auto 0;
`;

const FeedbackScore = ({ setScoresClicked }) => {
  const { colors } = useContext(ThemeContext);
  const [onFocused, setOnFocused] = useState();
  const scoresValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <>
      <FeedbackFormLabel rtl={getIsRightToLeft()}>
        <LabelText rtl={getIsRightToLeft()}>{i18n('luona_feedback_score')}</LabelText>
        <RequiredSign color={colors.luonaMainBrightColor}>*</RequiredSign>
      </FeedbackFormLabel>
      <ScoresContainer color={colors.luonaMainBrightColor} rtl={getIsRightToLeft()}>
        {scoresValues.map(index => {
          return (<ScoreItem 
              key={'star_' + Math.random() + '#' + index}
              className={'score ' + (index <= onFocused ? 'selected' : '')} 
              color={colors.luonaMainBrightColor}
              onMouseMove={() => setOnFocused(index)}
              onClick={() => setScoresClicked(index)}
              onTouchStart={() => setScoresClicked(index)}
          >{index}</ScoreItem>);
        })}
      </ScoresContainer>
    </>
  );
};

const FeedbackMessage = ({ value, handleChange }) => {
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <FeedbackFormLabel rtl={getIsRightToLeft()}>
        <LabelText rtl={getIsRightToLeft()}>{i18n('luona_feedback')}</LabelText>
        <RequiredSign color={colors.luonaMainBrightColor}>*</RequiredSign>
      </FeedbackFormLabel>
      <FeedbackText 
        placeholder='...'
        value={value}
        onChange={({target}) => handleChange(target.value)}
        colors={colors}
        rtl={getIsRightToLeft()}
      ></FeedbackText>
    </>
  );
};

const initialfeedback = {
  scores: null,
  comment: ''
};

const LuonaFeedback = () => {
  const { user } = useContext(AppContext);
  const { colors } = useContext(ThemeContext);
  const location = useLocation();
  const history = useHistory();
  const [ feedback, setFeedback ] = useState(initialfeedback);
  const [ isFeedbackSent, setIsFeedbackSent ] = useState(false);
  const [ roomId ] = useState(location?.state?.roomId || null);

  const delay = 1000;

  const sendFeedback = async() => {
    try {
      if (roomId) {
        await postChatFeedback({
          stars_general: 0,
          stars_technical: 0, // ?? techStars
          stars_user: feedback.scores,
          feedback: feedback.comment,
          contact_wanted: false,
          own_notes: user.userGroup.id, // ?? invoiceRef
          user_group_id: user.userGroup.id, // user.userGroup.id // this.state.invoiceRefUserGroupId
          favorite: 'no' // ??
        }, roomId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmitFeedback = (e) => {
    e.preventDefault();
    sendFeedback();
    setIsFeedbackSent(true);
  };
  
  const redirectToWizard = useCallback(() => {
    setFeedback(initialfeedback);
    if (isLuonaCustomer()) {
      history.replace('/#!/wizard');
    }
    history.replace('/#!/wizard');
  }, [history]);
  
  useEffect(() => {
    if (isFeedbackSent) {
      setTimeout(redirectToWizard, delay);
    }
    return () => clearTimeout(redirectToWizard, delay);
  }, [isFeedbackSent, redirectToWizard]);

  return (
    <FeedbackPage 
      blendMode='rgba(255, 255, 255, 0.75)'
      backgroundImage={luona_background}  
    >
      <FeedbackContainer>
        {isFeedbackSent ? (<p>{i18n('feedback_sent')}</p>) : (
        <>
          <FeedbackTitle color={colors.luonaMainBrightColor}>{i18n('luona_feedback_title')}</FeedbackTitle>
          <FeedbackDescription rtl={getIsRightToLeft()}>{i18n('luona_feedback_description')}</FeedbackDescription>
          <FeedbackForm>
            {feedback.scores === null ? (
              <FeedbackScore
              setScoresClicked={(idx) => setFeedback(prev => ({...prev, scores: idx < 1 ? 1 : idx}))}
              />) : (<>
              <FeedbackMessage 
                value={feedback.comment} 
                handleChange={(text) => setFeedback(prev => ({...prev, comment: text}))}
                />
              <SubmitButton 
                data-test='submitLuonaFeedbackBtn'
                borderColor={colors.luonaMainBrightColor}
                onClick={handleSubmitFeedback}
                >{i18n('luonaSignupButton')}</SubmitButton>
            </>)}
          </FeedbackForm>
        </>)}
      </FeedbackContainer>
    </FeedbackPage>
  );
};

export default LuonaFeedback;