import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ContentBox from './ContentBox';
import ActionList from './ActionList';
import ActionListItem from './ActionListItem';
import ChatWizardHeader from './ChatWizardHeader';
import { Input } from './Input';
import i18n from '../i18n';

import ThemeContext from '../contexts/ThemeContext';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const StyledInput = styled(Input)`
  margin-bottom: ${({ margin }) => (margin)}px;
`;

const LanguageListPage = props => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const [languages, setLanguages] = useState(props.selectableLanguages);
  const [searchKey, setSearchKey] = useState('');

  const onChange = (text) => {
    setSearchKey(text.toLowerCase());
  };

  useEffect(() => {
    setLanguages(props.selectableLanguages.filter(l => {
      if (searchKey.length > 2) {
        return l.name.toLowerCase().includes(searchKey) || l.nativeName.toLowerCase().includes(searchKey);
      }
      return l.name.toLowerCase().startsWith(searchKey) || l.nativeName.toLowerCase().startsWith(searchKey);
    }));
    if (searchKey) {
      onChange(searchKey);
    }
  }, [searchKey, props.selectableLanguages]);

  const languageItems = props.selectableLanguages.length > 0 ?
    languages.map(lang => {
      let rightIcon = null;

      // if (lang.availability === 1) {
      //   rightIcon = 'dot-single';
      // } else if (lang.availability === 2) {
      //   rightIcon = 'dots-two-horizontal';
      // } else if (lang.availability === 3) {
      //   rightIcon = 'dots-three-horizontal';
      // }

      return (
        <ActionListItem
          data-test={lang.name + 'LangChatWizard'}
          id={lang.id}
          key={lang.id}
          title={lang.name}
          subtitle={lang.nativeName || ' '}
          rightIcon={rightIcon}
          rightIconLib='entypo'
          rightIconColor={lang.availability === 1 ? colors.cancelColor : colors.contentBoxBorderColorLight2020}
          onClick={() => {
            props.onLanguageSelected(lang);
          }}
          selected={props.selectedLanguageId === lang.id}
        />
      );
    }) : (
        <ActionListItem
          title={i18n(props.error ? 'failed_to_fetch_languages' : (props.languagesFetched ? 'no_languages' : 'fetching_languages'))}
          subtitle={i18n(props.error ? 'please_try_again_later' : (props.languagesFetched ? 'please_try_again_later' : 'pleaseWait'))}
          onClick={() => {
            props.onLanguageSelected();
          }}
        />
    );

  return (
    <StyledContainer>
      <ChatWizardHeader
        title={props.title}
      />
      <ContentBox>
        {props.selectableLanguages.length > 5 ? (
          <StyledInput
            data-test='searchLangChatWizard'
            margin={layout.padding}
            type="text"
            placeholder={i18n('search')}
            value={searchKey}
            onChange={onChange}
          />
        ) : null}
        <ActionList>
          {languageItems}
        </ActionList>
      </ContentBox>
    </StyledContainer>
  );
};

LanguageListPage.propTypes = {
  title: PropTypes.string,
  onLanguageSelected: PropTypes.func,
  selectedLanguageId: PropTypes.string,
  selectableLanguages: PropTypes.array
};

export default LanguageListPage;
