import { useState, useContext, useEffect, useCallback, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import i18n from '../i18n';
import { SELFREGISTRATION_ROUTES, getViewState } from '../utils/redirectUtils';

import ThemeContext from '../contexts/ThemeContext';

import { signup, verify } from '../services/authService';
import { showError } from '../services/toastService';
import { logAnalytics } from '../services/analyticsLogger';
import {
  isTextFieldValid,
  validateFieldPhone,
  validateFieldEmail,
  phoneNumberMaxLength
} from '../utils/validateFields';
import { getUserFromLocalstorage, getData } from '../utils/localStorage';
import { RoundButton, CollapsedTextButton } from './Buttons';
import { StepInput } from './Input';
import Spinner from '../components/Spinner';
import Icon from '../components/Icon';
import NotUnicEmailInfoStep from '../components/NotUnicEmailInfoStep';
import InvalidDomainInfoStep from '../components/InvalidDomainInfoStep';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import {
  ContainerStepBox,
  ContentStepBox,
  StepsHeader,
  StepLable,
  FlexRow,
  FlexItem,
  StyledErrorMessage
} from './basicComponents';

import { customerServicePhone, customerServicePhoneLink } from '../utils/customerService';

const InfoBlock = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: #151529;
  margin-top: 8px;
  a {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StyledFlexItem = styled(FlexItem)`
  display: flex;
  margin-top: 16px;
  input {
    margin: 0;
    margin-right: 8px;
  }
  p {
    margin: 0;
    font-size: 12px;
  }
  @media (max-width: 768px) {
    margin-top: 8px;
  }
`;


const validatorMap = {
  fistName: isTextFieldValid,
  lastName: isTextFieldValid,
  email: validateFieldEmail,
  phone: validateFieldPhone
};

const delay = 1000;

export const RelationBlock = ({ type, isPersonal, setRelation }) => {
  return (
    <>
      <StepLable>{i18n(`is_personal_${type}`)}</StepLable>
      <StyledFlexItem>
        <input
          type='radio'
          data-test='contactInfoStepEmailIsPersonal'
          value='personal'
          checked={isPersonal}
          onChange={(e) => setRelation(e.target.checked, e.target.value)}
        />
        <p>{i18n(`${type}_for_personal`)}</p>
      </StyledFlexItem>
      <StyledFlexItem>
        <input
          type='radio'
          data-test='contactInfoStepEmailIsCorporate'
          value='corporate'
          checked={!isPersonal}
          onChange={(e) => setRelation(e.target.checked, e.target.value)}
        />
        <p>{i18n(`${type}_shared_with_colleagues`)}</p>
      </StyledFlexItem>
    </>
  );
};

export const EmailVerificationPage = () => {
  const { colors } = useContext(ThemeContext);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const [contactInfo, setContactInfo] = useState({
    fistName: '',
    lastName: '',
    email: '',
    isPersonalEmail: true
  });

  const [errors, setErrors] = useState({
    fistName: null,
    lastName: null,
    email: null
  });

  const [viewState, setViewState] = useState('contactInfo');

  useEffect(() => {
    if (isEmailVerified) {
      setTimeout(redirectToNextAuthStep, delay);
    }

    return () => clearTimeout(redirectToNextAuthStep, delay);
  }, [isEmailVerified, redirectToNextAuthStep]);

  const redirectToNextAuthStep = useCallback(() => {
    if (isEmailVerified) {
      history.replace(SELFREGISTRATION_ROUTES.check_email);
    }
  }, [history, isEmailVerified]);

  const updateContactInfo = (name, value) => {
    if (typeof value === 'string') {
      setContactInfo(prev => {
        return { ...prev, [name]: value };
      });
    }
    if (name === 'isPersonalEmail') {
      setContactInfo(prev => {
        return { ...prev, isPersonalEmail: value };
      });
    }
  };

  const checkData = (key) => {
    if (key) {
      if (!isTextFieldValid(contactInfo.fistName)) {
        setErrors(prev => ({...prev, [key]: `${key}_requirement`}));
      } else {
        setErrors(prev => ({...prev, [key]: null}));
      }
    }
    return;
  };

  const isValidData = () => {
    return Object.keys(contactInfo).reduce((memo, key) => { // eslint-disable-line consistent-return
      if (memo && key === 'isPersonalEmail') return true;
      if (memo && validatorMap[key]) {
        return validatorMap[key](getFixedValue(contactInfo, key));
      }
    }, true);
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const getFixedPhoneValue = (phoneValue) => {
    return phoneValue
      .replaceAll(' ', '')
      .replaceAll('_', '');
  };

  const getFixedValue = (data, field) =>
    field === 'phone' ? getFixedPhoneValue(data[field]) : data[field];

  const handleNext = async () => {
    setLoading(true);
    try {
      const body = {
        name: `${contactInfo.fistName} ${contactInfo.lastName}`,
        email: contactInfo.email,
        isPersonal: contactInfo.isPersonalEmail
      };
      const token = await executeRecaptcha('register');
      await signup(body);
      setIsEmailVerified(true);
      setLoading(false);
      logAnalytics('Self-registration signup success');
      localStorage.setItem('user', JSON.stringify({...body, recaptchaToken: token}));
    } catch (err) {
      console.log('ERROR:', err);
      logAnalytics('Self-registration signup error', {
        error: err.message
      });
      const stateName = getViewState(err.message);
      if (stateName) {
        logAnalytics(`Self-registration ${stateName} substep`, {
          name: `${contactInfo.fistName} ${contactInfo.lastName}`,
          email: contactInfo.email
        });
        setViewState(stateName);
      } else {
        if (err.message.includes('Contact info not unique')) {
          showError('Contact info not unique. Please try again.');
        } else {
          showError(i18n('error500'));
        }
      }
      setLoading(false);
    }
  };

  const setEmailRelation = (checked, value) => {
    if (checked) {
      if (value === 'personal') {
        updateContactInfo('isPersonalEmail', true);
      }
      if (value === 'corporate') {
        updateContactInfo('isPersonalEmail', false);
      }
    }
  };

  return (
    <>
      {(() => {
        switch (viewState) {
          case 'contactInfo':
            return (
              <ContainerStepBox>
                {loading && (<Spinner overlay={true} />)}
                <ContentStepBox>
                  <FlexRow>
                    <StepsHeader>{i18n('contact_information')}</StepsHeader>
                  </FlexRow>
                  <FlexRow>
                    <FlexItem>
                      <StepLable
                        data-test='contactInfoStepFirstNameLabel'
                      >
                        {i18n('first_name')}
                        <span> *</span>
                      </StepLable>
                      <StepInput
                        type="text"
                        value={contactInfo.fistName}
                        onChange={(value) => updateContactInfo('fistName', value)}
                        onBlur={() => checkData('fistName')}
                        data-test='contactInfoStepFirstNameInput'
                      />
                      {errors.fistName ? (
                        <StyledErrorMessage
                          color={colors.error}
                        >
                          {i18n(errors.fistName)}
                        </StyledErrorMessage>
                      ) : null}
                    </FlexItem>
                    <FlexItem>
                      <StepLable
                        data-test='contactInfoStepFirstLastLabel'
                      >
                        {i18n('last_name')}
                        <span> *</span>
                      </StepLable>
                      <StepInput
                        type="text"
                        value={contactInfo.lastName}
                        onChange={(value) => updateContactInfo('lastName', value)}
                        onBlur={() => checkData('lastName')}
                        data-test='contactInfoStepLastNameInput'
                      />
                      {errors.lastName ? (
                        <StyledErrorMessage
                          color={colors.error}
                        >
                          {i18n(errors.lastName)}
                        </StyledErrorMessage>
                      ) : null}
                    </FlexItem>
                  </FlexRow>
                  <FlexRow>
                    <FlexItem>
                      <StepLable
                        data-test='contactInfoStepEmailLabel'
                      >
                        {i18n('contact_info_email')}
                        <span> *</span>
                      </StepLable>
                      <StepInput
                        type="text"
                        placeholder={'xxxxx.xxxxxxxx@xxxxx.xx'}
                        value={contactInfo.email}
                        onChange={(value) => updateContactInfo('email', value)}
                        onBlur={() => checkData('email')}
                        data-test='contactInfoStepEmailInput'
                      />
                        {errors.email ? (
                        <StyledErrorMessage
                          color={colors.error}
                        >
                          {i18n(errors.email)}
                        </StyledErrorMessage>
                      ) : null}      
                    </FlexItem>
                  </FlexRow>
                  <FlexRow>
                    <FlexItem>
                      <RelationBlock 
                        type='email'
                        isPersonal={contactInfo.isPersonalEmail} 
                        setRelation={setEmailRelation}
                      />
                    </FlexItem>
                  </FlexRow>
                </ContentStepBox>
                <RoundButton
                  data-test='selfRegistrationNextBtn'
                  onClick={handleNext}
                  padding='25'
                  darkButton={true}
                  disabled={!isValidData()}
                >
                  <CollapsedTextButton>
                    <span>{i18n('continue')}</span>
                    <Icon
                      icon='chevron_right'
                      iconLib='material'
                      iconSize={30}
                      withoutContainer
                    />
                  </CollapsedTextButton>
                </RoundButton>
                <InfoBlock>
                  {i18n('already_have_an_account_part1')}
                  <a href='/login'>{i18n('already_have_an_account_part2')}</a>
                </InfoBlock>
              </ContainerStepBox>
            );
          case 'notUnicEmail':
            return <NotUnicEmailInfoStep />;
          case 'invalidDomain':
            return <InvalidDomainInfoStep />;
          default:
            return null;
        }
      })()}
    </>
  );
};

export const PhoneVerificationPage = () => {
  const { colors } = useContext(ThemeContext);
  const history = useHistory();
  const [user] = useState(getUserFromLocalstorage());

  const [loading, setLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    phone: '+358',
    isPersonalPhone: true
  });
  const [errors, setErrors] = useState({
    phone: null,
    notUniquePhone: false
  });

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  useEffect(() => {
    if (isPhoneVerified) {
      setTimeout(redirectToNextAuthStep, delay);
    }
    return () => clearTimeout(redirectToNextAuthStep, delay);
  }, [isPhoneVerified, redirectToNextAuthStep]);

  const redirectToNextAuthStep = useCallback(async () => {
    const userFromLocaleStorage = getUserFromLocalstorage();
    if (isPhoneVerified) {
      if (userFromLocaleStorage?.token) {
        history.replace(SELFREGISTRATION_ROUTES.cost_center);
      } else {
        history.replace(SELFREGISTRATION_ROUTES.check_phone);
      }
    }
  }, [history, isPhoneVerified]);

  const getFixedPhoneValue = (phoneValue) => {
    return phoneValue
      .replaceAll(' ', '')
      .replaceAll('_', '');
  };

  const getFixedValue = (data, field) =>
    field === 'phone' ? getFixedPhoneValue(data[field]) : data[field];

  const isValidData = () => {
    return Object.keys(contactInfo).reduce((memo, key) => { // eslint-disable-line consistent-return
      if (memo && key === 'isPersonalPhone') return true;
      if (memo && validatorMap[key]) {
        return validatorMap[key](getFixedValue(contactInfo, key));
      }
    }, true);
  };

  const checkData = () => {
    if (!validateFieldPhone(getFixedPhoneValue(contactInfo.phone))) {
      setErrors(prev => ({...prev, ['phone']: 'invalid_phone_number_error'}));
    } else {
      setErrors(prev => ({ ...prev, ['phone']: null }));
    }
  };

  const updateContactInfo = (name, value) => {
    setErrors(prev => ({ ...prev, ['notUniquePhone']: false }));
    if (typeof value === 'string') {
      const cleanedValue = typeof value === 'string' ? value.replace(/\D/g, '') : '';
      setContactInfo(prev => {
        return { ...prev, [name]: value };
      });
      if (name === 'phone' && cleanedValue[3] === '0') {
        setErrors(prev => ({...prev, [name]: 'phone_number_cannot_start_with_0'}));
      } else {
        setErrors(prev => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === 'isPersonalPhone') {
      setContactInfo(prev => {
        return { ...prev, isPersonalPhone: value };
      });
    }
  };

  const beforeMaskedStateChange = (newState, oldState) => {
    const symbolIndxMustBeNonZero = 5;
    const oldValue = oldState.value;
    let { value } = newState;
    let selection = newState.selection;
    let cursorPosition = selection ? selection.start : null;
    if (value[symbolIndxMustBeNonZero] === '0') {
      if (cursorPosition === symbolIndxMustBeNonZero + 1) {
        cursorPosition = cursorPosition - 1;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = oldValue;
    }
    return { value, selection };
  };

  const handleNext = async () => {
    setLoading(true);
    try {
      const userFromLocaleStorage = getUserFromLocalstorage();
      const body = {
        ...userFromLocaleStorage,
        phone: getFixedPhoneValue(contactInfo.phone),
        isPersonal: contactInfo.isPersonalPhone
      };
      const data = await signup(body);
      setIsPhoneVerified(true);
      setLoading(false);
      logAnalytics('Self-registration signup success');
      localStorage.setItem('user', JSON.stringify({...data}));
    } catch (err) {
      logAnalytics('Self-registration signup error', {
        error: err.message
      });
      'Contact info not unique';
      if (err.message.includes('Contact info not unique type: Interpreter')) {
        setErrors(prev => ({...prev, ['notUniquePhone']: 'already_registered_phone'}));
        const stateName = getViewState(err.message);
        if (stateName) {
          logAnalytics(`Self-registration ${stateName} substep`, {
            name: `${user.fistName} ${user.lastName}`,
            email: user.email,
            phone: user.phone
          });
        }
      } else {
        showError(i18n('error500'));
      }
      setLoading(false);
    }
  };

  const setPhoneRelation = (checked, value) => {
    if (checked) {
      if (value === 'personal') {
        updateContactInfo('isPersonalPhone', true);
      }
      if (value === 'corporate') {
        updateContactInfo('isPersonalPhone', false);
      }
    }
  };

  return (
    <ContainerStepBox>
      {loading && (<Spinner overlay={true} />)}
      <ContentStepBox>
        <FlexRow>
            <StepsHeader>{i18n('contact_information')}</StepsHeader>
        </FlexRow>
        <FlexRow>
          <FlexItem>
            <StepLable
              data-test='contactInfoStepPhoneLabel'
            >
              {i18n('contact_info_phone')}
              <span> *</span>
            </StepLable>
            <InputMask
              mask="+358 99 999 99999"
              value={contactInfo.phone}
              beforeMaskedValueChange={beforeMaskedStateChange}
              onChange={(value) => {
                  const cleanedValue = typeof value === 'string' ? value.replace(/\D/g, '') : '';
                  if (cleanedValue.length <= phoneNumberMaxLength) {
                    updateContactInfo('phone', value);
                  }
                }}
              onBlur={() => checkData()}
              placeholder='+xxx xx xxx xxxx'>
              {(inputProps) => <StepInput
                      {...inputProps}
                       type="tel"
                       data-test='contactInfoStepPhoneInput'
                />}
              </InputMask>
                {errors.phone ? (
                  <StyledErrorMessage
                    color={colors.error}
                  >
                    {i18n(errors.phone)}
                  </StyledErrorMessage>
                ) : null}
            </FlexItem>
          </FlexRow>
          <FlexRow>
            <FlexItem>
              <RelationBlock 
                type='phone'
                isPersonal={contactInfo.isPersonalPhone} 
                setRelation={setPhoneRelation}
              />
            </FlexItem>
          </FlexRow>
          <FlexRow>
            {errors.notUniquePhone && (
              <StyledErrorMessage color={colors.error}>
                {i18n(errors.notUniquePhone)}
                <a href={customerServicePhoneLink}>{customerServicePhone}</a>
              </StyledErrorMessage>
            )}
          </FlexRow>
      </ContentStepBox>
      <RoundButton
        data-test='selfRegistrationNextBtn'
        onClick={handleNext}
        padding='25'
        darkButton={true}
        disabled={!isValidData()}
      >
        <CollapsedTextButton>
          <span>{i18n('continue')}</span>
          <Icon
            icon='chevron_right'
            iconLib='material'
            iconSize={30}
            withoutContainer
          />
        </CollapsedTextButton>
      </RoundButton>
      <InfoBlock>
        {i18n('already_have_an_account_part1')}
        <a href='/login'>{i18n('already_have_an_account_part2')}</a>
      </InfoBlock>
    </ContainerStepBox>
  );
};

export const VerificationPage = () => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const [code] = useState(getData('code'));
  const history = useHistory();
  const [isVerificationFailed, setIsVerificationFailed] = useState(false);
  const [messageKey, setMessageKey] = useState('waiting_for_verification');
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0); // eslint-disable-line no-unused-vars

  useEffect(() => {
    const func = async () => {
      forceUpdate();
    };
    document.addEventListener('changeLang', func);
    return () => {
      document.removeEventListener('changeLang', func);
    };
  }, []);

  const redirectToNextAuthStep = useCallback(async (data) => {
    const user = getUserFromLocalstorage();
    let url = '/#!';
    if (data?.verificationStatus === 'pending') {
      url = url.concat(SELFREGISTRATION_ROUTES.phone);
    } else if (user?.token) {
      url = url.concat(SELFREGISTRATION_ROUTES.cost_center);
    } else {
      url = url.concat(SELFREGISTRATION_ROUTES.email);
    }
    history.replace(url);
  }, [history]);

  const getVerification = useCallback(async (codeOrToken) => {
    try {
      const data = await verify(codeOrToken);
      localStorage.setItem('user', JSON.stringify({...data}));
      await redirectToNextAuthStep(data);
    } catch (err) {
      if (err.message.includes('Invalid verification token')) {
        setMessageKey('verification_link_expired');
        setIsVerificationFailed(true);
      } else {
        setMessageKey('error500');
      }
    }
  }, [redirectToNextAuthStep]);

  useEffect(() => {
    if (token) {
      getVerification(token);
    } else if (code) {
      getVerification(code);
      localStorage.removeItem('code');
    }

  }, [token, code, getVerification]);

  const handleBack = () => {
    history.replace(SELFREGISTRATION_ROUTES.email);
  };

  return (
    <ContainerStepBox>
      <ContentStepBox>
        <FlexRow>
            <StepsHeader>{i18n('verification_page')}</StepsHeader>
        </FlexRow>
        <FlexRow>
          <FlexItem>{i18n(messageKey)}</FlexItem>
        </FlexRow>
      </ContentStepBox>
      {isVerificationFailed && (
        <>
          <RoundButton
            data-test='selfRegistrationNextBtn'
            onClick={handleBack}
            padding='25'
            darkButton={true}
          >
            <CollapsedTextButton>
              <span>{i18n('back')}</span>
              <Icon
                icon='chevron_right'
                iconLib='material'
                iconSize={30}
                withoutContainer
              />
            </CollapsedTextButton>
          </RoundButton>
          <InfoBlock>
            {i18n('already_have_an_account_part1')}
            <a href='/login'>{i18n('already_have_an_account_part2')}</a>
          </InfoBlock>
        </>
      )}

    </ContainerStepBox>
  );
};
