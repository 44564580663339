import React from 'react';
import styled from 'styled-components';
import {showInfo, showError, showSuccess, showWarning, showQuestion, showHtmlQuestion} from '../utils/msgbox'; //eslint-disable-line no-unused-vars
import i18n from '../i18n';
import DeviceSelector from '../components/deviceSelector';
import Header from '../components/header';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import reactMixin from 'react-mixin';
import TimerMixin from 'react-timer-mixin';
import { format } from '../utils/timeUtils';
import amplitude from 'amplitude-js';

import AppContext from '../contexts/AppContext';
import FunctionsContext from '../contexts/FunctionsContext';

import {
  CenteredBlockContainer,
  DialogBlock,
} from '../components/basicComponents';

import {
  getUser,
} from '../services/userService';
import {
  getLanguageList,
} from '../services/languageService';
import {
  createChatRoom,
  endChatRoom,
} from '../services/chatRoomService';
import {
  updateFeatureSupport,
} from '../services/featureSupportService';
import {
  getEulaUrl,
} from '../services/eulaService';

const StyledCenteredBlockContainer = styled(CenteredBlockContainer)`
  height: 100%;
  min-height: 100%;
  background-color: #46446F;
  overflow: hidden;
  overflow-y: auto;
`;

const StyledDialogBlock = styled(DialogBlock)`
  box-shadow: none;
`;

class Customer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      languages: [{
        id: null,
        name: i18n('pleaseWait')
      }],
      targetLanguages: [],
      skills: {},
      selectedTargetLanguage: '',
      selectedLanguage: '',

      secondsLeft: 0,
      invoicing: true,
      buttonDisabled: true,
      showSettings: false,
      systemAlert: false
    };

    this.activationTime = Date.now();
    this.ivLangList = null;
  }

  async componentDidMount() {
    this.endChat();
    this.appendLanguages();
    this.ivLangList = this.setInterval(async () => await this.appendLanguages(), 30 * 1000);

    amplitude.getInstance().logEvent('Voice call not supported');
    amplitude.getInstance().logEvent('Request interpreter activated');

    window.onfocus = () => {
      this.activationTime = Date.now();
      amplitude.getInstance().logEvent('Request interpreter activated');
    };

    window.onblur = () => {
      amplitude.getInstance().logEvent('Request interpreter deactivated', { 'Active seconds': String((Date.now() - this.activationTime) / 1000 )});
    };

    updateFeatureSupport(false, true).catch(e => console.log(e));
    this.getUser();
  }

  async getUser() {
    try {
      const user = await getUser();
      this.props.functionsContext.updateUser(user);
    } catch (e) {
      console.log('Failed to get user.', e);
    }
  }

  componentWillUnmount() {
    window.onfocus = null;
    window.onblur = null;
  }

  endChat() {
    this.props.functionsContext.postToWorker('stopBillablePolling');
    const roomId = localStorage.getItem('roomId');
    if (this.props.appContext.user && roomId) {
      endChatRoom(roomId)
        .then(() => localStorage.removeItem('roomId'))
        .catch(e => {
          console.log('Error', e);
        });
    }
  }

  async requestInterpreter() {
    if (this.state.selectedLanguage === null || this.state.languages.length === 0) {
      return;
    }

    this.setState({ buttonDisabled: true });

    let selectedLanguage = this.state.languages.find(lang => lang.id === this.state.selectedLanguage);

    if (selectedLanguage && selectedLanguage.languageTag !== 'prebooking') {
      localStorage.setItem('selectedLanguage', selectedLanguage.id);
    }

    if (!selectedLanguage) {
      selectedLanguage = this.state.languages[0];
    }

    if (this.state.targetLanguages.length > 0 && !selectedLanguage.languageTag !== 'prebooking') {
      localStorage.setItem('selectedTargetLanguage', this.state.selectedTargetLanguage);
    }

    if (selectedLanguage.languageTag !== 'prebooking' && this.state.skills[selectedLanguage.id]) {
      this.showSkillModal(this.state.skills[selectedLanguage.id].name, selectedLanguage);
    } else {
      await this.sendInterpretationRequest(undefined, selectedLanguage);
    }
  }

  async showSkillModal(skillName, selectedLanguage) {
    if (await showHtmlQuestion(i18n('skillModalQuestion') + '<br/><br/><b>' + skillName + '</b>')) {
      await this.sendInterpretationRequest(this.state.skills[selectedLanguage.id], selectedLanguage);
    } else {
      await this.sendInterpretationRequest(undefined, selectedLanguage);
    }
  }

  async sendInterpretationRequest(skill, selectedLanguage) {
    let languageCode = '';
    let languageName = '';
    let targetLanguageCode = '';
    let targetLanguageName = '';
    let skillName = '';

    languageCode = selectedLanguage.languageTag;
    languageName = selectedLanguage.name;

    this.state.targetLanguages.forEach(lang => {
      if (lang.id === this.state.selectedTargetLanguage) {
        targetLanguageCode = lang.languageTag;
        targetLanguageName = lang.name;
      }
    });

    if (skill) {
      skillName = this.state.skills[selectedLanguage.id].name;
    }

    const analyticsParams = {
      'type': 'data',
      'language id': selectedLanguage.id,
      'language code': languageCode,
      'language name': languageName,
      'target language id': this.state.selectedTargetLanguage,
      'target language code': targetLanguageCode,
      'target language name': targetLanguageName,
      'skill id': skill ? skill.id : '',
      'skill name': skillName
    };

    try {
      const chatRoom = await createChatRoom(
        selectedLanguage.id,
        this.state.selectedTargetLanguage,
        skill ? skill.id : null);

      amplitude.getInstance().logEvent('Request interpretation succeeded', analyticsParams);

      const unlisten = this.props.history.listen(() => {
        if (window.location.pathname === '/call') {
          this.setState({buttonDisabled: false});
          unlisten();
        }
      });

      this.props.history.replace(
        '/call', {
        callerName: '',
        languageName: chatRoom.language.name,
        secondsLeft: this.state.secondsLeft,
        apiKey: chatRoom.apiKey,
        chatToken: chatRoom.chatToken,
        sessionId: chatRoom.sessionId,
        roomId: chatRoom.id,
        textChatTokenProviderEndpoint: chatRoom.textChatTokenProviderEndpoint,
        textChatInstanceLocator: chatRoom.textChatInstanceLocator,
        textChatRoomId: chatRoom.textChatRoomId
      });
    } catch (e) {
      this.setState({buttonDisabled: false});

      if (e.response) {
        analyticsParams['HTTP status code'] = String(e.response.status);
      }

      if (e.message === 'Session expired') {
        this.sessionExpired();
      } else if (e.message === 'No minutes left') {
        amplitude.getInstance().logEvent('No minutes left to request interpretation', analyticsParams);
        showError(i18n('noTimeLeft'));
      } else {
        amplitude.getInstance().logEvent('Request interpretation failed', analyticsParams);
        showError(i18n('failedToCreateChatroom'));
      }
    }
  }

  async appendLanguages() {
    try {
      const res = await getLanguageList();

      this.setState({
        languages: res.selectableLanguages,
        secondsLeft: res.secondsLeft,
        invoicing: res.invoicing,
        buttonDisabled: false,
        selectedLanguage: localStorage.getItem('selectedLanguage') || (res.selectableLanguages.length > 0 ? res.selectableLanguages[0].id : null),
        selectedTargetLanguage: localStorage.getItem('selectedTargetLanguage') || (res.targetLanguages && res.targetLanguages.length > 0 ? res.targetLanguages[0].id : null),
        targetLanguages: res.targetLanguages || [],
        skills: res.skills,
        systemAlert: res.systemAlert
      });

      this.props.functionsContext.reloadPageIfExpired();
    } catch (e) {
      if (e.message === 'Session expired') {
        this.sessionExpired();
      } else {
        console.log(e);
        amplitude.getInstance().logEvent('Fetch language list failed', { 'HTTP status code': e.response ? String(e.response.status) : ''});
      }
    }
  }

  sessionExpired() {
    this.clearInterval(this.ivLangList);
    this.props.functionsContext.sessionExpired();
  }

  logout() {
    this.clearInterval(this.ivLangList);
    this.props.functionsContext.logout();
  }

  async refresh() {
    await this.appendLanguages();
  }

  settings() {
    this.setState({showSettings: !this.state.showSettings});
  }

  onLanguageChanged(id) {
    this.setState({selectedLanguage: id});
  }

  onTargetLanguageChanged(id) {
    this.setState({selectedTargetLanguage: id});
  }

  render() {
    let targetLanguageList = null;

    if (this.state.targetLanguages.length > 0) {
      targetLanguageList = (
        <div>
          <h2>{i18n('targetLanguage')}</h2>
          <select className='select' value={this.state.selectedTargetLanguage || ''} onChange={e => this.onTargetLanguageChanged(e.target.value) }>
            {this.state.targetLanguages.map(lang => (<option key={'lang_' + lang.id} value={lang.id}>{lang.name}</option>))}
          </select>
        </div>
      );
    }

    return (
      <StyledCenteredBlockContainer>
        <StyledDialogBlock>
          <Header
            user={this.props.appContext.user}
            onShowOptions={() => this.settings()}
            optionsVisible={this.state.showSettings}
            onRefresh={() => this.refresh()}
            onLogout={() => this.props.functionsContext.logout()} />
          <div className='dialog'>
            {targetLanguageList}
            <h2>{i18n('selectLanguage')}</h2>
            <select className='select' value={this.state.selectedLanguage || ''} onChange={e => this.onLanguageChanged(e.target.value) }>
              {this.state.languages.map(lang => (<option key={'lang_' + lang.id} value={lang.id}>{lang.name}</option>))}
            </select>
            <button className='action' disabled={this.state.buttonDisabled} onClick={() => this.requestInterpreter()}>{i18n('requestInterpreting')}
              <Busy visible={this.state.buttonDisabled} />
            </button>
            <SystemAlert visible={this.state.systemAlert} />
            <Note text={i18n('openingTimes')} />
            <DeviceSelector showSettings={this.state.showSettings} />
          </div>
          <a href={getEulaUrl(this.props.appContext.user)} target='_new' className='eula'>{i18n('termsOfUse')}</a>
          <TimeAvailable seconds={this.state.secondsLeft} invoicing={this.state.invoicing} />
        </StyledDialogBlock>
      </StyledCenteredBlockContainer>
    );
  }
}

const Note = props => {
  if (props.text === undefined || props.text.length === 0) {
    return null;
  }

  return (
    <h3>{props.text}</h3>
  );
};

const SystemAlert = props => {
  if (!props.visible) {
    return null;
  }

  return <h3 className='systemAlert'>{i18n('systemAlert')}</h3>;
};

const TimeAvailable = props => {
  if (props.invoicing) {
    return null;
  }

  const text = props.seconds > 0 ?
    i18n('timeLeft').replace('{}', format(props.seconds)) :
    i18n('zeroTimeLeft');

  return <div id='timeLeftContainer'><div id='customerTimeLeft'>{text}</div></div>;
};

SystemAlert.propTypes = {
  visible: PropTypes.bool.isRequired
};

Note.propTypes = {
  text: PropTypes.string.isRequired,
};

TimeAvailable.propTypes = {
  seconds: PropTypes.number.isRequired,
  invoicing: PropTypes.bool.isRequired
};

const Busy = props => {
  if (props.visible) {
    return <Spinner name='ball-clip-rotate' className='spinner' />;
  }

  return null;
};

const ContextWrapper = ({ ...props }) => (
  <FunctionsContext.Consumer>
    {functionsContext => (
      <AppContext.Consumer>
        {appContext => (
          <Customer appContext={appContext} functionsContext={functionsContext} {...props} />
        )}
      </AppContext.Consumer>
    )}
  </FunctionsContext.Consumer>
);

Busy.propTypes = {
  visible: PropTypes.bool
};

export default ContextWrapper;

Customer.propTypes = {
  appContext: PropTypes.object.isRequired,
  functionsContext: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

reactMixin.onClass(Customer, TimerMixin);
