import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { getPrebookings } from '../services/prebookService';
import { logAnalytics } from '../services/analyticsLogger';
import AppContext from '../contexts/AppContext';
import FunctionsContext from '../contexts/FunctionsContext';
import ThemeContext from '../contexts/ThemeContext';
import PreviewView from '../components/PreviewView';
import VideoRoom from '../components/VideoRoom';
import Spinner from '../components/Spinner';

import { showError } from '../utils/msgbox';
import i18n from '../i18n';
import { SERVISES, TARGET_LANGUAGE_ID } from '../utils/luonaSpecificData';

const MeetingRoom = (props) => {
  const search = useLocation().search;
  const chatRoomId = new URLSearchParams(search).get('chatRoomId');
  const name = new URLSearchParams(search).get('name');
  const locale = new URLSearchParams(search).get('locale');
  const skill = new URLSearchParams(search).get('skill');
  const prebookId = new URLSearchParams(search).get('prebookId')||
    new URLSearchParams(search).get('prebookingId');
  const params = useParams();
  const { user } = useContext(AppContext);
  const {
    fetchLanguageList
  } = useContext(FunctionsContext);

  const [ isPreviewView, setIsPreviewView ] = useState(true);
  const [ loading, setLoading ] = useState(true);
  const [ localAudio, setLocalAudio ] = useState(true);
  const [ localVideo, setLocalVideo ] = useState(true);
  const [ username, setUsername ] = useState(name || props.location?.state?.name);
  const [ videoEffects, setVideoEffects ] = useState({ backgroundBlur: false});
  const [ selectedLangs, setSelectedLangs ] = useState({});
  const [ isEndUser ] = useState(user.type !== 'Interpreter');
  const [ prebooking, setPrebooking ] = useState(null);
  const history = useHistory();
  const { colors } = useContext(ThemeContext);

const joinImmediately = async(userData) => {
  onJoin({userData, userName: user?.name});
  setLoading(false);
};

const fetchLanguageListData = async () => {
  try {
    await fetchLanguageList();
  } catch (e) {
    if (e.message === 'Already fetching') {
      return;
    }
  }
};

const getPrebookingsRequest = async (prebookingId) => {
  logAnalytics('Start preboking process', {
    userId: user?.id
  });
  try {
    const { prebookings } = await getPrebookings(true, user.type);
    const prebookForMultistart = prebookings.find((prebook) => {
      return prebook.id === prebookingId;
    });
    if (prebookForMultistart) {
      setPrebooking(prebookForMultistart);
    } else {
      logAnalytics('Prebooking is not found', {
        userId: user?.id
      });
      showError(i18n('prebooking_is_not_found'));
      history.replace('/prebooking_luona');
    }
  } catch (error) {
    logAnalytics('Error get prebooking for start', {
      userId: user?.id
    });
    showError(i18n('prebooking_is_not_found'));
    history.replace('/prebooking_luona');
    console.error(error);
  }
};

const getSkill = (prebook) => {
  if (prebook.skills?.length > 0) {
    const servicesIds = Object.values(SERVISES);
    const prebookSkill = prebook.skills.find(s => servicesIds.includes(s.id));
    return prebookSkill?.id || skill || SERVISES.socialServiseId;
  }
  return skill || SERVISES.socialServiseId;
};

useEffect(() => {
  if (prebooking) {
    setSelectedLangs({ skill: getSkill(prebooking) });
    setLoading(false);
  }
}, [prebooking]); // eslint-disable-line react-hooks/exhaustive-deps

const isAuthorize = () => {
  const userJson = localStorage.getItem('user');
  if (userJson !== null) {
    try {
      const userData = JSON.parse(userJson);
      return !!userData?.token;
    } catch (e) {
      console.error('Failed to parse user.', e);
    }
  }
   return false;
};

  useEffect(() => {
    const func = async (ev) => {
      if (ev.detail.user && !prebookId) {
        joinImmediately(ev.detail.user);
      }
    };
    const getUserData = async(ev) => {
      if ((ev.detail.user) && prebookId) {
        getPrebookingsRequest(prebookId);
        if (!username) {
          setUsername(user?.name);
        }
      }
    };

    if (user?.token && !user?.selectableLanguages?.length) {
      fetchLanguageListData();
    }

    if (isEndUser && user?.selectableLanguages?.length && !prebookId) {
      joinImmediately();
    }

    if (!isEndUser) {
      onJoin({});
      setLoading(false);
    }

    if (isAuthorize() && prebookId) {
      getPrebookingsRequest(prebookId);
      if (!username) {
        setUsername(user?.name);
      }
    }

    document.addEventListener('getUserLangs', func);
    document.addEventListener('setUserToken', getUserData);
    return () => {
      document.removeEventListener('getUserLangs', func);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      window.history.replaceState({from: '/meeting', to: '/feedback'}, document.title, '/');
    };
  }, []);

  const languageTagMap = {
    ru: 'rus',
    en: 'eng',
    fi: 'fin',
    ua: 'rus',
    ar: 'ara',
    ku: 'ckb'
  };

  const onJoin = ({ userName, userData }) => { // eslint-disable-line consistent-return
    const userObj = userData || user;
    if (isEndUser && !prebooking) {
      const genericLanguageId = userObj.selectableLanguages.find(lang => lang.languageTag ==='default' || lang.languageTag ==='lna').id; //luona
      const targetLanguageId = TARGET_LANGUAGE_ID; //English
      //const defaultLanguageId = userObj.selectableLanguages.find(lang => lang.languageTag === 'rus').id;
      const languageId = userObj.selectableLanguages.find(lang => lang.languageTag === languageTagMap[locale])?.id;
      if (!prebookId && !chatRoomId && (!languageId || !skill)) {
        return history.push('/wizard');
      } else {
        setSelectedLangs({ genericLanguageId, targetLanguageId, languageId, skill });
      }
    }
    if (userName) {
      setUsername(userName);
    }
    setIsPreviewView(false);
  };

  return (
    <>
      {loading ? (<Spinner overlay={true} color={colors.luonaMainBrightColor}/>) : isPreviewView ? (
        <PreviewView
          prebooking={prebooking}
          username={username}
          handleJoin={onJoin}
          audio={localAudio}
          video={localVideo}
          videoEffects={videoEffects}
          heandleChangeVideoEffects={(value) => setVideoEffects(value)}
          heandleChangeAudio={(value) => setLocalAudio(value)}
          heandleChangeVideo={(value) => setLocalVideo(value)}
          history={history}
        ></PreviewView>
      ) : (
        <VideoRoom
          prebooking={prebooking}
          roomId={params.meetingId}
          audio={localAudio}
          video={localVideo}
          username={username}
          isEndUser={isEndUser}
          videoEffects={videoEffects}
          heandleChangeVideoEffects={(value) => setVideoEffects(value)}
          selectedLangs={selectedLangs}
          handleLeave={(leftChatRoomId) => { // eslint-disable-line consistent-return
            if (isEndUser) {
              const path = leftChatRoomId ? '/feedback_luona' : '/wizard';
              const param = leftChatRoomId ? {roomId: leftChatRoomId} : {};
              history.push(path, param);
            } else {
              return history.push('/');
            }
          }}
        />
      )}
    </>
  );
};

export default MeetingRoom;
