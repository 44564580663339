import moment from 'moment';

export const parseNoticeMessage = (messageToParse) => {
  const parsedAlternativeContactPerson = messageToParse?.match(/Viesti välitetään henkilölle: ([\s\S]*?)\n?Numero\(t\) johon tulkki soittaa: /) || messageToParse?.match(/Relay the message to person: ([\s\S]*?)\n?Numbers to be called: /);
  const parsedPhoneNumbers = messageToParse?.match(/Numero\(t\) johon tulkki soittaa: ([\s\S]*?)\n?Viesti: /) || messageToParse?.match(/Numbers to be called: ([\s\S]*?)\n?Message:/);
  const parsedContentData = messageToParse?.match(/Viesti:\n? ([\s\S]*?)$/) || messageToParse?.match(/Message:\n? ([\s\S]*?)$/);

  return {
    alternativeContactPerson: Array.isArray(parsedAlternativeContactPerson) ? parsedAlternativeContactPerson[1] : '',
    phoneNumbers: Array.isArray(parsedPhoneNumbers) && parsedPhoneNumbers[1].match(/\+?[0-9]+/g) ? parsedPhoneNumbers[1].match(/\+?[0-9]+/g) : [],
    contentData: Array.isArray(parsedContentData) ? parsedContentData[1] : '',
  };
};

export const parseNoticeCustomerData = (messageToParse) => {
  const customerData = messageToParse?.match(/^([\s\S]*?)\n?Date of birth: /) || messageToParse?.match(/^([\s\S]*?)\n?Asiakkaan syntymäaika: /);
  const customerBirthday = messageToParse?.match(/Date of birth: ([\s\S]*?)$/) || messageToParse?.match(/Asiakkaan syntymäaika: ([\s\S]*?)$/);

  return {
    customerData: Array.isArray(customerData) ? customerData[1] : '',
    customerBirthday: Array.isArray(customerBirthday) ? customerBirthday[1] : '',
  };
};

export const isPhoneNumberValid = (maybePhoneNumber) => {
  return /[0-9]{9,12}/.test(maybePhoneNumber);
};

export const isValidCustomerBirthday = (maybeDate) => {
  return /[0-9]/.test(maybeDate);
};

export const getValidCustomerBirthday = (maybeDate) => {
  return !/[0-9]/.test(maybeDate) ? '**/**/****' : moment(maybeDate).format('DD/MM/YYYY');
};

export const getFormatedCustomFieldsForSave = (customFields, aCustomFieldsValues, withEmptyFields = false) => {
  return customFields.reduce((memo, customField) => {
    if (aCustomFieldsValues[customField.name] || (withEmptyFields && customField.customValueId)) {
      memo.push({
        id: customField.id,
        type: customField.type,
        value: aCustomFieldsValues[customField.name] || '',
        ...(customField.customValueId ? {
          customValueId: customField.customValueId,
        } : {}),
      });
    }
    return memo;
  }, []);
};

const getFixedDateStrind = date => {
  if (date) {
    if (date instanceof Date) {
      try {
        const milliseconds = Date.parse(date);
        return moment(milliseconds).format('DD/MM/YYYY');
      } catch (e) {
        return null;
      }
    }
    return date;
  }
  return null;
};

export const isDateValid = date => {
  if (date) {
    return moment(getFixedDateStrind(moment(date).format('DD/MM/YYYY')), 'DD/MM/YYYY', true).isValid();
  }
  return true;
};

export const isValidDateInCustomFields = (customFields, customFieldsValues) => {
  if (customFields && customFields.length > 0 && customFieldsValues) {
    return customFields.filter((customField) => customField.type === 'date' && !isDateValid(customFieldsValues[customField.name])).length === 0;
  }
  return [];
};

export const isRequiredCustomFieldsFilled = (customFields, customFieldsValues) => {
  if (customFields && customFields.length > 0 && customFieldsValues) {
    return customFields.filter((customField) => customField.required && !customFieldsValues[customField.name]).length === 0;
  }
  return [];
};

const getSpecificIndexPage = (pages, specificPages) => {
  return pages.reduce((memo, item, index) => {
    const pageName = item.page || item;
    if (specificPages.indexOf(pageName) !== -1) {
      memo.push(index);
    }
    return memo;
  }, []);
};

export const getFixedPagePath = (path, specificPages) => {
  const specificIndexes = getSpecificIndexPage(path, specificPages);
  if (specificIndexes.length > 0) {
    return path.filter((value, index) => specificIndexes.indexOf(index) === -1);
  }
  return path;
};
