export const chatWizardStepNamesMap = {
  dateTime: 'DateTime',
  presence: 'Presence',
  location: 'Location',
  date: 'Date',
  time: 'Time',
  duration: 'Duration',
  language: 'Language',
  targetLanguage: 'TargetLanguage',
  skill: 'Skill',
  dialect: 'Dialect',
  gender: 'Gender',
  invoicing: 'Invoicing',
  invoiceTargeting: 'InvoiceTargeting',
  preferredInterpreter: 'PreferredInterpreter',
  additionalInfo: 'additionalInfo',
  customerReference: 'CustomerReference',
  callType: 'CallType',
  summary: 'Summary',
  customerInfo: 'CustomerInfo',
  content: 'Content',
  ccEmails: 'ccEmails',
  patientName: 'patientName',
  remoteAsBackup: 'RemoteAsBackup',
  presenceForBackup: 'PresenceForBackup',
};

export const buildSteps = (pages, user, config) => {
  return pages.reduce((memo, step) => {
    if (step === chatWizardStepNamesMap.dateTime) {
      memo.push(chatWizardStepNamesMap.presence);
      if (config.presence === 'onsite') {
        memo.push(chatWizardStepNamesMap.location);
        memo.push(chatWizardStepNamesMap.remoteAsBackup);
        if (config.remoteAsBackup) {
          memo.push(chatWizardStepNamesMap.presenceForBackup);
        }
      }
      
      memo.push(chatWizardStepNamesMap.date);
      memo.push(chatWizardStepNamesMap.time);
      memo.push(chatWizardStepNamesMap.duration);
    } else if (step === chatWizardStepNamesMap.language) {
      if (config.targetLanguages.length > 1 && user.userGroup && user.userGroup.showTargetLanguages) {
        memo.push(chatWizardStepNamesMap.targetLanguage);
      }
      memo.push(chatWizardStepNamesMap.language);
    } else if (step === chatWizardStepNamesMap.skill) {
      const skills = config.skills;
      if (skills && skills.length > 0) {
        if (skills?.some(skill => skill.category && skill.category.tag === 'dialect')) {
          memo.push(chatWizardStepNamesMap.dialect);
        }
        if (skills?.some(skill => skill.category && skill.category.tag === 'gender')) {
          memo.push(chatWizardStepNamesMap.gender);
        }
        if (skills?.some(skill => !skill.category || (skill.category.tag !== 'dialect' && skill.category.tag !== 'gender' && skill.category.tag !== 'basic'))) {
          memo.push(chatWizardStepNamesMap.skill);
        }
      }
      if (user?.userGroup?.allowPreferredInterpreter) {
        memo.push(chatWizardStepNamesMap.preferredInterpreter);
      }
    } else if (step === chatWizardStepNamesMap.invoicing) {
      if (
        (!config.prebooking && user?.userGroup?.invoiceRefBeforeHand) ||
        (config.prebooking && user?.userGroup?.invoiceRefBeforeHandPrebooking)
      ) {
        if (
          user?.invoiceRefUserGroups?.length > 0 ||
          user?.userGroup?.invoiceUserGroupSearch ||
          user?.userGroup?.invoiceUserGroupsFromHistory
        ) {
          memo.push(chatWizardStepNamesMap.invoiceTargeting);
        }
        if (config.presence !== 'notice') {
          memo.push(chatWizardStepNamesMap.customerReference);
        }
      }
    } else if (step === chatWizardStepNamesMap.callType) {
      if (config.voiceCallSupported && config.presence === 'remote' || (config.presence === 'external' && config.callType === 'call')) {
        memo.push(chatWizardStepNamesMap.callType);
      }
    } else if (step === chatWizardStepNamesMap.summary) {
      memo.push(chatWizardStepNamesMap.additionalInfo, chatWizardStepNamesMap.summary);

    } else {
      memo.push(step);
    }
    return memo;
  }, []);
};
