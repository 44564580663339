import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isLuonaHost } from '../utils/luonaSpecificData';

const PrivateRoute = ({ isAuthorized, component: Component, render, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthorized) {
        return render ? render(props) : <Component {...props} />;
      } else if (isLuonaHost()) {
        return (<Redirect to={{ pathname: '/wizard', state: { from: props.location } }} />);
      } else {
        return (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />);
      }
    }}
  />
);

export default PrivateRoute;
