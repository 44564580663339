import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import moment from 'moment';

import Datepicker from './Datepicker';
import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import ActionListItem from './ActionListItem';
import { Input, PhoneNumberInput } from './Input';
import i18n, { langCode } from '../i18n';

import { getValidCustomerBirthday } from '../utils/prebooksHelpers';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const MessageContainer = styled.div`
  margin-bottom: 5px;
  padding-top: 10px;
`;

const StyledDatepicker = styled(Datepicker)`
  margin: 0;
  border-radius: 6px;
  border: 1px solid #d8d8dc;
`;

const CustomerNameAndDateOfBirth = ({
  active,
  forceOpened,
  customerData,
  setCustomerData,
  customerDataBirthday,
  setCustomerDataBirthday,
}) => {
  const [isCustomerDataOpen, setIsCustomerDataOpen] = useState(forceOpened ? true : false);

  return (
    isCustomerDataOpen ? (
      <>
        <MessageContainer>
          <Input
            active={!active}
            noDelay={true}
            notBoldInputTitle
            title={i18n('customer_name')}
            type="text"
            value={customerData}
            onChange={setCustomerData}
            maxLength="900"
            onFocus={() => {
              amplitude.getInstance().logEvent('Edit prebooking customer name and date of birth');
            }}
          />
        </MessageContainer>
        <MessageContainer>
          <StyledDatepicker
            notBoldInputTitle
            title={i18n('date_of_birth')}
            fixedMaxWidth
            selected={customerDataBirthday}
            onChange={setCustomerDataBirthday}
            locale={langCode}
            maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            openToDate={customerDataBirthday ? customerDataBirthday : new Date(moment().subtract(18, 'years'))}
          />
        </MessageContainer>
      </>
    ) : (
      <ActionListItem
        editable={true}
        title={i18n('customer_name_and_date_of_birth')}
        subtitle={customerData || customerDataBirthday ? `${customerData} ${getValidCustomerBirthday(customerDataBirthday)}` : i18n('mandatory_information')}
        onClick={() => setIsCustomerDataOpen(true)}
      />
    )
  );
};

const ToWhomTheMessageIsForwardedIfNotToTheCustomer = ({
  active,
  forceOpened,
  alternativeContactPerson,
  setAlternativeContactPerson,
}) => {
  const [isAlternativeContactPersonOpen, setIsAlternativeContactPersonOpen] = useState(forceOpened ? true : false);

  return (
    isAlternativeContactPersonOpen ? (
      <MessageContainer>
        <Input
          active={active}
          noDelay={true}
          notBoldInputTitle
          title={i18n('to_whom_the_message_is_forwarded_if_not_to_the_customer')}
          type="textarea"
          value={alternativeContactPerson}
          onChange={setAlternativeContactPerson}
          maxLength="40"
          onFocus={() => {
            amplitude.getInstance().logEvent('Edit prebooking to whom the message is forwarded');
          }}
        />
      </MessageContainer>
    ) : (
      <ActionListItem
        editable={true}
        title={i18n('to_whom_the_message_is_forwarded_if_not_to_the_customer')}
        subtitle={alternativeContactPerson ? alternativeContactPerson : i18n('optional_information')}
        onClick={() => setIsAlternativeContactPersonOpen(true)}
      />
    )
  );
};

const TelephoneNumberToWhichTheInterpreterWillCall = ({
  active,
  forceOpened,
  mainPhoneNumber,
  setMainPhoneNumber,
  phoneNumbers,
  setPhoneNumbers,
}) => {
  const [isPhoneNumbersOpen, setIsPhoneNumbersOpen] = useState(forceOpened ? true : false);

  return (
    isPhoneNumbersOpen ? (
      <>
        <MessageContainer>
          <PhoneNumberInput
            active={active}
            noDelay={true}
            notBoldInputTitle
            title={i18n('main_telephone_number_to_which_the_interpreter_will_call')}
            type="textarea"
            value={mainPhoneNumber}
            onChange={setMainPhoneNumber}
            maxLength="12"
            onFocus={() => {
              amplitude.getInstance().logEvent('Edit prebooking message telephone number to which the interpreter will call');
            }}
          />
        </MessageContainer>
        <MessageContainer>
          <Input
            noDelay={true}
            notBoldInputTitle
            title={i18n('additional_telephone_number_to_which_the_interpreter_will_call')}
            type="textarea"
            value={phoneNumbers}
            onChange={setPhoneNumbers}
            maxLength="26"
            onFocus={() => {
              amplitude.getInstance().logEvent('Edit prebooking message telephone number to which the interpreter will call');
            }}
          />
        </MessageContainer>
      </>
    ) : (
      <ActionListItem
        editable={true}
        title={i18n('telephone_number_to_which_the_interpreter_will_call')}
        subtitle={mainPhoneNumber || phoneNumbers ? `${mainPhoneNumber} ${phoneNumbers}` : i18n('mandatory_information')}
        onClick={() => setIsPhoneNumbersOpen(true)}
      />
    )
  );
};

const CustomerInfo = ({
  editableField,
  customerData,
  setCustomerData,
  alternativeContactPerson,
  setAlternativeContactPerson,
  mainPhoneNumber,
  setMainPhoneNumber,
  phoneNumbers,
  setPhoneNumbers,
  customerDataBirthday,
  setCustomerDataBirthday,
}) => (
  <StyledContainer>
    <ChatWizardHeader
      title={i18n('customer_data')}
    />
    <ContentBox overlapping={true}>
      {
        !editableField || (editableField === 'customerNameAndDateOfBirth') ? (
          <CustomerNameAndDateOfBirth
            active={!editableField}
            forceOpened={editableField === 'customerNameAndDateOfBirth'}
            customerData={customerData}
            setCustomerData={setCustomerData}
            customerDataBirthday={customerDataBirthday}
            setCustomerDataBirthday={setCustomerDataBirthday}
          />
        ) : (null)
      }
      {
        !editableField || (editableField === 'toWhomTheMessageIsForwardedIfNotToTheCustomer') ? (
          <ToWhomTheMessageIsForwardedIfNotToTheCustomer
            active={!editableField}
            forceOpened={editableField === 'toWhomTheMessageIsForwardedIfNotToTheCustomer'}
            alternativeContactPerson={alternativeContactPerson}
            setAlternativeContactPerson={setAlternativeContactPerson}
          />
        ) : (null)
      }
      {
        !editableField || (editableField === 'telephoneNumberToWhichTheInterpreterWillCall') ? (
          <TelephoneNumberToWhichTheInterpreterWillCall
            active={!editableField}
            forceOpened={editableField === 'telephoneNumberToWhichTheInterpreterWillCall'}
            mainPhoneNumber={mainPhoneNumber}
            setMainPhoneNumber={setMainPhoneNumber}
            phoneNumbers={phoneNumbers}
            setPhoneNumbers={setPhoneNumbers}
          />
        ) : (null)
      }
    </ContentBox>
  </StyledContainer>
);

CustomerInfo.propTypes = {
  customerData: PropTypes.string,
  setCustomerData: PropTypes.func,
  alternativeContactPerson: PropTypes.string,
  setAlternativeContactPerson: PropTypes.func,
  phoneNumbers: PropTypes.string,
  setPhoneNumbers: PropTypes.func,
};

export default CustomerInfo;
