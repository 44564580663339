import styled from 'styled-components';

import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import ActionListItem from './ActionListItem';

import i18n from '../i18n';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const RemoteAsBackup = ({
    onUpdatepresenceBackup,
    presenceBackup
}) => {
  return (
    <StyledContainer>
      <ChatWizardHeader
        title={i18n('remote_backup_to_onsite')}
      />
      <ContentBox
        centered={true}
      >
        <ActionListItem
            data-test='remoteAsBackupActionItem'
            title={i18n('use_remote_interpretation_option')}
            icon={'phone_android'}
            iconLib={'material'}
            onClick={() => {
                onUpdatepresenceBackup('remote');
            }}
            selected={presenceBackup === 'remote'}
          />
            <ActionListItem
            data-test='remoteAsBackupActionItem'
            title={i18n('cancel_prebooking_option')}
            icon={'clear'}
            iconLib={'material'}
            onClick={() => {
                onUpdatepresenceBackup('onsite');
            }}
            selected={presenceBackup === 'onsite'}
          />
      </ContentBox>
    </StyledContainer>
  );
};

export default RemoteAsBackup;
