import React, { useContext } from 'react';
import styled from 'styled-components';

import Icon from './Icon';

import ThemeContext from '../contexts/ThemeContext';

const StyledButton = styled.button`
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  transition: ${({ transition }) => (transition)};
  &:hover {
    cursor: pointer;
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconButton = ({ onClick, icon, iconSize, iconLib, iconColor, hoverColor, children, ...rest }) => {
  const themeContext = useContext(ThemeContext);

  const layout = themeContext.layout;

  return (
    <StyledButton
      transition={layout.transition}
      onClick={onClick}
      {...rest}
    >
      <Icon
        icon={icon}
        iconSize={iconSize}
        iconLib={iconLib}
        iconColor={iconColor}
        hoverColor={hoverColor}
      />
      {children}
    </StyledButton>
  );
};

export default IconButton;
