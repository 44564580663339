import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ContentBox from './ContentBox';
import ActionList from './ActionList';
import ActionListItem from './ActionListItem';
import ChatWizardHeader from './ChatWizardHeader';
import i18n from '../i18n';

import ThemeContext from '../contexts/ThemeContext';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CallTypePage = props => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;

  return (
    <StyledContainer>
      <ChatWizardHeader
        title={i18n('call_type_question')}
        helpId='call_type'
      />
      <ContentBox>
        <ActionList>
          {props.ongoingCall ? (
            <ActionListItem
              data-test='conferenceCall'
              title={i18n('conference_call_title')}
              subtitle={i18n('conference_call_tip')}
              icon={'phone_in_talk_outlined'}
              iconLib={'material'}
              iconColor={colors.error}
              nonSelectable={true}
            />
          ) : null}
          <ActionListItem
            data-test='callTypeCall'
            title={i18n('call_type_call')}
            subtitle={i18n('call_type_call_tip')}
            icon={'phone_in_talk_outlined'}
            iconLib={'material'}
            onClick={() => {
              props.onChange('call');
            }}
            selected={props.callType === 'call'}
          />
          <ActionListItem
            data-test='callTypeVideo'
            title={i18n('call_type_video')}
            subtitle={i18n('call_type_video_tip')}
            icon={'live_tv'}
            iconLib={'material'}
            onClick={() => {
              props.onChange('data');
            }}
            selected={props.callType === 'data'}
          />
        </ActionList>
      </ContentBox>
    </StyledContainer>
  );
};

CallTypePage.propTypes = {
  callType: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default CallTypePage;
