import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import ButtonPanel from './ButtonPanel';
import { RoundButton } from './Buttons';
import ContentBox from './ContentBox';
import ChatWizardHeader from './ChatWizardHeader';

import i18n from '../i18n';
import Layout from '../layout/Layout2020';
import { Input } from '../components/Input';

import {
  inviteUsersToTheGroup,
} from '../services/usergroupService';

import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ padding }) => (padding * 3)}px;
`;

const StyledInput = styled(Input)`
  display: block;
  font-size: 16px;
  width: 100%;
  height: 48px;
  min-height: 48px;
  padding: 10px 10px;
  background-color: #ffffff;
  &:not(:first-child) {
    margin-top: 15px;
  }
`;

const StyledErrorMessage = styled.div`
  color: ${({ color }) => (color)};
  padding: 5px 0;
`;

const ConfirmationDialog = ({ closeModal }) => {
  const themeContext = useContext(ThemeContext);
  const functionsContext = useContext(FunctionsContext);

  const colors = themeContext.colors;

  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const phonePattern = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[\s.]?[0-9]{3}[\s.]?[0-9]{4,6}$/);
  const emailPattern = new RegExp(/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/);

  const inviteToGroup = async () => {
    try {
      await inviteUsersToTheGroup(userName, userEmail, userPhoneNumber);
      setUserName('');
      setUserEmail('');
      setUserPhoneNumber('');
      closeModal();
    } catch (e) {
      if (e.message === 'Session expired') {
        functionsContext.sessionExpired();
      }
      closeModal();
    }
  };

  return (
    <StyledContainer
      padding={Layout.padding}
      colors={colors}
    >
      <ChatWizardHeader title={i18n('invite_to_the_group')} />
      <ContentBox
        minorTitle={i18n('invite_to_the_group_explanation')}
      >
        <StyledInput
          type="text"
          placeholder={i18n('name')}
          value={userName}
          onChange={setUserName}
        />
        <StyledInput
          type="text"
          placeholder={i18n('email')}
          value={userEmail}
          onChange={setUserEmail}
          onBlur={() => {
            if (userEmail && !emailPattern.test(userEmail)) {
              setEmailError('invalid_email_address');
            }
          }}
          onFocus={() => {
            setEmailError('');
          }}
        />
        {emailError ? (
          <StyledErrorMessage
            color={colors.error}
          >
            {i18n(emailError)}
          </StyledErrorMessage>
        ) : null}
        <StyledInput
          type="tel"
          placeholder={i18n('phone_number_title')}
          value={userPhoneNumber}
          onChange={setUserPhoneNumber}
          maxLength={'16'}
          onBlur={() => {
            if (userPhoneNumber && !phonePattern.test(userPhoneNumber)) {
              setPhoneError('invalid_phone_number');
            }
          }}
          onFocus={() => {
            setPhoneError('');
          }}
        />
        {phoneError ? (
          <StyledErrorMessage
            color={colors.error}
          >
            {i18n(phoneError)}
          </StyledErrorMessage>
        ) : null}
      </ContentBox>
      <ButtonPanel>
        <RoundButton
          darkButton
          disabled={!(userName && userEmail && userPhoneNumber)}
          color={colors.tulkaMainColor}
          padding={Layout.buttonPadding}
          label={i18n('invite')}
          onClick={inviteToGroup}
        />
      </ButtonPanel>
    </StyledContainer>
  );
};

export default ConfirmationDialog;
