import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'custom-event-polyfill';
import 'whatwg-fetch';
import 'function.name-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/app';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { getData } from './utils/localStorage';
import { isLuonaCustomer } from './utils/luonaSpecificData';

const errorHandle = async (e) => {
  console.log('Error to logout: ', e);
  if (!e) {
    return false;
  }
  if (typeof e?.message === 'string' && e?.message.includes('ResizeObserver')) {
    console.log('Ignored: ResizeObserver error');
    return false;
  }
  if (e?.message === 'ResizeObserver loop limit exceeded' || e?.message === 'Script error.') {
    console.log('Ignored: ResizeObserver loop limit exceeded');
    return false;
  }
  if (e?.message === 'Uncaught Error: Cannot get _mediaStoppedListener, NativeVideoElementWrapper is destroyed') {
    console.log('Ignored: Uncaught Error: Cannot get _mediaStoppedListener, NativeVideoElementWrapper is destroyed');
    return false;
  }
  if (isLuonaCustomer()) {
    if (!e?.message || e?.name) {
      return false;
    }
    window.location.href = '/wizard';
  } else {
    const previousChatroom = getData('previousChatroom');
    localStorage.clear();
    if (previousChatroom && previousChatroom?.id) {
      localStorage.setItem('previousChatroom', JSON.stringify(previousChatroom));
    }
    if ('caches' in window) {
      const keys = await caches.keys();
      await Promise.all(keys.map(key => caches.delete(key)));
    }
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
  }
  return false;
};

window.onerror = async function () {
  errorHandle();
};

window.addEventListener('error', errorHandle);

window.addEventListener('unhandledrejection', errorHandle);

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('main')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
