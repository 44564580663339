export const stepsMap = {
  languageStep: 'LanguageStep',
  servicesStep: 'ServicesStep',
  authorizationStep: 'AuthorizationStep',
  chooseInterpreterStep: 'ChooseInterpreterStep',
  urgencyStep: 'UrgencyStep',
  bookTimeStep: 'BookTimeStep'
};

export const isNeedChooseInterpreterStep = (lang) => {
  const notNeedIntarpreterLangs = ['en', 'fi'];
  return !notNeedIntarpreterLangs.includes(lang);
};

export const buildWizardSteps = (isAuthorized, available, locale) => {
  const { on_demand, prebooking } = available;
  const steps = [
    { name: stepsMap.languageStep },
    { name: stepsMap.servicesStep },
    { name: stepsMap.authorizationStep },
    { name: stepsMap.chooseInterpreterStep },
    { name: stepsMap.urgencyStep },
    { name: stepsMap.bookTimeStep }
  ];
  return steps.reduce((memo, step) => {
    if (step.name === stepsMap.authorizationStep) {
      return isAuthorized ? memo : memo.concat([step]);
    }

    if (step.name === stepsMap.chooseInterpreterStep) {
      return isNeedChooseInterpreterStep(locale) ? memo.concat([step]) : memo;
    }

    if (step.name === stepsMap.urgencyStep) {
      return (on_demand && prebooking) ? memo.concat([step]) : memo;
    }

    memo.push(step);
    return memo;
  }, []);
};
