import { useState, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import i18n, { tulkaLanguages, setLanguage, getLang } from '../i18n';
import logo from '../images/tulka_logo.svg';
import { RoundButton } from '../components/Buttons';
import SelectLanguages from '../components/SelectLanguages';
import { SELFREGISTRATION_ROUTES } from '../utils/redirectUtils';

import ThemeContext from '../contexts/ThemeContext';


const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const Logo = styled.div`
  width: 105px;
  img {
    display: block;
    width: 100%;
    heigh: auto;
  }
  @media (max-width: 768px) {
    width: 60px;
    min-width: 60px;
  }
`;

const ButtonsContent = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 768px) {
    gap: 0;
    justify-content: end;
    button {
      font-size: 12px;
      padding: 0 6px;
      margin-top: 3px;
    }
  }
`;

const StyledButton = styled(RoundButton)`
  white-space: nowrap
`;

const SwitchLangBlock = styled.div`
  width: 120px;
`;

const SignUpButton = styled(StyledButton)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Header = () => {
  const history = useHistory();
  const { colors } = useContext(ThemeContext);
  const [selectedLanguage, setSelectedLanguage] = useState(getLang());

  const handleSwithcLanguage = (langObj) => {
    if (getLang() !== langObj.value) {
      setSelectedLanguage(langObj.value);
      setLanguage(langObj.value);
      document.dispatchEvent(new CustomEvent('changeLang', {
        detail: {
          lang: langObj.value
        }
      }));
    }
  };

  return (
    <HeaderContainer>
      <Logo>
        <img src={logo} alt='logo' width='200' />
      </Logo>
      <ButtonsContent>
        <StyledButton
          data-test='selfRegistrationlogInBtn'
          fontSize={14}
          height={37}
          padding='32'
          radius={32}
          hideBorder={true}
          nowrap={true}
          lineHeight={21}
          label={i18n('log_in')}
          onClick={() => {
            history.push('/login');
          }}
          smallButton={true}
          darkButton={false}
        />
        <SignUpButton
          data-test='selfRegistrationSignUpBtn'
          label={i18n('sign_up')}
          onClick={() => {
            history.push(SELFREGISTRATION_ROUTES.email);
          }}
          fontSize={14}
          height={37}
          padding='32'
          radius={32}
          nowrap={true}
          lineHeight={21}

          smallButton={true}
          darkButton={true}
        />
        <SwitchLangBlock>
          <SelectLanguages
            activeColor={colors.tulkaMainColor}
            hoverColor={colors.tulkaMainColor}
            backgroundHoverColor = '#DFFFFE'
            selectedLanguage={selectedLanguage}
            avaliableLangs={tulkaLanguages.map(lang => ({key: lang, lable: lang}))}
            handleSwithcLanguage={handleSwithcLanguage}
          />
        </SwitchLangBlock>
      </ButtonsContent>
    </HeaderContainer>
  );
};


export default Header;
