import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import ActionList from './ActionList';
import ActionListItem from './ActionListItem';
import i18n from '../i18n';
import AppContext from '../contexts/AppContext';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const getTitle = (minutes) => {
  if (minutes < 60) {
    return i18n('minutes').replace('{}', minutes);
  }
  if (minutes === 60) {
    return i18n('hour').replace('{}', minutes / 60);
  }
  if (minutes % 60 === 0) {
    return i18n('hours').replace('{}', minutes / 60);
  }
  if (minutes / 60 < 2) {
    return i18n('hour_minutes').replace('{h}', Math.floor(minutes / 60)).replace('{m}', minutes % 60);
  }
  return i18n('hours_minutes').replace('{h}', Math.floor(minutes / 60)).replace('{m}', minutes % 60);
};

const PrebookingDurationPage = props => {
  const context = useContext(AppContext);

  const [minDuration] = useState( (props.presence === 'onsite' ? context.user.userGroup.minOnSiteDuration : null) || 
  context.user.userGroup.minPrebookingDuration || 0);
  const [maxDuration] = useState( (props.presence === 'onsite' ? context.user.userGroup.maxOnSiteDuration : null) || 
  context.user?.userGroup?.maxPrebookingDuration || (24 * 60 * 60 * 60));
  const [defaultDurations] = useState([10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 75, 90, 105, 120, 150, 180, 210, 240]);
  const [durations, setDurations] = useState(defaultDurations);

  useState(() => {
    setDurations(defaultDurations);
    if (!defaultDurations.includes(props.duration)) {
      props.onDurationChanged(0);
    }
  }, []);

  const times = useMemo(() => {
    return durations.reduce((memo, minutes) => {
      const seconds = minutes * 60;
      if (seconds >= minDuration && seconds <= maxDuration) {
        return memo.concat({
          title: getTitle(minutes),
          minutes
        });
      }
      return memo;
    }, []);
  }, [minDuration, maxDuration, durations]);

  return (
    <StyledContainer>
      <ChatWizardHeader
        title={i18n('prebooking_duration_header')}
      />
      <ContentBox>
        <ActionList>
          {times.map(({ title, minutes }) => (
            <ActionListItem
              data-test={minutes + 'MinDurationChatWizard'}
              key={minutes}
              title={title}
              onClick={() => {
                props.onDurationChanged(minutes);
              }}
              selected={props.duration === minutes}
              lineHeight={30}
            />
          ))}
        </ActionList>
      </ContentBox>
    </StyledContainer>
  );
};

PrebookingDurationPage.propTypes = {
  onDurationChanged: PropTypes.func.isRequired,
  duration: PropTypes.number
};

export default PrebookingDurationPage;
