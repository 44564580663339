import React, { useContext, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

import ButtonPanel from './ButtonPanel';
import { RoundButton } from './Buttons';
import ContentBox from './ContentBox';
import Datepicker from './Datepicker';

import i18n, { langCode } from '../i18n';
import { roundToNextMin } from '../utils/timeUtils';

import ThemeContext from '../contexts/ThemeContext';
import Select from 'react-select';
import AppContext from '../contexts/AppContext';

const StyledText = styled.p`
  margin: .5rem;
  font-size: 14px;
  opacity: .5;
`;

const StyledLabel = styled.p`
  margin: 1rem;
  font-size: 16px;
`;

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ padding }) => (padding * 3)}px;
`;

const StyledBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const GlobalStyle = createGlobalStyle`
  .rc-time-picker-panel {
    background-color: transparent !important;
    .rc-time-picker-panel-inner {
      margin-top: 3px;
      border: none;
      box-shadow: none;
      background-color: transparent;
      & .rc-time-picker-panel-input-wrap {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: none;
        padding: 0;
        background-color: ${({ backgroundColor }) => (backgroundColor)};
        & input {
          font-size: ${({ fontSize }) => (fontSize)}px;
          height: 50px;
          border: none;
          line-height: 1.5;
          color: ${({ mainColor }) => (mainColor)};
          background-color: transparent;
          padding: ${({ padding }) => (padding * 2)}px;
        }
      }
      & .rc-time-picker-panel-combobox {
        flex: 1;
        display: flex;
        border: ${({ borderColor }) => ('1px solid ' + borderColor)};
        border-top: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: ${({ backgroundColor }) => (backgroundColor)};
        & .rc-time-picker-panel-select {
          width: 58px;
          border: ${({ borderColor }) => ('1px solid ' + borderColor)};
          border-bottom: 0;
          border-top: 0;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
          & ul {
            & li {
              pading: 0 0 0 20px;
              height: 30px;
              line-height: 30px;
              color: ${({ mainColor }) => (mainColor)};
              &.rc-time-picker-panel-select-option-selected {
                background: ${({ mainColor }) => (mainColor)};
                color: white;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
`;

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  margin-top: ${({ margin }) => (margin / 2)}px;
  margin-bottom: ${({ margin }) => (margin / 2)}px;
  flex: 1;
  align-self: stretch;
  & input {
    height: 50px;
    flex: 1;
    font-size: ${({ fontSize }) => (fontSize)}px;
    text-align: left;
    align-self: stretch;
    color: ${({ color }) => (color)};
    padding: ${({ padding }) => (padding * 2)}px;
    border: none;
    border-radius: 6px;
    background-color: ${({ backgroundColor }) => (backgroundColor)};
  }
  & .rc-time-picker-clear {
    top: 14px;
    right: 14px;
  }
`;

const getDisabledHours = (date) => {
  const today = moment();
  if (moment(date).isSame(today, 'day')) {
    return [...Array(roundToNextMin(moment()).add(1, 'hours').hour()).keys()];
  }
  return [];
};

const getDisabledMinutes = (date, isEndTime) => {
  const minTime = isEndTime ? moment().add(1, 'hours').add(30, 'minutes') : moment().add(1, 'hours');
  const isBeforMinTime = moment(date || moment()).isBefore(minTime);
  const isTheSameHour = moment(date || moment()).isSame(minTime, 'hour');
  if (moment(date).isSame(minTime, 'day') && (isBeforMinTime || isTheSameHour)) {
    return [...Array(roundToNextMin(moment().add(1, 'hours')).minute() / 5).keys()].map((key) => (key * 5));
  }
  return [];
};

const getDisabledEndTimeHours = (startDate, endDate) => {
  const minTime = moment(startDate).add(25, 'minutes');
  if (moment(endDate).isBefore(minTime, 'day')) {
    return [...Array(roundToNextMin(moment(minTime)).hour()).keys()];
  }
  return [];
};

const getDisabledEndTimeMinutes = (startDate, endDate) => {
  const minTime = moment(startDate).add(25, 'minutes');
  const isBeforMinTime = moment(endDate).isBefore(minTime);
  if (isBeforMinTime) {
    return [...Array(roundToNextMin(moment(minTime)).minute() / 5).keys()].map((key) => (key * 5));
  }
  return [];
};

const getStartTime = ({startDate, endDate, id}) => {
  const today = moment();
  if (id && (moment(startDate).isSame(today, 'day') || moment(startDate).isBefore(today)) && (moment(endDate).isAfter(today) || moment(endDate).isSame(today))) {
    return moment(startDate);
  }
  if (!id) {
    if (moment(startDate).isBefore(today)) {
      return roundToNextMin(moment().add(1, 'hours'));
    }
    if (endDate && moment(endDate).isBefore(startDate)) {
      return moment(startDate);
    }
    return moment(startDate).add(8, 'hours');
  }
  return moment(startDate);
};

const getEndTime = ({startDate, endDate, id}) => {
  const today = moment();
  if (moment(startDate).isBefore(today) && moment(endDate).isBefore(today)) {
    if (!id) {
      return roundToNextMin(moment().add(2, 'hours'));
    }
    return moment(endDate);
  }
  if (moment(startDate).isSame(endDate)) {
    if (!id){
      return moment(endDate).add(17, 'hours');
    }
    return moment(endDate).add(30, 'minutes');
  }
  if (moment(startDate).isAfter(moment(endDate).subtract(30, 'minutes'))) {
    return roundToNextMin(moment(startDate).add(25, 'minutes'));
  }
  return moment(endDate);
};

const NewEventForm = ({ event, blockInterpreterTime, unBlockInterpreterTime, updateBlockedIntepreterTime }) => {
  const { colors, layout } = useContext(ThemeContext);
  const context = useContext(AppContext);

  const [aStartDate, setStartDate] = useState(getStartTime({startDate: event.startsAt, endDate: event.endsAt, id: event.id}));
  const [anEndDate, setEndDate] = useState(getEndTime({startDate: event.startsAt, endDate: event.endsAt, id: event.id}));
  const [aType, setType] = useState(event.type === 'reservations_od' ? 'prebooking' : 'all');
  const [minSelectableDate] = useState(moment().add(1, 'hour').startOf('hour'));

  const prepareDate = (currentDate, newDate) => {
    const tmpDate = moment(newDate);
    const clonedDate = currentDate.clone();

    clonedDate.set('year', moment(tmpDate).get('year'));
    clonedDate.set('month', moment(tmpDate).get('month'));
    clonedDate.set('date', moment(tmpDate).get('date'));

    return clonedDate;
  };

  const processBlockIntepreterTime = async (startsAt, endsAt, type) => {
    await blockInterpreterTime(startsAt, endsAt, type);
  };

  const processUnBlockIntepreterTime = async (id, ) => {
    await unBlockInterpreterTime(id);
  };

  const processUpdateBlockedIntepreterTime = async (id, startsAt, endsAt, type) => {
    await updateBlockedIntepreterTime(id, startsAt, endsAt, type);
  };

  const options = [{value: 'all', label: i18n('block_time')}, {value: 'prebooking', label: i18n('reservations_od')}];

  const isAdmin = context?.user?.role === 'admin';
  const ODReservationDisabled = !isAdmin && aType === 'prebooking';

  return (
    <StyledContainer
      padding={layout.padding}
      colors={colors}
    >
      <GlobalStyle
        fontSize={layout.fontSize}
        mainColor={colors.tulkaMainColor}
        padding={layout.padding}
        borderColor={colors.webMediumGray}
        backgroundColor={colors.webLightGray}
      />
      <ContentBox
        overlapping={true}
      >
        <StyledLabel>{i18n('unavailability_type')}</StyledLabel>
        {isAdmin &&
            <Select options={options} defaultValue={options.find(option => option.value === aType)} onChange={(t)=>{
              setType(t.value);
            }}/>}
        {aType==='all' &&<StyledText>{i18n('block_time_description')}</StyledText>}
        {aType==='prebooking' && <StyledText>{i18n('reservations_od_description')}</StyledText>}
        <StyledLabel>{i18n('pick_start_time')}</StyledLabel>
        <StyledBlock>
          <Datepicker
            selected={new Date(aStartDate)}
            onChange={(value) => {
              if (value) {
                const preparedStartDate = prepareDate(aStartDate, value);
                if (preparedStartDate.isAfter(anEndDate)) {
                  const preparedEndDate = prepareDate(anEndDate, value);
                  setEndDate(getEndTime({startDate: preparedStartDate, endDate: preparedEndDate, id: event.id}));
                }
                setStartDate(preparedStartDate);
              }
            }}
            locale={langCode}
            minDate={new Date(minSelectableDate)}
            disabled={ODReservationDisabled}
          />
          <StyledTimePicker
            allowEmpty={false}
            fontSize={layout.fontSize}
            margin={layout.margin}
            padding={layout.padding}
            color={colors.webGray}
            mainColor={colors.tulkaMainColor}
            backgroundColor={colors.webLightGray}
            value={aStartDate}
            minuteStep={5}
            disabled={ODReservationDisabled}
            showSecond={false}
            onChange={(value) => {
              if (value.isAfter(moment(anEndDate).subtract(30, 'minutes'))) {
                setEndDate(getEndTime({startDate: value, endDate: anEndDate, id: event.id}));
              }
              setStartDate(value);
            }}
            popupStyle={{
              backgroundColor: colors.webLightGray
            }}
            hideDisabledOptions={true}
            inputReadOnly={true}
            disabledHours={() => getDisabledHours(aStartDate)}
            disabledMinutes={() => getDisabledMinutes(aStartDate)}
          />
        </StyledBlock>
        <StyledLabel>{i18n('pick_end_time')}</StyledLabel>
        <StyledBlock>
          <Datepicker
            selected={new Date(anEndDate)}
            onChange={(value) => {
              const preparedEndDate = prepareDate(anEndDate, value);
              let startDateToUse = aStartDate;
              if (preparedEndDate.isBefore(moment(aStartDate).add(30, 'minutes'))) {
                startDateToUse = getStartTime({startDate: aStartDate, endDate: preparedEndDate});
                setStartDate(startDateToUse);
              }
              setEndDate(getEndTime({startDate: startDateToUse, endDate: preparedEndDate, id: event.id}));
            }}
            locale={langCode}
            minDate={new Date(minSelectableDate)}
            disabled={ODReservationDisabled}
          />
          <StyledTimePicker
            allowEmpty={false}
            fontSize={layout.fontSize}
            margin={layout.margin}
            padding={layout.padding}
            color={colors.webGray}
            mainColor={colors.tulkaMainColor}
            backgroundColor={colors.webLightGray}
            value={anEndDate}
            minuteStep={5}
            showSecond={false}
            disabled={ODReservationDisabled}
            onChange={(value) => {
              let startDateToUse = aStartDate;
              if (moment(value).isBefore(moment(aStartDate).add(30, 'minutes'))) {
                startDateToUse = moment(value).subtract(30, 'minutes');
                setStartDate(startDateToUse);
              }
              setEndDate(getEndTime({startDate: startDateToUse, endDate: value, id: event.id}));
            }}
            popupStyle={{
              backgroundColor: colors.webLightGray
            }}
            hideDisabledOptions={true}
            inputReadOnly={true}
            disabledHours={() => getDisabledEndTimeHours(aStartDate, anEndDate)}
            disabledMinutes={() => getDisabledEndTimeMinutes(aStartDate, anEndDate)}
          />
        </StyledBlock>
      </ContentBox>
      <ButtonPanel>
        {
          event.id ? !ODReservationDisabled && (
              moment(event.endsAt).isAfter(moment().subtract(2,'hours')) &&
              (<>
                <RoundButton
                  darkButton
                  label={i18n('update')}
                  disabled={moment(event.startsAt).isSame(moment(aStartDate)) && moment(event.endsAt).isSame(moment(anEndDate))}
                  onClick={() => processUpdateBlockedIntepreterTime(event.id, aStartDate, anEndDate, aType)}
                  color={colors.tulkaMainColor}
                  padding={layout.buttonPadding}
                />
                <RoundButton
                  label={i18n('cancel_interpreter_blocked_time')}
                  onClick={() => processUnBlockIntepreterTime(event.id)}
                  color={colors.tulkaMainColor}
                  padding={layout.buttonPadding}
                  warning
                />
              </>)
          ) : (
            <RoundButton
              darkButton
              label={i18n('block_time')}
              onClick={() => processBlockIntepreterTime(aStartDate, anEndDate, aType)}
              color={colors.tulkaMainColor}
              padding={layout.buttonPadding}
            />
          )
        }
      </ButtonPanel>
    </StyledContainer>
  );
};

export default NewEventForm;
