import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import ThemeContext from '../contexts/ThemeContext';
import IconButton from './IconButton';
import Icon from './Icon';

const StyledIconContainer = styled.div`
  text-align: center;
  position: relative;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  flex: 0 1 auto;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;
  color: ${({ color }) => (color)};
  background-color: rgba(228, 228, 255);
  border-radius: 4px;
  font-size: ${({ fontSize }) => (fontSize)}px;
  & > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    & > li {
      padding: 8px 12px;
      white-space: nowrap;
      color: ${({ color }) => (color)};
      border-radius: 4px;
      & i {
        transition: none;
      }
      & > div {
        color: ${({ color }) => (color)};
      }
      &:hover {
        background-color: ${({ color }) => (color)};
        & i {
          color: #ffffff !important;
        }
        & div {
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
`;

const DropdownMenu = ({ icon, iconColor, hoverBackgroundColor, openPrebookPreview, row }) => {
  const { colors } = useContext(ThemeContext);
  const [isOpenActionMenu, setIsOpenActionMenu] = useState(false);
  const isCancelled = status => status === 'cancelled';
  const isGoingPrebook = (aPrebook) =>
    aPrebook.date - Date.now() <= (aPrebook.leewayBefore * 1000);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpenActionMenu(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
    document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return (
    <StyledIconContainer ref={ref} className="Dropdown">
      <IconButton
        icon={icon}
        iconLib="material"
        iconColor={iconColor}
        hoverBackgroundColor={hoverBackgroundColor}
        fontSize={10}
        onClick={() => {
          setIsOpenActionMenu(row?.original?.id);
        }}
        data-test='clickDropdownMenuButton'
      />
      {row?.original?.id === isOpenActionMenu && (
        <DropdownList
          color={colors.tulkaMainColor}
          hoverColor={colors.webDarkBlue}
          data-test='prebookDropdownMenu'
        >
          <ul>
          {!isCancelled(row?.original?.status) && (
            <li onClick={() => {
              openPrebookPreview(row, {
                cloning: false,
                editMode: true,
                cansel: false
              });
              setIsOpenActionMenu(false);
            }}
              data-test='changeReservationDescriptionClick'
            >
            <StyledRow>
              <StyledIcon
                icon="edit"
                iconLib="material"
                iconColor={colors.tulkaMainColor}
                hoverColor="#ffffff"
                iconSize={18}
              />
              <div className="DropdownItem" data-test='changeReservationDescriptionText'>{i18n('change_reservation_description')}</div>
            </StyledRow>
            </li>
          )}
            <li onClick={() => {
              openPrebookPreview(row,{
                cloning: true,
                editMode: false,
                cansel: false
              });
              setIsOpenActionMenu(false);
            }}
              data-test='copyReservationDescriptionClick'
            >
            <StyledRow>
              <StyledIcon
                icon="content_copy"
                iconLib="material"
                iconColor={colors.tulkaMainColor}
                hoverColor="#ffffff"
                iconSize={18}
              />
              <div className="DropdownItem" data-test='copyReservationDescriptionText'>{i18n('copy_reservation_description')}</div>
            </StyledRow>

            </li>
            {(!isCancelled(row?.original?.status) && !isGoingPrebook(row?.original) && row?.original?.callType !== 'notice') && (
              <li onClick={() => {
                openPrebookPreview(row, {
                  cloning: false,
                  editMode: false,
                  cansel: true
                });
                setIsOpenActionMenu(false);
              }}
                data-test='cancelPrebookDescriptionClick'
              >
              <StyledRow>
                <StyledIcon
                  icon="delete_forever"
                  iconLib="material"
                  iconColor={colors.tulkaMainColor}
                  hoverColor="#ffffff"
                  iconSize={18}
                />
                <div className="DropdownItem" data-test='cancelPrebookDescriptionText'>{i18n('cancel_prebook_description')}</div>
              </StyledRow>
              </li>
            )}
          </ul>
        </DropdownList>
      )}
    </StyledIconContainer>
  );
};

export default DropdownMenu;
