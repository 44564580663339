import React, { useState } from 'react';
const moment = require('moment');
import i18n from '../i18n';
import { WizardList, WizardItem } from '../components/WizardList';
import { WizardStepContainer } from './WizardStep';

const UrgencyStep = ({ selectedTime, handleNext }) => {
  const [avaliableTimeSlots] = useState([
    {
      value: 'help_need_today',
      label: i18n('call_now'),
      date: moment()
    },
    {
      value: 'help_need_in_couple_of_days',
      label: i18n('book_an_appointment'),
      date: moment().add(1, 'days')
    }
  ]);

  return (
    <WizardStepContainer>
      <WizardList data-test='wizardListForPrebookingStep'>
        {avaliableTimeSlots.map(time => (
            <WizardItem
              data-test={`wizardItem-${time.value}`}
              key={time.value}
              selectable={time.value === selectedTime?.value}
              label={time.label}
              handleNext={() => handleNext(time)}
            />
        ))}
      </WizardList>
    </WizardStepContainer>
  );
};

export default UrgencyStep;
