import React, { useContext } from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import ThemeContext from '../contexts/ThemeContext';

import i18n from '../i18n';

import PrebookTypeIcon from './PrebookTypeIcon';

const moment = extendMoment(Moment);

const StyledEventContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

const StyledEventRow = styled.div`
  flex: 0 1 auto;
  display: flex;
  justify-content: ${({ noSpaceBettwen }) => noSpaceBettwen ? 'flex-start' : 'space-between'};
  align-items: center;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledEventText = styled.span`
  padding: ${({ padding }) => `0 ${padding}px`};
  color: ${({ eventTextColor }) => (eventTextColor)};
`;

const getTitle = (minutes) => {
  if (minutes < 60) {
    return i18n('minutes').replace('{}', minutes);
  }
  if (minutes === 60) {
    return i18n('hour').replace('{}', minutes / 60);
  }
  if (minutes % 60 === 0) {
    return i18n('hours').replace('{}', minutes / 60);
  }
  if (minutes / 60 < 2) {
    return i18n('hour_minutes').replace('{h}', Math.floor(minutes / 60)).replace('{m}', minutes % 60);
  }
  return i18n('hours_minutes').replace('{h}', Math.floor(minutes / 60)).replace('{m}', minutes % 60);
};

const CalendarEvent = ({
  title,
  start,
  end,
  type,
  simpleItem,
  agendaItem,
  noSpaceBettwen,
  booking,
  slotStart,
  slotEnd,
}) => {
  const { colors } = useContext(ThemeContext);

  const textColor = agendaItem ? colors.webDarkBlue : colors.webTextColor;

  const getTitleDependOnRange = (aSlotStart, aSlotEnd, aStart, anEnd) => {
    if (moment(aStart).isSame(moment(anEnd), 'day')) {
      return `${moment(new Date(aStart)).format('HH:mm')} - ${moment(new Date(anEnd)).format('HH:mm')}`;
    }
    if (moment(aStart).isSame(moment(anEnd), 'isoWeek')) {
      return `${moment(new Date(aStart)).format('DD/MM/YYYY HH:mm')} - ${moment(new Date(anEnd)).format('DD/MM/YYYY HH:mm')}`;
    }
    const slotRange = moment().range(aSlotStart, aSlotEnd);
    if (slotRange.contains(moment(new Date(aStart)).toDate())) {
      return `${moment(new Date(aStart)).format('DD/MM/YYYY HH:mm')} »`;
    }
    if (slotRange.contains(moment(new Date(anEnd)).toDate())) {
      return `« ${moment(new Date(anEnd)).format('DD/MM/YYYY HH:mm')}`;
    }
    return '';
  };
  const unavailability = type === 'unavailability' || type=== 'reservations_od';
  if (unavailability) {
    return (
      <StyledEventContainer
        eventTextColor={textColor}
      >
        {
          !simpleItem ? (
            <StyledEventRow>
              <StyledEventText
                eventTextColor={textColor}
              >
                {getTitleDependOnRange(slotStart, slotEnd, start, end)}
              </StyledEventText>
            </StyledEventRow>
          ) : null
        }
        <StyledEventRow>
          <StyledEventText
            eventTextColor={textColor}
          >
            {title}
          </StyledEventText>
        </StyledEventRow>
      </StyledEventContainer>
    );
  }
  const callType = unavailability ? '' : ((booking.presence === 'onsite' || booking.presence === 'notice' || (booking.presence === 'external' && booking.callType === 'data')) ? booking.presence : booking.callType);
  if (simpleItem) {
    return (
      <StyledEventContainer
        eventTextColor={textColor}
      >
        <StyledEventRow
          noSpaceBettwen
        >
          <PrebookTypeIcon
            color={textColor}
            type={callType}
          />
          <StyledEventText
            padding={noSpaceBettwen ? 0 : 8}
            eventTextColor={textColor}
          >
            {title}
          </StyledEventText>
        </StyledEventRow>
      </StyledEventContainer>
    );
  }

  return (
    <StyledEventContainer>
      <StyledEventRow>
        <StyledEventText
          eventTextColor={textColor}
        >
          {moment(new Date(booking?.interpretationDate)).format('HH:mm')}
        </StyledEventText>
        <StyledEventText
          padding={noSpaceBettwen ? 0 : 8}
          eventTextColor={textColor}
        >
          {title}
        </StyledEventText>
      </StyledEventRow>
      <StyledEventRow>
        <PrebookTypeIcon
          color={textColor}
          type={callType}
        />
        <StyledEventText
          eventTextColor={textColor}
        >
          {getTitle(booking?.durationEstimated / 60)}
        </StyledEventText>
      </StyledEventRow>
    </StyledEventContainer>
  );
};

export default CalendarEvent;
