import React, { useContext } from 'react';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';

import i18n from '../i18n';

import IconButton from './IconButton';

import ThemeContext from '../contexts/ThemeContext';

const StyledContainer = styled.div`
  width: 30px;
  border-radius: 30px;
  z-index: 9999;
  opacity: ${({ opacity }) => (opacity || 1.0)};
  flex: 1;
  display: flex;
  justify-content: flex-end;
  ${({ alignBottom }) => (alignBottom ? 'align-items: flex-end;' : '')}
`;

const StyledTooltipHeader = styled.div`
  color: white;
  line-height: ${({ fontSize }) => (fontSize + 4)}px;
  text-align: left;
  font-size: ${({ fontSize }) => (fontSize)}px;
  margin-bottom: 10px;
`;

const StyledTooltipText = styled.div`
  color: white;
  font-size: ${({ fontSize }) => (fontSize)}px;
  line-height: ${({ fontSize }) => (fontSize + 2)}px;
  text-align: left;
  font-weight: normal;
`;

const StyledTooltipContainer = styled.div`
  width: 100%;
  max-width: 300px;
`;

const StyledTooltip = styled(Tooltip)`
  display: flex;
`;

const HelpButton = ({
  helpId,
  color,
  alignBottom
}) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  return (
    <StyledContainer
      alignBottom={alignBottom}
    >
      <StyledTooltip
        content={(
          <StyledTooltipContainer
            padding={layout.padding}
          >
            <StyledTooltipHeader
              fontSize={layout.headerFontSize}
            >
              {i18n(`help_${helpId}_title`)}
            </StyledTooltipHeader>
            <StyledTooltipText
              fontSize={layout.fontSize}
            >
              {i18n(`help_${helpId}`)}
            </StyledTooltipText>
          </StyledTooltipContainer>
        )}
        background={colors.tulkaMainColor}
        className="target"
        direction="left-start"
      >
        <IconButton
          icon={'help_outline'}
          iconLib={'material'}
          color={color}
          data-tip="React-tooltip"
          data-event="click"
        />
      </StyledTooltip>
    </StyledContainer>
  );
};

export default HelpButton;
