import { AnalyticsBrowser } from '@segment/analytics-next';
import { isLuonaHost } from '../utils/luonaSpecificData';

const analytics = new AnalyticsBrowser();
let loaded = false;

export const analyticLoading = () => {
  if (!loaded) {
    try {
      analytics
        .load({ writeKey: 'qGhOllyizxagNfwi28PdRI1iDXEr8Eif' })
        .then(() => {
          loaded = true;
        })
        .catch((err) => {
          console.log('analyticInit error: ', err);
        });
    } catch (error) {
      console.log(error);
    }
  }
};

export const analyticsSetUser = (user) => {
  try {
    analytics.identify(user.id, {
       name: user.id,
       brand: isLuonaHost() ? 'luona' : 'tulka',
       type: user.type,
       userId: user.id
     });
  } catch (error) {
    console.log(error);
  }
};

export const logAnalytics = async (event, params) => {
  if (event && params) {
    analytics.track(event, params);
  } else {
    analytics.track(event);
  }
};

export const changePage = async (url) => {
  analytics.page(url);
};
