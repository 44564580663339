import styled from 'styled-components';
import Header from '../components/HeaderTop';

const MainContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const withHeader = (Component) => {

  const WrapperComponent = (props) => {

    return (
      <MainContent>
        <Header></Header>
        <Component
          {...props}
        />
      </MainContent>
    );
  };

  return WrapperComponent;
};

export default withHeader;
