import React, { useContext, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import Modal from 'react-modal';
import BillingTable from '../components/BillingTable';
import withLeftSideBar from '../hocs/withLeftSideBar';
import PrebookSummary from '../components/PrebookSummary';
import IconButton from '../components/IconButton';
import ConfirmationDialog from '../components/ConfirmationDialog';
import ModalDialogCloseButton from '../components/ModalDialogCloseButton';
import { RoundButton } from '../components/Buttons';
import Spinner from '../components/Spinner';
import {
  MainHeader,
  MainContentTopBar,
  MainContentTopBarButtons,
  ContainerWrapper,
} from '../components/basicComponents';

import i18n from '../i18n';

import {
  confirmPrebookingBilling,
} from '../services/prebookService';
import {
  confirmOndemand,
} from '../services/invoiceService';

Modal.setAppElement('#main');

const MainSubHeader = styled.div`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: .5px;
  margin-top: 15px;
  color: ${({ color }) => color};
`;

const StyledBillingContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
`;

const amplitudeInstance = amplitude.getInstance();
const amplitudeLogEvent = (title, obj) => {
  amplitudeInstance.logEvent(title, obj);
};

const Billing = ({ toggleSideBar, sidebarDocked, ...rest }) => {
  const { user } = useContext(AppContext);
  const { colors, layout } = useContext(ThemeContext);
  const {
    setBusy,
    fetchLanguageList,
    sessionExpired
  } = useContext(FunctionsContext);

  const [indicator, setIndicator] = useState(false);

  const [editableFields, setEditableField] = useState(['InvoiceTargeting', 'CustomerReference']);
  const [openForEdit, setOpenForEdit] = useState(false);

  const [itemsForVerify, setItemsForVerify] = useState([]);

  const [prebook, setPrebook] = useState(null);
  const [prebookPreviewIsOpen, setPrebookPreviewIsOpen] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [confimationDialod, setConfimationDialod] = useState('');
  const [prebookPreviewModal] = useState({
    message: i18n('your_changes_will_be_lost'),
    buttons: [],
    close: (aPrebook) => {
      amplitudeLogEvent('Cancel updating billing of prebook confirmed', {
        'prebooking id': aPrebook.id
      });
      closePrebookPreview();
    },
    notConfirmed: (aPrebook) => {
      amplitudeLogEvent('Cancel updating billing of prebook rejected', {
        'prebooking id': aPrebook.id
      });
    }
  });

  const updateAction = {
    ondemand: confirmOndemand,
    prebook: confirmPrebookingBilling
  };
  const confimationSettings = {
    prebookPreviewModal: prebookPreviewModal
  };

  const modalDialogStyles = {
    overlay: {
      zIndex: 999,
      backgroundColor: colors.webOverlay
    },
    content: {
      width: '100%',
      maxWidth: '660px',
      height: '100%',
      maxHeight: '750px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      borderRadius: '15px',
      border: 'none',
      overflow: 'initial',
      display: 'flex'
    }
  };

  function openPrebookPreview(aPrebook, editable = false) {
    setPrebook(aPrebook);
    if (aPrebook.presence === 'notice') {
      setEditableField(['InvoiceTargeting', 'CustomerReference', 'CustomerInfo']);
    } else {
      setEditableField(['InvoiceTargeting', 'CustomerReference']);
    }
    setOpenForEdit(editable);

    setBusy(true);
    setPrebookPreviewIsOpen(true);
  }

  function closePrebookPreview(reloadTable) {
    setPrebookPreviewIsOpen(false);
    setBusy(false);
    setOpenForEdit(false);
    setEditableField(['InvoiceTargeting', 'CustomerReference']);
    if (reloadTable === true) {
      document.dispatchEvent(new CustomEvent('reloadBillingPrebooksTable'));
    }
  }

  function openConfirmationModal(name) {
    setConfimationDialod(name);
    setConfirmationDialogIsOpen(true);
  }

  function closeConfirmationModal(confirmed) {
    setConfirmationDialogIsOpen(false);
    setConfimationDialod('');
    if (confirmed) {
      confimationSettings[confimationDialod].close(prebook);
    } else if (!confirmed && confimationSettings[confimationDialod].notConfirmed) {
      confimationSettings[confimationDialod].notConfirmed(prebook);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        await fetchLanguageList();
      } catch (e) {
        if (e.message === 'Already fetching') {
          return;
        }
      }
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!prebookPreviewIsOpen) {
      setPrebook(null);
    }
  }, [prebookPreviewIsOpen]);

  const confirmPrebooking = async () => {
    setIndicator(true);
    try {
      await Promise.all(itemsForVerify.map((prebookObj) => {
        const action = updateAction[prebookObj.type];
        return action(
          prebookObj.id
        );
      }));
      document.dispatchEvent(new CustomEvent('reloadBillingPrebooksTable'));
      setIndicator(false);
    } catch (e) {
      if (e.message === 'Session expired') {
        sessionExpired();
      }
      setIndicator(false);
    }
  };

  const isPrebookingActive = useMemo(() => {
    return user.isPrebookingLanguages;
  }, [user.isPrebookingLanguages]);

  return (
    <StyledBillingContainer {...rest}>
      <MainContentTopBar
        borderBottomColor={colors.webDeviderColor}
      >
        <Modal
          isOpen={confirmationDialogIsOpen}
          style={{
            ...modalDialogStyles,
            overlay: {
              backgroundColor: 'transparent',
              zIndex: 9999
            }
          }}
        >
          <ConfirmationDialog
            message={confimationSettings[confimationDialod] ? confimationSettings[confimationDialod].message : ''}
            closeModal={closeConfirmationModal}
          />
        </Modal>
        <Modal
          isOpen={prebookPreviewIsOpen}
          onRequestClose={closePrebookPreview}
          style={modalDialogStyles}
          contentLabel="Prebooking view"
        >
          <ModalDialogCloseButton
            onClick={() => closePrebookPreview()}
          />
          <PrebookSummary
            prebook={prebook}
            isBillingSummary={true}
            editableFields={openForEdit ? editableFields : []}
            closeModal={closePrebookPreview}
            previewOnly={true}
            confirmationDialog={() => openConfirmationModal('prebookPreviewModal')}
          />
        </Modal>
        {sidebarDocked ? null : (<IconButton
          icon={'menu'}
          iconLib={'material'}
          iconColor={colors.tulkaMainColor}
          onClick={toggleSideBar}
          data-tip="React-tooltip"
          data-event="click"
        />)}
        <MainHeader
          sidebarDocked={sidebarDocked}
          padding={layout.padding}
          color={colors.webDarkBlue}
        >
          {i18n('billing')}
          <MainSubHeader
            color={colors.webGray}
          >
            {i18n('check_that_the_interpretation_is_for_the_correct_cost_center')}
          </MainSubHeader>
        </MainHeader>
        <MainContentTopBarButtons>
          <RoundButton
            disabled={!itemsForVerify.length > 0}
            label={i18n('verify')}
            onClick={() => {
              confirmPrebooking();
            }}
            smallButton={true}
            darkButton={false}
          />
        </MainContentTopBarButtons>
      </MainContentTopBar>
      <ContainerWrapper>
        <BillingTable
          active={isPrebookingActive}
          openPreview={openPrebookPreview}
          setItemsForVerify={setItemsForVerify}
        />
      </ContainerWrapper>
      {indicator ? (
        <Spinner
          overlay
        />
      ): null}
    </StyledBillingContainer>
  );
};

export default withLeftSideBar(Billing);
