import moment from 'moment';

export const roundToNextMin = (date, minutes = 5) => {
  let updatedDate = date.clone().add(minutes, 'minutes');
  updatedDate.minutes(Math.floor(updatedDate.minutes() / minutes) * minutes);
  return updatedDate;
};

export const getStartTime = (date, time, minutes) => {
  const today = moment();
  const updatedTime = moment(date || moment());
  updatedTime.set('hour', moment(time || moment()).get('hour'));
  updatedTime.set('minute', moment(time || moment()).get('minute'));
  updatedTime.set('second', moment(time || moment()).get('second'));
  updatedTime.set('millisecond', moment(time || moment()).get('millisecond'));

  if (moment(date).isSame(today, 'day') && moment(updatedTime).isBefore(moment().add(2, 'hours'))) {
    return roundToNextMin(moment().add(2, 'hours'), minutes);
  }
  return time ? moment(updatedTime) : roundToNextMin(moment(), minutes);
};

export const getMinSelectableDate = (presence) => {
  if (presence !== 'onsite' && presence !== 'notice') {
    return moment().toDate();
  }
  if (presence === 'notice') {
    const today3pm = moment().set('hour', 15).set('minute', 0).set('second', 0);
    if (moment().isAfter(today3pm)) {
      return moment().add(1, 'day').toDate();
    }
    return moment().toDate();
  }
  const today2pm = moment().set('hour', 14).set('minute', 0).set('second', 0);
  if (moment().isAfter(today2pm)) {
    return moment().add(2, 'day').toDate();
  }
  return moment().add(1, 'day').toDate();
};


export const format = (secs) => {
  let hours = Math.floor(Math.floor(secs / 3600));
  let minutes = Math.floor(Math.floor((secs - (hours * 3600)) / 60));
  let seconds = Math.floor(secs - (hours * 3600) - (minutes * 60));

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return (hours > 0 ? (hours + ':') : '') + minutes + ':' + seconds;
};

export const isFirstTimeBeforeSecond = (firstTime, secondTime) => {
  const updatedTime = moment(firstTime);
  updatedTime.set('year', moment(secondTime).get('year'));
  updatedTime.set('month', moment(secondTime).get('month'));
  updatedTime.set('day', moment(secondTime).get('day'));

  return updatedTime.isBefore(moment(secondTime));
};

export const isToday = (dateString) => moment(dateString).isSame(new Date(), 'day');
