import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import ThemeContext from '../contexts/ThemeContext';
import AppContext from '../contexts/AppContext';
import i18n from '../i18n';

import ActionList from './ActionList';
import ActionListItem from './ActionListItem';
import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import { RoundButton } from './Buttons';
import ButtonPanel from './ButtonPanel';
import { Input } from './Input';
import Spinner from './Spinner';

import { cancelPrebooking } from '../services/prebookService';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding-top: 24px;
`;

const MessageContainer = styled.div`
  padding-top: 30px;
  flex: 1;
`;

const ContainerStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.span`
  line-height: ${({ fontSize }) => (fontSize + 4)}px;
  text-align: left;
  font-size: ${({ fontSize }) => (fontSize)}px;
  text-align: center;
`;

const CancelPrebookingDialog = ({ onBack, prebook, closeModal }) => {
  const {
    colors,
    layout,
  } = useContext(ThemeContext);
  const { user } = useContext(AppContext);

  const reasons = [{
      title: 'cancel_reason_sickness',
      subtitle: 'cancel_sickness_tip',
      category: 'sickness',
      icon: 'sick',
      iconLibrary: 'material',
      visible: true
    }, {
      title: 'cancel_reason_other',
      category: 'other',
      icon: 'cancel',
      iconLibrary: 'material',
      visible: true
  }];

  const [indicator, setIndicator] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [reason, setReason] = useState('');
  const [viewState, setViewState] = useState('category');

  const getTitle = () => {
    if (viewState === 'category') {
      return i18n('cancel_reason_selection');
    }
    if (viewState === 'reason') {
      return i18n('cancel_other_title');
    }
    if (viewState === 'message') {
      return i18n('cancel_sickness_title');
    }
    return '';
  };

  const onCancelPrebook = async () => {
    setIndicator(true);
    try {
      await cancelPrebooking(prebook.id, { reason, category: selectedCategory, userType: user.type });
      document.dispatchEvent(new CustomEvent('prebookCancelledInterpreter', {
        detail: {
          id: prebook?.id,
          reason,
          category: selectedCategory
        }
      }));
      setIndicator(false);
      closeModal();
    } catch (e) {
      setIndicator(false);
      closeModal();
    }
  };

  const onChooseCategory = async () => {
    if (selectedCategory === 'other') {
      return setViewState('reason');
    }
    if (selectedCategory === 'sickness') {
      return setViewState('message');
    }
    return await onCancelPrebook();
  };

  return (
    <StyledContainer>
      <ChatWizardHeader
        title={getTitle()}
      />
      <ContentBox
      >
        {(() => {
          switch (viewState) {
            case 'category':
              return (
                <ContainerStyled>
                  <ActionList>
                  {
                    reasons.map((item) => (
                      item.visible && <ActionListItem
                        key={item.reason}
                        title={i18n(item.title)}
                        subtitle={item.subtitle ? i18n(item.subtitle) : ''}
                        icon={item.icon}
                        iconLib={'material'}
                        selected={selectedCategory === item.category}
                        onClick={() => setSelectedCategory(item.category)}
                      />
                    ))
                  }
                  </ActionList>
                  <ButtonPanel>
                    <RoundButton
                      label={i18n('back')}
                      onClick={() => onBack() }
                      primary
                      color={colors.tulkaMainColor}
                      padding={layout.buttonPadding}
                    />
                    <RoundButton
                      label={i18n('choose_button')}
                      onClick={() => onChooseCategory()}
                      primary
                      darkButton
                      color={colors.tulkaMainColor}
                      padding={layout.buttonPadding}
                      disabled={!selectedCategory}
                    />
                </ButtonPanel>
              </ContainerStyled>
            );
            case 'reason':
              return (
                <ContainerStyled>
                  <MessageContainer>
                    <Input
                      title={i18n('cancel_other')}
                      required={true}
                      noDelay={true}
                      notBoldInputTitle
                      type="text"
                      value={reason}
                      onChange={(value) => setReason(value)}
                    />
                  </MessageContainer>
                  <ButtonPanel>
                    <RoundButton
                      label={i18n('back')}
                      onClick={() => setViewState('category') }
                      primary
                      color={colors.tulkaMainColor}
                      padding={layout.buttonPadding}
                    />
                    <RoundButton
                      label={i18n('proceed')}
                      onClick={() => onCancelPrebook()}
                      primary
                      darkButton
                      color={colors.tulkaMainColor}
                      padding={layout.buttonPadding}
                      disabled={reason.trim()?.length === 0}
                    />
                </ButtonPanel>
              </ContainerStyled>
            );
            case 'message':
              return (
                <ContainerStyled>
                  <ContentBox>
                    <StyledHeader
                    fontSize={layout.bigFontSize}
                    >
                      {i18n('cancel_sickness')}
                    </StyledHeader>
                  </ContentBox>
                  <ButtonPanel>
                    <RoundButton
                      label={i18n('back')}
                      onClick={() => setViewState('category') }
                      primary
                      color={colors.tulkaMainColor}
                      padding={layout.buttonPadding}
                    />
                    <RoundButton
                      label={i18n('proceed')}
                      onClick={() => onCancelPrebook()}
                      primary
                      darkButton
                      color={colors.tulkaMainColor}
                      padding={layout.buttonPadding}
                    />
                  </ButtonPanel>
                </ContainerStyled>
              );
            default:
              return null;
          }
        })()}
      </ContentBox>
      {indicator ? (
        <Spinner
          overlay
        />
      ): null}
    </StyledContainer>
  );
};

export default CancelPrebookingDialog;
