import requestService from './requestService';

const endpoints = {
  signup: 'signup',
  identity: 'identity'
};

export const getUserData = (id) => {
  try {
    const data = requestService.get(`${endpoints.signup}`, `id=${id}`, {
      apiType: 'platform',
    });
    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const postUserData = (userData) => {
  try {
    const data = requestService.postWithJson(
      `${endpoints.signup}`,
      null,
      userData,
      {apiType: 'platform'}
    );
    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const putUserData = (userData) => {
  try {
    const data = requestService.putWithJson(
      `${endpoints.signup}`,
      null,
      userData,
      { apiType: 'platform' }
    );
    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getIdentity = async(key) => {
  try {
    const data = await requestService.postWithJson(
      `${endpoints.identity}`,
      null,
      {key: key},
      { apiType: 'platform' }
    );
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getSessionId = async (key) => {
  const sessionId = localStorage.getItem('luonaId');
  if (!sessionId && key) {
    const res = await getIdentity(key);
    const { chatId, ...rest } = res;
    localStorage.setItem('luonaId', chatId);
    localStorage.setItem('user', JSON.stringify(rest));
    if (chatId) {
      localStorage.setItem('luonaId', chatId);
    }
    return chatId;
  }
  return sessionId;
};

export const getSessionData = async (key) => {
  const id = await getSessionId(key);
  if (id) {
    const sessionData = await getUserData(id);
    return sessionData;
  }
  return {};
};
