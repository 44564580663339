import { useCallback, useContext } from 'react';
import AppContext from '../contexts/AppContext';
import i18n from '../i18n';
import { logAnalytics } from '../services/analyticsLogger';
import { showError } from '../services/toastService';

export default function useBlurEffects() {
  const { user } = useContext(AppContext);

  const toggleBlurEffect = useCallback(async (camera, isBlure) => {
    if (camera && isBlure) {
      try {
        await camera.setVideoFilter({ type: 'backgroundBlur', blurStrength: 'high'});
      } catch (error) {
        logAnalytics('Error set blur from VideoExpress', {
          userId: user?.id,
          error: error.name
        });
        if (error.name === 'OT_NOT_SUPPORTED') {
          showError(i18n('browser_does_not_support_video_effects'));
        }
      }
    }
    if (camera && !isBlure) {
      await camera.clearVideoFilter();
    }
  }, [user?.id]);

  return {
    toggleBlurEffect,
  };
}
