import React from 'react';
import reactMixin from 'react-mixin';
import TimerMixin from 'react-timer-mixin';
import amplitude from 'amplitude-js';
import PropTypes from 'prop-types';

import i18n from '../i18n';
import {showInfo, showError, showSuccess, showQuestion} from '../utils/msgbox'; //eslint-disable-line no-unused-vars
import CallActions from '../components/CallActions';
import CallOverlay from '../components/callOverlay';
import CallJoinTimers from '../components/CallJoinTimers';
import Header from '../components/header';
import { opentok } from '../OpenTok';
import OpenTokStats from '../OpenTokStats';
import Ids from '../utils/ids';
import extranetPagesConfigurator from '../utils/extranetPagesConfigurator';

import AppContext from '../contexts/AppContext';
import FunctionsContext from '../contexts/FunctionsContext';

import {
  interpretationStillNeeded,
  endChatRoom,
} from '../services/chatRoomService';

const getHomePaths = (user) => {
  if (user?.type === 'Interpreter') {
    return '/';
  }
  const pages = extranetPagesConfigurator(user);
  if (pages && pages.length > 0) {
    return '/booking';
  }
  return '/';
};

class VideoChat extends React.Component {
  constructor(props) {
    super(props);

    opentok.restartApp = this.props.functionsContext.restartApp;

    this.ivWaiting = null;

    this.state = {
      landscape: this.props.location.state.landscape,
      callerName: this.props.location.state.callerName || '',
      languageName: this.props.location.state.languageName || '',
      waitedSeconds: 0,
      waitingForInterpreterSince: Date.now(),
      secondsLeft: this.props.location.state.secondsLeft || 0,
      timeLeft: 0,
      firstDuration: 0,
      firstPollAt: null,
      duration: 0,
      billableDuration: 0,
      lastPollAt: null,
      badConnection: false,
      endCall: null,
      unreadCount: 0,
      otherPartyJoinedTextChat: false
    };

    this.roomId = this.props.location.state.roomId;
    this.apiKey = props.location.state.apiKey;
    this.chatToken = props.location.state.chatToken;
    this.sessionId = props.location.state.sessionId;

    this.instanceLocator = this.props.location.state.textChatInstanceLocator;
    this.textChatRoomId = this.props.location.state.textChatRoomId;

    this.openTokStats = new OpenTokStats();
    this.lastStatFlush = null;
    this.endCallDisplayed = false;
    this.endCallClicked = false;

    this.ivDuration = null;
    this.lastInterpreterStillNeededSent = Date.now();
    this.ivHideBadConnectionLabel = null;
    this.answered = false;
    this.firstStatsSent = false;
  }

  async clearIntervalSafe(interval) {
    try {
      this.clearInterval(interval);
    } catch (e) {
      console.error(e);
    }
  }

  async clearTimeoutSafe(timer) {
    try {
      this.clearTimeout(timer);
    } catch (e) {
      console.error(e);
    }
  }

  componentDidUpdate() {
    if (this.state.endCall !== null) {
      opentok.disconnect();
      this.endCall(i18n((this.state.endCall === true ? 'oppositeSideHangsUp' : 'minutesConsumed')));
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.location || !nextProps.location.state) {
      return null;
    }

    const event = nextProps.location.state.event;

    if (event) {
      if (event.noMinutesLeft || event.isClosed) {
        amplitude.getInstance().logEvent('No prepaid minutes left');
        return {endCall: event.isClosed};
      } else {
        if (!prevState.firstPollAt) {
          return {
            firstDuration: event.billableDuration,
            firstPollAt: Date.now(),
            lastPollAt: Date.now(),
            secondsLeft: event.secondsLeft || 0,
            duration: event.billableDuration
          };
        }

        return {
          lastPollAt: Date.now(),
          secondsLeft: event.secondsLeft || 0,
          duration: event.billableDuration
        };
      }
    }

    return null;
  }

  isMieli() {
    const user = this.props.appContext.user;

    return (user.type === 'Customer' && user.userGroup.businessType.id === Ids.businessTypes.mieli) ||
      (user.type === 'Interpreter' && user.businessTypes[0].id === Ids.businessTypes.mieli);
  }

  async componentDidMount() {
    try {
      this.ivWaiting = this.props.appContext.user.type === 'Customer' ? this.setInterval(() => {
        if (Date.now() - this.lastInterpreterStillNeededSent >= 30 * 1000) {
          console.log('Interpretation still needed!');

          interpretationStillNeeded(this.roomId).catch((e) => {
            console.log('Error', e);
          });
          this.lastInterpreterStillNeededSent = Date.now();
        }

        this.setState({
          waitedSeconds: (Date.now() - this.state.waitingForInterpreterSince) / 1000
        });
      }, 1000) : null;

      opentok.setCallbacks({
        changeOrientation: landscape => {
          this.setState({landscape});
        },
        audioPacketLoss: () => {
          this.showBadConnectionLabel();
          amplitude.getInstance().logEvent('Bad connection');
        },
        connected: callerName => {
          localStorage.setItem('roomId', this.roomId);

          this.clearIntervalSafe(this.ivWaiting);
          this.props.functionsContext.postToWorker(
            'startBillablePolling', {
            roomId: this.roomId,
            camera: false
          });

          this.ivDuration = this.setInterval(async () => {
            if (this.state.lastPollAt > 0) {
              let billableDuration = this.state.firstDuration + ((Date.now() - this.state.firstPollAt) / 1000);

              // If the duration returned from the backend is significantly smaller than
              // the calculated one then use the returned one.
              if (this.state.duration > 0 && billableDuration - this.state.duration > 10) {
                billableDuration = this.state.duration;
              }

              this.setState({
                billableDuration,
                timeLeft: Math.max(this.state.secondsLeft - ((Date.now() - this.state.lastPollAt) / 1000), 0)
              });

              this.sendStats();
            }
          }, 1000);

          //if (this.props.context.user.type === 'Interpreter') {
            opentok.sendSignal('LANDSCAPE');
          //}

          this.setState({
            callerName: callerName || this.state.callerName,
            waitedSeconds: 0
          });

          this.ivWaiting = null;
          this.answered = true;

          try {
            if (document.body.fullscreenEnabled && document.body.fullscreenElement === null && document.body.requestFullscreen) {
              document.body.requestFullscreen();
            } else if (document.body.fullscreenEnabled && document.fullscreenElement === null && document.body.webkitRequestFullScreen) {
              document.body.webkitRequestFullScreen();
            } else if (document.body.fullscreenEnabled && document.fullscreenElement === null && document.body.mozRequestFullScreen) {
              document.body.mozRequestFullScreen();
            }
          } catch (e) {
            console.error(e);
          }

          amplitude.getInstance().logEvent('Customer and interpreter connected');
        },
        failedToConnect: async () => {
          this.endCallDisplayed = true;
          await showError(i18n('error500'));
          this.endCall();

          amplitude.getInstance().logEvent('OpenTok failed to connect');
        },
        disconnected: async () => {
          localStorage.removeItem('roomId');

          if (!this.endCallClicked && !this.endCallDisplayed) {
            amplitude.getInstance().logEvent('Other party disconnected from interpretation');

            document.getElementById('disconnectSound').play().catch(e => console.error); //eslint-disable-line no-unused-vars
            this.endCall(i18n('oppositeSideHangsUp'));
          } else {
            this.endCall();
          }
        },
        networkTerminated: async () => {
          localStorage.removeItem('roomId');

          amplitude.getInstance().logEvent('Interpretation disconnected due to network');

          document.getElementById('disconnectSound').play().catch(e => console.error); //eslint-disable-line no-unused-vars
          this.endCall(i18n('networkTerminated'));
        },
        reconnecting: () => {
          this.props.functionsContext.postToWorker('reconnecting');
        },
        reconnected: () => {
          this.props.functionsContext.postToWorker('reconnected');
          amplitude.getInstance().logEvent('OpenTok reconnected');
        },
        subscriberDidConnectToStream: () => {
          this.answered = true;
          amplitude.getInstance().logEvent('Other party connected to interpretation');
        }
      });

      await opentok.createOTSession(this.apiKey, this.chatToken, this.sessionId, !this.isMieli());
    } catch (e) {
      console.error(e);
      await this.endCall();
    }

    window.onfocus = null;

    window.onblur = () => {
      amplitude.getInstance().logEvent('App to background');
    };

  }

  componentWillUnmount() {
    window.onblur = null;
  }

  async sendStats() {
    try {
      const stats = await opentok.getStats();
      if (stats) {
        if (stats.audio) {
          const audioPacketLossRatio = stats.audio.packetsLost / (stats.audio.packetsLost + stats.audio.packetsReceived);

          if (audioPacketLossRatio > 0.03 && this.openTokStats.hasNewAudioPacketLoss({packetsLost: stats.audio.packetsLost})) {
            this.showBadConnectionLabel();
            opentok.reportAudioPacketLoss();
          }

          this.openTokStats.reportAudioStats({
            timestamp: stats.timestamp,
            bytesReceived: stats.audio.bytesReceived,
            packetsLost: stats.audio.packetsLost,
            packetsReceived: stats.audio.packetsReceived
          });

          if (!this.firstStatsSent) {
            this.firstStatsSent = true;
            this.props.functionsContext.postToWorker('sendFirstAudioStats', { roomId: this.roomId });

            amplitude.getInstance().logEvent('First OpenTok audio stats', {
              'packets lost': String(stats.audio.packetsLost),
              'packets received': String(stats.audio.packetsReceived),
              'bytes received': String(stats.audio.bytesReceived)
            });
          }
        }

        if (stats.video) {
          this.openTokStats.reportVideoStats({
            timestamp: stats.timestamp,
            bytesReceived: stats.video.bytesReceived,
            packetsLost: stats.video.packetsLost,
            packetsReceived: stats.video.packetsReceived
          });
        }
      }

      if (Date.now() - this.lastStatFlush > 30 * 1000) {
        const newStats = this.openTokStats.getStatsSinceLastReport();

        if (newStats) {
          this.props.functionsContext.postToWorker('setOpenTokStats', newStats);
        }

        this.lastStatFlush = Date.now();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async endCall(message) {
    this.clearIntervalSafe(this.ivWaiting);
    this.clearIntervalSafe(this.ivDuration);
    this.clearTimeoutSafe(this.ivHideBadConnectionLabel);

    this.props.functionsContext.postToWorker('stopBillablePolling');
    opentok.disconnect();

    const analyticsParams = {
      'duration': String(this.state.billableDuration),
      'chat room id': this.roomId
    };

    if (this.props.appContext.user && this.roomId) {
      endChatRoom(this.roomId).catch((e) => {
        console.error(e);

        if (e.response) {
          analyticsParams['HTTP status code'] = String(e.response.status);
        }

        amplitude.getInstance().logEvent('Failed to end chatroom', analyticsParams);
      });
    }

    if (message && !this.endCallDisplayed) {
      this.endCallDisplayed = true;
      this.setState({ endCall: null }, async () => await showInfo(message));
    }

    if (this.ivDuration) {
      this.props.history.replace('/feedback', {
        roomId: this.answered ? this.roomId : this.props.continuesRoomId
      });
    } else {
      this.props.history.replace(getHomePaths(this.props.appContext.user));
    }

    this.ivWaiting = null;
    this.ivDuration = null;
    this.ivHideBadConnectionLabel = null;

    try {
      if (document.fullscreen && document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.fullscreen && document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.fullscreen && document.mozExitFullscreen) {
        document.mozExitFullscreen();
      }
    } catch (e) {
      console.error(e);
    }

    amplitude.getInstance().logEvent('Interpretation ended', { 'duration': String(this.state.billableDuration) });
  }

  showBadConnectionLabel() {
    this.setState({badConnection: true});
    this.clearTimeoutSafe(this.ivHideBadConnectionLabel);
    this.ivHideBadConnectionLabel = this.setTimeout(() => {
      this.setState({ badConnection: false });
    }, 5000);
  }

  async confirmEndCall() {
    const accepted = await showQuestion(i18n('doYouWantToHangUp'));

    if (accepted) {
      amplitude.getInstance().logEvent('End interpretation tapped');

      this.endCallClicked = true;
      this.endCall();
    }
  }

  toggleCamera(camera) {
    this.props.functionsContext.postToWorker(camera ? 'cameraOn' : 'cameraOff');
    opentok.toggleCamera(camera);
  }

  toggleAudio(audio) {
    opentok.toggleAudio(audio);
  }

  render() {
    const subscriberStyle = this.state.landscape ? 'subscriberLandscape' : 'subscriberPortrait';
    const publisherStyle = this.isMieli() ? 'hidden' : '';
    const title = i18n(this.props.appContext.user.type === 'Interpreter' ? 'waitingForCustomer' : 'waitingForInterpreter');

    return (
      <span>
        <Header user={this.props.appContext.user} visible={this.ivWaiting !== null} noButtons={true} />
        {(this.ivWaiting !== null) ? (
          <CallJoinTimers
            title={title}
            seconds={this.state.waitedSeconds}
          />
        ) : null}
        <div id='videos' className='videos'>
          <div id='subscriber' className={subscriberStyle}></div>
          <div id='publisher' className={publisherStyle}></div>
        </div>
        <CallOverlay
          callerName={this.state.callerName}
          languageName={this.state.languageName}
          secondsLeft={this.state.timeLeft}
          durationSeconds={this.state.billableDuration}
          badConnection={this.state.badConnection}/>
        <CallActions
          endCall={() => this.confirmEndCall()}
          toggleCamera={camera => this.toggleCamera(camera)}
          toggleAudio={audio => this.toggleAudio(audio)}
        />
      </span>
    );
  }
}

const ContextWrapper = ({ ...props }) => (
  <FunctionsContext.Consumer>
    {functionsContext => (
      <AppContext.Consumer>
        {appContext => (
          <VideoChat appContext={appContext} functionsContext={functionsContext} {...props} />
        )}
      </AppContext.Consumer>
    )}
  </FunctionsContext.Consumer>
);

export default ContextWrapper;

VideoChat.propTypes = {
  appContext: PropTypes.object.isRequired,
  functionsContext: PropTypes.object.isRequired,
  event: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

reactMixin.onClass(VideoChat, TimerMixin);
