import amplitude from 'amplitude-js';

import i18n, { langCode } from '../i18n';
import requestService from './requestService';
import { showSuccess, showError } from './toastService';

const endpoints = {
  inviteCollaborator: '/user/inviteCollaborator',
  getCollaborators: '/user/getCollaborators',
  removeCollaborator: '/user/removeCollaborator',
};

const dataTestErrorMessage = 'collaboratorFormErrorMessage';
const dataTestSuccessMessage = 'collaboratorFormSuccessMessage';

export const addCollaborator = async(emailOrPhone) => {
  const seqrchQuery = `lang=${langCode === 'en' ? 'eng' : 'fin'}`;
  const body = `email=${encodeURIComponent(emailOrPhone)}`;

  try {
    const result = await requestService.put(`${endpoints.inviteCollaborator}?${seqrchQuery}`, null, body, {apiType: 'platform-v2'});
    amplitude.getInstance().logEvent('Add collaborator');
    showSuccess(i18n('invite_to_collaborate_success'), dataTestSuccessMessage);
    return result;
  } catch (e) {
    console.error(e);

    amplitude.getInstance().logEvent('Add collaborator failed', {
      'error': e.message
    });

    if (e.message === 'user_is_already_collabarator') {
      showError(i18n('this_user_already_added_to_collaborators'), dataTestErrorMessage);
    } else if (e.message === 'user_does_not_exist') {
      showError(i18n('user_does_not_exist'), dataTestErrorMessage);
    } else {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
      showError(i18n('error500'), dataTestErrorMessage);
    }

    throw e;
  }
};

export const removeCollaborator = async(targetUserId) => {
  const body = `targetUserId=${encodeURIComponent(targetUserId)}`;

  try {
    return requestService.put(`${endpoints.removeCollaborator}`, null, body, {apiType: 'platform-v2'}).then((response) => {
      amplitude.getInstance().logEvent('Remove collaborator');
      showSuccess(i18n('collaborator_successfully_removed'), dataTestSuccessMessage);
      return response;
    });
  } catch (e) {
    console.error(e);

    amplitude.getInstance().logEvent('Remove collaborator failed', {
      'error': e.message
    });

    if (e.response !== undefined && e.response.status === 400) {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
      showError(i18n('error500'), dataTestErrorMessage);
    } else {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
      showError(i18n('error500'), dataTestErrorMessage);
    }

    throw e;
  }
};

export const getCollaborators = async() => {
  try {
    return requestService.get(`${endpoints.getCollaborators}`, null, {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);

    if (e.response !== undefined && e.response.status === 400) {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
      showError(i18n('error500'), dataTestErrorMessage);
    } else {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
      showError(i18n('error500'), dataTestErrorMessage);
    }

    throw e;
  }
};
