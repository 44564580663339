import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';

import ThemeContext from '../contexts/ThemeContext';

import i18n from '../i18n';

import Icon from './Icon';

const StyledIconContainer = styled.div`
  text-align: center;
`;

const StyledTooltip = styled(Tooltip)`
  display: flex;
  justify-content: center;
`;

const StyledTooltipContainer = styled.div`
  width: 100%;
  max-width: 300px;
`;

const StyledTooltipText = styled.div`
  color: white;
  font-size: ${({ fontSize }) => (fontSize)}px;
  line-height: ${({ fontSize }) => (fontSize + 2)}px;
  text-align: left;
  font-weight: normal;
  word-wrap: break-word;
`;

const PrebookTypeIcon = ({ color, type, ...rest }) => {
  const { colors, layout } = useContext(ThemeContext);

  const [sharedProps] = useState({
    iconColor: color || colors.webDarkBlue,
    iconSize: 20
  });

  return (
    <StyledIconContainer {...rest}>
      <StyledTooltip
        content={(
          <StyledTooltipContainer
            padding={layout.padding}
          >
            <StyledTooltipText
              fontSize={layout.mediumFontSize}
            >
              {(type === 'notice') ? i18n('presence_notice') : ''}
              {(type === 'data') ? i18n('call_type_video') : ''}
              {(type === 'call') ? i18n('call_type_call') : ''}
              {(type === 'onsite') ? i18n('presence_on_site') : ''}
              {(type === 'external') ? i18n('presence_conference') : ''}
            </StyledTooltipText>
          </StyledTooltipContainer>
        )}
        background={colors.tulkaMainColor}
        className="target"
        direction="top"
      >
        {(type === 'notice') ? (
          <Icon
            icon="notifications_none"
            iconLib="material"
            data-tip={i18n('presence_notice')}
            data-event="hover"
            {...sharedProps}
          />
        ) : ''}
        {(type === 'data') ? (
          <Icon
            icon="live_tv"
            iconLib="material"
            data-tip={i18n('call_type_video')}
            data-event="hover"
            {...sharedProps}
          />
        ) : ''}
        {(type === 'call') ? (
          <Icon
            icon="phone_in_talk_outlined"
            iconLib="material"
            data-tip={i18n('call_type_call')}
            data-event="hover"
            {...sharedProps}
          />
        ) : ''}
        {(type === 'onsite') ? (
          <Icon
            icon="building-o"
            iconLib="fontAwesome"
            data-tip={i18n('presence_on_site')}
            data-event="hover"
            {...sharedProps}
          />
        ) : ''}
        {(type === 'external') ? (
          <Icon
            icon="group"
            iconLib="fontAwesome"
            data-tip={i18n('presence_conference')}
            data-event="hover"
            {...sharedProps}
          />
        ) : ''}
      </StyledTooltip>
    </StyledIconContainer>
  );
};

export default PrebookTypeIcon;
