import amplitude from 'amplitude-js';

import requestService from './requestService';

const endpoints = {
  enableNotifications: '/user/enableNotifications',
  disableNotifications: '/user/disableNotifications',
};

export const enableNotifications = async() => {
  try {
    return requestService.put(`${endpoints.enableNotifications}`, null, null, {apiType: 'platform-v2'}).then((response) => {
      amplitude.getInstance().logEvent('Enable notifications');
      return response;
    });
  } catch (e) {
    console.error(e);

    const analyticsParams = {};
    if (e.response) {
      analyticsParams['HTTP status code'] = String(e.response.status);
    }
    amplitude.getInstance().logEvent('Enable notifications failed', analyticsParams);

    throw e;
  }
};

export const disableNotifications = async(minutes) => {
  const body = 'minutes=' + minutes;

  try {
    return requestService.put(`${endpoints.disableNotifications}`, null, body, {apiType: 'platform-v2'}).then((response) => {
      amplitude.getInstance().logEvent('Disable notifications', {
        'minutes': String(minutes)
      });
      return response;
    });
  } catch (e) {
    console.error(e);

    const analyticsParams = {};
    if (e.response) {
      analyticsParams['HTTP status code'] = String(e.response.status);
    }
    amplitude.getInstance().logEvent('Disable notifications failed', analyticsParams);

    throw e;
  }
};
