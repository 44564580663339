import React, { useContext } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';

import i18n from '../i18n';

import ThemeContext from '../contexts/ThemeContext';

const StyledNoOptionsMessage = styled.span`
  padding: 10px;
  display: flex;
  flex: 1;
`;

const MenuItemText = styled.div`
  flex: 1 1 auto;
  display: flex;
  color: #ffffff;
`;

const StyledComponentsMenuList = styled(components.MenuList)`
  z-index: 999999;
  &::-webkit-scrollbar {
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 12px;
    background-color: rgba(196, 196, 196, 1);
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const SearchSelect = ({ selected, icon, disabled, placeholder, onChange, selectableItems }) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  return (
    <Select
      placeholder={placeholder || i18n('type_search_term_here')}
      isDisabled={disabled}
      options={selectableItems}
      value={selected}
      onChange={onChange}
      styles={{
        control: (styles, { isFocused }) => ({
          ...styles,
          boxShadow: 'none',
          borderColor: isFocused ? colors.webMediumGray : '#cccccc',
          padding: '6px 6px',
          ':hover': {
            borderColor: colors.webMediumGray
          }
        }),
        option: (base, state) => ({
          ...base,
          transition: layout.transition,
          backgroundColor: state.isSelected ? colors.tulkaMainColor : 'white',
          ':active': {
            backgroundColor: state.isSelected ? colors.tulkaMainColor : 'white'
          },
          ':hover': {
            backgroundColor: '#DFFFFE',
            color: colors.tulkaMainColor
          }
        }),
      }}
      components={{
        Option: (props) => {
          return <components.Option {...props}
            children={
              <Highlighter
                autoEscape={true}
                searchWords={[props.selectProps.inputValue]}
                textToHighlight={props.data.label}
              />
            }
          />;
        },
        NoOptionsMessage: () => (
          <StyledNoOptionsMessage>
            {i18n('no_options')}
          </StyledNoOptionsMessage>
        ),
        LoadingMessage: () => (
          <StyledNoOptionsMessage>
            {i18n('search_message')}
          </StyledNoOptionsMessage>
        ),
        Control: ({ children, ...nestedRest }) => (
          <components.Control {...nestedRest}>
            {icon ? icon : null}
            <MenuItemText>
              {children}
            </MenuItemText>
          </components.Control>
        ),
        MenuList: ({ children, ...props }) => {
          return <StyledComponentsMenuList {...props}>{children}</StyledComponentsMenuList>;
        },
        Menu: ({ children, ...props }) => {
          return <components.Menu {...props}>{children}</components.Menu>;
        },
      }}
    />
  );
};

export default SearchSelect;
