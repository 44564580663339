import amplitude from 'amplitude-js';

import i18n, { langCode } from '../i18n';
import requestService from './requestService';
import { showSuccess, showError } from './toastService';

const endpoints = {
  usergroups: '/usergroups',
  inviteToGroup: '/user/inviteUserToGroup',
};

const formatParametrs = (parametrsArray) => {
  return parametrsArray.filter(param => param).join('&');
};

export const getUsergroups = async(query, pureList, rootInvoicingUserGroupsOnly) => {
  try {
    const seqrchQuery = query ? `name=${encodeURIComponent(query)}` : '';
    const isPureList = pureList ? 'pureList=true' : '';
    const rootInvoicingUserGroupsOnlyParam = rootInvoicingUserGroupsOnly ? 'rootInvoicingUserGroupsOnly=true' : '';
    return requestService.get(`${endpoints.usergroups}`, formatParametrs([seqrchQuery, isPureList, rootInvoicingUserGroupsOnlyParam]), { apiType: 'platform-v2' });
  } catch (e) {
    console.error(e);

    if (e.response !== undefined && e.response.status === 400) {
      showError(i18n('error500'));
    } else {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });

      showError(i18n('error500'));
    }

    throw e;
  }
};

export const inviteUsersToTheGroup = async(userName, userEmail, userPhoneNumber) => {
  const seqrchQuery = `lang=${langCode === 'en' ? 'eng' : 'fin'}`;
  const body =
    `name=${encodeURIComponent(userName)}&` +
    `email=${encodeURIComponent(userEmail)}&` +
    `phoneNumber=${encodeURIComponent(userPhoneNumber)}`;

  try {
    return requestService.put(`${endpoints.inviteToGroup}`, seqrchQuery, body, {apiType: 'platform-v2'}).then((response) => {
      showSuccess(i18n('invite_success'));
      return response;
    });
  } catch (e) {
    console.error(e);

    amplitude.getInstance().logEvent('Invite to group failed', {
      'error': e.message
    });

    if (e.response !== undefined && e.response.status === 400) {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
      showError(i18n('error500'));
    } else {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
      showError(i18n('error500'));
    }

    throw e;
  }
};
