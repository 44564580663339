import { useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import { Input } from './Input';
import Icon from './Icon';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const StyledActionBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  ${({ noPadding }) => (noPadding ? '' : 'padding: 10px 40px 10px 40px;')}
  flex: 0 1 auto;
  justify-content: center;
  & + & {
    padding-top: 0 !important;
  }
`;

const StyledWarningContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 15px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const StyledWarningText = styled.span`
  color: #fcc02e;
`;

const MessageContainer = styled.div`
  margin-bottom: 10px;
`;

const AdditionalPrebookInfo = props => {
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [warningMessages, setWarningMessages] = useState([]);

  useEffect(() => {
    setShowWarningMessage(props.showWarningMessage);
    setWarningMessages(props.warningMessages);
  }, [props.showWarningMessage, props.editMode, props.warningMessages]);


  const isVisible = (name, value) => {
    if (props.editableFields && props.editableFields.length > 0) {
      return props.editableFields.includes(name);
    }
    return Boolean(value);
  };

  const getHelpId = () => {
    if (!props.readOnly) {
      return 'prebooking_summary';
    }
    return '';
  };

  const getWarningComponent = (editMode, dataAttrValue) => {
    return (
      <>
        {warningMessages.map(message => (
          <StyledActionBlock>
            <StyledWarningContainer>
              <StyledIcon
                icon='warning_amber'
                iconLib='material'
                iconColor={'#fcc02e'}
                iconSize={18}
              />
              <StyledWarningText data-test={dataAttrValue}>{message}</StyledWarningText>
            </StyledWarningContainer>
          </StyledActionBlock>
        ))}
      </>
    );
  };

  return (
    <StyledContainer>
      <ChatWizardHeader
        title={i18n('additional_order_info')}
        clonePrebook={props.clonePrebook}
        editPrebook={!props.cancelledByUser && props.editPrebook}
        helpId={getHelpId()}
        data-test='changeReservationModalTitle'
      />
      {(props.cloning || props.editMode) && showWarningMessage ? getWarningComponent(props.editMode, 'waitingMessage') : null}
      <ContentBox overlapping={true}>
        {
          !props.isNoticeInterpretation && isVisible('Comment', true) ? (
            <MessageContainer>
              <Input
                notBoldInputTitle
                title={i18n('comment')}
                placeholder={i18n('help_prebooking_summary')}
                readOnly={props.readOnly}
                type="textarea"
                value={props.comment}
                onChange={props.setComment}
                maxLength="1024"
              />
            </MessageContainer>
          ) : null
        }
        <MessageContainer>
          <Input
            notBoldInputTitle
            title={i18n('patient_name')}
            subTitle={i18n('patient_name_description')}
            readOnly={props.readOnly}
            type="input"
            value={props.patientName}
            onChange={props.setPatientName}
          />
        </MessageContainer>
        <MessageContainer>
          <Input
            notBoldInputTitle
            title={i18n('subject')}
            readOnly={props.readOnly}
            type="input"
            value={props.subject}
            onChange={props.setSubject}
          />
        </MessageContainer>
         {
           props.ccEmails.map((email, index) => {
             return (
                 <MessageContainer key={index}>
                   <Input
                     data-test={index + 'CcEmails'}
                     title={index === 0 ? i18n('cc_emails') : null}
                     readOnly={false}
                     value={email}
                     onChange={(value) => props.onUpdateCcEmails(index, value)}
                     placeholder={i18n('cc_email')}
                   />
                 </MessageContainer>
               );
           })
          }
      </ContentBox>
    </StyledContainer>
  );
};

export default AdditionalPrebookInfo;