import styled from 'styled-components';
import i18n, { getIsRightToLeft } from '../i18n';

import { WizardStepContainer, WizardStepTitle } from './WizardStep';
import { InputWizardWithIcon } from './Input';

const WizardStepTitleStyled = styled(WizardStepTitle)`
  margin-bottom: 30px;
`;

const InputWizardWithIconStyled = styled(InputWizardWithIcon)`
  max-width: 300px;
  margin: auto;
  ${({rtl}) => rtl && 'text-align: right;'}
`;

const fillEmail = (text, email='') => {
  if (!text ) {
    return null;
  }
  return text
    .replace('[email]', email);
};

const SignInCodeBlock = ({
  signInCode,
  updateSignInCode,
  updateErrors,
  email,
  error,
  touched
}) => {
  return (
    <WizardStepContainer>
      <WizardStepTitleStyled
        data-test='signInCodeBlockForAuthorizationStep'
        label={fillEmail(i18n('signin_code_block_message'), email)}/>
      <InputWizardWithIconStyled
        data-test='codeInputAuthorizationStep'
        placeholder={i18n('placeholder_signin_code')}
        type='text'
        value={signInCode || ''}
        error={error}
        touched={touched}
        onChange={value => {
          updateSignInCode(value);
          updateErrors('code', value);
        }}
        rtl={getIsRightToLeft()}
      />
    </WizardStepContainer>
  );
};


export default SignInCodeBlock;
