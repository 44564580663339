import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import i18n from '../i18n';
import useChat from '../hooks/useChat';
import { Input } from './Input';
import Icon from './Icon';

const StyledCloseIcon = styled(Icon)`
  cursor: pointer;
  margin-left: auto;
  padding: 10px 25px;
`;

const ChatContainer = styled.div`
  height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  width: ${({ show }) => (show ? '400px' : '0')};
  display: flex;
  flex-direction: column;
  background-color: rgb(32, 33, 36);
  border: 1px solid rgb(48, 48, 48);
  border-bottom: 1px solid rgb(32, 33, 36);
  overflow: hidden;
  position: ${({ show }) => (show ? 'fixed' : 'static')};
  right: 0;
  @media (max-width: 768px) {
    width: ${({ show }) => (show ? '100%' : '0')};
    top: 0;
    left: 0;
  }
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const Spacer = styled.div`
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledInput = styled(Input)`
  border: none;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.08);
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  padding: 0 25px;
  background-color: rgba(255, 255, 255, 0.08);
`;

const MessageContainer = styled.div`
  text-align: ${({ right }) => right ? 'right' : 'left'};
  padding: 20px;
  * {
    color: #ffffff;
  }
`;

const FromBlock = styled.div`
  font-style: italic;
  color: #8a8a94;
`;

const DateBlock = styled.div`
  font-size: 12px;
  color: #8a8a94;
`;

const Chat = (props) => {
  const { sendSignal, messages } = useChat({ room: props.room });
  const [ message, setMessage ] = useState('');
  const [ messagesList, setMessagesList ] = useState(messages);
  const messagesContainerRef = useRef(null);

  useEffect(() => {
    setMessagesList([...messagesList, ...messages]);
  }, [messages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    messagesContainerRef.current.scrollIntoView({ behavior: 'smooth' });
  });
  const sendMessage = text => {
    if (props.room && text.trim()) {
      sendSignal(text, 'text');
      setMessage('');
    }
  };

  return (
    <ChatContainer show={props.show}>
      <StyledCloseIcon
        onClick={props.toggleChat}
        icon='close'
        iconLib='material'
        iconColor='#ffffff'
        iconSize={20}
      />
      <MessagesContainer ref={messagesContainerRef}>
        <Spacer />
        { messagesList.map((msg, idx) => (
          <MessageContainer key={idx} right={msg.isSentByMe}>
            {msg.from && (<FromBlock> by {msg.from}</FromBlock>)}
            <div>{msg.data}</div>
            <DateBlock>{moment(msg.date).format('HH:mm')}</DateBlock>
          </MessageContainer>
        ))}
      </MessagesContainer>
      <Row
        onKeyPress={e => {
          if (e.code === 'Enter') {
            sendMessage(message);
          }
        }}
      >
        <StyledInput
          readOnly={false}
          value={message}
          onChange={setMessage}
          placeholder={i18n('type_message')}
        />
        <StyledIcon
          onClick={() => sendMessage(message)}
          icon='send'
          iconLib='material'
          iconColor='#8a8a94'
          iconSize={20}
        />
      </Row>
    </ChatContainer>
  );
};

export default Chat;
