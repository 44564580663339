import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import moment from 'moment';
import Modal from 'react-modal';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import withLeftSideBar from '../hocs/withLeftSideBar';
import PrebookSummary from '../components/PrebookSummary';
import IconButton from '../components/IconButton';
import CancelPrebookingDialog from '../components/CancelPrebookingDialog';
import ModalDialogCloseButton from '../components/ModalDialogCloseButton';
import InterpreterCalendar from '../components/InterpreterCalendar';
import {
  MainHeader,
  MainContentTopBar,
  ContainerWrapper,
} from '../components/basicComponents';
import { RoundButton } from '../components/Buttons';

import Interpretation from '../components/Interpretation';
import ConfirmationDialog from '../components/ConfirmationDialog';

import { showError } from '../services/toastService';
import {
  getPrebookings,
  cancelPrebooking,
  acceptAutoAllocatedPrebooking
} from '../services/prebookService';
import {
  getBlockedInterpreterTime,
} from '../services/interpreterBlockTimeService';

import {
  joinChatRoom
} from '../services/chatRoomService';

import { setAvailability } from '../services/userService';
import { logAnalytics } from '../services/analyticsLogger';
import PrebookTypeIcon from '../components/PrebookTypeIcon';
import { isLuonaHost } from '../utils/luonaSpecificData';

import i18n from '../i18n';

Modal.setAppElement('#main');

const StyledHomeContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
`;

const StyledContainerWrapper = styled(ContainerWrapper)`
  padding: 15px;
  position: relative;
`;

const StyledNotificationContainer = styled.div`
  background-color: ${({ colors }) => colors.contentBoxColor2020}; // webDefaultInputBorderColor
  border-radius: ${({ layout }) => layout.borderRadius + 'px'};
  border:  ${({ colors }) => '1px solid' + colors.webDefaultInputBorderColor};
  box-shadow: ${({ colors }) => colors.shadowColor + '0px 8px 24px'};
  padding: 10px;
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 998;
  height: auto;
  max-height: 100%;
  width: 460px;
  overflow: auto;
`;

const StyledPrebookList = styled.ul`
  list-style: none;
  margin: 0;
  margin-top: 20px;
  padding: 0;
`;

const StyledPrebookItem = styled.li`
  padding-bottom: 10px;
  margin-bottom: 10px;
  :not(:last-child) {
    border-bottom: ${({ colors }) => '1px solid' + colors.webDefaultInputBorderColor};
  }
  p {
    font-size: 10px;
    line-height: normal;
  }
`;

const Title = styled.div`
  font-size: ${({ fontSize }) => (fontSize)}px;
  color: ${({ color }) => (color)};
  margin: 10px 0 15px;
  font-weight: 600;
`;

const Label = styled.label`
  text-transform: capitalize;
  line-height: 1.7;
  display: flex;
  align-items: center;
  position: static;
  margin: 0;
  height: auto;
`;

const LabelText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  p {
     margin: 0;
  }
  margin-left: 10px;
`;

const StyledPrebookTypeIcon = styled(PrebookTypeIcon)`
  margin-right: 10px;
`;

const CheckboxButton = ({handleChange, checked, children}) => {
  return (
    <Label>
      <input
        type='checkbox'
        checked={checked}
        onChange={handleChange}
        />
      <LabelText>{children}</LabelText>
    </Label>
  );
};

const setInitialCheckedPrebookings = (prebookings) => {
  return prebookings.reduce((memo, {id}) => {
    memo[id] = false;
    return memo;
  }, {});
};

const AcceptAutoAllocatedPrebookings = ({incomingPrebookings, setAcceptedPrebooks}) => {
  const { colors, layout } = useContext(ThemeContext);
  const { user } = useContext(AppContext);
  const [checkedPrebookings, setCheckedPrebookings] = useState(setInitialCheckedPrebookings(incomingPrebookings));
  
  const isAllPrebookingsChecked = useMemo(() => {
    return Object.values(checkedPrebookings).every((el) => el);
  }, [checkedPrebookings]);

  const checkedPrebookingIds = useMemo(() => Object.keys(checkedPrebookings).filter((el) => checkedPrebookings[el] && el), [checkedPrebookings]);

  const isDisabled = useMemo(() => !checkedPrebookingIds.length, [checkedPrebookingIds]);

  useEffect(() => {
    setCheckedPrebookings(setInitialCheckedPrebookings(incomingPrebookings));
  }, [incomingPrebookings]);

  const handleChangeAllChecked = (prevState) => {
    const isCheched = Object.values(prevState).some(el => !el);
    return Object.keys(prevState).reduce((memo, el) => {
      memo[el] = isCheched;
      return memo;
    }, {});
  };

  const acceptPrebooking = async () => {
    try {
      const result = await Promise.all(
        checkedPrebookingIds.map((id) => {
          return acceptAutoAllocatedPrebooking(id);
        })
      );  
      document.dispatchEvent(new CustomEvent('prebookAcceptedInterpreter', {
        detail: { id: result }
      }));
      setAcceptedPrebooks(result);
    } catch (error) {
      console.log(error);
    }
  };

  const declinePrebooking = async () => {
    try {
      const result = await Promise.all(
        checkedPrebookingIds.map((id) => {
          return cancelPrebooking(id, { reason: '', category: '', userType: user.type });
        })
      );    
      document.dispatchEvent(new CustomEvent('prebookCancelledInterpreter', {
        detail: { id: result } 
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getCallType = (prebook) => (prebook.presence === 'onsite' || 
    prebook.presence === 'notice' || 
    (prebook.presence === 'external' && prebook.callType === 'data')) 
    ? prebook.presence : prebook.callType;
  
  return (
    <StyledNotificationContainer colors={colors} layout={layout}>
      {incomingPrebookings?.length ? (
        <>
          <Title
            fontSize={layout.bigFontSize}
            color={colors.webDarkBlue}
            data-test='acceptIncomingPrebookingsTitle'
          >{i18n('accept_incoming_prebookings')}</Title>
          <StyledPrebookList>
            <StyledPrebookItem colors={colors}>
              <CheckboxButton
                checked={isAllPrebookingsChecked}
                handleChange={() => setCheckedPrebookings(prev => handleChangeAllChecked(prev))}
              >{i18n('all')}</CheckboxButton>
            </StyledPrebookItem>
            {incomingPrebookings.map((el) => {
              const callType = getCallType(el);
              return (
                <StyledPrebookItem key={el.id} colors={colors}>
                  <CheckboxButton
                    checked={checkedPrebookings[el.id]}
                    handleChange={() => setCheckedPrebookings(prev => ({...prev, [el.id]: !prev[el.id]}))}
                  >
                    <StyledPrebookTypeIcon
                      color={colors.tulkaMainColor}
                      type={callType}
                    />
                    <div>
                      <p>{i18n('date_and_time')}: {el.interpretationDate}</p>
                      <p>{i18n('duration')}: {el.duration}</p>
                      <p>{i18n('language')}: {el.interpretedLanguage}</p>
                    </div>
                  </CheckboxButton>
                </StyledPrebookItem>
              );
            })}
          </StyledPrebookList>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <RoundButton
              fontSize={10}
              radius={4}
              height={25}
              data-test='acceptAutoAllocatePrebookingBtn'
              label={i18n('accept')}
              onClick={acceptPrebooking}
              color={colors.tulkaMainColor}
              padding={layout.buttonPadding}
              disabled={isDisabled}
            />
            <RoundButton
              fontSize={10}
              radius={4}
              height={25}
              data-test='declineAutoAllocatePrebookingBtn'
              label={i18n('decline')}
              onClick={declinePrebooking}
              warning
              primary
              color={colors.tulkaMainColor}
              padding={layout.buttonPadding}
              disabled={isDisabled}
            />
          </div>
        </>
    ) : (
      <p>{i18n('prebookings_already_checked')}</p>
    )}
    </StyledNotificationContainer>
  );
};

const Home = ({ toggleSideBar, sidebarDocked, ...rest }) => {
  const { user } = useContext(AppContext);
  const { colors, layout } = useContext(ThemeContext);
  const {
    setBusy,
    sessionExpired,
    postToWorker
  } = useContext(FunctionsContext);

  const history = useHistory();

  const [prebook, setPrebook] = useState(null);
  const [prebooks, setPrebooks] = useState([]);
  const [acceptedPrebooks, setAcceptedPrebooks] = useState([]);
  const [unavailability, setUnavailability] = useState([]);
  const [prebookPreviewIsOpen, setPrebookPreviewIsOpen] = useState(false);
  const [cancelPrebookingIsOpen, setCancelPrebookingIsOpen] = useState(false);
  const [ newChatIsOpen, setNewChatIsOpen ] = useState(false);
  const [ newChatData, setNewChatData ] = useState({});
  const [needToAcceptPrebookings, setNeedToAcceptPrebookings] = useState([]);
  const [isShowAcceptPrebookingsMenu, setIsShowAcceptPrebookingsMenu] = useState(false);
  const [isOnlineNotificationIsOpen, setIsOnlineNotificationIsOpen] = useState(false);
  const [isOnlineNotification] = useState({
    message: i18n('confirm_you_are_online'),
    buttons: [{
      label: i18n('join_back'),
      onClick: () => setIsOnlineNotificationIsOpen(false),
      warning: false,
      primary: false,
    }]
  });

  const _isMounted = useRef(true);
  
  useEffect(() => {
    setIsShowAcceptPrebookingsMenu(!!needToAcceptPrebookings);
  }, [needToAcceptPrebookings]);

  function openPrebookPreview(aPrebook) {
    setPrebook(aPrebook);
    setBusy(true);
    setPrebookPreviewIsOpen(true);
  }

  function closePrebookPreview() {
    setPrebookPreviewIsOpen(false);
    setBusy(false);
  }

  function openNewChatModal(detail) {
    setBusy(true);
    setNewChatData(detail.data);
    setNewChatIsOpen(true);
    logAnalytics('Open new chat modal', {
      chatId: detail.chatId,
      userId: user.id
    });
  }

  function closeNewChatModal() {
    setNewChatIsOpen(false);
    setNewChatData({});
    setBusy(false);
    logAnalytics('Close new chat modal', { userId: user.id });
  }

  function rejectRequest() {
    closeNewChatModal();
    postToWorker('rejectRequest');
    logAnalytics('Denial call', { userId: user.id });
  }

  async function acceptCall() {
    postToWorker('acceptRequest');
    const chatRoom = await joinChatRoom();

    const targetLanguageName = chatRoom.targetLanguages.length > 0 ? chatRoom.targetLanguages[0].name : '';
    const targetLanguageCode = chatRoom.targetLanguages.length > 0 ? chatRoom.targetLanguages[0].languageTag : '';
    const targetLanguageId = chatRoom.targetLanguages.length > 0 ? chatRoom.targetLanguages[0].id : '';

    amplitude.getInstance().logEvent('Accept request succeeded', {
      'type': chatRoom.callType,
      'language id': chatRoom.interpretedLanguage.id,
      'language code': chatRoom.interpretedLanguage.languageTag,
      'language name': chatRoom.interpretedLanguage.name,
      'target language id': targetLanguageId,
      'target language code': targetLanguageCode,
      'target language name': targetLanguageName
    });

    history.replace(
      '/call', {
      callerName: chatRoom.userGroupName,
      languageName: chatRoom.interpretedLanguage.name,
      secondsLeft: 0,
      apiKey: chatRoom.apiKey,
      chatToken: chatRoom.chatToken,
      sessionId: chatRoom.sessionId,
      roomId: chatRoom.id,
      textChatTokenProviderEndpoint: chatRoom.textChatTokenProviderEndpoint,
      textChatInstanceLocator: chatRoom.textChatInstanceLocator,
      textChatRoomId: chatRoom.textChatRoomId
    });
  }

  async function acceptRequest(isMultistart) {
    closeNewChatModal();
    try {
      setAvailability(false);
    } catch (error) {
      console.log('Failed to update unavailability');
    }
    if (isMultistart) {
      postToWorker('acceptRequest');
      logAnalytics('Accept call', { userId: user.id });
      history.push(
        `/meetings/${123}`, {
        name: user.name
      });
    } else {
      acceptCall();
    }
  }

  function closeCancelPrebooking() {
    setCancelPrebookingIsOpen(false);
    setPrebookPreviewIsOpen(false);
    setBusy(false);
  }

  function backCancelPrebooking() {
    setCancelPrebookingIsOpen(false);
  }

  function cancelPrebookingDialog() {
    setCancelPrebookingIsOpen(true);
    setBusy(true);
  }

  function createPrebookingForAccept (prebooking) {
    return {
      id: prebooking.id,
      interpretationDate: moment(new Date(prebooking.interpretationDate)).format('DD/MM/YYYY HH:mm'),
      duration: moment.duration(prebooking.durationEstimated, 'seconds').format('hh:mm', {
        trim: false
      }),
      interpretedLanguage: prebooking.interpretedLanguage.name ? prebooking.interpretedLanguage.name : prebooking.interpretedLanguage.languageTag,
      presence: prebooking.presence,
      callType: prebooking.callType
    };
  }

  function getPrebookingsForAccept(prebookings) {
    return prebookings.reduce((memo, pr) => {
      if (!pr.autoAllocationAcceptedAt) {
        memo.push(createPrebookingForAccept(pr));
      }
      return memo;
    }, []);
  }

  const getPreparedPrebookings = async (dateFrom, dateTo) => {
    try {
      const result = await Promise.all([
        getPrebookings(false, user.type, undefined, dateFrom, dateTo),
        getBlockedInterpreterTime(),
      ]);
      if (_isMounted.current) {
        const prebookings = result[0].prebookings;
        const needToAccept = getPrebookingsForAccept(prebookings);
        setNeedToAcceptPrebookings(needToAccept);
        setPrebooks(prebookings || []);
        setUnavailability(result[1]);
      }
    } catch (e) {
      console.log('Failed to get prebooking.', e);

      if (e.response !== undefined && e.response.status === 400) {
        amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
        if (_isMounted.current) {
          showError(i18n('error500'));
        }
      } else if (e.message === 'Session expired') {
        sessionExpired();
      } else {
        amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
        if (_isMounted.current) {
          showError(i18n('error500'));
        }
      }
    }
  };

  const closeIsOnlineNotification = () => {
    logAnalytics('Close is Online Notification', {
      userId: user?.id
    });
    setIsOnlineNotificationIsOpen(false);
  };

  useEffect(() => {
    getPreparedPrebookings();
    const showNotification = async () => {
      logAnalytics('Display is Online Notification', {
        userId: user?.id
      });
      setIsOnlineNotificationIsOpen(true);
    };
    document.addEventListener('needIsOnlineNotification', showNotification);
    return () => {
      document.removeEventListener('needIsOnlineNotification', showNotification);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const openNewChatModalIfNeeded = (detail) => {
    // const isPrebook = !!detail.data?.presence;
    // const isLuona = INTERPRETED_LANGUAGE_TAGS.includes(detail.data.interpretetedLanguageTag);
    // if (!isPrebook && isLuona && !user.availableUntil > 0) {
    //   return;
    // }
    openNewChatModal(detail);
  };

  useEffect(() => {
    const func = async (ev) => {
      if (ev.detail) {
        if (ev.detail.data.hasNewChats) {
          openNewChatModalIfNeeded(ev.detail);
        }
      }
    };
    document.addEventListener('newChats', func);
    return () => {
      document.removeEventListener('newChats', func);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => { // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!prebookPreviewIsOpen) {
      setPrebook(null);
    }
  }, [prebookPreviewIsOpen]);

  useEffect(() => {
    const func = (ev) => {
      if (ev && ev.detail) {
        getPreparedPrebookings();
      }
    };
    document.addEventListener('prebookCancelledInterpreter', func);
    return () => {
      document.removeEventListener('prebookCancelledInterpreter', func);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prebooks]);

  useEffect(() => {
    const func = (ev) => {
      if (ev && ev.detail) {
        getPreparedPrebookings();
      }
    };
    document.addEventListener('prebookAcceptedInterpreter', func);
    return () => {
      document.removeEventListener('prebookAcceptedInterpreter', func);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedPrebooks]);

  const handleNavigation = (dateFrom, dateTo)=> {
    getPreparedPrebookings(dateFrom, dateTo);
  };

  const isAutoAllocatedInterpreter = useMemo(() => {
    return !isLuonaHost() &&
      user?.autoAllocatePrebookings && 
      user?.autoAllocationStartsAt;
  }, [user]);

  if (!user.id) {
    return null;
  }
  const cancelable = prebook?.interpretationDate - Date.now() > 60 * 60 * 1000 && !prebook?.noShowAt;
  return (
    <StyledHomeContainer {...rest}>
      <MainContentTopBar
        borderBottomColor={colors.webDeviderColor}
      >
        <Modal
          isOpen={isOnlineNotificationIsOpen}
          onRequestClose={closeIsOnlineNotification}
          style={layout.modalDialogStyles}
        >
          <ModalDialogCloseButton
            onClick={() => closeIsOnlineNotification()}
          />
          <ConfirmationDialog
            buttons={isOnlineNotification.buttons}
            message={isOnlineNotification.message}
            closeModal={closeIsOnlineNotification}
          />
        </Modal>
        <Modal
          isOpen={prebookPreviewIsOpen}
          onRequestClose={closePrebookPreview}
          style={layout.modalDialogStyles}
          contentLabel="Prebooking view"
        >
          <ModalDialogCloseButton
            onClick={() => closePrebookPreview()}
          />
          <PrebookSummary
            prebook={prebook}
            previewOnly={!cancelable}
            isInterpreterPreview={true}
            ongoing={prebook && prebook.presence === 'remote' && prebook.interpretationDate - Date.now() <= (prebook.leewayBefore * 1000)}
            closeModal={closePrebookPreview}
            confirmationDialog={cancelPrebookingDialog}
          />
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={false}
          isOpen={newChatIsOpen}
          onRequestClose={closeNewChatModal}
          style={layout.modalDialogStyles}
        >
          <Interpretation
            user={user}
            rejectRequest={rejectRequest}
            acceptRequest={acceptRequest}
            close={closeNewChatModal}
            data={newChatData}
          />
        </Modal>
        <Modal
          isOpen={cancelPrebookingIsOpen}
          onRequestClose={closeCancelPrebooking}
          style={{
            ...layout.modalDialogStyles,
            overlay: {
              backgroundColor: 'transparent',
              zIndex: 9999
            }
          }}
          contentLabel="Prebooking view"
        >
          <ModalDialogCloseButton
            onClick={() => closeCancelPrebooking()}
          />
          <CancelPrebookingDialog
            onBack={backCancelPrebooking}
            prebook={prebook}
            closeModal={() => closeCancelPrebooking()}
          />
        </Modal>
        {sidebarDocked ? null : (<IconButton
          icon={'menu'}
          iconLib={'material'}
          iconColor={colors.tulkaMainColor}
          onClick={toggleSideBar}
          data-tip="React-tooltip"
          data-event="click"
        />)}
        <MainHeader
          sidebarDocked={sidebarDocked}
          padding={layout.padding}
          color={colors.webDarkBlue}
        >
          {i18n('my_bookings')}
        </MainHeader>
        {isAutoAllocatedInterpreter && (
          <RoundButton
            data-test='acceptIncomingPrebookingsBtn'
            label={i18n('accept_incoming_prebookings')}
            onClick={() => setIsShowAcceptPrebookingsMenu(!isShowAcceptPrebookingsMenu)}
            smallButton={true}
            darkButton={false}
            icon={isShowAcceptPrebookingsMenu ? 'close' : 'check'}
          />
        )}
      </MainContentTopBar>
      <StyledContainerWrapper>
      {isShowAcceptPrebookingsMenu && isAutoAllocatedInterpreter && (
        <AcceptAutoAllocatedPrebookings 
          incomingPrebookings={needToAcceptPrebookings}
          setAcceptedPrebooks={setAcceptedPrebooks}
        />
      )}
        <InterpreterCalendar
          prebooks={prebooks}
          unavailability={unavailability}
          openPreview={openPrebookPreview}
          handleNavigation={handleNavigation}
        />
      </StyledContainerWrapper>
    </StyledHomeContainer>
  );
};

export default withLeftSideBar(Home);
