const almostBlack = '#505050';
const black = '#000000';
const semiBlack = '#333333';
const white = '#FFFFFF';
const darkGray = '#585858';
const gray = '#888888';
const lightGray = '#B8B8B8';
const red = '#E03333';

const sapphire = '#4178CC';
const beach = '#8DD1D4';
const darkBeach = '#688184';
const coral = '#EA7E8F';
const twilight = '#46446F';
const twilightLight = '#48684B';
const blush = '#FFBEC5';
const glass = '#BFF1F4';
const sky = '#A1BBE1';
const shadowColor = 'rgba(149, 157, 165, 0.2)';

const luonaMainBrightColor = 'rgb(255, 81, 31)';
const luonaMainBrightOpacity = 'rgba(255, 81, 31, 0.7)';
const luonaModalOverlay = 'rgba(0, 0, 0, 0.87)';
const luonaMainLightColor = '#fad5c8';
const luonaWaitonfRoomBgc = 'rgb(246, 249, 252)';
const luonaGrayTextColor = '#656565';
const luonaSuccessColor = '#7eaa55';
const luonaBorderColor = 'rgb(247, 247, 249)';

const elementHeight = 44;
const gridDefault = 10;

const Layout = {
  elementHeight,

  grid: {
    default: gridDefault,
  },

  general: {
    logo: {
      resizeMode: 'contain',
    },

    poweredByLogo: {
      width: '100px',
      height: '20px',
      resizeMode: 'contain',
      alignSelf: 'center',
    },

    background: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: null,
      height: null,
      resizeMode: 'cover',
    },

    h2: {
      fontSize: 20,
      backgroundColor: 'transparent',
    },

    h22: {
      fontSize: 18,
      backgroundColor: 'transparent',
    },

    h3: {
      fontSize: 16,
      backgroundColor: 'transparent',
    },

    p: {
      fontSize: 14,
      backgroundColor: 'transparent',
    },

    link: {
      backgroundColor: 'transparent',
      marginTop: gridDefault,
      marginBottom: gridDefault,
    },

    punchline: {
      fontSize: 20,
      opacity: 0.8,
      backgroundColor: 'transparent',
      paddingTop: 20,
      textAlign: 'center',
    },
  },

  color: {
    almostBlack,
    black,
    semiBlack,
    darkGray,
    gray,
    lightGray,
    white,
    red,

    sapphire,
    beach,
    darkBeach,
    coral,
    blush,
    glass,
    sky,
    twilight,
    twilightLight,
    shadowColor,

    luonaMainBrightColor,
    luonaMainBrightOpacity,
    luonaModalOverlay,
    luonaMainLightColor,
    luonaWaitonfRoomBgc,
    luonaGrayTextColor,
    luonaSuccessColor,
    luonaBorderColor
  },

  form: {
    button: {
      width: 280,
      height: elementHeight,
      borderWidth: 0,
      justifyContent: 'center',
      alignSelf: 'center',
      borderRadius: 3,
    },

    buttonText: {
      fontSize: 16,
      lineHeight: 20,
      textAlign: 'center',
    },

    textInput: {
      fontSize: 14,
      height: elementHeight - 5,
      backgroundColor: white,
      textAlignVertical: 'center',
      borderRadius: 3,
    },
  },

  mainContainer: {
    flex: 1,
  },

  viewportWidth: () => '100%',
  viewportHeight: () => '100%',
  topMargin: 20,
  naviHeight: 44,
};

export default Layout;
