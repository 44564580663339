import styled from 'styled-components';
import i18n from '../i18n';

import ChatWizardHeader from './ChatWizardHeader';
import { Input } from './Input';

const StyledContainer = styled.div`
  padding-top: 40px;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: 10px 40px;
`;

const MessageContainer = styled.div`
  margin-bottom: 10px;
`;

const CcEmails = ({ userEmail, onUpdateCcEmails, ccEmails }) => {
  return (
    <StyledContainer>
      <ChatWizardHeader
        title={i18n('cc_emails')}
        clonePrebook={false}
        helpId={''}
       />
       <ContentContainer>
         <MessageContainer>
           <Input
             data-test='userEmailCcEmails'
             readOnly={true}
             value={userEmail}
           />
         </MessageContainer>
         {
           ccEmails.map((email, index) => (
               <MessageContainer key={index}>
                 <Input
                   data-test={index + 'CcEmails'}
                   readOnly={false}
                   value={email}
                   onChange={(value) => onUpdateCcEmails(index, value)}
                   placeholder={i18n('cc_email')}
                 />
               </MessageContainer>
             ))
           }
       </ContentContainer>
    </StyledContainer>
  );
};


export default CcEmails;
