import styled from 'styled-components';
import i18n from '../i18n';

import {
  ContainerStepBox,
  ContentStepBox,
  MainStepsHeader,
  StepsHeader
} from './basicComponents';

const StyledStepsHeader = styled(StepsHeader)`
  & + & {
    margin-top: 8px;
  }
`;

const InvalidDomainInfoStep = () => {
  return (
    <ContainerStepBox>
      <ContentStepBox>
        <MainStepsHeader>{i18n('no_contract_found')}</MainStepsHeader>
        <StyledStepsHeader>{i18n('it_seems_like_you_dont_have_an_contract_with_tulka_based_on_your_email_address')}</StyledStepsHeader>
        <StyledStepsHeader>{i18n('please_contact_our_sales_to_begin_using_tulka')}</StyledStepsHeader>
      </ContentStepBox>
    </ContainerStepBox>
  );
};

export default InvalidDomainInfoStep;
