import requestService from './requestService';
const endpoints = {
  continueChatRoom: '/chatroom/continue/',
  createChatRoom: 'chatroom/multistart',
  joinChatRoom: '/chatroom/join',
  endChatRoom: '/chatroom/end/',
  chatRoomStillWaiting: '/chatroom/still_waiting/',
  chatRoomStatus: '/chatroom/status/',
};
export const continueChatRoom = async(chatRoomId, callType) => {
  const body =
    'supportsSecondsLeft=true' +
    '&callType=' + encodeURIComponent(callType);
  try {
    const chatData = await requestService.post(`${endpoints.continueChatRoom}${chatRoomId}`, null, body, {
      apiType: 'platform-v2',
    });
    if (chatData.error) {
      throw new Error(chatData.message);
    }
    return chatData;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const getChatRoomStatus = async(chatRoomId) => {
  try {
    return requestService.get(`${endpoints.chatRoomStatus}${chatRoomId}`, null, {
      apiType: 'platform-v2',
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const joinChatRoom = async() => {
  try {
    return requestService.get(`${endpoints.joinChatRoom}`, null, {
      apiType: 'platform-v2',
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const interpretationStillNeeded = async(roomId) => {
  try {
    return requestService.put(`${endpoints.chatRoomStillWaiting}${roomId}`, null, null, {
      apiType: 'platform-v2',
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const continueSession = async(roomId) => {
  try {
    const chatData = await requestService.post(`${endpoints.continueChatRoom}${roomId}`, null, null, {
      apiType: 'platform-v2',
      defaultParamsToBody: true,
    });
    if (chatData.error) {
      throw new Error(chatData.message);
    }
    return chatData;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getChatRooms = (languageId, targetLanguageId, skillId, prebookingId, favoriteInterpreterId, invoiceUserGroupId, invoiceRef) => {
  return [{
    languageId,
    targetLanguageId,
    skillIds: skillId,
    prebookingId,
    favoriteInterpreterId,
    invoiceUserGroupId,
    invoiceRef
  }];
};

export const createChatRoom = async(languageId, targetLanguageId, skillId, callType, prebookingId, favoriteInterpreterId, invoiceUserGroupId, invoiceRef) => {
  const body = {
    callType,
    chatRooms: getChatRooms(languageId, targetLanguageId, skillId, prebookingId, favoriteInterpreterId, invoiceUserGroupId, invoiceRef),
    ...requestService.getDefaultParamsJson()
  };
  try {
    const chatData = await requestService.postWithJson(`${endpoints.createChatRoom}`, {}, body, {
      apiType: 'platform'
    });
    if (chatData.error) {
      throw new Error(chatData.message);
    }
    return chatData;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const endChatRoom = async(roomId) => {
  try {
    return requestService.put(`${endpoints.endChatRoom}${roomId}`, null, null, {
      apiType: 'platform-v2',
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};