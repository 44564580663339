import React, { useContext } from 'react';
import styled from 'styled-components';
import ThemeContext from '../contexts/ThemeContext';
import Icon from './Icon';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.08);
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  & + & {
    margin-left: 25px;
  }
`;

const Toolbar = ({
  audio,
  video,
  blur,
  setAudio,
  setVideo,
  setBlur,
  heandlerLeave,
  heanderChat,
  isChat,
}) => {
  const { colors } = useContext(ThemeContext);
  return (
    <Container color={colors.tulkaMainColor}>
      <StyledIcon
        onClick={() => setAudio(!audio)}
        icon={audio ? 'mic' : 'mic_off'}
        iconLib='material'
        iconSize={20}
        iconColor={audio ? '#ffffff' : colors.errorColor}
      />
      <StyledIcon
        onClick={() => setVideo(!video)}
        icon={video ? 'videocam' : 'videocam_off'}
        iconLib='material'
        iconColor={video ? '#ffffff' : colors.errorColor}
        iconSize={20}
      />
      <StyledIcon
        onClick={heandlerLeave}
        icon='phone'
        iconLib='fontAwesome'
        iconColor={colors.errorColor}
        iconSize={20}
      />
      {video && (
        <StyledIcon
        onClick={() => setBlur()}
        iconLib='material'
        icon={blur ? 'blur_on' : 'blur_off'}
        iconColor='#ffffff'
        iconSize={20}
        />
      )}
      {isChat && (
        <StyledIcon
        onClick={heanderChat}
        icon='chat'
        iconLib='material'
        iconColor='#ffffff'
        iconSize={20}
        />
      )}
      
    </Container>
  );
};

export default Toolbar;
