import React from 'react';
import i18n from '../i18n';
import { format } from '../utils/timeUtils';
import PropTypes from 'prop-types';

export default class CallOverlay extends React.Component {
  render() {
    let timeLeft = '';
    let timeLeftTitle = '';

    if (this.props.secondsLeft > 0) {
      timeLeft = format(this.props.secondsLeft);
      timeLeftTitle = i18n('freeUseLeft');
    }

    let duration = '';

    if (this.props.durationSeconds > 0) {
      duration = format(this.props.durationSeconds);
    }

    let timeLeftClass = this.props.secondsLeft <= 2 * 60 ? 'runningOutOfTime' : '';
    if (this.props.secondsLeft <= 1 * 60) {
      timeLeftClass += ' superLowTimeLeft';
    }

    const badConnection = this.props.badConnection ? i18n('roomInfoHasAudioPacketLoss') : '';

    return (
      <div id='callOverlay'>
        <div id='callInfo'>
          <div id='callerName'>{this.props.callerName}</div>
          <div id='callLanguage'>{this.props.languageName}</div>
          <div id='timeLeftTitle' className={timeLeftClass}>{timeLeftTitle}</div>
          <div id='timeLeft' className={timeLeftClass}>{timeLeft}</div>
        </div>
        <div id='badConnection'>{badConnection}</div>
        <div id='callDuration'>{duration}</div>
      </div>
    );
  }
}

CallOverlay.propTypes = {
  callerName: PropTypes.string.isRequired,
  languageName: PropTypes.string.isRequired,
  secondsLeft: PropTypes.number.isRequired,
  durationSeconds: PropTypes.number.isRequired,
  badConnection: PropTypes.bool.isRequired
};
