import amplitude from 'amplitude-js';

import { manifest } from '../Manifest';

import requestService from './requestService';
import { analyticsSetUser } from './analyticsLogger';


const endpoints = {
  login: '/auth/login',
  logout: '/auth/logout',
  resetPassword: '/auth/forgotPassword',
  signup: '/auth/signup',
  frequencyPoll: '/auth/frequencyPoll',
  verify: '/auth/verify',
  resendLink: '/auth/resendLink',
  sendTutorial: '/auth/sendTutorial'
};

function setAnalyticsUser(user) {
  amplitude.getInstance().setVersionName(manifest.appVersion);
  amplitude.getInstance().setUserId(user.id);
  amplitude.getInstance().setUserProperties({
    'type': user.type,
    'brand': manifest.brand
  });
}

export const login = async(username, password) => {
  const body =
    'username=' + encodeURIComponent(username) +
    '&password=' + encodeURIComponent(password);

  try {
    const userObj = await requestService.post(`${endpoints.login}`, null, body, {apiType: 'platform-v2'});

    setAnalyticsUser(userObj);
    localStorage.setItem('user', JSON.stringify(userObj));
    amplitude.getInstance().logEvent('Login');

    return userObj;
  } catch (e) {
    console.error(e);

    amplitude.getInstance().logEvent('Login failed', {
      'HTTP status code': (e.response ? String(e.response.status) : '')
    });

    throw e;
  }
};

export const logout = async(user) => {
  const body =
    'username=' + encodeURIComponent(user.username);

  try {
    return requestService.post(`${endpoints.logout}`, null, body, {apiType: 'platform-v2'}).then((response) => {
      amplitude.getInstance().logEvent('Logout');
      return response;
    });
  } catch (e) {
    console.error(e);

    amplitude.getInstance().logEvent('Logout failed');

    throw e;
  }
};

export const resetPassword = async(phoneNumber) => {
  const body = 'phoneNumber=' + encodeURIComponent(phoneNumber);

  try {
    return requestService.post(`${endpoints.resetPassword}`, null, body, {apiType: 'platform-v2'}).then((response) => {
      amplitude.getInstance().logEvent('Reset password');
      return response;
    });
  } catch (e) {
    console.error(e);

    const analyticsParams = {};

    if (e.response) {
      analyticsParams['HTTP status code'] = String(e.response.status);
    }
    amplitude.getInstance().logEvent('Reset password failed', analyticsParams);

    throw e;
  }
};

export const signup = (userData) => {
  try {
    const data = requestService.postWithJson(
      `${endpoints.signup}`,
      null,
      userData,
      {apiType: 'platform-v2'}
    );
    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const frequencyPoll = (userData) => {
  try {
    const data = requestService.postWithJson(
      `${endpoints.frequencyPoll}`,
      null,
      userData,
      {apiType: 'platform-v2'}
    );
    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const verify = (token) => {
  try {
    const data = requestService.postWithJson(
      `${endpoints.verify}`, 
      null, 
      {token}, 
      {apiType: 'platform-v2'}
    );
    return data;
  } catch (err) {
    console.error(err.message);
    return err;
  }
};

export const loginWithCode = async(code) => {
  const body =
    'code=' + encodeURIComponent(code);

  try {
    const userObj = await requestService.post(`${endpoints.login}`, null, body, {apiType: 'platform-v2'});
    localStorage.setItem('user', JSON.stringify(userObj));
    analyticsSetUser(userObj);

    return userObj;
  } catch (e) {
    console.error(e);

    throw e;
  }
};

export const resendLink = async(email, isEmailVerify, updatedEmail, isPersonal) => {
  const body = {
    email: email,
    isPersonal: isPersonal,
    isEmailVerify: isEmailVerify
  };
  if (updatedEmail) {
    body.updatedEmail = updatedEmail;
  }
  try {
    await requestService.postWithJson(`${endpoints.resendLink}`, null, body, {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);

    throw e;
  }
};

export const resendCode = async(email, isEmailVerify, phone, updatedPhone, isPersonal) => {
  const body = {
    phone: phone,
    isPersonal: isPersonal
  };
  if (updatedPhone) {
    body.updatedPhone = updatedPhone;
  }
  if (email) {
    body.email = email;
    body.isEmailVerify = isEmailVerify;
  }
  try {
    const userObj = await requestService.postWithJson(`${endpoints.resendLink}`, null, body, {apiType: 'platform-v2'});
    return userObj;
  } catch (e) {
    console.error(e);

    throw e;
  }
};

export const sendTutorialEmail = async() => {
  try {
    await requestService.get(`${endpoints.sendTutorial}`, null, {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);

    throw e;
  }
};
