import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import i18n from '../i18n';
import { format } from '../utils/timeUtils';

const StyledDialog = styled.div`
  position: relative;
  width: 300px;
  z-index: 5;

  margin: auto;
  margin-top: 10px;
  display: block;
`;

const StyledTitle = styled.h2`
  text-align: center;
`;

const StyledWaitingDuration = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #888888 !important;
`;

const CallJoinTimers = ({ title, seconds }) => (
  <StyledDialog>
    <StyledTitle>
      {title}
    </StyledTitle>
    <StyledWaitingDuration>
      {i18n('youHaveWaitedFor').replace('{}', format(seconds))}
    </StyledWaitingDuration>
  </StyledDialog>
);

CallJoinTimers.propTypes = {
  title: PropTypes.string.isRequired,
  seconds: PropTypes.number.isRequired
};

export default CallJoinTimers;
