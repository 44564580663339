import { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import i18n from '../i18n';
import { setUserPassword } from '../services/userService';
import { showError } from '../services/toastService';
import ThemeContext from '../contexts/ThemeContext';
import { isValidPassword } from '../utils/validateFields';
import { logAnalytics } from '../services/analyticsLogger';
import { getUserFromLocalstorage } from '../utils/localStorage';
import { RoundButton } from './Buttons';
import { StepInput } from './Input';
import Spinner from '../components/Spinner';
import {
  ContainerStepBox,
  ContentStepBox,
  StepsHeader,
  StepLable,
  FlexRow,
  FlexItem,
  StyledErrorMessage
} from './basicComponents';
import { SELFREGISTRATION_ROUTES, REDIRECT_DELAY } from '../utils/redirectUtils';
import { sendTutorialEmail } from '../services/authService';

const InfoBlock = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: #151529;
`;

const StyledFlexRow = styled(FlexRow)`
  justify-content: center;
`;

const StyledFormContainer = styled(FlexRow)`
  margin-top: 16px;
`;

const SetPasswordStep = () => {
  const { colors } = useContext(ThemeContext);
  const history = useHistory();
  const [user] = useState(getUserFromLocalstorage());
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errors, setErrors] = useState({
    password: null,
    repeatPassword: null
  });

  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);

  useEffect(() => {
    if (isPasswordUpdated) {
      setTimeout(redirectToNextAuthStep, REDIRECT_DELAY);
    }

    return () => clearTimeout(redirectToNextAuthStep, REDIRECT_DELAY);
  }, [isPasswordUpdated, redirectToNextAuthStep]);

  const redirectToNextAuthStep = useCallback(() => {
    if (isPasswordUpdated) {
      history.replace(SELFREGISTRATION_ROUTES.auth_complete);
      sendTutorialEmail();
    }
  }, [history, isPasswordUpdated]);

  const isPasswordValide = () => {
    return isValidPassword(password) && password === repeatPassword;
  };

  const checkPasswords = (key) => {
    switch (key) {
      case 'password':
        if (!isValidPassword(password)) {
          setErrors(prev => ({...prev, ['password']: 'password_requirement'}));
        }
      break;
      case 'repeatPassword':
        if (password !== repeatPassword) {
          setErrors(prev => ({...prev, ['repeatPassword']: 'passwords_do_not_match'}));
        }
      break;
    }
  };

  const handleNext = async () => {
    logAnalytics('Self-registration set user password', {
      userId: user?.id
    });
    setLoading(true);
    try {
      await setUserPassword(password, '111111');
      setLoading(false);
      setIsPasswordUpdated(true);
    } catch (err) {
      setLoading(false);
      logAnalytics('Self-registration set user password error', {
        error: err?.message,
        userId: user?.id
      });
      showError(i18n('set_password_failed'));
    }
  };

  return (
    <ContainerStepBox>
      {loading && (<Spinner overlay={true} />)}
      <ContentStepBox>
        <FlexRow>
          <StepsHeader>{i18n('create_an_account')}</StepsHeader>
        </FlexRow>
        <StepsHeader>{i18n('set_up_your_password')}</StepsHeader>
        <StyledFormContainer>
          <FlexItem>
            <StepLable
              data-test='setPasswordStepEmailLabel'
            >
              {i18n('contact_info_email')}
            </StepLable>
            <InfoBlock>{user?.email}</InfoBlock>
          </FlexItem>
        </StyledFormContainer>
        <FlexRow>
          <FlexItem>
            <StepLable
              data-test='setPasswordStepPasswordLabel'
            >
              {i18n('password')}
              <span> *</span>
            </StepLable>
            <StepInput
              type="password"
              value={password}
              onChange={(value) => {
                setPassword(value);
                setErrors(prev => ({...prev, ['password']: null}));
              }}
              onBlur={() => checkPasswords('password')}
              data-test='setPasswordStepPasswordInput'
            />
            {errors.password ? (
              <StyledErrorMessage
                color={colors.error}
              >
                {i18n(errors.password)}
              </StyledErrorMessage>
            ) : null}
          </FlexItem>
        </FlexRow>
        <FlexRow>
          <FlexItem>
            <StepLable
              data-test='setPasswordStepRepeatPasswordLabel'
            >
              {i18n('repeat_password')}
              <span> *</span>
            </StepLable>
            <StepInput
              type="password"
              value={repeatPassword}
              onChange={(value) => {
                setRepeatPassword(value);
                setErrors(prev => ({...prev, ['repeatPassword']: null}));
              }}
              onBlur={() => checkPasswords('repeatPassword')}
              data-test='setPasswordStepRepeatPasswordInput'
            />
            {errors.repeatPassword ? (
              <StyledErrorMessage
                color={colors.error}
              >
                {i18n(errors.repeatPassword)}
              </StyledErrorMessage>
            ) : null}
          </FlexItem>
        </FlexRow>
      </ContentStepBox>
      <StyledFlexRow>
        <RoundButton
          data-test='setPasswordStepNextBtn'
          padding='25'
          label={i18n('submit')}
          onClick={handleNext}
          darkButton={false}
          disabled={!isPasswordValide()}
        />
      </StyledFlexRow>
    </ContainerStepBox>
  );
};

export default SetPasswordStep;
