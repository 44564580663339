import React, { useContext, useEffect, forwardRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';

import fi from 'date-fns/locale/fi';
import 'react-datepicker/dist/react-datepicker.css';

import ThemeContext from '../contexts/ThemeContext';

import { Required, RefInput, InputTitle } from './Input';

const GlobalStyle = createGlobalStyle`
  .disabled-datepicker {
    pointer-events: none;
  }
  .react-datepicker-wrapper {
    display: flex;
    flex: 1;
    .react-datepicker__input-container {
      display: flex;
      flex: 1;
      input {
        display: flex;
        flex: 1;
        border: none;
        border-radius: 6px;
        height: 50px;
        padding: ${({ padding }) => `${padding}px ${padding * 2}px`};
        background-color: ${({ backgroundColor }) => (backgroundColor)};
        font-size: ${({ fontSize }) => (fontSize)}px;
        color: ${({ color }) => (color)};
        ${({ fixedMaxWidth }) => (fixedMaxWidth ? '' : `
          &.react-datepicker-ignore-onclickoutside {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        `)}
      }
    }
  }
  .react-datepicker__tab-loop {
    .react-datepicker__tab-loop__start {

    }
    .react-datepicker-popper {
      ${({ fixedMaxWidth }) => (fixedMaxWidth ? `
        width: 100%;
        max-width: 350px;
      ` : `
        width: 100%;
        min-width: 100%;
      `)}
      &.react-datepicker-popper[data-placement^="bottom"] {
        padding: 0;
      }
      .react-datepicker {
        width: 100%;
        border-color: #F5F8FB;
        border-width: 2px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        ${({ fixedMaxWidth }) => (fixedMaxWidth ? `
          max-width: 350px;
          border-width: 1px;
          border-color: #d8d8dc;
          margin-left: -1px;
          margin-top: 5px;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        ` : '')}
        .react-datepicker__triangle {

        }
        .react-datepicker__navigation {
          top: 4px;
          .react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous,
          .react-datepicker__navigation-icon.react-datepicker__navigation-icon--next {
            &::before {
              height: 6px;
              top: calc(50% - 3px);
              left: calc(50% - 3px);
              width: 6px;
              border-width: 2px 2px 0 0;
              border-color: rgb(58, 56, 91);
            }
          }
          &:hover {
            border: none;
            border-radius: 6px;
            background-color: rgb(58, 56, 91);
            .react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous,
            .react-datepicker__navigation-icon.react-datepicker__navigation-icon--next {
              &::before {
                border-color: #ffffff;
              }
            }
          }
        }
        .react-datepicker__month-container {
          width: 100%;
          .react-datepicker__header {
            padding: 0;
            border-bottom: 1px solid rgb(58, 56, 91);
            background-color: #ffffff;
            .react-datepicker__current-month {
              font-size: ${({ fontSizeMedium }) => (fontSizeMedium)}px;
              font-weight: 400;
              color: rgb(58, 56, 91);
              padding: .75em .5em;
              text-transform: capitalize;
            }
            .react-datepicker__day-names {
              display: flex;
              flex: 1;
              margin-bottom: 0;
              .react-datepicker__day-name {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                padding: .75em .5em;
                margin: 0;
                font-size: ${({ fontSizeMedium }) => (fontSizeMedium)}px;
                font-weight: 400;
                color: rgb(58, 56, 91);
                text-transform: uppercase;
                line-height: normal;
              }
            }
            .react-datepicker__header__dropdown {
              .react-datepicker__month-dropdown-container,
              .react-datepicker__year-dropdown-container {
                margin-right: 30px;
                .react-datepicker__month-read-view--down-arrow,
                .react-datepicker__year-read-view--down-arrow {
                  border-width: 2px 2px 0 0;
                  border-color: rgb(58,56,91);
                  top: 2px;
                }
                .react-datepicker__month-dropdown,
                .react-datepicker__year-dropdown {
                  background-color: #ffffff;
                  border: 1px solid #d8d8dc;
                  border-radius: 6px;
                  padding: 5px 0;
                  .react-datepicker__month-option,
                  .react-datepicker__year-option {
                    line-height: 24px;
                    width: 90%;
                    transition: ${({ transition }) => (transition)};
                    &:hover {
                      background-color: rgb(58,56,91);
                      color: #ffffff;
                      border-radius: 6px;
                      .react-datepicker__navigation--years-upcoming,
                      .react-datepicker__navigation--years-previous {
                        &::before {
                          border-color: #ffffff;
                        }
                      }
                    }
                    .react-datepicker__navigation--years-upcoming {
                      top: 0;
                      &::before {
                        height: 10px;
                        width: 10px;
                        top: calc(50% - 3px);
                        left: calc(50% - 7px);
                        border-width: 2px 0 0 2px;
                        content: "";
                        display: block;
                        position: relative;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                        z-index: 9999;
                        border-style: solid;
                      }
                    }
                    .react-datepicker__navigation--years-previous {
                      top: 0;
                      &::before {
                        height: 10px;
                        width: 10px;
                        top: calc(50% - 10px);
                        left: calc(50% - 7px);
                        border-width: 0 2px 2px 0;
                        content: "";
                        display: block;
                        position: relative;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                        z-index: 9999;
                        border-style: solid;
                      }
                    }
                  }
                }
              }
            }
          }
          .react-datepicker__month {
            margin: 0;
            .react-datepicker__week {
              display: flex;
              flex: 1;
              .react-datepicker__day {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                font-size: ${({ fontSizeMedium }) => (fontSizeMedium)}px;
                font-weight: 400;
                color: rgb(58, 56, 91);
                padding: .75em .5em;
                border-radius: 6px;
                border: none;
                line-height: normal;
                transition: all 80ms linear 0s;
                margin: 0;
                width: auto;
                &.react-datepicker__day--keyboard-selected {
                  background-color: #ffffff;
                }
                &.react-datepicker__day--outside-month {
                  color: rgb(138, 138, 148);
                  background-color: rgb(245, 248, 251);
                  border-radius: 0;
                  border: none;
                }
                &.react-datepicker__day--disabled {
                  color: rgb(138, 138, 148);
                  background-color: rgb(245, 248, 251);
                  border-radius: 0;
                  border: none;
                }
                &.react-datepicker__day--selected {
                  color: white;
                  background-color: rgb(58, 56, 91);
                  border-radius: 6px;
                }
                &:not(.react-datepicker__day--disabled):hover {
                  color: white;
                  border: none;
                  border-radius: 6px;
                  background-color: rgb(58, 56, 91);
                }
              }
            }
          }
        }
      }
    }
    .react-datepicker__tab-loop__end {

    }
  }
`;

const StyledCalendarContainer = styled.div`
  position: relative;
  display: flex;
  flex: 2;
  align-self: stretch;
  margin-top: ${({ margin }) => (margin / 2)}px;
  margin-bottom: ${({ margin }) => (margin / 2)}px;
  margin-right: ${({ margin }) => (margin / 2)}px;
`;

const CustomInput = forwardRef(({ onChange, ...rest }, ref) => (
  <RefInput
    type="text"
    margin={0}
    ref={ref}
    onChange={(event) => {
      if (event?.target?.value) {
        const preparedValue = event.target.value.replaceAll(/\D+/g, '/').replaceAll(/\d+(?=\/)/g, (replacement) => {
          return replacement.length <= 1 ? 0 + replacement : replacement;
        });
        event.target.value = preparedValue;
      }
      onChange(event);
    }}
    {...rest}
  />
));

const Datepicker = ({
  fixedMaxWidth,
  title,
  selected,
  onChange,
  langCode,
  minDate,
  maxDate,
  required,
  showMonthDropdown,
  showYearDropdown,
  notBoldInputTitle,
  openToDate,
  disabled,
  ...rest
}) => {
  const { colors, layout } = useContext(ThemeContext);

  useEffect(() => {
    registerLocale('fi', fi);
  });

  return (
    <>
      <GlobalStyle
        fontSize={layout.fontSize}
        fontSizeMedium={layout.mediumFontSize}
        color={colors.webGray}
        padding={layout.padding}
        backgroundColor={colors.webLightGray}
        fixedMaxWidth={fixedMaxWidth}
        transition={layout.transition}
      />
      {
        title ? (
          <InputTitle
            color={colors.webDarkBlue}
            notBoldInputTitle={notBoldInputTitle}
            fontSize={layout.fontSize}
          >
            {title}{required ? (<Required/>) : ''}
          </InputTitle>
        ) : null
      }
      <StyledCalendarContainer
        margin={layout.margin}
        {...rest}
      >
        <DatePicker
          dateFormat={'dd/MM/yyyy'}
          selected={selected}
          onChange={onChange}
          locale={langCode}
          calendarStartDay={1}
          minDate={minDate}
          maxDate={maxDate}
          showPopperArrow={false}
          useWeekdaysShort={langCode === 'fi' ? false : true}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          customInput={<CustomInput/>}
          openToDate={openToDate}
          shouldCloseOnSelect={false}
          className={disabled && 'disabled-datepicker'}
        />
      </StyledCalendarContainer>
    </>
  );
};

export default Datepicker;
