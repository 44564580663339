import React, { useContext } from 'react';
import styled from 'styled-components';

import Icon from './Icon';

import ThemeContext from '../contexts/ThemeContext';

import { v4 as uuid } from 'uuid'; 

const StyledIcon = styled(Icon)`
  & > i, & > span {
    color: ${({ color }) => (color)} !important;
  }
`;

const StyledRequiredIcon = styled(Icon)`
  margin-right: 10px;
  & > i, & > span {
    color: ${({ color }) => (color)} !important;
  }
`;

const RowBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: transparent;
  ${({ padding, noPaddings }) => (noPaddings ? '' : `padding: ${padding * 2}px ${padding * 2}px`)};
`;

const ColBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  margin-left: ${({ isSetMarginLeft, margin }) => (isSetMarginLeft ? margin : 0)}px;
  margin-right: ${({ isSetMarginRight, margin }) => (isSetMarginRight ? margin: 45)}px;
  min-height: ${({ subtitle, lineHeight }) => (
    !subtitle ? 25 : (lineHeight || 48)
  )}px;
`;

const Title = styled.div`
  font-size: ${({ fontSize }) => (fontSize)}px;
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'left')};
  color: ${({ color }) => (color)};
  background-color: transparent;
  margin-bottom: ${({ subtitle }) => (subtitle ? '5px' : '0')};
  line-height: 18px;
`;

const Subtitle = styled.div`
  font-size: ${({ fontSize }) => (fontSize)}px;
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'left')};
  color: ${({ color }) => (color)};
  background-color: transparent;
  word-break: break-word;
`;

const StyledDevider = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const ListItemContainer = styled.div`
  flex: 1;
  flex-direction: row;
  ${({ alignCenter }) => (alignCenter ? 'text-align: center;' : '')};
  background-color: ${({ selected, selectedColor }) => (selected ? selectedColor : 'transparent')};
  border-radius: 6px;
  transition: ${({ transition }) => (transition)};
  overflow: hidden;
  ${({ readOnly, selectedColor }) => (!readOnly ? `
      cursor: pointer;
      &:hover {
        background-color: ${selectedColor};
        & ${Title} {
          color: white;
        }
        & ${StyledIcon} > i, & ${StyledIcon} > span {
          color: white !important;
        }
      }
    ` : ''
  )}
  &:last-child {
    & ${StyledDevider} {
      border-color: transparent !important;
    }
  }
`;

const ActionListItem = (props) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  // const [titleOpacity, setTitleOpacity] = useState(new Animated.Value(1.0)); //eslint-disable-line no-unused-vars
  // const [value, setValue] = React.useState('');
  //
  // const textInput = useRef(null);
  //
  // useEffect(() => {
  //   Animated.loop(
  //     Animated.sequence([
  //       Animated.timing(titleOpacity, {
  //         duration: 1000,
  //         toValue: 0.3,
  //         useNativeDriver: true
  //       }),
  //       Animated.timing(titleOpacity, {
  //         duration: 1000,
  //         toValue: 1.0,
  //         useNativeDriver: true
  //       }),
  //       Animated.delay(1000)
  //     ])
  //   ).start();
  // }, [props.animateTitle]);
  //
  // const getIndicatorStyling = () => {
  //   if (!props.indicator) {
  //     return {
  //       height: 0
  //     };
  //   }
  //
  //   return {};
  // };
  //
  // const onChangeText = text => {
  //   setValue(text);
  //   props.onText(text);
  // };
  //
  //
  //
  // const Icon = props.iconLib ? icons[props.iconLib] : null;
  // const icon = Icon ? <Icon name={props.icon} style={props.imageSizeIcon ? styles.imageIcon : styles.icon} size={25} color={props.iconColor || colors.contentBoxBorderColorLight2020} /> : null;
  //
  // const Icon2 = props.iconLib2 ? icons[props.iconLib2] : null;
  // const icon2 = Icon2 ? <Icon2 name={props.icon2} style={[styles.icon, styles.icon2]} size={25} color={colors.contentBoxBorderColorLight2020} /> : null;
  //
  // const IconRight = props.rightIconLib ? icons[props.rightIconLib] : null;
  // const iconRight = IconRight ? (
  //   <View style={[styles.rightSubtitleContainer, {
  //     width: 25
  //   }]}>
  //     <IconRight name={props.rightIcon} style={[styles.icon, styles.rightIcon]} size={25} color={props.rightIconColor || colors.contentBoxBorderColorLight2020} />
  //   </View>) : null;
  //
  // const chevron = props.toggle || props.chevron === false ?
  //   null : <EntypoIcon name='chevron-thin-right' style={styles.chevron} size={14} color={colors.contentBoxBorderColorLight2020} />;
  //
  // const image = props.imageUrl && !context.noImages ? (
  //   <Image
  //     style={styles.image}
  //     resizeMode='cover'
  //     resizeMethod='scale'
  //     progressiveRenderingEnabled={true}
  //     source={{
  //       uri: props.imageUrl
  //     }}
  //   />) : null;
  //
  // const checkbox = props.checkbox === true ?
  //   (Platform.OS === 'ios' ?
  //     <Switch
  //       ios_backgroundColor={colors.secondaryButton2020}
  //       trackColor={{false: colors.secondaryButton2020, true: colors.containerColor2020}}
  //       onValueChange={props.onCheck}
  //       value={props.checked} /> :
  //
  //     <Switch
  //       thumbColor={props.checked ? colors.containerColor2020 : colors.scrollListColor2020}
  //       trackColor={{false: colors.secondaryButton2020, true: colors.secondaryButton2020}}
  //       onValueChange={props.onCheck}
  //       value={Boolean(props.checked)} />
  //   ) : null;
  //
  // const title = props.title ? (
  //   <View style={[styles.titleContainer, {
  //     minHeight: props.subtitle ? ((styles.buttonTextSmall ? 16 : 18) + 6) : (props.lineHeight || 48)
  //   }]}>
  //     <Animated.Text style={[styles.buttonText, {
  //       fontFamily: props.boldTitle ? colors.titleFont : colors.textFont,
  //       color: props.titleColor || colors.textColor2020,
  //       lineHeight: props.subtitle ? ((styles.buttonTextSmall ? 16 : 18) + 6) : (props.lineHeight || 24),
  //       opacity: props.animateTitle ? titleOpacity : 1.0,
  //       textAlign: props.center === true ? 'center' : 'left'
  //     }, props.minorItem ? styles.buttonTextSmall : null]} accessibilityRole='menuitem'>{props.title}</Animated.Text>
  //   </View>
  // ) : null;

  // const subtitle = props.subtitle ? (
  //   <Text style={[styles.subtitle, {
  //     fontFamily: colors.textFont,
  //     color: props.subtitleColor || colors.contentBoxBorderColor2020
  //   }]}>{props.subtitle}</Text>
  // ) : null;
  //
  // const subtitle2 = props.subtitle2 ? (
  //   <Text style={[styles.subtitle, {
  //     fontFamily: colors.textFont,
  //     color: props.subtitle2Color || colors.contentBoxBorderColor2020
  //   }]}>{props.subtitle2}</Text>
  // ) : null;
  //
  // const subtitle3 = props.subtitle3 ? (
  //   <Text style={[styles.subtitle, {
  //     fontFamily: colors.textFont,
  //     color: props.subtitle3Color || colors.contentBoxBorderColor2020
  //   }]}>{props.subtitle3}</Text>
  // ) : null;
  //
  // const rightSubtitle = props.rightSubtitle ? (
  //   <View style={[styles.rightSubtitleContainer, {
  //     width: props.rightSubtitleWidth || 60
  //   }]}>
  //     <Text style={[styles.rightSubtitle, {
  //       fontSize: props.rightSubtitleFontSize || 16,
  //       fontFamily: colors.textFont,
  //       color: props.rightSubtitleColor || colors.textColor2020,
  //       lineHeight: props.rightSubtitleAlign === 'center' ? 42 : 20
  //     }]}>{props.rightSubtitle}</Text>
  //   </View>
  // ) : null;
  //
  // const textEdit = props.onText ? (
  //   <TextInput style={[styles.textEdit, {
  //       color: colors.textColor2020,
  //       backgroundColor: colors.scrollListColor2020,
  //       borderColor: colors.secondaryButton2020,
  //       fontFamily: colors.textFont
  //     }]}
  //     ref={textInput}
  //     placeholderTextColor={colors.contentBoxBorderColorLight2020}
  //     placeholder={props.textPlaceholder}
  //     onChangeText={onChangeText}
  //     value={value}
  //     returnKeyType='search'
  //     returnKeyLabel={props.returnKeyLabel}
  //     maxLength={80}
  //     underlineColorAndroid={'transparent'}
  //     autoCompleteType='off'
  //     autoCorrect={false}
  //     autoCapitalize='none'
  //     importantForAutofill='no'
  //   />) : null;

  // const title = props.title ? (
  //   <View style={[styles.titleContainer, {
  //     minHeight: props.subtitle ? ((styles.buttonTextSmall ? 16 : 18) + 6) : (props.lineHeight || 48)
  //   }]}>
  //     <Animated.Text style={[styles.buttonText, {
  //       fontFamily: props.boldTitle ? colors.titleFont : colors.textFont,
  //       color: props.titleColor || colors.textColor2020,
  //       lineHeight: props.subtitle ? ((styles.buttonTextSmall ? 16 : 18) + 6) : (props.lineHeight || 24),
  //       opacity: props.animateTitle ? titleOpacity : 1.0,
  //       textAlign: props.center === true ? 'center' : 'left'
  //     }, props.minorItem ? styles.buttonTextSmall : null]} accessibilityRole='menuitem'>{props.title}</Animated.Text>
  //   </View>
  // ) : null;
  return (
    <ListItemContainer
      key={() => uuid()}
      data-test={props['data-test']}
      readOnly={props.readOnly}
      transition={layout.transition}
      selected={props.selected}
      selectedColor={colors.tulkaMainColor}
      onClick={(data) => {
        if (!props.readOnly) {
          props.onClick(data);
        }
      }}
    >
      <RowBlock
        padding={layout.padding}
        noPaddings={props.noPaddings}
      >
        {props.icon && <StyledIcon
          color={props.selected ? 'white' : colors.webDarkBlue}
          margin={layout.margin}
          iconLib={props.iconLib}
          icon={props.icon}
          iconColor={props.iconColor}
        />}
        <ColBlock
          isSetMarginLeft={props.icon}
          isSetMarginRight={props.selected}
          margin={layout.margin}
          subtitle={props.subtitle}
          lineHeight={props.lineHeight}
        >
          {props.title ? (
            <Title
              alignCenter={props.alignCenter}
              fontSize={layout.fontSize}
              subtitle={props.subtitle}
              color={props.selected ? 'white' : colors.webDarkBlue}
            >
              {props.title}
            </Title>
          ) : null}
          {props.subtitle ?
            Array.isArray(props.subtitle) ?
              props.subtitle.map(sub => (
                <Subtitle
                  key={sub}
                  alignCenter={props.alignCenter}
                  fontSize={layout.mediumFontSize}
                  color={colors.webGray}
                >
                  {sub}
                </Subtitle>
              )) : (
            <Subtitle
              alignCenter={props.alignCenter}
              fontSize={layout.mediumFontSize}
              color={colors.webGray}
            >
              {props.subtitle}
            </Subtitle>
          ) : null}
        </ColBlock>
        {props.selected ? (
          <StyledIcon
            color={'white'}
            margin={layout.margin}
            iconLib={'material'}
            icon={'check'}
          />
        ) : null}
        {props.required ? (
          <StyledRequiredIcon
            color={colors.error}
            margin={layout.margin}
            iconLib={'material'}
            icon={'warning_amber'}
          />
        ) : null}
        {props.editable ? (
          <StyledIcon
            margin={layout.margin}
            iconLib={'material'}
            icon={'edit'}
          />
        ) : null}
      </RowBlock>
      <StyledDevider />
    </ListItemContainer>
  );
  // return (
  //   <TouchableDebounce
  //     style={({ pressed }) => ([styles.item, {
  //       backgroundColor: props.selected || pressed ? (colors.contentBoxBorderColor2020 + '40') : 'transparent'
  //     }])}
  //     onPress={props.onPress}
  //     onLayout={props.onLayout}
  //     delay={1000}
  //   >
  //     <View style={styles.itemRow}>
  //       <View>
  //         {icon}
  //         {icon2}
  //         {image}
  //       </View>
  //       <View style={styles.textCol}>
  //         {textEdit}
  //         {title}
  //         {subtitle}
  //         {subtitle2}
  //         {subtitle3}
  //       </View>
  //       {chevron}
  //       {checkbox}
  //       {rightSubtitle}
  //       {iconRight}
  //       <ActivityIndicator
  //         animating={props.indicator}
  //         size={'small'}
  //         color={'white'}
  //         style={[styles.indicator, getIndicatorStyling()]} />
  //     </View>
  //   </TouchableDebounce>
  // );
};

// const styles = StyleSheet.create({
//   icon: {
//     width: 25,
//     height: 27,
//     marginRight: Layout.margin,
//     backgroundColor: 'transparent',
//     textAlign: 'center'
//   },
//   icon2: {
//     marginTop: 10,
//     opacity: 0.8
//   },
//   rightIcon: {
//     marginRight: 0
//   },
//   image: {
//     width: 50,
//     height: 50,
//     borderRadius: 25,
//     marginRight: Layout.margin,
//     backgroundColor: 'transparent'
//   },
//   imageIcon: {
//     width: 50,
//     height: 50,
//     borderRadius: 25,
//     lineHeight: 50,
//     textAlign: 'center',
//     marginRight: Layout.margin,
//     backgroundColor: 'transparent'
//   },
//   chevron: {
//     width: 14,
//     height: 14,
//     marginLeft: Layout.margin,
//     backgroundColor: 'transparent'
//   },
//   titleContainer: {
//     justifyContent: 'center',
//     flex: 1
//   },
//   buttonText: {
//     fontSize: 18,
//     textAlign: 'left',
//     backgroundColor: 'transparent',
//     marginBottom: 5
//   },
//   buttonTextSmall: {
//     fontSize: 16
//   },
//   subtitle: {
//     fontSize: 14,
//     textAlign: 'left',
//     backgroundColor: 'transparent'
//   },
//   rightSubtitle: {
//     fontSize: 16,
//     textAlign: 'right',
//     backgroundColor: 'transparent'
//   },
//   rightSubtitleContainer: {
//     flexDirection: 'column',
//     flexWrap: 'nowrap',
//     alignSelf: 'stretch',
//     alignItems: 'flex-end',
//     justifyContent: 'flex-start',
//     backgroundColor: 'transparent'
//   },
//   indicator: {
//     overflow: 'hidden',
//     position: 'absolute',
//     left: 0,
//     right: 0,
//     top: 0,
//     bottom: 0
//   },
//   textEdit: {
//     height: 50,
//     flex: 1,
//     fontSize: 16,
//     textAlign: 'left',
//     alignSelf: 'stretch',
//     backgroundColor: 'transparent',
//     borderRadius: 5,
//     borderWidth: 1,
//     paddingLeft: Layout.margin,
//     paddingRight: Layout.margin
//   }
// });

export default ActionListItem;
