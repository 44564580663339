import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import i18n, { getIsRightToLeft, langCode } from '../i18n';

import { cancelPrebooking, getPrebookings } from '../services/prebookService';
import { logAnalytics } from '../services/analyticsLogger';
import { setBookingDate, isGonePrebooking, isCancelledPrebooking, MIN_BEFORE_PREBOOKING_START } from '../utils/setBookingDate';
import { getSkillsName } from '../utils/getSkillsName';
import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import { FullBackgroundImageContainer } from '../components/basicComponents';
import luona_background from '../images/luona_back.jpg';
import { LuonaFormTitle } from '../components/Input';
import { LuonaButton } from '../components/Buttons';
import Spinner from '../components/Spinner';

import Modal from 'react-modal';
Modal.setAppElement('#main');

const ImagePageContainer = styled(FullBackgroundImageContainer)`
  height: auto;
  min-height: 100vh;
`;

const PageContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 10px;
`;

const PrebookingsList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const PrebookItem = styled.li`
  width: 300px;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid ${({ color }) => color};

  :hover {
    transition: ${({ transition }) => transition};
    box-shadow: 0 1px 2px 0 ${({ color }) => color};
  }
  .removePrebookButton {
    margin: 0 auto;
  }
`;

const PrebookItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0px;
    .skills {
      text-align: left;
    }
  }
  ${({rtl}) => rtl && 'flex-flow: row-reverse;'}
`;

const ModalConfirmBlock = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
`;

const ModalConfirmText = styled.p`
  text-align: center;
`;

const ModalButtons = styled.div`
  display: flex;
  button:not(:last-child) {
    margin-right: ${({ rtl }) => rtl ? 0 : 10}px;
  }
  button:not(:first-child) {
    margin-right: ${({ rtl }) => rtl ? 10 : 0}px;
  }
  ${({ rtl }) => rtl && 'flex-flow: row-reverse;'}
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PrebookingLuona = () => {
  const [locale, setLocale] = useState(langCode);
  moment.locale(locale === 'ua' ? 'uk' : locale);
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { colors, layout } = useContext(ThemeContext);
  const { sessionExpired } = useContext(FunctionsContext);
  const [today] = useState(moment());
  const [tomorrow] = useState(moment().add(1, 'day'));
  const [prebook, setPrebook] = useState(null);
  const [prebookings, setPrebookings] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] =
    useState(false);
  const [prebookCancelModal] = useState({
    message: i18n('cancel_prebook_confirmation_message'),
    buttons: [],
    close: async id => {
      cancelPrebookingRequest(id);
    },
  });
  const history = useHistory();
  
  useEffect(()=>{
    const lang = localStorage.getItem('lang');
    if (lang) {
      setLocale(lang);
    }
  }, []);

  const isAuthorize = () => {
    const userJson = localStorage.getItem('user');
    if (userJson !== null) {
      try {
        const userData = JSON.parse(userJson);
        return !!userData?.token;
      } catch (e) {
        console.error('Failed to parse user.', e);
      }
    }
     return false;
  };

  useEffect(() => {
    const getPrebookingData = async() => {
      getPrebookingsRequest();
    };
    if (isAuthorize()) {
      getPrebookingsRequest();
    }

    document.addEventListener('setUserToken', getPrebookingData);
    return () => {
      document.removeEventListener('setUserToken', getPrebookingData);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPrebookingsRequest = async () => {
    setLoading(true);
    try {
      const prebookingData = await getPrebookings(true, user.type);
      const data = prebookingData.prebookings?.length ? prebookingData.prebookings.filter(pr => !pr.cancelledAt) : [];
      setPrebookings(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      logAnalytics('Error get prebookings', {
        error: error?.message || error,
        userId: user?.id
      });
      setLoading(false);
    }
  };

  const cancelPrebookingRequest = async prebookId => {
    setLoading(true);
    try {
     await cancelPrebooking(prebookId, { userType: 'Customer'});
      setItemsData((prev) => (prev.filter(item => item.id !== prebookId)));
      setLoading(false);
    } catch (e) {
      logAnalytics('Error cancel prebooking', {
        error: e?.message || e,
        userId: user?.id
      });
      if (e.message === 'Session expired') {
        sessionExpired();
      }
      setLoading(false);
    }
  };

  const modalDialogStyles = {
    overlay: {
      zIndex: 999,
      backgroundColor: colors.luonaModalOverlay,
    },
    content: {
      width: '100%',
      maxWidth: '300px',
      height: '35%',
      minHeight: '250px',
      maxHeight: '750px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      borderRadius: '15px',
      border: 'none',
      overflow: 'initial',
      display: 'flex',
    },
  };

  function isShowStartMeetingButton(booking){
    let availableTimeBeforeStart = booking.interpretationDate - MIN_BEFORE_PREBOOKING_START < Date.now();
    return (availableTimeBeforeStart && !isCancelledPrebooking(booking) && !isGonePrebooking(booking));
  }

  useEffect(() => {
    const currentPrebooks = prebookings
      .map(booking => {
        return {
          id: booking.id,
          date: setBookingDate(booking, { today, tomorrow }),
          durationEstimated: booking.durationEstimated,
          durationAsString: moment
            .duration(booking.durationEstimated, 'seconds')
            .format(`h [${i18n('hrs')}] m [${i18n('min')}]`, {
              trim: false,
            }),
          skills: getSkillsName(booking.skills),
          interpretationDate: booking.interpretationDate,
          leewayBefore: booking.leewayBefore,
          leewayAfter: booking.leewayAfter,
          cancelledAt: booking.cancelledAt
        };
      });
    setItemsData(currentPrebooks);
  }, [prebookings, today, tomorrow]);

  function closeConfirmationModal(confirmed) {
    setConfirmationDialogIsOpen(false);
    if (confirmed) {
      prebookCancelModal.close(prebook.id);
    }
  }

  return (
    <>
      {loading && (
        <Spinner overlay={true} color={colors.luonaMainBrightColor} />
      )}
      <ImagePageContainer
        blendMode='rgba(255, 255, 255, 0.75)'
        backgroundImage={luona_background}
      >
        <Modal isOpen={confirmationDialogIsOpen} style={modalDialogStyles}>
          <ModalConfirmBlock data-test='modalConfirmRemovePrebook'>
            <ModalConfirmText data-test='messageModalConfirm'>
              {i18n('cancel_prebook_confirmation_message')}
            </ModalConfirmText>
            <ModalButtons rtl={getIsRightToLeft()}>
              <LuonaButton
                data-test='noConfirmBtn'
                label={i18n('no')}
                handleClick={() => {
                  closeConfirmationModal(false);
                }}
              />
              <LuonaButton
                data-test='yesConfirmBtn'
                label={i18n('yes')}
                handleClick={() => {
                  closeConfirmationModal(true);
                }}
              />
            </ModalButtons>
          </ModalConfirmBlock>
        </Modal>
        <PageContainer>
          <LuonaFormTitle
            data-test='prebookingPageTitle'
            position='center'
            title={i18n('prebooking_page_title')}
          />
          <PrebookingsList>
            {itemsData?.map(item => {
              if (!isGonePrebooking(item)) {
                return (
                  <PrebookItem
                    data-test={`prebookItem-${item.id}`}
                    key={item.id}
                    color={colors.luonaMainBrightOpacity}
                    transition={layout.transition}
                  >
                    <PrebookItemRow rtl={getIsRightToLeft()}>
                      <p data-test='datePrebookItem' >{item?.date}</p>
                      <p data-test='durationEstimatedPrebookItem'>{item.durationAsString}</p>
                    </PrebookItemRow>
                    <PrebookItemRow rtl={getIsRightToLeft()}>
                      <p className='skills' data-test='skillsPrebookItem'>
                        {item.skills && (<span>{item.skills}</span>)}
                      </p>
                    </PrebookItemRow>
                    <ButtonsRow>
                      {!isShowStartMeetingButton(item) && (
                        <LuonaButton
                          data-test='removePrebookBtn'
                          className='removePrebookButton'
                          label={i18n('remove')}
                          handleClick={() => {
                            setConfirmationDialogIsOpen(true);
                            setPrebook(item);
                          }}
                        />
                      )}
                      {isShowStartMeetingButton(item) && (
                        <LuonaButton
                          data-test='startPrebookBtn'
                          className='removePrebookButton'
                          label={i18n('start_prebook')}
                          handleClick={() => {
                            logAnalytics('Click on start prebook', {
                              userId: user?.id
                            });
                            history.replace(`/meetings/123?prebookId=${item.id}`);
                          }}
                        />
                      )}
                    </ButtonsRow>
                  </PrebookItem>
                );
              } else {
                return null;
              }
            })}
            {!prebookings.length && !loading && <p data-test='defaultPrebookingPageMessage'>{i18n('defaultPrebookingPageMessage')}</p>}
          </PrebookingsList>
          <LuonaButton
            data-test='goToServicesBtn'
            label={i18n('go_to_services')}
            margin='30px auto'
            width='auto'
            handleClick={() => history.replace('/wizard', {stepName: 'ServicesStep'})}
          />
        </PageContainer>
      </ImagePageContainer>
    </>
  );
};

export default PrebookingLuona;
