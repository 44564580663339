import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import amplitude from 'amplitude-js';

import ThemeContext from '../contexts/ThemeContext';

import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import { Input } from './Input';
import i18n from '../i18n';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const MessageContainer = styled.div`
  margin-bottom: 5px;
`;

const TextBlock = styled.div`
  font-size: ${({ fontSize }) => (fontSize)}px;
  font-weight: normal;
  letter-spacing: .5px;
  margin-top: 15px;
  color: ${({ color }) => color};
`;

const ContentData = ({
  contentData,
  setContentData,
}) => {
  const { colors, layout } = useContext(ThemeContext);

  return (
    <StyledContainer>
      <ChatWizardHeader
        title={i18n('write_the_content')}
      />
      <ContentBox overlapping={true}>
        <MessageContainer>
          <Input
            active
            notBoldInputTitle
            title={i18n('write_the_content_of_the_matter_to_be_reported_below')}
            type="textarea"
            value={contentData}
            onChange={setContentData}
            maxLength="1000"
            onFocus={() => {
              amplitude.getInstance().logEvent('Edit content of prebook');
            }}
            height={200}
          />
        </MessageContainer>
        <TextBlock
          fontSize={layout.mediumFontSize}
          color={colors.webGray}
        >
          {i18n('content_data_tooltip')}
        </TextBlock>
        <TextBlock
          fontSize={layout.mediumFontSize}
          color={colors.webGray}
        >
          {i18n('content_data_second_tooltip')}
        </TextBlock>
      </ContentBox>
    </StyledContainer>
  );
};

ContentData.propTypes = {
  contentData: PropTypes.string,
  setContentData: PropTypes.func,
};

export default ContentData;
