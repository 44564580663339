import React, { useContext } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import Icon from '../components/Icon';

import ThemeContext from '../contexts/ThemeContext';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

const ErrorHeader = styled.div`
  font-size: 30px;
  text-transform: uppercase;
  color: ${({ color }) => (color)};
  margin-right: 10px;
`;

const ErrorText = styled.div`
  color: ${({ color }) => (color)};
  font-size: 18px;
  text-align: center;
`;

const ErrorPage = () => {
  const { colors } = useContext(ThemeContext);
  return (
    <StyledContainer>
      <Row>
        <ErrorHeader color={colors.errorColor}>Error</ErrorHeader>
          <Icon
            icon={'error_outline'}
            iconLib="material"
            iconSize="40"
            iconColor={colors.errorColor}
          />
      </Row>
      <ErrorText color={colors.luonaGrayTextColor}>{i18n('requestErrorMessage')}</ErrorText>
    </StyledContainer>
  );
};


export default ErrorPage;
