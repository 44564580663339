import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import amplitude from 'amplitude-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneSlash, faVideoSlash } from '@fortawesome/free-solid-svg-icons';

import Icon from './Icon';

const StyledEndSessionIcon = styled(Icon)`
  width: 60px;
  height: 60px;
  margin: 0 40px;
  background-color: #e47788;
  border-radius: 50%;
  cursor: pointer;
  transition: all 80ms linear;
  & > i, & > span {
    transform: rotate(98deg);
  }
  &:hover, &:focus, &:active {
    background-color: #bd5c6b;
  }
`;

const StyledFontAwesomeIconContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
  transition: all 80ms linear;
  background-color: ${({ active }) => (active ? 'white' : 'transparent')};
  & > svg {
    transition: all 80ms linear;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 24px !important;
  height: 24px !important;
`;

const StyledCallActionContainer = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  height: 60px;
  z-index: 6;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

const DeviceIcon = ({ active, name, onClick }) => (
  <StyledFontAwesomeIconContainer
    active={active}
    onClick={onClick}
  >
    <StyledFontAwesomeIcon
      icon={name}
      color={active ? '#46446F' : 'white'}
    />
  </StyledFontAwesomeIconContainer>
);

const CallActions = ({ endCall, toggleAudio, toggleCamera }) => {
  const [audioDisabled, setAudioDisabled] = useState(false);
  const [cameraDisabled, setCameraDisabled] = useState(false);

  const onToggleAudio = () => {
    toggleAudio(audioDisabled);
    setAudioDisabled(!audioDisabled);
    amplitude.getInstance().logEvent('Toggle audio');
  };

  const onToggleCamera = () => {
    toggleCamera(cameraDisabled);
    setCameraDisabled(!cameraDisabled);
    amplitude.getInstance().logEvent('Toggle video');
  };

  return (
    <StyledCallActionContainer>
      <DeviceIcon
        name={faMicrophoneSlash}
        active={audioDisabled}
        onClick={onToggleAudio}
      />
      <StyledEndSessionIcon
        icon="phone"
        iconLib="fontAwesome"
        iconColor="white"
        iconSize={36}
        onClick={endCall}
      />
      <DeviceIcon
        name={faVideoSlash}
        active={cameraDisabled}
        onClick={onToggleCamera}
      />
    </StyledCallActionContainer>
  );
};

CallActions.propTypes = {
  toggleAudio: PropTypes.func.isRequired,
  endCall: PropTypes.func.isRequired,
  toggleCamera: PropTypes.func.isRequired
};

export default CallActions;
