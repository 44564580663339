import React, { useContext } from 'react';
import styled from 'styled-components';

import ThemeContext from '../contexts/ThemeContext';

const StyledPanel = styled.div`
  display: flex;
  flex: 0 1 auto;
  height: ${({ heightAuto }) => (heightAuto ? 'auto' : '98px')};
  justify-content: center;
  padding: ${({ padding }) => (padding)}px;
  box-sizing: border-box;
  & button:not(:last-child) {
    margin-right: ${({ padding, rtl }) => rtl ? 0 : (padding * 2)}px;
  }
  & button:not(:first-child) {
    margin-right: ${({ padding, rtl }) => rtl ? (padding * 2) : 0}px;
  }
  `;

const StyledContainer = styled.div`
  ${({ column }) => (column ? `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  ` : '')}
  ${({rtl}) => rtl && `
    display: flex; 
    flex-flow: row-reverse;
  `}
`;

const ButtonPanel = ({ heightAuto, column, children, rtl }) => {
  const themeContext = useContext(ThemeContext);

  const layout = themeContext.layout;

  return (
    <StyledPanel
      heightAuto={heightAuto}
      padding={layout.padding}
      rtl={rtl}
    >
      <StyledContainer column={column} rtl={rtl}>
        {children}
      </StyledContainer>
    </StyledPanel>
  );
};

export default ButtonPanel;
