import React, { useContext } from 'react';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import IconButton from './IconButton';

import i18n from '../i18n';

import ThemeContext from '../contexts/ThemeContext';

const CollaboratorsContainer = styled.div`
  height: 32px;
  flex: 0 1 auto;
  display: flex;
  justify-content: flex-start;
  ${({ isAnimated }) => (isAnimated ? `
    &:hover {
      ${CollaboratorsAvatar} {
        &:not(:first-child) {
          margin-left: -15px;
        }
      }
      ${StyledIconButton} {
        margin-left: -15px;
      }
    }
  ` : '')}
`;

const CollaboratorsAvatar = styled.div`
  width: 32px;
  height: 32px;
  font-size: 12px;
  padding-top: 6px;
  border: 1px solid #1F1F3F;
  cursor: pointer;
  transition: ${({ transition }) => (transition)};
  margin-left: -25px;
  border-radius: 50%;
  background-color: ${({ background }) => background};
  color: #ffffff;
  text-align: center;
`;

const StyledTooltipContainer = styled.div`
  width: 100%;
  max-width: 300px;
`;

const StyledTooltip = styled(Tooltip)`
  display: flex;
  justify-content: center;
`;

const StyledTooltipText = styled.div`
  color: white;
  font-size: ${({ fontSize }) => (fontSize)}px;
  line-height: ${({ fontSize }) => (fontSize + 2)}px;
  text-align: left;
  font-weight: normal;
  word-wrap: break-word;
`;

const CollaboratorsPreview = ({ avatarColor, collaboratorsToShow, openCollaboratorsManagementDialog, getNameInitials }) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  return (
    <CollaboratorsContainer
      isAnimated={collaboratorsToShow && collaboratorsToShow.length > 0}
    >
      {
        collaboratorsToShow && collaboratorsToShow.length > 0 ? collaboratorsToShow.map((collaborator) => {
          return (
            <CollaboratorsAvatar
              key={collaborator.id}
              background={avatarColor}
              transition={layout.transition}
              onClick={() => openCollaboratorsManagementDialog()}
            >
              {getNameInitials(collaborator.name)}
            </CollaboratorsAvatar>
          );
        }) : null
      }

      <StyledTooltip
        content={(
          <StyledTooltipContainer
            padding={layout.padding}
          >
            <StyledTooltipText
              fontSize={layout.mediumFontSize}
            >
              {i18n('add_collaborators')}
            </StyledTooltipText>
          </StyledTooltipContainer>
        )}
        background="#1F1F3F"
        className="target"
        direction="top"
      >
        <StyledIconButton
          icon="add"
          iconSize={20}
          iconLib="material"
          iconColor={colors.webGray}
          hoverColor="#ffffff"
          onClick={() => openCollaboratorsManagementDialog()}
          data-test='addCollaboratorsButton'
        />
      </StyledTooltip>
    </CollaboratorsContainer>
  );
};

const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  background: #1F1F3F;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  margin-left: -25px;
  & > div {
    width: 100%;
    height: 100%;
    margin: auto;
    & > i {
      color: ${({ iconColor }) => (iconColor)} !important;
    }
  }
  &:hover {
    background: #1F1F3F;
    & > div > i {
      color: ${({ hoverColor }) => (hoverColor)} !important;
    }
  }
`;

export default CollaboratorsPreview;
