import { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ThemeContext from '../contexts/ThemeContext';
import styled from 'styled-components';
import i18n from '../i18n';
import { showError } from '../services/toastService';
import { resendLink, resendCode } from '../services/authService';
import { logAnalytics } from '../services/analyticsLogger';
import { getUserFromLocalstorage } from '../utils/localStorage';
import { RoundButton } from './Buttons';
import { StepInput, CheckboxButton } from './Input';
import Spinner from '../components/Spinner';
import NotUnicEmailInfoStep from '../components/NotUnicEmailInfoStep';
import InvalidDomainInfoStep from '../components/InvalidDomainInfoStep';
import {
  ContainerStepBox,
  ContentStepBox,
  StepsHeader,
  StepLable,
  FlexRow,
  FlexItem,
  StyledErrorMessage
} from './basicComponents';
import InputMask from 'react-input-mask';
import { validateFieldPhone, phoneNumberMaxLength } from '../utils/validateFields';
import { RelationBlock } from './ContactInfoStep';
import { SELFREGISTRATION_ROUTES, getViewState } from '../utils/redirectUtils';
import { customerServicePhone, customerServicePhoneLink } from '../utils/customerService';

const StyledStepLable = styled(StepLable)`
  margin-bottom: 0;
  margin-left: 9px;
  text-transform: none;
`;

const FullWithFlexRow = styled(FlexRow)`
  width: 100%;
  display: flex;
  flex-flow: column;
`;

const StyledFlexRow = styled(FlexRow)`
  flex: 1;
  margin-top: 16px;
`;

const delay = 1000;

export const ConfirmEmailStep = () => {
  const [user] = useState(getUserFromLocalstorage());
  const [loading, setLoading] = useState(false);
  const [isNotReceiveEmail, setIsNotReceiveEmail] = useState(false);
  const [email, setEmail] = useState(user.email);
  const [isPersonalEmail, setIsPersonalEmail] = useState(true);
  const [viewState, setViewState] = useState('confirmEmail');

  const handleNext = async () => {
    logAnalytics('Self-registration resend Code clicked');
    setLoading(true);
    try {
      const updatedEmail = user.email !== email ? email : null;
      const isEmailVerify = true;
      await resendLink(user.email, isEmailVerify, updatedEmail, isPersonalEmail);
      setLoading(false);
      logAnalytics('Self-registration resend Code success');
    } catch (err) {
      setLoading(false);
      logAnalytics('Self-registration resend Code error', {
        error: err.message
      });
      const stateName = getViewState(err.message);
      if (stateName) {
        logAnalytics(`Self-registration ${stateName} substep`, {
          name: `${user.fistName} ${user.lastName}`,
          email: user.email,
          phone: user.phone
        });
        setViewState(stateName);
      } else {
        showError(i18n('error500'));
      }
    }
  };

  const setEmailRelation = (checked, value) => {
    if (checked) {
      if (value === 'personal') {
        setIsPersonalEmail(true);
      }
      if (value === 'corporate') {
        setIsPersonalEmail(false);
      }
    }
  };

  return (
    <>
    {(() => {
      switch (viewState) {
        case 'confirmEmail':
          return (<ContainerStepBox>
            {loading && (<Spinner overlay={true} />)}
            <ContentStepBox>
              <FlexRow>
                <StepsHeader>{i18n('confirm_your_email')}</StepsHeader>
              </FlexRow>
              <StepsHeader>{i18n('confirm_your_email_message')}</StepsHeader>
            </ContentStepBox>
            <FlexRow>
              <FlexItem>
                <CheckboxButton
                  checked={isNotReceiveEmail}
                  handleChange={() => setIsNotReceiveEmail(!isNotReceiveEmail)}
                  size={22}
                >
                  <StyledStepLable>{i18n('didnt_receive_an_email')}</StyledStepLable>
                </CheckboxButton>
              </FlexItem>
            </FlexRow>
            {isNotReceiveEmail && (
              <>
              <FullWithFlexRow>
                <FlexItem>
                  <StepLable
                    data-test='selectCostCenterStepAreaLabel'
                  >
                    {i18n('check_email_again')}
                  </StepLable>
                  <StepInput
                    type="text"
                    placeholder={'xxxxx.xxxxxxxx@xxxxx.xx'}
                    value={email}
                    onChange={(value) => setEmail(value)}
                    data-test='confirmEmailStepCodeInput'
                  />
                </FlexItem>
                <FlexItem>
                  <RelationBlock 
                    type='email'
                    isPersonal={isPersonalEmail}
                    setRelation={setEmailRelation}
                  />
                </FlexItem>
              </FullWithFlexRow>
              <RoundButton
                data-test='confirmEmailStepSendEmailAgainBtn'
                label={i18n('send_email_again')}
                padding='25'
                onClick={handleNext}
                darkButton={false}
              />
              </>
            )}
          </ContainerStepBox>);
        case 'notUnicEmail':
          return (
            <NotUnicEmailInfoStep />
        );
        case 'invalidDomain':
          return (
            <InvalidDomainInfoStep />
          );
        default:
          return null;
      }
    })()}
    </>
  );
};

export const ConfirmPhoneStep = () => {
  const history = useHistory();
  const { colors } = useContext(ThemeContext);

  const [user] = useState(getUserFromLocalstorage());
  const [loading, setLoading] = useState(false);
  const [isNotReceiveSms, setIsNotReceiveSms] = useState(false);
  const [phone, setPhone] = useState(user.phone);
  const [isPersonalPhone, setIsPersonalPhone] = useState(true);
  const [code, setCode] = useState('');
  const [viewState] = useState('confirmPhone');

  const [errors, setErrors] = useState({
    phone: null,
    notUniquePhone: false
  });

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  useEffect(() => {
    if (isPhoneVerified) {
      setTimeout(redirectToNextAuthStep, delay);
    }
    return () => clearTimeout(redirectToNextAuthStep, delay);
  }, [isPhoneVerified, redirectToNextAuthStep]);

  const redirectToNextAuthStep = useCallback(async () => {
    const userFromLocaleStorage = getUserFromLocalstorage();
    let url = '/#!';
    if (isPhoneVerified && userFromLocaleStorage?.token) {
      url = url.concat(SELFREGISTRATION_ROUTES.cost_center);
      history.replace(url);
    }
  }, [history, isPhoneVerified]);

  const updatePhone = (value) => {
    setErrors(prev => ({ ...prev, ['notUniquePhone']: false }));
    if (typeof value === 'string') {
      const cleanedValue = typeof value === 'string' ? value.replace(/\D/g, '') : '';
      setPhone(getFixedPhoneValue(value));
      if (cleanedValue[3] === '0') {
        setErrors(prev => ({...prev, ['phone']: 'phone_number_cannot_start_with_0'}));
      } else {
        setErrors(prev => {
          return { ...prev, ['phone']: null };
        });
      }
    }
  };

  const handleNext = async () => {
    logAnalytics('Self-registration resend Code clicked');
    setLoading(true);
    try {
      const updatedPhone = user.phone !== phone ? phone : null;
      const isEmailVerify = false;
      const userFromLocaleStorage = getUserFromLocalstorage();
      const data = await resendCode(userFromLocaleStorage.email, isEmailVerify, user.phone, updatedPhone, isPersonalPhone); // resend SMS or get user data with redirect to cost center
      localStorage.setItem('user', JSON.stringify({...data}));
      setIsPhoneVerified(true);
      setLoading(false);
      if (data?.token) {
        logAnalytics('Self-registration resend Code not needed, redirect to cost center');
      }
      logAnalytics('Self-registration resend Code success');
    } catch (err) {
      setLoading(false);
      logAnalytics('Self-registration resend Code error', {
        error: err.message
      });
      if (err.message.includes('Contact info not unique type: Interpreter')) {
        setErrors(prev => ({...prev, ['notUniquePhone']: 'already_registered_phone'}));
        const stateName = getViewState(err.message);
        if (stateName) {
          logAnalytics(`Self-registration ${stateName} substep`, {
            name: `${user.fistName} ${user.lastName}`,
            email: user.email,
            phone: user.phone
          });
        }
      }
      else {
        showError(i18n('error500'));
      }
    }
  };

  const beforeMaskedStateChange = (newState, oldState) => {
    const symbolIndxMustBeNonZero = 5;
    const oldValue = oldState.value;
    let { value } = newState;
    let selection = newState.selection;
    let cursorPosition = selection ? selection.start : null;
    if (value[symbolIndxMustBeNonZero] === '0') {
      if (cursorPosition === symbolIndxMustBeNonZero + 1) {
        cursorPosition = cursorPosition - 1;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = oldValue;
    }
    return { value, selection };
  };

  const checkPhone = () => {
    if (!validateFieldPhone(getFixedPhoneValue(phone))) {
      setErrors(prev => ({...prev, ['phone']: 'invalid_phone_number_error'}));
    } else {
      setErrors(prev => {
        return { ...prev, ['phone']: null };
      });
    }
  };

  const getFixedPhoneValue = (phoneValue) => {
    return phoneValue
      .replaceAll(' ', '')
      .replaceAll('_', '');
  };

  const setPhoneRelation = (checked, value) => {
    setErrors(prev => ({ ...prev, ['notUniquePhone']: false }));
    if (checked) {
      if (value === 'personal') {
        setIsPersonalPhone(true);
      }
      if (value === 'corporate') {
        setIsPersonalPhone(false);
      }
    }
  };

  useEffect(() => {
    if (typeof code === 'string' && code.length === 4) {
      localStorage.setItem('code', code);
      history.replace(SELFREGISTRATION_ROUTES.verification);
    }
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps


  const confirmMessageKey = useMemo(() => {
    return user.isVoiceVerification ? 'confirm_your_phone_message' : 'confirm_your_mobile_phone_message';
  }, [user.isVoiceVerification]);

  const didntReciveMessageKey = useMemo(() => {
    return user.isVoiceVerification ? 'didnt_receive_a_call' : 'didnt_receive_sms';
  }, [user.isVoiceVerification]);

  const checkPhoneAgainKey = useMemo(() => {
    return user.isVoiceVerification ? 'check_phone_again_by_call' : 'check_phone_again_by_sms';
  }, [user.isVoiceVerification]);

  const getCodeAgainKey = useMemo(() => {
    return user.isVoiceVerification ? 'send_phone_again_for_call' : 'send_phone_again_for_sms';
  }, [user.isVoiceVerification]);

  return (
    <>
    {(() => {
      switch (viewState) {
        case 'confirmPhone':
          return (<ContainerStepBox>
            {loading && (<Spinner overlay={true} />)}
            <ContentStepBox>
              <FlexRow>
                <StepsHeader>{i18n('confirm_your_phone')}</StepsHeader>
              </FlexRow>
              <StepsHeader>{i18n(confirmMessageKey)}</StepsHeader>
            </ContentStepBox>
            <StyledFlexRow>
              <FlexItem>
                <StepLable data-test='confirmEmailStepCodeLabel'>
                  {i18n('confirmation_code')}
                  <span> *</span>
                </StepLable>
                <StepInput
                  autoFocus={true}
                  type="text"
                  placeholder={'xxxxxx'}
                  value={code}
                  onChange={(value) => setCode(value)}
                  data-test='confirmEmailStepCodeInput'
                />
              </FlexItem>
              </StyledFlexRow>
              <FlexRow>
                <FlexItem>
                  <CheckboxButton
                    checked={isNotReceiveSms}
                    handleChange={() => setIsNotReceiveSms(!isNotReceiveSms)}
                    size={22}
                  >
                    <StyledStepLable>{i18n(didntReciveMessageKey)}</StyledStepLable>
                  </CheckboxButton>
                </FlexItem>
              </FlexRow>
            {isNotReceiveSms && (
              <>
              <FullWithFlexRow>
                <FlexItem>
                  <StepLable
                    data-test='selectCostCenterStepAreaLabel'
                  >
                    {i18n(checkPhoneAgainKey)}
                  </StepLable>
                  <InputMask
                    mask="+358 99 999 99999"
                    value={phone}
                    beforeMaskedValueChange={beforeMaskedStateChange}
                    onChange={(value) => {
                        const cleanedValue = typeof value === 'string' ? value.replace(/\D/g, '') : '';
                        if (cleanedValue.length <= phoneNumberMaxLength) {
                          updatePhone(value);
                        }
                      }}
                    onBlur={() => checkPhone()}
                    placeholder='+xxx xx xxx xxxx'>
                    {(inputProps) => <StepInput
                            {...inputProps}
                            type="tel"
                            data-test='contactInfoStepPhoneInput'
                      />}
                    </InputMask>
                      {errors.phone ? (
                        <StyledErrorMessage
                          color={colors.error}
                        >
                          {i18n(errors.phone)}
                        </StyledErrorMessage>
                      ) : null}
                </FlexItem>
                <FlexItem>
                  <RelationBlock 
                    type='phone'
                    isPersonal={isPersonalPhone}
                    setRelation={setPhoneRelation}
                  />
                </FlexItem>
              </FullWithFlexRow>
              <FlexRow>
                {errors.notUniquePhone && (
                  <StyledErrorMessage color={colors.error}>
                    {i18n(errors.notUniquePhone)}
                    <a href={customerServicePhoneLink}>{customerServicePhone}</a>
                  </StyledErrorMessage>
                )}
              </FlexRow>
              <RoundButton
                data-test='confirmPhoneStepSendEmailAgainBtn'
                label={i18n(getCodeAgainKey)}
                padding='25'
                onClick={handleNext}
                darkButton={false}
              />
              </>
            )}
          </ContainerStepBox>);
        default:
          return null;
      }
    })()}
    </>
  );
};

