/* eslint-disable consistent-return */
import { getChatRoomStatus } from '../services/chatRoomService';
import { getData } from '../utils/localStorage';

const CONTINUE_DURATION = 1 * 60 * 1000; //1 minute

export const rejoinChatroomLink = (chatRoomId) => {
  return `/meetings/123?chatRoomId=${chatRoomId}`;
};

const isContinuePosible = (previousChatRoom) => {
  return previousChatRoom?.participantCount === 2 &&
  new Date().getTime() - previousChatRoom?.leftDate <= CONTINUE_DURATION;
};

export const isPreviousChatroomOpen = async(previousChatRoom, isCustomer) => {
  if (previousChatRoom?.id) {
    try {
      const status = await getChatRoomStatus(previousChatRoom?.id);
      return (status && !status?.closed) || (isCustomer && isContinuePosible(previousChatRoom));
    } catch (err) {
      console.log('GET STATUS ERROR: ', err);
      return false;
    }

  }
  return false;
};

export const setLeftDate = () => {
  const previousChatroom = getData('previousChatroom');
  if (previousChatroom) {
    localStorage.setItem('previousChatroom', JSON.stringify({
      ...previousChatroom,
      leftDate: new Date().getTime()
    }));
  }
};
