import React from 'react';
import { manifest } from '../Manifest';
import {showInfo, showError} from '../utils/msgbox';
import PropTypes from 'prop-types';
import moment from 'moment';
import amplitude from 'amplitude-js';
import Modal from 'react-modal';
import i18n from '../i18n';

import {
  enableNotifications,
  disableNotifications,
} from '../services/notificationService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faUser, faArrowLeft, faSync } from '@fortawesome/free-solid-svg-icons';

const ohjaa = window.location.href.indexOf('ohjaa') !== -1;

const customStyles = {
  content: {
    top: '30%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -30%)',
    zIndex: '100',
    width: '270px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '0',
    paddingTop: '0'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  }
};

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      version: '',
      showPopup: false,
      showDurations: false
    };

    this.refreshButton = React.createRef();
  }

  componentDidMount() {
    this.setManifestData();
  }

  async setManifestData() {
    const data = await manifest.getContent();
    document.title = data.name;
    this.setState({version: manifest.appVersion});
  }

  logoClicked = () => {
    amplitude.getInstance().logEvent('Logo clicked');
    const user = this.props.user;
    showInfo((user.name ? (user.name + '\n') : '') + ('☎ ' + user.phoneNumber));
  };

  enableNotifications = async user => {
    try {
      await enableNotifications();
      user.notificationsDisabledUntil = 0;
      localStorage.setItem('user', JSON.stringify(user));
      this.props.onNotificationsChanged(false);
    } catch (e) {
      showError(i18n('failed_to_enable_notifications'));
    }
  };

  showNotifications = async () => {
    const user = this.props.user;
    this.setState({showPopup: false});

    if ((user.notificationsDisabledUntil || 0) - Date.now() > 0) {
      amplitude.getInstance().logEvent('Menu - Enable notifications');
      this.enableNotifications(user);
    } else {
      setTimeout(() => this.setState({showDurations: true}), 200);
    }
  };

  disableNotifications = async minutes => {
    const user = this.props.user;
    this.setState({showDurations: false});
    try {
      await disableNotifications(minutes);

      user.notificationsDisabledUntil = moment().add(minutes, 'minutes').toDate().getTime();
      localStorage.setItem('user', JSON.stringify(user));
      this.props.onNotificationsChanged(true);
    } catch (e) {
      showError(i18n('failed_to_disable_notifications'));
    }
  };

  showOptions = () => {
    this.setState({showPopup: false});
    this.props.onShowOptions();
  };

  refresh = async () => {
    amplitude.getInstance().logEvent('Refresh');
    this.refreshButton.current.classList.add('progressing');

    await this.props.onRefresh();

    setTimeout(() => {
      this.refreshButton.current.classList.remove('progressing');
      this.refreshButton.current.classList.add('regressing');

      setTimeout(() => {
        this.refreshButton.current.classList.remove('regressing');
      }, 300);
    }, 1000);
  };

  render() {
    const user = this.props.user;
    if (!user) {
      return (
        <span>
          <div id='logo' className='logo' onClick={() => this.logoClicked()}>Túlka</div>
          <div id='version'>{this.state.version}</div>
        </span>
      );
    }

    let notificationsItem = null;
    let statsButton = null;

    if (user.type === 'Interpreter') {
      const notifications = i18n((user.notificationsDisabledUntil || 0) - Date.now() > 0 ? 'enable_notifications' : 'disable_notifications');
      notificationsItem = <button className='menuButton' onClick={this.showNotifications}>{notifications.toUpperCase()}</button>;
      statsButton = this.props.noButtons || ohjaa ? null : (<span className='stats'><FontAwesomeIcon icon={faTrophy} onClick={this.props.onStatistics} /></span>);
    }

    const menuButton = this.props.noButtons ? null : (<span className='menu'><FontAwesomeIcon icon={faUser} onClick={() => this.setState({showPopup: true})} /></span>);
    const refreshButton = this.props.noButtons ? null : (<span ref={this.refreshButton} className='refresh'><FontAwesomeIcon icon={faSync} onClick={this.refresh} /></span>);
    const backButton = this.props.onBack ? (<span className='stats'><FontAwesomeIcon icon={faArrowLeft} onClick={this.props.onBack} /></span>) : null;

    return (
      <span>
        {backButton}
        {statsButton}
        {refreshButton}
        {menuButton}

        <div id='logo' className='logo' onClick={this.logoClicked}>Túlka</div>
        <div id='version'>{this.state.version}</div>
        <Modal
          isOpen={this.state.showPopup}
          style={customStyles}
          closeTimeoutMS={200}
        >
          <div className='menuText'>
            <p>{user.name}</p>
            <p>{user.phoneNumber}</p>
            <button className='menuButton' onClick={this.showOptions}>{i18n(this.props.optionsVisible ? 'hideOptions' : 'options').toUpperCase()}</button>
            {notificationsItem}
            <button className='menuButton' onClick={() => this.props.onLogout()}>{i18n('logout').toUpperCase()}</button>
            <button className='menuButton cancelButton' onClick={() => this.setState({showPopup: false})}>{i18n('cancel').toUpperCase()}</button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showDurations}
          style={customStyles}
          closeTimeoutMS={200}
        >
          <div className='menuText'>
            <p>{i18n('disable_duration')}</p>
            <button className='menuButton' onClick={() => this.disableNotifications(60)}>{i18n('for_hour').toUpperCase()}</button>
            <button className='menuButton' onClick={() => this.disableNotifications(24 * 60)}>{i18n('for_day').toUpperCase()}</button>
            <button className='menuButton' onClick={() => this.disableNotifications(7 * 24 * 60)}>{i18n('for_week').toUpperCase()}</button>
            <button className='menuButton cancelButton' onClick={() => this.setState({showDurations: false})}>{i18n('cancel').toUpperCase()}</button>
          </div>
        </Modal>
      </span>
    );
  }
}

Header.propTypes = {
  user: PropTypes.object,
  onShowOptions: PropTypes.func,
  optionsVisible: PropTypes.bool,
  onRefresh: PropTypes.func,
  onLogout: PropTypes.func,
  onStatistics: PropTypes.func,
  onBack: PropTypes.func,
  onNotificationsChanged: PropTypes.func,
  noButtons: PropTypes.bool
};
