import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ThemeContext from '../contexts/ThemeContext';

import HelpButton from './HelpButton';
import hexToRgbA from '../utils/hexToRgbA';

const ContainerBox = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  padding: ${({ title }) => (title ? '0 40px 10px 40px' : '10px 40px 10px 40px')};
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 12px;
    background-color: rgba(196, 196, 196, 1);
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const StyledWrapper = styled.div`
  flex: 0 1 auto;
`;

const StyledText = styled.div`
  line-height: ${({ fontSize }) => (fontSize + 2)}px;
  text-align: left;
  background-color: transparent;
  color: ${({ color }) => (color)};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  font-size: ${({ fontSize }) => (fontSize)}px;
  align-self: stretch;
  border-bottom: ${({ borderColor, borderBottom }) => (borderBottom ? '1px solid ' + hexToRgbA(borderColor, 0.1) : 'inherit')};
  padding-bottom: 15px;
  margin-bottom: 15px;
  white-space: pre-line;
`;

const NestedContainerBox = styled.div`
  flex: 1;
  align-items: stretch;
  align-self: stretch;
  ${({ centerContent }) => (centerContent ? `
    display: flex;
    align-items: center;
  ` : '')}
`;

const ContentBox = props => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const title = props.title ? (
    <StyledWrapper>
      <StyledText
        fontSize={layout.fontSize}
        color={colors.webGray}
        borderColor={colors.titleBottomBorder}
        centered={props.centered}
        borderBottom={true}
      >
        {props.title}
      </StyledText>
    </StyledWrapper>
  ) : null;

  const minorTitle = props.minorTitle ? (
    <StyledWrapper>
      <StyledText
        fontSize={layout.fontSize}
        color={colors.webMediumGray}
        borderColor={colors.titleBottomBorder}
        centered={props.centered}
        borderBottom={true}
        data-test={props.dataTest}
      >
        {props.minorTitle}
      </StyledText>
    </StyledWrapper>
  ) : null;

  return (
    <ContainerBox
      data-test={props['data-test']}
      title={props.title || props.minorTitle}
      className={props.className}
    >
      {title}
      {props.helpId ? (
        <HelpButton
          helpId={props.helpId}
          color={colors.contentBoxBorderColor2020}
        />
      ) : null}
      {minorTitle}
      <NestedContainerBox
        centerContent={props.centerContent}
      >
        {props.children}
      </NestedContainerBox>
    </ContainerBox>
  );
};

ContentBox.propTypes = {
  title: PropTypes.string,
  centered: PropTypes.bool
};

export default ContentBox;
