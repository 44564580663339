import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Input } from './Input';
import Datepicker from './Datepicker';
import ThemeContext from '../contexts/ThemeContext';

import i18n, { langCode } from '../i18n';
import { isDateValid } from '../utils/prebooksHelpers';
import { isRefFieldValid } from '../utils/validateFields';
import { StyledErrorMessage } from './basicComponents';

const StyledFieldWrapper = styled.div`
  ${({ required }) => required && 'padding-bottom: 12px;'}
`;

const StyledInput = styled(Input)`
  margin: 0 0 10px;
`;

const StyledDatepicker = styled(Datepicker)`
  margin: 0 0 10px;
  border-radius: 6px;
  border: ${({ borderColor }) => ('1px solid ' + borderColor)};
`;

const UsergroupCustomFields = ({
  usergroupCustomFields,
  customFieldsValue,
  setCustomFieldsValue
}) => {
  const [values, setValues] = useState({});
  const [error, setError] = useState({});
  const themeContext = useContext(ThemeContext);
  const colors = themeContext.colors;

  const handleChangeField = (field, value) => {
    setCustomFieldsValue({[field]: ''});
    setValues({...values, [field]: value});

    const comparedValue = value?.split().reduce((acc, char) => {
      if (!acc && (char === ' ')) {
        return acc;
      }
      return acc + char;
    }, '');

    const isValidComparedValue = isRefFieldValid(comparedValue);
    if (isValidComparedValue) {
      setError({...error, [field]: false});
      setCustomFieldsValue({[field]: value});
    }
  };

  const handleBlur = (required, field) => {
    if (!required) {
      return;
    }
    const comparedValue = values[field]?.trim();
    if (!isRefFieldValid(comparedValue)) {
      setError({...error, [field]: 'invalid_field_value'});
    } else {
      setError({...error, [field]: false});
    }
  };

  return (
    <>
      {usergroupCustomFields?.map((field) => {
        if (field.type === 'number') {
          return (
            <StyledInput
              data-test={field.name + 'UsergroupCustomField'}
              key={field.name}
              title={field.label}
              type="number"
              value={customFieldsValue ? customFieldsValue[field.name] : ''}
              onChange={(value) => setCustomFieldsValue({
                [field.name]: value
              })}
              maxLength="255"
              required={field.required}
            />
          );
        }
        if (field.type === 'textArea') {
          return (
            <StyledInput
              data-test={field.name + 'UsergroupCustomField'}
              key={field.name}
              title={field.label}
              type="textarea"
              value={customFieldsValue ? customFieldsValue[field.name] : ''}
              onChange={(value) => setCustomFieldsValue({
                [field.name]: value
              })}
              maxLength="255"
              height={115}
              required={field.required}
            />
          );
        }
        if (field.type === 'date') {
          return (
            <StyledDatepicker
              data-test={field.name + 'UsergroupCustomField'}
              fixedMaxWidth
              showMonthDropdown
              showYearDropdown
              key={field.name}
              title={field.label}
              openToDate={customFieldsValue && customFieldsValue[field.name] ? new Date(customFieldsValue[field.name]) : new Date(moment().subtract(18, 'years'))}
              selected={customFieldsValue && customFieldsValue[field.name] ? new Date(customFieldsValue[field.name]) : ''}
              borderColor={error[field.name] ? colors.error : colors.webDefaultInputBorderColor}
              onChange={(value) => {
                setError({...error, [field.name]: !isDateValid(value)});
                setCustomFieldsValue({
                  [field.name]: value
                });
              }}
              locale={langCode}
              required={field.required}
            />
          );
        }
        return (
          <StyledFieldWrapper required={field.required} key={field.name}>
            <StyledInput
              data-test={field.name + 'UsergroupCustomField'}
              title={field.label}
              type="text"
              value={values[field.name] || ''}
              onChange={(value) => handleChangeField(field.name, value)}
              onBlur={() => handleBlur(field.required, field.name)}
              maxLength="255"
              required={field.required}
            />
            {error[field.name] &&(
              <StyledErrorMessage color={colors.error}>
                {i18n(error[field.name])}
              </StyledErrorMessage>
            )}
          </StyledFieldWrapper>
        );
      })}
    </>
  );
};

export default UsergroupCustomFields;
