import swal from 'sweetalert2';
import i18n from '../i18n';

export async function showInfo(message) {
  await swal.fire({text: message, icon: 'info', width: '300px', cancelButtonColor: '#8DD1D4'});
}

export async function showError(message) {
  await swal.fire({title: i18n('errorTitle'), text: message, icon: 'error', width: '300px', cancelButtonColor: '#8DD1D4'});
}

export async function showSuccess(message) {
  await swal.fire({title: i18n('successTitle'), text: message, icon: 'success', width: '300px', cancelButtonColor: '#8DD1D4'});
}

export async function showWarning(message) {
  await swal.fire({title: i18n('warnTitle'), text: message, icon: 'warning', width: '300px', cancelButtonColor: '#8DD1D4'});
}

export async function showQuestion(message) {
  const res = await swal.fire({
    text: message, 
    icon: 'question', 
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: i18n('yes'),
    cancelButtonText: i18n('no'),
    confirmButtonColor: '#EA7E8F',
    cancelButtonColor: '#8DD1D4',
    reverseButtons: true,
    width: '300px'});

  if (res.value) {
    return true;
  }

  return false;
}

export async function showHtmlQuestion(message) {
  const res = await swal.fire({
    html: message, 
    icon: 'question', 
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: i18n('yes'),
    cancelButtonText: i18n('no'),
    confirmButtonColor: '#EA7E8F',
    cancelButtonColor: '#8DD1D4',
    reverseButtons: true,
    width: '300px'});

  if (res.value) {
    return true;
  }

  return false;
}

export async function showHTML(html) {
  await swal.fire({
    html, 
    width: '270px', 
    padding: '0',
    showConfirmButton: false,
    cancelButtonColor: '#8DD1D4'
  });
}
