import React, { useContext, useEffect, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Select, { components } from 'react-select';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import withLeftSideBar from '../hocs/withLeftSideBar';
import HomeUserGroupSelector from '../components/HomeUserGroupSelector';
import Icon from '../components/Icon';
import ButtonPanel from '../components/ButtonPanel';
import { RoundButton } from '../components/Buttons';
import Spinner from '../components/Spinner';
import IconButton from '../components/IconButton';
import InviteDialog from '../components/InviteDialog';
import ModalDialogCloseButton from '../components/ModalDialogCloseButton';
import ResetUserPassword from '../components/ResetUserPassword';
import {
  MainHeader,
  MainContentTopBar,
  ContainerWrapper,
} from '../components/basicComponents';

import {
  setUser,
  setUserPassword,
  setUserEmails
} from '../services/userService';

import i18n, { tulkaLanguages, setLanguage, getLang } from '../i18n';
import { showError } from '../utils/msgbox';
import { Input } from '../components/Input';
import { validateFieldEmail } from '../utils/validateFields';
import { showSuccess } from '../services/toastService';

Modal.setAppElement('#main');

const StyledSettingsContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
`;

const StyledContainerWrapper = styled(ContainerWrapper)`
  padding: 10px;
`;

const StyledSettingContainer = styled.div`
  width: 100%;
  max-width: 700px;
  align-self: center;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StyledNoOptionsMessage = styled.span`
  padding: 10px;
  display: flex;
  flex: 1;
`;

const MenuItemText = styled.div`
  flex: 1 1 auto;
  display: flex;
  color: #ffffff;
`;

const StyledIcon = styled(Icon)`
  margin-left: 10px;
`;

const Title = styled.div`
  font-size: ${({ fontSize }) => (fontSize)}px;
  color: ${({ color }) => (color)};
  margin: 10px 0 15px;
  font-weight: 600;
`;

const StyledComponentsInput = styled(components.Input)`
  input {
    border-radius: 0;
    color: ${({ color }) => (color)} !important;
    &::-webkit-input-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &::-moz-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &:-ms-input-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &:-moz-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
  }
`;

const StyledErrorMessage = styled.div`
  color: ${({ color }) => (color)};
  padding: 5px 0;
`;

const StyledInput = styled(Input)`
  background-color: #ffffff;
  margin-bottom: 15px;
`;

const initialErrors = {
  email: null,
  username: null,
};

const Settings = ({ history, toggleSideBar, sidebarDocked, ...rest }) => {
  const context = useContext(AppContext);
  const {
    colors,
    layout,
  } = useContext(ThemeContext);
  const {
    setBusy,
    fetchUserAndUpdateLocally,
    sessionExpired,
  } = useContext(FunctionsContext);

  const [inviteDialogIsOpen, setInviteDialogIsOpen] = React.useState(false);

  const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(true);
  const [selectedLanguage, setSelectedLanguage] = React.useState(getLang());
  const [selectedUsergroup, setSelectedUsergroup] = React.useState({
    id: context.user?.userGroup?.id,
    name: context.user?.userGroup?.name
  });
  const [userRole] = React.useState(context.user?.type);
  const [oldPassword, setOldPassword] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordChanges, setPasswordChanges] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [indicator, setIndicator] = React.useState(false);
  const [emailErrors, setEmailErrors] = React.useState(initialErrors);
  const [emailForLogin, setEmailForLogin] = React.useState(context.user?.username || '');
  const [email, setEmail] = React.useState(context.user?.email || '');

  useEffect(() => {
    checkEmail('email', email);
    checkEmail('username', emailForLogin);
  }, [email, emailForLogin, checkEmail]);

  const errorKeys = useMemo(() => {
    return {
      email: 'invalid_email_address',
      username: 'invalid_email_address'
    };
  }, []);

  const contextSetBusy = useCallback((status) => {
    setBusy(status);
  }, [setBusy]);

  // const openInviteDialog = () => {
  //   contextSetBusy(true);
  //   setInviteDialogIsOpen(true);
  // };

  const closeInviteDialog = () => {
    setInviteDialogIsOpen(false);
    contextSetBusy(false);
  };

  const updateUserHomeGroup = async () => {
    amplitude.getInstance().logEvent('User home group updated', {
      'Selected group id': selectedUsergroup.id,
      'Selected group name': selectedUsergroup.name,
      'Current user group id': context.user.userGroup.id,
      'Current user group name': context.user.userGroup.name,
    });
    try {
      await setUser(selectedUsergroup.id, true);
      await fetchUserAndUpdateLocally();
    } catch (e) {
      if (e.message === 'Session expired') {
        sessionExpired();
      }

      throw new Error('Update user home group failed');
    }
  };

  const updateEmails = async () => {
    try {
      await setUserEmails(email, emailForLogin);
      await fetchUserAndUpdateLocally();
    } catch (e) {
      if (e.message === 'Session expired') {
        sessionExpired();
      }
      if (e.message === 'username is not unique') {
        showError(i18n('username_not_unique'));
      }
    }
  };

  const updateUserPassword = async () => {
    amplitude.getInstance().logEvent('User password updated');
    try {
      await setUserPassword(password, oldPassword);
      showSuccess(i18n('new_password_success'));
    } catch (e) {
      amplitude.getInstance().logEvent('Update user password failed', {
        'error': e.message
      });

      if (e.message === 'Session expired') {
        sessionExpired();
      }
      showError(i18n('new_password_wrong'));
    }
  };

  const setPasswordToUpdate = (oPassword, aPassword) => {
    setOldPassword(oPassword);
    setPassword(aPassword);
  };

  const updateSettings = async () => {
    setIndicator(true);
    try {
      if (userRole !== 'Interpreter' && selectedUsergroup.id !== context.user.userGroup.id) {
        await updateUserHomeGroup();
      }
      if (oldPassword && password) {
        await updateUserPassword();
      }
      if (getLang() !== selectedLanguage) {
        setLanguage(selectedLanguage);
      }
      if (isEmailChanged() || isEmailForLoginChanged()) {
        await updateEmails();
      }
      history.go(0);
    } catch (e) {
      console.log('Error', e);
      showError(i18n('requestErrorMessage'));
    }
    setIndicator(false);
  };

  useEffect(() => {
    setSelectedUsergroup({
      id: context.user?.userGroup?.id,
      name: context.user?.userGroup?.name
    });
  }, [context.user.userGroup]);
  
  const isLanguageChanged = useCallback(() => {
    return getLang() !== selectedLanguage;
  }, [selectedLanguage]);
  
  const isPasswordChanged = useCallback(() => {
    return !!(password && passwordChanges);
  }, [password, passwordChanges]);

  const isEmailChanged = useCallback(() => {
    return !!(email && email !== context.user.email);
  }, [email, context.user.email]);

  const isEmailForLoginChanged = useCallback(() => {
    return !!(emailForLogin && emailForLogin !== context.user.username);
  }, [emailForLogin, context.user.username]);
  
  const isUserGroupExistAndChanged = useCallback(() => {
    return !!(userRole !== 'Interpreter' && selectedUsergroup?.id && selectedUsergroup?.id !== context.user?.userGroup?.id);
  }, [userRole, selectedUsergroup?.id, context?.user?.userGroup?.id]);

  const isEmailError = useMemo(() => {
    return Object.values(emailErrors).some(err => err);
  }, [emailErrors]);

  const isChangesExist = useMemo(() => {
    return isUserGroupExistAndChanged() || isLanguageChanged() || isPasswordChanged()|| isEmailChanged() || isEmailForLoginChanged();
  }, [isUserGroupExistAndChanged, isLanguageChanged, isPasswordChanged, isEmailChanged, isEmailForLoginChanged]);

  useEffect(() => {
    if (isChangesExist) {
      contextSetBusy(true);
    } else {
      contextSetBusy(false);
    }
    if (!passwordError && isChangesExist) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [contextSetBusy, context.user?.userGroup?.id, selectedUsergroup?.id, password, passwordError, isEmailError, isChangesExist]);

  const modalDialogStyles = {
    overlay: {
      zIndex: 999,
      backgroundColor: colors.webOverlay
    },
    content: {
      width: '100%',
      maxWidth: '660px',
      height: '100%',
      maxHeight: '750px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      borderRadius: '15px',
      border: 'none',
      overflow: 'initial',
      display: 'flex'
    }
  };

  const checkEmail = useCallback((name, value) => {
    if (!validateFieldEmail(value)) {
      setEmailErrors(prev => ({...prev, [name]: errorKeys[name]}));
    } else {
      setEmailErrors(prev => ({...prev, [name]: null}));
    }
  }, [errorKeys]);

  const handleChangeEmail = (name, value) => {
    if (name === 'email') {
      setEmail(value);
    }
    if (name === 'username') {
      setEmailForLogin(value);
    }
  };

  return (
    <StyledSettingsContainer {...rest}>
      <MainContentTopBar
        borderBottomColor={colors.webDeviderColor}
      >
        <Modal
          isOpen={inviteDialogIsOpen}
          style={{
            overlay: modalDialogStyles.overlay,
            content: {
              ...modalDialogStyles.content,
              maxHeight: '425px'
            }
          }}
        >
          <ModalDialogCloseButton
            onClick={() => {
              closeInviteDialog();
            }}
          />
          <InviteDialog
            closeModal={() => {
              closeInviteDialog();
            }}
          />
        </Modal>
        {sidebarDocked ? null : (<IconButton
          icon={'menu'}
          iconLib={'material'}
          iconColor={colors.tulkaMainColor}
          onClick={toggleSideBar}
          data-tip="React-tooltip"
          data-event="click"
        />)}
        <MainHeader
          sidebarDocked={sidebarDocked}
          padding={layout.padding}
          color={colors.webDarkBlue}
          data-test='settingsPageTitle'
        >
          {i18n('settings')}
        </MainHeader>
      </MainContentTopBar>
      <StyledContainerWrapper>
        <StyledSettingContainer>
          <Title
            fontSize={layout.bigFontSize}
            color={colors.webDarkBlue}
            data-test='changeUserEmailForLogindLabel'
          >
            {i18n('reset_user_email_for_login')}
          </Title>
          <StyledInput
            type="mail"
            placeholder={i18n('reset_user_email_for_login')}
            value={emailForLogin}
            onChange={(value) => handleChangeEmail('username', value)}
            onBlur={() => checkEmail('username', emailForLogin)}
            data-test='changeEmailForLoginInput'
          />
          {emailErrors.username ? (<StyledErrorMessage color={colors.error}>{i18n(emailErrors.username)}</StyledErrorMessage>) : null}
        </StyledSettingContainer>
        <StyledSettingContainer>
          <Title
            fontSize={layout.bigFontSize}
            color={colors.webDarkBlue}
            data-test='changeUserEmailLabel'
          >
            {i18n('reset_user_email')}
          </Title>
          <StyledInput
            type="mail"
            placeholder={i18n('reset_user_email')}
            value={email}
            onChange={(value) => handleChangeEmail('email', value)}
            onBlur={() => checkEmail('email', email)}
            data-test='changeEmailInput'
          />
          {emailErrors.email ? (<StyledErrorMessage color={colors.error}>{i18n(emailErrors.email)}</StyledErrorMessage>) : null}
        </StyledSettingContainer>
        <StyledSettingContainer>
          <Title
            fontSize={layout.bigFontSize}
            color={colors.webDarkBlue}
            data-test='changeUserPasswordLabel'
          >
            {i18n('reset_user_password')}
          </Title>
          <ResetUserPassword
            setPasswordToUpdate={setPasswordToUpdate}
            setPasswordChanges={setPasswordChanges}
            setPasswordError={setPasswordError}
          />
        </StyledSettingContainer>
        {userRole !== 'Interpreter' ? (<StyledSettingContainer>
          <Title
            fontSize={layout.bigFontSize}
            color={colors.webDarkBlue}
            data-test='changeHomeUserGroupLabel'
          >
            {i18n('user_home_group_header')}
          </Title>
          <HomeUserGroupSelector
            id="settingsPage"
            selectedUsergroup={selectedUsergroup}
            setSelectedUsergroup={setSelectedUsergroup}
            // openInviteDialog={openInviteDialog}
            // isUserGroupSaved={selectedUsergroup?.id === context.user?.userGroup?.id}
            pureList={true}
          />
        </StyledSettingContainer>) : null}
        <StyledSettingContainer>
          <Title
            fontSize={layout.bigFontSize}
            color={colors.webDarkBlue}
          >
            {i18n('app_language')}
          </Title>
          <Select
            value={{
              value: selectedLanguage,
              label: selectedLanguage.toUpperCase()
            }}
            options={tulkaLanguages.map((lang) => ({
              value: lang,
              label: lang.toUpperCase()
            }))}
            isSearchable={false}
            onChange={(obj) => setSelectedLanguage(obj.value)}
            styles={{
              control: (styles, { isFocused }) => ({
                ...styles,
                boxShadow: 'none',
                borderColor: isFocused ? colors.webMediumGray : '#cccccc',
                padding: '6px 6px',
                ':hover': {
                  borderColor: colors.webMediumGray
                }
              }),
              option: (base, state) => ({
                ...base,
                transition: layout.transition,
                backgroundColor: state.isSelected ? colors.tulkaMainColor : 'white',
                ':active': {
                  backgroundColor: state.isSelected ? colors.tulkaMainColor : 'white'
                },
                ':hover': {
                  backgroundColor: '#DFFFFE',
                  color: colors.tulkaMainColor
                }
              }),
            }}
            components={{
              Input: (props) => {
                return <StyledComponentsInput
                  color={colors.webGray}
                  lighttextcolor={colors.webMediumGray}
                  {...props}
                />;
              },
              NoOptionsMessage: () => (
                <StyledNoOptionsMessage>
                  {i18n('no_options')}
                </StyledNoOptionsMessage>
              ),
              LoadingMessage: () => (
                <StyledNoOptionsMessage>
                  {i18n('search_message')}
                </StyledNoOptionsMessage>
              ),
              Control: ({ children, ...nestedRest }) => (
                <components.Control {...nestedRest}>
                  <StyledIcon
                    icon='language'
                    iconLib='material'
                    iconColor={colors.webDarkBlue}
                    iconSize={24}
                    hoverColor={colors.webDarkBlue}
                  />
                  <MenuItemText>
                    {children}
                  </MenuItemText>
                </components.Control>
              )
            }}
          />
        </StyledSettingContainer>
      </StyledContainerWrapper>
      <ButtonPanel>
        <RoundButton
          darkButton
          disabled={saveButtonDisabled}
          color={colors.tulkaMainColor}
          padding={layout.buttonPadding}
          label={i18n('update_settings')}
          onClick={updateSettings}
          data-test='saveSettingsButton'
        />
      </ButtonPanel>
      {indicator ? (
        <Spinner
          overlay
        />
      ): null}
    </StyledSettingsContainer>
  );
};

export default withLeftSideBar(Settings);
