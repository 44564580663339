import styled from 'styled-components';

import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import ActionListItem from './ActionListItem';

import i18n from '../i18n';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const PresenceForBackup = ({
    customerServiceComments,
    onUpdateCustomerServiceComments
}) => {
  return (
    <StyledContainer>
      <ChatWizardHeader
        title={i18n('which_remote_interpretation_type_do_you_choose')}
      />
      <ContentBox
        centered={true}
      >
         <ActionListItem
            data-test='presenceForBackupCallTypeCall'
            title={i18n('phone_interpretation')}
            icon={'phone_in_talk_outlined'}
            iconLib={'material'}
            onClick={() => {
                onUpdateCustomerServiceComments('REMOTE AS BACKUP PHONE');
            }}
            selected={customerServiceComments === 'REMOTE AS BACKUP PHONE'}
          />
           <ActionListItem
            data-test='presenceForBackupPresenceConference'
            title={i18n('presence_conference')}
            subtitle={i18n('presence_conference_tip')}
            icon={'group'}
            iconLib={'fontAwesome'}
            onClick={() => {
                onUpdateCustomerServiceComments('REMOTE AS BACKUP CONFERENCE/VIRTUAL ROOM');
            }}
            selected={customerServiceComments === 'REMOTE AS BACKUP CONFERENCE/VIRTUAL ROOM'}
          />
      </ContentBox>
    </StyledContainer>
  );
};

export default PresenceForBackup;
