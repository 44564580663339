import { useRef, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown';
import i18n from '../i18n';
import ThemeContext from '../contexts/ThemeContext';
import AppContext from '../contexts/AppContext';
import usePreviewPublisher from '../hooks/usePreviewPublisher';
import {isGonePrebooking, isCancelledPrebooking, isTooLate, isStartPrebook} from '../utils/setBookingDate';
import { logAnalytics } from '../services/analyticsLogger';
import { LuonaButton } from './Buttons';
import Icon from './Icon';
import { LuonaInput, LuonaInputLabel, LuonaSelect, LuonaOption } from './Input';

import { MainHeader } from './basicComponents';

import { showError } from '../utils/msgbox';

const AVAILABLE_LANGUAGE_TAGS = ['rus', 'eng', 'fin', 'ukr'];

const WaitingRoomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${({ background }) => background};
  padding: 25px;
  border-radius: 4px;
  height: 100%;
  width: 100%;
`;

const WaitingRoomVideoPreview = styled.div`
  position: relative;
  margin: 20px 0px;
  width: 50%;
  height: 50%;
  .video-audio-button {
    display: none !important;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ButtonBlock = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
  }
  @media (min-width: 791px) {
    padding: 0 10px;
  }
  @media (min-width: 891px) {
    padding-left: 50px;
    padding-right: 0;
  }
`;
const Row = styled(MainHeader)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  & + & {
    margin-left: 25px;
  }
`;

const EarlyPreview = ({ hours, minutes, seconds }) => {
  return (
    <div>
      <p>{i18n('early_preview_intro')}</p>
      <p>{i18n('prebooking_will_start_in')} <span>{hours}:{minutes}:{seconds}</span></p>
      <p>{i18n('you_can_close_tab_for_now')}</p>
    </div>
  );
};

const SimplePreview = ({ hours, minutes, seconds }) => {
  return (
    <div>
      <div>{i18n('prebooking_will_start_in')} <span>{hours}:{minutes}:{seconds}</span></div>
    </div>
  );
};

const PreviewView = (props) => {
  const { colors } = useContext(ThemeContext);
  const { user } = useContext(AppContext);
  const [ userName, setUserName ] = useState(props.username || '');
  const [ localAudio, setLocalAudio ] = useState(true);
  const [ localVideo, setLocalVideo ] = useState(props.video);
  const [ error, setError ] = useState(!(userName && userName.length > 0));
  const [languages, setLanguages] = useState([{
      id: null,
      name: i18n('pleaseWait')
  }]);
  const [ selectedLanguage, setSelectedLanguage ] = useState('');
  const waitingRoomVideoContainer = useRef();
  const {
    createPreview,
    destroyPreview,
    previewPublisher
  } = usePreviewPublisher();

  const onLanguageChanged = (id) => {
    setSelectedLanguage(id);
  };

  useEffect(() => {
    setTimeout(()=>{
      if (user.selectableLanguages) {
        setLanguages(user.selectableLanguages.filter(lang => AVAILABLE_LANGUAGE_TAGS.includes(lang.languageTag)));
      }
    }, 1500);
  }, [user]);

  useEffect(() => {
    if (props.prebooking && 
      isStartPrebook(props.prebooking) &&
      !isGonePrebooking(props.prebooking) && 
      !isCancelledPrebooking(props.prebooking) && 
      !isTooLate(props.prebooking)) {
      onJoin();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function createPreviewIntance() {
      try {
        if (waitingRoomVideoContainer.current) {
          createPreview(waitingRoomVideoContainer.current);
        }
      } catch (e) {
        console.log('ERROR createPreview: ', e);
      }
    }
    createPreviewIntance();
    return () => {
      destroyPreview();
    };
  }, [createPreview, destroyPreview]);

  useEffect(() => {
  if (previewPublisher) {
    if (localAudio && !previewPublisher.isAudioEnabled()) {
      previewPublisher.enableAudio();
      props.heandleChangeAudio(true);
    } else if (!localAudio && previewPublisher.isAudioEnabled()) {
      previewPublisher.disableAudio();
      props.heandleChangeAudio(false);
    }
  }
}, [localAudio, previewPublisher, props]);

useEffect(() => {
if (previewPublisher) {
  if (localVideo && !previewPublisher.isVideoEnabled()) {
    previewPublisher.enableVideo();
    props.heandleChangeVideo(true);
  } else if (!localVideo && previewPublisher.isVideoEnabled()) {
    previewPublisher.disableVideo();
    props.heandleChangeVideo(false);
  }
}
}, [localVideo, previewPublisher, props]);

const onChangeUserName = value => {
  const username = value ? value.trim() : null;
  setUserName(value);
  setError(!(username && username.length > 0));
};

const onJoin = () => {
  logAnalytics('On join to meeting', {
    userId: user?.id
  });
  props.handleJoin({ userName, userData: user });
};

const renderer = ({ hours, minutes, seconds }) => {
  const isEarlyPreview = hours >= 0 && minutes >= 10;
  if (isEarlyPreview) {
    return (
      <EarlyPreview hours={hours} minutes={minutes} seconds={seconds}/>
    );
  }
  return (
    <SimplePreview hours={hours} minutes={minutes} seconds={seconds}/>
  );
};

const prebookingPreview = (prebook) => {
  logAnalytics('Start prebooking preview', {
    userId: user?.id
  });
  destroyPreview();
  if (isGonePrebooking(prebook)) {
    showError(i18n('prebooking_is_not_found'));
    logAnalytics('Prebooking is not found', {
      userId: user?.id
    });
    props.history.replace('/prebooking_luona');
    return null;
  }
  if (isCancelledPrebooking(prebook)) {
    showError(i18n('prebooking_is_cancelled'));
    logAnalytics('Prebooking is cancelled', {
      userId: user?.id
    });
    props.history.replace('/prebooking_luona');
    return null;
  }
  if (isTooLate(prebook)) {
    showError(i18n('prebooking_is_too_late'));
    logAnalytics('Prebooking is too late', {
      userId: user?.id
    });
    props.history.replace('/prebooking_luona');
    return null;
  }
  logAnalytics('Started countdown before starting prebooking', {
    userId: user?.id
  });
  return (
    <Countdown
      onComplete={() => onJoin() }
      date={new Date(prebook.interpretationDate).getTime()}
      renderer={renderer}
    />
  );
};

  return (
    <WaitingRoomContainer background={colors.luonaWaitonfRoomBgc}>
    {props.prebooking ? prebookingPreview(props.prebooking) : (
      <>
        <WaitingRoomVideoPreview ref={waitingRoomVideoContainer}>
          <Row>
            <StyledIcon
              onClick={() => setLocalAudio(!localAudio)}
              icon={localAudio ? 'mic' : 'mic_off' }
              iconLib='material'
              iconColor={localAudio ? '#ffffff' : colors.errorColor }
              iconSize={20}
            />
            <StyledIcon
              onClick={() => setLocalVideo(!localVideo)}
              icon={localVideo ? 'videocam' : 'videocam_off'}
              iconLib='material'
              iconColor={localVideo ? '#ffffff' : colors.errorColor }
              iconSize={20}
            />
          </Row>
        </WaitingRoomVideoPreview>
        <ButtonBlock>
          {!props.username && (
            <>
              <LuonaInputLabel
                data-test='nameLabelPreviewView'
                htmlFor='name'
                label={i18n('what_is_your_name')}
              />
              <LuonaInput
                data-test='nameInputPreviewView'
                readOnly={false}
                name='name'
                value={userName}
                placeholder={i18n('your_name')}
                handleChange={(e) => onChangeUserName(e.target.value)}
              />
            </>
          )}
          <LuonaInputLabel
            data-test='langLabelPreviewView'
            htmlFor='lang'
            label={i18n('selectLanguage')}
          />
          <LuonaSelect
            className='select'
            value={selectedLanguage || ''}
            handleChange={e => onLanguageChanged(e.target.value)}
          >
            {languages.map(lang => {
              return (
                <LuonaOption
                  key={'lang_' + lang.id}
                  value={lang.id}
                  label={lang.name}
                />
              );
            })}
          </LuonaSelect>
          <LuonaInputLabel
            data-test='readyToJoinLabelPreviewView'
            htmlFor='lang'
            label={i18n('ready_to_join')}
          />
          <LuonaButton
            label={i18n('join_to_meeting')}
            handleClick={() => onJoin(userName)}
            disabled={error}
            margin='0px'
          />
        </ButtonBlock>
      </>
    )}
    </WaitingRoomContainer>
  );
};

export default PreviewView;
