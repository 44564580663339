import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import i18n from '../i18n';
import { MyTulkaReJoinButton } from './Buttons';
import { getData } from '../utils/localStorage';
import { isPreviousChatroomOpen, rejoinChatroomLink } from '../utils/rejoinChatroom';

const StyledRejoinBlock = styled.div`
  margin-bottom: 24px;
  margin-right: 12px;
  visibility: ${({isHidden}) => isHidden && 'hidden'};
`;

const ReJoinBlock = () => {
  const [checkRejoinTimeout, setCheckRejoinTimeout] = useState(null);
  const [previousChatRoom] = useState(getData('previousChatroom'));
  const [isShowRejoin, setIsShowRejoin] = useState(false);
  const history = useHistory();

  const startPolling = async (roomId) => {
    const timeoutId = setTimeout(async() => {
      if (roomId) {
        const isOpen = await isPreviousChatroomOpen(previousChatRoom);
        if (isOpen) {
          setIsShowRejoin(true);
          startPolling(roomId);
        } else {
          clearTimeout(checkRejoinTimeout);
          localStorage.removeItem('previousChatroom');
          setIsShowRejoin(false);
        }
      }
    }, 2000);
    setCheckRejoinTimeout(timeoutId);
  };

  async function setRejoinChatroom(){
    const isOpen = await isPreviousChatroomOpen(previousChatRoom, false);
    setIsShowRejoin(isOpen);
  }
  useEffect(() => {
    if (previousChatRoom?.id) {
      setRejoinChatroom();
      startPolling(previousChatRoom?.id);
    }
    return (() => {
      clearTimeout(checkRejoinTimeout);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledRejoinBlock isHidden={!isShowRejoin}>
      <MyTulkaReJoinButton label={i18n('rejoin_meeting')}
        onClick={() => {
          clearTimeout(checkRejoinTimeout);
          const link = rejoinChatroomLink(previousChatRoom.id);
          history.replace(link);
        }}
      />
    </StyledRejoinBlock>
  );
};

export default ReJoinBlock;
