export default class OpenTokStats {
  constructor() {
    this.beganTimestampInMilliseconds = null;
    this.lastUsedPeriodicalAudioStat = null;
    this.lastUsedPeriodicalVideoStat = null;
    this.latestAudioStat = null;
    this.latestVideoStat = null;
    this.latestAudioStatTimestamp = null;
    this.latestVideoStatTimestamp = null;
  }

  getStats(lang, role) {
    if (!this.latestAudioStat || !this.latestVideoStat) {
      return null;
    }

    const durationInSeconds = (parseInt(this.latestAudioStat.timestamp) - parseInt(this.beganTimestampInMilliseconds)) / 1000;

    const audioPacketsLost = parseInt(this.latestAudioStat.packetsLost);
    const audioBytesReceived = parseInt(this.latestAudioStat.bytesReceived);
    const audioPacketsReceived = parseInt(this.latestAudioStat.packetsReceived);

    const videoPacketsLost = parseInt(this.latestVideoStat.packetsLost);
    const videoBytesReceived = parseInt(this.latestVideoStat.bytesReceived);
    const videoPacketsReceived = parseInt(this.latestVideoStat.packetsReceived);

    return {
      role,
      interpretationLanguage: lang,
      interpretationDuration: durationInSeconds,
      audioBw: audioBytesReceived * 8 / durationInSeconds,
      audioPLRatio: audioPacketsLost === 0 ? 0 : parseInt((audioPacketsLost / (audioPacketsLost + audioPacketsReceived)) * 1000),
      videoBw: videoBytesReceived * 8 / durationInSeconds,
      videoPLRatio: videoPacketsLost === 0 ? 0 : parseInt((videoPacketsLost / (videoPacketsLost + videoPacketsReceived)) * 1000)
    };
  }

  getStatsSinceLastReport() {
    if (!this.beganTimestampInMilliseconds) {
      return null;
    }

    const audio = this.latestAudioStat && this.lastUsedPeriodicalAudioStat;
    const video = this.latestVideoStat && this.lastUsedPeriodicalVideoStat;

    const audioStat = audio ? this.parsePeriodicalData(this.latestAudioStat, this.lastUsedPeriodicalAudioStat) : null;
    const videoStat = video ? this.parsePeriodicalData(this.latestVideoStat, this.lastUsedPeriodicalVideoStat) : null;

    this.lastUsedPeriodicalAudioStat = this.latestAudioStat;
    this.lastUsedPeriodicalVideoStat = this.latestVideoStat;

    return {
      audio: audioStat,
      video: videoStat
    };
  }

  hasNewAudioPacketLoss(stats) {
    if (!this.latestAudioStat) { 
      return stats.packetsLost > 0; 
    }

    return stats.packetsLost > this.latestAudioStat.packetsLost;
  }

  reportAudioStats(stats) {
    const ts = parseFloat(stats.timestamp);

    if (!this.beganTimestampInMilliseconds) {
      this.beganTimestampInMilliseconds = stats.timestamp;
    }

    if (!this.lastUsedPeriodicalAudioStat) {
      this.lastUsedPeriodicalAudioStat = stats;
    }

    if (!this.latestAudioStatTimestamp || this.latestAudioStatTimestamp < ts) {
      this.latestAudioStat = stats;
      this.latestAudioStatTimestamp = ts;
    }
  }

  reportVideoStats(stats) {
    const ts = parseFloat(stats.timestamp);

    if (!this.beganTimestampInMilliseconds) {
      this.beganTimestampInMilliseconds = stats.timestamp;
    }

    if (!this.lastUsedPeriodicalVideoStat) {
      this.lastUsedPeriodicalVideoStat = stats;
    }

    if (!this.latestVideoStatTimestamp || this.latestVideoStatTimestamp < ts) {
      this.latestVideoStat = stats;
      this.latestVideoStatTimestamp = ts;
    }
  }

  parsePeriodicalData(newestData, previouslySentData) {
    const periodLengthMilliseconds = parseInt(newestData.timestamp) - parseInt(previouslySentData.timestamp);
    const bytesReceivedInPeriod = newestData.bytesReceived - previouslySentData.bytesReceived;

    return {
      timestamp: parseInt(newestData.timestamp),
      periodLengthMilliseconds,
      bandwidth: parseInt((bytesReceivedInPeriod * 8) / (periodLengthMilliseconds / 1000)),
      bytesReceived: bytesReceivedInPeriod,
      packetsLost: (newestData.packetsLost - previouslySentData.packetsLost),
      packetsReceived: (newestData.packetsReceived - previouslySentData.packetsReceived)
    };
  }
}