import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { MyTulkaInput, RadioInput } from '../components/Input';
import { MyTulkaButton } from '../components/Buttons';
import IconButton from '../components/IconButton';
import Spinner from '../components/Spinner';
import {
  FullBackgroundImageContainer,
  CenteredBlockContainer,
  VersionLabel,
  LogoImg,
  DialogBlock,
} from '../components/basicComponents';

import { manifest } from '../Manifest';
import i18n from '../i18n';
import { showSuccess } from '../utils/msgbox';

import {
  resetPassword,
} from '../services/authService';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';

import Logo from '../images/myTulkaLogo.png';
import BackgroundImage from '../images/login-background.jpeg';

const StyledCenteredBlockContainer = styled(CenteredBlockContainer)`
  background-color: rgba(255, 255, 255, 0.75);
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  left: 10px;
  top: 10px;
`;

const StyledTitle = styled.h2`
  text-align: left;
  color: ${({ color }) => (color)};
  font-size: ${({ fontSize }) => (fontSize)}px;
`;

const StyledTip = styled.h3`
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
  color: ${({ color }) => (color)};
  font-size: ${({ fontSize }) => (fontSize)}px;
`;

const StyledText = styled.span`
  flex: auto 1 auto;
  display: flex;
  font-size: 16px;
  color: white;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: ${({ color }) => (color)};
  font-size: ${({ fontSize }) => (fontSize)}px;
`;

const StyledRadioButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

const StyledRadioButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
`;

const StyledMyTulkaButton = styled(MyTulkaButton)`
  margin-top: 46px;
`;

const StyledRadioInput = styled(RadioInput)`
  width: auto;
`;

const ForgotPassword = () => {
  const context = useContext(AppContext);
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const [indicator, setIndicator] = useState(false);
  const [inputType, setInputType] = useState('tel');
  const [inputPlaceholder, setInputPlaceholder] = useState(i18n('placeholder_phone_number'));
  const [inputMaxLength, setInputMaxLength] = useState(16);
  const [inputData, setInputData] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setInputData('');
    setInputPlaceholder(inputType === 'tel' ? i18n('placeholder_phone_number') : i18n('email'));
    setInputMaxLength(inputType === 'tel' ? 16 : '');
  }, [inputType]);

  useEffect(() => {
    setError(null);
    setButtonDisabled(inputData.length === 0);
  }, [inputData]);

  const changePassword = async () => {
    setIndicator(true);

    try {
      await resetPassword(inputData);
      context.history.replace('/login');
      showSuccess(i18n('forgot_password_success'));
    } catch (e) {
      setError(i18n('forgot_password_error'));
      setIndicator(false);
    }
  };

  const goBack = () => {
    context.history.replace('/login');
  };

  return (
    <FullBackgroundImageContainer
      backgroundImage={BackgroundImage}
    >
      <StyledCenteredBlockContainer>
        <StyledIconButton
          icon="arrow_back_ios"
          iconLib="material"
          iconColor={colors.tulkaMainColor}
          hoverColor={colors.webDarkBlue}
          onClick={() => goBack()}
        />
        <DialogBlock
          backgroundColor={'#ffffff'}
        >
          <LogoImg src={Logo} title="Túlka"/>
          <StyledTitle
            color={colors.webDarkBlue}
            fontSize={layout.mediumFontSize}
          >
            {i18n('forgot_password_link')}
          </StyledTitle>
          <StyledTip
            color={colors.webDarkBlue}
            fontSize={layout.smallFontSize}
          >
            {i18n('forgot_password_info')}
          </StyledTip>
          <StyledRadioButtonsContainer>
            <StyledRadioButtonContainer>
              <StyledRadioInput
                type="radio"
                value="tel"
                name="inputType"
                checked={inputType === 'tel'}
                onChange={(event) => setInputType(event.target.value)}
              />
              <StyledText
                color={colors.webDarkBlue}
                fontSize={layout.smallFontSize}
              >
                {i18n('phone_number_title')}
              </StyledText>
            </StyledRadioButtonContainer>
            <StyledRadioButtonContainer>
              <StyledRadioInput
                type="radio"
                value="email"
                name="inputType"
                checked={inputType === 'email'}
                onChange={(event) => setInputType(event.target.value)}
              />
              <StyledText
                color={colors.webDarkBlue}
                fontSize={layout.smallFontSize}
              >
                {i18n('email')}
              </StyledText>
            </StyledRadioButtonContainer>
          </StyledRadioButtonsContainer>
          <MyTulkaInput
            titleFontSize={layout.smallFontSize}
            fontSize={layout.mediumFontSize}
            type={inputType}
            placeholder={inputPlaceholder}
            value={inputData}
            onChange={setInputData}
            maxLength={inputMaxLength.toString()}
            error={error ? error : ''}
            iconLib={'material'}
            icon={'account_circle'}
          />
          <StyledMyTulkaButton
            label={i18n('proceed')}
            disabled={buttonDisabled}
            onClick={changePassword}
          />
          <VersionLabel
            fontSize={layout.smallFontSize}
            color={colors.webMediumGray}
          >
            {manifest.appVersion}
          </VersionLabel>
        </DialogBlock>
        {
          indicator ? (
            <Spinner
              overlay
            />
          ) : null
        }
      </StyledCenteredBlockContainer>
    </FullBackgroundImageContainer>
  );
};

export default ForgotPassword;
