import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

import ContentBox from './ContentBox';
import ChatWizardHeader from './ChatWizardHeader';
import i18n, { langCode } from '../i18n';
import Icon from './Icon';
import { getMinSelectableDate } from '../utils/timeUtils';

import ThemeContext from '../contexts/ThemeContext';

const StyledIcon = styled(Icon)`
  width: 100%;
  height: 100%;
  margin: 0;
`;

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledCalendar = styled(Calendar)`
  width: 100%;
  border: none;
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    transition: ${({ transition }) => (transition)};
    &:disabled {
      & > ${StyledIcon}:hover {
        & > i, & > span {
          color: ${({ mainColor }) => (mainColor)} !important;
        }
      }
    }
  }

  .react-calendar__navigation {
    & > button {
      padding: 0;
      border-radius: 6px;
      color: ${({ mainColor }) => (mainColor)};
      background-color: transparent;
      &:enabled:focus {
        background-color: transparent;
      }
      &:enabled:hover {
        transition: ${({ transition }) => (transition)};
        background-color: ${({ mainColor }) => (mainColor)};
        color: white;
      }
    }
    & > button:not(.react-calendar__navigation__label) {
      font-size: 20px;
    }
    & .react-calendar__navigation__label__labelText {
      text-transform: capitalize;
    }
  }

  .react-calendar__viewContainer {
    & .react-calendar__month-view__weekdays__weekday {
      color: ${({ mainColor }) => (mainColor)};
      border-bottom: ${({ mainColor }) => ('1px solid ' + mainColor)};
      & > * {
        text-decoration: none;
      }
    }
    & button.react-calendar__tile.react-calendar__month-view__days__day {
      color: ${({ mainColor }) => (mainColor)};
      &.react-calendar__tile:disabled {
        color: ${({ disabledColor }) => (disabledColor)};
        background-color: ${({ disabledBackgroundColor }) => (disabledBackgroundColor)};
      }
      &.react-calendar__tile--now {
        background: transparent;
        &:enabled:hover, &:enabled:focus, &.react-calendar__tile--active {
          border-radius: 6px;
          transition: ${({ transition }) => (transition)};
          background-color: ${({ mainColor }) => (mainColor)};
          color: white;
        }
      }
      &:enabled:hover, &:enabled:focus, &.react-calendar__tile--active {
        border-radius: 6px;
        transition: ${({ transition }) => (transition)};
        background-color: ${({ mainColor }) => (mainColor)};
        color: white;
      }
      &.react-calendar__month-view__days__day--neighboringMonth {
        color: ${({ neighboringMonthColor }) => (neighboringMonthColor)};
      }
    }
  }
`;

const TextBlock = styled.div`
  font-size: ${({ fontSize }) => (fontSize)}px;
  font-weight: normal;
  letter-spacing: .5px;
  margin-top: 15px;
  color: ${({ color }) => color};
`;

const PrebookingDatePage = ({ date, presence, onDateChanged, message }) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  useEffect(() => {
    const minSelectableDate = getMinSelectableDate(presence);
    if (moment(date).isBefore(minSelectableDate, 'year') && moment(date).isBefore(minSelectableDate, 'month') && moment(date).isBefore(minSelectableDate, 'day')) {
      onDateChanged(minSelectableDate);
    }
  }, [date, presence]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledContainer>
      <ChatWizardHeader
        title={presence === 'notice' ? i18n('prebooking_date_header_notice') : i18n('prebooking_date_header')}
      />
      <ContentBox>
        <StyledCalendar
          mainColor={colors.tulkaMainColor}
          disabledColor={colors.webGray}
          disabledBackgroundColor={colors.webLightGray}
          neighboringMonthColor={colors.webMediumGray}
          transition={layout.transition}
          minDate={getMinSelectableDate(presence)}
          maxDate={moment().add(8, 'months').toDate()}
          maxDetail="month"
          minDetail="month"
          value={date}
          onChange={(value) => {
            const previousDate = moment(date || moment()).clone();
            previousDate.set('year', moment(value).get('year'));
            previousDate.set('month', moment(value).get('month'));
            previousDate.set('date', moment(value).get('date'));

            onDateChanged(new Date(previousDate));
          }}
          locale={langCode}
          prevLabel={
            <StyledIcon
              icon="navigate_before"
              iconLib="material"
              iconColor={colors.webDarkBlue}
              iconSize={24}
              hoverColor={'#ffffff'}
            />
          }
          nextLabel={
            <StyledIcon
              icon="navigate_next"
              iconLib="material"
              iconColor={colors.webDarkBlue}
              iconSize={24}
              hoverColor={'#ffffff'}
            />
          }
        />
        {message ? (
          <TextBlock
            fontSize={layout.mediumFontSize}
            color={colors.webGray}
          >
            {message}
          </TextBlock>
        ) : null}
      </ContentBox>
    </StyledContainer>
  );
};

PrebookingDatePage.propTypes = {
  onDateChanged: PropTypes.func.isRequired,
  date: PropTypes.object
};

export default PrebookingDatePage;
