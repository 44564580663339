export const getMediaPermissions = async () => {
  if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
      return stream;
    } catch (e) {
      console.error(`VideoMeeting Permissions: ${e}`);
      throw 'OT_USER_MEDIA_ACCESS_DENIED';
    }
  } else {
    return new Promise((resolve) => {
      console.log('getUserMedia is not supported');
      resolve();
    });
  }
};
