import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import Clipboard from 'react-clipboard.js';
import PropTypes from 'prop-types';

import Icon from '../components/Icon';
import ThemeContext from '../contexts/ThemeContext';

const baseButtonStyles = css`
  height: ${({ height }) => (height ? `${height}px` : '50px')};
  min-height: ${({ height }) => (height ? `${height}px` : '50px')};
  border: ${({ color, hideBorder }) => hideBorder ? 'none' : '1px solid ' + color};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '50px')};
  color: ${({ darkButton, color }) => (darkButton ? 'white' : color)};
  padding: ${({ padding }) => '0 ' + padding}px;
  white-space: ${({ nowrap }) => nowrap ? 'nowrap' : 'normal'};
  line-height:  ${({ lineHeight }) => lineHeight ? `${lineHeight}px` : 'normal'};
  background-color: ${({ darkButton, color }) =>
    darkButton ? color : 'transparent'};
  transition: ${({ transition }) => transition};
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize}px;
  ${({ toUpperCase }) => toUpperCase ? 'text-transform: uppercase;' : ''}
  &:hover {
    color: ${({ darkButton, color }) => (darkButton ? color : 'white')};
    background-color: ${({ darkButton, color }) =>
      darkButton ? 'transparent' : color};
    i {
      color: ${({ darkButton, color }) =>
        darkButton ? color : 'white'} !important;
    }
  }
  &:disabled {
    opacity: 0.8;
    &:hover {
      color: ${({ darkButton, color, warning, warningColor }) => {
         if (darkButton) return 'white';
         if (warning) return warningColor;
         return color;
      }};
      background-color: ${({ darkButton, color }) =>
        darkButton ? color : 'transparent'};
        i {
          color: ${({ darkButton, color, warning, warningColor }) => {
            if (darkButton) return 'white !important';
            if (warning) return warningColor + '!important';
            return color + '!important';
          }
        }
    }
  }
  ${({ warning, warningColor }) =>
    warning
      ? `
    color: ${warningColor};
    border: ${'1px solid ' + warningColor};
    &:hover {
      color: 'white';
      background-color: ${warningColor};
    }
  `
      : ''}
  ${({ icon, iconAfter }) =>
    icon || iconAfter
      ? `
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      ${iconAfter ? 'margin-left: 5px;' : 'margin-right: 5px;'}
    }
  `
      : ''}
`;

const StyledClipboardButton = styled(Clipboard)`
  ${baseButtonStyles}
  text-transform: uppercase;
`;

const StyledRoundButton = styled.button`
  ${baseButtonStyles}
  @media (max-width: 768px) {
    width: ${({ mobWidth }) => mobWidth ? '100%' : 'auto'};
  }
`;

const StyledMyTulkaButton = styled.button`
  width: 100%;
  height: 38px;
  min-height: 38px;
  border-radius: 18px;
  color: white;
  background-color: ${({ buttonColor }) => buttonColor};
  border: ${({ buttonColor }) => '1px solid ' + buttonColor};
  transition: ${({ transition }) => transition};
  font-size: ${({ fontSize }) => fontSize}px;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    color: ${({ buttonColor }) => buttonColor};
    background-color: transparent;
  }
  &:disabled {
    opacity: 0.8;
    &:hover {
      color: white;
      background-color: ${({ buttonColor }) => buttonColor};
    }
  }
`;

const StyledMyTulkaReJoinButton = styled(StyledMyTulkaButton)`
  &:hover {
    color: white;
    background-color: ${({ hoverColor }) => hoverColor};
    border-color: ${({ hoverColor }) => hoverColor};
  }
`;

const StyledMyTulkaLinkButton = styled.a`
  display: block;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize}px;
`;
const StyledLuonaButton = styled.button`
  font-family: inherit;
  display: block;
  padding: 8px 18px;
  background-color: ${({ lightColor }) => lightColor};
  color: ${({ brightColor }) => brightColor};
  border: 1px solid ${({ lightColor }) => lightColor};
  border-radius: 16px;
  text-transform: uppercase;
  margin: ${({margin}) => margin};
  cursor: pointer;
  transition: ${({ transition }) => transition};
  :hover {
    border: 1px solid ${({ brightColor }) => brightColor};
    background: ${({ brightColor }) => brightColor};
    color: white;
  }
  :disabled {
    background-color: ${({ lightColor }) => lightColor};
    color: ${({ brightColor }) => brightColor};
    border: 1px solid ${({ lightColor }) => lightColor};
  }
  @media (max-width: 768px) {
    width: ${({width}) => width ? width : '100%'};
  }
`;
const RoundButton = (props) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const [lastPress, setLastPress] = useState(0);

  const onClick = () => {
    if (props.indicator || props.disabled || Date.now() - lastPress < 500) {
      return false;
    } else {
      setLastPress(Date.now());
      props.onClick();
      return true;
    }
  };

  return (
    <StyledRoundButton
      className={props.className}
      fontSize={props.fontSize || layout.fontSize}
      transition={layout.transition}
      darkButton={props.darkButton}
      disabledColor={colors.webMediumGray}
      color={colors.containerColor}
      disabled={props.disabled}
      padding={
        props.padding || props.smallButton
          ? layout.smallButtonPadding
          : layout.buttonPadding
      }
      onClick={onClick}
      warning={props.warning}
      warningColor={colors.errorColor}
      icon={props.icon}
      iconAfter={props.iconAfter}
      data-test={props['data-test']}
      mobWidth={props.mobWidth}
      radius={props.radius}
      height={props.height}
      hideBorder={props.hideBorder}
      nowrap={props.nowrap}
      lineHeight={props.lineHeight}
    >
      {props.icon ? (
        <Icon
          icon={props.icon}
          iconLib='material'
          iconColor={colors.webDarkBlue}
          iconSize={18}
          withoutContainer
        />
      ) : null}
      {props.label ?
        props.lowerCase ? props.label : props.label.toUpperCase() :
        props.children
      }
      {props.iconAfter ? (
        <Icon
          icon={props.iconAfter}
          iconLib='material'
          iconColor={colors.webDarkBlue}
          iconSize={18}
          withoutContainer
        />
      ) : null}
    </StyledRoundButton>
  );
};

const MyTulkaButton = ({
  indicator,
  darkButton,
  smallButton,
  warning,
  disabled,
  onClick,
  lowerCase,
  label,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const [lastPress, setLastPress] = useState(0);

  const pressHandler = () => {
    if (indicator || disabled || Date.now() - lastPress < 500) {
      return false;
    } else {
      setLastPress(Date.now());
      onClick();
      return true;
    }
  };

  return (
    <StyledMyTulkaButton
      fontSize={layout.smallFontSize}
      transition={layout.transition}
      buttonColor={colors.containerColor}
      darkButton={darkButton}
      disabledColor={colors.webMediumGray}
      color={colors.containerColor}
      disabled={disabled}
      padding={smallButton ? layout.smallButtonPadding : layout.buttonPadding}
      onClick={pressHandler}
      warning={warning}
      warningColor={colors.errorColor}
      {...rest}
    >
      {lowerCase ? label : label.toUpperCase()}
    </StyledMyTulkaButton>
  );
};

const MyTulkaReJoinButton = ({
  indicator,
  darkButton,
  smallButton,
  warning,
  disabled,
  onClick,
  lowerCase,
  label,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const [lastPress, setLastPress] = useState(0);

  const pressHandler = () => {
    if (indicator || disabled || Date.now() - lastPress < 500) {
      return false;
    } else {
      setLastPress(Date.now());
      onClick();
      return true;
    }
  };

  return (
    <StyledMyTulkaReJoinButton
      fontSize={layout.smallFontSize}
      transition={layout.transition}
      hoverColor={colors.containerColor}
      buttonColor='#1F1F3F'
      darkButton={darkButton}
      disabledColor={colors.webMediumGray}
      color={colors.containerColor}
      disabled={disabled}
      padding={smallButton ? layout.smallButtonPadding : layout.buttonPadding}
      onClick={pressHandler}
      warning={warning}
      warningColor={colors.errorColor}
      {...rest}
    >
      {lowerCase ? label : label.toUpperCase()}
    </StyledMyTulkaReJoinButton>
  );
};

const MyTulkaLinkButton = (props) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  return (
    <StyledMyTulkaLinkButton
      fontSize={layout.mediumFontSize}
      color={colors.webDarkBlue}
      {...props}
    />
  );
};
const LuonaButton = ({ label, handleClick, disabled, ...rest }) => {
  const { colors, layout} = useContext(ThemeContext);
  return (
    <StyledLuonaButton
      lightColor={colors.luonaMainLightColor}
      brightColor={colors.luonaMainBrightColor}
      transition={layout.transition}
      disabled={disabled}
      onClick={handleClick}
      {...rest}
    >
      {label}
    </StyledLuonaButton>
  );
};

MyTulkaButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  lowerCase: PropTypes.bool,
};

RoundButton.propTypes = {
  darkButton: PropTypes.bool,
  warning: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  indicator: PropTypes.bool,
  padding: PropTypes.string,
  color: PropTypes.string,
  smallButton: PropTypes.bool,
};

const CenterText = styled.span`
  vertical-align: sub;
  white-space: ${({rowWrap}) => rowWrap ? 'pre-wrap' : 'nowrap'};
`;

const BaseWizardButton = styled.button`
  display: inline-block;
  margin: auto;
  color: #ffffff;
  background: ${({ brightColor }) => brightColor};
  padding: 10px 20px;
  margin: 10px;
  border-radius: 2px;
  border: 0;
  font-weight: 700;
  cursor: pointer;
  letter-spacing: 0.1em;
  border: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  &:disabled {
    opacity: 0.8;
  }
`;

const LuonaWizardButton = ({children, ...props}) => {
  const themeContext = useContext(ThemeContext);
  const colors = themeContext.colors;
  return (
    <BaseWizardButton {...props}
      brightColor={colors.luonaMainBrightColor}
    >
      <CenterText rowWrap={props.rowWrap}>{children}</CenterText>
    </BaseWizardButton>
  );
};

const CollapsedTextButton = styled.span`
  text-transform: uppercase;
  position: relative;
  display: flex;
  justify-content: center;
  span {
    display: flex;
  }
  i {
    display: none;
  }
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    span {
      display: none;
    }
    i {
      display: flex;
      position: absolute;
      color: ${({ darkButton, color }) =>
        darkButton ? color : 'white'} !important;
    }
  }
`;

export {
  RoundButton,
  StyledClipboardButton,
  MyTulkaButton,
  MyTulkaReJoinButton,
  MyTulkaLinkButton,
  LuonaButton,
  LuonaWizardButton,
  CollapsedTextButton
};
