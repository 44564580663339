import React, { useContext } from 'react';
import MaterialIcons from '@material-ui/core/Icon';
import styled from 'styled-components';

import ThemeContext from '../contexts/ThemeContext';

const StyledMaterialIcons = styled(MaterialIcons)`
  ${({ width }) => (width ? `
    &.MuiIcon-root {
      width: ${width};
      height: ${width};
    }
  ` : '')};
`;

const IconContainer = styled.div`
  width: ${({ size }) => (size)}px;
  min-width: ${({ size }) => (size)}px;
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  text-align: center;
  transition: ${({ transition }) => (transition)};
  & > i, & > span {
    font-size: ${({ size }) => (size)}px;
    width: ${({ size }) => (size)}px;
    min-width: ${({ size }) => (size)}px;
    transition: ${({ transition }) => (transition)};
    &::before {
      vertical-align: super;
    }
  }
  &:hover {
    & > i, & > span {
      color: ${({ hoverColor }) => (hoverColor)} !important;
    }
  }
`;

const icons = {
  material: ({ icon, name, size, ...rest}) => {
    return (
      <StyledMaterialIcons
        component="i"
        className="material-icons material-icons-outlined"
        style={{
          fontSize: size,
          ...rest
        }}
      >
        {icon || name}
      </StyledMaterialIcons>
    );
  },
  fontAwesome: ({ icon, name, size, ...rest}) => {
    return (
      <StyledMaterialIcons
        component="i"
        className={`fa fa-${icon || name}`}
        style={{
          fontSize: size,
          ...rest
        }}
      ></StyledMaterialIcons>
    );
  },
};

const Icon = ({
  icon,
  iconLib,
  iconColor,
  iconSize,
  hoverColor,
  withoutContainer,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const size = iconSize || 25;

  const AnIcon = iconLib ? icons[iconLib] : null;

  if (withoutContainer) {
    return AnIcon ? (
      <AnIcon
        name={icon}
        icon={icon}
        size={size}
        width={'auto'}
        color={iconColor || colors.contentBoxBorderColorLight2020}
        {...rest}
      />
    ) : null;
  }
  return (
    <IconContainer
      size={size}
      transition={layout.transition}
      hoverColor={hoverColor}
      layout={layout}
      {...rest}
    >
    {AnIcon ? (
      <AnIcon
        name={icon}
        icon={icon}
        size={size}
        color={iconColor || colors.contentBoxBorderColorLight2020}
      />
    ) : null}
    </IconContainer>
  );
};

export default Icon;
