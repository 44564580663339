import React, { useContext, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import Modal from 'react-modal';
import ChatWizard from './ChatWizard';
import InterpreterNow from './InterpreterNow';
import { RoundButton } from '../components/Buttons';
import BookingsTable from '../components/BookingsTable';
import withLeftSideBar from '../hocs/withLeftSideBar';
import IconButton from '../components/IconButton';
import ConfirmationDialog from '../components/ConfirmationDialog';
import ModalDialogCloseButton from '../components/ModalDialogCloseButton';
import PrebookConteiner from '../components/PrebookConteiner';
import {
  MainHeader,
  MainContentTopBar,
  MainContentTopBarButtons,
  ContainerWrapper,
} from '../components/basicComponents';

import i18n from '../i18n';

Modal.setAppElement('#main');

const StyledHomeContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
`;

const getIsCancelForFree = (prebooking) => {
  const timeForFreeCancel = prebooking.interpretationDate - (prebooking.cancelForFreeSeconds * 1000) > Date.now();
  return timeForFreeCancel || !(timeForFreeCancel || prebooking?.interpreter?.id);
};

const Home = ({ toggleSideBar, sidebarDocked, ...rest }) => {
  const { user } = useContext(AppContext);
  const { colors, layout } = useContext(ThemeContext);
  const {
    setBusy,
    fetchLanguageList
  } = useContext(FunctionsContext);

  const [prebookingModalIsOpen, setPrebookingModalIsOpen] = useState(false);
  const [prebook, setPrebook] = useState(null);
  const [prebookPreviewIsOpen, setPrebookPreviewIsOpen] = useState(false);
  const [interpreterNowModalIsOpen, setInterpreterNowModalIsOpen] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [confimationDialod, setConfimationDialod] = useState('');
  const [skipConfirmationDialog, setSkipConfirmationDialog] = useState(false);
  const [separateConfirmationDialog, setSeparateConfirmationDialog] = useState(false);
  const [prebookModal] = useState({
    message: i18n('prebook_confirmation_message'),
    buttons: [],
    close: closePrebooking
  });
  const [prebookPreviewModal, setPrebookPreviewModal] = useState({
    message: i18n('cancel_prebook_confirmation_message'),
    buttons: [],
    close: () => {
      document.dispatchEvent(new CustomEvent('prebookCancellingConfirmed'));
    },
    notConfirmed: (aPrebook) => {
      const cancelForFree = getIsCancelForFree(aPrebook);
      amplitude.getInstance().logEvent('Cancel prebooking rejected', {
        'prebooking id': aPrebook.id,
        'cancel for free': cancelForFree ? 'yes' : 'no'
      });
    }
  });

  const [routeParams, setRouteParams] = useState({
    params: {
      pages: ['DateTime', 'Language', 'Skill', 'Favorite', 'Invoicing', 'CallType', 'Summary']
    }
  });

  const confimationSettings = {
    prebookingModal: prebookModal,
    prebookPreviewModal: prebookPreviewModal
  };

  const modalDialogStyles = {
    overlay: {
      zIndex: 999,
      backgroundColor: colors.webOverlay
    },
    content: {
      width: '100%',
      maxWidth: '660px',
      height: '100%',
      maxHeight: '750px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      borderRadius: '15px',
      border: 'none',
      overflow: 'initial',
      display: 'flex'
    }
  };

  function orderPrebooking(prebooking) {
    const skills = prebooking.skills ? prebooking.skills.filter(s => s.category.tag !== 'gender' && s.category.tag !== 'dialect') : [];
    const skill = skills.length > 0 ? skills[0] : null;

    setRouteParams({
      params: {
        pages: [],
        selectedLanguageId: prebooking.interpretedLanguage.id,
        targetLanguageId: prebooking.targetLanguage.id,
        gender: prebooking.gender,
        dialect: prebooking.dialect,
        skill,
        callType: prebooking.callType,
        prebookedInterpreterName: prebooking.interpreter?.name,
        prebookingId: prebooking.interpreter ? prebooking.id : null,
        invoiceUserGroup: prebooking.invoiceUserGroup,
        invoiceRef: prebooking.invoiceRef,
        invoiceRecipientRef: prebooking.invoiceRecipientRef,
        chatRoom: prebooking.chatRoom,
        phoneNumber: prebooking.hotlinePhoneNumber
      }
    });
    openPrebooking();
  }

  function openPrebookPreview(aPrebook, settingMode) {
    const { cloning, editMode, cansel } = settingMode;
    const cancelForFree = getIsCancelForFree(aPrebook);
    setPrebook(aPrebook);
    if (aPrebook.presence === 'notice') {
      setRouteParams({
        params: {
          pages: ['Language', 'CustomerInfo', 'Content', 'Invoicing', 'Date', 'Summary'],
        }
      });
    } else {
      setRouteParams({
        params: {
          pages: ['DateTime', 'Language', 'Skill', 'Favorite', 'Invoicing', 'CallType', 'Summary']
        }
      });
    }

    setPrebookPreviewModal({
      ...prebookPreviewModal,
      message: cancelForFree ? i18n('cancel_prebook_confirmation_message') : i18n('really_cancel_prebooking_too_late'),
      cloning,
      editMode,
      cansel
    });

    setBusy(true);
    setPrebookPreviewIsOpen(true);
  }

  function closePrebookPreview() {
    setPrebookPreviewIsOpen(false);
    setPrebookPreviewModal({
      ...prebookPreviewModal,
      cloning: false,
      editMode: false,
      cansel: false
    });
    setBusy(false);
  }

  function openPrebooking() {
    setBusy(true);
    setPrebookingModalIsOpen(true);
  }

  function closePrebooking() {
    setPrebookingModalIsOpen(false);
    setBusy(false);
    setRouteParams({
      params: {
        pages: ['DateTime', 'Language', 'Skill', 'Favorite', 'Invoicing', 'CallType', 'Summary']
      }
    });
  }

  function openConfirmationModal(name, isSeparate) {
    setConfimationDialod(name);
    setConfirmationDialogIsOpen(true);
    setSeparateConfirmationDialog(isSeparate);
  }

  function closeConfirmationModal(confirmed) {
    setConfirmationDialogIsOpen(false);
    setConfimationDialod('');
    if (confirmed) {
      confimationSettings[confimationDialod].close();
    } else if (!confirmed && confimationSettings[confimationDialod].notConfirmed) {
      confimationSettings[confimationDialod].notConfirmed(prebook);
    }
    if (separateConfirmationDialog) {
      closePrebookPreview();
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        await fetchLanguageList();
      } catch (e) {
        if (e.message === 'Already fetching') {
          console.log('e.message', e.message);
        }
      }
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!prebookPreviewIsOpen) {
      setPrebook(null);
    }
  }, [prebookPreviewIsOpen]);

  useEffect(() => {
    const func = async (ev) => {
      if (ev.detail) {
        setSkipConfirmationDialog(ev.detail.skip);
      }
    };
    document.addEventListener('setSkipConfirmationDialog', func);
    return () => {
      document.removeEventListener('setSkipConfirmationDialog', func);
    };
  }, []);

  const isPrebookingActive = useMemo(() => {
    return user.isPrebookingLanguages;
  }, [user.isPrebookingLanguages]);

  if (!user.id) {
    return null;
  }

  return (
    <StyledHomeContainer {...rest}>
      <MainContentTopBar
        borderBottomColor={colors.webDeviderColor}
      >
        <Modal
          isOpen={confirmationDialogIsOpen}
          style={{
            ...modalDialogStyles,
            overlay: {
              backgroundColor: 'transparent',
              zIndex: 9999
            }
          }}
        >
          <ConfirmationDialog
            message={confimationSettings[confimationDialod] ? confimationSettings[confimationDialod].message : ''}
            closeModal={closeConfirmationModal}
          />
        </Modal>
        <Modal
          isOpen={prebookingModalIsOpen}
          onRequestClose={() => {
            if (!skipConfirmationDialog) {
              openConfirmationModal('prebookingModal');
            } else {
              closePrebooking();
            }
          }}
          style={modalDialogStyles}
          contentLabel="Prebooking"
        >
          <ModalDialogCloseButton
            onClick={() => {
              if (!skipConfirmationDialog) {
                openConfirmationModal('prebookingModal');
              } else {
                closePrebooking();
              }
            }}
          />
          <ChatWizard
            closeModal={closePrebooking}
            route={routeParams}
          />
        </Modal>
        <Modal
          isOpen={prebookPreviewIsOpen}
          onRequestClose={closePrebookPreview}
          style={modalDialogStyles}
          contentLabel="Prebooking view"
        >
          <ModalDialogCloseButton
            onClick={() => closePrebookPreview()}
          />
          <PrebookConteiner
            prebook={prebook}
            routeParams={routeParams}
            cloningView={prebookPreviewModal.cloning}
            editModeView={prebookPreviewModal.editMode}
            canselView={prebookPreviewModal.cansel}
            ongoing={prebook && prebook.presence === 'remote' && prebook.interpretationDate - Date.now() <= (prebook.leewayBefore * 1000)}
            joinTheCall={() => {
              amplitude.getInstance().logEvent('Start prebooking tapped', {
                'prebooking id': prebook.id,
                'presence': prebook.presence
              });
              orderPrebooking(prebook);
            }}
            closeModal={closePrebookPreview}
            openConfirmationModal={(name, isSeparate) => openConfirmationModal('prebookPreviewModal', isSeparate)}
          />
        </Modal>
        <Modal
          isOpen={interpreterNowModalIsOpen}
          onRequestClose={() => {
            setInterpreterNowModalIsOpen(false);
            setBusy(false);
          }}
          style={modalDialogStyles}
          contentLabel="Interpreter Now"
        >
          <ModalDialogCloseButton
            onClick={() => {
              setInterpreterNowModalIsOpen(false);
              setBusy(false);
            }}
          />
          <InterpreterNow
            closeModal={() => {
              setInterpreterNowModalIsOpen(false);
              setBusy(false);
            }}
          />
        </Modal>
        {sidebarDocked ? null : (<IconButton
          icon={'menu'}
          iconLib={'material'}
          iconColor={colors.tulkaMainColor}
          onClick={toggleSideBar}
          data-tip="React-tooltip"
          data-event="click"
        />)}
        <MainHeader
          sidebarDocked={sidebarDocked}
          padding={layout.padding}
          color={colors.webDarkBlue}
        >
          {i18n('my_bookings')}
        </MainHeader>
        <MainContentTopBarButtons>
          <RoundButton
            data-test='interpreterNowBtn'
            label={i18n('interpreter_now')}
            onClick={() => {
              setBusy(true);
              setInterpreterNowModalIsOpen(true);
            }}
            smallButton={true}
            darkButton={true}
          />
          <RoundButton
            data-test='prebookedInterpretationBtn'
            label={i18n('pre_book')}
            onClick={openPrebooking}
            smallButton={true}
            darkButton={false}
          />
        </MainContentTopBarButtons>
      </MainContentTopBar>
      <ContainerWrapper>
        <BookingsTable
          active={isPrebookingActive}
          openPreview={openPrebookPreview}
          openEdit={openPrebookPreview}
          openCloning={openPrebookPreview}
          openCansel={openPrebookPreview}
        />
      </ContainerWrapper>
    </StyledHomeContainer>
  );
};

export default withLeftSideBar(Home);
