import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import ModalDialogCloseButton from '../components/ModalDialogCloseButton';
import ManageCollaborators from '../components/ManageCollaborators';
import CollaboratorsPreview from '../components/CollaboratorsPreview';
import AvailableBlock from '../components/AvailableBlock';
import ReJoinBlock from './ReJoinBlock';
import {
  getCollaborators,
} from '../services/collaboratorsService';

const getNameInitials = (userName) => {
  try {
    return userName.replace(/\s+/, '-').split('-').slice(0, 2).map(name => name[0].toUpperCase()).join('');
  } catch (e) {
    return '';
  }
};

const StyledUserBlock = styled.div`
  padding-left: 32px;
`;

const Avatar = styled.div`
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: ${({ background }) => background};
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  padding-top: 23px;
`;

const UserBlockText = styled.div`
  padding-bottom: 6px;
  color: #ffffff;
  font-size: ${({ fontSize }) => `${fontSize}px`};
`;

const AvatarBlock = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
`;

const UserBlock = ({ name, nameInitials, avatarColor, needToShowCollaborators, collaboratorsToShow, openCollaboratorsManagementDialog, isShowInterpreterAvailability }) => {
  return (
    <StyledUserBlock>
      <AvatarBlock>
        <Avatar
          background={avatarColor}
        >
          {nameInitials}
        </Avatar>
        {needToShowCollaborators ? (<CollaboratorsPreview
          avatarColor={avatarColor}
          collaboratorsToShow={collaboratorsToShow}
          openCollaboratorsManagementDialog={openCollaboratorsManagementDialog}
          getNameInitials={getNameInitials}
        />) : null}
      </AvatarBlock>
      <ReJoinBlock />
      {isShowInterpreterAvailability() && (
        <AvailableBlock />
      )}
      <UserBlockText fontSize={20}>{name}</UserBlockText>
    </StyledUserBlock>
  );
};

const UserProfile = () => {
  const appContext = useContext(AppContext);
  const { colors } = useContext(ThemeContext);
  const functionsContext = useContext(FunctionsContext);

  const [user] = useState(appContext.user);
  const [addCollaboratorsDialogIsOpen, setAddCollaboratorsDialogIsOpen] = React.useState(false);

  const [collaborators, setCollaborators] = useState([]);
  const [isUserAreCustomer] = useState(appContext.user?.type !== 'Interpreter');
  const [needToShowCollaborators, setNeedToShowCollaborators] = useState(appContext.user?.type !== 'Interpreter' && JSON.parse(localStorage.getItem('isCollaboratorsActive')));

  const isShowInterpreterAvailability = () => {
    return appContext.user?.proficiencies?.some(prof => {
      return prof.interpretedLanguage.distributionEngineVersion > 1;
    });
  };

  const updateCollaborators = async () => {
    try {
      const response = await getCollaborators();
      localStorage.setItem('isCollaboratorsActive', true);
      setNeedToShowCollaborators(true);
      setCollaborators(response);
    } catch (e) {
      localStorage.setItem('isCollaboratorsActive', false);
      setNeedToShowCollaborators(false);
      if (e.message === 'Session expired') {
        functionsContext.sessionExpired();
      }
    }
  };

  useEffect(() => {
    let updated = false;
    if (isUserAreCustomer && !updated) {
      updateCollaborators();
    }
    return () => {
      updated = true;
    };
  }, [isUserAreCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const nameInitials = (() => {
    try {
      if (user && user.name) {
        return getNameInitials(user.name);
      }
    } catch (e) {
      return '';
    }
    return '';
  })();

  const openAddCollaboratorsDialog = () => {
    functionsContext.setBusy(true);
    setAddCollaboratorsDialogIsOpen(true);
  };

  const closeAddCollaboratorsDialog = () => {
    setAddCollaboratorsDialogIsOpen(false);
    functionsContext.setBusy(false);
  };

  const modalDialogStyles = {
    overlay: {
      zIndex: 999,
      backgroundColor: colors.webOverlay
    },
    content: {
      width: '100%',
      maxWidth: '660px',
      height: '100%',
      maxHeight: '600px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      borderRadius: '15px',
      border: 'none',
      overflow: 'initial',
      display: 'flex'
    }
  };

  return (
    <div>
      {
        needToShowCollaborators ? (
          <Modal
            isOpen={addCollaboratorsDialogIsOpen}
            onRequestClose={closeAddCollaboratorsDialog}
            style={modalDialogStyles}
            contentLabel="Manage collaborators"
          >
            <ModalDialogCloseButton
              onClick={closeAddCollaboratorsDialog}
            />
            <ManageCollaborators
              userCollaborators={collaborators}
              closeModal={closeAddCollaboratorsDialog}
              updateCollaborators={updateCollaborators}
            />
          </Modal>
        ) : null
      }
      {user ? (
        <UserBlock
          avatarColor={colors.containerColor2020}
          name={user.name}
          nameInitials={nameInitials}
          needToShowCollaborators={needToShowCollaborators}
          collaboratorsToShow={collaborators?.slice(0, 3)}
          openCollaboratorsManagementDialog={openAddCollaboratorsDialog}
          isShowInterpreterAvailability={isShowInterpreterAvailability}
        />
      ) : null}
    </div>
  );
};

export default UserProfile;
