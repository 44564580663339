import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import i18n from '../i18n';
import { getRoomById, joinMeetingRoom, getCredentialsByChatRoomId } from '../services/VideoRoomService';
import { getChatRoomStatus, continueSession, endChatRoom } from '../services/chatRoomService';
import { logAnalytics } from '../services/analyticsLogger';
import useRoom from '../hooks/useVideoRoom';
import Modal from 'react-modal';
import Toolbar from './Toolbar';
import Chat from './Chat';
import Spinner from './Spinner';
import ConfirmationDialog from './ConfirmationDialog';
import ThemeContext from '../contexts/ThemeContext';
import AppContext from '../contexts/AppContext';
import useBackgroundBlur from '../hooks/useBackgroundBlur';
import { showError } from '../utils/msgbox';
import toast from '../services/toastService';
import { insertUrlParam } from '../utils/urlUtils';
import { getData } from '../utils/localStorage';
import { setLeftDate } from '../utils/rejoinChatroom';
import { SERVISES } from '../utils/luonaSpecificData';
import { getMediaPermissions } from '../utils/videoMeetingPermissions';
import Countdown from 'react-countdown';

Modal.setAppElement('#main');

const CallContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgb(32, 33, 36);
`;

const RoomContainer = styled.div`
  position: ${({ wrapp }) => wrapp ? 'absolute' : 'relative'};
  width: ${({ wrapp }) => wrapp ? '1px' : 'auto'};
  height: ${() => `${window.innerHeight - 50}px`};
  flex: 1;
  .video-audio-button {
    display: none !important;
  }
`;

const WaitingBlock = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const WaitingNotification = styled.div`
  margin-top: 20px;
  color: #ffffff;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const VideoRoom = (props) => {
  const roomContainer = useRef();
  const {
    createCall,
    room,
    leaveOfCall,
    participants,
    wrappParticipants,
    cameaCreated,
    networkStatus
  } = useRoom();
  const search = useLocation().search;
  const chatRoomId = new URLSearchParams(search).get('chatRoomId');
  const [ isChat ] = useState(false);
  const [ localAudio, setLocalAudio ] = useState(props.audio);
  const [ localVideo, setLocalVideo ] = useState(props.video);
  const [ localBlur, setLocalBlur ] = useState(props.videoEffects.backgroundBlur);
  const [ credentials, setCredentials ] = useState(null);
  const [ showChat, setShowChat] = useState(false);
  const [ isWaiting, setIsWaiting] = useState(chatRoomId ? false : true);
  const [ timerId, setTimerId ] = useState(null);
  const [ participantCount, setParticipantCount ] = useState(3);
  const [ timestamp, setTimestamp ] = useState(0);
  const [ timerTimestampId , setTimerTimestampId ] = useState(0);
  const [ isRejoin ] = useState(!!chatRoomId);
  const [ confirmationDialogIsOpen, setConfirmationDialogIsOpen ] = useState(false);
  const { colors, layout } = useContext(ThemeContext);
  const { user } = useContext(AppContext);
  const waitingTime = 3 * 60 * 1000; // 3 min
  const waitingParticipantTime = 5 * 60 * 1000; // 5 min
  const [startCountdownDate] = useState(Date.now() + waitingTime);
  let POLLING_TIMEOUT_ID = 0;
  let WAITING_PARTICIPANT_TIME_ID = 0;
  let IS_WAITING_MESSAGE = 0;
  let skill = props.selectedLangs.skill;

  const {
    toggleBlurEffect,
  } = useBackgroundBlur();

  const closeCurrentChatroom = async () => {
    const previousChatroom = getData('previousChatroom');
    if (previousChatroom?.id) {
      await endChatRoom(previousChatroom.id);
    }
    leaveOfCall();
  };

  const getCurrentParticipantsFromUi = () => {
    const subscribers = document.getElementsByClassName('OT_subscriber');
    const publisher = document.getElementsByClassName('OT_publisher');
    const participantsComponents = [...subscribers, ...publisher];
    return participantsComponents?.length;
  };

  const setWaitingParticipantTime = () => {
    WAITING_PARTICIPANT_TIME_ID = setTimeout(async () => {
      const previousChatroom = getData('previousChatroom');
      const participantCountFromUi = getCurrentParticipantsFromUi();
      if (participantCountFromUi < previousChatroom?.participantCount) {
        logAnalytics('Meeting is closed. Reason - not all participants rejoined', {
          userId: user?.id
        });
        await closeCurrentChatroom();
        localStorage.removeItem('previousChatroom');
        props.handleLeave(previousChatroom.id);
      }
    }, waitingParticipantTime);
  };

  const heandleLeaveParticipant = async () => {
    const previousChatroom = getData('previousChatroom');
    if (previousChatroom?.participantCount === 2) {
      setLeftDate();
      await closeCurrentChatroom();
      props.handleLeave(previousChatroom.id);
    } else {
      setWaitingParticipantTime();
    }
  };

  const startPolling = async (roomId) => {
    clearTimeout(POLLING_TIMEOUT_ID);
    POLLING_TIMEOUT_ID = setTimeout(async() => {
      if (roomId) {
        try {
          const status = await getChatRoomStatus(roomId);
          if (status?.closed) {
            logAnalytics('Chatroom closed', {
              'chat room id': roomId,
              userId: props.user?.id
            });
            console.log('chatroom closed: ', roomId);
            clearTimeout(POLLING_TIMEOUT_ID);
            setLeftDate();
            leaveOfCall();
            if (window.location.pathname.includes('/meetings')) {
              props.handleLeave(roomId);
            }
          } else {
            startPolling(roomId);
          }
        } catch (e) {
          console.log('Previus pooling failed with a reason ', e?.message);
          logAnalytics('Previus pooling failed', {
            error: e?.message,
            userId: user?.id
          });
          startPolling(roomId);
        }
      }
    }, 2000);
  };

  useEffect(() => {
    props.heandleChangeVideoEffects({
      backgroundBlur: localBlur
    });
  }, [localBlur]); // eslint-disable-line react-hooks/exhaustive-deps

  const initParticipantslist = [
    { order: 1, text: i18n('luona_worker_persone') },
    { order: 2, text: i18n('interpreter_persone') }
  ];

  const getWatingParticipants = participantsArray => {
    if (participantsArray?.length > 0) {
      const participantslist = initParticipantslist.slice(0, participantCount - 1);
      const isMe = participantsArray.find(participant => participant.isMe);
      return isMe ? participantslist.filter(participant => participant.order !== isMe.order) : participantslist;
    }
    return [];
  };

  const getWaitingText = () => {
    // (0 for customer, 1 for luona, 2 for interpreter, 3… for other participants)
    const watingParticipants = getWatingParticipants(participants);
    const initMessage = props.isEndUser ? i18n('connecting_to_meeting') : i18n('connecting_to_meeting_for_interpreter');

    const existParticipants = participants.reduce((memo, participantData) => {
      if (!participantData.isMe) {
        return memo.concat([participantData.order]);
      }
      return memo;
    }, []);
    const filteredParticipants = watingParticipants.reduce((memo, person) => {
      if (!existParticipants.includes(person.order)) {
        return memo.concat([person.text]);
      }
      return memo;
    }, []);
    if (filteredParticipants?.length > 0) {
      return `${i18n('looking_for')} ${filteredParticipants.join(', ')}`;
    }
    return initMessage;
  };
  const [ message, setMessage] = useState(getWaitingText());
  const history = useHistory();

  const getParticipantAmount = chatRoom => {
    const min = 2;
    const linkedChatrooms = chatRoom.linkedChatRoomIds?.length || 0;
    const result = Math.max(min, linkedChatrooms + 1);
    logAnalytics('Participant amount', {
      amount: result,
      userId: user?.id
    });
    return result;
  };

  const setPreviousChatroom = chatRoom => {
    const amount = getParticipantAmount(chatRoom);
    setParticipantCount(amount);
    localStorage.setItem('previousChatroom', JSON.stringify({
      id: chatRoom.id,
      callType: chatRoom.callType,
      participantCount: amount
    }));
    insertUrlParam('chatRoomId', chatRoom.id);
    startPolling(chatRoom.id);
  };

  const createOrJoinChatRoom = async (isEndUser) => { // eslint-disable-line consistent-return
    if (!isEndUser) {
      try {
        const data = await joinMeetingRoom();
        setPreviousChatroom(data);
        return data;
      } catch (err) {
        logAnalytics('Error join to meeting', {
          error: err,
          userId: user?.id
        });
        history.replace('/');
      }
    } else {
      try {
        const data = await getRoomById(props.selectedLangs, props.prebooking);
        setPreviousChatroom(data);
        return data;
      } catch (err) {
        if (typeof err.message === 'string' && err.message.includes('No interpreter in prebooking')){
          showError(i18n('no_interpreter_in_prebooking'));
        }
        logAnalytics('Error create meeting', {
          error: err?.message || err,
          userId: user?.id
        });
        history.replace('/wizard');
      }
    }
  };

  const redirectAfterNotAnsweredCall = (prebooking) => {
    if (prebooking) {
      showError(i18n('prebooking_not_answered'));
    }
    history.replace({
      pathname: '/wizard',
      state: {
        stepName: 'BookTimeStep',
        skill: props.selectedLangs.skill || skill || SERVISES.socialServiseId
      }
    });
  };

  const setTimer = () => {
    IS_WAITING_MESSAGE = setTimeout(async () => {
      const previousChatroom = getData('previousChatroom');
      const participantCountFromUi = getCurrentParticipantsFromUi();
      if (participantCountFromUi < previousChatroom?.participantCount) {
        logAnalytics('Meeting is closed. Reason - not all participants joined', {
          userId: user?.id
        });
        await closeCurrentChatroom();
        localStorage.removeItem('previousChatroom');
        redirectAfterNotAnsweredCall(props.prebooking);
      }
    }, waitingTime);
    setTimerId(IS_WAITING_MESSAGE);
  };

  const rejoinParticipant = async(isEndUser) => {
    const previousChatroom = getData('previousChatroom');
    const status = await getChatRoomStatus(chatRoomId);
    if (status && !status.closed) {
      setParticipantCount(previousChatroom?.participantCount);
      const res = await getCredentialsByChatRoomId(chatRoomId);
      startPolling(previousChatroom?.id);
      return res;
    }
    if (isEndUser && status?.closed && previousChatroom?.participantCount === 2) {
      const res = await continueSession(chatRoomId);
      localStorage.removeItem('previousChatroom');
      setPreviousChatroom(res);
      setTimer();
      return res;
    }
    showError(i18n('meeting_closed'));
    localStorage.removeItem('previousChatroom');
    props.handleLeave(previousChatroom.id);
    logAnalytics('Error rejoin participant', {
      error: 'meeting closed',
      userId: user?.id
    });
    leaveOfCall();
    return; // eslint-disable-line consistent-return
  };

  const createOrRejoinMeetion = async(isEndUser) => {
    if (isRejoin) {
      const data = await rejoinParticipant(isEndUser);
      return data;
    }
    localStorage.removeItem('previousChatroom');
    const data = await createOrJoinChatRoom(isEndUser);
    return data;
  };

  const clearAllTimeouts = () => {
    clearTimeout(timerId);
    clearTimeout(IS_WAITING_MESSAGE);
    clearTimeout(timerTimestampId);
    clearTimeout(POLLING_TIMEOUT_ID);
    clearTimeout(WAITING_PARTICIPANT_TIME_ID);
  };

  const getSkill = (data) => {
    if (data.skills?.length > 0) {
      const servicesIds = Object.values(SERVISES);
      const result = data.skills.find(s => servicesIds.includes(s.id));
      return result?.id;
    }
    return null;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await getMediaPermissions();
        const data = await createOrRejoinMeetion(props.isEndUser);
        skill = getSkill(data); // eslint-disable-line react-hooks/exhaustive-deps
        setCredentials({
          apikey: data.apiKey,
          sessionId: data.sessionId,
          token: data.chatToken,
        });
      } catch (err) {
        if (err === 'OT_USER_MEDIA_ACCESS_DENIED') {
          logAnalytics('No access to camera or microphone', {
            userId: user.id
          });
          showError(i18n('no_access_to_your_camera_or_microphone'));
        }
        console.log('ERROR: ', err);
        logAnalytics('Error create or rejoin meeting', {
          error: err,
          userId: user.id
        });
        localStorage.removeItem('previousChatroom');
        const path = props.isEndUser ? '/wizard' : '/';
        history.replace(path);
      }
    }
    let updated = false;
    if (!updated) {
      fetchData();
    }
    if (isWaiting && waitingTime && props.isEndUser) {
      setTimer();
    }
    const timerTimestamp = setInterval(() => setTimestamp(Date.now()), 3000);
    setTimerTimestampId(timerTimestamp);
    return (() => {
      leaveOfCall();
      clearAllTimeouts();
      updated = true;
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isWaiting) {
      setLocalAudio(true);
      clearTimeout(timerTimestampId);
      clearTimeout(IS_WAITING_MESSAGE);
      clearTimeout(timerId);
      logAnalytics('Waiting message is hidden', {
        userId: user?.id
      });
    }
  }, [isWaiting]); // eslint-disable-line react-hooks/exhaustive-deps

  const prepareToShowUserData = username => {
    if (typeof username === 'string') {
      const fullName = username.split(' ');
      const firstName = fullName[0];
      const lastName = fullName[1];
      return lastName?.length > 0 ? `${firstName} ${lastName[0]}` : firstName;
    }
    return '';
  };

  useEffect(() => {
    if (credentials) {
      try {
        createCall(
          props.handleLeave,
          heandleLeaveParticipant,
          credentials,
          roomContainer.current,
          prepareToShowUserData(props.username || user.name),
          { publishAudio: localAudio, publishVideo: localVideo }
        );
      } catch (e) {
        logAnalytics('Error joining to meeting from Vonage', {
          error: e,
          userId: user?.id
        });
        history.replace('/error');
      }
    }
  }, [createCall, credentials, props.userName, leaveOfCall]); // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => {
  if (cameaCreated && isWaiting) {
    setLocalAudio(false);
  }
}, [cameaCreated, isWaiting]);

useEffect(() => {
  if (networkStatus) {
    const method = networkStatus.status === 'error' ? 'showError' : 'showSuccess';
    if (typeof toast[method] === 'function') {
      toast[method](i18n(networkStatus.message));
    }
  }
}, [networkStatus]);

  useEffect(() => {
  if (room) {
    if (localAudio && !room.camera.isAudioEnabled()) {
      room.camera.enableAudio();
    } else if (!localAudio && room.camera.isAudioEnabled()) {
      room.camera.disableAudio();
    }
  }
}, [localAudio, room]);

useEffect(() => {
if (room) {
  if (localVideo && !room.camera.isVideoEnabled()) {
    room.camera.enableVideo();
  } else if (!localVideo && room.camera.isVideoEnabled()) {
    room.camera.disableVideo();
  }
}
}, [localVideo, room]);

useEffect(() => {
  if (isWaiting) {
    console.log('try up view');
    setTimeout(() => {
      const container = document.getElementById('layoutContainer');
      const subscribers = document.getElementsByClassName('OT_subscriber');
      const publisher = document.getElementsByClassName('OT_publisher');
      const fixedSubscribers = Array.from(subscribers).map((subscriber, index) => {
        subscriber.setAttribute('user-name', wrappParticipants[index]?.name);
        const participantName = subscriber.getElementsByClassName('participant-name')[0];
        const targetBlock = subscriber.getElementsByClassName('bottomRightContainer-1-0-8');
        if (targetBlock?.length > 0 && !participantName) {
          const el = document.createElement('div');
          el.classList.add('participant-name', 'name-1-0-3');
          const participantNameEl = participantName || el; // eslint-disable-line no-unused-vars
          // participantNameEl.textContent = wrappParticipants[index]?.name;
          // targetBlock[targetBlock.length - 1].appendChild(participantNameEl);
        }
        return subscriber;
      });
      const participantsComponents = [...fixedSubscribers, ...publisher];
      if (container && participantsComponents?.length === participantCount ) {
        const sortedSubscribers = fixedSubscribers.sort((elem1, elem2) => {
          const a = participants.find(participant => participant.name === elem1.getAttribute('user-name'))?.order || 0;
          const b = participants.find(participant => participant.name === elem2.getAttribute('user-name'))?.order || 0;
          return a - b;
        });
        const sortedParticipants = [...sortedSubscribers, ...publisher];
        sortedParticipants.forEach((item) => {
          container.appendChild(item);
        });
      }
      setIsWaiting(participantsComponents?.length < participantCount);
      setMessage(getWaitingText());
      if (participantsComponents?.length === participantCount) {
        clearTimeout(IS_WAITING_MESSAGE);
        clearTimeout(timerId);
      }
    }, 1000);
  }
}, [participants, wrappParticipants, timestamp]); // eslint-disable-line react-hooks/exhaustive-deps

const handleBluer = async () => {
  toggleBlurEffect(room?.camera, !localBlur);
  setLocalBlur(!localBlur);
};

const closeConfirmationModal = async(confirmed) => {
  setConfirmationDialogIsOpen(false);
  const previousChatroom = getData('previousChatroom');
  if (confirmed) {
    logAnalytics('Leave meeting', {
      userId: user.id
    });
    await closeCurrentChatroom();
    localStorage.removeItem('previousChatroom');
    props.handleLeave(previousChatroom.id);
  }
};

const renderer = ({hours, minutes, seconds }) => {
  return (
    <div>
      <WaitingNotification>{i18n('waiting_time')} <span>{hours}:{minutes}:{seconds}</span></WaitingNotification>
    </div>
  );
};

  return (
    <CallContainer>
      <Modal
        isOpen={confirmationDialogIsOpen}
        style={{
          ...layout.modalDialogStyles,
          overlay: {
            backgroundColor: 'transparent',
            zIndex: 9999
          }
        }}
      >
        <ConfirmationDialog
          message={i18n('close_meeting_confirmation_message')}
          closeModal={closeConfirmationModal}
        />
      </Modal>
      {
        isWaiting && (
          <WaitingBlock>
            <div>
              <Spinner color={colors.luonaMainBrightColor} />
              <WaitingNotification>{message}</WaitingNotification>
              <Countdown
                onComplete={() => {
                  console.log('COMPLETE');
                } }
                date={startCountdownDate}
                renderer={renderer}
              />
            </div>
          </WaitingBlock>
        )
      }
      <Row>
        <RoomContainer wrapp={isWaiting} ref={roomContainer} id="roomContainer"></RoomContainer>
        {room && isChat && (
          <Chat show={showChat} room={room} toggleChat={() => setShowChat(!showChat)} />
        )}
      </Row>
      <Toolbar
        audio={localAudio}
        video={localVideo}
        blur={localBlur}
        setAudio={setLocalAudio}
        setVideo={setLocalVideo}
        setBlur={handleBluer}
        heandlerLeave={() => {
          setConfirmationDialogIsOpen(true);
        }}
        heanderChat={() => setShowChat(!showChat)}
        isChat={isChat}
      />
    </CallContainer>
  );
};

export default VideoRoom;
