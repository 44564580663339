import React, { useContext } from 'react';
import ThemeContext from '../contexts/ThemeContext';
import i18n from '../i18n';

import Select, {components} from 'react-select';
import styled from 'styled-components';
import Icon from './Icon';

const StyledComponentsInput = styled(components.Input)`
  input {
    border-radius: 0;
    color: ${({ color }) => (color)} !important;
    &::-webkit-input-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &::-moz-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &:-ms-input-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &:-moz-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
  }
`;

const StyledNoOptionsMessage = styled.span`
  padding: 10px;
  display: flex;
  flex: 1;
`;

const StyledIcon = styled(Icon)`
  margin-left: 10px;
`;

const MenuItemText = styled.div`
  flex: 1 1 auto;
  display: flex;
  color: #ffffff;
`;

const SelectLanguages = ({selectedLanguage, avaliableLangs, handleSwithcLanguage, activeColor, hoverColor, backgroundHoverColor}) => {
  const { colors, layout} = useContext(ThemeContext);

  return (
    <Select
      value={{
        value: selectedLanguage,
        label: selectedLanguage?.toUpperCase()
      }}
      options={avaliableLangs.map((lang) => ({
        value: lang.key,
        label: lang.key.toUpperCase()
      }))}
      isSearchable={false}
      onChange={handleSwithcLanguage}
      styles={{
        control: (styles, { isFocused }) => ({
          ...styles,
          boxShadow: 'none',
          borderColor: isFocused ? colors.webMediumGray : '#cccccc',
          cursor: 'pointer',
          ':hover': {
            borderColor: colors.webMediumGray
          }
        }),
        option: (base, s) => ({
          ...base,
          transition: layout.transition,
          backgroundColor: s.isSelected ? activeColor || colors.luonaMainBrightColor : 'white',
          cursor: 'pointer',
          ':active': {
            backgroundColor: s.isSelected ? activeColor || colors.luonaMainBrightColor : 'white'
          },
          ':hover': {
            backgroundColor: backgroundHoverColor || colors.luonaMainLightColor,
            color: hoverColor || colors.luonaMainBrightColor
          }
        }),
      }}
      components={{
        Input: (props) => {
          return <StyledComponentsInput
            color={colors.webGray}
            lighttextcolor={colors.webMediumGray}
            {...props}
          />;
        },
        NoOptionsMessage: () => (
          <StyledNoOptionsMessage>
            {i18n('no_options')}
          </StyledNoOptionsMessage>
        ),
        LoadingMessage: () => (
          <StyledNoOptionsMessage>
            {i18n('search_message')}
          </StyledNoOptionsMessage>
        ),
        Control: ({ children, ...nestedRest }) => (
          <components.Control {...nestedRest}>
            <StyledIcon
              icon='language'
              iconLib='material'
              iconColor={colors.webDarkBlue}
              iconSize={24}
              hoverColor={colors.webDarkBlue}
            />
            <MenuItemText>
              {children}
            </MenuItemText>
          </components.Control>
        )
      }}
    />
  );
};

export default SelectLanguages;
