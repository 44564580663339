import { useState, useContext, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import AppContext from '../contexts/AppContext';
import FunctionsContext from '../contexts/FunctionsContext';
import ThemeContext from '../contexts/ThemeContext';
import i18n from '../i18n';
import { setUserConfirmedData } from '../services/userService';
import { validateFieldEmail } from '../utils/validateFields';
import { Input } from './Input';
import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import HomeUserGroupSelector from './HomeUserGroupSelector';
import ButtonPanel from './ButtonPanel';
import { RoundButton } from './Buttons';
import Spinner from '../components/Spinner';
import { showError } from '../services/toastService';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: ${({ padding }) => (padding * 3)}px;
  overflow: hidden;
  border-radius: 15px;
  border: none;
  position: relative;
  max-width: 800px;
  margin: auto;
  height: 100%;
  .innerBlock {
    height: 100%;
    max-height: 200px;
    overflow: auto;
  }
`;

const Title = styled.div`
  font-size: ${({ fontSize }) => (fontSize)}px;
  color: ${({ color }) => (color)};
  margin: 10px 0 15px;
  font-weight: 600;
  ${({ textTransform }) => textTransform ? `
    text-transform: ${textTransform};
  ` : ''}
`;

const SubTitleText = styled.div`
  flex: 1;
  display: flex;
  font-size: ${({ fontSize }) => (fontSize)}px;
  text-align: left;
  color: ${({ color }) => (color)};
  background-color: transparent;
  margin-bottom: 0;
  align-items: center;
`;


const StyledInput = styled(Input)`
  background-color: #ffffff;
  margin-bottom: 15px;
`;

const StyledErrorMessage = styled.div`
  color: ${({ color }) => (color)};
  padding: 5px 0;
`;

const validationFuncMap = {
  email: validateFieldEmail,
  username: validateFieldEmail
};

const initialErrors = {
    email: null,
    username: null,
  };

const notConfirmUserGroupRoles = [
  'supervisor',
  'admin',
  'invoicing',
  'salaries'
];

const checkDefaultUsername = (uName) => {
  if (validateFieldEmail(uName)) {
    return uName;
  }
  return '';
};

const AccountDataConfirmation = ({closeModal, ...rest}) => {
    const { user } = useContext(AppContext);
    const { colors, layout } = useContext(ThemeContext);
    const { fetchUserAndUpdateLocally, sessionExpired } = useContext(FunctionsContext);
    const [confirmationUserData, setConfirmationUserData] = useState(
      { 
        email: user?.email || '', 
        username: checkDefaultUsername(user?.username), 
        userGroup: {
          id: user?.userGroup?.id, 
          name: user?.userGroup?.name
        }
      } 
    );
    const [errors, setErrors] = useState(initialErrors);

    const namesWithEmail = useMemo(() => {
      return ['email', 'username'];
    }, []);

    const isShowUserGropuConfirmation = useMemo(() => {
      return user.type !== 'Interpreter' && !notConfirmUserGroupRoles.includes(user.role) && !user.userGroupVerified;
    }, [user.role, user.type, user.userGroupVerified]);

    const setInitialTouchedValues = (data) => {
      return Object.keys(data).reduce((memo, elem) => {
        if (namesWithEmail.includes(elem)) {
          memo[elem] = !!confirmationUserData[elem];
        }
        if (elem === 'userGroup' && isShowUserGropuConfirmation) {
          memo.userGroupId = !!confirmationUserData[elem].id;
        }
        return memo;
      }, {});
    };
    
    const [touched, setTouched] = useState(() => setInitialTouchedValues(confirmationUserData));
    const [indicator, setIndicator] = useState(false);

    const createBody = useCallback(() => {
      return Object.keys(confirmationUserData).reduce((memo, elem) => {
        if (namesWithEmail.includes(elem) && confirmationUserData[elem] !== user[elem]) {
          memo[elem] = confirmationUserData[elem];
        }
        if (isShowUserGropuConfirmation && elem === 'userGroup' && 
            confirmationUserData[elem].id !== user[elem].id) {
          memo.userGroupId = confirmationUserData[elem].id;
        }
        if (isShowUserGropuConfirmation && elem === 'userGroup' && 
          confirmationUserData[elem].id === user[elem].id) {
          memo.userGroupId = '';
        }
        return memo;
      }, {});
    }, [confirmationUserData, user, namesWithEmail, isShowUserGropuConfirmation]);
    
    const updateUserData = async () => {
      const body = createBody();

      try {
        await setUserConfirmedData(body, true);
        await fetchUserAndUpdateLocally();
        setIndicator(false);
        closeModal();
      } catch (e) {
        if (e.message === 'Session expired') {
          sessionExpired();
        }
        if (e.message === 'username is not unique') {
          showError(i18n('username_not_unique'));
        }
      }
    };

    const updateInformation = async () => {
      setIndicator(true);
      try {
        await Promise.all([
          updateUserData()
        ]);
      } catch (e) {
        console.log('MODAL err:', e);
      }
      setIndicator(false);
      closeModal();
      };

    const saveEnteredUserData = (name, value) => {
        setConfirmationUserData((prev) => {
          return {...prev, [name]: value};
        }); 
        setErrors(prev => ({ ...prev, [name]: false }));
        setTouched(prev => ({ ...prev, [name]: true }));
    };

    const updateErrors = (name, value) => {
        const isValidFunc = validationFuncMap[name];
        if (typeof isValidFunc === 'function') {
          const isValid = isValidFunc(value);
          setErrors(prev => ({ ...prev, [name]: !isValid }));
          setTouched(prev => ({ ...prev, [name]: true }));
        }
    };

    const isValidForm = (userDataParam, errorsParam) => {
        const error = Object.values(errorsParam).some(err => err);
        const emptyField = Object.values(userDataParam).some(field => !field);
        return !error && !emptyField;
      };

    const isDisabled = useMemo(() => {
        return !isValidForm(touched, errors);
    }, [touched, errors]);

    const validationErrorMessage = useMemo(() => {
        const { email, username} = errors;
        if (email || username) {
            return i18n('invalid_email_address');
        }
        return 'Validation Error';
    }, [errors]);

    return (
        <StyledContainer
            padding={layout.padding}
            {...rest}
        >
             <ChatWizardHeader
                title={`${i18n('hey_username').replace('{username}', user.name)} \n${i18n('confirm_your_account_information')}`}
                helpId={isShowUserGropuConfirmation ? 'confirmation' : ''}
                alignHelpButtonBottom={true}
            />
            <ContentBox
                minorTitle={i18n('confirm_your_account_information_description')}
            >
              {isShowUserGropuConfirmation && (
                <>
                  <Title
                    color={colors.webDarkBlue}
                    fontSize={layout.fontSize}
                  >
                    {i18n('choose_default_cost_center')}
                  </Title>
                  <HomeUserGroupSelector
                    className='innerBlock'
                    id="confirmationModalDialog"
                    selectedUsergroup={confirmationUserData.userGroup}
                    setSelectedUsergroup={(uGroup) => uGroup && setConfirmationUserData(prev => ({...prev, userGroup: uGroup}))}
                    pureList={true}
                  />
                </>
              )}
                <Title
                  color={colors.webDarkBlue}
                  fontSize={layout.fontSize}
                  textTransform='uppercase'
                >
                  {i18n('enter_or_confirm_username')}
                </Title>
                    <StyledInput 
                        type='mail'
                        placeholder={i18n('label_email')}
                        value={confirmationUserData.username}
                        onChange={(v) => saveEnteredUserData('username', v)}
                        onBlur={() => updateErrors('username', confirmationUserData.username)}
                        data-test='confirmationUserEmailForUsername'
                    />
                    {errors.username ? (
                    <StyledErrorMessage color={colors.error}>
                      {validationErrorMessage}
                    </StyledErrorMessage>
                    ) : null}
                <Title
                  color={colors.webDarkBlue}
                  fontSize={layout.fontSize}
                >
                  {i18n('enter_or_confirm_email')}
                </Title>
                    <StyledInput 
                        type='mail'
                        placeholder={i18n('label_email')}
                        value={confirmationUserData.email}
                        onChange={(v) => saveEnteredUserData('email', v)}
                        onBlur={() => updateErrors('email', confirmationUserData.email)}
                        data-test='confirmationUserEmail'
                    />
                    {errors.email ? (
                    <StyledErrorMessage color={colors.error}>
                      {validationErrorMessage}
                    </StyledErrorMessage>
                    ) : null}
            </ContentBox>
            {indicator ? (<Spinner overlay />): null}
            <ButtonPanel column={true}>
                {isShowUserGropuConfirmation && (
                  <SubTitleText
                    color={colors.webGray}
                    fontSize={layout.mediumFontSize}
                  >
                    {i18n('you_can_change_this_information_at_any_time')}
                  </SubTitleText>
                )}
                <RoundButton
                    darkButton
                    disabled={isDisabled}
                    color={colors.tulkaMainColor}
                    padding={layout.buttonPadding}
                    label={i18n('i_confirm_the_information')}
                    onClick={updateInformation}
                />
            </ButtonPanel>
        </StyledContainer>
    );
};

export default AccountDataConfirmation;