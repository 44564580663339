import React, { useContext, useState, useEffect} from 'react';
import styled from 'styled-components';

import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import i18n from '../i18n';
import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import Spinner from '../components/Spinner';
import CollaboratorsAddingForm from './CollaboratorsAddingForm';
import CollaboratorsList from './CollaboratorsList';

import {
  addCollaborator,
  removeCollaborator,
} from '../services/collaboratorsService';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: ${({ padding }) => (padding * 3)}px;
  overflow: hidden;
  border-radius: 15px;
  border: none;
  position: relative;
`;

const ManageCollaborators = ({ userCollaborators, updateCollaborators, ...rest }) => {
  const themeContext = useContext(ThemeContext);
  const functionsContext = useContext(FunctionsContext);

  const layout = themeContext.layout;

  const [indicator, setIndicator] = React.useState(false);
  const [collaborators, setCollaborators] = useState(userCollaborators);

  useEffect(() => {
    setCollaborators(userCollaborators);
  }, [userCollaborators]);

  const addCollaboratorToTheList = async (emailOrPhone) => {
    setIndicator(true);
    try {
      await addCollaborator(emailOrPhone);
      await updateCollaborators();
      setIndicator(false);
    } catch (e) {
      if (e.message === 'Session expired') {
        functionsContext.sessionExpired();
      }
      setIndicator(false);
    }
  };

  const removeCollaboratorFromList = async (id) => {
    setIndicator(true);
    try {
      await removeCollaborator(id);
      await updateCollaborators();
      setIndicator(false);
    } catch (e) {
      if (e.message === 'Session expired') {
        functionsContext.sessionExpired();
      }
      setIndicator(false);
    }
  };

  return (
    <StyledContainer
      padding={layout.padding}
      {...rest}
    >
      <ChatWizardHeader
        title={i18n('collaborators_header')}
        data-test='collaboratorsHeader'
      />
      <ContentBox
        minorTitle={i18n('collaborators_header_description')}
        dataTest='collaboratorsHeaderDescription'
      >
        <CollaboratorsAddingForm
          collaborators={collaborators}
          addCollaborator={addCollaboratorToTheList}
          data-test='addCollaboratorEmailInput'
        />
        {collaborators && collaborators.length > 0 ? (
          <CollaboratorsList
            collaborators={collaborators}
            removeCollaborator={removeCollaboratorFromList}
            data-test='collaboratorsList'
          />
        ) : null}
        {indicator ? (
          <Spinner
            overlay
          />
        ): null}
      </ContentBox>
    </StyledContainer>
  );
};

export default ManageCollaborators;
