import React, { useContext } from 'react';
import styled from 'styled-components';

import ContentBox from './ContentBox';
import ActionList from './ActionList';
import ActionListItem from './ActionListItem';
import ChatWizardHeader from './ChatWizardHeader';
import i18n from '../i18n';
import AppContext from '../contexts/AppContext';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const PresencePage = props => {
  const context = useContext(AppContext);

  return (
    <StyledContainer>
      <ChatWizardHeader
        title={i18n('presence_question')}
      />
      <ContentBox>
        <ActionList>
          <ActionListItem
            data-test='presenceRemoteActionItem'
            title={i18n('presence_remote')}
            subtitle={i18n('presence_remote_tip')}
            icon={'phone_android'}
            iconLib={'material'}
            onClick={() => {
              props.onChanged('remote');
            }}
            selected={props.presence === 'remote'}
          />
          <ActionListItem
            data-test='presenceOnSiteActionItem'
            title={i18n('presence_on_site')}
            subtitle={i18n('presence_on_site_tip')}
            icon={'building-o'}
            iconLib={'fontAwesome'}
            visible={context.user.isOnSiteLanguages}
            onClick={() => {
              props.onChanged('onsite');
            }}
            selected={props.presence === 'onsite'}
          />
          <ActionListItem
            data-test='presenceConferenceActionItem'
            title={i18n('presence_conference')}
            subtitle={i18n('presence_conference_tip')}
            icon={'group'}
            iconLib={'fontAwesome'}
            onClick={() => {
              props.onChanged('external');
            }}
            selected={props.presence === 'external'}
          />
          <ActionListItem
            data-test='presenceNoticeActionItem'
            title={i18n('presence_notice')}
            subtitle={i18n('presence_notice_tip')}
            icon={'notifications_none'}
            iconLib={'material'}
            onClick={() => {
              props.onChanged('notice');
            }}
            selected={props.presence === 'notice'}
          />
        </ActionList>
      </ContentBox>
    </StyledContainer>
  );
};

export default PresencePage;
