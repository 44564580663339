import { Helmet } from 'react-helmet';

export const ParadigmaHead = () => {
    return (
      <Helmet>
        <title>Paradigma</title>
        <link rel='manifest' href='./manifest_paradigmasoft.json'></link>
        <link rel='icon' type='image/x-icon' href='./paradigma-apple-touch-icon.png'></link>
      </Helmet>
    );
};
  
export const TulkaHead = () => {
    return (
      <Helmet>
        <title>Túlka Web App</title>
        <link rel="shortcut icon" href="./favicon.ico" type="image/x-icon" />
        <link rel="icon" href="./favicon.ico" type="image/x-icon" />
        <link rel="apple-touch-icon" href="./apple-touch-icon.png" />
        <link rel="manifest" href="./manifest.json" />
        <meta name="apple-mobile-web-app-title" content="Túlka Web App for iOS" />
      </Helmet>
    );
};
  
export const TulkaRegistrationHead = () => {
    return (
      <Helmet>
        <title>Túlka Web App</title>
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>
    );
};