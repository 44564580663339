import { useContext, useState, useEffect } from 'react';
import ThemeContext from '../contexts/ThemeContext';
import i18n, { langCode } from '../i18n';
import styled from 'styled-components';

import {
  getLanduageWithCodeList
} from '../services/languageService';

const StyledGuideWrapper = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
`;

const StyledText = styled.p`
    color: ${({ color }) => (color)};
    margin: 0;
    margin-bottom: 10px;
`;

const StyledTitle = styled.h2`
    color: ${({ color }) => (color)};
    font-size: 28px;
    margin: 0;
    margin-bottom: 10px;
`;

const StyledSubTitle = styled.h3`
    color: ${({ color }) => (color)};
    margin: 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
`;

const StyledMainNumber = styled.p`
    color: ${({ color }) => (color)};
    font-size: 34px;
    line-height: 1.5;
    font-weight: 600;
    padding: 0;
    margin: 0;
`;

const StyledSecondaryNumber = styled.p`
    color: ${({ color }) => (color)};
    font-size: 18px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
`;

const StyledAccentText = styled.span`
    color: ${({ color }) => (color)};
`;

const ListWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 230px;
`;

const StyledLangsList = styled.ul`
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
  overflow-y: hidden;
  overflow-x: hidden;
  li {
    list-style: none;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    color: ${({ color }) => (color)};
    span:first-child {
      margin-right: 10px;
      font-weight: bold;
    }
  }
`;

export const CustomerGuideForCall = () => {
    const { colors } = useContext(ThemeContext);
    const [languagesWithCode, setLanguagesWithCode] = useState(null);

    const localeMap = {
      'en': 'eng',
      'fi': 'fin'
    };

    useEffect(() => {
      const setLangsWithCode = async () => {
        const langsList = await getLanduageWithCodeList(localeMap[langCode]);
        setLanguagesWithCode(langsList);
      };
      setLangsWithCode();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <StyledGuideWrapper>
            <StyledTitle color={colors.defaultGuideColor}>{i18n('order_an_interpreter_by_calling_the_number')}</StyledTitle>
            <StyledMainNumber color={colors.accentGuideColor}>{i18n('main_calling_number')}</StyledMainNumber>
            <StyledText color={colors.defaultGuideColor}>{i18n('phone_must_be_registered')}</StyledText>
            <StyledText color={colors.defaultGuideColor}><StyledAccentText color={colors.accentGuideColor}>{i18n('note')}</StyledAccentText>{i18n('number_is_encrypted')}</StyledText>
            <StyledSecondaryNumber color={colors.accentGuideColor}>{i18n('main_calling_number_with_code')}</StyledSecondaryNumber>
            <StyledText color={colors.defaultGuideColor}><StyledAccentText color={colors.accentGuideColor}>{i18n('attention_for_hus')}</StyledAccentText>{i18n('if_neither_number_works')}</StyledText>
            <StyledSecondaryNumber color={colors.accentGuideColor}>{i18n('main_calling_number_with_zero')}</StyledSecondaryNumber>
            <StyledText color={colors.defaultGuideColor}>{i18n('contact_to_customer_service')}</StyledText>
            <StyledSubTitle color={colors.defaultGuideColor}>{i18n('select_interpreter_language')}</StyledSubTitle>
            <ListWrapper>
            <StyledLangsList color={colors.defaultGuideColor}>
                {languagesWithCode?.map(lang => {
                    return (
                        <li key={lang.id}>
                            <span>{lang.number}</span>
                            <span>{lang.localisation}</span>
                        </li>
                    );
                })}
            </StyledLangsList>
            </ListWrapper>
        </StyledGuideWrapper>
    );
};