import requestService from './requestService';

const endpoints = {
  orders: '/orders',
  updateOrder: '/chatroom/setChatroom',
};

export const getOrders = async() => {
  try {
    return requestService.get(`${endpoints.orders}`, 'nonConfirmed=true', {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const confirmOndemand = async(prebookingId, userGroupId, invoiceRef, invoiceRecipientRef, customGroupValues) => {
  const body =
    (prebookingId ? 'chatRoomId=' + encodeURIComponent(prebookingId) + '&' : '') +
    (userGroupId ? 'invoiceUserGroup.id=' + encodeURIComponent(userGroupId) + '&' : '') +
    (invoiceRef ? 'invoiceRef=' + encodeURIComponent(invoiceRef) + '&' : '') +
    (invoiceRecipientRef ? 'invoiceRecipientRef=' + encodeURIComponent(invoiceRecipientRef) + '&' : '') +
    (customGroupValues ? 'customFields=' + JSON.stringify(customGroupValues) + '&' : '') +
    'confirm=true';

  try {
    return requestService.post(`${endpoints.updateOrder}`, null, body, {
      apiType: 'platform-v2',
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};
