import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ContentBox from './ContentBox';
import ActionList from './ActionList';
import ActionListItem from './ActionListItem';
import ChatWizardHeader from './ChatWizardHeader';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SkillPage = props => (
  <StyledContainer>
    <ChatWizardHeader
      title={props.title}
      helpId={props.helpId}
    />
    <ContentBox>
      <ActionList>
        <ActionListItem
          data-test={props.anyTitle.replace(/\s/g, '') + 'SkillChatWizard'}
          title={props.anyTitle}
          icon={props.anyIcon}
          iconLib={props.anyIconLib}
          onClick={() => props.onSelected(null)}
          selected={props.selectedSkill === null}
        />
        {(props.selectableSkills && props.selectableSkills.length > 0) ? props.selectableSkills.map(skill => (
          <ActionListItem
            data-test={skill.tag + 'SkillChatWizard'}
            key={skill.id}
            title={skill.name}
            icon={skill.icon}
            iconLib={skill.iconLib}
            onClick={() => props.onSelected(skill)}
            selected={props.selectedSkill && props.selectedSkill.id === skill.id}
          />
        )) : null}
      </ActionList>
    </ContentBox>
  </StyledContainer>
);

SkillPage.propTypes = {
  selectableSkills: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  selectedSkill: PropTypes.object,
  anyIconLib: PropTypes.string,
  anyIcon: PropTypes.string,
  anyTitle: PropTypes.string,
  title: PropTypes.string,
  iconLib: PropTypes.string,
  icon: PropTypes.string
};

export default SkillPage;
