import React from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import Header from '../components/header';
import PropTypes from 'prop-types';
import amplitude from 'amplitude-js';

import AppContext from '../contexts/AppContext';
import FunctionsContext from '../contexts/FunctionsContext';

import {
  CenteredBlockContainer,
  DialogBlock,
} from '../components/basicComponents';

const StyledCenteredBlockContainer = styled(CenteredBlockContainer)`
  height: 100%;
  min-height: 100%;
  background-color: #46446F;
  overflow: hidden;
  overflow-y: auto;
`;

const StyledDialogBlock = styled(DialogBlock)`
  box-shadow: none;
`;

class Statistics extends React.Component {
  constructor(props) {
    super(props);

    const stats = localStorage.getItem('statistics');

    this.state = {
      stats: stats ? JSON.parse(stats) : null
    };
  }

  componentDidMount() {
    amplitude.getInstance().logEvent('Statistics');
    this.props.functionsContext.postToWorker('sendReady');
  }

  render() {
    const user = this.props.appContext.user;

    const stats = this.state.stats ? (
      <table className='statistics'>
        <tbody>
          <tr className='firstRow'>
            <td className='textCol'>{i18n('stats_today')}</td>
            <td className='numCol'>{this.state.stats.today}</td>
          </tr>
          <tr>
            <td className='textCol'>{i18n('stats_yesterday')}</td>
            <td className='numCol'>{this.state.stats.yesterday}</td>
          </tr>
          <tr className='firstRow'>
            <td className='textCol'>{i18n('stats_this_week')}</td>
            <td className='numCol'>{this.state.stats.thisWeek}</td>
          </tr>
          <tr>
            <td className='textCol'>{i18n('stats_last_week')}</td>
            <td className='numCol'>{this.state.stats.lastWeek}</td>
          </tr>
          <tr className='firstRow'>
            <td className='textCol'>{i18n('stats_this_month')}</td>
            <td className='numCol'>{this.state.stats.thisMonth}</td>
          </tr>
          <tr>
            <td className='textCol'>{i18n('stats_last_month')}</td>
            <td className='numCol'>{this.state.stats.lastMonth}</td>
          </tr>
          <tr className='firstRow'>
            <td className='textCol'>{i18n('stats_this_year')}</td>
            <td className='numCol'>{this.state.stats.thisYear}</td>
          </tr>
          <tr>
            <td className='textCol'>{i18n('stats_last_year')}</td>
            <td className='numCol'>{this.state.stats.lastYear}</td>
          </tr>
        </tbody>
      </table>
    ): (
      <h2 className='noStatistics'>{i18n('no_statistics')}</h2>
    );

    return (
      <StyledCenteredBlockContainer>
        <StyledDialogBlock>
          <Header user={this.props.appContext.user} noButtons={true} onBack={this.props.functionsContext.onBack} />
          <div className='dialog'>
            <div id='statisticsUsername'>{user.name}</div>
            <div id='statisticsPhone'>{user.phoneNumber}</div>

            {stats}

            <button className='action showRatings' onClick={this.props.functionsContext.showFeedbackList}>{i18n('show_feedbacks')}</button>
            <div id='statsDisclaimer'>{i18n('stats_disclaimer')}</div>
          </div>
        </StyledDialogBlock>
      </StyledCenteredBlockContainer>
    );
  }
}

const ContextWrapper = ({ ...props }) => (
  <FunctionsContext.Consumer>
    {functionsContext => (
      <AppContext.Consumer>
        {appContext => (
          <Statistics appContext={appContext} functionsContext={functionsContext} {...props} />
        )}
      </AppContext.Consumer>
    )}
  </FunctionsContext.Consumer>
);

export default ContextWrapper;

Statistics.propTypes = {
  appContext: PropTypes.object.isRequired,
  functionsContext: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};
