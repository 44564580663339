import React, { useContext, useState, useEffect, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import styled from 'styled-components';
import Moment from 'moment';
import Header from 'react-big-calendar/lib/Header';
import DateHeader from 'react-big-calendar/lib/DateHeader';
import { extendMoment } from 'moment-range';
import Modal from 'react-modal';
import { showSuccess, showError } from '../services/toastService';
import amplitude from 'amplitude-js';

Modal.setAppElement('#main');

import 'react-big-calendar/lib/css/react-big-calendar.css';

import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import ConfirmationDialog from '../components/ConfirmationDialog';
import ModalDialogCloseButton from './ModalDialogCloseButton';
import NewEventForm from './NewEventForm';
import CalendarEvent from './CalendarEvent';
import Spinner from './Spinner';

import {
  blockInterpreterTime,
  unBlockInterpreterTime,
} from '../services/interpreterBlockTimeService';

import i18n, { langCode } from '../i18n';
import hexToRgbA from '../utils/hexToRgbA';
import { parseNoticeMessage } from '../utils/prebooksHelpers';

const moment = extendMoment(Moment);

const localizer = momentLocalizer(moment);

const StyledHeader = styled.div`
  padding: 8px;
  font-size: ${({ fontSize }) => (fontSize)}px;
  color: ${({ textColor }) => (textColor)};
`;

const StyledDateHeader = styled.div`
  padding: 5px;
  & > a {
    font-size: ${({ fontSize }) => (fontSize)}px;
    color: ${({ textColor }) => (textColor)};
    transition: ${({ transition }) => (transition)};
    &:hover {
      color: ${({ hoverTextColor }) => (hoverTextColor)};
    }
  }
`;

const WrappedHeader = (props) => {
  const { colors, layout } = useContext(ThemeContext);

  return (
    <StyledHeader
      fontSize={layout.fontSize}
      textColor={colors.webDarkBlue}
    >
      <Header {...props}/>
    </StyledHeader>
  );
};

const WrappedDateHeader = (props) => {
  const { colors, layout } = useContext(ThemeContext);

  return (
    <StyledDateHeader
      fontSize={layout.mediumFontSize}
      textColor={colors.webGray}
      hoverTextColor={colors.webDarkBlue}
      transition={layout.transition}
    >
      <DateHeader {...props}/>
    </StyledDateHeader>
  );
};

const StyledCalendarWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  .rbc-toolbar {
    margin: 0;
    padding: 0 0 15px;
  }
  .rbc-btn-group,
  .rbc-toolbar-label {
    height: 50px;
    display: flex;
    flex: 1;
    align-items: center;
    flex-grow: 1;
  }
  .rbc-btn-group:first-child {
    justify-content: flex-start;
  }
  .rbc-btn-group:last-child {
    justify-content: flex-end;
  }
  .rbc-toolbar-label {
    font-size: ${({ labelFontSize }) => (labelFontSize)}px;
    color: ${({ labelTextColor }) => (labelTextColor)};
    margin: 10px 0 15px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  .rbc-btn-group button {
    height: 50px;
    background-color: rgba(228, 228, 255, 0.5);
    border-radius: 0;
    border-width: 0;
    font-size: ${({ buttonFontSize }) => (buttonFontSize)}px;
    color: ${({ buttonTextColor }) => (buttonTextColor)};
    transition: ${({ transition }) => (transition)};
    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    &.rbc-active, &:active, &:hover {
      box-shadow: none;
      color: rgb(255, 255, 255);
      background-color: rgb(58, 56, 91);
    }
  }
  .rbc-row.rbc-month-header,
  .rbc-time-header,
  .rbc-time-content,
  .rbc-today,
  .rbc-agenda-table {
    background-color: white;
  }
  .rbc-time-content {
    margin-top: -2px;
    margin-bottom: -1px;
  }
  .rbc-row .rbc-row-segment {
    padding: 0px 0px 1px 1px;
  }
  .rbc-month-row {
    overflow: auto;
    height: auto;
    flex-basis: initial;
    flex: 1 0 auto;
    .rbc-row-bg {
      cursor: pointer;
      background-color: white;
      .rbc-day-bg.rbc-off-range-bg {
        background-color: rgb(245, 248, 251);
      }
      .rbc-day-bg.rbc-today {
        background-color: rgba(228,228,255,0.5);
      }
    }
    .rbc-row-content {
      cursor: pointer;
    }
  }
  .rbc-event {
    outline: none;
    border-radius: 6px;
    background-color: ${({ eventBackgroundColor }) => (eventBackgroundColor)};
    border-color: ${({ eventBackgroundColor }) => (eventBackgroundColor)};
    &.unavailability {
      background-color: ${({ unavailabilityEventBackgroundColor }) => (hexToRgbA(unavailabilityEventBackgroundColor, 0.6))};
      border-color: ${({ unavailabilityEventBackgroundColor }) => (hexToRgbA(unavailabilityEventBackgroundColor, 0.6))};
    }
    &.reservations_od {
      background-color: ${({ reservationsForODEventBackgroundColor }) => (hexToRgbA(reservationsForODEventBackgroundColor, 0.6))};
      border-color: ${({ reservationsForODEventBackgroundColor }) => (hexToRgbA(reservationsForODEventBackgroundColor, 0.6))};
    }
    &.previous_prebooking {
      background-color: ${({ previousPrebookingEventBackgroundColor }) => (hexToRgbA(previousPrebookingEventBackgroundColor, 0.6))};
      border-color: ${({ previousPrebookingEventBackgroundColor }) => (hexToRgbA(previousPrebookingEventBackgroundColor, 0.6))};
    }
    &.previous {
      opacity: 0.7;
    }
  }
  .rbc-month-view {
    overflow: auto;
    border-radius: 6px;
  }
  .rbc-time-view {
    border-radius: 6px;
  }
  .rbc-day-slot .rbc-time-slot {
    border-top: 0;
  }
  .rbc-agenda-view {
    border-radius: 6px;
    border: 1px solid #DDD;
    flex: initial;
  }
  .rbc-agenda-view table.rbc-agenda-table {
    border: 1px solid transparent;
  }
  .rbc-time-view {
    overflow: hidden;
  }
  .rbc-month-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .rbc-month-row:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .rbc-row-bg {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .rbc-timeslot-group {
    ${({ minimalDurationTime }) => (`min-height: ${Math.max((220 - Math.min(minimalDurationTime, 245)), 25)}px;`)}
  }
  .rbc-day-slot.rbc-time-column {
    flex: 1 0;
  }
  .rbc-events-container {
    margin-right: 0;
  }
  .rbc-allday-cell {
    display: none;
  }
  .rbc-time-header-cell .rbc-header {
    border-bottom: none;
  }
  .rbc-event-label {
    color: ${({ eventTextColor }) => (eventTextColor)};
  }
  .rbc-time-header.rbc-overflowing {
    border-right: none;
  }
  .rbc-agenda-table .rbc-header {
    padding: 8px;
    font-size: ${({ tableHeadeFontSize }) => (tableHeadeFontSize)}px;
    color: ${({ tableHeadeTextColor }) => (tableHeadeTextColor)};
  }
  .rbc-header > a > div > span {
    color: ${({ linkHeaderColor }) => (linkHeaderColor)};
    transition: ${({ transition }) => (transition)};
    &:hover {
      color: ${({ hoverLinkHeaderColor }) => (hoverLinkHeaderColor)};
    }
  }
  .rbc-event {
    transition: ${({ transition }) => (transition)};
    &:hover {
      background-color: #1F1F3F;
    }
    &.unavailability:hover {
      background-color: ${({ unavailabilityEventBackgroundColor }) => (hexToRgbA(unavailabilityEventBackgroundColor, 0.8))};
    }
    &.reservations_od:hover {
      background-color: ${({ reservationsForODEventBackgroundColor }) => (hexToRgbA(reservationsForODEventBackgroundColor, 0.8))};
    }
    &.previous_prebooking:hover {
      background-color: ${({ previousPrebookingEventBackgroundColor }) => (hexToRgbA(previousPrebookingEventBackgroundColor, 0.8))};
    }
  }
  .rbc-agenda-event-cell {
    cursor: pointer;
    transition: ${({ transition }) => (transition)};
    &:hover {
      background-color: rgba(228,228,255,0.5);
    }
  }
  .rbc-show-more {
    color: ${({ linkHeaderColor }) => (linkHeaderColor)};
    &:hover {
      color: ${({ hoverLinkHeaderColor }) => (hoverLinkHeaderColor)};
    }
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td.rbc-agenda-time-cell {
    border-left: 1px solid #DDD;
  }
`;

const sortByTimeAsc = function(lhs, rhs) {
  let results = lhs.hours() > rhs.hours() ? 1 : lhs.hours() < rhs.hours() ? -1 : 0;

  if (results === 0) {
    results = lhs.minutes() > rhs.minutes() ? 1 : lhs.minutes() < rhs.minutes() ? -1 : 0;
  }
  if (results === 0) {
    results = lhs.seconds() > rhs.seconds() ? 1 : lhs.seconds() < rhs.seconds() ? -1 : 0;
  }

  return results;
};

const InterpreterCalendar = ({ interpreterId, prebooks, unavailability, openPreview, readOnly, handleNavigation }) => { // active
  moment.locale(langCode);

  const [indicator, setIndicator] = useState(false);
  const [interpreter, setInterpreter] = useState(interpreterId);
  const [confirmationDialog, setConfirmationDialog] = useState('');
  const [addingNewEventIsOpen, setAddingNewEventIsOpen] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [unavailabilityEvent, setUnavailabilityEvent] = useState({
    startsAt: null,
    endsAt: null,
  });
  const { colors, layout } = useContext(ThemeContext);
  const {
    setBusy,
    sessionExpired
  } = useContext(FunctionsContext);

  const modalDialogStyles = {
    overlay: {
      zIndex: 999,
      backgroundColor: colors.webOverlay
    },
    content: {
      width: '100%',
      maxWidth: '660px',
      height: '100%',
      maxHeight: '750px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      borderRadius: '15px',
      border: 'none',
      overflow: 'initial',
      display: 'flex'
    }
  };

  const _isMounted = useRef(true); // Initial value _isMounted = true

  useEffect(() => {
    return () => { // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, []);

  const [prebookings, setPrebookings] = useState([]);
  const [unavailabilityForRemove, setUnavailabilityForRemove] = useState(null);
  const [unavailabilityArray, setUnavailabilityArray] = useState([]);
  const [currentDateRange, setCurrentDateRange] = useState([
    moment().startOf('month').toDate(),
    moment().endOf('month').toDate()
  ]);
  const [minimalTime, setMinimalTime] = useState(new Date());
  const [minimalDurationTime, setMinimalDurationTime] = useState(30);

  useEffect(() => {
    const preparedPrebooks = prebooks.map((prebook) => {
      if (prebook.presence === 'notice') {
        const parsedNoticeMessage = parseNoticeMessage(prebook.messageToInterpreter);

        return {
          ...prebook,
          alternativeContactPerson: parsedNoticeMessage.alternativeContactPerson,
          mainPhoneNumber: parsedNoticeMessage.phoneNumbers[0],
          phoneNumbers: parsedNoticeMessage.phoneNumbers?.slice(1)?.join(' '),
          contentData: parsedNoticeMessage.contentData,
        };
      }
      return prebook;
    });
    setPrebookings(preparedPrebooks);
  }, [prebooks]);

  useEffect(() => {
    setInterpreter(interpreterId);
  }, [interpreterId]);

  useEffect(() => {
    setUnavailabilityArray(unavailability);
  }, [unavailability]);

  useEffect(() => {
    const range = moment().range(currentDateRange[0], currentDateRange[1]);

    const prebooksInCurrentRange = prebookings.filter((prebooking) => {
      return range.contains(moment(new Date(prebooking.interpretationDate)).toDate());
    });
    const unavailabilityInCurrentRange = unavailability.filter((unavailabilityItem) => {
      const itemRange = moment().range(unavailabilityItem.startsAt, unavailabilityItem.endsAt);
      return range.overlaps(itemRange);
    });

    const durations = [
      ...prebooksInCurrentRange.map((prebooking) => prebooking.durationEstimated),
      ...unavailabilityInCurrentRange.map((unavailabilityItem) => {
        const diff = moment(unavailabilityItem.endsAt).diff(moment(unavailabilityItem.startsAt));
        return moment.duration(diff).asSeconds();
      }),
    ];

    const times = [
      ...prebooksInCurrentRange.map((prebooking) => prebooking.interpretationDate),
      ...unavailabilityInCurrentRange.map((unavailabilityItem) => unavailabilityItem.startsAt),
    ];

    setMinimalDurationTime(Math.min(...durations) / 60);
    const sortedByTimes = times.sort((a, b) => {
      return sortByTimeAsc(moment(a), moment(b));
    });
    setMinimalTime(new Date(sortedByTimes[0]));
  }, [prebookings, unavailability, currentDateRange]);

  const data = React.useMemo(() => {
    return [...prebookings.map((booking) => {
      return {
        start: moment(new Date(booking.interpretationDate)).toDate(),
        end: moment(new Date(booking.interpretationDate + (booking.durationEstimated * 1000))).toDate(),
        title: booking.interpretedLanguage.name,
        prebook: booking,
        type: moment(booking.interpretationDate).isAfter(moment()) ? 'prebook': 'previous_prebooking',
      };
    }), ...unavailabilityArray.map((unavailabilityItem) => {
      return {
        id: unavailabilityItem.id,
        start: moment(new Date(unavailabilityItem.startsAt)).toDate(),
        end: moment(new Date(unavailabilityItem.endsAt)).toDate(),
        title: i18n('unavailable_time'),
        type: unavailabilityItem.type === 'prebooking' ? 'reservations_od' : 'unavailability',
        previous: moment(new Date(unavailabilityItem.endsAt)).isBefore(moment())
      };
    })];
  }, [ prebookings, unavailabilityArray ]);

  localizer.startOfWeek = () => (1);

  const isTimeOverlaping = (startTime, endTime, id) => {
    const range = moment().range(startTime, endTime);
    const overlapingItems = data.filter((calendarItem) => {
      const itemRange = moment().range(calendarItem.start, calendarItem.end);
      return !(id && id === calendarItem.id) && itemRange.overlaps(range);
    }, []);

    return overlapingItems.length > 0;
  };

  const processBlockInterpreterTime = async (startTime, endTime, type) => {
    try {
      setIndicator(true);
      const result = await blockInterpreterTime(startTime, endTime, type, interpreter);
      setUnavailabilityArray([...unavailabilityArray, {
        startsAt: result.startsAt,
        endsAt: result.endsAt,
        id: result._id,
        type
      }]);

      amplitude.getInstance().logEvent('Intepreter time blocked');
      showSuccess(i18n('time_successfuly_blocked'));
      setAddingNewEventIsOpen(false);
      setBusy(false);
      setIndicator(false);
    } catch (e) {
      console.log('Failed to block interpreter time. Reason: ', e);

      amplitude.getInstance().logEvent('Intepreter time block failed', {
        'error': e.message
      });

      if (e.message === 'Session expired') {
        sessionExpired();
      } else {
        amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
        showError(i18n('error500'));
      }
    }
  };

  const processUnBlockInterpreterTime = async (id) => {
    try {
      setIndicator(true);
      await unBlockInterpreterTime(id, interpreter);
      setUnavailabilityArray([...unavailabilityArray.filter((item) => {
        return item.id !== id;
      })]);

      amplitude.getInstance().logEvent('Intepreter blocked time canceled');
      showSuccess(i18n('time_successfuly_unblocked'));
      setAddingNewEventIsOpen(false);
      setBusy(false);
      setIndicator(false);
    } catch (e) {
      console.log('Failed to cancel blocked interpreter time. Reason: ', e);

      amplitude.getInstance().logEvent('Cancel intepreter blocked time failed', {
        'error': e.message
      });

      if (e.message === 'Session expired') {
        sessionExpired();
      } else {
        amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
        showError(i18n('error500'));
      }
    }
  };

  const updateBlockedIntepreterTime = async (id, startTime, endTime, type) => {
    try {
      setIndicator(true);
      await unBlockInterpreterTime(id, interpreter);
      const result = await blockInterpreterTime(startTime, endTime, type, interpreter);
      setUnavailabilityArray([...unavailabilityArray.filter((item) => {
        return item.id !== id;
      }), {
        startsAt: result.startsAt,
        endsAt: result.endsAt,
        id: result._id,
        type
      }]);

      amplitude.getInstance().logEvent('Intepreter blocked time updated');
      showSuccess(i18n('time_successfuly_updated'));
      setAddingNewEventIsOpen(false);
      setBusy(false);
      setIndicator(false);
    } catch (e) {
      console.log('Failed to update blocked interpreter time. Reason: ', e);

      amplitude.getInstance().logEvent('Update intepreter blocked time failed', {
        'error': e.message
      });

      if (e.message === 'Session expired') {
        sessionExpired();
      } else {
        amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
        showError(i18n('error500'));
      }
    }
  };

  useEffect(() => {
    const func = () => {
      processUnBlockInterpreterTime(unavailabilityForRemove);
      setUnavailabilityForRemove(null);
    };
    document.addEventListener('unBlockInterpreterTime', func);
    return () => {
      document.removeEventListener('unBlockInterpreterTime', func);
    };
  }, [unavailabilityForRemove]); // eslint-disable-line react-hooks/exhaustive-deps

  const [overlapingModal] = useState({
    message: i18n('overlaping_information_dialog_mesage'),
    buttons: [{
      label: i18n('ok'),
      onClick: () => closeConfirmationModal(false, 'overlapingModal'),
      warning: false,
      primary: false,
    }],
    close: () => {
      closeNewEventForm();
    }
  });

  const [cancelConfirmationModal] = useState({
    message: i18n('cancel_blocked_time_confirmation_message'),
    buttons: [],
    close: () => {
      document.dispatchEvent(new CustomEvent('unBlockInterpreterTime'));
    },
    notConfirmed: () => {
      setUnavailabilityForRemove(null);
    }
  });

  const confimationSettings = {
    overlapingModal: overlapingModal,
    cancelConfirmationModal: cancelConfirmationModal,
  };

  const openUnavailabilityDialog = (event) => {
    setBusy(true);
    setUnavailabilityEvent(event);
    setAddingNewEventIsOpen(true);
  };

  const closeNewEventForm = () => {
    setUnavailabilityEvent({
      startsAt: null,
      endsAt: null,
    });
    setAddingNewEventIsOpen(false);
    setBusy(false);
  };

  const openConfirmationModal = (name) => {
    setConfirmationDialog(name);
    setConfirmationDialogIsOpen(true);
  };

  const closeConfirmationModal = (confirmed, dialogName) => {
    if (confirmed) {
      confimationSettings[confirmationDialog || dialogName].close();
    } else if (!confirmed && confimationSettings[confirmationDialog || dialogName].notConfirmed) {
      confimationSettings[confirmationDialog || dialogName].notConfirmed();
    }
    setConfirmationDialogIsOpen(false);
    setConfirmationDialog('');
  };

  return (
    <StyledCalendarWrapper
      transition={layout.transition}
      eventTextColor={colors.webTextColor}
      eventBackgroundColor={colors.containerColor}
      unavailabilityEventBackgroundColor={colors.error}
      reservationsForODEventBackgroundColor={colors.warn}
      previousPrebookingEventBackgroundColor={colors.webGray}
      tableHeadeFontSize={layout.fontSize}
      tableHeadeTextColor={colors.webDarkBlue}
      linkHeaderColor={colors.webGray}
      hoverLinkHeaderColor={colors.webDarkBlue}
      minimalDurationTime={minimalDurationTime}
    >
      <Modal
        isOpen={confirmationDialogIsOpen}
        style={{
          ...modalDialogStyles,
          overlay: {
            backgroundColor: 'transparent',
            zIndex: 9999
          }
        }}
      >
        <ConfirmationDialog
          buttons={confimationSettings[confirmationDialog] ? confimationSettings[confirmationDialog].buttons : []}
          message={confimationSettings[confirmationDialog] ? confimationSettings[confirmationDialog].message : ''}
          closeModal={closeConfirmationModal}
        />
      </Modal>
      <Modal
        isOpen={addingNewEventIsOpen}
        style={modalDialogStyles}
      >
        <ModalDialogCloseButton
          onClick={closeNewEventForm}
        />
        <NewEventForm
          event={unavailabilityEvent}
          blockInterpreterTime={(startTime, endTime, type) => {
            const isTimeOverlaped = isTimeOverlaping(startTime, endTime);
            if (isTimeOverlaped) {
              openConfirmationModal('overlapingModal');
              // TODO: Need to add additional functionality
            } else {
              processBlockInterpreterTime(startTime, endTime, type);
            }
          }}
          unBlockInterpreterTime={(id) => {
            setUnavailabilityForRemove(id);
            openConfirmationModal('cancelConfirmationModal');
          }}
          updateBlockedIntepreterTime={(id, startsAt, endsAt, type) => {
            const isTimeOverlaped = isTimeOverlaping(startsAt, endsAt, id);
            if (isTimeOverlaped) {
              openConfirmationModal('overlapingModal');
              // TODO: Need to add additional functionality
            } else {
              updateBlockedIntepreterTime(id, startsAt, endsAt, type);
            }
          }}
        />
        {
          indicator ? (
            <Spinner
              overlay
            />
          ) : null
        }
      </Modal>
      <Calendar
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={data}
        culture={langCode}
        scrollToTime={minimalTime}
        showMultiDayTimes={true}
        onNavigate={(newDate, view) => {
          if (view === 'month') {
            handleNavigation(moment(newDate).startOf(view).startOf('isoWeek'), moment(newDate).endOf(view).endOf('isoWeek'));
          } else if (view === 'week') {
            handleNavigation(moment(newDate).startOf('isoWeek'), moment(newDate).endOf('isoWeek'));
          } else {
            handleNavigation(moment(newDate).startOf(view), moment(newDate).endOf(view));
          }
        }}
        onRangeChange={(dateRange) => {
          if (dateRange.length === 7) {
            setCurrentDateRange([dateRange[0], dateRange[6]]);
          } else if (dateRange.length === 1) {
            setCurrentDateRange([
              moment(dateRange[0]).startOf('day').toDate(),
              moment(dateRange[0]).endOf('day').toDate()
            ]);
          }
          else {
            setCurrentDateRange([dateRange.start, dateRange.end]);
          }
        }}
        formats={{
          timeGutterFormat: (time) => moment(time).format('HH:mm'),
          agendaTimeFormat: (time) => `${moment(time).format('HH:mm')}`,
          agendaTimeRangeFormat: ({ start, end }) => `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`,
          agendaHeaderFormat: ({ start, end }) => `${moment(start).format('DD/MM/YYYY')} - ${moment(end).format('DD/MM/YYYY')}`,
          eventTimeRangeFormat: ({ start, end }) => `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`,
          eventTimeRangeStartFormat: ({ start }) => `${moment(start).format('HH:mm')} »`,
          eventTimeRangeEndFormat: ({ end }) => `« ${moment(end).format('HH:mm')}`,
        }}
        eventPropGetter={(event) => ({
          className: `${event.type}${event.previous? ' previous' : ''}`
        })}
        onSelectEvent={(event) => {
          if (event.type === 'prebook' || event.type ==='previous_prebooking') {
            openPreview(event.prebook);
          }
          if (event.type === 'unavailability' || event.type === 'reservations_od') {
            openUnavailabilityDialog({
              id: event.id,
              startsAt: event.start,
              endsAt: event.end,
              type: event.type,
            });
          }
        }}
        onSelectSlot={({ start, end }) => {
          if (!readOnly) {
            openUnavailabilityDialog({
              startsAt: start,
              endsAt: end,
            });
          }
        }}
        components={{
          agenda: {
            event: (eventData) => (
              <CalendarEvent
                simpleItem
                agendaItem
                title={eventData.event.title}
                start={eventData.event.start}
                end={eventData.event.end}
                type={eventData.event.type}
                booking={eventData.event.prebook}
              />
            )
          },
          day: {
            event: (eventData) => (
              <CalendarEvent
                simpleItem
                title={eventData.event.title}
                start={eventData.event.start}
                end={eventData.event.end}
                type={eventData.event.type}
                booking={eventData.event.prebook}
              />
            )
          },
          week: {
            header: WrappedHeader,
            event: (eventData) => (
              <CalendarEvent
                simpleItem
                title={eventData.event.title}
                start={eventData.event.start}
                end={eventData.event.end}
                type={eventData.event.type}
                booking={eventData.event.prebook}
              />
            )
          },
          month: {
            header: WrappedHeader,
            dateHeader: WrappedDateHeader,
            event: (eventData) => (
              <CalendarEvent
                noSpaceBettwen
                title={eventData.event.title}
                start={eventData.event.start}
                end={eventData.event.end}
                type={eventData.event.type}
                booking={eventData.event.prebook}
                slotStart={eventData.slotStart}
                slotEnd={eventData.slotEnd}
              />
            )
          }
        }}
        messages={{
          allDay: i18n('all_day'),
          previous: i18n('previous'),
          next: i18n('next'),
          today: i18n('today'),
          month: i18n('month'),
          week: i18n('week'),
          day: i18n('day'),
          agenda: i18n('agenda'),
          date: i18n('date'),
          time: i18n('time'),
          event: i18n('event'),
          noEventsInRange: i18n('no_events_in_range'),
          showMore: (count) => {
            return `${i18n('show_more')} ${count}`;
          },
        }}
      />
    </StyledCalendarWrapper>
  );
};

export default InterpreterCalendar;
