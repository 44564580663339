import moment from 'moment';
import i18n from '../i18n';

export const MIN_BEFORE_PREBOOKING_START = 300000;
const MAX_AFTER_PREBOOKING_START = 600000;

const isCurrentDate = (date, current) =>
  date.year() === current.year() &&
  date.month() === current.month() &&
  date.date() === current.date();

const updateDateString = (string, key, time) => (string = i18n(key) + ` ${time}`);

const setCurrentDate = ({ isNow, isToday, isTomorrow }, date, string) => {
  if (isNow) {
    return updateDateString(string, 'now', date.format('HH:mm'));
  }
  if (isToday) {
    return updateDateString(string, 'today', date.format('HH:mm'));
  }
  if (isTomorrow) {
    return updateDateString(string, 'tomorrow', date.format('HH:mm'));
  }
  return string;
};

export function setBookingDate(booking, checkDate ) {
  const date = moment(new Date(booking.interpretationDate));
  let dateString = date.format('DD MMMM HH:mm');

  const checkCurrentDate = {
    isNow:
      booking.interpretationDate - Date.now() <= booking.leewayBefore * 1000 &&
      booking.interpreter,
    isToday: checkDate?.today && isCurrentDate(date, checkDate.today),
    isTomorrow: checkDate?.tomorrow
      && isCurrentDate(date, checkDate.tomorrow),
  };

  return setCurrentDate(checkCurrentDate, date, dateString);
}

export function isGonePrebooking(prebooking) {
  return prebooking.interpretationDate + prebooking.durationEstimated * 1000 < Date.now();
}

export function isCancelledPrebooking(prebooking) {
  return !!prebooking.cancelledAt;
}

export function isTooLate(prebooking) {
  return prebooking.interpretationDate + MAX_AFTER_PREBOOKING_START < Date.now();
} 

export const isStartPrebook = (prebooking) => {
  return prebooking.interpretationDate < Date.now();
};