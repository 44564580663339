import React, { useContext } from 'react';
import styled from 'styled-components';

import ButtonPanel from './ButtonPanel';
import { RoundButton } from './Buttons';
import ContentBox from './ContentBox';

import i18n, { getIsRightToLeft } from '../i18n';

import ThemeContext from '../contexts/ThemeContext';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ padding }) => (padding * 3)}px;
`;

const StyledHeader = styled.span`
  line-height: ${({ fontSize }) => (fontSize + 4)}px;
  font-size: ${({ fontSize }) => (fontSize)}px;
  width: 100%;
  text-align: center;
`;

const ConfirmationDialog = ({ message, buttons, closeModal, fontSize }) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  return (
    <StyledContainer
      padding={layout.padding}
      colors={colors}
    >
      <ContentBox
        centerContent={true}
        overlapping={true}
      >
        <StyledHeader
          fontSize={fontSize || layout.bigHeaderFontSize}
        >
          {message}
        </StyledHeader>
      </ContentBox>
      <ButtonPanel rtl={getIsRightToLeft()}>
        {buttons && buttons.length > 0 ? (
          buttons.map(button => (
            <RoundButton
              fontSize={fontSize}
              data-test={button.label + 'Btn'}
              key={button.label}
              {...button}
              color={colors.tulkaMainColor}
              padding={layout.buttonPadding}
            />
          ))
        ) : (
          <>
            <RoundButton
              fontSize={fontSize}
              data-test='noConfirmBtn'
              label={i18n('no')}
              onClick={() => {
                closeModal(false);
              }}
              color={colors.tulkaMainColor}
              padding={layout.buttonPadding}
            />
            <RoundButton
              fontSize={fontSize}
              data-test='yesConfirmBtn'
              label={i18n('yes')}
              onClick={() => {
                closeModal(true);
              }}
              warning
              primary
              color={colors.tulkaMainColor}
              padding={layout.buttonPadding}
            />
          </>
        )}
      </ButtonPanel>
    </StyledContainer>
  );
};

export default ConfirmationDialog;
