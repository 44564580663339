import styled from 'styled-components';
import i18n from '../i18n';

import ChatWizardHeader from './ChatWizardHeader';
import { Input } from './Input';

const StyledContainer = styled.div`
  padding-top: 40px;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: 10px 40px;
`;

const MessageContainer = styled.div`
  margin-bottom: 10px;
`;

const PatientName = ({ patientName, onUpdatePatientName }) => {
  return (
    <StyledContainer>
      <ChatWizardHeader
        title={i18n('patient_name')}
        clonePrebook={false}
        helpId={''}
       />
       <ContentContainer>
         <MessageContainer>
           <Input
             data-test="inputPatientName"
             readOnly={false}
             value={patientName}
             onChange={onUpdatePatientName}
           />
         </MessageContainer>
       </ContentContainer>
    </StyledContainer>
  );
};

export default PatientName;
