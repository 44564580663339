import { VerificationPage } from '../components/ContactInfoStep';
import styled from 'styled-components';
import withHeader from '../hocs/withHeader';
import { FullBackgroundImageContainer } from '../components/basicComponents';
import background from '../images/background.png';

const ImageContainer = styled(FullBackgroundImageContainer)`
  padding: 21px;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  height: 80%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 8px 16px 8px rgba(21, 21, 41, 0.25);
  max-width: 600px;
  min-width: 288px;
  & > div {
    flex: 1;
    display: flex;
    overflow: hidden;
    width: 100%;
    & > div {
      flex: 1;
      display: flex;
      justify-content: flex-start;
    }
  }
`;

const SelfRegistrationVerificationPage = () => {
  return (
    <ImageContainer
      blendMode='transparent'
      backgroundImage={background}
    >
      <StyledWrapper>
        <VerificationPage />
      </StyledWrapper>
    </ImageContainer>
  );
};

export default withHeader(SelfRegistrationVerificationPage);