import React, { useContext, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ThemeContext from '../contexts/ThemeContext';

import Icon from './Icon';

const StyledDiv = styled.div``;

const StyledMenuItem = styled.div`
  font-size: 16px;
  margin: 12px;
  height: 60px;
  border-radius: 12px;
  color: #ffffff;
  background-color: ${({ active }) => active ? '#1F1F3F' : 'transparent'};
  padding: 0 16px;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  ${({ transition }) => transition ? `transition: ${transition}` : ''};
  &:hover {
    cursor: pointer;
    background-color: #1F1F3F;
  }
`;

const MenuItemText = styled.div`
  flex: 1 1 auto;
  display: flex;
  padding-left: 7px;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
`;

const MenuItemCount = styled.div`
  border-radius: 50px;
  background-color: ${({ color }) => (color)};
  color: white;
  padding: 4px;
  font-size: 12px;
  line-height: 16px;
  min-width: 24px;
  text-align: center;
`;

const MenuItem = ({ to, text, icon, font, count, currentPath, dataTest, ...rest }) => {
  const { colors, layout } = useContext(ThemeContext);
  const [activeLink] = useState(currentPath && currentPath === to);
  const LinkComp = to ? Link : StyledDiv;

  return useMemo(() => (
    <LinkComp to={to} data-test={dataTest}>
      <StyledMenuItem active={activeLink} transition={layout.transition} {...rest}>
        <Icon
          icon={icon}
          iconLib={font}
          iconColor={colors.menuColor}
          iconSize={24}
          hoverColor={colors.menuColor}
        />
        <MenuItemText>
          <span>{text}</span>
          {count > 0 ? (
            <MenuItemCount
              color={colors.errorColor}
            >
              {count}
            </MenuItemCount>
          ) : null}
        </MenuItemText>
      </StyledMenuItem>
    </LinkComp>
  ), [
    to,
    text,
    icon,
    font,
    count,
    colors,
    layout,
    activeLink,
    rest,
    dataTest
  ]);
};

export default MenuItem;
