const businessTypes = {
  b2b: '3384bf50-884a-4756-9046-b23c73f98fba',
  demo: 'e2956105-1d23-49a3-a493-2734bde559c5',
  devDemo: 'e3b3ce45-b0bb-44b2-aa52-f18fb5591011',
  tulkaPremium: '0fe37777-d2d6-4678-8d46-f203b7c882cb',
  tapani: 'fd2ad8b0-ba24-4031-8de0-afd0fd4bba3e',
  neuvoo: '9c18d9ef-7f26-4fd2-a31a-8932262eacc2'
};

const contractPartners = {
  tulka: 'fff17b1b-ff0d-4e93-92d0-a537afc419b8',
  monetra: '7327f9d4-bf31-469a-a250-552c88ca8e69'
};

const userGroups = {
  businessDemo: 'd008d8df-c201-43a8-8a5e-c98599ca2123'
};

const ids = {
  businessTypes,
  contractPartners,
  userGroups
};

export default ids;
