import React from 'react';
import PropTypes from 'prop-types';
import amplitude from 'amplitude-js';
import { opentok } from '../OpenTok';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faVideo } from '@fortawesome/free-solid-svg-icons';

export default class DeviceSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      audioDevices: [],
      videoDevices: [],
      selectedAudioDevice: localStorage.getItem('selectedAudioDevice') || '',
      selectedVideoDevice: localStorage.getItem('selectedVideoDevice') || ''
    };
  }

  componentDidMount() {
    this.enumDevices();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showSettings !== this.props.showSettings && this.state.audioDevices.length === 0) {
      this.enumDevices();
    }
  }

  async enumDevices() {
    try {
      const devices = await opentok.enumDevices();
      this.setState(devices);
      if (!this.state.selectedAudioDevice && devices.audioDevices.length > 0) {
        this.setState({
          selectedAudioDevice: devices.audioDevices[0].deviceId
        });
        localStorage.setItem('selectedAudioDevice', devices.audioDevices[0].deviceId);
      }

      if (!this.state.selectedVideoDevice && devices.videoDevices.length > 0 && devices.videoDevices[0].label && devices.videoDevices[0].deviceId) {
        this.setState({
          selectedVideoDevice: devices.videoDevices[0].deviceId
        });
        localStorage.setItem('selectedVideoDevice', devices.videoDevices[0].deviceId);
      }

      if (this.state.selectedVideoDevice && (devices.videoDevices.length === 0 || !devices.videoDevices[0].label || !devices.videoDevices[0].deviceId)) {
        localStorage.removeItem('selectedVideoDevice');
        this.setState({
          selectedVideoDevice: ''
        });
      }

      amplitude.getInstance().logEvent('Devices enumerated', {
        'video devices': devices.videoDevices.length,
        'audio devices': devices.audioDevices.length
      });
    } catch (e) {
      console.error(e);
      amplitude.getInstance().logEvent('Failed to enum devices');
    }
  }

  onAudioDeviceChanged(id) {
    console.log('onAudioDeviceChanged: ', id);

    this.setState({selectedAudioDevice: id});
    localStorage.setItem('selectedAudioDevice', id);

    amplitude.getInstance().logEvent('Audio device changed');
  }

  onVideoDeviceChanged(id) {
    this.setState({selectedVideoDevice: id});
    localStorage.setItem('selectedVideoDevice', id);

    amplitude.getInstance().logEvent('Video device changed');
  }

  render() {
    return (
      <div id='devices'>
        <AudioDevices devices={this.state.audioDevices} onChange={e => this.onAudioDeviceChanged(e.target.value) } value={this.state.selectedAudioDevice} visible={this.props.showSettings} />
        <VideoDevices devices={this.state.videoDevices} onChange={e => this.onVideoDeviceChanged(e.target.value) } value={this.state.selectedVideoDevice} visible={this.props.showSettings} />
      </div>
    );
  }
}

const AudioDevices = props => {
  if (props.visible === false) {
    return null;
  }

  return (
    <div id='audioDevices'>
      <FontAwesomeIcon icon={faMicrophone} size='2x' color='white' />
      <select className='select' onChange={props.onChange} value={props.value}>
        {props.devices.map(device => (<option key={'adev_' + device.deviceId} value={device.deviceId}>{device.label}</option>))}
      </select>
    </div>
  );
};

AudioDevices.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  devices: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired
};

const VideoDevices = props => {
  if (props.visible === false) {
    return null;
  }

  return (
    <div id='videoDevices'>
      <FontAwesomeIcon icon={faVideo} size='2x' color='white' />
      <select className='select' onChange={props.onChange} value={props.value}>
        {props.devices.map(device => (<option key={'vdev_' + device.deviceId} value={device.deviceId}>{device.label}</option>))}
      </select>
    </div>
  );
};

VideoDevices.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  devices: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired
};

DeviceSelector.propTypes = {
  showSettings: PropTypes.bool.isRequired
};
