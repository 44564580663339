import moment from 'moment'; //eslint-disable-line no-unused-vars
import 'moment/locale/fi';
import 'moment/locale/ru';
import 'moment/locale/uk';
import 'moment/locale/ar';
import 'moment/locale/ku';

import { isLuonaCustomer } from './utils/luonaSpecificData';

const translations = {
  fi: {
    // customer guide for od-call (modal)
    order_an_interpreter_by_calling_the_number: 'Tilaa tulkki soittamalla numeroon',
    main_calling_number: '041 400 1234',
    main_calling_number_with_code: '*31# 041 400 1234',
    main_calling_number_with_zero: '0041 400 1234',
    phone_must_be_registered: 'Puhelin, jolla soitat akuuttinumeroomme tulee olla rekisteröity järjestelmäämme.',
    note: 'Huom!',
    number_is_encrypted: ' Jos numerosi on salattu niin kirjoita palvelunumero seuraavassa muodossa:',
    attention_for_hus: 'Huomio HUSin lankaverkossa toimijat!',
    if_neither_number_works: ' Jos kumpikaan numero ei toimi, niin kirjoita palvelunumero seuraavassa muodossa:',
    contact_to_customer_service: 'Ongelmatapauksessa ota yhteyttä asiakaspalveluumme.',
    select_interpreter_language: 'Puhelun yhdistettyä valitse tulkattava kieli seuraavista syöttämällä numero puhelimen numeronäppäimillä',
    accept_incoming_prebookings: 'Hyväksy tai hylkää saapuneet ennalta varatut tulkkaukset',
    accept: 'Hyväksy',
    decline: 'Hylkää',
    accept_all: 'Hyväksy kaikki',
    date_and_time: 'Ajankohta',
    duration: 'Kesto',
    language: 'Kieli',
    presence: 'Tyyppi',
    prebookings_already_checked: 'Varaus on jo valittu',
    username_not_unique: 'Käyttäjätunnus ei ole yksilöllinen.',
    new_username_set: 'Uusi käyttäjätunnus asetettu: ',
    // LUONA signup
    luonaSignupForm: 'Kirjautumislomake',

    label_name: 'Etu- ja sukunimi',
    placeholder_name: 'Nimi latinalaisin kirjaimin',
    validate_name: 'Vähintään neljä kirjainta',

    label_phone: 'Puhelinnumero',
    placeholder_phone: '+3XXXXXXXXXXX',
    validate_phone: 'Puhelinnumero muodossa: +XXXXXXXXXXXX',

    label_email: 'Sähköpostiosoite',
    placeholder_email: 'user@mail.com',
    validate_email: 'Sähköpostiosoite muodossa: user@mail.com',

    label_city: 'Vain numeroita',
    placeholder_city: 'Kaupunki',
    validate_city: 'Kaupunki',
    selectOption: 'Vain latinalaisia kirjaimia',

    agreePrivacyPolicy: 'Hyväksyn ',
    privacyPolicy: 'tietosuojakäytännön',
    luonaSignupButton: 'Lähetä',
    requestErrorMessage: 'Jotain meni pieleen, yritä uudestaan!',
    new_password_success: 'Salasana vaihdettu',
    new_password_wrong: 'Väärä vanha salasana',
    requestErrorMessageUserNotFound: 'Anna rekisteröity puhelinnumero ja sähköpostiosoite.',
    requestErrorMessageUserExist:
      'Antamasi sähköpostiosoite tai puhelinnumero on jo käytössä, ole hyvä ja käytä toista.',
    slot_not_free_anymore: 'Valittu aikaväli ei valitettavasti ole enää käytettävissä',
    no_interpreter_in_prebooking: 'Varauksessa ei ole tulkkia.',
    back_to_telegram: 'Palaa Telegramiin',
    // LUONA
    loginTitle: 'Sisäänkirjautuminen',
    username: 'Käyttäjätunnus',
    password: 'Salasana',
    loginButton: 'Kirjaudu',
    loginFailed: 'Sisäänkirjautuminen epäonnistui.',
    sessionExpired: 'Istunto on vanhentunut. Ole hyvä ja kirjaudu uudelleen.',
    meeting_closed: 'Tapaaminen on päättynyt.',
    signin_code_block_message: 'Tämä sähköpostiosoite on jo käytössä. Lähetimme vahvistuskoodin osoitteeseen: [email]. Kirjoita vahvistuskoodi allaolevaan kenttään.',
    placeholder_signin_code: 'koodi',
    close_meeting_confirmation_message: 'Oletko varma että haluat poistua tapaamisesta? Tapaaminen päättyy tällöin kaikilta osapuolilta',
    internet_connection_lost: 'Verkkoyhteys katkesi',
    internet_connection_exist: 'Verkkoyhteys palautui',
    what_is_your_language: 'Hei, mitä kieltä ymmärrät?',
    what_you_need_help_with: 'Mihin tarvitset apua?',
    selectable_langs: 'Kieli',
    selectable_servises: 'Asiani koskee',
    selectable_time: 'Aika',
    selectable_interpreter: 'Tapaamisen kieli on englanti tai suomi. Tarvitseko tulkin?',
    authtorize: 'Kirjautumislomake',
    choose_time: 'Valitse varattava aika',
    prebook_not_available_message: 'Palvelu ei ole saatavilla tällä hetkellä. Palveluajat ovat ma-pe {SCHEDULE}.',
    //Luona services
    health: 'Terveyttä',
    work_or_study: 'Työtä tai opiskelua',
    money_or_livelihood: 'Rahaa tai toimeentuloa',
    reception_allowance: 'Vastaanottoraha tai PFS-korttia',
    my_money_is_not_enough: 'Rahani eivät riitä',
    copy_email: 'Kopioi sähköpostiosoite',
    successfully_copied: 'Sähköpostiosoite kopioitu',
    other_practical_matters: 'Muut käytännön asiat',
    thanks_for_booking: 'Kiitos varauksestasi.',
    prebook_created_at: 'Videotapaamisesi on vahvistettu seuraavasti:',
    prebooking_skill: 'Aihe:',
    you_need_high_network: 'Tapaamisen onnistumisen ja laadun varmistamiseksi huolehdithan, että sinulla on vakaa ja nopea verkkoyhteys.',
    you_will_got_accept: 'Saat meiltä varausvahvistuksen sekä kaksi muistutusta tekstiviestitse ja sähköpostitse.',
    message_with_link_to_meeting: 'Viestit sisältävät linkin, joista pääset tapaamiseen sovittuna ajankohtana.',
    dont_forget_decline_prebooking: 'Muistathan perua tapaamisen, mikäli ajankohta ei sovi sinulle.',
    for_decline_need: 'Voit peruuttaa varauksesi osiossa:',
    click_for_decline_prebooking: 'ja napsauta "Poista" -painiketta.', 
    best_regards: 'Ystävällisin terveisin,',
    luona_DigiVOK: 'Luona DigiVOK',
    emigration_or_holiday_outside_Finland: 'Maastamuuttoa tai lomaa Suomen ulkopuolella',
    free_time: 'Vapaa-aika',
    housing: 'Asumista',
    child_care: 'Lasta',
    child_health: 'Terveys',
    upbringing_parenting: 'Kasvatus/vanhemmuus',
    kindergarten_or_school: 'Päiväkoti tai koulu',
    reception_centre_info_service: 'Vastaanottokeskuksen neuvonta',
    physical_or_mental_symptoms: 'Fyysiset tai psyykkiset oireet',
    related_to_money_or_livelihood: 'Rahaan tai toimeentuloon liittyvää',
    related_to_your_own_or_family_life_situation:
      'Omaan tai perheen elämäntilanteeseen liittyvää',
    legal_matter: 'Oikeudellista asiaa',
    everyday_practical_things: 'Päivittäiset, arkiset käytännön asiat',
    service_housing: 'Majoitukseen liiittyvät käytännön asiat',
    how_do_you_judge_the_criticality_of_your_case:
      'Kuinka itse arvioi asiaasi kriittisyyden?',
    help_need_today: 'Asiani olisi hyvä saada kuntoon tänään',
    help_need_in_couple_of_days:
      'Asiani olisi hyvä saada kuntoon lähipäivinä',
    help_need_in_week: 'Asiani on saatava hoidettua viikon sisällä',
    help_is_not_urgent: 'Asiani ei ole kiireellinen',
    call_now: 'Soittaa heti',
    book_an_appointment: 'Varata ajan',
    authtorize_yourself: 'Tunnistaudu eli kerro meille kuka olet',
    user_successfully_registered: 'Rekisteröityminen onnistui',
    book_time: 'Varaa aika',
    what_time_works_for_you: 'Mikä aika sinulle sopii?',
    wizard_default_message:
      'Mikäli tilanne on henkeä uhkaava, soita 112.\nMikäli terveysasiasi ei voi odottaa seuraavaan arkipäivään, soita päivystysapu 116117. \nMikäli sinulla on muu akuutti kriisi, soita asuinkuntasi sosiaali- ja kriisipäivystykseen.',
    wizard_welcome_message:
      'Tervetuloa! Teemme yhdessä vaikuttavia sote-palveluja. Voimmeko olla avuksi?',
    wizard_PrebookingStep_header:
      'Pyrimme hoitamaan tilanteet lähtökohtaisesti heti. Mikäli kaikki työntekijämme ovat varattuina, sinulla on mahdollisuus varata aika tätä kautta. Haluaisitko varata ajan vai soittaa heti?',
    wizard_TimeStep_header:
      'Valitettavasti kukaan ei päässyt nyt vastaamaan. Pääset varaamaanajan tästä:',

    errorTitle: 'Hupsista!',
    warnTitle: 'Hmmm...',
    successTitle: 'Mahtavaa!',

    selectLanguage: 'Valitse kieli',
    interpreter: 'Tulkki',
    startInterpreting: 'Aloita tulkkaus',
    requestInterpreting: 'Pyydä tulkkausta',

    interpretationNeeded: 'Tulkkauspyyntö',
    requestForInterpretation: 'Tulkkausta tarvitaan!',
    requestForCall: 'Asiakas tarvitsee apua',
    nothingToInterpret: 'Ei tulkattavaa',
    noInterpretationNeeded: 'Kaikkiin tulkkauspyyntöihin on vastattu.',

    doYouWantToHangUp: 'Haluatko päättää tulkkauksen?',
    oppositeSideHangsUp: 'Vastapuoli katkaisi yhteyden.',
    networkTerminated: 'Yhteys katkesi.',
    review: 'Arvostele tulkkaus',
    customerFeedbackEnjoyingTulka: 'Sujuiko tulkkaus?',
    customerRatingInterpreter: 'Arviosi tulkkauksen laadusta',
    customerInvoiceNotes: 'Laskutusviite',
    interpreterRatingInterpreter: 'Sujuiko tulkkaus?',
    interpreterRatingCustomer: 'Kuinka suoriuduin tulkkauksessa',
    ratingQuality: 'Arviosi yhteyden laadusta',
    feedback: 'Palaute',
    feedbackDesc: 'kirjoita kommenttisi tähän',
    feedbackContactMe: 'Ottakaa minuun yhteyttä',
    proceed: 'Lähetä',
    skip: 'Ohita',

    endCall: 'Lopeta puhelu',
    refresh: 'Päivitä',
    logout: 'Kirjaudu ulos',
    toggleCamera: 'Hallitse kameraa',
    toggleAudio: 'Mykistys',

    onlyChromeSupported: 'Selaimesi ei ole tuettu',
    pleaseUseChrome: 'Asenna Google Chrome -selain käyttääksesi Túlkaa',
    downloadChrome: 'Lataa Google Chrome',
    continueInterpretation: 'Jatka tulkkausta',
    roomInfoCallEndedClosed: 'Palvelin sulki keskustelun.',
    feedbackTooLate: 'Tulkkauksen jatkaminen on mahdollista minuutin ajan!',

    noTimeLeft: 'Esittelyaika on loppunut. Olemme pian yhteydessä sinuun!',
    minutesConsumed:
      'Esittelyaika on nyt päättynyt. Olemme pian yhteydessä sinuun!',
    timeLeft: 'Esittelyaikaa jäljellä: {}',
    zeroTimeLeft: 'Esittelyaika on loppunut.\nOlemme pian yhteydessä sinuun!',
    freeUseLeft: 'Aikaa jäljellä',

    failedToSendFeedback: 'Palautteen lähettäminen epäonnistui!',
    failedLogOut: 'Uloskirjautuminen ei onnistunut.',
    failedToConnect: 'Ei yhteyttä palvelimeen.',
    waitingForInterpreter:
      'Pyyntösi tavoittaa tulkkeja parhaillaan. Joku heistä vapautuu pian tulkkaamaan. Odotus kestää yleensä 1-3 minuuttia.',
    waitingForCustomer: 'Odotetaan asiakasta...',
    pleaseWait: 'Hetkinen...',
    youHaveWaitedFor: 'Olet odottanut {}',
    error500: 'Jotain meni vikaan. Yritäthän uudelleen.',
    roomInfoHasAudioPacketLoss: 'Yhteydessä ongelmia!',

    yes: 'Kyllä',
    no: 'En',
    cancel: 'Peruuta',

    default: 'Oletusarvoinen',
    termsOfUse: 'Käyttöehdot ja tietosuoja',
    options: 'Näytä asetukset',
    hideOptions: 'Piilota asetukset',

    enable_notifications: 'Kytke ilmoitukset',
    disable_notifications: 'Sammuta ilmoitukset',
    disable_duration: 'Sammuta ilmoitukset',
    for_hour: 'tunniksi',
    for_day: 'päiväksi',
    for_week: 'viikoksi',
    for_month: 'kuukaudeksi',
    for_a_day: '1 päivä',
    for_days: '{} päivää',
    for_a_day_an_hour: '1 päivä, 1 tunti',
    for_a_day_hours: '1 päivä, {} tuntia',
    for_days_an_hour: '{} päivää, 1 tunti',
    for_days_hours: '{d} päivää, {h} tuntia',
    notifications_disabled_for: 'Ilmoitukset pois päältä\n{}',
    failed_to_disable_notifications:
      'Ilmoitusten sammutus ei onnistunut. Yritä myöhemmin uudelleen.',
    failed_to_enable_notifications:
      'Ilmoitusten kytkentä ei onnistunut. Yritä myöhemmin uudelleen.',
    forgot_password_link: 'Unohditko salasanan?',
    forgot_password_info:
      'Lähetämme sinulle uuden salasanan tekstiviestinä tai sähköpostina. Jos sinulla ei ole tunnuksia, ota yhteyttä info@tulka.com.',
    placeholder_phone_number: '+35840...',
    phone_number_title: 'Puhelinnumero',
    forgot_password_error:
      'Tekstiviestiä tai sähköpostia ei voitu lähettää. Ota yhteyttä osoitteeseen info@tulka.com',
    forgot_password_success:
      'Saat uuden salasanan kohta tekstiviestinä tai sähköpostiin.',
    skillModalQuestion:
      'Tarvitsetko alla olevaa erityisosaamista? Odotusaika voi olla pidempi.',
    targetLanguage: 'Kieli jota ymmärrät',
    openingTimes: '',
    systemAlert: 'Palvelussa teknisiä häiriöitä. Pahoittelemme.',

    chatFailed: 'Tekstichattiin kytkeytyminen epäonnistui.',
    chatTipInterpreter:
      'Täällä voit vaihtaa viestejä asiakkaan kanssa. Viestit tuhotaan välittömästi, kun tulkkaus päättyy.',
    chatTipCustomer:
      'Täällä voit vaihtaa viestejä tulkin kanssa. Viestit tuhotaan välittömästi, kun tulkkaus päättyy.',
    chatSendFailed: 'Viestin lähetys epäonnistui.',
    chatTypeMessage: 'Kirjoita viesti...',
    invoiceRefMandatory: 'Syötä vielä laskutusviite.',
    invoiceRefUserGroupMandatory: 'Valitse vielä laskun saaja.',
    selectInvoiceRefUserGroup: '-- Valitse --',

    show_feedbacks: 'Tähtiarviot',
    show_feedbacks_title: 'Näin minua arvioitiin',
    failedToFetchFeedbacks: 'Failed to fetch the ratings.',
    failedToCreateChatroom: 'Tähtiarvioiden hakeminen epäonnistui',
    statistics: 'Omat tulkkausmäärät',
    no_statistics: 'Tulkkausmääriä ei ole saatavilla.',
    stats_disclaimer:
      'Tulkkausmäärät ovat vain arvioita, eivätkä välttämättä täysin vastaa lopullisen palkkalaskelman määriä.',
    stats_today: 'Tänään',
    stats_yesterday: 'Eilen',
    stats_this_week: 'Tällä viikolla',
    stats_last_week: 'Viime viikolla',
    stats_this_month: 'Tässä kuussa',
    stats_last_month: 'Viime kuussa',
    stats_this_year: 'Tänä vuonna',
    stats_last_year: 'Viime vuonna',

    feedbackButton: 'Paina kirjoittaaksesi palautetta',

    // Prebooking
    prebook: 'Tee ennakkovaraus',
    prebooking_date_header: 'Milloin tarvitset tulkin?',
    prebooking_date_header_notice: 'Ilmoituksen viimeinen toimituspäivä',
    step: '{step} / {steps}',
    choose_button: 'Valitse',
    prebooking_duration_header: 'Kuinka kauan arvioit tarvitsevasi tulkkia?',
    hours_minutes: '{h}:{m}',
    hour_minutes: '{h}:{m}',
    minutes: '{} min',
    hour: '60 min',
    hours: '{}:00',
    customer_reference: 'Tiedot tulkkauksesta laskulle',
    customer_reference_placeholder: 'Kentän tiedot eivät tule näkyviin tulkille',
    please_provide_customer_reference:
      'Annatko vielä lisätiedot tulkkauksesta laskulle.',
    prebooking_time_header: 'Mihin kellonaikaan tarvitset tulkin?',
    select_language_title: 'Minkä kielisen tulkin tarvitset?',
    fetching_languages: 'Haetaan kieliä...',
    invoice_cause: 'Laskun kohdistaminen',
    invoice_cause_description: 'Valitse maksaja / kustannuspaikka',
    invoice_ref_placeholder: 'HUOM! Asiakaspalvelu ei lue tätä.',
    call_type_video: 'Video',
    call_type_call: 'Puhelu',
    call_type_question: 'Mitä yhteyttä haluat käyttää?',
    additional_order_info: 'Tilauksen lisätiedot',
    confirm_prebooking: 'Tarkista varauksen tiedot',
    comment: 'Välttämätön lisätieto käsittelijälle',
    prebooking_date: 'Päivä',
    prebooking_time: 'Kellonaika',
    prebooking_duration: 'Kesto',
    language_title: 'Kieli',
    presence_title: 'Tulkkauksen tyyppi',
    call_type_title: 'Yhteystyyppi',
    select_target_language_title: 'Mitä kieltä ymmärrät?',
    location_question: 'Missä tulkkaus tapahtuu?',
    location_placeholder_city: 'Kaupunki',
    location_placeholder_address: 'Lähiosoite',
    location_placeholder_postcode: 'Postinumero',
    add_message_to_interpreter: 'Lisää viesti tulkille',
    subject: 'Tulkkauksen aihe',
    interpretation_location_details: 'Saapumisohjeet tulkkauspaikalle (esim. toimipiste, rappu, kerros, huone jne.)',
    address_info: 'Osoitetiedot',
    arrival_instructions: 'Saapumisohjeet',
    addressAndArrivalInstrustions: 'Osoite ja saapumisohjeet',
    preferred_interpreters_question: 'Haluatko valita mieluisan tulkin?',
    search_preferred_interpreter: 'Hae tulkki nimellä',
    not_guarantee: 'Yritämme saada toivotun tulkin, mutta emme pysty takaamaan hänen olevan vapaana.',
    history_is_limited: 'Haun tulos on rajallinen, koska et ole käyttänyt vielä montaakaan tulkkia.',
    presence_question: 'Minkä tyyppinen tulkkaus on kyseessä?',
    presence_remote: 'Etätulkkaus',
    presence_remote_tip: 'Puhelun tai videon välityksellä tapahtuva etätulkkaus',
    presence_on_site: 'Lähitulkkaus',
    presence_on_site_tip: 'Tulkki paikan päällä',
    presence_notice: 'Ilmoitustulkkaus',
    presence_notice_tip:
      'Tekstimuotoinen viesti toimitetaan puhuttuna tulkkauksena',
    select_favorite: 'Haluatko valita mieluisimmat tulkit?',
    any_favorite: 'Kuka tahansa käy',
    call_type_call_tip: 'Vain ääni normaalina puheluna',
    call_type_video_tip: 'Mobiilisovelluksen kautta avattava videoyhteys',
    target_language_title: 'Kieli jolla työskentelet',
    invoice_ref_title: 'Tiedot tulkkauksesta laskulle',
    save_prebooking: 'Lähetä varaus',
    edit_prebooking: 'Tallenna',
    any_gender: 'Ei väliä',
    any_dialect: 'Mikä tahansa käy',
    gender_title: 'Sukupuoli',
    dialect_title: 'Murre',
    select_skill: 'Onko sinulla erityistarpeita tulkille?',
    select_dialect: 'Tarvitsetko jonkin erityisen murteen?',
    select_gender: 'Onko tulkin sukupuolella väliä?',
    any_skill: 'Ei erityistarpeita',
    skill_title: 'Erityistarpeet',
    unconfirmed_prebooking: 'Odottaa vahvistusta',
    confirmed: 'Vahvistettu',
    delivered: 'Toimitettu',
    not_able_to_reach: 'Ei tavoitettu',
    cancelled: 'Peruutettu',
    active: 'Aktiivinen',
    location_title_city: 'Kaupunki',
    location_title_address: 'Lähiosoite',
    searching_interpreter: 'Etsitään tulkkia',
    please_call_to_this_number: 'Soita tähän numeroon',
    // Help tooltips
    help_prebooking_summary_title: 'Kirjoita tähän ainoastaan välttämätön lisätieto käsittelijälle',
    help_prebooking_summary:
      'Huom! Lisätietokenttään kirjoittaminen pidentää tilauksen käsittelyaikaa.',
    help_call_type_title: 'Tulkkausyhteys',
    help_call_type:
      'Puhelutulkkauksella voit liittää yhteen useamman eri paikassa olevan ihmisen (konferenssipuhelu).\n\nVideotulkkauksessa ihmiset näkevät toisensa ja voit esimerkiksi pyytää tulkkia kääntämään tekstiä. Videotoiminnon voi kytkeä päälle ja pois tulkkauksen aikana.\n\nVoit halutessasi vaihtaa tulkkausmuodon kesken kaiken päättämällä tulkkauksen ja valitsemalla \'Jatka tulkkausta\'.',
    help_gender_title: 'Tulkin sukupuoli',
    help_gender:
      'Voit halutessasi valita tulkin sukupuolen. Huomioithan, että valinta vähentää käytettävissä olevien tulkkien määrää ja voi vaikeuttaa tulkin saatavuutta.',
    help_dialect_title: 'Tulkin osaama murre',
    help_dialect:
      'Voit halutessasi valita tulkin osaaman murteen. Huomioithan, että valinta vähentää käytettävissä olevien tulkkien määrää ja voi vaikeuttaa tulkin saatavuutta.',
    help_confirmation_title: 'Vahvista kotikustannuspaikka',
    help_confirmation:
      'Tekemäsi tilaukset kirjataan oletuksena kotikustannuspaikkaasi, ellet valitse tilaukselle poikkeavaa kustannuspaikkaa.',
    tomorrow: 'Huomenna',
    today: 'Tänään',
    now: 'Nyt',
    help_location_title: 'Sijainti/toimipaikka',
    help_location:
      'Kirjoita paikka, jossa tulkkaus tapahtuu. Esimerkiksi toimipaikka tai osoite.',
    help_favorites_title: 'Mieluisimmat tulkit',
    help_favorites:
      'Voit valita yhden tai useamman suosikkitulkkisi.\n\nHuomaa, että toivotut tulkit eivät välttämättä ole käytettävissä valittuna ajankohtana.',
    on_site_not_available_in_app:
      'Läsnäolotulkkausta ei voi aloittaa sovelluksesta.',
    back: 'Takaisin',
    rejoin_meeting: 'Liity uudestaan tapaamiseen',
    change_language: 'Vaihda kieli',
    prebook_successfully_created: 'Varauksen lisääminen onnistui',
    prebook_successfully_edit: 'Ennaltavaraus päivitetty',
    prebook_successfully_cancelled: 'Varauksen peruuttaminen onnistui',
    prebook_successfully_accepted: 'Varaus hyväksytty',
    prebooking_datails: 'Varauksen tiedot',
    prebook_confirmation_message: 'Oletko varma että haluat perua varauksen?',
    cancel_prebook_confirmation_message:
      'Oletko varma että haluat perua varauksen?',
    really_cancel_prebooking_too_late:
      'Oletko varma että haluat perua varauksen? Ilmainen peruminen ei ole enää mahdollista.',
    cancel_reason_selection: 'Mistä syystä haluat peruuttaa?',
    cancel_reason_sickness: 'Oma sairastuminen',
    cancel_sickness_tip: 'Lääkärintodistus vaaditaan',
    cancel_reason_mistake: 'Vahinko',
    cancel_reason_other: 'Muu syy',
    cancel_reason_other_tip: 'Vapaamuotoinen selitys vaaditaan',
    cancel_other_title: 'Selitys',
    cancel_other: 'Kerro mitä tapahtui?',
    cancel_sickness_title: 'Lääkärintodistus',
    cancel_sickness: 'Ole hyvä ja toimita lääkärintodistus sairastumisesta.',
    copy_information: 'Kopioi tiedot',
    my_bookings: 'Varaukset',
    interpreter_now: 'Tulkki heti',
    pre_book: 'Varaa tulkki',
    time: 'Aika',
    status: 'Tila',
    search: 'Haku',
    favorite_title: 'Mieluisimmat tulkit',
    no_prebookings: 'Sinulla ei ole varauksia.',
    prebooking_not_active: 'Sopimuksesi ei sisällä ennaltavaraamista.',
    reservation_number: 'Varausnumero',
    email: 'Sähköpostiosoite',
    not_yet_support_interpreter_login:
      'Uusi selainversio ei vielä sisällä tulkin toimintoja. Ole hyvä ja käytä mobiiliappia.',
    please_try_again_later: 'Yritä myöhemmin uudelleen.',
    no_languages: 'Ei kieliä',
    interpreter_phone_number: 'Tulkin puhelinnumero',
    remove: 'Poistaa',
    presence_conference: 'Neuvottelu / virtuaalihuone',
    presence_conference_tip: 'esim. Microsoft Teams, Skype, Google Meet, Zoom',
    interpreter_email: 'Tulkin sähköposti',
    all: 'Kaikki',
    invoice_user_group: 'Kustannuspaikka',
    cc_emails: 'Tilausvahvistuksen ja muutosilmoitusten vastaanottajat',
    cc_email: 'Sähköpostiosoite',
    cc_emails_copy: 'Tilausvahvistuksen ja muutosilmoitusten vastaanottajat',
    patient_name: 'Tulkattavan nimi',
    patient_name_description: 'Tämä tieto ei tule laskulle vaan menee tulkille tiedoksi esim. jääviystilanteita varten.',
    customer: 'Asiakas',
    customer_unit: 'Yksikkö',
    personal: 'Omat',
    search_for: 'Hae yksiköllä, kielellä, kustannuspaikalla tai asiakkaalla',
    failed_to_create_prebooking:
      'Varauksen luominen epäonnistui. Yritä myöhemmin uudelleen.',
    to_start_interpretation: 'Aloita tulkkaus soittamalla numeroon:',
    start_interpretation: 'Aloita tulkkaus',
    hey_username: 'Hei {username},',
    confirm_your_account_information: 'Vahvista käyttäjätilisi perustiedot',
    confirm_your_account_information_description:
      'Mahdollisimman sujuvan palvelun toimittamiseksi pyydämme sinua vahvistamaan tai korjaamaan käyttäjätunnukseesi liittyvät alla olevat tiedot',
    other: 'Muu',
    no_options: 'Ei hakutuloksia',
    search_message: 'Hae...',
    i_confirm_the_information: 'VAHVISTAN TIETONI OIKEIKSI',
    billing: 'Laskutus',
    checking_your_billing_information: 'Laskutustietojen tarkistus',
    check_that_the_interpretation_is_for_the_correct_cost_center:
      'Tarkista että tulkkaus kohdistuu oikeaan kustannuspaikkaan/yksikköön ja että laskutusviitetiedot ovat ohjeistuksen mukaiset',
    update: 'Päivitä',
    update_prebook: 'Päivitä varaus',
    your_changes_will_be_lost:
      'Haluatko varmasti keskeyttää? Kaikki muutokset menetetään.',
    settings: 'Asetukset',
    user_home_group_header: 'Oma toimipaikka',
    app_language: 'Kieli',
    update_settings: 'Tallenna asetukset',
    no_invoice_reference: 'En laskutusviitettä',
    no_user_group_selected: 'Kustannuspaikkaa ei valittu',
    prebook_successfully_updated: 'Laskutustiedot onnistuneesti vahvistettu',
    type_search_term_here: 'Kirjoita haettavan kustannuspaikan nimen osa tähän',
    verify: 'Vahvista',
    prebookings_successfully_updated:
      'Laskutustiedot onnistuneesti vahvistettu',
    alternative_cost_center: 'Tilaus- tai poikkeava kustannuspaikkanumero',
    alternative_cost_center_edit:
      'Muokkaa tilaus- tai poikkeavaa kustannuspaikkanumeroa',
    choose_default_cost_center: 'Valitse kotikustannuspaikka',
    enter_or_confirm_username: 'Anna tai vahvista kirjautumiseen käytettävä sähköpostiosoite.',
    enter_or_confirm_email: 'Anna tai vahvista sähköpostiosoite, johon lähetetään automaattisesti varausvahvistukset ja muutosilmoitukset varatuista tulkkauksista',
    you_can_change_this_information_at_any_time:
      'Voit koska tahansa muuttaa näitä tietoja ”Asetukset” -valikosta',
    edit: 'Muokkaa',
    change_reservation: 'Muokkaa',
    copy_reservation: 'Kopioi',
    change_reservation_description: 'Muokkaa varausta',
    copy_reservation_description: 'Kopioi varaus',
    cancel_prebook_description: 'Peruuta varaus',
    additional_cost_will_be_charged:
      'Tämä muokkaus on maksullinen. Alkuperäinen varaus alkaa 24 tunnin sisällä, joten veloitamme tästä muutoksesta hinnaston mukaisesti viime hetken peruutuksen verran.',
    we_will_cancel_this_booking_and_make_a_new_one:
      'Peruutamme tämän varauksen ja teemme sinulle uuden, jos muutat - aikaa, päiväystä, tyyppiä, kieltä tai kestoa.',
    preview: 'Esikatsele',
    edit_prebooking_details: 'Muokkaa varauksen laskutustietoja',
    no_invoices: 'Sinulla ei ole laskutukseen lähteviä tulkkausvarauksia.',
    add: 'Lisää',
    invite_success: 'Kutsu onnistuneesti lähetetty',
    invite_to_the_group: 'Kutsu uusi käyttäjä omaan yksikköösi',
    invite_to_the_group_explanation:
      'Tiedätkö kollegan jonka tehtäviin kuuluu tulkkivarausten tekeminen? Kutsu hänet Túlkaan.',
    invite: 'Lähetä kutsu',
    invite_to_group: 'Kutsu uusi käyttäjä',
    collaborators_header: 'Varausten yhteishallinnointi',
    add_collaborators: 'Jaa kollegalle hallinnointioikeus varauksiin',
    please_enter_email: 'Ole hyvä ja kirjoita sähköpostiosoite',
    collaborators_header_description:
      'Jaa kollegalle hallinnointioikeus varauksiisi. \nHuomioi, että hallinnointioikeuden voi jakaa vain jo olemassaolevan käyttäjän kanssa.',
    name: 'Etunimi ja Sukunimi',
    user_does_not_exist:
      'Käyttäjää ei löydy, voit jakaa oikeuden vain olemassaolevan käyttäjän kanssa.',
    invite_to_collaborate_success: 'Hallinnointioikeuden jako onnistui.',
    collaborator_successfully_removed:
      'Hallinnointioikeus onnistuneesti peruutettu.',
    invalid_email_address: 'Virheellinen sähköpostiosoite',
    invalid_phone_number: 'Hallinnointioikeus onnistuneesti peruutettu.',
    invalid_phone_number_error: 'Virheellinen puhelinnumero',
    already_registered_phone: 'Tämä numero on rekisteröity jo olemassa olevalle käyttäjälle. Ota yhteyttä asiakaspalveluun p. ',
    phone_number_cannot_start_with_0: 'Puhelinnumero ei voi alkaa 0:lla kansainvälisessä muodossa',
    email_address_from_different_organisation_domain:
      'Sähköpostiosoite kuuluu eri organisaatiolle.',
    this_user_already_added_to_collaborators:
      'Tällä käyttäjällä on jo hallinnointioikeus varauksiisi.',
    prebooked: 'Ennaltavarattu tulkkaus',
    ondemand: 'Pikatulkkaus',
    reset_user_password: 'Salasanan vaihto',
    reset_user_email: 'Vaihda sähköpostiosoite varausvahvistuksia varten',
    reset_user_email_for_login: 'Vaihda kirjautumiseen käytettävä sähköpostiosoite',
    please_enter_password: 'Salasana',
    please_enter_new_password: 'Uusi salasana',
    please_enter_current_password: 'Nykyinen salasana',
    please_repeat_password: 'Toista salasana',
    please_repeat_new_password: 'Uusi salasana uudestaan',
    passwords_do_not_match: 'Salasanat eivät täsmää',
    old_password_is_required: 'Nykyinen salasana on pakollinen',
    enter_new_password: 'Syötä uusi salasana',
    repeat_new_password: 'Uusi salasana uudestaan',
    password_requirement:
      'Salasanan tulee olla vähintään 8 merkkiä pitkä ja sisältää pieniä ja isoja kirjaimia sekä numero.',
    all_day: 'Koko päivä',
    previous: 'Edellinen',
    next: 'Seuraava',
    // today: 'Tänään',
    month: 'Kuukausi',
    week: 'Viikko',
    day: 'Päivä',
    agenda: 'Agenda',
    date: 'Pvm',
    // time: 'Aika',
    event: 'Tapahtuma',
    no_events_in_range: 'Ei tapahtumia jaksolla',
    show_more: 'Näytä lisää',
    reports: 'Raportointi',
    number_of_interpretations: 'Tulkkausmäärä',
    interpretation_breakdown_previous_month:
      'Tulkkauserittely - edellinen kuukausi',
    your_browser_is_not_supported: 'Käyttämäsi selain ei ole tuettu.',
    edge_browser_is_not_supported: 'EDGE-selain ei ole tuettu. Käytä Google Chrome -selainta.',
    we_recommend_using_the_latest_version_of_chrome_or_firefox_in_order_to_continue_using_this_service:
      'Suosittelemme käyttämään uusinta versiota Chrome tai Firefox selaimista jatkaaksesi tämän palvelun käyttämistä.',
    use_of_interpretation_report: 'Tulkkauksen Käyttö',
    billing_report: 'Laskutus',
    customer_reporting_report: 'Asiakasraportointi',
    data_export_report: 'Tiedon Vienti',
    monetra_billing_report: 'Monetra Laskutus',
    monetra_payroll_report: 'Monetra Palkanlasku',
    copy_data_to_new_booking: 'Käytä varausta uuden pohjana',
    update_at_least_date_or_time_fields_to_create_a_booking:
      'Luodaksesi varauksen muokkaa vähintään Päivä tai Kellonaika kenttiä',
    cancel_prebook: 'Peruuta varaus',
    newButton: 'Uusi',
    announcement_interpretation: 'Ilmoitustulkkaus',
    customer_name_and_date_of_birth: 'Asiakkaan nimi ja syntymäaika (laskulle)',
    customer_name: 'Asiakkaan nimi (laskulle)',
    date_of_birth: 'Asiakkaan syntymäaika (laskulle)',
    to_whom_the_message_is_forwarded_if_not_to_the_customer:
      'Kenelle viesti välitetään, jos ei asiakkaalle',
    telephone_number_to_which_the_interpreter_will_call:
      'Puhelinnumero(t), johon tulkki soittaa',
    main_telephone_number_to_which_the_interpreter_will_call:
      'Puhelinnumero, johon tulkki ensisijaisesti soittaa',
    additional_telephone_number_to_which_the_interpreter_will_call:
      'Muut puhelinnumero(t), joista asiakasta yritetään tavoittaa',
    message_to_customer_service_or_interpreter:
      'Viesti asiakaspalveluun tai tulkille',
    mandatory_information: 'Pakollinen tieto',
    optional_information: 'Vapaaehtoinen tieto',
    customer_data: 'Asiakkaan tiedot',
    write_the_content: 'Viestin sisältö',
    write_the_content_of_the_matter_to_be_reported_below:
      'Kirjoita ilmoitettavan asian sisältö alle',
    content_data_tooltip:
      'Ilmoitustulkkaus on yksisuuntainen, puhuttuna tarjottu viestin tulkkaus. Viestin pituus on maksimissaan 1000 merkkiä.',
    content_data_second_tooltip:
      'Tulkki ottaa yhteyttä ilmoitettuun puhelinnumeroon vähintään 5 kertaa. Ilmoitustulkkaus lasketaan suoritetuksi siitä huolimatta, ettei viesti saavuta asiakasta.',
    calendar_tooltip_notice_interpretation:
      'Tulkki soittaa ilmoitettuun numeroon valitun päivän loppuun mennessä.',
    calendars: 'Kalenterit',
    please_select_language: 'Valitse kieli',
    please_select_interpreter: 'Valitse tulkki',
    please_select_language_and_interpreter_to_see_the_calendar:
      'Valitse kieli ja tulkki ensin.',
    please_select_time: 'Valitse ajankohta poissaololle',
    block_time: 'Vahvistan poissaolon',
    block_time_description: 'Estetty aika - tänä aikana tulkki ei vastaanota soittoja eikä ennaltavarauksia',
    reservations_od: 'Varattu OD:lle',
    reservations_od_description: 'Varattu OD:lle - tänä aikana tulkki ei vastaanota ennaltavarauksia, ainoastaan OD soittoja',
    unavailable_time: 'Poissaolo',
    unavailability_type: 'Tyyppi',
    pick_start_time: 'Valitse aloitusaika',
    pick_end_time: 'Valitse lopetusaika',
    time_successfuly_blocked: 'Poissaolo onnistuneesti kirjattu',
    time_successfuly_unblocked: 'Poissaolo onnistuneesti peruutettu',
    time_successfuly_updated: 'Poissaolo onnistuneesti päivitetty',
    overlaping_information_dialog_mesage:
      'Valittu ajankohta osuu osittain päällekkäin olemassaolevan varauksen tai aiemman poissaolomerkinnän kanssa. Valitse uusi ajankohta tai peruuta aiempi varaus tai merkintä.',
    ok: 'ok',
    cancel_blocked_time_confirmation_message:
      'Oletko varma että haluat peruuttaa poissaoloilmoituksen?',
    cancel_interpreter_blocked_time: 'Poista sulku',
    relay_the_message_to_person: 'Viesti välitetään henkilölle:',
    birthday: 'Asiakkaan syntymäaika:',
    numbers_to_be_called: 'Numero(t) johon tulkki soittaa:',
    message: 'Viesti:',
    has_to_be_a_phone_number: 'Täytyy olla muotoa puhelinnumero',
    other_information_relevant_to_the_invoicing_such_as_information_about_the_customer:
      'Muu laskuntarkastajalle olennainen tieto, kuten tieto palvelunkäyttäjästä',
    this_invoice_needs_additional_information:
      'Ole hyvä ja täytä vähintään pakolliset laskutustiedot',
    cancelled_by: 'Peruuttaja',
    cancel_reason: 'Peruutuksen syy',
    join_to_meeting: 'Liity kokoukseen',
    ready_to_join: 'Valmis liittymään?',
    what_is_your_name: 'Mikä on nimesi?',
    your_name: 'Nimesi',
    waiting_for_luona_worker_and_interpreter:
      'Odotetaan Luonan työntekijää ja tulkkia…',
    waiting_label: 'Odotetaan…',
    type_message: 'Kirjoita viesti',
    looking_for: 'Hyvän palvelun takaaminen vaatii joskus aikaa. Odota hetki. Etsitään',
    luona_worker_persone: 'Luonan työntekijä',
    interpreter_persone: 'Tulkki',
    interpreter_status: 'Käytettävissä nyt',
    availability_yes: 'Käytettävissä nyt',
    availability_no: 'Et ole käytettävissä.',
    // prebooking luona
    prebooking_page_title: 'Varaukset',
    defaultPrebookingPageMessage: 'Sinulla ei ole varauksia',
    go_to_prebookings_list: 'Siirry varauslistaan',
    luona_feedback_title: 'Laatukysely',
    luona_feedback_description: 'Olet hyödyntänyt Luonan uuttaa teknistä etäpalvelualustaa. Keräämme palautetta palvelusta ja olisimme kiitollisia, jos vastaisit lyhyeen kyselyyn.',
    luona_feedback_score: 'Kuinka todennäköisesti suosittelisit tätä etäpalvelualustaa ystävällesi?',
    luona_feedback: 'Palaute',
    feedback_sent: 'Kiitos palautteesta!',
    luona_prefer: 'Etuudet',
    Luona_lead: 'Ryhmänvetäjä',
    luona_social: 'Sosiaali',
    luona_nurses: 'Terveys',
    go_to_services: 'Siirry palveluun',
    start_prebook: 'Aloita',
    prebooking_is_gone: 'Varausta ei enää ole',
    prebooking_not_answered: 'Emme valitettavasti pysty toimittamaan ennakkovaraustasi. Varaa uusi tapaaminen asiantuntijan kanssa.',
    prebooking_is_not_found: 'Aktiivista varausta ei löytynyt. Varaa uusi tapaaminen asiantuntijan kanssa.',
    prebooking_is_cancelled: 'Aktiivista varausta ei löytynyt. Varaus on mahdollisesti peruttu. Varaa uusi tapaaminen asiantuntijan kanssa.',
    prebooking_is_too_late: 'Varatusta ajasta on jo niin kauan, että tulkki on siirtynyt toiseen puheluun. Varaa uusi tapaaminen asiantuntijan kanssa.',
    prebooking_will_start_in: 'Ajanvarauksesi alkuun on:',
    early_preview_intro: ' Arvoisa Luonan asiakas!',
    you_can_close_tab_for_now: 'Voit sulkea tämän välilehden toistaiseksi.',
    my_prebooking_list: 'Omat varaukseni',
    waiting_time: 'Odotusaika on',
    connecting_to_meeting: 'Yhdistetään tapaamiseen',
    connecting_to_meeting_for_interpreter: 'Odota hetki, yhdistämme kaikki osallistujat.',
    no_access_to_your_camera_or_microphone: 'Et ole antanut lupaa käyttää laitteesi kameraa tai mikrofonia',
    remote_backup_to_onsite: 'Kuinka toimitaan, jos tulkkia ei löydy paikan päälle?',
    use_remote_interpretation_option: 'Käytetään etätulkkausta',
    cancel_prebooking_option: 'Perutaan tulkkaus',
    which_remote_interpretation_type_do_you_choose: 'Kumman etätulkkausmuodon valitset?',
    phone_interpretation: 'Puhelintulkkaus',
    conference_virtual_room: 'Neuvottelu / Virtuaalihuone (esim. Google Meet, Teams, Zoom)',
    confirm_you_are_online: 'Oletko poistunut? Jos haluat jatkaa puhelujen vastaanottoa, ole ystävällinen ja klikkaa "Liity takaisin" -painiketta.',
    join_back: 'Liity takaisin',
    requestErrorMessageReasonPoorInternet: 'Hupsista! Jotain meni pieleen. Syy saattaa olla huonossa nettiyhteydessä. Tarkasta yhteysasetukset!',
    networkDisconnectedMessage: 'Nettiyhteytesi ei ole riittävän nopea video-puheluille. Tarkasta yhteysasetukset!',
    interpreter_name: 'Tulkki',
    meeting_disconnected: 'Yhteys katkesi',
    meeting_reconnecting: 'Yhdistetään uudelleen',
    meeting_reconnected: 'Yhteys palautettu',
    browser_does_not_support_video_effects: 'Selaimesi ei tue videotehosteita',

    // Self-selfRegistration
    log_in: 'Kirjaudu sisään',
    sign_up: 'Rekisteröidy',
    create_an_account: 'Luo käyttäjätunnus',
    contact_information: 'Yhteystiedot',
    first_name: 'Etunimi',
    last_name: 'Sukunimi',
    contact_info_email: 'Sähköposti',
    contact_info_phone: 'Puhelinnumero',
    continue: 'Seuraava',
    is_personal_email: 'Sähköpostiosoitetta käytetään henkilöllisyytesi vahvistamiseen. Vahvista onko tämä sähköposti vain henkilökohtaiseen viestintääsi vai jaettu työtovereiden kanssa:',
    email_for_personal: 'Sähköposti on vain henkilökohtaiseen viestintääni',
    email_shared_with_colleagues: 'Sähköposti on jaettu työtoverien kanssa',
    check_email_again: 'Syötä sähköpostiosoite uudelleen vahvistussähköpostin lähettämistä varten.',
    is_personal_phone: 'Puhelinnumeroa käytetään henkilöllisyytesi vahvistamiseen. Vahvista onko tämä puhelinnumero vain henkilökohtaisessa käytössä vai jaettu työtovereiden kanssa:',
    phone_for_personal: 'Puhelinnumero on vain henkilökohtaisessa käytössä',
    phone_shared_with_colleagues: 'Puhelinnumero on jaettu työtoverien kanssa',
    check_phone_again_by_sms: 'Syötä puhelinnumero uudelleen vahvistustekstiviestin lähettämistä varten.',
    check_phone_again_by_call: 'Syötä puhelinnumero uudelleen automaattista soittoa varten.',
    already_have_an_account_part1: 'Onko sinulla jo tili? ',
    already_have_an_account_part2: 'Kirjaudu sisään ',
    confirm_your_email: 'Vahvista sähköpostiosoitteesi',
    confirm_your_email_message: 'Rekisteröinnin vahvistamiseksi olemme lähettäneen rekisteröityyn sähköpostiin vahvistusviestin. Vahvista sähköpostiosoite viestissä annettujen ohjeiden mukaan.',
    confirm_your_phone: 'Vahvista puhelinnumero',
    confirm_your_mobile_phone_message: 'Rekisteröinnin viimeistelemiseksi olemme lähettäneet vahvistusviestin rekisteröityyn puhelinnumeroon. Vahvista puhelinnumero noudattamalla tekstiviestissä olevia ohjeita.',
    confirm_your_phone_message: 'Automaatti soittaa sinulle pian lankapuhelinnumeron omistajuuden vahvistamiseksi. Kuulet puhelussa koodin, joka sinun tulee syöttää tälle rekisteröintilomakkeelle.',
    confirmation_code: 'Vahvistuskoodi',
    didnt_receive_an_email: 'Etkö saanut sähköpostia?',
    didnt_receive_a_call: 'Etkö saanut puhelua?',
    didnt_receive_sms: 'Etkö saanut vahvistusviestiä?',
    send_email_again: 'Lähetä sähköposti uudelleen',
    send_phone_again_for_sms: 'Lähetä uusi vahvistustekstiviesti',
    send_phone_again_for_call: 'Pyydä uutta soittoa',
    verification_page: 'Vahvistus',
    verification_link_expired: 'Jotain meni pieleen. Vahvistuslinkki on mahdollisesti päässyt vanhentumaan. Yritä uudelleen.',
    waiting_for_verification: 'Odotetaan vahvistusta', 
    select_the_correct_cost_center_regarding_invoicing: 'Valitse alla olevasta listasta oikea laskutusyksikkö tai kustannuspaikka. Mikäli et löydä oikeaa laskutusyksikköä valitse “En löytänyt oikeaa laskutusyksikköä” -valinta',
    how_often_do_you_predict_to_utilize_interpretation_services: 'Kuinka usein arvioit keskimäärin tarvitsevasi tulkkauspalveluita?',
    daily: 'Päivittäin',
    weekly: 'Viikottain',
    more_than_weekly: 'Harvemmin',
    periodically: 'Tarve on jaksottaista',
    cost_center: 'Laskutusyksikkö',
    i_could_not_find_the_correct_cost_center: 'En löytänyt oikeaa laskutusyksikköä',
    information_regarding_cost_center: 'Tiedot laskutusyksiköstä (toimiala sekä osastosi / yksikkösi, jolla työskentelet):',
    please_tell_us_where_you_work_in_which_unit_etc: ' Syötä kenttään toimiala sekä osastosi / yksikkösi, jolla työskentelet.',
    update_user_home_group_failed: 'Laskutusyksikön päivitys epäonnistui',
    set_up_your_password: 'Luo salasana',
    repeat_password: 'Vahvista salasana',
    submit: 'Rekisteröidy',
    set_password_failed: 'Salasanan päivitys epäonnistui',
    account_created: 'Käyttäjätunnus luotu!',
    you_can_now_login_to_the_tulka_application_using_your_credentials: 'Voit nyt kirjautua Túlkaan käyttäjätunnuksellasi. Sivun alareunasta löytyvällä painikkeella pääset suoraan Túlkan selainversioon.',
    we_have_also_sent_you_instructions_for_use_by_email: 'Olemme myös lähettäneet sinulle käyttöohjeet sähköpostiin.',
    open_mobile_app: 'Avaa mobiilisovellus',
    login_to_web: 'Kirjaudu sisään selainversioon',
    account_found: 'Olemassa oleva tunnus löytynyt',
    please_log_in_or_request_a_new_password: 'Kirjaudu sisään tai pyydä uusi salasana.',
    forgot_password: 'Unohdin salasanan',
    no_contract_found: 'Sopimusta ei löytynyt',
    it_seems_like_you_dont_have_an_contract_with_tulka_based_on_your_email_address: 'Emme löytäneet olemassa olevaa sopimusta antamallasi sähköpostiosoitteella.',
    please_contact_our_sales_to_begin_using_tulka: 'Mikäli haluat Túlkan käyttöön ota yhteyttä asiakaspalveluumme sivun oikeasta alareunasta olevan chatin kautta, puhelimitse 030 660 6400 tai sähköpostitse info@tulka.com',
    email_already_registered: 'Antamallasi sähköpostiosoitteella on jo olemassa oleva tunnus.',
    contact_service_with_chat: 'Ota yhteyttä asiakaspalveluumme sivun oikeasta alareunasta löytyvän chatin kautta tai soittamalla numeroon ',
    contact_service_without_chat: 'Ota yhteyttä asiakaspalveluumme p. ',
    contact_sales: 'Ota yhteyttä myyntiin',
    invalid_code: 'Virheellinen koodi',
    invalid_field_value: 'Tämä kenttä on pakollinen, eikä se voi olla tyhjä.',
    fistName_requirement: 'Etunimi pakollinen',
    lastName_requirement: 'Sukunimi pakollinen',
    contact_service_contacts: 'puhelimitse 030 660 6400 tai sähköpostitse info@tulka.com',
    backup_option: 'Varavaihtoehto',
    no_backup_option_selected: 'varavaihtoehtoa ei ole valittu',
  },

  en: {
    // customer guide for od-call (modal)
    if_neither_number_works: ' If neither number works, enter the service number in the following format:',
    order_an_interpreter_by_calling_the_number: 'Order an interpreter by calling the number',
    main_calling_number: '041 400 1234',
    main_calling_number_with_code: '*31# 041 400 1234',
    main_calling_number_with_zero: '0041 400 1234',
    phone_must_be_registered: 'The phone you use to call our emergency number must be registered in our system.',
    note: 'Note!',
    number_is_encrypted: ' If your number is encrypted, enter the service number in the following format:',
    attention_for_hus: 'Attention HUS wired network operators!',
    contact_to_customer_service: 'In case of problems, contact our customer service.',
    select_interpreter_language: 'After the call is connected, select the language to be interpreted from the following by entering the number using the phone\'s number keys',
    accept_incoming_prebookings: 'Accept or decline incoming prebookings',
    accept: 'Accept',
    decline: 'Decline',
    accept_all: 'Accept all',
    date_and_time: 'Date and time',
    duration: 'Duration',
    language: 'Language',
    presence: 'Presence',
    prebookings_already_checked: 'Prebookings already checked',
    username_not_unique: 'Login email is not unique.',
    new_username_set: 'New username set: ',
    // LUONA
    luonaSignupForm: 'Signup form',

    label_name: 'First name and last name',
    placeholder_name: 'Latin letters only',
    validate_name: 'More than 3 characters',

    label_phone: 'Phone',
    placeholder_phone: '+3XXXXXXXXXXX',
    validate_phone: 'mobile phone number in format: +XXXXXXXXXXXX',

    label_email: 'Email',
    placeholder_email: 'user@mail.com',
    validate_email: 'email in format: user@mail.com',

    label_city: 'City',
    placeholder_city: 'City name',
    validate_city: 'Only latin letters',
    selectOption: 'Select city',

    agreePrivacyPolicy: 'I agree with ',
    privacyPolicy: 'privacy policy',
    luonaSignupButton: 'Send',
    requestErrorMessage: 'Something went wrong, please try again!',
    new_password_success: 'New password successfully added',
    new_password_wrong: 'Old password not found',
    requestErrorMessageUserNotFound: 'Enter the registered phone number and email address.',
    requestErrorMessageUserExist:
      'Provided email or phone number is already in use, please try another one.',
    slot_not_free_anymore: 'Sorry, selected time slot is unavailable anymore',
    no_interpreter_in_prebooking: 'No interpreter in prebooking',
    back_to_telegram: 'Back to Telegram',
    what_is_your_language: 'Hello, What is your language',
    what_you_need_help_with: 'What do you need help for?',
    selectable_langs: 'Language',
    selectable_servises: 'Services',
    selectable_time: 'Time',
    selectable_interpreter: 'The consultation language is English or Finish. Do you need an interpreter?',
    authtorize: 'Authorization',
    choose_time: 'Please choose the time for your prebooking',
    prebook_not_available_message: 'Unfortunately, this service is unavailable right now. The working time:  Monday to Friday {SCHEDULE}',
    health: 'Health',
    work_or_study: 'Work or studying',
    money_or_livelihood: 'Money or livelihood',
    reception_allowance: 'Reception allowance / PFS card',
    my_money_is_not_enough: 'Running out of money',
    copy_email: 'Copy email',
    successfully_copied: 'E-mail successfully copied',
    other_practical_matters: 'Other practical topic',
    thanks_for_booking: 'Thank you for your reservation.',
    prebook_created_at: 'Your video meeting has been confirmed as following:',
    prebooking_skill: 'Issue:',
    you_need_high_network: 'To ensure the quality of the meeting, please make sure you have a stable and fast network connection, enough for video conference.',
    you_will_got_accept: 'You will receive a booking confirmation and two reminders by text message and email.',
    message_with_link_to_meeting: 'The messages contain a link from which you can get to the meeting at the agreed time.',
    dont_forget_decline_prebooking: 'Remember to cancel the meeting if you are not able to participate.',
    for_decline_need: 'You can do it in:',
    click_for_decline_prebooking: 'and then click on DELETE button on your pre-booking.', 
    best_regards: 'Kindest wishes,',
    luona_DigiVOK: 'Luona DigiVOK',
    emigration_or_holiday_outside_Finland:
      'Emigration or vacation outside Finland',
    free_time: 'Leisure time',
    housing: 'Living',
    child_care: 'Child',
    child_health: 'Health',
    upbringing_parenting: 'Upbringing/Parenthood',
    kindergarten_or_school: 'Daycare or school',
    reception_centre_info_service: 'Reception centre info service',
    physical_or_mental_symptoms: 'Health',
    related_to_money_or_livelihood: 'Financial security',
    related_to_your_own_or_family_life_situation:
      'Circumstances related to myself or my family',
    legal_matter: 'Legal affair',
    everyday_practical_things: 'Daily, everyday practical things',
    service_housing: 'Living',
    how_do_you_judge_the_criticality_of_your_case:
      'How do you rate the criticality of your case?',
    help_need_today: 'Urgent, should be taken care of during today',
    help_need_in_couple_of_days: 'Urgent, but can wait for few days',
    help_is_not_urgent: 'Not urgent',
    call_now: 'Call now',
    book_an_appointment: 'Book an appointment',

    help_need_in_week: 'Help need during week',
    authtorize_yourself: 'Authtorize yourself',
    user_successfully_registered: 'Authorization is successful',
    book_time: 'Book Time',
    what_time_works_for_you: 'What time works for you?',
    wizard_default_message:
      'If the situation is life threatening, call 112. If your health concern cannot wait till the next working day, call 116117 for advice. If you have another acute crisis, call the social crisis service of your municipality.',
    wizard_welcome_message:
      'Welcome to Luona service! We are going to do everything that we can to help you. How we can help you?',
    wizard_PrebookingStep_header:
      'We strive to deal with situations immediately. If all our employees are booked, you will have the opportunity to book an appointment through this. Would you like to book an appointment or call right now?',
    wizard_TimeStep_header:
      'Unfortunately, no one was able to answer now. You can book time from here.',
    // LUONA

    loginTitle: 'Sign in',
    username: 'Username',
    password: 'Password',
    loginButton: 'Sign in',
    loginFailed: 'Signin failed',
    sessionExpired: 'Session has expired. Please sign in again.',
    meeting_closed: 'Meeting was closed.',
    signin_code_block_message: 'This e-mail is already used before. We sent the verification code to your e-mail address: [email]. Please enter the verification code in the field below. ',
    placeholder_signin_code: 'code',
    close_meeting_confirmation_message: 'Are you sure that you want to leave the call? For all participants, this call will be closed too',
    internet_connection_lost: 'Lost internet connection',
    internet_connection_exist: 'Restored internet connection',
    errorTitle: 'Oops!',
    warnTitle: 'Hmmm...',
    successTitle: 'Great!',

    selectLanguage: 'Choose language',
    interpreter: 'Interpreter',
    startInterpreting: 'Interpret',
    requestInterpreting: 'Request interpretation',

    interpretationNeeded: 'Interpretation needed',
    requestForInterpretation: 'Interpretation is needed!',
    requestForCall: 'Customer is contacting',
    nothingToInterpret: 'All requests have been answered.',
    noInterpretationNeeded: 'All requests have been answered.',

    doYouWantToHangUp: 'Do you really want to hang up?',
    oppositeSideHangsUp: 'The other person has left the conversation.',
    networkTerminated: 'The conversation was disconnected.',
    review: 'Review',
    customerFeedbackEnjoyingTulka: 'Satisfied with the service?',
    customerRatingInterpreter: 'My feedback about interpreter',
    customerInvoiceNotes: 'Invoice reference',
    interpreterRatingInterpreter: 'How did it go?',
    interpreterRatingCustomer: 'How well did I do?',
    ratingQuality: 'Technical quality',
    feedback: 'Feedback',
    feedbackDesc: 'Write your comments here',
    feedbackContactMe: 'Please contact me',
    proceed: 'Send',
    skip: 'Skip',

    endCall: 'End call',
    refresh: 'Refresh',
    logout: 'Log out',
    toggleCamera: 'Toggle camera',
    toggleAudio: 'Toggle audio',

    onlyChromeSupported: 'Browser not supported',
    pleaseUseChrome: 'Please install Google Chrome to use Túlka Web App',
    downloadChrome: 'Click here to install',
    continueInterpretation: 'Continue interpretation',
    roomInfoCallEndedClosed: 'Server closed the conversation.',
    feedbackTooLate: 'Continue option no longer available!',

    noTimeLeft: 'The demo use has ended. We`ll contact you soon!',
    minutesConsumed: 'The demo use has now ended. We`ll contact you soon!',
    timeLeft: 'Interpretation time left: {}',
    zeroTimeLeft: 'The demo use has ended.\nWe`ll contact you soon!',
    freeUseLeft: 'Time left',

    failedToSendFeedback: 'Failed to send feedback!',
    failedLogOut: 'Failed to log out.',
    failedToCreateChatroom: 'Failed to start an interpretation!',
    failedToConnect: 'Failed to connect to server.',
    waitingForInterpreter:
      'Your request was sent to the interpreters. The waiting time is usually 1 to 3 minutes.',
    waitingForCustomer: 'Waiting for customer...',
    waiting_time: 'Waiting time is',
    pleaseWait: 'Please wait...',
    youHaveWaitedFor: 'You`ve waited {}',
    error500: 'Something went wrong. Please try again.',
    roomInfoHasAudioPacketLoss: 'Bad Connection!',

    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',

    default: 'Default',
    termsOfUse: 'Terms of use & Privacy policy',
    options: 'Show options',
    hideOptions: 'Hide options',

    enable_notifications: 'Enable notifications',
    disable_notifications: 'Disable notifications',
    disable_duration: 'Disable notifications for',
    for_hour: '1 hour',
    for_day: '1 day',
    for_week: '1 week',
    for_month: '1 month',
    for_a_day: '1 day',
    for_days: '{} days',
    for_a_day_an_hour: '1 day, 1 hour',
    for_a_day_hours: '1 day, {} hours',
    for_days_an_hour: '{} days, 1 hour',
    for_days_hours: '{d} days, {h} hours',
    notifications_disabled_for: 'Notifications disabled for\n{}',
    failed_to_disable_notifications:
      'Failed to disable notifications. Please try again later.',
    failed_to_enable_notifications:
      'Failed to enable notifications. Please try again later.',
    forgot_password_link: 'Forgot your password?',
    forgot_password_info:
      'If you have an account, we can send you a new password via SMS or email. In other cases contact info@tulka.com.',
    placeholder_phone_number: '+35840...',
    phone_number_title: 'Phone number',
    forgot_password_error:
      'The SMS or e-mail could not be sent. Please contact info@tulka.com',
    forgot_password_success:
      'Thank you. You should receive your password via SMS or e-mail shortly.',
    skillModalQuestion:
      'Do you need this experience? The waiting time may be longer.',
    targetLanguage: 'Language you understand',
    openingTimes: '',
    systemAlert: 'Technical issues with the service. Our apologies.',

    chatFailed: 'Failed to connect to text chat.',
    chatTipInterpreter:
      'Here you can chat with the customer. All messages are destroyed immediately when the interpretation ends.',
    chatTipCustomer:
      'Here you can chat with the interpreter. All messages are destroyed immediately when the interpretation ends.',
    chatSendFailed: 'Failed to send the message.',
    chatTypeMessage: 'Type a message...',
    invoiceRefMandatory: 'Invoice reference is a mandatory field.',
    invoiceRefUserGroupMandatory: 'Please select a recipient for the invoice.',
    selectInvoiceRefUserGroup: '-- Select --',

    show_feedbacks: 'Ratings',
    show_feedbacks_title: 'How I was rated',
    failedToFetchFeedbacks: 'Failed to fetch the ratings.',
    statistics: 'My interpretations',
    no_statistics: 'No statistics available.',
    stats_disclaimer:
      'The numbers are only estimates and may not exactly match the final pay slip.',
    stats_today: 'Today',
    stats_yesterday: 'Yesterday',
    stats_this_week: 'This week',
    stats_last_week: 'Last week',
    stats_this_month: 'This month',
    stats_last_month: 'Last month',
    stats_this_year: 'This year',
    stats_last_year: 'Last year',

    feedbackButton: 'Tap to write comments',

    // Prebooking
    prebook: 'Prebooked Interpretation',
    prebooking_date_header: 'When do you need an interpreter?',
    prebooking_date_header_notice: 'Last date for the message to be delivered',
    step: '{step} of {steps}',
    choose_button: 'Select',
    prebooking_duration_header:
      'How long do you think you need an interpreter?',
    hours_minutes: '{h}:{m}',
    hour_minutes: '{h}:{m}',
    minutes: '{} min',
    hour: '60 min',
    hours: '{}:00',
    customer_reference: 'Additional invoicing information',
    customer_reference_placeholder: 'The field information is not visible to the interpreter',
    please_provide_customer_reference: 'Please provide additional information.',
    prebooking_time_header: 'At what time do you need an interpreter?',
    select_language_title: 'What should be the interpreter language?',
    fetching_languages: 'Fetching languages...',
    invoice_cause: 'Cost Allocation',
    invoice_cause_description: 'Please select appropriate cost center',
    invoice_ref_placeholder: 'NOTE! Customer service does not read this.',
    call_type_video: 'Video',
    call_type_call: 'Call',
    call_type_question: 'Please choose the connection type',
    additional_order_info: 'Additional order information',
    confirm_prebooking: 'Please check the booking details.',
    comment: 'Necessary additional information for order handler',
    prebooking_date: 'Date',
    prebooking_time: 'Time',
    prebooking_duration: 'Duration',
    language_title: 'Language',
    presence_title: 'Presence',
    call_type_title: 'Call type',
    select_target_language_title: 'What is your language?',
    location_question: 'What is the location?',
    location_placeholder_city: 'City',
    location_placeholder_address: 'Street address',
    location_placeholder_postcode: 'Postcode',
    add_message_to_interpreter: 'Add message to interpreter',
    subject: 'Subject',
    interpretation_location_details: 'Arrival instructions at the location (e.g. office, stairs, floor, room, etc.)',
    address_info: 'Address information',
    arrival_instructions: 'Arrival instructions',
    addressAndArrivalInstrustions: 'Address and arrival instructions',
    preferred_interpreters_question: 'Would you like to select a preferred interpreter?',
    search_preferred_interpreter: 'Find interpreter by name',
    not_guarantee: 'We do our best to get the wished interpreter, but cannot guarantee that he/she is available.',
    history_is_limited: 'The search result is limited because you haven\'t used many interpreters yet.',
    presence_question: 'What type of interpretation?',
    presence_remote: 'Remote',
    presence_remote_tip: 'Via video or call using the app',
    presence_on_site: 'On-site',
    presence_on_site_tip: 'Interpreter physically present',
    presence_notice: 'Announcement interpretation',
    presence_notice_tip: 'Written message delivered as spoken interpretation',
    select_favorite: 'Want to select preferred interpreters?',
    any_favorite: 'No preference',
    call_type_call_tip: 'Voice only using a phone call',
    call_type_video_tip: 'Voice and image using mobile data',
    target_language_title: 'Your working language',
    invoice_ref_title: 'Invoice tag',
    save_prebooking: 'Book',
    edit_prebooking: 'Save',
    any_gender: 'Does not matter',
    any_dialect: 'No specific needs',
    gender_title: 'Gender',
    dialect_title: 'Dialect',
    select_skill: 'Any special needs for the interpreter?',
    select_dialect: 'Do you need a specific dialect?',
    select_gender: 'Does the interpreter gender matter?',
    any_skill: 'No special needs',
    skill_title: 'Special needs',
    unconfirmed_prebooking: 'Pending confirmation',
    confirmed: 'Confirmed',
    delivered: 'Delivered',
    not_able_to_reach: 'Not able to reach',
    cancelled: 'Cancelled',
    active: 'Active',
    location_title_city: 'City',
    location_title_address: 'Street address',
    // Help tooltips
    help_prebooking_summary_title: 'Write here only necessary additional information for order handler',
    help_prebooking_summary:
      'Note that filling this field will prolong processing time of the order.',
    help_call_type_title: 'Interpretation connection',
    help_call_type:
      'With a telephone interpretation you are able to join people who are in different locations (conference call).\n\nIn a video interpretation, people can see each others, and you can also show some text to the interpreter to translate. Video can be enabled or disabled any time during the interpretation.\n\nYou can change the interpretation connection by ending the interpretation and selecting \'Continue interpreration\'.',
    help_gender_title: 'Interpreter gender',
    help_gender:
      'You can request an interpreter of a specific gender. Please note that the selection may result a smaller number of available interpreters and make it less likely to get one.',
    help_dialect_title: 'Interpreter dialect',
    help_dialect:
      'You can request an interpreter who knows a specific dialect of the selected language. Please note that the selection may result a smaller number of available interpreters and make it less likely to get one.',
    help_confirmation_title: 'Confirm your default cost center',
    help_confirmation:
      'Your orders will be allocated by default to your default cost center, unless you specify an alternative cost center while placing the order.',
    tomorrow: 'Tomorrow',
    today: 'Today',
    now: 'Now',
    help_location_title: 'Location',
    help_location:
      'Type the location of the interpretation. This can be an address or other identifier.',
    help_favorites_title: 'Preferred interpreters',
    help_favorites:
      'You can select one or more of you favorite interpreters as the preferred interpreters. If one of the favorites is available he/she will be assigned to the interpretation.',
    on_site_not_available_in_app:
      'On-site interpretation cannot be started from the app.',
    searching_interpreter: 'Looking for interpreter',
    please_call_to_this_number: 'Please, call to this number',
    back: 'Back',
    rejoin_meeting: 'Rejoin meeting',
    change_language: 'Change language',
    prebook_successfully_created: 'Prebooking successfully created',
    prebook_successfully_edit: 'Prebooking successfully updated',
    prebook_successfully_cancelled: 'Prebooking successfully cancelled',
    prebook_successfully_accepted: 'Prebooking successfully accepted',
    prebooking_datails: 'Prebooking details',
    prebook_confirmation_message:
      'Are you sure that you want to abort prebooking?',
    cancel_prebook_confirmation_message:
      'Are you sure that you want to cancel prebooking?',
    really_cancel_prebooking_too_late:
      'Are you sure that you want to cancel prebooking? At this stage, cancelling is not free.',
    cancel_reason_selection: 'What is the reason for cancelling?',
    cancel_reason_sickness: 'Own sickness',
    cancel_sickness_tip: 'Medical certificate required',
    cancel_reason_mistake: 'Mistake',
    cancel_reason_other: 'Other',
    cancel_reason_other_tip: 'A free text explanation required.',
    cancel_other_title: 'Explanation',
    cancel_other: 'Tell us what happened?',
    cancel_sickness_title: 'Medical certificate',
    cancel_sickness: 'Please send a medical certificate of the sickness.',
    copy_information: 'Copy Information',
    my_bookings: 'Bookings',
    interpreter_now: 'Interpreter now',
    pre_book: 'Prebooked Interpretation',
    time: 'Time',
    status: 'Status',
    search: 'Search',
    favorite_title: 'Preferred interpreters',
    no_prebookings: 'You don`t have any prebookings.',
    prebooking_not_active: 'Your contract does not include prebooking.',
    reservation_number: 'Reservation number',
    email: 'E-mail',
    not_yet_support_interpreter_login:
      'The new web app does not yet support interpreter login. Please use the mobile app.',
    please_try_again_later: 'Please try again later.',
    no_languages: 'No languages',
    interpreter_phone_number: 'Interpreter phone number',
    remove: 'Delete',
    presence_conference: 'Conference / virtual room',
    presence_conference_tip: 'e.g. Microsoft Teams, Skype, Google Meet, Zoom',
    interpreter_email: 'Interpreter email',
    all: 'All',
    invoice_user_group: 'Cost center',
    cc_emails: 'Confirmation and change notifications recipients',
    cc_email: 'E-mail address',
    cc_emails_copy: 'Сonfirmation and change notification recipients',
    patient_name: 'Your client\'s name who needs the interpreter',
    patient_name_description: 'This information does not appear on the bill, but goes to the interpreter for information, e.g. in case of detention.',
    customer: 'Customer',
    customer_unit: 'Unit',
    personal: 'Personal',
    search_for: 'Search for unit, language, cost center or customer',
    failed_to_create_prebooking:
      'Failed to create a new prebooking. Please try again later.',
    to_start_interpretation:
      'To start interpretation please call to this number:',
    start_interpretation: 'Start Interpretation',
    hey_username: 'Hey {username},',
    confirm_your_account_information: 'Confirm your account information',
    confirm_your_account_information_description:
      'In order to provide the smoothest possible service, we ask you to confirm or correct the information below regarding your profile',
    other: 'Other',
    no_options: 'No search results',
    search_message: 'Search...',
    i_confirm_the_information: 'I CONFIRM THE INFORMATION',
    billing: 'Invoicing',
    checking_your_billing_information: 'Checking your billing information',
    check_that_the_interpretation_is_for_the_correct_cost_center:
      'Check that the interpretation is for the correct cost center / unit and that the billing reference information is in accordance with the instructions',
    update: 'Update',
    update_prebook: 'Update prebooking',
    your_changes_will_be_lost:
      'Are you sure you want to cancel? Your changes will be discarded.',
    settings: 'Settings',
    user_home_group_header: 'Home user group',
    app_language: 'Language',
    update_settings: 'Save settings',
    no_invoice_reference: 'No invoice reference',
    no_user_group_selected: 'No user group selected',
    prebook_successfully_updated: 'Invoicing information confirmed',
    type_search_term_here: 'Type search term here',
    verify: 'Confirm',
    prebookings_successfully_updated: 'Invoicing information confirmed',
    alternative_cost_center: 'Alternative cost center or order number',
    alternative_cost_center_edit:
      'Edit alternative cost center or order number',
    choose_default_cost_center: 'Choose default cost center',
    enter_or_confirm_username: 'Enter or confirm the email used for login',
    enter_or_confirm_email: 'Enter or confirm an e-mail address to which booking confirmations and change notifications for booked interpretations are automatically sent',
    you_can_change_this_information_at_any_time:
      'You can change this information at any time from the “Settings” menu',
    edit: 'Edit',
    change_reservation: 'Change',
    copy_reservation: 'Copy',
    change_reservation_description: 'Change reservation',
    copy_reservation_description: 'Copy reservation',
    cancel_prebook_description: 'Cancel reservation',
    additional_cost_will_be_charged:
      'Additional cost will be charged. Original scheduled time takes place within 24 hours, so this change will incur an additional charge as per last minute cancellation in the price list',
    we_will_cancel_this_booking_and_make_a_new_one:
      'We will cancel this booking and make a new one if you change - time, date, type, language, duration',
    preview: 'Preview',
    edit_prebooking_details: 'Edit Prebooking details',
    no_invoices: 'You don`t have any bookings to process for invoicing.',
    add: 'Add',
    invite_to_the_group: 'Invite to the group',
    invite_to_the_group_explanation:
      'Do you know a colleague who uses interpretation services in their profession? Invite her to Túlka',
    invite: 'Invite',
    invite_success: 'Invite successfully sent.',
    invite_to_group: 'Invite new people',
    collaborators_header: 'Your collaborators',
    add_collaborators: 'Add collaborators',
    please_enter_email: 'Please enter email',
    name: 'Name',
    collaborators_header_description:
      'Invite a collaborator to co-manage your bookings. \nNote: You can invite an existing user only.',
    user_does_not_exist:
      'User does not exist, you can invite only existing user',
    invite_to_collaborate_success: 'User successfully invited to collaborate.',
    collaborator_successfully_removed: 'Collaborator successfully removed.',
    invalid_email_address: 'Invalid email address',
    invalid_phone_number: 'Invalid phone number',
    invalid_phone_number_error: 'Invalid phone number',
    already_registered_phone: 'This phone number is already registered to another user. Please contact customer service ',
    phone_number_cannot_start_with_0: 'Phone number cannot start with 0 in international format',
    email_address_from_different_organisation_domain:
      'Email address from different domain',
    this_user_already_added_to_collaborators:
      'This user already added to collaborators',
    prebooked: 'Prebooked',
    ondemand: 'On demand',
    reset_user_password: 'Change password',
    reset_user_email: 'Change email for booking confirmations',
    reset_user_email_for_login: 'Change email for login',
    please_enter_password: 'Password',
    please_enter_new_password: 'New password',
    please_enter_current_password: 'Current password',
    please_repeat_password: 'Repeat password',
    please_repeat_new_password: 'New password again',
    passwords_do_not_match: 'Passwords do not match',
    old_password_is_required: 'Old password is required',
    enter_new_password: 'Enter new password',
    repeat_new_password: 'Repeat new password',
    password_requirement:
      'Password must be aleast 8 characters, including an uppercase and lowercase letter, a number',
    all_day: 'All day',
    previous: 'Previous',
    next: 'Next',
    // today: 'Today',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    agenda: 'Agenda',
    date: 'Date',
    // time: 'Time',
    event: 'Event',
    no_events_in_range: 'No events in range',
    show_more: 'Show more',
    reports: 'Reports',
    number_of_interpretations: 'Tulkkausmäärä',
    interpretation_breakdown_previous_month:
      'Tulkkauserittely - edellinen kuukausi',
    your_browser_is_not_supported: 'Your Browser is not Supported.',
    edge_browser_is_not_supported: 'EDGE browser is not supported. Please use Google Chrome.',
    we_recommend_using_the_latest_version_of_chrome_or_firefox_in_order_to_continue_using_this_service:
      'We recommend using the latest version of Chrome or Firefox in order to continue using this service.',
    use_of_interpretation_report: 'Tulkkauksen Käyttö',
    billing_report: 'Laskutus',
    customer_reporting_report: 'Asiakasraportointi',
    data_export_report: 'Tiedon Vienti',
    monetra_billing_report: 'Monetra Laskutus',
    monetra_payroll_report: 'Monetra Palkanlasku',
    copy_data_to_new_booking: 'Copy data to new booking',
    replace_with_new_one: 'Replace with new one',
    update_at_least_date_or_time_fields_to_create_a_booking:
      'Update at least Date or Time fields to create a booking',
    cancel_prebook: 'Cancel',
    newButton: 'New',
    announcement_interpretation: 'Announcement interpretation',
    customer_name_and_date_of_birth:
      'Customer name and date of birth (for invoice)',
    customer_name: 'Customer name (for invoice)',
    date_of_birth: 'Date of birth (for invoice)',
    to_whom_the_message_is_forwarded_if_not_to_the_customer:
      'To whom the message is forwarded, if not to the customer',
    telephone_number_to_which_the_interpreter_will_call:
      'Telephone number (s) to which the interpreter will call',
    main_telephone_number_to_which_the_interpreter_will_call:
      'Main telephone number to which the interpreter will call',
    additional_telephone_number_to_which_the_interpreter_will_call:
      'Additional telephone number(s) to which the interpreter will call',
    message_to_customer_service_or_interpreter:
      'Message to customer service or interpreter',
    mandatory_information: 'Mandatory information',
    optional_information: 'Optional information',
    customer_data: 'Customer data',
    write_the_content: 'Message content',
    write_the_content_of_the_matter_to_be_reported_below:
      'Write the content of the matter to be reported below',
    content_data_tooltip:
      'Message interpretation is a one-way, spoken interpretation of a message. The maximum length of the message is 1000 characters.',
    content_data_second_tooltip:
      'The interpreter will call the given phone number at least 5 times. The interpretation of the message will be considered completed even when the message does not reach the customer.',
    calendar_tooltip_notice_interpretation:
      'The interpreter will call the number provided by the ned of the selected day.',
    calendars: 'Calendars',
    please_select_language: 'Choose language',
    please_select_interpreter: 'Please select interpreter',
    please_select_language_and_interpreter_to_see_the_calendar:
      'Please select language and interpreter to see the calendar',
    please_select_time: 'Please select time',
    block_time: 'Blocked time',
    block_time_description: 'Blocked time - During this time the interpreter will not receive any calls or prebookings.',
    reservations_od: 'Reservation for OD',
    reservations_od_description: 'Reservation for OD - During this time interpreter will not receive prebookings, but will be targeted only to OD',
    unavailable_time: 'Unavailable time',
    unavailability_type: 'Unavailability type',
    pick_start_time: 'Pick a start time',
    pick_end_time: 'Pick an end time',
    time_successfuly_blocked: 'Time successfully blocked',
    time_successfuly_unblocked: 'Blocked time successfully canceled',
    time_successfuly_updated: 'Blocked time successfully updated',
    overlaping_information_dialog_mesage:
      'The dates are overlapping with an existing prebooking or another unavailability. Please remove overlapping unavailabilities, cancel the overlapping prebookings or use different dates.',
    ok: 'ok',
    cancel_blocked_time_confirmation_message:
      'Are you sure that you want to cancel the blocked time?',
    cancel_interpreter_blocked_time: 'Unblock',
    relay_the_message_to_person: 'Relay the message to person:',
    birthday: 'Date of birth:',
    numbers_to_be_called: 'Numbers to be called:',
    message: 'Message:',
    has_to_be_a_phone_number: 'Has to be a phone number',
    other_information_relevant_to_the_invoicing_such_as_information_about_the_customer:
      'Other information relevant to the invoicing, such as information about the customer',
    this_invoice_needs_additional_information:
      'This invoice needs additional information. Please fill in the required fields to confirm your order',
    cancelled_by: 'Cancelled by',
    cancel_reason: 'Cancel reason',
    join_to_meeting: 'Join to meeting',
    ready_to_join: 'Ready to join?',
    what_is_your_name: 'What\'s your name?',
    your_name: 'Your name',
    waiting_for_luona_worker_and_interpreter:
      'Waiting for Luona worker and interpreter...',
    waiting_label: 'Waiting...',
    type_message: 'Type a message',
    looking_for: 'We are trying to provide the best service but sometimes it takes time. Please hold on, we are looking for',
    luona_worker_persone: 'Luona worker',
    interpreter_persone: 'Interpreter',
    interpreter_status: 'On-demand interpretations',
    availability_yes: 'Available now',
    availability_no: 'You are not available.',
    // prebooking luona
    prebooking_page_title: 'Prebooking',
    defaultPrebookingPageMessage: 'You don\'t have any prebookings',
    go_to_prebookings_list: 'Go to prebookings list',
    luona_feedback_title: 'Quality survey',
    luona_feedback_description: 'You have taken advantage of Luona\'s new technological remote service platform. We collect feedback about the service, and we would be grateful if you answer our short survey.',
    luona_feedback_score: 'How likely is it that you would recommend this remote service platform to a friend?',
    luona_feedback: 'Feedback',
    feedback_sent: 'Thank you for your feedback!',
    luona_prefer: 'Preferential',
    Luona_lead: 'Lead team',
    luona_social: 'Social',
    luona_nurses: 'Medical',
    go_to_services: 'Go to services',
    start_prebook: 'Start',
    prebooking_is_gone: 'Prebooking is gone',
    prebooking_not_answered: 'Unfortunately we cannot deliver the prebooking. Book a consultation with a specialist again',
    prebooking_is_not_found: 'Unfortunately, we cannot find a matching active prebooking. Please try to make an emergency call or book a consultation with a specialist again.',
    prebooking_is_cancelled: 'Unfortunately, we cannot find a matching active prebooking. Maybe It was cancelled. Please try to make an emergency call or book a consultation with a specialist again.',
    prebooking_is_too_late: 'Unfortunately, the prebooking start time has passed. After waiting, the interpreters were redirected to handle urgent calls. Please try to make an emergency call or book a consultation with a specialist again.',
    prebooking_will_start_in: 'The start of your appointment is in:',
    early_preview_intro: 'Dear Luona customer!',
    you_can_close_tab_for_now: 'You can close this tab for now.',
    my_prebooking_list: 'My reservations',
    connecting_to_meeting: 'Connecting to meeting',
    connecting_to_meeting_for_interpreter: 'Please hold on to the line, we are connecting all the parties',
    no_access_to_your_camera_or_microphone: 'No access to your camera or microphone',
    remote_backup_to_onsite: 'What to do if no interpreter is found on-site?',
    use_remote_interpretation_option: 'Use remote interpretation',
    cancel_prebooking_option: 'Cancel prebooking',
    which_remote_interpretation_type_do_you_choose: 'Which remote interpretation type do you choose?',
    phone_interpretation: 'Phone interpretation',
    conference_virtual_room: 'Conference / Virtual room (e.g. Google meet, Teams, Zoom)',
    confirm_you_are_online: 'Did you leave? If you want to keep accepting calls please click the button "Join back".',
    join_back: 'Join back',
    requestErrorMessageReasonPoorInternet: 'Oops! Something went wrong. One of the reasons might be poor internet. Please check your internet settings!',
    networkDisconnectedMessage: 'Your internet connection is not fast enough for video calls. Please check your internet settings!',
    interpreter_name: 'Interpreter',
    meeting_disconnected: 'You have been disconnected',
    meeting_reconnecting: 'You are being reconnected',
    meeting_reconnected: 'You have been reconnected',
    browser_does_not_support_video_effects: 'Browser does not support video effects',
    // Self-selfRegistration
    log_in: 'log in',
    sign_up: 'sign up',
    create_an_account: 'Create an account',
    contact_information: 'Contact information',
    first_name: 'First name',
    last_name: 'Last name',
    is_personal_email: 'This email address is being used to verify your identity. Please specify whether this email address is solely for personal communication or shared with colleagues:', 
    email_for_personal: 'Email is solely for personal communication',
    email_shared_with_colleagues: 'Email is shared with colleagues',
    check_email_again: 'Please enter email address again for sending verification email.',
    is_personal_phone: 'This phone number is used to verify your identity. Please specify whether this phone number is solely for personal communication or shared with colleagues:',
    phone_for_personal: 'Phone number is solely for personal communication',
    phone_shared_with_colleagues: 'Phone number is shared with colleagues',
    check_phone_again_by_sms: 'Please enter phone number again for sending verification sms.',
    check_phone_again_by_call: 'Please enter landline number again for receiving an automated call.',
    already_have_an_account_part1: 'Already have an account? ',
    already_have_an_account_part2: 'Log in ',
    contact_info_email: 'Email',
    contact_info_phone: 'Phone number',
    confirm_your_email: 'Confirm your email',
    confirm_your_email_message: 'To complete registration, we have sent a verification email to the registered email. Please verify email by following instructions on the email.',
    confirm_your_phone: 'Confirm your phone',
    confirm_your_mobile_phone_message: 'To complete registration, we have sent a verification SMS to the registered phone number. Please verify the phone number by following instructions on the SMS.',
    confirm_your_phone_message: 'You will receive an automated call for verifying ownership of a landline number. During the call you will hear a code, which you need to type here.',
    confirmation_code: 'Confirmation code',
    didnt_receive_an_email: 'Didn\'t receive an email?',
    didnt_receive_a_call: 'Didn\'t receive a call?',
    didnt_receive_sms: 'Didn\'t receive sms?',
    send_email_again: 'Send email again',
    send_phone_again_for_sms: 'Send SMS again',
    send_phone_again_for_call: 'Request another callback',
    verification_page: 'Verification page',
    verification_link_expired: 'Something went wrong. Verification link might have expired. Please try again.',
    waiting_for_verification: 'Waiting for verification', 
    select_the_correct_cost_center_regarding_invoicing: 'Select the correct cost center regarding invoicing. If you can\'t find the correct cost center select "I couldn’t find the correct cost center" -option.',
    how_often_do_you_predict_to_utilize_interpretation_services: 'How often do you predict to utilize interpretation services?',
    daily: 'Daily',
    weekly: 'Weekly',
    more_than_weekly: 'More seldom than weekly',
    periodically: 'Periodically',
    cost_center: 'Cost center',
    i_could_not_find_the_correct_cost_center: 'I couldn\'t find the correct cost center',
    information_regarding_cost_center: 'Information regarding cost center (Industry / department where you work)',
    please_tell_us_where_you_work_in_which_unit_etc: 'Please tell us where you work, in which unit etc.',
    update_user_home_group_failed: 'Update user home group failed',
    set_up_your_password: 'Set up your password',
    repeat_password: 'Repeat password',
    set_password_failed: 'Set password failed',
    submit: 'submit',
    account_created: 'Account created!',
    you_can_now_login_to_the_tulka_application_using_your_credentials: 'You can now log in to Túlka. The button at the bottom of the page takes you directly to the browser version of Túlka.',
    we_have_also_sent_you_instructions_for_use_by_email: 'We have also sent you the instructions by email.',
    open_mobile_app: 'Open mobile app',
    login_to_web: 'Login to WEB',
    account_found: 'Account found',
    please_log_in_or_request_a_new_password: 'Please log in or request a new password.',
    forgot_password: 'Forgot password',
    no_contract_found: 'No contract found',
    it_seems_like_you_dont_have_an_contract_with_tulka_based_on_your_email_address: 'It seems like you don\'t have an contract with Túlka based on your email address.',
    please_contact_our_sales_to_begin_using_tulka: 'If you want to use Túlka, contact our customer service via the chat at the bottom right of the page, by phone on 030 660 6400 or by email at info@tulka.com',
    email_already_registered: 'Email is already registered with another account.',
    contact_service_with_chat: 'Please contact customer service with chat or by calling number ',
    contact_service_without_chat: 'Please contact customer service by calling number ',
    contact_sales: 'by phone on 030 660 6400 or by email at info@tulka.com',
    invalid_code: 'Invalid code',
    invalid_field_value: 'This field is required and it can not be empty.',
    fistName_requirement: 'First name required',
    lastName_requirement: 'Last name required',
    contact_service_contacts: 'tel. 030 660 6400 | info@tulka.com',
    backup_option: 'Backup option',
    no_backup_option_selected: 'No backup option selected',
  },

  uk: {
    luonaSignupForm: 'Форма реєстрації',

    label_name: 'Ім`я та прізвище',
    placeholder_name: 'Тільки латинські літери',
    validate_name: 'Введіть своє ім`я та прізвище латинськими літерами',

    label_phone: 'Мобільний номер телефону',
    placeholder_phone: '+3XXXXXXXXXXX',
    validate_phone:
      'Введіть свій мобільний номер телефону у форматі: +3XXXXXXXXXXX',

    label_email: 'Електронна адреса',
    placeholder_email: 'user@mail.com',
    validate_email: 'Введіть свою електронну пошту у форматі: user@mail.com',

    label_city: 'Місце реєстрації',
    placeholder_city: 'Назва міста латинськими літерами',
    validate_city: 'Назва міста латинськими літерами',
    selectOption: 'Виберіть місто',

    agreePrivacyPolicy: 'Погоджуюсь з ',
    privacyPolicy: 'політикою конфіденційності',
    luonaSignupButton: 'Відправити',
    requestErrorMessage: 'Щось пішло не так, будь ласка спробуйте ще раз.',
    requestErrorMessageUserNotFound: 'Введіть зареєстровані номер телефона та електронну адресу.',
    requestErrorMessageUserExist:
      'Вказана електронна пошта або телефонний номер вже зареєстрований. Вкажіть інші.',
    slot_not_free_anymore: 'Вибачте, але цей час вже недоступний до бронювання',
    no_interpreter_in_prebooking: 'Вибачте, але наразі немає вільного перекладача',
    back_to_telegram: 'Назад до бота',
    // meeting room
    pleaseWait: 'Будь ласка зачекайте',
    what_is_your_name: 'Як вас звати?',
    your_name: 'Ваше ім`я',
    selectLanguage: 'Виберіть мову',
    ready_to_join: 'Готові приєднатися?',
    join_to_meeting: 'Приєднатися',
    type_message: 'Напишить повідомлення',
    looking_for: ' Ми намагаємося надавати найкращі послуги, але іноді це потребує часу. Будь ласка, зачекайте підключення',
    luona_worker_persone: 'Luona працівника',
    interpreter_persone: 'Перекладача',
    // prebooking luona
    prebooking_page_title: 'Попередні бронювання',
    defaultPrebookingPageMessage: 'У вас немає жодного бронювання',
    presence_conference: 'Конференція / Віртуальна кімната',
    presence_on_site: 'На сайті',
    presence_remote: 'Віддалено',
    presence_notice: 'Оголошення перекладу',
    call_type_video: 'Тип відео',
    call_type_call: 'Тип дзвонка',
    all: 'Всі',
    time: 'Дата та Час',
    now: 'Зараз',
    today: 'Сьогодні',
    tomorrow: 'Завтра',
    language_title: 'Мова',
    status: 'Статус',
    prebooking_duration: 'Тривалість',
    call_type_title: 'Тип',
    interpreter_phone_number: 'Телефон перекладача',
    invoice_user_group: 'Група користувача',
    customer: 'Клієнт',
    customerInvoiceNotes: 'Нотатки',
    alternative_cost_center: 'Додатковий рахунок',
    reservation_number: '№ бронювання',
    confirmed: 'Підтвердження',
    unconfirmed_prebooking: 'Не підтверджено',
    completed_prebooking: 'Готовніть бронювання',
    delivered: 'Доставлено',
    not_able_to_reach: 'Не в змозі дістатися',
    cancelled: 'Відмінено',
    active: 'Активно',
    change_reservation_description: 'Редагувати',
    copy_reservation_description: 'Копіювати',
    cancel_prebook_description: 'Відмінити',
    cancel_prebook_confirmation_message:
      'Чи дійсно ви хочете видалити бронювання?',
    no: 'Ні',
    yes: 'Так',
    hrs: 'г',
    min: 'хв',
    prebook_successfully_cancelled: 'Бронювання успішно видалено',
    remove: 'Видалити',
    what_is_your_language: 'Привіт, яка твоя мова',
    what_you_need_help_with: 'З чим вам потрібна допомога?',
    selectable_servises: 'Послуги',
    selectable_langs: 'Мова',
    selectable_time: 'Час',
    selectable_interpreter: 'Консультація проходить англійською чи фінською мовою. Чи потрібен вам перекладач для консультації? Зверніть увагу, що на відеозустрічі буде присутній або перекладач з української, або російської мови.',
    authtorize: 'Авторизація',
    choose_time: 'Оберіть зручний час для вашої консультації',
    prebook_not_available_message: 'На жаль, зараз ця послуга недоступна. Графік роботи: понеділок-п\'ятниця {SCHEDULE}.',
    services_step_title: 'Сервіси',

    health: 'Здоров\'я',
    work_or_study: 'Робота та навчання',
    money_or_livelihood: 'Гроші та засоби існування',
    reception_allowance: 'Грошова допомога / PFS карта',
    my_money_is_not_enough: 'Моїх грошей недостатньо',
    related_to_your_own_or_family_life_situation:
    'Обставини, пов\'язані зі мною або моєю сім\'єю',
    legal_matter: 'Правова допомога',
    other_practical_matters: 'Інші практичні питання',
    emigration_or_holiday_outside_Finland:
      'Еміграція або відпочинок за межами Фінляндії',
    free_time: 'Вільний час',
    housing: 'Житлова допомога',
    child_care: 'Діти',
    kindergarten_or_school: 'Дитячий садочок або школа',
    reception_centre_info_service: 'Інформаційна служба приймального центра',
    child_health: 'Здоров\'я',
    upbringing_parenting: 'Виховання/Батьківство',
    help_need_today: 'Терміново, необхідно вирішити cьогодні',
    help_need_in_couple_of_days: 'Протягом пару днів',
    help_is_not_urgent: 'Не терміново',
    call_now: 'Подзвонити зараз',
    book_an_appointment: 'Записатись на консультацію',

    help_need_in_week: 'Не терміново',
    copy_email: 'Скопіювати пошту',
    successfully_copied: 'E-mail скопійовано успішно',

    thanks_for_booking: 'Дякуємо за бронювання.',
    prebook_created_at: 'Ваша відеозустріч підтверджена на:',
    prebooking_skill: 'Тема:',
    you_need_high_network: 'Для успішного підключення переконайтеся, що у вас є стабільне та швидке інтернет-з\'єднання, достатнє для відеоконференції.',
    you_will_got_accept: 'Ви отримаєте підтвердження бронювання та по два нагадування через смс та електронну пошту.',
    message_with_link_to_meeting: 'У повідомленнях буде посилання, через яке ви зможете перейти на сторінку відеозустрічі у призначений час.',
    dont_forget_decline_prebooking: 'Не забудьте скасувати зустріч, якщо ви не зможете бути присутні.',
    for_decline_need: 'Щоб скасувати бронювання, перейдіть до:',
    click_for_decline_prebooking: 'і натисніть кнопку ВИДАЛИТИ на потрібному бронюванні.', 
    best_regards: 'З найкращими побажаннями,',
    luona_DigiVOK: 'Luona DigiVOK',

    physical_or_mental_symptoms: 'Охорона здоров\'я',
    related_to_money_or_livelihood: 'Грошове забезпечення',
    everyday_practical_things: 'Інші практичні питання',
    service_housing: 'Житлова допомога',
    how_do_you_judge_the_criticality_of_your_case:
      'Як ви оцінюєте критичність вашої справи?',
    authtorize_yourself: 'Авторизуйся',
    user_successfully_registered: 'Авторизація успішна',
    book_time: 'Забронювати час',
    what_time_works_for_you: 'Який час буде для вас зручний?',
    wizard_default_message:
      'Якщо ситуація загрожує життю, телефонуйте за номером 112.\nЯкщо здоров\'я не може дочекатися наступного робочого дня, зателефонуйте за номером екстреної допомоги 116117.\nЯкщо у вас ще одна гостра криза, зателефонуйте до соціально-кризової служби у вашій громаді.',
    wizard_welcome_message:
      'Вітаємо в сервісі Luona! Ми зробимо усе, щоб вам допомогти. Чим можемо допомогти?',
    wizard_TimeStep_header:
      'На жаль, зараз ніхто не зміг відповісти. Записатися на прийом можна тут.',
    wizard_PrebookingStep_header:
      'Ми прагнемо вирішувати ситуації негайно. Якщо всі наші співробітники заброньовані, у вас є можливість записатися на зустріч. Хочете записатись на консультацію або подзвонити прямо зараз?',
    back: 'Назад',
    rejoin_meeting: 'Повернутись до консультації',
    change_language: 'Змінити мову',
    next: 'Далі',
    go_to_prebookings_list: 'Переглянути бронювання консультацій',
    luona_feedback_title: 'Анкета-опитування якості',
    luona_feedback_description: 'Ви скористалися новою технологічною платформою дістанційного обслуговування Luona. Ми збираємо відгуки про сервіс, і ми були б вдячні, якщо ви відповісте на наше коротке опитування.',
    luona_feedback_score: 'Наскільки йомовірно, що ви порекомендуєте цю платформу віддаленого обслуговування своєму другові?',
    luona_feedback: 'Відгук',
    feedback_sent: 'Дякуємо за відгук!',
    luona_prefer: 'Пільги',
    Luona_lead: 'Розпорядча служба',
    luona_social: 'Соціальна служба',
    luona_nurses: 'Медична служба',
    go_to_services: 'Повернутись до сервісів',
    start_prebook: 'Старт',
    prebooking_is_gone: 'Консультацій закінчилася',
    prebooking_not_answered: 'Нам дуже шкода, але ми не можемо обслужити цю консультацію. Будь ласка, спробуйте замовити консультацію з фахівцем ще раз.',
    prebooking_is_not_found: 'На жаль, ми не можемо знайти відповідного активного бронювання. Будь ласка, спробуйте здійснити терміновий дзвінок або забронювати консультацію з фахівцем ще раз.',
    prebooking_is_cancelled: 'На жаль, ми не можемо знайти відповідного активного бронювання. Можливо він був скасований. Будь ласка, спробуйте здійснити терміновий дзвінок або забронювати консультацію з фахівцем ще раз.',
    prebooking_is_too_late: 'На жаль, час початку консультації пройшов. Після очікування перекладачі були перенаправлені на обслуговування термінових викликів. Будь ласка, спробуйте здійснити терміновий дзвінок або забронювати консультацію з фахівцем ще раз.',
    prebooking_will_start_in: 'Початок вашої зустрічі через:',
    early_preview_intro: 'Шановний клієнт Luona!',
    you_can_close_tab_for_now: 'Ви можете поки що закрити цю вкладку.',
    my_prebooking_list: 'Мій список консультацій',
    waiting_time: 'Час очікування',
    connecting_to_meeting: 'Підключення до зустрічі',
    no_access_to_your_camera_or_microphone: 'Немає доступу до вашої камери або мікрофону',
    errorTitle: 'Помилка',
    warnTitle: 'Щось пішло не так...',
    sessionExpired: 'Щось пішло не так...',
    meeting_closed: 'Консультацію закінчено.',
    signin_code_block_message: 'Ця електронна адреса вже використовувалася раніше. Ми надіслали код підтвердження на вашу електронну адресу: [email]. Будь ласка, введіть код підтвердження в поле нижче.',
    placeholder_signin_code: 'код',
    close_meeting_confirmation_message: 'Ви впевнені, що хочете завершити дзвінок? Для всіх учасників цей дзвінок також буде завершено',
    internet_connection_lost: 'Немає інтернет з\'єднання',
    internet_connection_exist: 'Інтернет з\'єднання відновлено',
    requestErrorMessageReasonPoorInternet: 'Ой! Щось пішло не так. Однією з причин може бути поганий Інтернет. Будь ласка, перевірте налаштування Інтернету!',
    networkDisconnectedMessage: 'Ваше інтернет-з’єднання недостатньо швидке для відеодзвінків. Будь ласка, перевірте налаштування Інтернету!',
    meeting_disconnected: 'З’єднання було втрачено',
    meeting_reconnecting: 'Відновлюємо зв\'язок',
    meeting_reconnected: 'З\'єднання відновлено',
    browser_does_not_support_video_effects: 'Браузер не підтримує відеоефекти'
  },

  ru: {
    luonaSignupForm: 'Форма регистрации',

    label_name: 'Имя и фамилия',
    placeholder_name: 'Только латинские буквы',
    validate_name: 'Введите свои имя и фамилию латинскими буквами',

    label_phone: 'Мобильный номер телефона',
    placeholder_phone: '+3XXXXXXXXXXX',
    validate_phone: 'Введите мобильный номер телефона в формате: +3XXXXXXXXXXX',

    label_email: 'Электронная почта',
    placeholder_email: 'user@mail.com',
    validate_email: 'Введите электронную почту в формате: user@mail.com',

    label_city: 'Город проживания',
    placeholder_city: 'Название города латинскими буквами',
    validate_city: 'Название города латинскими буквами',
    selectOption: 'Выберите город',

    agreePrivacyPolicy: 'Соглашаюсь с ',
    privacyPolicy: 'политикой конфиденциальности',
    luonaSignupButton: 'Отправить',
    requestErrorMessage: 'Что-то пошло не так, пожалуйста попробуйте еще раз.',
    requestErrorMessageUserNotFound: 'Введите зарегистрированные номер телефона и электронный адрес.',
    requestErrorMessageUserExist:
      'Указанная электронная почта или номер мобильного уже используется. Укажите другие данные.',
    slot_not_free_anymore: 'Извините, это время уже не доступно для бронирования',
    no_interpreter_in_prebooking: 'Извините, на данный момент нет доступного переводчика',
    back_to_telegram: 'Назад к боту',
    // meeting room
    pleaseWait: 'Пожалуйста подождите',
    what_is_your_name: 'Как вас зовут?',
    your_name: 'Ваше имя',
    selectLanguage: 'Выберите язык',
    ready_to_join: 'Готовы присоединиться?',
    join_to_meeting: 'Присоединиться',
    type_message: 'Напишите сообщение',
    looking_for: ' Мы стараемся предоставить лучший сервис, но иногда это требует времени. Пожалуйста, подождите подключения',
    luona_worker_persone: 'Luona сотрудника',
    interpreter_persone: 'Переводчика',
    // prebooking luona
    prebooking_page_title: 'Предварительные бронирования',
    defaultPrebookingPageMessage:
      'У вас нет  предварительно забронированных консультаций',
    presence_conference: 'Конференция / Виртуальная комната',
    presence_on_site: 'На сайте',
    presence_remote: 'Удаленно',
    presence_notice: 'Объявление перевода',
    call_type_video: 'Тип видео',
    call_type_call: 'Тип звонка',
    all: 'Все',
    time: 'Время',
    now: 'Сейчас',
    today: 'Сегодня',
    tomorrow: 'Завтра',
    language_title: 'Язык',
    status: 'Статус',
    prebooking_duration: 'Продолжительность',
    call_type_title: 'Тип',
    interpreter_phone_number: 'Телефон переводчика',
    invoice_user_group: 'Группа пользователя',
    customer: 'Клиент',
    customerInvoiceNotes: 'Заметки',
    alternative_cost_center: 'Дополнительный счет',
    reservation_number: '№ бронирования',
    confirmed: 'Подтверждение',
    unconfirmed_prebooking: 'Не подтверждено',
    completed_prebooking: 'Готовность бронирования',
    delivered: 'Доставлено',
    not_able_to_reach: 'Не в состоянии добраться',
    cancelled: 'Отменено',
    active: 'Активно',
    change_reservation_description: 'Редактировать',
    copy_reservation_description: 'Копировать',
    cancel_prebook_description: 'Отменить',
    cancel_prebook_confirmation_message:
      'Вы действительно хотите удалить бронирование?',
    hrs: 'ч',
    min: 'мин',
    no: 'Нет',
    yes: 'Да',
    prebook_successfully_cancelled: 'Бронирование успешно удалено',
    remove: 'Удалить',
    what_is_your_language: 'Здравствуйте, какой у вас язык',
    what_you_need_help_with: 'В чем вам нужна помощь?',
    selectable_langs: 'Язык',
    selectable_servises: 'Услуги',
    health: 'Здоровье',
    work_or_study: 'Работа и образование',
    money_or_livelihood: 'Деньги и средства существования',
    reception_allowance: 'Пособие / PFS карта',
    my_money_is_not_enough: 'Моих денег недостаточно',
    related_to_your_own_or_family_life_situation:
      'Связано с личной или семейной жизненной ситуацией',
    legal_matter: 'Правовая защита',
    other_practical_matters: 'Другие практические вопросы',
    thanks_for_booking: 'Благодарим за ваше бронирование.',
    prebook_created_at: 'Ваша видеовстреча подтверждена:',
    prebooking_skill: 'Тема:',
    you_need_high_network: 'Для успешного подключения убедитесь, что у вас стабильное и быстрое интернет-соединение, достаточное для видеоконференции.',
    you_will_got_accept: 'Вы получите подтверждение бронирования и по два напоминания через смс и электронную почту.',
    message_with_link_to_meeting: 'В сообщениях будет ссылка, по которой вы сможете перейти на страницу видеовстречи в назначенное время.',
    dont_forget_decline_prebooking: 'Не забудьте отменить бронирование, если вы не сможете присутствовать.',
    for_decline_need: 'Чтобы это сделать, зайдите в:',
    click_for_decline_prebooking: 'и нажмите кнопку УДАЛИТЬ на нужном бронировании.', 
    best_regards: 'С наилучшими пожеланиями,',
    luona_DigiVOK: 'Luona DigiVOK',
    emigration_or_holiday_outside_Finland:
      'Эмиграция или отдых за пределами Финляндии',
    free_time: 'Свободное время',
    selectable_time: 'Время',
    selectable_interpreter: 'Консультация проводится на английском или финском языке. Вам нужен переводчик для консультации?',
    authtorize: 'Авторизация',
    choose_time: 'Виберите удобное время для консультации',
    prebook_not_available_message: 'К сожалению, сейчас эта услуга недоступна. Время работы: понедельник - пятница {SCHEDULE}.',
    copy_email: 'Скопировать почту',
    successfully_copied: 'E-mail скопирован успешно',
    housing: 'Жилищный вопрос',
    child_care: 'Дети и забота о них',
    child_health: 'Здоровье',
    upbringing_parenting: 'Воспитание / Отцовство',
    kindergarten_or_school: 'Детский сад или школа',
    reception_centre_info_service: 'Информационная служба приемного центра',
    physical_or_mental_symptoms: 'Здравоохранение',
    related_to_money_or_livelihood: 'Денежное обеспечение',
    everyday_practical_things: 'Повседневные практические вещи',
    service_housing: 'Жилищная помощь',
    how_do_you_judge_the_criticality_of_your_case:
      'Как вы оцениваете критичность вашего случая?',

    help_need_today: 'Срочно, необходимо решить сегодня',
    help_need_in_couple_of_days: 'В течении пару дней',
    help_is_not_urgent: 'Не срочно',
    call_now: 'Позвонить сейчас',
    book_an_appointment: 'Записаться на консультацию',

    help_need_in_week: 'Помощь нужна в течение недели',
    authtorize_yourself: 'Авторизируйся',
    user_successfully_registered: 'Авторизация успешна',
    book_time: 'Забронировать время',
    what_time_works_for_you: 'Какое время будет для вас удобно?',
    wizard_default_message:
      'Если ситуация угрожает вашей жизни, звоните по телефону 112. Если состояние здоровья не может ждать следующего рабочего дня, звоните по телефону экстренной помощи 116117. Если у вас любая другая острая ситуация, звоните в социально-кризисную службу в вашей общине.',
    wizard_welcome_message:
      'Добро пожаловать в сервис Luona! Мы постараемся сделать все, чтобы вам помочь. Как можем помочь?',
    wizard_TimeStep_header:
      'К сожалению сейчас все сотрудники заняты, но вы можете записаться на прием здесь.',
    wizard_PrebookingStep_header:
      'Мы стараемся решать вопросы немедленно. Если все наши сотрудники забронированы, вы можете записаться на встречу. Хотите записаться на прием или позвонить прямо сейчас?',
    back: 'Назад',
    rejoin_meeting: 'Вернуться к консультации',
    change_language: 'Изменить язык',
    next: 'Дальше',
    go_to_prebookings_list: 'Просмотр бронирования консультаций',
    luona_feedback_title: 'Анкета-опрос качества',
    luona_feedback_description: 'Вы воспользовались новой технологичной платформой дистанционного обслуживания Luona. Мы собираем отзывы про сервис, и мы были бы благодарны, если вы ответите на наш короткий опрос.',
    luona_feedback_score: 'Насколько вероятно, что вы порекомендуете эту платформу удаленного обслуживания своему другу?',
    luona_feedback: 'Отзыв',
    feedback_sent: 'Спасибо за отзыв!',
    luona_prefer: 'Льготы и пособия',
    Luona_lead: 'Распорядительная служба',
    luona_social: 'Социальная служба',
    luona_nurses: 'Медицинская служба',
    go_to_services: 'Вернуться к сервисам',
    start_prebook: 'Старт',
    prebooking_is_gone: 'Консультация закончилась',
    prebooking_not_answered: 'К сожалению, мы не можем обслужить эту консультацию. Запишитесь на консультацию к специалисту повторно',
    prebooking_is_not_found: 'К сожалению, мы не можем найти соответствующего активного бронирования. Пожалуйста, попробуйте совершить срочный звонок или забронировать конультацию со специалистом еще раз.',
    prebooking_is_cancelled: 'К сожалению, мы не можем найти соответствующего активного бронирования. Возможно он был отменен. Пожалуйста, попробуйте совершить срочный звонок или забронировать конультацию со специалистом еще раз.',
    prebooking_is_too_late: 'К сожалению, время старта пребука прошло. После ожидания переводчики были перенаправлены на обслуживание срочных вызовов.  Пожалуйста, попробуйте совершить срочный звонок или забронировать конультацию со специалистом еще раз.',
    prebooking_will_start_in: 'Начало вашей встречи через:',
    early_preview_intro: 'Уважаемый клиент Luona!',
    you_can_close_tab_for_now: 'Вы можете пока закрыть эту вкладку.',
    my_prebooking_list: 'Мой список консультаций',
    waiting_time: 'Время ожидания',
    connecting_to_meeting: 'Подключение к встрече',
    no_access_to_your_camera_or_microphone: 'Нет доступа к вашей камере или микрофону',
    errorTitle: 'Ошибка',
    warnTitle: 'Что-то пошло не так...',
    sessionExpired: 'Что-то пошло не так...',
    meeting_closed: 'Консультация завершена.',
    signin_code_block_message: 'Этот e-mail уже использовался ранее. Мы отправили код подтверждения на ваш адрес электронной почты: [email]. Пожалуйста, введите проверочный код в поле ниже.',
    placeholder_signin_code: 'код',
    close_meeting_confirmation_message: 'Вы уверены, что хотите завершить звонок? Для всех участников этот звонок также будет завершён',
    internet_connection_lost: 'Нет интернет соединения',
    internet_connection_exist: 'Интернет соединение восстановлено',
    requestErrorMessageReasonPoorInternet: 'Ой! Что-то пошло не так. Одной из причин может быть плохой интернет. Пожалуйста, проверьте настройки интернета!',
    networkDisconnectedMessage: 'Ваше интернет-соединение недостаточно быстрое для видеозвонков. Пожалуйста, проверьте настройки Интернета.',
    meeting_disconnected: 'Соединение было потеряно',
    meeting_reconnecting: 'Восстанавливаем связь',
    meeting_reconnected: 'Соединение восстановлено',
    browser_does_not_support_video_effects: 'Браузер не поддерживает видеоэффекты'
  },

  ar: {
    wizard_welcome_message: ' مرحباً! معاً نقدم خدمات إجتماعية ذو أهمية. كيف نساعدكم؟',
    wizard_default_message: 'في حالة وجود التهديد على الحياة اتصل برقم 112.في حالة وجود مشكلة صحية لديك ولا يمكنك الإنتظار لليوم التالي اتصل بالطوارئ على 116117. في حالة وجود أزمات اخرى مُستعجلة اتصل بمركز الخدمات الإجتماعية والأزمات بمكان إقامتك.    ',
    wizard_PrebookingStep_header: 'نحن نسعى جاهدين للتعامل مع كل الحالات على الفور. في حالة إنشغال كل موظفينا، ستتاح لك الفرصة لحجز موعد لنفسك هنا. هل تريد حجز موعد او الإتصال حالاً؟ ',
    wizard_TimeStep_header: 'للأسف لم يتم الإجابة من قِبل اي شخص. تستطيع حجز موعد من هنا:',
    back: 'رجوع',
    next: 'التالي',
    authtorize: 'إستمارة تسجيل الدخول',
    label_name: 'الإسم والكنية (اللقب)',
    placeholder_name: 'الاسم بالأحرف اللاتينية',
    label_phone: 'رقم الهاتف',
    placeholder_phone: '+3XXXXXXXXXXX',
    label_email: 'البريد الإلكتروني ',
    placeholder_email: 'user@mail.com',
    agreePrivacyPolicy: 'موافق',
    privacyPolicy: 'سياسة الخصوصية',
    signin_code_block_message: 'هذا البريد الإلكتروني قيد الإستخدام. سوف نقوم بإرسال رمز التفعيل لهذا العنوان [email]. قم بكتابة الرمز في الحقل/المكان أدناه.',
    placeholder_signin_code: 'الرمز',
    requestErrorMessage: 'هناك خلل حاول مجدداً!',
    requestErrorMessageUserNotFound: 'ادخل رقم الهاتف والبريد الإلكتروني المسجل.',
    requestErrorMessageUserExist: 'البريد الإلكتروني او رقم الهاتف الذي قمت بإعطائه مسجل مسبقاً، قم بإدخال معلومات اخرى من فضلك.',
    user_successfully_registered: 'تم التسجيل بنجاح',
    selectable_servises: 'الأمر يتعلق بـ',
    health: 'الصحة',
    work_or_study: 'العمل او الدراسة',
    money_or_livelihood: 'المال او الدخل',
    reception_allowance: 'المساعدة النقدية او كارت PFS',
    my_money_is_not_enough: 'المال لا يكفيني',
    related_to_your_own_or_family_life_situation: 'الأوضاع الحياتية  الخاصة او أوضاع تتعلق بالأُسرة',
    legal_matter: 'مسألة قانونية',
    other_practical_matters: 'مسائل أُخرى',
    emigration_or_holiday_outside_Finland: 'الإنتقال لخارج البلد او أخذ إجازة خارج فنلندا',
    free_time: 'اوقات الفراغ',
    housing: 'السكن',
    child_care: 'الأطفال',
    kindergarten_or_school: 'الروضة او المدرسة',
    child_health: 'الصحة',
    upbringing_parenting: 'التربية / الأبوة و الآمومة',
    reception_centre_info_service: 'إستشارة مركز الإستقبال',
    selectable_interpreter: 'اللغة المستخدمة اثناء الاجتماع هي الإنجليزية اوالفنلندية. هل تحتاج لمترجم؟',
    call_now: 'اتصل على الفور',
    book_an_appointment: 'إحجز موعد',
    connecting_to_meeting: 'سيتم الربط بالاجتماع',
    waiting_time: 'وقت الإنتظار هو',
    looking_for: 'ضمان الخدمة الجيدة يحتاج احياناً  لبعض الوقت. انتظر لحظة. يتم البحث.',
    luona_worker_persone: 'موظف Luona',
    interpreter_persone: 'المترجم',
    pleaseWait: 'انتظر لحظة',
    what_is_your_name: 'ما اسمك؟',
    your_name: 'اسمك',
    selectLanguage: 'اختر لغة',
    ready_to_join: 'جاهز للإنضمام؟',
    join_to_meeting: 'انضم للاجتماع',
    type_message: 'اكتب رسالة',
    rejoin_meeting: 'إنضم للاجتماع مجدداً',
    no_interpreter_in_prebooking: 'ليس هناك مترجم في الموعد المحجوز',
    browser_does_not_support_video_effects: 'المتصفح لا يدعم تأثيرات الفيديو',
    prebooking_not_answered: 'للأسف لا يمكننا إيصالك بالموعد الذي حجزته مسبقاً. قم بحجز موعد اخر مع المختص.',
    prebooking_is_not_found: 'لم يتم العثور على موعد نشط. قم بحجز موعد جديد مع المختص.',
    prebooking_is_cancelled: 'لم يتم العثور على موعد نشط، ربما قد تم إلغاء الحجز. قم بحجز موعد جديد مع المختص.',
    prebooking_is_too_late: ' الوقت المحجوز إستغرق مدة طويلة، مما أدى لإنتقال المترجم لمكالمة اخرى. قم بحجز موعد جديد مع المختص.',
    prebooking_will_start_in: 'إبتداء حجزك بعد:',
    early_preview_intro: 'عزيزي عميل Luona',
    you_can_close_tab_for_now: 'يمكنك إغلاق نافذة التبويب في الوقت الحالي.',
    no_access_to_your_camera_or_microphone: ' لم تعطي الإذن لجهازك بإستخدام الكاميرا أو الميكروفون',
    errorTitle: 'خطأ',
    warnTitle: 'هنالك خطأ ما ...',
    sessionExpired: 'إنتهت صلاحية الجلسة. الرجاء تسجيل الدخول مجدداً.',
    meeting_closed: 'إنتهت المقابلة.',
    close_meeting_confirmation_message: 'هل انت متأكد انك تريد الانسحاب من المقابلة؟ سيتم وقتها إنهاء المقابلة من قِبَل كل الجهات المشاركة.',
    internet_connection_lost: 'انقطع الاتصال بالشبكة.',
    internet_connection_exist: 'تم الإتصال بالشبكة مجدداً.',
    requestErrorMessageReasonPoorInternet: 'عذراً! هناك خطأ ما. قد يكون الإتصال بالشبكة سيء. تحقق من إعدادات الإتصال!',
    networkDisconnectedMessage: 'الإتصال بالإنترنت ليس سريعاً بما يكفي لمكالمات الفيديو. تحقق من إعدادات الإتصال!',
    meeting_reconnecting: 'نعيد الإتصال مُجدداً',
    meeting_reconnected: 'انقطع الإتصال بالشبكة',
    prebook_not_available_message: 'الخدمة غير متوفرة حالياً. مواعيد الخدمة من الاثنين الى الجمعة {SCHEDULE}.',
    choose_time: 'حدد الوقت للحجز',
    slot_not_free_anymore: 'للأسف، الوقت الذي اخترته لم يعد متاحاً حالياً',
    thanks_for_booking: 'شكراً على حجز الموعد!',
    prebook_created_at: 'تم تأكيد مقابلة الفيديو على النحو التالي:',
    prebooking_skill: 'الموضوع',
    you_need_high_network: 'لضمان نجاح الإتصال وجودتهِ، يرجى التأكد من ان شبكة الإنترنت سريعة ومستقرة.',
    you_will_got_accept: 'ستتلقى تأكيداً للحجز اضافةً لرسالتين للتذكير و بريد الكتروني.',
    message_with_link_to_meeting: 'الرسالة تحتوي على رابط ينقلك الى صفحة الإجتماع في الوقت المحدد.',
    dont_forget_decline_prebooking: 'لا تنسى إلغاء الموعد المحجوز، في حالة عدم ملائمة التوقيت لك.',
    for_decline_need: 'يمكنك إلغاء حجزك من خلال: ',
    click_for_decline_prebooking: 'و إضغط على زر "الحذف" ',
    best_regards: 'مع أطيب التحيات',
    luona_DigiVOK: 'Luona DigiVOK',
    my_prebooking_list: 'حجوزاتي',
    luona_feedback_title: 'الإستفسار عن الجودة',
    luona_feedback_description: 'لقد استفدت من المنصة الإلكترونية للخدمة عن بُعد الخاصة بـ Luona. نقوم بجمع الإنطباعات عن الخدمة وسنكون ممتنين لو قمتم بلإجابة على الإستبيان القصير.',
    luona_feedback_score: 'ما مدى إحتمالية التوصية بمنصة الخدمة عن بعد لأصدقائك؟',
    luona_feedback: 'تعليق/ملاحظة',
    feedback_sent: 'شكراًعلى ملاحظاتك!',
    luonaSignupButton: 'إرسال',
    prebooking_page_title: 'الحجوزات',
    defaultPrebookingPageMessage: 'ليس لديك حجز',
    go_to_services: 'إنتقل للخدمة',
    start_prebook: 'البدء',
    remove: 'الإزالة',
    cancel_prebook_confirmation_message: 'هل انت متأكد انك تريد الغاء الموعد؟',
    hrs: 'h (ساعة، إختصار)',
    min: 'min ( دقائق، إختصار)',
    no: 'لا',
    yes: 'نعم',
    prebook_successfully_cancelled: 'تم إلغاء الموعد بنجاح',
    now: 'حالاً',
    today: 'اليوم',
    tomorrow: 'غداً',
    luona_prefer: 'فوائد',
    Luona_lead: 'قائد المجموعة',
    luona_social: 'اجتماعي',
    luona_nurses: 'صحة',
    ok: 'نعم',
    cancel: 'يلغي'
  },

  ku: {
    wizard_welcome_message: 'سڵاو! پێکەوە خزمەتگوزاریەکی کاریگەردرووست ئەکەین. چۆن ئەتوانین یارمەتیدان بدەین؟',
    wizard_default_message: 'لەکاتی بوونی مەترسی لەسەر ژیان پەیوەندی بکە بە ژمارە112.لەکاتی بوونی گیروگرفتێکی تەندروستی پەیوەندی بکە ژمارەی بەفریاگوزاری116117. لەکاتی بوونی حاڵاتی تری نائاسایی  پەیوەندی بکە بە بنکەی فریاگوزاری قەیرانەکان لەشوێنی نیشتەجێبوونت',
    wizard_PrebookingStep_header: 'ئێمە هه وڵ دەدەین کەڕەنگدانەوە و هه ڵسوکەوتمان لەگەڵ هه رحاڵەتێک لەسەر بناغەی جۆری حاڵەتەکەبێت.لەکاتێکدا ئەگەرکارمەندت دەست نەکەوت کات بگرە بۆ خۆت. دەتەوێت کاتێک بگریت هه ر ئێستا ؟',
    wizard_TimeStep_header: 'بە داخەوە کەس وەڵامی نەدایەوە،دەتوانی کات بگریت لێرەوە: ',
    back: 'گەڕانەوە',
    next: 'داهاتوو',
    authtorize: 'فۆرمی تۆمارکردنی چونەژوورەوە',
    label_name: 'ناو و نازناو(لەقەب)',
    placeholder_name: 'ناو بەپیتی لاتینی',
    label_phone: 'ژمارە تەلەفۆن',
    placeholder_phone: '+3XXXXXXXXXXX',
    label_email: 'ئیمەیل',
    placeholder_email: 'user@mail.com',
    agreePrivacyPolicy: 'ڕازیم',
    privacyPolicy: 'ڕمیاری نهێنی پاراستن',
    signin_code_block_message: 'ئەم ئیمەیلە بەکارهاتووە.هه ڵئستین بە ناردنی کۆدێک بۆ ئەم ناونیشانە [email].تکایە هستە بە تۆمارکردنی لە م شوێنەی خوارەوەدا ',
    placeholder_signin_code: 'کۆد',
    requestErrorMessage: 'کێشەیەک هه یه هه وڵبدە جارێکی تر!',
    requestErrorMessageUserNotFound: 'تکایە هه ستە بە نوسینی ئەو ئیمە یل و ژمارە تە لەفۆنەی کە پێشتر تۆمارت کردوە',
    requestErrorMessageUserExist: 'ئەو ئیمە یل و ژمارە تە لەفۆنەی کە نوسیوتە پێشترەۆمارکراوە، تکایە زانیاری جیاواز تۆمار بکە',
    user_successfully_registered: 'چوونەژورەوە  بەسەرکەوتوویی ئنجام درا',
    selectable_servises: 'کارەکە پەیوەندی هه یە بە',
    health: 'تەندروستی',
    work_or_study: 'ئیش وخوێندن',
    money_or_livelihood: 'پارە و بژێوی',
    reception_allowance: 'یارمەتی ئابوری یان کارتی PFS',
    my_money_is_not_enough: '-پارەکانم بەش ناکات',
    related_to_your_own_or_family_life_situation: 'کاروباری خۆم یان کاروباری تایبەت بەخێزانەکەم',
    legal_matter: 'کاروباری یاسایی',
    other_practical_matters: 'کاروباری تر',
    emigration_or_holiday_outside_Finland: '-کۆچکردن یان پشوو لەدەرەوەی فینلەندادا',
    free_time: 'گوێزانەوە بۆ دەرەوەی وڵات یان وەرگرتنی پشوو دەرەوەی فینلەندا',
    housing: 'خانوو',
    child_care: 'مناڵ',
    kindergarten_or_school: 'دایەنگا و قوتابخانە',
    child_health: 'تەندروستی',
    upbringing_parenting: 'بەخێوکردن و دایک و باوکایەتی',
    reception_centre_info_service: 'ڕاوێژکاری بنکەی وەرگرتنی پەنانەران ',
    selectable_interpreter: 'زمانی بەکارهاتوو بۆ چاوپێکەوتنەکە ئینگلیزی یان فینلەندی. پێوەستم بە وەرێڕە',
    call_now: 'هه رئێستا پەیوەندی بکە',
    book_an_appointment: 'کات بگرە',
    connecting_to_meeting: 'پەیوەند',
    waiting_time: 'کاتی چاوەروانی بریتیە لە',
    looking_for: 'بۆدڵنیابوون لە خزمەتگوزاریەکی باش، پێویستمان بە بڕێک کات هه یە',
    luona_worker_persone: 'کارمەندی Luona ',
    interpreter_persone: 'وه رگێر',
    pleaseWait: 'چرکەیەک بوەستە',
    what_is_your_name: 'ناوت چی یە؟',
    your_name: 'ناوت',
    selectLanguage: 'زمانێک هه ڵبژێرە',
    ready_to_join: 'ئایا ئمادەی بۆ کۆبونەوەکە؟',
    join_to_meeting: 'پەیوەند بە بە چاوپێکەوتنەکەوە',
    type_message: 'نامە بنوسە',
    rejoin_meeting: 'دووبارە پەیوەند بە کۆبونەوەکەوە',
    no_interpreter_in_prebooking: 'وەرگێر(متەرجم)نیە بۆ حیجز',
    browser_does_not_support_video_effects: 'وێبگەڕ پشتگیری لە کاریگەرەکانی ڤیدیۆ ناکات',
    prebooking_not_answered: 'بەداخەوە ناتوانین تۆ بگەیەنین بە کاتەکەی کە پێشوەخت گرتوتە. تکایە هه ڵستە بە گرتنی کاتێکی تازە لەگەڵ شارەزایەک',
    prebooking_is_not_found: 'هیچ کاتێکی پێشت وەخت گیراو دیار نیە. تکایە هه ڵستە بە گرتنی کاتێکی تر لەگەڵ شارەزایەک',
    prebooking_is_cancelled: 'هیچ کاتێکی پێشت وەخت گیراو دیار نیە. ڕەنگە کاتەکەت هه ڵوەشانێتەوە. تکایە هه ڵستە بە گرتنی کاتێکی کە لەگەڵ شارەزایەک',
    // eslint-disable-next-line no-useless-escape
    prebooking_is_too_late: 'کاتە دیاریکراوەکەت زۆی خایاند، کە بوووە هۆی ئەوەی وەگێرەکە\مترجمە کە بگوێزرێتەوە بۆ پەیوەندیەکی تر.تکایە هه ڵستە بە گرتنی کاتێکی کە لەگەڵ شارەزایەک',
    prebooking_will_start_in: 'دەستپێکی چاوپێکەوتنەکەت لەمانەدایە:',
    early_preview_intro: 'بەڕێز پەیوەندیداری Luona! ',
    you_can_close_tab_for_now: 'ئەتوانی پەنجەرەی تاپەکەت داخەی بەتەواوەتی',
    no_access_to_your_camera_or_microphone: 'ڕێگەت نەداوە بە ئامێرەکەت کە کامێرا و میکرۆفۆنەکەت بەکار بێت',
    errorTitle: 'هه ڵە',
    warnTitle: 'هه ڵەیەک هه یە',
    sessionExpired: 'کاتی چاوپێکەوتنەکە بەسەرچوو. تکایە جارێکی تر چونەژوورەوە تۆماربکە',
    meeting_closed: 'چاوپێکەوتنەکە کۆتایی پاێهات',
    close_meeting_confirmation_message: 'ئایا تۆ دڵنیای کە ئەتەوێ بکشێیتەوە لە چاوپێکەوتنەکە؟لە و کاتەدا چاوپێکەوتنەکە کۆتایی پێ دێت لە لایەن هه موو لایەنە بەشداربۆکانەوە',
    internet_connection_lost: 'پەیوەندیەکە ەپچڕا',
    internet_connection_exist: 'پەیوەندیەکە گەڕایەوە',
    requestErrorMessageReasonPoorInternet: 'ببورە!هه ڵەیەک ڕوویدا.یەکێک لە هۆکارەکان بۆی هه یە ئینترنێتی خراپ بێت. تکایە چاو بە ئینترنێتەکەتا بخشێنەرەوە.',
    networkDisconnectedMessage: 'پەیوەندی ئینترنێتەکە تئەوەندە خێرا نی یە کەگونجاو بێت بۆ پەیوەندی ڤیدێۆیی.تکایە ئاگاداری خەتی ئینترنێتەکەت بە ',
    meeting_reconnecting: 'پەیوەندی دەکەینەوە جارێکی تر',
    meeting_reconnected: 'پەیوەندیە ڤیدێوێکە پچڕا',
    prebook_not_available_message: 'خزمەت گوزاریەکە بەردەست نی یە .کاتەکانی خزمەت گوزاری لە دووشەم تا هه ینی{SCHEDULE}.',
    choose_time: 'بەرواری چاوپێکەوتنەکەت دیاری بکە',
    slot_not_free_anymore: 'بەداخەوە بەرواری ئەو ڕۆژانەی کە هه ڵتبژاردوە بەردەست نیە لە ئێستادا',
    thanks_for_booking: 'سوپاس بۆکاتگرتنەکەت!',
    prebook_created_at: 'پەیوەندیە ڤیدێوێکەت تۆمار کرا بەمشێوەیە:',
    prebooking_skill: 'بابەت',
    you_need_high_network: 'بۆ ئەوەی پەیوەندیەکی سەرکەوتوو ئەنجام بدەی دڵنیابە کە هێڵی ئینتەرنێتێکی جێگیر وخێرات هه یە کە گونجاوە بۆ پەیوەندی ڤیدیو',
    you_will_got_accept: 'نامەی دڵنیابوونی کاتگرتنەکەت بە دەست دەگات دواتر دوو نامەی بیرخستنەوەو ئیمێل',
    message_with_link_to_meeting: 'نامەکە لینکێک لەخۆی ئەگرێ ئتگوێزێتەوە بۆ پەڕەی چاوپێکەوتنە ڤیدیۆکەت لەکاتی دیاریکراودا',
    dont_forget_decline_prebooking: 'هه ڵوەشندنەوەی کاتاکەت لە بیرنەکەی ئەگەر بەرواری کاتەگیراوەکە بۆ تۆ گونجاو نەبوو ',
    for_decline_need: 'بۆ هه ڵوەشندنەوە ی کاتە گیراوەکەت:',
    click_for_decline_prebooking: 'دەستبنێ بەم دوگمەیەدا',
    best_regards: 'لەگەڵ ڕێزوسڵاوی',
    luona_DigiVOK: 'Luona DigiVOK',
    my_prebooking_list: 'کاتە گیراوەکانم',
    luona_feedback_title: 'هه ڵسانگاندنی کوالیتی',
    luona_feedback_description: 'تو سودت لەبەکارهێنانیدووری ئەم بنکە ئەلکترۆنیە کردوەLuona.ئێمە هه ڵئستین بەکۆکردنەوەی تێبینیەکان دەربارەی ئەم بنکەیە وە زۆر سوپاستان ئکەین ئەگەر بتوانن وەڵامی ئەم ڕاپرسیە کورتە بدەنەوە',
    luona_feedback_score: 'تاچەن پێشنیاری بەکارهێنانی ئەم بنکە خزمەتگوزاریە ئەکەیت بۆ هاوڕێکانت؟',
    luona_feedback: 'تێبینی',
    feedback_sent: 'سوپاس بۆتێبینیەکانت!',
    luonaSignupButton: 'ناردن',
    prebooking_page_title: 'کاتەگیراوەکانت',
    defaultPrebookingPageMessage: 'کاتی گیراوت نی یە',
    go_to_services: 'بڕۆبۆ خزمەتگوزاریەکە ',
    start_prebook: 'دەستپێکردن',
    remove: 'لابردن',
    cancel_prebook_confirmation_message: 'ئایا دڵنیای لە ویستی هه ڵوەشاندنەوەی کاتەتەت؟',
    hrs: 'کورتکراوەی کاتژمێر',
    min: 'کورتکراوەی چرکە',
    no: 'نەخێر',
    yes: 'بەڵێ',
    prebook_successfully_cancelled: 'هه ڵوەشندنەوەی کاتاکە بەسەرکەوتویی ئەنجامدرا',
    now: 'هه رئێستا',
    today: 'ئەمڕۆ',
    tomorrow: 'بەیانی',
    luona_prefer: 'سوودەکان',
    Luona_lead: 'سەرۆکی کۆمەڵە',
    luona_social: 'کۆمەڵایەتی',
    luona_nurses: 'تەندروستی',
    ok: 'باشە',
    cancel: 'ڕەتکردنەوە'
  },

  en_dailuge: {
    loginTitle: 'Sign in',
    username: 'Phone number',
    password: 'Password',
    loginButton: 'Sign in',
    loginFailed: 'Signin failed',
    sessionExpired: 'Session has expired. Please sign in again.',

    errorTitle: 'Oops!',
    warnTitle: 'Hmmm...',
    successTitle: 'Great!',

    selectLanguage: 'Choose city',
    interpreter: 'Host',
    startInterpreting: 'Start video chat',
    requestInterpreting: 'Request video chat',

    interpretationNeeded: 'Help needed',
    requestForInterpretation: 'An active chat request available.',
    nothingToInterpret: 'No active chat requests.',
    noInterpretationNeeded: 'All requests have been answered.',

    doYouWantToHangUp: 'Do you really want to hang up?',
    oppositeSideHangsUp: 'The other person has left the conversation.',
    networkTerminated: 'The conversation was disconnected.',
    review: 'Review',
    customerFeedbackEnjoyingTulka: 'Satisfied with the service?',
    customerRatingInterpreter: 'How would you rate the service quality',
    customerInvoiceNotes: 'Invoice reference',
    interpreterRatingInterpreter: 'How well did I do',
    interpreterRatingCustomer: 'Your review about the traveller',
    ratingQuality: 'Technical quality',
    feedback: 'Feedback',
    feedbackDesc: 'Write your comments here',
    feedbackContactMe: 'Please contact me',
    proceed: 'Send',
    skip: 'Skip',

    endCall: 'End call',
    refresh: 'Refresh',
    logout: 'Log out',
    toggleCamera: 'Toggle camera',
    toggleAudio: 'Toggle audio',

    onlyChromeSupported: 'Browser not supported',
    pleaseUseChrome: 'Please install Google Chrome to use Ohjaa! Web App',
    downloadChrome: 'Click here to install',
    continueInterpretation: 'Continue video chat',
    roomInfoCallEndedClosed: 'Server closed the conversation.',
    feedbackTooLate: 'Continue option no longer available!',

    noTimeLeft: 'You have used all purchased chat time.',
    minutesConsumed: 'You have now used all purchased chat time.',
    timeLeft: 'Purchased chat time left: {}',
    zeroTimeLeft: 'No purchased chat time left.',
    freeUseLeft: 'Time left',

    failedToSendFeedback: 'Failed to send feedback!',
    failedLogOut: 'Failed to log out.',
    failedToCreateChatroom: 'Failed to start a chat!',
    failedToConnect: 'Failed to connect to the server.',
    waitingForInterpreter: 'Your request was sent to the hosts.',
    waitingForCustomer: 'Waiting for customer...',
    pleaseWait: 'Please wait...',
    youHaveWaitedFor: 'You\'ve waited {}',
    error500: 'Something went wrong. Please try again.',
    roomInfoHasAudioPacketLoss: 'Bad Connection!',

    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',

    default: 'Default',
    termsOfUse: 'Terms of use & Privacy policy',
    options: 'Options',
    hideOptions: 'Hide options',

    enable_notifications: 'Enable notifications',
    disable_notifications: 'Disable notifications',
    disable_duration: 'Disable notification for',
    for_hour: '1 hour',
    for_day: '1 day',
    for_week: '1 week',
    for_month: '1 month',
    for_a_day: '1 day',
    for_days: '{} days',
    for_a_day_an_hour: '1 day, 1 hour',
    for_a_day_hours: '1 day, {} hours',
    for_days_an_hour: '{} days, 1 hour',
    for_days_hours: '{d} days, {h} hours',
    notifications_disabled_for: 'Notifications disabled for\n{}',
    failed_to_disable_notifications:
      'Failed to disable notifications. Please try again later.',
    failed_to_enable_notifications:
      'Failed to enable notifications. Please try again later.',
    forgot_password_link: 'Forgot your password?',
    forgot_password_info:
      'If you have an account, we can send you a new password via SMS. In other cases contact info@tulka.com.',
    placeholder_phone_number: '+35840...',
    phone_number_title: 'Phone number',
    forgot_password_error:
      'The SMS could not be sent. Please contact info@tulka.com',
    forgot_password_success:
      'Thank you. You should receive your password via SMS shortly.',
    skillModalQuestion:
      'Do you need this experience? The waiting time may be longer.',
    targetLanguage: 'Your destination',
    openingTimes: '',
    systemAlert: 'Technical issues with the service. Our apologies.',

    chatFailed: 'Failed to connect to text chat.',
    chatTipInterpreter:
      'Here you can chat with the traveller. All messages are destroyed immediately when the call ends.',
    chatTipCustomer:
      'Here you can chat with the host. All messages are destroyed immediately when the call ends.',
    chatSendFailed: 'Failed to send the message.',
    chatTypeMessage: 'Type a message...',
    invoiceRefMandatory: 'Invoice reference is a mandatory field.',
    invoiceRefUserGroupMandatory: 'Please select a recipient for the invoice.',
    selectInvoiceRefUserGroup: '-- Select --',

    show_feedbacks: 'Ratings',
    show_feedbacks_title: 'How I was rated',
    failedToFetchFeedbacks: 'Failed to fetch the ratings.',
    statistics: 'My interpretations',
    no_statistics: 'No statistics available.',
    stats_disclaimer:
      'The numbers are only estimates and may not exactly match the final pay slip.',
    stats_today: 'Today',
    stats_yesterday: 'Yesterday',
    stats_this_week: 'This week',
    stats_last_week: 'Last week',
    stats_this_month: 'This month',
    stats_last_month: 'Last month',
    stats_this_year: 'This year',
    stats_last_year: 'Last year',

    feedbackButton: 'Tap to write comments',
  },

  zh_dailuge: {
    loginTitle: '登录',
    username: '手机号码',
    password: '密码',
    loginButton: '登录',
    loginFailed: '登录失败',
    sessionExpired: '你已退出。请再次登录。',

    errorTitle: '出现错误！',
    warnTitle: '可能遇到了问题...',
    successTitle: '完成！',

    selectLanguage: '旅行者',
    interpreter: '旅行达人',
    startInterpreting: '点击开始通话',
    requestInterpreting: '请求通话',

    interpretationNeeded: '你的信息\n帮助了其他旅行者\n非常感谢！',
    requestForInterpretation: '你的信息\n帮助了其他旅行者\n非常感谢！',
    nothingToInterpret: '通话完成',
    noInterpretationNeeded: '通话完成',

    doYouWantToHangUp: '确定退出？',
    oppositeSideHangsUp: '"对方通话已终断。',
    networkTerminated: 'The conversation was disconnected.',
    review: '反馈',
    customerFeedbackEnjoyingTulka: '喜欢Ohjaa的服务？',
    customerRatingInterpreter: '你对此次旅行达人服务的评分？ ',
    customerInvoiceNotes: '你的反馈将被一同显示',
    interpreterRatingInterpreter: '你对用户的评分',
    interpreterRatingCustomer: '你对用户的评分',
    ratingQuality: '你对此次通话质量的评分？ ',
    feedback: '反馈',
    feedbackDesc: '在这里留下你的反馈',
    feedbackContactMe: '请联系我们',
    proceed: '发送',
    skip: '跳过',

    endCall: '结束通话',
    refresh: '刷新',
    logout: '退出',
    toggleCamera: '切换摄像头',
    toggleAudio: 'Toggle audio',

    onlyChromeSupported: '只支持谷歌Chrome浏览器 ',
    pleaseUseChrome: '使用Ohjaa服务的时候请选择谷歌Chrome浏览器!',
    downloadChrome: '下载谷歌浏览器',
    continueInterpretation: '繼續傳譯',
    roomInfoCallEndedClosed: 'Server closed the conversation.',
    feedbackTooLate: 'Continue option no longer available!',

    noTimeLeft: '购买的通话时间已用尽',
    minutesConsumed: '购买的通话时间已用尽',
    timeLeft: '剩余通话时间: {}',
    zeroTimeLeft: '剩余通话时间为0。',
    freeUseLeft: '剩余通话时间',

    failedToSendFeedback: '发送反馈失败',
    failedLogOut: '退出失败',
    failedToCreateChatroom: '连接通话失败',
    failedToConnect: '连接失败.',
    waitingForInterpreter: '你的通话请求已发送，匹配通话将会花1-2分钟的时间。',
    waitingForCustomer: '等待用户中',
    pleaseWait: '请稍候',
    youHaveWaitedFor: '你已等待 {}',
    error500: '发生错误。请重试。',
    roomInfoHasAudioPacketLoss: '連線有問題',

    yes: '是',
    no: '否',
    cancel: '取消',

    default: 'Default',
    termsOfUse: '使用条款',
    options: '选项',
    hideOptions: '选项',

    enable_notifications: '启用通知',
    disable_notifications: '禁用通知',
    disable_duration: '禁用通知',
    for_hour: '一小时',
    for_day: '一天',
    for_week: '一周',
    for_month: '一个月',
    for_a_day: '1天',
    for_days: '{} 天',
    for_a_day_an_hour: '1天, 1小时',
    for_a_day_hours: '1天, {} 小时',
    for_days_an_hour: '{} 天, 1小时',
    for_days_hours: '{d} 天, {h} 小时',
    notifications_disabled_for: '禁用通知\n{}',
    failed_to_disable_notifications: '禁用通知失败. 请稍后再次尝试.',
    failed_to_enable_notifications: '启用通知失败. 请稍后再次尝试.',
    forgot_password_link: '忘记密码？',
    forgot_password_info: '如果你已经注册，我们将会通过手机短信发送新密码。',
    forgot_password_error: '短信无法发送，请联系我们',
    forgot_password_success: '谢谢！你很快将会通过短信收到新密码。',
    placeholder_phone_number: '手机号码',
    phone_number_title: 'Phone number',
    skillModalQuestion:
      'Do you need this experience? The waiting time may be longer.',
    targetLanguage: 'Your destination',
    openingTimes: '',
    systemAlert: '遇到技术故障，给您带来不便请见谅',
    chatFailed: '启动文字聊天服务失败',
    chatTipInterpreter: 'H在这里和旅行者聊天',
    chatTipCustomer: '在这里和旅行达人聊天',
    chatSendFailed: '发送信息失败',
    chatTypeMessage: '写信息…',
    invoiceRefMandatory: 'Invoice reference is a mandatory field.',
    invoiceRefUserGroupMandatory: 'Please select a recipient for the invoice.',
    selectInvoiceRefUserGroup: '-- Select --',

    show_feedbacks: 'Ratings',
    show_feedbacks_title: 'How I was rated',
    failedToFetchFeedbacks: 'Failed to fetch the ratings.',
    statistics: 'My interpretations',
    no_statistics: 'No statistics available.',
    stats_disclaimer:
      'The numbers are only estimates and may not exactly match the final pay slip.',
    stats_today: 'Today',
    stats_yesterday: 'Yesterday',
    stats_this_week: 'This week',
    stats_last_week: 'Last week',
    stats_this_month: 'This month',
    stats_last_month: 'Last month',
    stats_this_year: 'This year',
    stats_last_year: 'Last year',

    feedbackButton: 'Tap to write comments',
  },
};

const getLanguageFromBrowser = () => {
  let langCode = (
    ('languages' in navigator ? navigator.languages[0] : null) ||
    navigator.language
  ).substring(0, 2);
  if (window.location.href.indexOf('ohjaa') !== -1) {
    if (langCode === 'zh') {
      return 'zh_dailuge';
    } else {
      return 'en_dailuge';
    }
  }
  return langCode;
};

export const fullAvailableLangs = ['en', 'fi', 'ua', 'ru', 'ar', 'ku'];
export const tulkaLanguages = ['en', 'fi'];

export const defaultLang = 'en';

export const getLang = () => {
  let currentLang = localStorage.getItem('lang');
  if (selectableLanguages.includes(currentLang)) {
    return currentLang;
  }
  currentLang = getLanguageFromBrowser();
  if (selectableLanguages.includes(currentLang)) {
    return currentLang;
  }
  localStorage.setItem('lang', defaultLang);
  return defaultLang;
};

const getSelectableLanguages = () => {
  if (isLuonaCustomer()) {
    return fullAvailableLangs;
  }
  return tulkaLanguages;
};

export const setLanguage = (lang) => {
  if (lang && fullAvailableLangs.some(l => l === lang)) {
    localStorage.setItem('lang', lang);
    return;
  } 
  localStorage.removeItem('lang');
};

export const selectableLanguages = getSelectableLanguages();

export const langCode = getLang();

export const getIsRightToLeft = () => {
  const upToDateLangCode = getLang();
  return upToDateLangCode === 'ar' || upToDateLangCode === 'ku';
};

export const setLangOnLoginPage = () => {
  const browserLang = getLanguageFromBrowser();
  if (tulkaLanguages.includes(browserLang)){
    setLanguage(browserLang);
    return;
  }
  setLanguage(defaultLang);
};

const localize = (key) => {
  const upToDateLangCode = getLang();

  const upToDateLocalizations = translations[upToDateLangCode === 'ua' ? 'uk' : upToDateLangCode];
  return upToDateLocalizations[key] || `${key}`;
};

export default localize;
