import amplitude from 'amplitude-js';
import moment from 'moment';
import i18n from '../i18n';
import requestService from './requestService';
import { showSuccess, showError } from './toastService';
import { isInterpretationPrebookNeeded } from '../utils/luonaSpecificData';

const endpoints = {
  getPrebookings: '/prebooking/',
  editPrebooking: 'prebooking/',
  acceptPrebooking: 'prebooking/',
  createPrebooking: '/prebooking/create',
  cancelPrebooking: '/prebooking/cancel/',
  getFreeSlots: 'prebooking/freeSlots',
  createMulti: 'prebooking/createMulti'
};

const formatParametrs = (parametrsArray) => {
  return parametrsArray.filter(param => param).join('&');
};

export const getPrebookings = async(onlyPersonal = false, userType, interpreterUserId, dateFrom, dateTo) => {
  const onlyPersonalParam = onlyPersonal ? 'onlyPersonal=true' : '';
  const interpreterParam = interpreterUserId ? `targetUser=${interpreterUserId}` : '';
  const interpreterTypeParams = userType ? `userType=${userType}` : '';
  const dateFromParam = dateFrom ? `dateFrom=${moment(dateFrom).format('YYYY-MM-DD')}`: '';
  const dateToParam = dateTo ? `dateTo=${moment(dateTo).format('YYYY-MM-DD')}`: '';

  try {
    return requestService.get(`${endpoints.getPrebookings}`, formatParametrs([onlyPersonalParam, interpreterParam, interpreterTypeParams, dateFromParam, dateToParam, 'cancelled=true']), {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);
    if (e.response !== undefined && e.response.status === 400) {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
      showError(i18n('error500'));
    } else {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
      showError(i18n('error500'));
    }
    throw e;
  }
};

export const createPrebooking = async(prebook) => {
  const body = getPrebookBody(prebook);

  try {
    const prebookData = await requestService.postWithJson(
      `${endpoints.createPrebooking}`,
      null,
      body,
      { apiType: 'platform-v2'}
    );

    if (prebookData.error) {
      throw new Error(prebookData.message);
    }

    showSuccess(i18n('prebook_successfully_created'));
    return prebookData;
  } catch (e) {
    console.error(e);

    if (e.response !== undefined && e.response.status === 400) {
      amplitude.getInstance().logEvent('Failed to create prebooking', {
        error: e.message,
        'HTTP status code': e.response ? String(e.response.status) : null
      });
      showError(i18n('failed_to_create_prebooking'));
    } else {
      amplitude.getInstance().logEvent('Failed to create prebooking', {
        error: e.message,
        'HTTP status code': e.response ? String(e.response.status) : null
      });
      showError(i18n('failed_to_create_prebooking'));
    }

    throw e;
  }
};

const getPrebookBody = (prebook) => {
  const data = {
    date: prebook.date ? prebook.date.getTime() : null,
    duration: prebook.duration ? prebook.duration * 60 :null,
    languageId: prebook.selectedLanguageId ? prebook.selectedLanguageId :null,
    targetLanguageId: prebook.selectedTargetLanguageId ? prebook.selectedTargetLanguageId :null,
    skillIds: prebook.selectedSkillIds?.length > 0 ? prebook.selectedSkillIds?.join() : null,
    callType: prebook.callType ? prebook.callType : null,
    invoiceUserGroupId: prebook.invoiceUserGroupId ? prebook.invoiceUserGroupId :null,
    invoiceRef: prebook.invoiceRef ? prebook.invoiceRef :null,
    invoiceRecipientRef: prebook.invoiceRecipientRef ? prebook.invoiceRecipientRef :null,
    comments: prebook.comment ? prebook.comment :null,
    presence: prebook.presence ? prebook.presence :null,
    locationCity: prebook.locationCity ? prebook.locationCity :null,
    streetAddress: prebook.streetAddress ? prebook.streetAddress :null,
    postcode: prebook.postcode,
    subject: prebook.subject ? prebook.subject : null,
    messageToInterpreter: prebook.messageToInterpreter ? prebook.messageToInterpreter :null,
    ccEmails: prebook.ccEmails ? prebook.ccEmails :null,
    patientName: prebook.patientName ? prebook.patientName : null,
    customerServiceComments: prebook.customerServiceComments || '',
    favoriteInterpreterIds: prebook.favoriteInterpreters?.length > 0 ? prebook.favoriteInterpreters?.join() :null,
    customFields: prebook?.customFields || null
  };
  return Object.keys(data).reduce((memo, field) => {
    if (data[field]) {
      memo[field] = data[field];
    }
    return memo;
  }, {});

};

export const editPrebooking = async(prebook, prebookingId) => {
  const body = getPrebookBody(prebook);
  const param = requestService.getDefaultParams();
  try {
    const prebookData = await requestService.putWithJson(`${endpoints.editPrebooking}${encodeURIComponent(prebookingId)}/edit`, param, body, {apiType: 'platform'});
    showSuccess(i18n('prebook_successfully_edit'));
    return prebookData;
  } catch (e) {
    console.error(e);
    showError(i18n('error500'));
    throw e;
  }
};

export const acceptAutoAllocatedPrebooking = async (prebookingId) => {
  const params = requestService.getDefaultParams();

  try {
    const response = await requestService.putWithJson(`${endpoints.acceptPrebooking}${encodeURIComponent(prebookingId)}/accept`, params, null, {apiType: 'platform'});
    showSuccess(i18n('prebook_successfully_accepted'));
    return response;
  } catch (e) {
    console.log(e);
    showError(i18n('error500'));
    throw e;
  }
};

const getParamForCancelPrebooking = (params) => {
  if (params) {
    return Object.keys(params).reduce((memo, key) => {
      if (!memo && params[key]) {
        return `${key}=${encodeURIComponent(params[key])}`;
      }
      return params[key] ? `${memo}&${key}=${encodeURIComponent(params[key])}` : memo;
    }, '');
  }
  return '';
};

export const cancelPrebooking = async(prebookingId, params) => {
  const body = getParamForCancelPrebooking(params);
  try {
    return requestService.putWithJson(`${endpoints.cancelPrebooking}${encodeURIComponent(prebookingId)}`, body, params, {apiType: 'platform-v2'}).then((response) => {
      showSuccess(i18n('prebook_successfully_cancelled'));
      return response;
    });
  } catch (e) {
    console.error(e);

    if (e.response !== undefined && e.response.status === 400) {
      showError(i18n('error500'));
    } else {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
      showError(i18n('error500'));
    }

    throw e;
  }
};

export const confirmPrebookingBilling = async(prebookingId, userGroupId, invoiceRef, invoiceRecipientRef, customGroupValues) => {
  const data = {
    invoiceUserGroupId: userGroupId,
    invoiceRef: invoiceRef,
    invoiceRecipientRef: invoiceRecipientRef,
    customFields: customGroupValues,
    confirm: true
  };
  const param = requestService.getDefaultParams();
  const body = Object.keys(data).reduce((memo, field) => {
    if (data[field]) {
      memo[field] = data[field];
    }
    return memo;
  }, {});

  try {
    const prebookData = await requestService.putWithJson(`${endpoints.editPrebooking}${encodeURIComponent(prebookingId)}/edit`, param, body, {apiType: 'platform'});
    showSuccess(i18n('prebook_successfully_updated'));
    return prebookData;
  } catch (e) {
    console.error(e);

    if (e.response !== undefined && e.response.status === 400) {
      showError(i18n('error500'));
    } else {
      amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
      showError(i18n('error500'));
    }

    throw e;
  }
};

export const getFreeSlots = (body) => {
  try {
    const data = requestService.postWithJson(
      `${endpoints.getFreeSlots}`,
      null,
      body,
      { apiType: 'platform'}
    );
    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const getBodyForMultiPrebook = ({date, interpreterId, interpreterUserId, targetLanguageId, genericLanguageId, interpretedLanguageId, skill}) => {
  const defaultBody = {
    date: date ? new Date(date?.start).getTime() : null,
    duration: Math.max(date.duration || 0, 900),
    presence: 'remote',
    callType: 'data',
    appVariant: 'tulka',
  };
  let prebookings = [{
    interpreterUserId: interpreterUserId,
    interpreterId: interpreterId,
    targetLanguageId,
    languageId: genericLanguageId,
    skillIds: [skill]
  }];

  if (isInterpretationPrebookNeeded(interpretedLanguageId)) {
    prebookings.push({
      targetLanguageId,
      languageId: interpretedLanguageId,
    });
  }
  return {...defaultBody, prebookings };
};

export const createMultiPrebook = (prebookData) => {
  const body = getBodyForMultiPrebook(prebookData);
  try {
    const data = requestService.postWithJson(
      `${endpoints.createMulti}`,
      null,
      body,
      { apiType: 'platform'}
    );
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
