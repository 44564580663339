import React from 'react';
import styled from 'styled-components';
import { WizardList, WizardItem } from './WizardList';
import { WizardStepContainer, WizardStepTitle } from './WizardStep';

const ContentContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const LanguageStep = ({ selectedLang, setLang, handleNext, avaliableLangs }) => {

  return (
    <WizardStepContainer>
      <ContentContainer>
        <WizardStepTitle data-test='wizardStepTitleForLanguageStep' label='Мова/ Язык/ Language/ Kieli'/>
        <WizardList data-test='wizardListForLanguageStep'>
          {avaliableLangs.map(lang => (
            <WizardItem
              data-test={`wizardItem-${lang.key}`}
              key={lang.key}
              selectable={lang.key === selectedLang}
              label={lang.label}
              handleNext={() => {
                setLang(lang.key);
                handleNext(lang.key);
              }}
            />
          ))}
        </WizardList>
      </ContentContainer>
    </WizardStepContainer>
  );
};

export default LanguageStep;
