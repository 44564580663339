import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ChatWizardHeader from './ChatWizardHeader';
import { Input } from './Input';
import ContentBox from './ContentBox';
import UsergroupCustomFields from './UsergroupCustomFields';

import i18n from '../i18n';
import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';

import { StyledErrorMessage } from './basicComponents';
import { isRefFieldValid } from '../utils/validateFields';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MessageContainer = styled.div`
  margin-bottom: 20px;
`;

const getCustomInvoiceNotesTitle = (isCustomFieldsShown, customTitle) => {
  if (isCustomFieldsShown) {
    return i18n('other_information_relevant_to_the_invoicing_such_as_information_about_the_customer');
  }
  if (customTitle) {
    return customTitle;
  }
  return i18n('please_provide_customer_reference');
};

const CustomerReference = ({
  active,
  editableRefField,
  invoiceRefMandatory,
  onNext,
  onValueSelected,
  isNoticeInterpretation,
  usergroupCustomFields,
  customFieldsValue,
  setCustomFieldsValue,
  ...rest
}) => {
  const context = useContext(AppContext);
  const themeContext = useContext(ThemeContext);
  const colors = themeContext.colors;
  const isCustomFieldsShown = (usergroupCustomFields && usergroupCustomFields.length > 0) && (!editableRefField || editableRefField === 'all' || editableRefField === 'editCustomGroupFields');

  const subtitle = getCustomInvoiceNotesTitle(isCustomFieldsShown, context.user.userGroup?.invoiceRefTitle);

  const [values, setValues] = useState({});
  const [error, setError] = useState({});

  const handleChangeField = (field, fieldValue) => {
    onValueSelected('');
    setValues({...values, [field]: fieldValue});

    const comparedValue = fieldValue?.split().reduce((acc, char) => {
      if (!acc && (char === ' ')) {
        return acc;
      }
      return acc + char;
    }, '');

    const isValidComparedValue = isRefFieldValid(comparedValue);
    if (isValidComparedValue) {
      setError({...error, [field]: false});
      if (field === 'customerReference') {
        onValueSelected(comparedValue);
      }
    }
    
  };

  const handleBlur = (required, field) => {
    if (!required) {
      return;
    }
    const comparedValue = values[field]?.trim();
    if (!isRefFieldValid(comparedValue) && invoiceRefMandatory) {
      setError({...error, [field]: 'invalid_field_value'});
    } else {
      setError({...error, [field]: false});
    }
  };

  const isCustomReferenceRequired = useMemo(() => {
    return isCustomFieldsShown || editableRefField === 'customerInvoiceNotes' ? false : true;
  }, [isCustomFieldsShown, editableRefField]);

  return (
    <StyledContainer {...rest}>
      <ChatWizardHeader
        title={editableRefField === 'alternativeCostCenter' ? i18n('alternative_cost_center_edit') : i18n('customer_reference')}
      />
      <ContentBox
        minorTitle={!isNoticeInterpretation && !(usergroupCustomFields && usergroupCustomFields.length > 0) ? subtitle : ''}
      >
        {
          isCustomFieldsShown ? (
            <UsergroupCustomFields
              usergroupCustomFields={usergroupCustomFields}
              customFieldsValue={customFieldsValue}
              setCustomFieldsValue={setCustomFieldsValue}
            />
          ) : null
        }
        {
          (!isNoticeInterpretation && (!editableRefField || editableRefField === 'all' || editableRefField === 'customerInvoiceNotes')) ? (
            <MessageContainer>
              <Input
                data-test='customerInvoiceNotes'
                title={usergroupCustomFields && usergroupCustomFields.length > 0 ? subtitle : ''}
                type="textarea"
                placeholder={i18n('customer_reference_placeholder')}
                value={values.customerReference || ''}
                onChange={(fieldValue) => handleChangeField('customerReference', fieldValue)}
                onNext={onNext}
                maxLength="255"
                active={(!usergroupCustomFields || usergroupCustomFields.length === 0) ? active : false}
                height={115}
                required={isCustomReferenceRequired}
                onBlur={() => handleBlur(isCustomReferenceRequired, 'customerReference')}
              />
              {error.customerReference &&(
                <StyledErrorMessage color={colors.error}>
                  {i18n(error.customerReference)}
                </StyledErrorMessage>
              )}
            </MessageContainer>
          ) : null
        }

      </ContentBox>
    </StyledContainer>
  );
};

CustomerReference.propTypes = {
  onValueSelected: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  value: PropTypes.string
};

CustomerReference.defaultProps = {
  value: ''
};

export default CustomerReference;
