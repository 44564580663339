import { useState, useRef, useCallback } from 'react';
import * as VideoExpress from '@vonage/video-express';

export default function usePreviewPublisher() {
  let previewPublisher = useRef();
  const [previewMediaCreated, setPreviewMediaCreated] = useState(false);

  const createPreview = useCallback(
    async (targetEl, publisherOptions) => {
      try {
        const publisherProperties = Object.assign({
          width: '100%',
          height: '100%',
          publishAudio: true,
          publishVideo: true}, publisherOptions);
        previewPublisher.current = new VideoExpress.PreviewPublisher(targetEl);
        await previewPublisher.current.previewMedia({
          targetElement: targetEl,
          publisherProperties
        });

        setPreviewMediaCreated(true);
      } catch (err) {
        console.log('[createPreview]', err);
      }
    },
    []
  );

  const destroyPreview = useCallback(() => {
    if (previewPublisher && previewPublisher.current) {
      previewPublisher.current.destroy();
      console.log('[destroyPreview] - ', previewPublisher);
    }
  }, []);

  return {
    previewPublisher: previewPublisher.current,
    createPreview,
    destroyPreview,
    previewMediaCreated
  };
}
