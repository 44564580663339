import requestService from './requestService';

const endpoints = {
  updateFeatureSupport: '/user/updateFeatureSupport',
};

export const updateFeatureSupport = async(voiceCall, textChat) => {
  const body = 'voiceCall=' + voiceCall + '&textChat=' + textChat;

  try {
    return requestService.put(`${endpoints.updateFeatureSupport}`, null, body, {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);
    throw e;
  }
};
