import React, { useContext } from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import styled from 'styled-components';
import debounce from 'es6-promise-debounce';
import Highlighter from 'react-highlight-words';

import i18n from '../i18n';

import ThemeContext from '../contexts/ThemeContext';

const StyledComponentsInput = styled(components.Input)`
  input {
    border-radius: 0;
    color: ${({ color }) => (color)} !important;
    &::-webkit-input-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &::-moz-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &:-ms-input-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &:-moz-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
  }
`;

const StyledComponentsValueContainer = styled(components.ValueContainer)`
  padding: 11px 16px !important;
  border-radius: 6px;
`;

const StyledNoOptionsMessage = styled.span`
  padding: 10px;
  display: flex;
  flex: 1;
`;

const StyledComponentsMenuList = styled(components.MenuList)`
  &::-webkit-scrollbar {
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 12px;
    background-color: rgba(196, 196, 196, 1);
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const UsergroupSelect = ({ containerRef, scrollContainerRef, selected, onChange, getSelectableItems, ...rest}) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;

  const getOptions = async (inputValue) => {
    return getSelectableItems(inputValue).then((items) => {
      return items.map((userGroup) => {
        const ref = userGroup.invoiceRecipientRef ? `(${userGroup.invoiceRecipientRef})` : '';
        return {
          value: userGroup.id,
          label: userGroup.name + ref,
          customFields: userGroup.customFields,
        };
      });
    });
  };

  const height = containerRef.current?.parentNode?.parentNode?.offsetHeight ? containerRef.current?.parentNode?.parentNode?.offsetHeight - 48 : 280;

  return (
    <AsyncSelect
      cacheOptions
      autoFocus={true}
      value={{
        value: (selected && selected.id) || null,
        label: (selected && selected.name) || null,
        customFields: (selected && selected.customFields) || null
      }}
      onChange={(obj) => onChange({
        id: obj.value,
        name: obj.label,
        customFields: obj.customFields,
      })}
      defaultOptions={selected}
      maxMenuHeight={height}
      loadOptions={(inputValue) => {
        if (inputValue.length < 3) {
          return Promise.resolve();
        }
        return debounce(() => getOptions(inputValue), 500)();
      }}
      styles={{
        control: (styles, { isFocused }) => ({
          ...styles,
          boxShadow: 'none',
          borderColor: isFocused ? colors.webMediumGray : '#cccccc',
          ':hover': {
            borderColor: colors.webMediumGray
          }
        }),
      }}
      components={{
        Option: (props) => {
          return <components.Option {...props}
          data-test={'option' + rest['data-test']}
            children={
              <Highlighter
                autoEscape={true}
                searchWords={[props.selectProps.inputValue]}
                textToHighlight={props.data.label}
              />
            }
          />;
        },
        Input: (props) => {
          if (!props.selectProps?.menuIsOpen && (props.selectProps?.value?.value && props.selectProps?.value?.label)) {
            return <StyledComponentsInput
              data-test={'input' + rest['data-test']}
              color={colors.webGray}
              lighttextcolor={colors.webMediumGray}
              {...props}
            />;
          }
          return <StyledComponentsInput
            data-test={'input' + rest['data-test']}
            color={colors.webGray}
            lighttextcolor={colors.webMediumGray}
            placeholder={i18n('type_search_term_here')}
            {...props}
          />;
        },
        SingleValue: ({ children, ...props }) => {
          if (props.selectProps?.menuIsOpen || !(props.selectProps?.value?.value && props.selectProps?.value?.label)) {
            return <components.SingleValue {...props}></components.SingleValue>;
          }
          return <components.SingleValue {...props}>{children}</components.SingleValue>;
        },
        Menu: ({ children, ...props }) => {
          if (props.selectProps.inputValue.length < 3) {
            return null;
          }
          return <components.Menu {...props}>{children}</components.Menu>;
        },
        MenuList: ({ children, ...props }) => {
          return <StyledComponentsMenuList {...props}>{children}</StyledComponentsMenuList>;
        },
        NoOptionsMessage: () => (
          <StyledNoOptionsMessage>
            {i18n('no_options')}
          </StyledNoOptionsMessage>
        ),
        LoadingMessage: () => (
          <StyledNoOptionsMessage>
            {i18n('search_message')}
          </StyledNoOptionsMessage>
        ),
        IndicatorsContainer: () => {
          return null;
        },
        ValueContainer: ({ children, ...props }) => {
          if (scrollContainerRef && scrollContainerRef.current && scrollContainerRef.current.scrollIntoView) {
            setTimeout(() => {
              scrollContainerRef?.current?.scrollIntoView(true, {
                behavior: 'smooth',
                block: 'start',
              });
            });
          }
          return (
            <StyledComponentsValueContainer
              backgroundColor={colors.webLightGray}
              {...props}
            >
              {children}
            </StyledComponentsValueContainer>
          );
        }
      }}
    />
  );
};

export default UsergroupSelect;
