import Layout from './Layout';
import Layout2020 from './Layout2020';
import Ids from '../config/ids';

const assets = {
  textOnlyLogo: require('../images/logo.png'),
  logo: require('../images/logo2.png'),
  monetra_logo: require('../images/blitz_logo2.png'),
  poweredByLogo: require('../images/powered_by_tulka.png'),
  background_login: require('../images/bg_login.jpg'),
};

const fontLuona = 'Rubik';

const fontNormal = 'GothamRounded-Book';
const fontMedium = 'GothamRounded-Medium';
const fontBold = 'GothamRounded-Bold';

const blitzFontNormal = 'HaboroSlab-NorMed';
const blitzFontMedium = 'HaboroSlab-NorMed';
const blitzFontBold = 'HaboroSlab-NorMed';

const blitzYellow = '#F39200';
const blitzRed = '#B51F2A';
const blitzLightRed = '#C52F3A';
const blitzGrey = '#C2C2C2';
const blitzDarkGrey = '#575656';

let brand = 'tulka';

export async function getNativeColors(user) {
  return getColors(user);
}

export function getAppVariant() {
  return brand;
}

export default function getColors(user) {
  let contractPartnerId = Ids.contractPartners.tulka;

  let contractPartnerName = 'Túlka';
  let businessTypeName = 'B2B';

  if (user) {
    if (user.userGroup) {
      if (user.userGroup.contractPartner) {
        contractPartnerId = user.userGroup.contractPartner.id;
        contractPartnerName = user.userGroup.contractPartner.name;
      }
      if (user.userGroup.businessType) {
        businessTypeName = user.userGroup.businessType.name;
      }
    } else if (user.contractPartners && user.contractPartners.length > 0) {
      contractPartnerId = user.contractPartners[0].id;
      contractPartnerName = user.contractPartners[0].name;
    }

    if (user.businessTypes && user.businessTypes.length > 0) {
      businessTypeName = user.businessTypes[0].name;
    }
  }

  let colors = {
    brand: 'tulka',
    businessType: businessTypeName,
    partner: contractPartnerName,
    showPhoneNumber: true,
    hideRealSubscriberName: false,
    autoSelectUILanguage: false,
    showInvoiceRef: true,
    feedbackSliders: false,
    showMessageIfNoLanguageSelected: true,
    showMessageIfNoInterpreters: false,
    hideFreeTextFeedback: true,
    noLanguageSelection: false,
    showKeepDisplayOn: true,
    phoneNumberUsername: true,
    statusBarStyle: 'light-content',
    logo: assets.logo,
    pickerTextColor: Layout.color.twilight,
    pickerSelectedTextColor: Layout.color.beach,
    popupTextColor: Layout.color.beach,
    notificationTextColor: Layout.color.semiBlack,
    notificationBoldTextColor: Layout.color.twilight,
    buttonColor: Layout.color.beach,
    buttonTextColor: Layout.color.white,
    buttonDisabledColor: Layout.color.gray,
    buttonDisabledTextColor: Layout.color.lightGray,
    lastButtonColor: Layout.color.glass,
    lastButtonTextColor: Layout.color.darkBeach,
    textFont: fontNormal,
    fontLuona: fontLuona,
    buttonFont: fontBold,
    primaryButtonFont2020: fontMedium,
    secondaryButtonFont2020: fontNormal,
    titleFont: fontBold,
    infoFont: fontMedium,
    backgroundColor: Layout.color.white,
    textColor: Layout.color.white,
    durationColor: Layout.color.beach,
    openRequestsColor: 'transparent',
    logoStyle: { tintColor: Layout.color.white },
    loginLogoStyle: { opacity: 0.8 },
    logoWidth: 0.33,
    poweredByLogoStyle: { height: 0 },
    logoPadding: 0,
    logoBottomMargin: 20,
    menuColor: Layout.color.white,
    containerColor: Layout.color.twilight,
    selectedColor: 'rgba(0, 0, 0, 0.2)',
    selectedTextColor: 'rgba(255, 255, 255, 0.5)',
    placeholderTextColor: Layout.color.sky,
    textAreaBackground: Layout.color.twilight,
    textAreaBorderColor: Layout.color.white,
    textAreaTextColor: Layout.color.white,
    textInputTextColor: Layout.color.twilight,
    textInputBackgroundColor: Layout.color.white,
    textAreaFont: fontNormal,
    textInputFont: fontBold,

    webDarkBlue: '#151529',
    tulkaMainColor: '#3A385B',
    webBackground: '#F6F9FC',
    webGray: '#8a8a94',
    webMediumGray: '#929292',
    webOverlay: 'rgba(21, 21, 41, 0.9)',
    webLightGray: '#F5F8FB',
    webSuccessColor: '#36b37e',
    webDefaultInputBorderColor: '#d8d8dc',
    webTextColor: '#ffffff',
    webMyTulkaGrayColor: '#f6f6f8',
    webMyTulkaErrorBackground: '#feebeb',
    webMyTulkaErrorText: '#d18e8a',
    webDeviderColor: '#dee1e6',

    popupTextInputBorderColor: Layout.color.white,
    popupTextInputTextColor: Layout.color.semiBlack,
    popupTextInputBackgroundColor: Layout.color.white,
    popupPlaceholderTextColor: Layout.color.lightGray,
    popupTextInputFont: fontNormal,

    stars: Layout.color.white,
    showContinueButton: true,
    contactMeNow: true,
    poweredByLogo: assets.poweredByLogo,
    error: Layout.color.coral,
    warn: Layout.color.beach,
    success: Layout.color.twilight,
    successTextColor: Layout.color.white,
    acceptColor: Layout.color.beach,
    acceptTextColor: Layout.color.white,
    cancelColor: Layout.color.coral,
    cancelTextColor: Layout.color.white,
    errorColor: Layout.color.coral,
    background_login: assets.background_login,
    video: assets.video,
    loginButton: Layout.color.coral,
    loginInputPlaceholderTextColor: Layout.color.sky,
    loginInputBackgroundColor: Layout.color.white,
    loginInputOpacity: 0.8,
    linkColor: Layout.color.beach,
    loginLinkColor: Layout.color.white,
    loginTextAreaFont: fontBold,
    formErrorColor: Layout.color.coral,
    chatBubbleMyBackgroundColor: Layout.color.sapphire,
    chatBubbleMyTextColor: Layout.color.white,
    chatBubbleMyDateTextColor: Layout.color.sky,
    chatBubbleOtherBackgroundColor: Layout.color.white,
    chatBubbleOtherTextColor: Layout.color.twilight,
    chatBubbleOtherDateTextColor: Layout.color.lightGray,
    chatButtonColor: Layout.color.white,
    extendedNotificationDisableDurations: false,
    leaveContactRequest: false,
    videoChatTextColor: Layout.color.white,

    headerLogo2020: assets.textOnlyLogo,
    textColor2020: Layout.color.semiBlack,
    logoStyle2020: { tintColor: Layout.color.white },
    containerColor2020: Layout.color.twilight,
    containerPressColor2020: Layout.color.twilightLight,
    panelColor2020: Layout.color.white,
    contentBoxColor2020: Layout.color.white,
    contentBoxBorderColor2020: Layout2020.color.gray,
    contentBoxBorderColorLight2020: Layout2020.color.semiGray,
    scrollListColor2020: Layout2020.color.lightGray,
    secondaryButton2020: Layout2020.color.midGray,
    headerLogoWidth2020: 0.2,

    dotIndicator: Layout.color.lightGray,
    titleBottomBorder: Layout.color.gray,
    endCallButton: Layout.color.coral,
    accentGuideColor: Layout.color.coral,
    defaultGuideColor: Layout.color.twilight,
    safeAreaColor: Layout.color.black,

    buttonUnderlayColor: Layout.color.white,
    shadowColor: Layout.color.shadowColor,

    luonaMainBrightColor: Layout.color.luonaMainBrightColor,
    luonaMainBrightOpacity: Layout.color.luonaMainBrightOpacity,
    luonaModalOverlay: Layout.color.luonaModalOverlay,
    luonaMainLightColor: Layout.color.luonaMainLightColor,
    luonaGrayTextColor: Layout.color.luonaGrayTextColor,
    luonaSuccessColor: Layout.color.luonaSuccessColor,
    luonaBorderColor: Layout.color.luonaBorderColor
  };

  if (
    contractPartnerId === Ids.contractPartners.monetra ||
    brand === 'monetra'
  ) {
    colors = {
      brand: 'monetra',
      businessType: businessTypeName,
      partner: contractPartnerName,
      hideRealSubscriberName: false,
      showPhoneNumber: true,
      autoSelectUILanguage: false,
      showInvoiceRef: true,
      feedbackSliders: false,
      noLanguageSelection: false,
      showMessageIfNoLanguageSelected: true,
      showMessageIfNoInterpreters: false,
      hideFreeTextFeedback: true,
      showKeepDisplayOn: true,
      phoneNumberUsername: true,
      statusBarStyle: 'dark-content',
      logo: assets.monetra_logo,
      pickerTextColor: Layout.color.black,
      pickerSelectedTextColor: blitzRed,
      popupTextColor: Layout.color.black,
      notificationTextColor: Layout.color.black,
      notificationBoldTextColor: blitzRed,
      buttonTextColor: Layout.color.black,
      buttonColor: blitzYellow,
      buttonDisabledColor: Layout.color.gray,
      buttonDisabledTextColor: Layout.color.lightGray,
      lastButtonColor: blitzRed,
      lastButtonTextColor: Layout.color.black,
      buttonFont: blitzFontBold,
      textFont: blitzFontNormal,
      fontLuona: fontLuona,
      titleFont: blitzFontBold,
      infoFont: blitzFontMedium,
      backgroundColor: Layout.color.white,
      textColor: Layout.color.black,
      logoStyle: {},
      logoWidth: 0.5,
      poweredByLogoStyle: { tintColor: Layout.color.white },
      loginLogoStyle: { opacity: 0.8 },
      logoPadding: 10,
      logoBottomMargin: 20,
      menuColor: blitzDarkGrey,
      containerColor: blitzGrey,
      selectedColor: 'rgba(0, 0, 0, 0.2)',
      selectedTextColor: 'rgba(255, 255, 255, 0.5)',
      textAreaBackground: blitzGrey,
      textAreaBorderColor: blitzDarkGrey,
      textAreaTextColor: Layout.color.black,
      textInputTextColor: Layout.color.black,
      textInputBackgroundColor: Layout.color.white,
      textAreaFont: blitzFontNormal,
      textInputFont: blitzFontBold,
      placeholderTextColor: blitzDarkGrey,
      loginInputPlaceholderTextColor: blitzDarkGrey,
      stars: Layout.color.black,
      showContinueButton: true,
      contactMeNow: true,
      poweredByLogo: assets.poweredByLogo,
      error: blitzYellow,
      warn: blitzYellow,
      success: blitzYellow,
      successTextColor: Layout.color.black,
      acceptColor: blitzYellow,
      acceptTextColor: Layout.color.black,
      cancelColor: blitzRed,
      cancelTextColor: Layout.color.black,
      errorColor: blitzRed,
      background_login: assets.background_login,
      video: assets.video,
      loginButton: blitzYellow,
      loginInputBackgroundColor: '#ffffff',
      loginInputOpacity: 0.8,
      linkColor: Layout.color.white,
      loginLinkColor: Layout.color.white,
      loginTextAreaFont: blitzFontBold,
      formErrorColor: blitzRed,
      chatBubbleMyBackgroundColor: blitzYellow,
      chatBubbleMyTextColor: Layout.color.white,
      chatBubbleMyDateTextColor: Layout.color.white,
      chatBubbleOtherBackgroundColor: Layout.color.white,
      chatBubbleOtherTextColor: blitzDarkGrey,
      chatBubbleOtherDateTextColor: blitzGrey,
      chatButtonColor: Layout.color.white,
      extendedNotificationDisableDurations: false,
      leaveContactRequest: false,
      videoChatTextColor: Layout.color.white,

      headerLogo2020: assets.monetra_logo,
      textColor2020: Layout.color.semiBlack,
      logoStyle2020: { tintColor: Layout.color.white },
      containerColor2020: blitzRed,
      containerPressColor2020: blitzLightRed,
      panelColor2020: Layout.color.white,
      contentBoxColor2020: Layout.color.white,
      contentBoxBorderColor2020: Layout2020.color.gray,
      contentBoxBorderColorLight2020: Layout2020.color.semiGray,
      scrollListColor2020: Layout2020.color.lightGray,
      secondaryButton2020: Layout2020.color.midGray,
      headerLogoWidth2020: 0.2,

      dotIndicator: Layout.color.lightGray,
      titleBottomBorder: Layout.color.gray,
      endCallButton: Layout.color.coral,
      safeAreaColor: Layout.color.black,

      buttonUnderlayColor: Layout.color.white,
    };
  }

  return colors;
}
