import React, { useState, useContext, useEffect} from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import i18n from '../i18n';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import DeviceSelector from '../components/deviceSelector';
import ChatWizardHeader from '../components/ChatWizardHeader';
import ButtonPanel from '../components/ButtonPanel';
import { RoundButton } from '../components/Buttons';
import ActionList from '../components/ActionList';
import ActionListItem from '../components/ActionListItem';
import { format } from '../utils/timeUtils';
import { showError, showHtmlQuestion} from '../utils/msgbox';
import { CustomerGuideForCall } from '../components/CustomerGuideForCall';

import {
  getLanguageList
} from '../services/languageService';
import {
  createChatRoom,
  endChatRoom,
} from '../services/chatRoomService';
import {
  updateFeatureSupport,
} from '../services/featureSupportService';

const StyledInterpreterNow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 15px;
  border: none;
  position: relative;
  padding: 40px 40px 40px 40px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TimeAvailable = props => {
  if (props.invoicing) {
    return null;
  }

  const text = props.seconds > 0 ?
    i18n('timeLeft').replace('{}', format(props.seconds)) :
    i18n('zeroTimeLeft');

  return <div id='timeLeftContainer'><div id='customerTimeLeft'>{text}</div></div>;
};

const Note = props => {
  if (props.text === undefined || props.text.length === 0) {
    return null;
  }

  return (
    <h3>{props.text}</h3>
  );
};

const SystemAlert = props => {
  if (!props.visible) {
    return null;
  }

  return <h3 className='systemAlert'>{i18n('systemAlert')}</h3>;
};

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const InterpreterNow = props => {
  const context = useContext(AppContext);
  const themeContext = useContext(ThemeContext);
  const functionsContext = useContext(FunctionsContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const { history } = context;
  const [languages, setLanguages] = useState([{
      id: null,
      name: i18n('pleaseWait')
  }]);
  const [targetLanguages, setTargetLanguages] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedTargetLanguage, setSelectedTargetLanguage] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [invoicing, setInvoicing] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showSettings] = useState(false);
  const [systemAlert, setSystemAlert] = useState(false);
  const [ivLangList, setIvLangList] = useState(null);
  const [viewState, setViewState] = useState('language');
  const [callType, setCallType] = useState(null);

  useEffect(() => {
    endChat();
    appendLanguages();
    const intervalId = setInterval(async () => await appendLanguages(), 30 * 1000);
    setIvLangList(intervalId);

    amplitude.getInstance().logEvent('Voice call not supported');
    amplitude.getInstance().logEvent('Request interpreter activated');

    const now = Date.now();
    window.onfocus = () => {
      amplitude.getInstance().logEvent('Request interpreter activated');
    };

    window.onblur = () => {
      amplitude.getInstance().logEvent('Request interpreter deactivated', { 'Active seconds': String((Date.now() - now) / 1000 )});
    };

    updateFeatureSupport(false, true).catch(e => console.log(e));

    return () => {
      clearInterval(intervalId);

      window.onfocus = null;
      window.onblur = null;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function endChat() {
    functionsContext.postToWorker('stopBillablePolling');
    const roomId = localStorage.getItem('roomId');
    if (context.user && roomId) {
      endChatRoom(roomId)
        .then(() => localStorage.removeItem('roomId'))
        .catch(e => {
          console.log('Error', e);
        });
    }
  }

  async function appendLanguages() {
    try {
      const res = await getLanguageList();

      setLanguages(res.selectableLanguages);
      setSecondsLeft(res.secondsLeft);
      setInvoicing(invoicing);
      setButtonDisabled(false);
      setSelectedLanguage(localStorage.getItem('selectedLanguage') || (res.selectableLanguages.length > 0 ? res.selectableLanguages[0].id : null));
      setSelectedTargetLanguage(localStorage.getItem('selectedTargetLanguage') || (res.targetLanguages && res.targetLanguages.length > 0 ? res.targetLanguages[0].id : null));
      setTargetLanguages(res.targetLanguages || []);
      setSkills({});
      setSystemAlert(res.systemAlert);

      functionsContext.reloadPageIfExpired();
    } catch (e) {
      if (e.message === 'Session expired') {
        sessionExpired();
      } else {
        console.log(e);
        amplitude.getInstance().logEvent('Fetch language list failed', { 'HTTP status code': e.response ? String(e.response.status) : ''});
      }
    }
  }

  function sessionExpired() {
    clearInterval(ivLangList);
    functionsContext.sessionExpired();
  }

  async function requestInterpreter() {
    if (selectedLanguage === null || languages.length === 0) {
      return;
    }

    setButtonDisabled(true);

    let selectedLang = languages.find(lang => lang.id === selectedLanguage);

    if (selectedLang && selectedLang.languageTag !== 'prebooking') {
      localStorage.setItem('selectedLanguage', selectedLang.id);
    }

    if (!selectedLang) {
      selectedLang = languages[0];
    }

    if (targetLanguages.length > 0 && !selectedLang.languageTag !== 'prebooking') {
      localStorage.setItem('selectedTargetLanguage', selectedTargetLanguage);
    }

    if (selectedLang.languageTag !== 'prebooking' && skills[selectedLang.id]) {
      showSkillModal(skills[selectedLang.id].name, selectedLang);
    } else {
      await sendInterpretationRequest(undefined, selectedLang);
    }
  }

  async function showSkillModal(skillName, aSelectedLanguage) {
    if (await showHtmlQuestion(i18n('skillModalQuestion') + '<br/><br/><b>' + skillName + '</b>')) {
      await sendInterpretationRequest(skills[aSelectedLanguage.id], aSelectedLanguage);
    } else {
      await sendInterpretationRequest(undefined, aSelectedLanguage);
    }
  }

  async function sendInterpretationRequest(skill, aSelectedLanguage) {
    let languageCode = '';
    let languageName = '';
    let targetLanguageCode = '';
    let targetLanguageName = '';
    let skillName = '';

    languageCode = aSelectedLanguage.languageTag;
    languageName = aSelectedLanguage.name;

    targetLanguages.forEach(lang => {
      if (lang.id === selectedTargetLanguage) {
        targetLanguageCode = lang.languageTag;
        targetLanguageName = lang.name;
      }
    });

    if (skill) {
      skillName = skills[aSelectedLanguage.id].name;
    }

    const analyticsParams = {
      'type': 'data',
      'language id': aSelectedLanguage.id,
      'language code': languageCode,
      'language name': languageName,
      'target language id': selectedTargetLanguage,
      'target language code': targetLanguageCode,
      'target language name': targetLanguageName,
      'skill id': skill ? skill.id : '',
      'skill name': skillName
    };

    try {
      const chatRoom = await createChatRoom(
        aSelectedLanguage.id,
        selectedTargetLanguage,
        skill ? skill.id : null);

      amplitude.getInstance().logEvent('Request interpretation succeeded', analyticsParams);

      props.closeModal();
      history.replace(
        '/call', {
        callerName: '',
        languageName: chatRoom.interpretedLanguage.name,
        secondsLeft: secondsLeft,
        apiKey: chatRoom.apiKey,
        chatToken: chatRoom.chatToken,
        sessionId: chatRoom.sessionId,
        roomId: chatRoom.id,
        textChatTokenProviderEndpoint: chatRoom.textChatTokenProviderEndpoint,
        textChatInstanceLocator: chatRoom.textChatInstanceLocator,
        textChatRoomId: chatRoom.textChatRoomId
      });
    } catch (e) {
      setButtonDisabled(false);

      if (e.response) {
        analyticsParams['HTTP status code'] = String(e.response.status);
      }

      if (e.message === 'Session expired') {
        sessionExpired();
      } else if (e.message === 'No minutes left') {
        amplitude.getInstance().logEvent('No minutes left to request interpretation', analyticsParams);
        showError(i18n('noTimeLeft'));
      } else {
        amplitude.getInstance().logEvent('Request interpretation failed', analyticsParams);
        showError(i18n('failedToCreateChatroom'));
      }
    }
  }

  function onLanguageChanged(id) {
    setSelectedLanguage(id);
  }

  function onTargetLanguageChanged(id) {
    setSelectedTargetLanguage(id);
  }

  const availableOnDemandLangs = languages.filter(lang => lang.onDemand);

  const getTitle = () => {
    if (viewState === 'language') {
      return i18n('targetLanguage');
    }
    if (viewState === 'callType') {
      return i18n('call_type_question');
    }
    if (viewState === 'message') {
      return i18n('message');
    }
    return '';
  };

  const isDisabled = (state) => {
    if (state === 'language') {
      return !selectedTargetLanguage || !selectedLanguage;
    }
    if (state === 'callType') {
      return !callType || buttonDisabled;
    }
    if (state === 'callType') {
      return !callType || buttonDisabled;
    }
    return false;
  };

  return (
    <StyledInterpreterNow>
    {(() => {
      switch (viewState) {
        case 'language':
          return (
            <StyledContainer>
              <StyledContent>
                {(targetLanguages.length > 0) && (
                  <div>
                    <ChatWizardHeader
                      noPadding
                      title={getTitle()}
                    />
                    <select className='select' value={selectedTargetLanguage || ''} onChange={e => onTargetLanguageChanged(e.target.value) }>
                      {targetLanguages.map(lang => (<option key={'lang_' + lang.id} value={lang.id}>{lang.name}</option>))}
                    </select>
                  </div>
                )}
                <ChatWizardHeader
                  noPadding
                  title={i18n('selectLanguage')}
                />
                <select className='select' value={selectedLanguage || ''} onChange={e => onLanguageChanged(e.target.value) }>
                  {availableOnDemandLangs.map(lang => (<option key={'lang_' + lang.id} value={lang.id}>{lang.name}</option>))}
                </select>
                <SystemAlert
                  visible={systemAlert}
                />
                <Note
                  text={i18n('openingTimes')}
                />
                <DeviceSelector
                  showSettings={showSettings}
                />
              </StyledContent>
            <TimeAvailable
              seconds={secondsLeft}
              invoicing={invoicing}
            />
              <ButtonPanel>
                <RoundButton
                  label={i18n('close')}
                  onClick={() => props.closeModal()}
                  primary
                  darkButton
                  color={colors.tulkaMainColor}
                  padding={layout.buttonPadding}
                />
                <RoundButton
                  label={i18n('choose_button')}
                  onClick={() => setViewState('callType')}
                  primary
                  darkButton
                  disabled={isDisabled('language')}
                  color={colors.tulkaMainColor}
                  padding={layout.buttonPadding}
                />
              </ButtonPanel>
            </StyledContainer>
        );
        case 'callType':
          return (
            <StyledContainer>
              <ChatWizardHeader
                noPadding
                title={getTitle()}
                helpId='call_type'
              />
              <StyledContent>
                <ActionList>
                  <ActionListItem
                    data-test='callTypeCall'
                    title={i18n('call_type_call')}
                    subtitle={i18n('call_type_call_tip')}
                    icon={'phone_in_talk_outlined'}
                    iconLib={'material'}
                    onClick={() => setCallType('call')}
                    selected={callType === 'call'}
                  />
                  <ActionListItem
                    data-test='callTypeVideo'
                    title={i18n('call_type_video')}
                    subtitle={i18n('call_type_video_tip')}
                    icon={'live_tv'}
                    iconLib={'material'}
                    onClick={() => setCallType('data')}
                    selected={callType === 'data'}
                  />
                </ActionList>
              </StyledContent>
              <ButtonPanel>
                <RoundButton
                  label={i18n('back')}
                  onClick={() => setViewState('language')}
                  primary
                  color={colors.tulkaMainColor}
                  padding={layout.buttonPadding}
                  disabled={buttonDisabled}
                />
                <RoundButton
                  label={i18n('requestInterpreting')}
                  onClick={() => {
                    if (callType === 'call') {
                      setViewState('message');
                    } else {
                      requestInterpreter();
                    }
                  }}
                  primary
                  darkButton
                  disabled={isDisabled('callType')}
                  color={colors.tulkaMainColor}
                  padding={layout.buttonPadding}
                  indicator={buttonDisabled}
                />
              </ButtonPanel>
            </StyledContainer>
        );
        case 'message':
          return (
            <StyledContainer>
              <CustomerGuideForCall />
              <ButtonPanel>
                <RoundButton
                  label={i18n('back')}
                  onClick={() => setViewState('callType')}
                  primary
                  color={colors.tulkaMainColor}
                  padding={layout.buttonPadding}
                />
                <RoundButton
                  label={i18n('close')}
                  onClick={() => props.closeModal()}
                  primary
                  darkButton
                  color={colors.tulkaMainColor}
                  padding={layout.buttonPadding}
                />
              </ButtonPanel>
            </StyledContainer>
          );
        default:
          return null;
      }
    })()}
    </StyledInterpreterNow>
  );
};

export default InterpreterNow;
