import requestService from './requestService';

const endpoints = {
  languageList: '/user/languageList',
  hotlineLanguageList: '/integrations/hotline/langs'
};

export const getLanguageList = async() => {
  const params = 'fullJson=true&multipleSkills=true&closeChatRooms=true&includePrebooking=true&call_state=NoCall';
  try {
    return requestService.get(`${endpoints.languageList}`, params, {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getLanduageWithCodeList = async (locale='eng') => {
  const params = `locale=${locale}`;
  try {
    return requestService.get(`${endpoints.hotlineLanguageList}`, params, {apiType: 'integration', withoutDefaultParams: true});
  } catch (e) {
    console.error(e);
    throw e;
  }
};
