export const phoneNumberMaxLength = 13;

export const validateFieldName = (value) => {
  const regex = new RegExp(
    /^([\s]{0,}[a-zA-ZäöåÄÖÅ][\s]{0,})/,
    'gi'
  );
  return regex.test(value);
};
export const validateFieldPhone = (value) => {
  const regex = new RegExp(/^[+][0-9]{12,13}$/);
  return regex.test(value);
};
export const validateFieldEmail = (value) => {
  const regex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/, 'i');
  return regex.test(value);
};

export const isPhoneNumberValid = (maybePhoneNumber) => {
  return /[0-9]{9,12}/.test(maybePhoneNumber);
};

export const isTextFieldValid = (text) => {
  return text?.length > 0;
};

export const isRefFieldValid = (text) => {
  return text?.length > 2;
};

export const isCodeValid = (value) => {
  return /^[0-9]+$/.test(value);
};

export const isValidPassword = (value) => {
  const regex = new RegExp(/^(?=.*\d)(?=.*\p{Ll})(?=.*\p{Lu})[\p{Ll}\p{Lu}0-9\W_]{8,}$/gu);
  return regex.test(value);
};
