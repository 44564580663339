import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n, { getIsRightToLeft } from '../i18n';
import ThemeContext from '../contexts/ThemeContext';
import { WizardLabel, InputWizardWithIcon, LuonaAgreementBlock } from './Input';
import SignInCodeBlock from './SignInCodeBlock';
import Spinner from './Spinner';
import {
  validateFieldEmail,
  validateFieldName,
  validateFieldPhone,
  isCodeValid
} from '../utils/validateFields';
import { WizardStepContainer, WizardStepTitle } from './WizardStep';

const validationFuncMap = {
  name: validateFieldName,
  phone: validateFieldPhone,
  email: validateFieldEmail,
  code: isCodeValid
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
`;

const AuthorizationStep = ({
  userData,
  setUserData,
  setDisabledNext,
  isShowSignInCode,
  signInCode,
  updateSignInCode
}) => {
  const { colors } = useContext(ThemeContext);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const updateUserDate = (name, value) => {
    setUserData(name, value);
    setErrors(prev => ({ ...prev, [name]: false }));
    setTouched(prev => ({ ...prev, [name]: true }));
  };

  const updateErrors = (name, value) => {
    const isValidFunc = validationFuncMap[name];
    if (typeof isValidFunc === 'function') {
      const isValid = isValidFunc(value);
      setErrors(prev => ({ ...prev, [name]: !isValid }));
      setTouched(prev => ({ ...prev, [name]: true }));
    }
  };

  const isValidForm = (userDataParam, errorsParam, agreeParam) => {
    if (isShowSignInCode) {
      return !errors?.code && !!signInCode;
    }
    const error = Object.values(errorsParam).some(err => err);
    const emptyField = Object.values(userDataParam).every(field => !field);
    return !error && !emptyField && agreeParam;
  };

  useEffect(() => {
    setDisabledNext(!isValidForm(touched, errors, agreeTerms));
  }, [touched, errors, agreeTerms, setDisabledNext]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WizardStepContainer>
      {!isShowSignInCode ? (
        <ContentContainer>
          {loading && (
            <Spinner overlay={true} color={colors.luonaMainBrightColor} />
          )}
          <WizardStepTitle data-test='wizardStepTitleForAuthorizationStep' label={i18n('authtorize')} />
          <WizardLabel data-test='nameLabelAuthorizationStep' label={i18n('label_name')} rtl={getIsRightToLeft()}/>
          <InputWizardWithIcon
            data-test='nameInputAuthorizationStep'
            placeholder={i18n('placeholder_name')}
            type='text'
            value={userData.name}
            error={errors.name}
            touched={touched.name}
            onChange={value => updateUserDate('name', value)}
            onBlur={() => updateErrors('name', userData.name)}
            rtl={getIsRightToLeft()}
          />
          <WizardLabel data-test='phoneLabelAuthorizationStep' label={i18n('label_phone')} rtl={getIsRightToLeft()}/>
          <InputWizardWithIcon
            data-test='phoneInputAuthorizationStep'
            placeholder={i18n('placeholder_phone')}
            type='text'
            value={userData.phone}
            error={errors.phone}
            touched={touched.phone}
            onChange={value => updateUserDate('phone', value)}
            onBlur={() => updateErrors('phone', userData.phone)}
            rtl={getIsRightToLeft()}
          />
          <WizardLabel data-test='emailLabelAuthorizationStep' label={i18n('label_email')} rtl={getIsRightToLeft()}/>
          <InputWizardWithIcon
            data-test='emailInputAuthorizationStep'
            placeholder={i18n('placeholder_email')}
            type='text'
            value={userData.email}
            error={errors.email}
            touched={touched.email}
            onChange={value => updateUserDate('email', value)}
            onBlur={() => updateErrors('email', userData.email)}
            rtl={getIsRightToLeft()}
          />
          <LuonaAgreementBlock
            componentName='AuthorizationStep'
            agreeText={i18n('agreePrivacyPolicy')}
            linkName={i18n('privacyPolicy')}
            linkPath='https://tulka.com/terms-of-use-privacy-policy/'
            agreeTerms={agreeTerms}
            handleAgreeChange={() => setAgreeTerms(!agreeTerms)}
            rtl={getIsRightToLeft()}
          />
        </ContentContainer>
      ) : (
        <SignInCodeBlock
          signInCode={signInCode}
          updateSignInCode={updateSignInCode}
          updateErrors={updateErrors}
          email={userData.email}
          error={errors.code}
          touched={touched.code}
        />
      )}
    </WizardStepContainer>
  );
};

export default AuthorizationStep;
