import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import i18n, { langCode } from '../i18n';
import amplitude from 'amplitude-js';
import ThemeContext from '../contexts/ThemeContext';

import ContentBox from './ContentBox';
import ActionList from './ActionList';
import ActionListItem from './ActionListItem';
import Icon from './Icon';

import { isLuonaHost } from '../utils/luonaSpecificData';
import sound from '../utils/audio';
import { logAnalytics } from '../services/analyticsLogger';

const WAIT_SECONDS = 9;

const InterpretationContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding * 3)}px;
  background: ${({ background }) => background};
  overflow: hidden;
  border-radius: 15px;
  border: none;
  position: relative;
`;

const Heading = styled.h2`
  text-align: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  min-width: 300px;
  margin: auto;
`;

const IconContainer = styled.div`
  padding: 15px;
  background: ${({ background }) => background};
  border-radius: 50%;
  margin: 0 20px;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledContentBox = styled(ContentBox)`
  border-radius: 15px;
  background-color: #ffffff !important;
`;

const Timer = styled.div`
  color: #ffffff;
  text-align: center;
  margin-bottom: 15px;
`;

const getAnsverBeforeValue = answerBefore => {
  return Math.max(answerBefore, Date.now() + WAIT_SECONDS * 1000);
};

const Interpretation = (props) => {
  const [ callUntil ] = useState(getAnsverBeforeValue(props.data.answerBefore));
  const [ callingLeft, setCallingLeft ] = useState(0);
  const [ timerInterval ] = useState(1000);
  const { colors, layout } = useContext(ThemeContext);

  const getIsMultistart = (userGroupName) => {
    if (typeof userGroupName === 'string') {
      return userGroupName.toLowerCase().includes('product development') ||
      userGroupName.toLowerCase().includes('luona');
    }
    return false;
  };
  const isMultistart = getIsMultistart(props.data.userGroupName);

  useEffect(() => {
    setCallingLeft(Math.max(callUntil - Date.now(), 0));
    const interval = callUntil ? setInterval(() => {
      const millisLeft = callUntil - Date.now();
      if (millisLeft <= 0) {
        props.close();
        document.title = 'Paradigma';
      } else {
        setCallingLeft(millisLeft);
        document.title = `(${((millisLeft % 60000) / 1000).toFixed(0)}s) Calling`;
      }
    }, timerInterval) : 0;
    return () => clearInterval(interval);
  }, [timerInterval, callUntil]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    sound.play()
    .then(() => {
      logAnalytics('Start play sound', {
        userId: props.user?.id
      });
    })
    .catch(e => {
      console.error(e);
      logAnalytics('Failed to play sound', {
        'error': e.message,
        userId: props.user?.id
      });
      amplitude.getInstance().logEvent('Failed to play sound', {
        'error': e.message
      });
    });
    return (() => {
      sound.pause();
      document.title = isLuonaHost() ? 'Paradigma' : 'Túlka Web App';
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getLocalizateText = (localizations, lang) => {
    if (localizations) {
      return localizations[lang];
    }
    return '';
  };

  const setCallHeading = () => {
    return isLuonaHost() ? i18n('requestForCall') : i18n('requestForInterpretation');
  };

  return (
    <InterpretationContainer
      padding={layout.padding}
      background={colors.tulkaMainColor}
    >
      <Heading>{setCallHeading()}</Heading>
      <StyledContentBox>
        <ActionList>
          <ActionListItem
            data-test='interpretationUserGroupActionItem'
            title={props.data.userGroupName}
            icon={'live_tv'}
            iconLib={'material'}
            readOnly={true}
          />
          <ActionListItem
            data-test='interpretationLangActionItem'
            title={getLocalizateText(props.data.interpretedLanguageName, langCode)}
            subtitle={getLocalizateText(props.data.targetLanguagesNames, langCode)}
            icon={'language'}
            iconLib={'material'}
            readOnly={true}
          />
        </ActionList>
      </StyledContentBox>
      <Spacer />
      <Timer>{moment.utc(callingLeft).format('HH:mm:ss')}</Timer>
      <ButtonRow>
        <IconContainer 
          background={colors.acceptColor}
          onClick={(e) => {
            e.preventDefault();
            props.acceptRequest(isMultistart);
          }}
        >
          <StyledIcon
            icon='call'
            iconLib='material'
            iconColor={'#ffffff'}
            iconSize={30}
          />
        </IconContainer>
        <IconContainer
          background={colors.error}
          onClick={(e) => {
            e.preventDefault();
            props.rejectRequest();
          }}
        >
          <StyledIcon
            icon='call_end'
            iconLib='material'
            iconColor={'#ffffff'}
            iconSize={30}
          />
        </IconContainer>
      </ButtonRow>
    </InterpretationContainer>
  );
};

export default Interpretation;
