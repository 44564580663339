import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import i18n from '../i18n';
import { Input } from '../components/Input';
import { RoundButton } from './Buttons';

import ThemeContext from '../contexts/ThemeContext';

const StyledCollaboratorsForm = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledInput = styled(Input)`
  display: block;
  font-size: 16px;
  width: 300px;
  height: 50px;
  min-height: 50px;
  padding: 10px 10px;
  margin-right: 15px;
  background-color: #ffffff;
`;

const StyledCollaboratorsFormContainer = styled.div`
  margin-bottom: 15px;
`;

const StyledErrorMessage = styled.div`
  color: ${({ color }) => (color)};
  padding: 5px 0;
`;

const CollaboratorsList = ({ collaborators, addCollaborator, ...props }) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const [inputData, setInputData] = useState('');
  const [error, setError] = useState('');
  const emailPattern = new RegExp(/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/);

  useEffect(() => {
    setInputData('');
    setError('');
  }, [collaborators]);

  return (
    <StyledCollaboratorsFormContainer>
      <StyledCollaboratorsForm>
        <StyledInput
          type="text"
          placeholder={i18n('please_enter_email')}
          value={inputData}
          onChange={(value) => {
            setInputData(value);
            if (error) {
              setError('');
            }
          }}
          {...props}
        />
        <RoundButton
          darkButton
          disabled={!(inputData && inputData.length > 0)}
          color={colors.tulkaMainColor}
          padding={layout.buttonPadding}
          label={i18n('add')}
          onClick={() => {
            if (emailPattern.test(inputData)) {
              const isThisEmailAlreadyAdded = collaborators.find((collaborator) => (collaborator.email === inputData));
              if (isThisEmailAlreadyAdded) {
                setError('this_user_already_added_to_collaborators');
              } else {
                addCollaborator(inputData);
                setInputData('');
              }
            } else {
              setError('invalid_email_address');
            }
          }}
          data-test='addCollaboratorButton'
        />
      </StyledCollaboratorsForm>
      {error ? (
        <StyledErrorMessage
          color={colors.error}
          data-test='addColaboratorErrorMessage'
        >
          {i18n(error)}
        </StyledErrorMessage>
      ) : null}
    </StyledCollaboratorsFormContainer>
  );
};

export default CollaboratorsList;
