import { PLATFORM } from '../API';
import requestService from './requestService';

const endpoints = {
  eula: '/docs/eula',
};

export const getEulaUrl = (user) => {
  let type = (user.type === 'Interpreter' ? 'interpreter' : 'customer');
  const json = requestService.getDefaultParamsJson();

  let bt = '';

  if (user) {
    if (user.businessTypes && user.businessTypes.length > 0) {
      bt = user.businessTypes[0].id;
    } else if (user.userGroup && user.userGroup.businessType) {
      bt = user.userGroup.businessType.id;
    }
  }

  return PLATFORM + 'api/v1' + endpoints.eula +
      '?l=' + json.locale +
      '&type=' + type +
      '&bt=' + encodeURIComponent(bt) + '&' + requestService.getDefaultParams();
};
