import { useMemo } from 'react';
import i18n from '../i18n';
import { initAvaliableService } from '../utils/luonaSpecificData';

export const NotificationPrebookCreated = ({dateAndTime, skill, children}) => {

    const skillName = useMemo(() => {
      return initAvaliableService.reduce((memo, service) => {
        if (service?.skill === skill) {
          memo = i18n(service.label);
          return memo;
        }
        return memo;
      }, '');
    }, [skill]);
  
    return (
      <div data-test='prebookCreatedMessage'>
        <p>{i18n('thanks_for_booking')}</p>
        <p>{i18n('prebook_created_at')} {dateAndTime}.</p>
        <p>{i18n('prebooking_skill')} {skillName}</p>
        <p>{i18n('you_need_high_network')}</p>
        <p>{i18n('you_will_got_accept')}</p>
        <p>{i18n('message_with_link_to_meeting')}</p>
        <p>{i18n('dont_forget_decline_prebooking')}</p>
        <p>{i18n('for_decline_need')}</p>
        {children}
        <p>{i18n('click_for_decline_prebooking')}</p>
        <p>{i18n('best_regards')}</p>
        <p>{i18n('luona_DigiVOK')}</p>
      </div>
    );
  };
