import requestService from './requestService';

const endpoints = {
  feedback: '/chatroom/feedback/',
  feedbacks: '/chatroom/feedbacks',
};

export const getFeedbacks = async() => {
  try {
    return requestService.get(`${endpoints.feedbacks}`, null, {
      apiType: 'platform-v2',
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postChatFeedback = async(feedback, roomId) => {
  try {
    return requestService.postWithJson(
      `${endpoints.feedback}${roomId}`, 
      null, 
      feedback, 
      { apiType: 'platform-v2' }
      );
  } catch (e) {
    console.error(e);
    throw e;
  }
};