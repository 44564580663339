import React from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import Header from '../components/header';
import Ids from '../utils/ids';
import { showError } from '../utils/msgbox';
import PropTypes from 'prop-types';
import reactMixin from 'react-mixin';
import TimerMixin from 'react-timer-mixin';
import amplitude from 'amplitude-js';

import AppContext from '../contexts/AppContext';
import FunctionsContext from '../contexts/FunctionsContext';

import {
  CenteredBlockContainer,
  DialogBlock,
} from '../components/basicComponents';

import {
  continueSession,
} from '../services/chatRoomService';
import {
  postChatFeedback,
} from '../services/feedbackService';

const StyledCenteredBlockContainer = styled(CenteredBlockContainer)`
  height: 100%;
  min-height: 100%;
  background-color: #46446F;
  overflow: hidden;
  overflow-y: auto;
`;

const StyledDialogBlock = styled(DialogBlock)`
  box-shadow: none;
`;

const ohjaa = window.location.href.indexOf('ohjaa') !== -1;

class Feedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      overallStars: 0,
      subscriberStars: 0,
      techStars: 0,
      roomId: Boolean(props.location.state) && Boolean(props.location.state.roomId) ? props.location.state.roomId : null,
      showContinue: ohjaa ? false : (props.appContext.user.type === 'Customer'),
      ivHideContinue: null,
      feedback: '',
      invoiceRef: '',
      feedbackDescVisible: false,
      invoiceRefUserGroupId: null,
      invoiceRefUserGroupPicked: false,
      invoiceRefVisible: false
    };

    this.invoiceRefInput = React.createRef();
    this.invoiceUserGroupsRef = React.createRef();
    this.feedback = React.createRef();
  }

  componentDidMount() {
    if (this.state.showContinue === true && this.state.ivHideContinue === null) {
      this.setState({ivHideContinue: this.setTimeout(() => this.setState({showContinue: false}), 60 * 1000)});
    }
  }

  subscriberStarsClicked = star => {
    this.setState({subscriberStars: star});
  };

  techStarsClicked = star => {
    this.setState({techStars: star});
  };

  send = async () => {
    const user = this.props.appContext.user;

    let invoiceRef = this.state.invoiceRef;
    let invoiceUserGroupName;

    if (user.invoiceRefUserGroups) {
      const irug = user.invoiceRefUserGroups.find(group => group.id === this.state.invoiceRefUserGroupId);
      if (irug && !invoiceRef.toLowerCase().startsWith(irug.prefix.toLowerCase())) {
        invoiceRef = irug.prefix + invoiceRef;
        invoiceUserGroupName = irug.name;
      }
    }

    const analyticsParams = {
      'technical': String(this.state.techStars),
      'user': String(this.state.subscriberStars),
      'comment': (this.state.feedback && this.state.feedback.length > 0 ? 'yes' : 'no'),
      'contact wanted': 'no',
      'invoice ref': (this.state.invoiceRef && this.state.invoiceRef.length > 0 ? 'yes' : 'no'),
      'invoice user group id': this.state.invoiceRefUserGroupId,
      'invoice user group name': invoiceUserGroupName
    };

    if (user.userGroup) {
      if (user.userGroup.invoiceRefMandatory && (!this.state.invoiceRef || (this.state.invoiceRef && this.state.invoiceRef.length === 0))) {
        amplitude.getInstance().logEvent('Feedback - invoice ref missing', analyticsParams);
        showError(i18n('invoiceRefMandatory'));
        return;
      } else if (user.userGroup.invoiceRefUserGroupMandatory && (!this.state.invoiceRefUserGroupId && (this.state.invoiceRefUserGroupId && this.state.invoiceRefUserGroupId.length > 0))) {
        amplitude.getInstance().logEvent('Feedback - invoice ref user group not selected', analyticsParams);
        showError(i18n('invoiceRefUserGroupMandatory'));
        return;
      }
    }

    try {
      await postChatFeedback({
        contact_wanted: false,
        feedback: this.state.feedback,
        own_notes: this.state.invoiceRef,
        stars_general: 0,
        stars_user: this.state.subscriberStars,
        stars_technical: this.state.techStars,
        user_group_id: this.state.invoiceRefUserGroupId
      },
      this.state.roomId);

      amplitude.getInstance().logEvent('Feedback - sending succeeded', analyticsParams);
    } catch (e) {
      console.error(e);

      if (e.response) {
        analyticsParams['HTTP status code'] = String(e.response.status);
      }

      amplitude.getInstance().logEvent('Feedback - sending failed', analyticsParams);
      await showError(i18n('failedToSendFeedback'));
    }

    this.leave();
  };

  continueInterpretation = async () => {
    this.setState({buttonDisabled: true});

    try {
      const chatRoom = await continueSession(this.state.roomId);
      amplitude.getInstance().logEvent('Continue chat room');

      const unlisten = this.props.appContext.history.listen(() => {
        if (window.location.pathname === '/call') {
          this.setState({buttonDisabled: false});
          unlisten();
        }
      });

      this.props.appContext.history.replace(
        '/call', {
        callerName: '',
        languageName: chatRoom.language.name,
        secondsLeft: this.state.secondsLeft,
        apiKey: chatRoom.apiKey,
        chatToken: chatRoom.chatToken,
        sessionId: chatRoom.sessionId,
        roomId: chatRoom.id,
        continuesRoomId: chatRoom.id
      });
    } catch (e) {
      this.setState({buttonDisabled: false});

      const analyticsParams = {
        'chat room id': this.state.roomId
      };

      if (e.response) {
        analyticsParams['HTTP status code'] = String(e.response.status);
      }

      if (e.message === 'Session expired') {
        this.sessionExpired();
      } else if (e.message === 'Too late') {
        showError(i18n('feedbackTooLate'));
      } else if (e.message === 'No minutes left') {
        showError(i18n('noTimeLeft'));
      } else {
        showError(i18n('failedToCreateChatroom'));
      }

      amplitude.getInstance().logEvent('Continue chat room failed', analyticsParams);
    }
  };

  skip = () => {
    const user = this.props.appContext.user;

    if (user.userGroup) {
      if (user.userGroup.invoiceRefMandatory) {
        amplitude.getInstance().logEvent('Feedback - skip - invoice ref missing');
        showError(i18n('invoiceRefMandatory'));
        return;
      } else if (user.userGroup.invoiceRefUserGroupMandatory) {
        amplitude.getInstance().logEvent('Feedback - skip - invoice ref user group not selected');
        showError(i18n('invoiceRefUserGroupMandatory'));
        return;
      }
    }

    amplitude.getInstance().logEvent('Feedback - skip');
    this.leave();
  };

  leave = () => {
    this.clearTimeout(this.state.ivHideContinue);
    this.props.appContext.history.replace(this.props.appContext.user.type === 'Interpreter' ? '/interpreter' : '/');
  };

  onInvoiceRefChanged = invoiceRef => {
    this.setState({invoiceRef});

    const user = this.props.appContext.user;

    if (user.invoiceRefUserGroups) {
      const selectedGroup = user.invoiceRefUserGroups.find(group => invoiceRef.toLowerCase().startsWith(group.prefix.toLowerCase()));

      if (selectedGroup) {
        if (selectedGroup.id !== this.state.invoiceRefUserGroupId) {
          this.setState({
            invoiceRefUserGroupId: selectedGroup.id,
            invoiceRefUserGroupPicked: false
          });
        }
      } else if (!this.state.invoiceRefUserGroupPicked && this.state.invoiceRefUserGroupId !== null) {
        this.setState({
          invoiceRefUserGroupId: null
        });
      }
     }
  };

  onFeedbackChanged = e => {
    this.setState({feedback: e.target.value});
  };

  onActionClicked = () => {
    this.shouldSkip() ? this.skip() : this.send();
  };

  shouldSkip = () => {
    return this.state.techStars === 0 && this.state.subscriberStars === 0 && this.state.feedback.length === 0 && this.state.invoiceRef.length === 0 && !this.state.invoiceRefUserGroupPicked;
  };

  onFeedbackDescClicked = () => {
    this.setState({feedbackDescVisible: true});
  };

  onChangeInvoiceRefUserGroup = e => {
    this.setState({
      invoiceRefUserGroupId: e.target.value || null,
      invoiceRefUserGroupPicked: Boolean(e.target.value)
    });
  };

  render() {
    const user = this.props.appContext.user;
    const interpreter = user === 'Interpreter';

    const businessType = ((Boolean(user.userGroup) && Boolean(user.userGroup.businessType)) ? user.userGroup.businessType.id : '').toLowerCase();

    return (
      <StyledCenteredBlockContainer>
        <StyledDialogBlock>
          <Header user={this.props.appContext.user} noButtons={true} />
          <div className='dialog'>
            <ContinueInterpretation visible={this.state.showContinue} onClick={this.continueInterpretation} />

            <AdditionalInfo
              interpreter={interpreter}
              visible={true}
              subscriberStars={this.state.subscriberStars}
              techStars={this.state.techStars}
              subscriberStarsClicked={this.subscriberStarsClicked}
              techStarsClicked={this.techStarsClicked}
              feedbackRef={this.feedback}
              onFeedbackChanged={this.onFeedbackChanged}
              feedbackValue={this.state.feedback}
              invoiceRefInputRef={this.invoiceRefInput}
              invoiceRef={this.state.invoiceRef}
              feedbackDescVisible={this.state.feedbackDescVisible}
              onFeedbackDescClicked={this.onFeedbackDescClicked}
              onInvoiceRefChanged={this.onInvoiceRefChanged}
              businessType={businessType}
              invoiceRefMandatory={user.userGroup && user.userGroup.invoiceRefMandatory}
              invoiceUserGroupsRef={this.invoiceUserGroupsRef}
              invoiceUserGroups={user.invoiceRefUserGroups}
              onChangeInvoiceRefUserGroup={this.onChangeInvoiceRefUserGroup}
              invoiceUserGroupId={this.state.invoiceRefUserGroupId}
            />

            <button className='action giveRatingCustomer' onClick={this.onActionClicked}>{i18n(this.shouldSkip() ? 'skip' : 'proceed')}</button>
          </div>
        </StyledDialogBlock>
      </StyledCenteredBlockContainer>
    );
  }
}

const AdditionalInfo = props => {
  if (!props.visible) {
    return null;
  }

  const subscriberQuality = i18n(props.interpreter ? 'interpreterRatingCustomer' : 'customerRatingInterpreter');

  const subscriberStarsTitle = <h3 className='sub'>{subscriberQuality}</h3>;
  const subscriberStars = <Stars onClick={star => props.subscriberStarsClicked(star)} value={props.subscriberStars} />;

  const techStarsTitle = <h3 className='sub'>{i18n('ratingQuality')}</h3>;
  const techStars = <Stars onClick={star => props.techStarsClicked(star)} value={props.techStars} />;

  const feedbackDesc = props.feedbackDescVisible ? (
    <span>
      <h3 className='sub'>{i18n('feedback')}</h3>
      <textarea id='customerFeedback' placeholder={i18n('feedbackDesc')} ref={props.feedbackRef} value={props.feedbackValue} onChange={props.onFeedbackChanged}></textarea>
    </span>
  ) : null;
  const feedbackDescLink = !props.feedbackDescVisible ? <a href='#!' onClick={props.onFeedbackDescClicked}>{i18n('feedbackButton')}</a> : null;

  return (
    <div>
      {subscriberStarsTitle}
      {subscriberStars}

      {techStarsTitle}
      {techStars}

      <InvoiceRef
        invoiceRefMandatory={props.invoiceRefMandatory}
        businessType={props.businessType}
        refValue={props.invoiceRefInputRef}
        invoiceUserGroupsRef={props.invoiceUserGroupsRef}
        invoiceUserGroups={props.invoiceUserGroups}
        value={props.invoiceRef}
        invoiceUserGroupId={props.invoiceUserGroupId}
        onChange={props.onInvoiceRefChanged}
        onChangeInvoiceRefUserGroup={props.onChangeInvoiceRefUserGroup} />

      {feedbackDesc}
      {feedbackDescLink}
  </div>);
};

const ContinueInterpretation = props => {
  if (props.visible === true) {
    return <button className='action' onClick={props.onClick}>{i18n('continueInterpretation')}</button>;
  }

  return null;
};

const Stars = props => {
  if (ohjaa) {
    return (
      <span>
        <input type='range' className='slider' min='0' max='10' step='1' value={props.value} onChange={e => props.onClick(parseInt(e.target.value))} />
        <div className='steps'>{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(index => (<div className='step' key={'step_' + Math.random() + '#' + index}>{index}</div>))}</div>
      </span>
    );
  }

  return (<ul className='stars'>
    {[1, 2, 3, 4, 5].map(index => (<li className={'star ' + (index <= props.value ? 'selected' : '')} onClick={() => props.onClick(index)} key={'star_' + Math.random() + '#' + index}></li>))}
  </ul>);
};

const InvoiceRef = props => {
  if (!props.invoiceRefMandatory &&
      props.businessType !== Ids.businessTypes.b2b &&
      props.businessType !== Ids.businessTypes.demo &&
      props.businessType !== Ids.businessTypes.devDemo &&
      props.businessType !== Ids.businessTypes.tulkaPremium &&
      props.businessType !== Ids.businessTypes.tapani)
  {
    return null;
  }

  const invoiceUserGroups = props.invoiceUserGroups && props.invoiceUserGroups.length > 0 ? (
    <select className='select' value={props.invoiceUserGroupId || ''} ref={props.invoiceUserGroupsRef} onChange={props.onChangeInvoiceRefUserGroup}>
      <option value=''>{i18n('selectInvoiceRefUserGroup')}</option>
      {props.invoiceUserGroups.map(group => (<option value={group.id} key={group.id}>{group.name}</option>))}
    </select>) : null;

  return (
    <span>
      <textarea id='customerInvoiceRef' placeholder={i18n('customerInvoiceNotes')} maxLength='255' ref={props.refValue} value={props.value} onChange={e => props.onChange(e.target.value)}></textarea>
      {invoiceUserGroups}
    </span>
  );
};

AdditionalInfo.propTypes = {
  interpreter: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  subscriberStars: PropTypes.number.isRequired,
  techStars: PropTypes.number.isRequired,
  feedbackRef: PropTypes.object.isRequired,
  subscriberStarsClicked: PropTypes.func.isRequired,
  techStarsClicked: PropTypes.func.isRequired,
  feedbackValue: PropTypes.string.isRequired,
  onFeedbackChanged: PropTypes.func.isRequired,
  businessType: PropTypes.string.isRequired,
  invoiceRefInputRef: PropTypes.object.isRequired,
  invoiceRef: PropTypes.string.isRequired,
  onInvoiceRefChanged: PropTypes.func.isRequired,
  invoiceRefMandatory: PropTypes.bool.isRequired,
  feedbackDescVisible: PropTypes.bool.isRequired,
  onFeedbackDescClicked: PropTypes.func.isRequired,
  invoiceUserGroupsRef: PropTypes.object,
  invoiceUserGroups: PropTypes.array,
  onChangeInvoiceRefUserGroup: PropTypes.func,
  invoiceUserGroupId: PropTypes.string
};

ContinueInterpretation.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

Stars.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

InvoiceRef.propTypes = {
  businessType: PropTypes.string.isRequired,
  refValue: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  invoiceRefMandatory: PropTypes.bool,
  invoiceUserGroupsRef: PropTypes.object.isRequired,
  invoiceUserGroups: PropTypes.array.isRequired,
  onChangeInvoiceRefUserGroup: PropTypes.func.isRequired,
  invoiceUserGroupId: PropTypes.string
};

const ContextWrapper = ({ ...props }) => (
  <FunctionsContext.Consumer>
    {functionsContext => (
      <AppContext.Consumer>
        {appContext => (
          <Feedback appContext={appContext} functionsContext={functionsContext} {...props} />
        )}
      </AppContext.Consumer>
    )}
  </FunctionsContext.Consumer>
);

export default ContextWrapper;

Feedback.propTypes = {
  appContext: PropTypes.object.isRequired,
  functionsContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

reactMixin.onClass(Feedback, TimerMixin);
