/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import i18n, { langCode, getIsRightToLeft, setLanguage, defaultLang } from '../i18n';
import { v4 as uuid } from 'uuid';
import { FullBackgroundImageContainer } from '../components/basicComponents';

import LanguageStep from '../components/LanguageStep';
import LuonaRedirectStep from '../components/LuonaRedirectStep';
import ChooseInterpreterStep from '../components/ChooseInterpreterStep';
import ServicesStep from '../components/ServicesStep';
import UrgencyStep from '../components/UrgencyStep';
import AuthorizationStep from '../components/AuthorizationStep';
import BookTimeStep from '../components/BookTimeStep';
import Spinner from '../components/Spinner';
import Icon from '../components/Icon';
import SelectLanguages from '../components/SelectLanguages';

import { LuonaWizardButton } from '../components/Buttons';

import { getUserData, postUserData, putUserData } from '../services/authLuona';
import { createMultiPrebook } from '../services/prebookService';
import { getLanguageList } from '../services/languageService';
import { getAvailabilityInterpreters } from '../services/interpretersService';
import { logAnalytics } from '../services/analyticsLogger';
import { getSelectableLanguage } from '../utils/languageUtils';
import { getData } from '../utils/localStorage';
import { stepsMap, buildWizardSteps } from '../utils/luonaStepsBuilder';

import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';
import AppContext from '../contexts/AppContext';

import { isToday } from '../utils/timeUtils';
import { showError } from '../utils/msgbox';
import { showSuccess } from '../services/toastService';
import { isPreviousChatroomOpen, rejoinChatroomLink } from '../utils/rejoinChatroom';
import { initAvaliableService, TARGET_LANGUAGE_ID, getIsNeedInterpreter } from '../utils/luonaSpecificData';

const dateFormat = 'YYYY-MM-DD';

const SectionWidthLimiter = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  width: 800px;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  color: #2b1d18;
`;

const StepContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 2 auto;
  margin-top: 10px;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  justify-content: space-between;
  flex: 0 1 auto;
  border-top: ${({ colors }) => '1px solid ' + colors.luonaBorderColor};
  ${({rtl}) => rtl && 'flex-flow: row-reverse;'}
`;

const GoBackButton = styled(LuonaWizardButton)`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  background: transparent;
  border: 1px solid ${({ borderColor }) => borderColor};
  color: ${({ borderColor }) => borderColor};
  border-radius: 16px;
`;

const SubmitButton = styled(LuonaWizardButton)`
  background-color: ${({ background }) => background};
  color: #fff;
  border-radius: 16px;
`;

const RejoinButton = styled(GoBackButton)`
  background: none;
  box-sizing: border-box;
  border: none;
  color: #000;
  margin-left: auto;
  padding: 0;
  display: flex;
  align-items: center;
  span {
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
  }
  .rejoinButton {
    background: ${({ background }) => background};
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
    border-radius: 50%;
    width: 100%;
    height: 100%;
    padding: 4px;
    margin-right: 4px;
  }
  .text {
    font-weight: 600;
    margin: 0;
    padding: 4px;
    border-bottom: 1px solid;
    text-transform: uppercase;
    @media ${({tablet})=> tablet} {
      font-size: 12px;
    }
  }
`;

const WelcomeMessage = styled.h3`
  color: #000;
  text-align: center;
  margin: 0;
  flex: 0 1 auto;
`;

const DefaultMessage = styled.p`
  color: rgba(0, 0, 0, .8);
  margin: 10px;
  font-size: 12px;
  flex: 0 1 auto;
  ${({rtl}) => rtl && 'text-align: right;'}
`;

const SwitchLangBlock = styled.div`
  margin-left: auto;
  width: 130px;
`;

const avaliableLangs = [
  { key: 'ua', label: 'Українська' },
  { key: 'ru', label: 'Русский' },
  { key: 'en', label: 'English' },
  { key: 'fi', label: 'Suomi' },
  { key: 'ar', label: 'اَلْعَرَبِيَّةُ' },
  { key: 'ku', label: 'سۆرانی' }
];

const getInitStep = (state, stepsList, isAuthorized) => {
  const stepName = state?.stepName || (isAuthorized && stepsMap.servicesStep);
  const incomingStep = stepsList.find(stepItem => stepItem.name === stepName);
  const stepNumber = stepsList.indexOf(incomingStep);
  return stepNumber + 1 || 1;
};

  const LuonaWizard = () => {
  const { colors, layout} = useContext(ThemeContext);
  const { updateUser } = useContext(FunctionsContext);
  const { user } = useContext(AppContext);
  const isAuthorized = !!user?.token;
  const { state } = useLocation();
  const isRedirectFromMeeting = state?.stepName === stepsMap.bookTimeStep;
  const [userData, setUserData] = useState({
    name: '',
    phone: '',
    email: '',
    locale: langCode,
  });

  const [skill, setSkill] = useState(state?.skill);
  const [subStep, setSubStep] = useState(0);
  const [stepsList, setStepsList] = useState(buildWizardSteps(isAuthorized, {on_demand: true, prebooking: true}, userData.locale));
  const [step, setStep] = useState(getInitStep(state, stepsList, isAuthorized));
  const [loading, setLoading] = useState(false);
  window.history.replaceState({}, document.title);

  const [serviceToShow, setServiceToShow] = useState([...initAvaliableService]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedTime, setSelectedTime] = useState(state?.skill ? moment().add(1, 'days') : null);
  const [selectedFreeTimeSlot, setSelectedFreeTimeSlot] = useState(null);
  const [createdPrebook, setCreatedPrebook] = useState(null);
  const [disabledNext, setDisabledNext] = useState(true);
  const [previousChatRoom] = useState(getData('previousChatroom'));
  const [isShowRejoin, setIsShowRejoin] = useState(false);
  const [isShowSignInCode, setIsShowSignInCode] = useState(false);
  const [signInCode, setSignInCode] = useState(null);
  const [avaliableForInterpretation, setAvaliableForInterpretation] = useState({on_demand: true, prebooking: true});
  const [ , setIsAvailableTimeSlots] = useState(false);

  let checkRejoinInterval = null;
  const history = useHistory();
  const languageTagMap = {
    ru: 'rus',
    en: 'eng',
    fi: 'fin',
    ua: 'rus',
    ar: 'ara',
    ku: 'ckb'
  };

  const [isNeedInterpreter, setIsNeedInterpreter] = useState(getIsNeedInterpreter(userData.locale));
  let latestSkill = null;

  const isExistStepName = stepName => stepsList.find(item => item.name === stepName);

  const getLangsMap = (selectableLanguagesParam) => {
    const localeForMeeting = getIsNeedInterpreter(userData.locale) ? userData.locale : defaultLang;
    const genericLanguageId = selectableLanguagesParam.find(lang => lang.languageTag ==='default' || lang.languageTag ==='lna').id;
    const targetLanguageId = TARGET_LANGUAGE_ID;
    const defaultLanguageId = selectableLanguagesParam.find(lang => lang.languageTag === 'rus').id;
    const interpretedLanguageId = selectableLanguagesParam.find(lang => lang.languageTag === languageTagMap[localeForMeeting])?.id || defaultLanguageId;
    return {
      genericLanguageId,
      targetLanguageId,
      interpretedLanguageId
    };
  };

  const getLanguageSet = async () => {
    if (user.selectableLanguages) {
      return getLangsMap(user.selectableLanguages);
    }
    const json = await getLanguageList();
    const selectableLanguagesByUser = getSelectableLanguage(json.selectableLanguages);
    return getLangsMap(selectableLanguagesByUser);
  };

  const getUserDate = async () => {
    const id = localStorage.getItem('luonaId');
    try {
      if (id) {
        const data = await getUserData(id);
        setUserData(prev => ({ ...prev, ...data }));
      }
      setLoading(false);
    } catch (error) {
      console.log('ERROR: ', error);
      logAnalytics('Error get user data', {
        error: error,
        ...(user?.id ? {
          userId: user?.id
        } : {})
      });
      setLoading(false);
    }
  };

  const startPolling = async (roomId) => {
    checkRejoinInterval = setTimeout(async() => {
      if (roomId) {
        const isOpen = await isPreviousChatroomOpen(previousChatRoom, true);
        if (isOpen) {
          setIsShowRejoin(true);
          startPolling(roomId);
        } else {
          clearTimeout(checkRejoinInterval);
          localStorage.removeItem('previousChatroom');
          setIsShowRejoin(false);
        }
      }
    }, 2000);
  };

  async function setRejoinChatroom(){
    const isOpen = await isPreviousChatroomOpen(previousChatRoom, true);
    setIsShowRejoin(isOpen);
  }

  const updateStepListIfNeeded = (available, skillId) => {
    const { on_demand, prebooking } = available;
    setStepsList(buildWizardSteps(isAuthorized, { on_demand, prebooking }, userData.locale));
    if (on_demand && !prebooking) {
      redirectToMeeting(skillId);
    }
  };

  const setAvailability = async (skillId) => {
    try {
      setLoading(true);
      const { genericLanguageId } = await getLanguageSet();
      const result = await getAvailabilityInterpreters(genericLanguageId, skillId);
      setAvaliableForInterpretation(result);
      setLoading(false);
      updateStepListIfNeeded(result, skillId);
    } catch (e) {
      logAnalytics('Error get workers availability', {
        error: e?.message || e,
        userId: user?.id
      });
      updateStepListIfNeeded({}, skillId);
    }
  };

  useEffect(() => {
    logAnalytics('Change step', {
      curentStepName: stepsList[step - 1]?.name,
      ...(user?.id ? {
        userId: user?.id
      } : {})
    });
  }, [step]);

  useEffect(() => {
    logAnalytics('Selected sub step', {
      subStep,
      ...(user?.id ? {
        userId: user?.id
      } : {})
    });
  }, [subStep]);

  useEffect(() => {
    logAnalytics('Services selected', {
      servicesLabels: selectedServices.map(s => s.label),
      servicesSkills: selectedServices.map(s => s.skill),
      ...(user?.id ? {
        userId: user?.id
      } : {})
    });
  }, [selectedServices]);

  useEffect(() => {
    const list = buildWizardSteps(isAuthorized, avaliableForInterpretation, userData.locale);
    setStepsList(list);
    if (isRedirectFromMeeting) {
      try {
        const curentStepName = stepsList[step - 1]?.name;
        setStep(getInitStep({stepName: curentStepName}, list, isAuthorized));
      } catch (e) {
        console.log('ERROR: ', e);
      }
    }
  }, [userData.locale]);

  useEffect(() => {
    setStepsList(buildWizardSteps(!!user.token, avaliableForInterpretation, userData.locale));
  }, [user.token]);

  useEffect(() => {
    if (previousChatRoom?.id) {
      setRejoinChatroom();
      startPolling(previousChatRoom?.id);
    }
    return (() => {
      clearTimeout(checkRejoinInterval);
    });
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      setLoading(true);
      getUserDate();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateUserDate = (name, value) => {
    setUserData(prev => {
      return { ...prev, [name]: value };
    });
  };

  const setNextStep = curentStep => {
    if (curentStep < stepsList.length) {
      setStep(curentStep + 1);
    }
  };

  const setPrevStep = curentStep => {
    if (curentStep > 0) {
      setStep(curentStep - 1);
    }
  };

  const getStepComponent = useCallback(() => { // eslint-disable-line consistent-return
    const curentStep = stepsList[step - 1];

    let date = Date.now();
    let today = moment(new Date(date)).format(dateFormat);
    const startToRedirectInProd = moment('2023-12-01').format(dateFormat);
    const startToRedirectInDev = moment('2023-10-16').format(dateFormat);

    const env = process.env.REACT_APP_ENV || 'development';
    const startToRedirect = env === 'development' ? startToRedirectInDev : startToRedirectInProd;

    const isTimeToRedirect = moment(today).isSameOrAfter(startToRedirect, 'day');

    if (isTimeToRedirect) {
      return <LuonaRedirectStep />;
    }

    if (curentStep?.name === stepsMap.languageStep) {
      return (
        <LanguageStep
          selectedLang={userData.locale}
          setLang={setLang}
          handleNext={handleNext}
          avaliableLangs={avaliableLangs}
        />
      );
    }
    if (curentStep?.name === stepsMap.chooseInterpreterStep) {
      return (
        <ChooseInterpreterStep
          selectedChoiceInterpreter={isNeedInterpreter}
          setChoice={(choice) => {
            localStorage.setItem('isNeedChooseInterpreterStep', choice);
            setIsNeedInterpreter(choice);
          }}
          handleNext={handleNext}
        />
      );
    }
    if (curentStep?.name === stepsMap.servicesStep) {
      const selectedService = selectedServices[subStep];
      return (
        <ServicesStep
          serviceToShow={serviceToShow}
          selectedService={selectedService}
          handleNext={handleNext}
        />
      );
    }
    if (curentStep?.name === stepsMap.authorizationStep) {
      return (
        <AuthorizationStep
          isShowSignInCode={isShowSignInCode}
          updateSignInCode={(value) => {
            setSignInCode(value);
          }}
          signInCode={signInCode}
          userData={userData}
          setUserData={updateUserDate}
          setDisabledNext={setDisabledNext}
        />
      );
    }
    if (curentStep?.name === stepsMap.urgencyStep) {
      return (
        <UrgencyStep
          selectedTime={selectedTime}
          handleNext={handleNext}
          skill={skill || selectedServices[subStep]?.skill}
          getLanguageSet={getLanguageSet}
        ></UrgencyStep>
      );
    }

    if (curentStep?.name === stepsMap.bookTimeStep) {
      return (
        <BookTimeStep
          avaliableForInterpretation={avaliableForInterpretation}
          locale={userData.locale}
          skill={skill || selectedServices[subStep]?.skill}
          selectedFreeTimeSlot={selectedFreeTimeSlot}
          createdPrebook={createdPrebook}
          getLanguageSet={getLanguageSet}
          handleNext={handleNext}
          setIsAvailableTimeSlots={setIsAvailableTimeSlots}
          history={history}
        />
      );
    }
  }, [serviceToShow, selectedServices, step, userData, selectedTime, selectedFreeTimeSlot, createdPrebook, stepsList, loading, signInCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const setLang = async(lang) => {
    setLanguage(lang);
    setUserData(prev => ({ ...prev, locale: lang }));
  };

  useEffect(() => {
    async function updateUserData(){
      try {
        const userD = await putUserData(userData);
        setUserData(prev => ({ ...prev, ...userD }));
      } catch (err) {
        console.log(err);
      }
    }
    let updated = false;
    if (isAuthorized && userData.id && !updated) {
      updateUserData();
    }
    return () => {
      updated = true;
    };
  }, [userData.locale]);

  const redirectToMeeting = (skillId) => {
    localStorage.removeItem('previousChatroom');
    const localeForMeeting = getIsNeedInterpreter(userData.locale) ? userData.locale : defaultLang;
    let link = `/meetings/123?name=${userData.name || user.username}&locale=${localeForMeeting}&skill=${skillId || latestSkill}`;
    logAnalytics('Redirect to meeting', {
      name: userData.name || user.username,
      locale: localeForMeeting,
      skill: skillId || latestSkill,
      ...(user?.id ? {
        userId: user?.id
      } : {})
    });
    history.replace(link);
  };

  const clearSignInCode = () => {
    setIsShowSignInCode(false);
    setSignInCode(null);
  };

  const handServicesStep = async (servise) => {
    setSelectedServices(prev => {
      if (prev[subStep]) {
        return prev.map((item, idx) => {
          if (idx === subStep) {
            return servise;
          }
          return item;
        });
      }
      return [...prev, servise];
    });
    if (servise?.skill) {
      latestSkill = servise?.skill;
      setSkill(null);
      if (isAuthorized && !isExistStepName(stepsMap.chooseInterpreterStep)) {
        await setAvailability(servise?.skill);
      }
      nextStep();
    }
    if (servise?.children) {
      setSubStep(prev => prev + 1);
      setServiceToShow(() => {
        return [...servise?.children] || [];
      });
    }
  };

  const handleBack = () => {
    const curentStep = stepsList[step - 1];
    if (curentStep?.name === stepsMap.servicesStep) {
      const prevStep = subStep - 1;
      if (prevStep >= 0) {
        const arr = selectedServices.slice(0, prevStep + 1);
        const result = arr.reduce(
          (memo, serviceValue, idx) => {
            if (idx < arr.length - 1) {
              const foundService = memo.find(service => {
                return service.value === serviceValue.value;
              });
              return foundService?.children || [];
            }
            return memo;
          },
          [...initAvaliableService]
        );
        setSubStep(prev => prev - 1);
        setServiceToShow(() => {
          return [...result];
        });
      } else {
        setSubStep(0);
        setPrevStep(step);
      }
    }

    if (curentStep?.name === stepsMap.bookTimeStep) {
      if (isRedirectFromMeeting) {
        setStep(2);
        window.history.replaceState({}, document.title);
      } else {
        setPrevStep(step);
        setCreatedPrebook(false);
        window.history.replaceState({}, document.title);
      }
    }

    if (curentStep?.name === stepsMap.authorizationStep) {
      clearSignInCode();
      setPrevStep(step);
    }

    if (curentStep?.name === stepsMap.chooseInterpreterStep) {
      setPrevStep(step);
    }

    if (curentStep?.name === stepsMap.urgencyStep) {
      setPrevStep(step);
    }
  };

  const setUserToState = async (luonaId, userObj) => {
    setUserData(prev => ({...prev, id: luonaId}));
    showSuccess(i18n('user_successfully_registered'));
    localStorage.setItem('luonaId', luonaId);
    localStorage.setItem('user', JSON.stringify(userObj));
    if (!isExistStepName(stepsMap.chooseInterpreterStep)) {
      await setAvailability(skill || selectedServices[subStep]?.skill);
    }
    await updateUser({...userObj});
    setLoading(false);
  };

  const passSignIncode = async () => {
    setLoading(true);
    try {
      const responseUser = await postUserData({ ...userData, name: trimUserName(userData.name), signInCode });
      const { token, luonaId } = responseUser;
      await setUserToState(luonaId, {...responseUser.user, token});
      clearSignInCode();
    } catch (e) {
      const match = e?.message.match(/Not\sfound\suser*/);
      showError(i18n(match ? 'requestErrorMessageUserNotFound' : 'requestErrorMessage'));
      logAnalytics('Error pass signin code', {
        error: e?.message || e.message,
        ...(user?.id ? {
          userId: user?.id
        } : {})
      });
      setLoading(false);
      clearSignInCode();
    }
  };

  const toRegisterUser = async () => {
    setLoading(true);
  try {
    const luonaId = uuid();
    const responseUser = await postUserData({ ...userData, name: trimUserName(userData.name), id: luonaId });
    if (responseUser.signInCode) {
      setIsShowSignInCode(true);
      setLoading(false);
    } else {
      const { token } = responseUser;
      await setUserToState(luonaId, {...responseUser.user, token});
    }
  } catch (error) {
      const match = error?.message.match(/Error:\snot\sunique\susername.*/);
      showError(i18n(match ? 'requestErrorMessageUserExist' : 'requestErrorMessage'));
      logAnalytics('Error authorization user', {
        error: error?.message || error,
        ...(user?.id ? {
          userId: user?.id
        } : {})
      });
      setLoading(false);
  }
};

const toSetPrebook = value => {
  setSelectedTime(value);
  const selectedService = selectedServices[subStep];
  if (isToday(value.date) && value.value === 'help_need_today' && isAuthorized) {
    redirectToMeeting(selectedService?.skill);
  } else {
    nextStep();
  }
};

// eslint-disable-next-line consistent-return
const trimUserName = (value) => {
  if (typeof value === 'string') {
    return value.trim();
  }
  return value;
};

  const handleNext = async(value) => { // eslint-disable-line consistent-return
    const curentStep = stepsList[step - 1];
    if (curentStep?.name === stepsMap.languageStep) {
      nextStep();
    }
    if (curentStep?.name === stepsMap.chooseInterpreterStep) {
      if (isAuthorized) {
        await setAvailability(skill || selectedServices[subStep]?.skill);
      }
      nextStep();
    }
    if (curentStep?.name === stepsMap.servicesStep) {
      handServicesStep(value);
    }
    if (curentStep?.name === stepsMap.authorizationStep) {
      if (isShowSignInCode) {
        return passSignIncode();
      }
      return toRegisterUser();
    }
    if (curentStep?.name === stepsMap.urgencyStep) {
      toSetPrebook(value);
    }
    if (curentStep?.name === stepsMap.bookTimeStep) {
      setSelectedFreeTimeSlot(value);
      setLoading(true);
      const languageSet = await getLanguageSet();
      try {
        const prebookings = await createMultiPrebook({
          ...languageSet,
          date: value,
          interpreterId: value?.interpreterId,
          interpreterUserId: value?.interpreterUserId,
          skill: skill || selectedServices[subStep]?.skill || latestSkill,
        });
        logAnalytics('Created multi prebook', {
          date: value,
          skill: skill || selectedServices[subStep]?.skill || latestSkill,
          userId: user?.id
        });
        setCreatedPrebook(!!prebookings);
        setLoading(false);
      } catch (err) {
        logAnalytics('Error create multi prebook', {
          error: err?.message || err,
          userId: user?.id
        });
        if (err.message === 'Slot not free anymore'){
          showError(i18n('slot_not_free_anymore'));
        }
        showError(i18n('requestErrorMessage'));
        setLoading(false);
      }
    }
  };

  const nextStep = () => setNextStep(step);

  const getWelcomeMessage = () => {
    const curentStep = stepsList[step - 1];
    if (curentStep?.name === stepsMap.servicesStep) {
      return i18n('wizard_welcome_message');
    }
    if (curentStep?.name === stepsMap.bookTimeStep && curentStep?.header && !createdPrebook) {
      return i18n('wizard_TimeStep_header');
    }
    if (curentStep?.name === stepsMap.urgencyStep) {
      return i18n('wizard_PrebookingStep_header');
    }
    return '';
  };

  const getDefaultMessage = () => {
    const curentStep = stepsList[step - 1];
    const pages = [
      stepsMap.servicesStep,
      stepsMap.bookTimeStep,
      stepsMap.urgencyStep,
      stepsMap.authorizationStep,
    ];
    const hasDefaultMessage = pages.some(page => curentStep?.name === page);
    if (hasDefaultMessage) {
      return i18n('wizard_default_message');
    }
    return '';
  };

  const isShowMyPrebookingsButton = () => {
    const currentStepName = stepsList[step - 1]?.name;
    const isShowOnServicesStep = currentStepName === stepsMap.servicesStep;
    return isAuthorized && isShowOnServicesStep;
  };

  const isShowSwitchLanguageButton = () => {
    const stepsWithButton = Object.values(stepsMap).filter(stepItem => stepItem !== stepsMap.languageStep);
    const currentStepName = stepsList[step - 1]?.name;
    return stepsWithButton.includes(currentStepName);
  };

  const searchStepIdxByName = (stepName) => {
    return stepsList.findIndex((stepItem) => stepItem.name === stepName);
  };

  const handleSwithcLanguage = (obj) => {
    setCreatedPrebook(false);
    setLang(obj.value);
    const stepIdx = searchStepIdxByName(stepsMap.servicesStep);
    if (stepIdx >= 0) {
      setStep(stepIdx + 1);
    }
  };

  const isPreviousLanguageStep = () => {
    return stepsList[step - 2]?.name === stepsMap.languageStep && subStep === 0;
  };

  return (
    <>
      {loading && (
        <Spinner overlay={true} color={colors.luonaMainBrightColor} />
      )}
      <FullBackgroundImageContainer background='rgb(252, 252, 252)'>
      <SectionWidthLimiter>
        {isShowSwitchLanguageButton() && (
          <SwitchLangBlock>
            <SelectLanguages
              selectedLanguage={userData.locale}
              avaliableLangs={avaliableLangs}
              handleSwithcLanguage={handleSwithcLanguage}
            />
          </SwitchLangBlock>
        )}
        <WelcomeMessage data-test={`welcomeMessageFor${stepsList[step - 1]?.name}`}>{getWelcomeMessage()}</WelcomeMessage>
        <StepContainer>
          {isShowRejoin && (
            <RejoinButton
              visible={true}
              borderColor={colors.luonaSuccessColor}
              background={colors.luonaSuccessColor}
              type='button'
              onClick={() => {
                clearTimeout(checkRejoinInterval);
                const link = rejoinChatroomLink(previousChatRoom.id);
                history.push(link);
              }}
            >
              <Icon
                icon='phone'
                iconLib='fontAwesome'
                iconColor={colors.webDarkBlue}
                iconSize={14}
                withoutContainer={false}
                className='rejoinButton'
              />
              <p className='text' tablet={layout.devicesMedia.tablet}>
                {i18n('rejoin_meeting')}
              </p>
          </RejoinButton>
          )}
          {getStepComponent()}
        </StepContainer>
        {isShowMyPrebookingsButton() && (
          <SubmitButton
            data-test='myPrebookingListBtn'
            borderColor={colors.luonaMainBrightColor}
            onClick={() => history.push('/prebooking_luona')}
          >
            {i18n('my_prebooking_list')}
          </SubmitButton>
        )}
        <DefaultMessage data-test={`alarmMessageFor${stepsList[step - 1]?.name}`} rtl={getIsRightToLeft()}>{getDefaultMessage()}</DefaultMessage>
        <ButtonsContainer colors={colors} rtl={getIsRightToLeft()}>
        {!isPreviousLanguageStep() && (
          <GoBackButton
            data-test={`backButtonFor${stepsList[step - 1]?.name}`}
            borderColor={colors.luonaMainBrightColor}
            visible={step > 1}
            onClick={handleBack}
          >
            {i18n('back')}
          </GoBackButton>
        )}
          {stepsList[step - 1]?.name === stepsMap.authorizationStep && (
              <SubmitButton
                data-test={`submitButtonFor${stepsList[step - 1]?.name}`}
                background={colors.luonaMainBrightColor}
                disabled={disabledNext}
                onClick={handleNext}
              >
                {i18n('next')}
              </SubmitButton>
            )}
          </ButtonsContainer>
        </SectionWidthLimiter>
      </FullBackgroundImageContainer>
    </>
  );
};


export default LuonaWizard;
