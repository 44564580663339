import React, { useContext, useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Spinner from '../components/Spinner';
import HeaderText from '../components/HeaderText';

import i18n from '../i18n';
import withLeftSideBar from '../hocs/withLeftSideBar';
import extranetPagesConfigurator from '../utils/extranetPagesConfigurator';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';

const StyledIframe = styled.iframe`
  display: block;
  border: none;
  width: 100%;
  min-width: 100%;
  height: 100%;
`;

const StyledBlock = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;

const StyledTabs = styled(({ className, ...rest }) => (<Tabs className={['react-tabs', className]} {...rest}/>))`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  .react-tabs__tab-panel {
    display: none;
    width: 100%;
    height: 100%;
    &.react-tabs__tab-panel--selected {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
  .react-tabs__tab-list {
    margin: 0;
    border-bottom: 1px solid rgb(222, 225, 230);
    padding: 0 8px;
    .react-tabs__tab {
      color: rgb(138, 138, 148);
      border: none;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 20px;
      font-size: 16px;
      &.react-tabs__tab--selected, &:hover {
        transition: ${({ transitions }) => (transitions)};
        background-color: rgb(58, 56, 91);
        color: #ffffff;
      }
    }
  }
`;

const MessageContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
`;

const StyledHeaderText = styled(HeaderText)`
  width: 100%;
  white-space: pre-wrap;
  text-align: center;
`;

const StyledContainerTabs = styled.div`
  overflow: hidden;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const isNotSupportedBrowser = () => {
  const ua = navigator.userAgent;
  const vendor = navigator.vendor;
  return ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1 || ua.indexOf('safari') > -1 || (/Safari/i.test(ua) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(ua));
};

const OrderHistoryIframe = ({ pageId, type, user, styles }) => {
  const ref = useRef(null);
  const countRef = useRef(0);
  const [aStyles] = useState(styles ? {
    ...styles,
    'min-height': styles.height
  } : {});
  const pageUrl = pageId ? (
    type === 0 ? `https://tulka.domo.com/embed/pages/private/${pageId}` : `https://tulka.domo.com/embed/card/private/${pageId}`
  ) : user.orderHistoryUrl;
  const [src] = useState(`https://extranet.tulka.com/setTulkaSessionAndRedirect/${user?.token}?jumpto=${pageUrl}`);

  useEffect(() => {
    document.dispatchEvent(new CustomEvent('iframeStartLoaded', {
      detail: {
        id: pageId
      }
    }));
  }, [pageId]);

  const dispatchEvent = () => {
    countRef.current++;
    if (type === 1 || countRef.current > 1) {
      document.dispatchEvent(new CustomEvent('iframeLoaded', {
        detail: {
          id: pageId
        }
      }));
    }
  };

  useLayoutEffect(() => {
    const currentRef = ref.current;
    currentRef.addEventListener('load', dispatchEvent);
    return () => {
      currentRef.removeEventListener('load', dispatchEvent);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledIframe
      id={pageId}
      key={pageId}
      src={src}
      ref={ref}
      style={aStyles}
    />
  );
};

const OrderHistory = () => {
  const { user } = useContext(AppContext);
  const { layout } = useContext(ThemeContext);

  const [indicator, setIndicator] = useState(true);
  const [pages] = useState(extranetPagesConfigurator(user));
  const [showMessage] = useState(isNotSupportedBrowser());

  useLayoutEffect(() => {
    function receiveMessage() {
      setIndicator(false);
    }

    document.addEventListener('iframeLoaded', receiveMessage, false);
    return () => {
      document.removeEventListener('iframeLoaded', receiveMessage, false);
    };
  }, []);

  useLayoutEffect(() => {
    function receiveMessage() {
      setIndicator(true);
    }

    document.addEventListener('iframeStartLoaded', receiveMessage, false);
    return () => {
      document.removeEventListener('iframeStartLoaded', receiveMessage, false);
    };
  }, []);

  useLayoutEffect(() => {
    function receiveMessage(event) {
      const origins = ['https://embed.domo.com', 'https://public.domo.com'];
      if (origins.includes(event.origin)) {
        setIndicator(false);
      }
    }

    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, []);

  const pagesBlock = useMemo(() => {
    return pages.map((aPage, index) => {
      const subPages = aPage.subPages;

      return (
        <TabPanel
          key={index}
        >
          {
            subPages.map((subPage) => {
              if (subPage.type === 2) {
                return (
                  <br key={subPage.id}/>
                );
              }
              return (
                <OrderHistoryIframe
                  pageId={subPage.id}
                  type={subPage.type}
                  user={user}
                  key={subPage.id}
                  styles={{
                    width: subPage.width,
                    height: subPage.height
                  }}
                />
              );
            })
          }
        </TabPanel>
      );
    });
  }, [user, pages]);

  return (
    <StyledBlock>
      {showMessage ? (
        <MessageContainer>
          <StyledHeaderText
            text={i18n('your_browser_is_not_supported')}
            fontSize={layout.headerFontSize}
          />
          <StyledHeaderText
            text={i18n('we_recommend_using_the_latest_version_of_chrome_or_firefox_in_order_to_continue_using_this_service')}
            fontSize={layout.headerFontSize}
          />
        </MessageContainer>
      ) : null}
      {(pages && pages.length) ? (
        <>
          <StyledTabs
            transitions={layout.transition}
          >
            <TabList>
              {pages.map((aPage, index) => (
                <Tab key={index}>{aPage.name}</Tab>
              ))}
            </TabList>
            <StyledContainerTabs>
              {pagesBlock}
              {indicator ? (
                <Spinner
                  overlay
                />
              ) : null}
            </StyledContainerTabs>
          </StyledTabs>
        </>
      ) : null}
      {!(pages && pages.length) ? (
        <>
          <OrderHistoryIframe
            user={user}
          />
          {indicator ? (
            <Spinner
              overlay
            />
          ) : null}
        </>
      ) : null}
    </StyledBlock>
  );
};

export default withLeftSideBar(OrderHistory);
