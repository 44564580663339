import React, { useContext } from 'react';
import styled from 'styled-components';

import IconButton from './IconButton';

import ThemeContext from '../contexts/ThemeContext';

const StyledCollaboratorsList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledCollaboratorsListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  font-size: ${({ fontSize }) => (fontSize)}px;
  color: rgb(21, 21, 41);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
`;

const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  & > div {
    width: 100%;
    height: 100%;
    margin: auto;
    & > i {
      color: ${({ iconColor }) => (iconColor)} !important;
    }
  }
  &:hover {
    background: #1F1F3F;
    & > div > i {
      color: ${({ hoverColor }) => (hoverColor)} !important;
    }
  }
`;

const CollaboratorsList = ({ collaborators, removeCollaborator }) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  return (
    <StyledCollaboratorsList>
      {collaborators.map((collaborator) => (
        <StyledCollaboratorsListItem
          key={collaborator.id}
          fontSize={layout.fontSize}
          data-test='collaboratorItem'
        >
          {`${collaborator.name} (${collaborator.email})`}
          <StyledIconButton
            icon="delete"
            iconSize={20}
            iconLib="material"
            iconColor={colors.webGray}
            hoverColor="#ffffff"
            onClick={() => removeCollaborator(collaborator.targetUserId)}
            data-test='removeCollaboratorButton'
          />
        </StyledCollaboratorsListItem>
      ))}
    </StyledCollaboratorsList>
  );
};

export default CollaboratorsList;
