import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import ThemeContext from '../contexts/ThemeContext';
import i18n from '../i18n';
import Spinner from './Spinner';
import { WizardList, WizardItem } from '../components/WizardList';
import { WizardStepContainer, WizardStepTitle } from './WizardStep';
import { getFreeSlots } from '../services/prebookService';
import { SCHEDULE } from '../utils/luonaSpecificData';
import { NotificationPrebookCreated } from './LuonaWizardNotifications';
import { LuonaWizardButton } from './Buttons';
import styled from 'styled-components';

const SubmitButton = styled(LuonaWizardButton)`
  background-color: ${({ background }) => background};
  color: #fff;
  border-radius: 16px;
`;

const BookTimeStep = ({
  locale,
  skill,
  selectedFreeTimeSlot,
  createdPrebook,
  getLanguageSet,
  handleNext,
  avaliableForInterpretation,
  setIsAvailableTimeSlots,
  history
}) => {
  const [loading, setLoading] = useState(true);
  const { colors } = useContext(ThemeContext);
  const [timeSlots, setTimeSlots] = useState([]);
  moment.locale(locale === 'ua' ? 'uk' : locale);

  const getTimeSlots = async(avaliable) => {
    try {
      const { genericLanguageId } = await getLanguageSet();
      let result = [];
      if (avaliable?.prebooking) {
        result = await getFreeSlots({
          genericLanguageId,
          skillIds: [skill],
          startDate: moment().add(3, 'days')
        });
      }
      setTimeSlots(result);
      setIsAvailableTimeSlots(!!result.length);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setIsAvailableTimeSlots(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getTimeSlots(avaliableForInterpretation);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getLocalizationString = (key, time) => i18n(key) + ` ${time}`;

  const getDateString = (dateString) => {
    const isToday = moment(dateString).isSame(new Date(), 'day');
    const isTomorrow = moment(dateString) < moment().add(1, 'day').endOf('day');
    if (isToday) {
      return getLocalizationString('today', moment(dateString).format('HH:mm'));
    }
    if (isTomorrow) {
      return getLocalizationString('tomorrow', moment(dateString).format('HH:mm'));
    }
    return moment(dateString).format('DD MMMM HH:mm');
  };

  const getPrebookNotAvailableMessage = (skillId) => {
    const message = i18n('prebook_not_available_message');
    return message.replace('{SCHEDULE}', SCHEDULE[skillId]);
  };

  return (
    <>
    {loading && (<Spinner overlay={true} color={colors.luonaMainBrightColor} />)}
    {createdPrebook && (
      <WizardStepContainer>
        <NotificationPrebookCreated skill={skill} dateAndTime={getDateString(selectedFreeTimeSlot?.start)}>
          <SubmitButton
              data-test='myPrebookingListBtn'
              borderColor={colors.luonaMainBrightColor}
              onClick={() => history.push('/prebooking_luona')}
            >
              {i18n('my_prebooking_list')}
            </SubmitButton>
        </NotificationPrebookCreated>
      </WizardStepContainer>
    )}
    {!createdPrebook && timeSlots?.length > 0 && (
      <WizardStepContainer>
        <WizardStepTitle data-test='wizardStepTitleForBookTimeStep' label={i18n('choose_time')} />
        <WizardList data-test='wizardListForBookTimeStep'>
          {timeSlots.map((time) => (
            <WizardItem
              data-test={`wizardItem-${time.start}`}
              key={time.start}
              selectable={time.start === selectedFreeTimeSlot?.start}
              label={getDateString(time.start)}
              handleNext={() => handleNext(time)}
            />
          ))}
        </WizardList>
      </WizardStepContainer>
    )}
    {!loading && timeSlots?.length === 0 && (
      <WizardStepContainer>
        <div data-test='prebookNotAvaliableMessage'>{getPrebookNotAvailableMessage(skill)}</div>
      </WizardStepContainer>
    )}
    </>
  );
};

export default BookTimeStep;
