import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import ThemeContext from '../contexts/ThemeContext';

import i18n from '../i18n';

const StyledStatusContainer = styled.div`
  text-align: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 10px;
  padding: 8px 2px;
`;

const StyledStatusBadge = styled.span`
  font-size: ${({ fontSize }) => (fontSize)}px;
  color: ${({ color }) => color};
`;

const StatusBadge = ({ status }) => {
  const { layout } = useContext(ThemeContext);

  const [propsByStatus] = useState({
    confirmed: {
      key: 'confirmed',
      styles: {
        backgroundColor: '#eafee6',
        color: '#22D434'
      }
    },
    pending: {
      key: 'unconfirmed_prebooking',
      styles: {
        backgroundColor: '#fff3e7',
        color: '#FFAD62'
      }
    },
    completed: {
      key: 'completed_prebooking',
      styles: {
        backgroundColor: '#eafee6',
        color: '#22D434'
      }
    },
    delivered: {
      key: 'delivered',
      styles: {
        backgroundColor: '#eafee6',
        color: '#22D434'
      }
    },
    not_able_to_reach: {
      key: 'not_able_to_reach',
      styles: {
        backgroundColor: '#fff3e7',
        color: '#EA7E8F'
      }
    },
    cancelled: {
      key: 'cancelled',
      styles: {
        backgroundColor: 'rgba(234, 126, 143, 0.2);',
        color: '#EA7E8F'
      }
    },
  });
  const [currentStatus, setCurrentStatus] = useState(propsByStatus.pending);

  useEffect(() => {
    setCurrentStatus(propsByStatus[status] || propsByStatus.pending);
  }, [propsByStatus, status]);

  return (
    <StyledStatusContainer
      backgroundColor={currentStatus.styles.backgroundColor}
    >
      <StyledStatusBadge
        fontSize={layout.smallFontSize}
        color={currentStatus.styles.color}
      >
        {i18n(currentStatus.key)}
      </StyledStatusBadge>
    </StyledStatusContainer>
  );
};

export default StatusBadge;
