import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ChatWizardHeader from '../components/ChatWizardHeader';
import ContentBox from '../components/ContentBox';
import i18n from '../i18n';
import HomeUserGroupSelector from '../components/HomeUserGroupSelector';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const InvoiceTargeting = ({ value, onValueSelected, ...props }) => (
  <StyledContainer {...props}>
    <ChatWizardHeader
      title={i18n('invoice_cause')}
    />
    <ContentBox
      overlapping={true}
      minorTitle={i18n('invoice_cause_description')}
      centered={false}
    >
      <HomeUserGroupSelector
        id="invoiceTargeting"
        data-test='CostCenterSelectChatWizard'
        selectedUsergroup={value}
        setSelectedUsergroup={(selectedGroup) => onValueSelected(selectedGroup)}
      />
    </ContentBox>
  </StyledContainer>
);

InvoiceTargeting.propTypes = {
  onValueSelected: PropTypes.func.isRequired,
  value: PropTypes.object
};

export default InvoiceTargeting;
