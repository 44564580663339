import React, { useState, useContext } from 'react';
import qs from 'query-string';

import AppContext from '../contexts/AppContext';
import FunctionsContext from '../contexts/FunctionsContext';

const InviteHandle = () => {
  const appContext = useContext(AppContext);
  const functionsContext = useContext(FunctionsContext);

  const [loginInProcess, setLoginInProcess] = useState(false);

  const login = async (username, password) => {
    await functionsContext.login(username, password);
    setLoginInProcess(false);
  };

  if (!loginInProcess) {
    setLoginInProcess(true);
    const parsed = qs.parse(location.search);
    const valueToDecode = parsed.c || '';
    const decodedData = window.atob(valueToDecode);
    if (decodedData) {
      const parsedDecodedData = JSON.parse(decodedData);

      if (parsedDecodedData.email && parsedDecodedData.password) {
        login(parsedDecodedData.email, parsedDecodedData.password);
      } else {
        appContext.history.replace('/login');
      }
    } else {
      appContext.history.replace('/login');
    }
  }

  return (
    <div></div>
  );
};

export default InviteHandle;
