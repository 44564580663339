import { toast } from 'react-toastify';
import styled from 'styled-components';

import Icon from '../components/Icon';

import Layout from '../layout/Layout2020';

const StyledIcon = styled(Icon)`
  margin-right: 10px;
  & > i, & > span {
    color: ${({ color }) => (color)} !important;
  }
`;

export const showSuccess = (message, dataTest) => {
  toast.success(message, {
    toastId: dataTest,
    icon: () => (
      <StyledIcon
        color={'white'}
        margin={Layout.margin}
        iconLib={'material'}
        icon={'check'}
      />
    )
  });
};

export const showError = (message, dataTest) => {
  toast.error(message, {
    toastId: dataTest,
    icon: () => (
      <StyledIcon
        color={'white'}
        margin={Layout.margin}
        iconLib={'material'}
        icon={'error'}
      />
    )
  });
};

export const showInfo = (message, dataTest) => {
  toast.info(message, {
    toastId: dataTest,
    icon: () => (
      <StyledIcon
        color={'black'}
        margin={Layout.margin}
        iconLib={'material'}
        icon={'warning_amber'}
      />
    )
  });
};

export default {
  showSuccess,
  showError,
  showInfo
};
