import React, { useContext } from 'react';
import styled from 'styled-components';

import ThemeContext from '../contexts/ThemeContext';
import IconButton from './IconButton';

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: -50px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.35);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ModalDialogCloseButton = ({ onClick, ...rest }) => {
  const themeContext = useContext(ThemeContext);
  const colors = themeContext.colors;

  return (
    <StyledCloseButtonContainer
      {...rest}
    >
      <IconButton
        data-test='modalDialogCloseButton'
        hoverColor={colors.tulkaMainColor}
        icon={'close'}
        iconLib={'material'}
        onClick={onClick}
      />
    </StyledCloseButtonContainer>
  );
};

export default ModalDialogCloseButton;
