import React from 'react';
import styled from 'styled-components';

const ActionListContainer = styled.div`
  flex: 1;
  width: 100%;
  align-items: flex-start;
  flex-wrap: nowrap;
  ${({ flexList }) => flexList ? `
    flex: 0 1 auto;
    align-content: stretch;
  ` : ''}
  justify-content: flex-start;
  background-color: transparent;
`;

const ActionList = props => {
  const childrenArray = props.children ? (Array.isArray(props.children) ? props.children : [props.children]) : [];
  const flatChildren = [].concat.apply([], childrenArray);

  const children = flatChildren.filter(child => child && child.props && child.props.visible !== false);

  return (
    <ActionListContainer
      flexList={props.flexList}
    >
      {children}
    </ActionListContainer>
  );
};

export default ActionList;
