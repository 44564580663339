import React, { useContext } from 'react';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';

import ThemeContext from '../contexts/ThemeContext';

const StyledTooltipContainer = styled.div`
  width: 100%;
  max-width: 300px;
`;

const StyledTooltip = styled(Tooltip)`
  display: flex;
  justify-content: center;
`;

const StyledSimpleTooltip = styled(Tooltip)`
  font-size: ${({ fontSize }) => (fontSize)}px;
`;

const StyledTooltipText = styled.div`
  color: white;
  font-size: ${({ fontSize }) => (fontSize)}px;
  line-height: ${({ fontSize }) => (fontSize + 2)}px;
  text-align: left;
  font-weight: normal;
  word-wrap: break-word;
`;

export const CustomTooltip = ({ direction, children, text }) => {
  const { colors, layout } = useContext(ThemeContext);

  return (
    <StyledTooltip
      content={(
        <StyledTooltipContainer
          padding={layout.padding}
        >
          <StyledTooltipText
            fontSize={layout.mediumFontSize}
          >
            {text}
          </StyledTooltipText>
        </StyledTooltipContainer>
      )}
      background={colors.tulkaMainColor}
      className="target"
      direction={direction}
    >
      {children}
    </StyledTooltip>
  );
};

export const SimpleTooltip = ({ direction, children, text }) => {
  const { colors, layout } = useContext(ThemeContext);

  return (
    <StyledSimpleTooltip
      fontSize={layout.smallFontSize}
      hoverDelay={300}
      content={(
        <StyledTooltipContainer
          padding={layout.padding}
        >
          <StyledTooltipText
            fontSize={layout.mediumFontSize}
          >
            {text}
          </StyledTooltipText>
        </StyledTooltipContainer>
      )}
      background={colors.tulkaMainColor}
      className="target"
      direction={direction}
    >
      {children}
    </StyledSimpleTooltip>
  );
};
