import amplitude from 'amplitude-js';

import requestService from './requestService';

const endpoints = {
  getUser: '/user/getUser',
  setUser: '/user/setUser',
  statuses: 'matching/statuses'
};

export const getUser = async() => {
  try {
    return requestService.get(`${endpoints.getUser}`, null, {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);
    amplitude.getInstance().logEvent('Failed to get user');
    throw e;
  }
};

export const setUserPassword = async(password, oldPassword) => {
  const body = 'password=' + encodeURIComponent(password) + '&' + 'oldPassword=' + encodeURIComponent(oldPassword);

  try {
    return requestService.put(`${endpoints.setUser}`, null, body, {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const setUserEmails = (email, username) => {
  const body = 'email=' + encodeURIComponent(email) + '&' + 'username=' + encodeURIComponent(username);

  try {
    return requestService.put(`${endpoints.setUser}`, null, body, {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const setUserConfirmedData = async(userData) => {
  const params = Object.keys(userData).reduce((memo, elem) => {
    if (elem === 'userGroupId') {
      if (userData[elem]) {
        memo = memo.concat(`&userGroupVerified=true&userGroup.id=${encodeURIComponent(`${userData[elem]}`)}`);
      } else {
        memo = memo.concat('&userGroupVerified=true');
      }
    } else {
      memo = memo.concat(`&${elem}=${encodeURIComponent(`${userData[elem]}`)}`);
    }
    return memo;
  }, `profileVerifiedAt=${Date.now()}`);
  try {
    return requestService.put(`${endpoints.setUser}`, null, params, {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getBodyForSetUser = (params) => {
  if (params?.length > 0) {
    return params.filter(param => param).join('&');
  }
  return '';
};

export const setUser = async(userGroupId, isPureList, comment) => {
  const userGroup = userGroupId ? 'userGroupVerified=true&userGroup.id=' + userGroupId : '';
  const pureList = isPureList ? 'pureList=true' : '';
  const customerComment = comment ? `customerComment=${encodeURIComponent(comment)}` : '';
  const body = getBodyForSetUser([userGroup, pureList, customerComment]);
  try {
    return requestService.put(`${endpoints.setUser}`, null, body, {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);

    amplitude.getInstance().logEvent('Update user home group failed', {
      'error': e.message
    });

    throw e;
  }
};

export const getAvailability = async() => {
  try {
    return requestService.get(`${endpoints.statuses}`, {}, {apiType: 'platform'});
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export function setAvailability(isAvailable) {
  const body = isAvailable ? { startedAt: new Date() } : { endedAt: new Date() };
  try {
    return requestService.postWithJson(`${endpoints.statuses}`, {}, body, {apiType: 'platform'});
  } catch (e) {
    console.error(e);
    throw e;
  }
}
