import requestService from './requestService';

const endpoints = {
  unavailability: 'unavailability',
};

export const getBlockedInterpreterTime = async(interpreterUserId) => {
  const interpreterParam = interpreterUserId ? `targetUser=${interpreterUserId}` : '';

  try {
    return requestService.get(`${endpoints.unavailability}`, interpreterParam, { apiType: 'platform' });
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const blockInterpreterTime = async(startTime, endTime, type, interpreterUserId) => {
  const interpreterParam = interpreterUserId ? `&targetUser=${interpreterUserId}` : '';
  const body =
    `startsAt=${encodeURIComponent(new Date(startTime).toISOString())}&` +
    `endsAt=${encodeURIComponent(new Date(endTime).toISOString())}&` +
    `type=${type}`;

  try {
    return requestService.post(`${endpoints.unavailability}/create`, interpreterParam, body, { apiType: 'platform' });
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const unBlockInterpreterTime = async(id, interpreterUserId) => {
  const interpreterParam = interpreterUserId ? `targetUser=${interpreterUserId}` : '';
  try {
    return requestService.putWithJson(`${endpoints.unavailability}/remove/${id}`, interpreterParam, null, { apiType: 'platform' });
  } catch (e) {
    console.error(e);
    throw e;
  }
};
