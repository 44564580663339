import { logAnalytics } from '../services/analyticsLogger';
let audio;
let audioContext;

const getUserFromLocalstorage = () => {
  const userJson = localStorage.getItem('user');

  if (userJson) {
    try {
      return JSON.parse(userJson);
    } catch (e) {
      console.error('Failed to parse user.', e);
    }
  }
  return {};
};

const init = () => {
  const user = getUserFromLocalstorage();
  if (!audio && !audioContext) {
    try {
      audio = document.getElementById('notificationSound');
      audio.volume = 1.0;
      audio.loop = true;
      logAnalytics('Load audio file', {
        userId: user?.id
      });
    } catch (e) {
      logAnalytics('Error load audio file', {
        userId: user?.id
      });
    }
  }
};

const isPlaying = () => {
  return audio?.duration > 0 && !audio?.paused;
};

const play = () => { // eslint-disable-line consistent-return
  if (!isPlaying()) {
    audio.currentTime = 0;
    return audio.play();
  }
};

const pause = () => { // eslint-disable-line consistent-return
  if (isPlaying()) {
    return audio.pause();
  }
};

const sound = {
  init: init,
  play: play,
  pause: pause
};

export default sound;
