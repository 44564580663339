import React from 'react';
import i18n from '../i18n';

import { WizardList, WizardItem } from '../components/WizardList';
import { WizardStepContainer, WizardStepTitle } from './WizardStep';

const ServicesStep = ({ serviceToShow, handleNext }) => {
  return (
    <WizardStepContainer>
      <WizardStepTitle data-test='wizardStepTitleForServicesStep' label={i18n('selectable_servises')} />
      <WizardList data-test='wizardListForServicesStep'>
        {serviceToShow.map(service => {
          return (
            <WizardItem
              data-test={`wizardItem-${service.localeKey || service.value}`}
              key={service.value}
              type={service.type}
              link={service.value}
              label={i18n(service.localeKey || service.value)}
              handleNext={() => handleNext(service)}
              email={service?.email}
            />
          );
        })}
      </WizardList>
    </WizardStepContainer>
  );
};

export default ServicesStep;
