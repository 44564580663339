import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import amplitude from 'amplitude-js';

import ButtonPanel from './ButtonPanel';
import { RoundButton, StyledClipboardButton } from './Buttons';
import PrebookingSummaryPage from './PrebookingSummaryPage';
import Spinner from './Spinner';

import i18n from '../i18n';
import {
  parseNoticeMessage,
  parseNoticeCustomerData,
  getValidCustomerBirthday,
  getFormatedCustomFieldsForSave,
  isRequiredCustomFieldsFilled,
  isValidDateInCustomFields
} from '../utils/prebooksHelpers';

import {
  cancelPrebooking,
  confirmPrebookingBilling,
} from '../services/prebookService';
import {
  confirmOndemand,
} from '../services/invoiceService';

import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';
import AppContext from '../contexts/AppContext';

import CustomerReference from '../components/CustomerReference';
import InvoiceTargeting from '../components/InvoiceTargeting';
import CustomerInfo from '../components/CustomerInfo';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: ${({ padding }) => (padding * 3)}px;
  overflow: hidden;
  border-radius: 15px;
  border: none;
  position: relative;
`;

const StyledInvoiceTargeting = styled(InvoiceTargeting)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 15px;
  border: none;
  position: relative;
`;

const StyledCustomerReference = styled(CustomerReference)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 15px;
  border: none;
  position: relative;
`;

const PrebookSummary = ({ prebook, clonePrebook, editPrebook, isBillingSummary, isInterpreterPreview, editableFields, ongoing, joinTheCall, confirmationDialog, previewOnly, closeModal }) => {
  const {
    colors,
    layout,
  } = useContext(ThemeContext);
  const {
    sessionExpired,
  } = useContext(FunctionsContext);

  const { user } = useContext(AppContext);

  const [indicator, setIndicator] = useState(false);
  const [page, setPage] = useState('Summary');
  const [editableRefField, setEditableRefField] = useState('all');
  const [editableCustomerInfoFields] = useState('customerNameAndDateOfBirth');
  const updateAction = {
    ondemand: 'confirmPrebookingOndemand',
    prebook: 'confirmPrebookingBilling'
  };
  const apiActions = {
    confirmPrebookingOndemand: confirmOndemand,
    confirmPrebookingBilling: confirmPrebookingBilling,
  };

  const initialCustomerBirthday = prebook.customerDataBirthday ? new Date(moment(prebook.customerDataBirthday, 'DD/MM/YYYY')) : '';
  const initialCustomGroupValues = prebook?.customFields?.reduce((memo, field) => {
    memo[field.name] = field.value;
    return memo;
  }, {});

  const getInvoiceUserGroup = (invoiceUserGroup) => {
    if (invoiceUserGroup && Object.prototype.hasOwnProperty.call(invoiceUserGroup, '_id')) {
      return {
        id: invoiceUserGroup._id,
        name: invoiceUserGroup.name,
      };
    }
    return invoiceUserGroup;
  };

  const [updatedCustomerRef, setUpdatedCustomerRef] = useState(prebook.invoiceRef);
  const [temporaryCustomerRef, setTemporaryCustomerRef] = useState(prebook.invoiceRef);
  const [updatedInvoiceRecipientRef, setUpdatedInvoiceRecipientRef] = useState(prebook.invoiceRecipientRef);
  const [temporaryInvoiceRecipientRef, setTemporaryInvoiceRecipientRef] = useState(prebook.invoiceRecipientRef);
  const [updatedInvoiceTarget, setUpdatedInvoiceTarget] = useState(getInvoiceUserGroup(prebook.invoiceUserGroup));
  const [temporaryInvoiceTarget, setTemporaryInvoiceTarget] = useState(getInvoiceUserGroup(prebook.invoiceUserGroup));
  const [updatedCustomerName, setUpdatedCustomerName] = useState(prebook.customerData);
  const [temporaryCustomerName, setTemporaryCustomerName] = useState(prebook.customerData);
  const [updatedCustomerBirthday, setUpdatedCustomerBirthday] = useState(initialCustomerBirthday);
  const [temporaryCustomerBirthday, setTemporaryCustomerBirthday] = useState(initialCustomerBirthday);

  const [updatedCustomGroupValues, setUpdatedCustomGroupValues] = useState(initialCustomGroupValues);
  const [temporaryCustomGroupValues, setTemporaryCustomGroupValues] = useState(initialCustomGroupValues);

  const [usergroupCustomFields, setUsergroupCustomFields] = useState(temporaryInvoiceTarget?.customFields || prebook?.customFields || []);
  const [usergroupInvoiceRef, setUsergroupInvoiceRef] = useState(temporaryInvoiceTarget?.invoiceRefMandatory || prebook?.invoiceUserGroup?.invoiceRefMandatory || false);


  useEffect(() => {
    const func = async (ev) => {
      setIndicator(true);
      const reason = ev.detail?.reason;
      const cancelForFree = prebook.interpretationDate - (prebook.cancelForFreeSeconds * 1000) > Date.now();
      amplitude.getInstance().logEvent('Cancel prebooking confirmed', {
        'prebooking id': prebook.id,
        'cancel for free': cancelForFree ? 'yes' : 'no'
      });

      try {
        await cancelPrebooking(prebook.id, { userType: user.type});
        document.dispatchEvent(new CustomEvent('prebookCancelled', {
          detail: {
            id: prebook.id,
            reason
          }
        }));
        setIndicator(false);
        closeModal();
      } catch (e) {
        if (e.message === 'Session expired') {
         sessionExpired();
        }
        setIndicator(false);
        closeModal();
      }
    };
    document.addEventListener('prebookCancellingConfirmed', func);
    return () => {
      document.removeEventListener('prebookCancellingConfirmed', func);
    };
  }, [prebook]); // eslint-disable-line react-hooks/exhaustive-deps

  const getInfoForCopy = (aPrebook) => {
    if (!aPrebook) {
      return;
    }
    let data = '';
    if (aPrebook.interpretedLanguage && aPrebook.interpretedLanguage.name) {
      data += `${i18n('language_title')}: ${aPrebook.interpretedLanguage.name}\n`;
    }
    if (prebook.interpretationDate || prebook.createdAt) {
      data += `${i18n('prebooking_date')}: ${moment(prebook.interpretationDate || prebook.createdAt).format('DD/MM/YYYY')}\n`;
    }
    if (prebook.interpretationDate || prebook.createdAt) {
      data += `${i18n('prebooking_time')}: ${moment(prebook.interpretationDate || prebook.createdAt).format('LT')}\n`;
    }
    if (aPrebook.interpreter?.name) {
      data += `${i18n('interpreter_name')}: ${aPrebook.interpreter?.name}\n`;
    }
    if (prebook.interpretationDate || prebook.createdAt) {
      if (prebook.presence === 'remote' || (prebook.presence === 'external' && prebook.callType !== 'data')) {
        data += `${i18n('presence_title')}: ${i18n('presence_remote')}\n`;
      }
      if (aPrebook.presence === 'external' && aPrebook.callType === 'data') {
        data += `${i18n('presence_title')}: ${i18n('presence_conference')}\n`;
      }
      if (aPrebook.presence === 'onsite') {
        data += `${i18n('presence_title')}: ${i18n('presence_on_site')}\n`;
        data += `${i18n('address_info')}: ${aPrebook.locationCity} ${aPrebook.streetAddress}, ${aPrebook.postcode}\n`;
        data += `${i18n('arrival_instructions')}: ${aPrebook.messageToInterpreter}\n`;
      }
    }
    if (aPrebook.presence !== 'notice' && (prebook.durationEstimated || prebook.billableDuration)) {
      data += `${i18n('call_type_title')}: ${i18n(`call_type_${prebook.callType === 'data' ? 'video' : 'call'}`)}\n`;
    }
    if (aPrebook.hotlinePhoneNumber) {
      data += `${i18n('interpreter_phone_number')}: ${aPrebook.hotlinePhoneNumber}\n`;
    }
    if (aPrebook.interpreter && aPrebook.interpreter.email && aPrebook.callType === 'data' && aPrebook.presence === 'external') {
      data += `${i18n('interpreter_email')}: ${aPrebook.interpreter.email}\n`;
    }
    if (aPrebook.invoiceUserGroup && aPrebook.invoiceUserGroup.name) {
      data += `${i18n('invoice_user_group')}: ${aPrebook.invoiceUserGroup.name}\n`;
      if (aPrebook.customFields) {
        const customFieldsData = aPrebook.customFields.map((customField) => {
          if (customField.type === 'date') {
            return `${customField.label}: ${customField.value ? moment(customField.value).format('DD/MM/YYYY') : ''}\n`;
          }
          return `${customField.label}: ${customField.value}\n`;
        });
        if (customFieldsData) {
          data += customFieldsData.join('');
        }
      }
    }
    if (aPrebook.customer && aPrebook.customer.name) {
      data += `${i18n('customer')}: ${aPrebook.customer.name}\n`;
    }
    if (aPrebook.userGroup && aPrebook.userGroup.name) {
      data += `${i18n('customer_unit')}: ${aPrebook.userGroup.name}\n`;
    }
    if (aPrebook.invoiceRef) {
      if (aPrebook.presence === 'notice') {
        const parsedNoticeCustomerData = parseNoticeCustomerData(aPrebook.invoiceRef);
        data += `${i18n('customer_name_and_date_of_birth')}: ${parsedNoticeCustomerData.customerData} ${getValidCustomerBirthday(parsedNoticeCustomerData.customerDataBirthday)}\n`;
      } else {
        data += `${i18n('customerInvoiceNotes')}: ${aPrebook.invoiceRef}\n`;
      }
    }
    if (aPrebook.presence === 'notice') {
      const parsedNoticeMessage = parseNoticeMessage(aPrebook.messageToInterpreter);

      if (parsedNoticeMessage.alternativeContactPerson) {
        data += `${i18n('to_whom_the_message_is_forwarded_if_not_to_the_customer')}: ${parsedNoticeMessage.alternativeContactPerson}\n`;
      }
      if (parsedNoticeMessage.phoneNumbers) {
        data += `${i18n('telephone_number_to_which_the_interpreter_will_call')}: ${parsedNoticeMessage.phoneNumbers?.join(' ')}\n`;
      }
      if (aPrebook.comments) {
        data += `${i18n('message_to_customer_service_or_interpreter')}: ${aPrebook.comments}\n`;
      }
      if (parsedNoticeMessage.contentData) {
        data += `${i18n('write_the_content_of_the_matter_to_be_reported_below')}: ${parsedNoticeMessage.contentData}\n`;
      }
    }
    if (aPrebook.invoiceRecipientRef) {
      data += `${i18n('alternative_cost_center')}: ${aPrebook.invoiceRecipientRef}\n`;
    }
    if (aPrebook.patientName) {
      data += `${i18n('patient_name')}: ${aPrebook.patientName}\n`;
    }
    if (aPrebook.orderNumber) {
      data += `${i18n('reservation_number')}: ${aPrebook.orderNumber}\n`;
    }
    if (aPrebook.ccEmails || user.email) {
      const result = [user.email, aPrebook.ccEmails].reduce((memo, email) => {
        if (email) {
          return memo ? `${memo}, ${email}` : email;
        }
        return memo;
      }, '');
      data += `${i18n('cc_emails_copy')}: ${result}\n`;
    }
    if (aPrebook.cancelledByUser && aPrebook.cancelledByUser.name) {
      data += `${i18n('cancelled_by')}: ${aPrebook.cancelledByUser.name}\n`;
    }
    return data; //eslint-disable-line consistent-return
  };

  const onEditPage = (aPage, editAlternative) => {
    setPage(aPage);
    setEditableRefField(editAlternative);
  };

  const getCustomerRef = () => {
    if (prebook.presence === 'notice') {
      return `
        ${temporaryCustomerName} ${i18n('birthday')} ${moment(temporaryCustomerBirthday).format('DD/MM/YYYY')}
      `;
    }
    if (temporaryCustomerRef) {
      return temporaryCustomerRef;
    }
    return '';
  };

  const confirmPrebook = async () => {
    setIndicator(true);
    const action = updateAction[prebook.type];
    try {
      await apiActions[action](
        prebook.id,
        temporaryInvoiceTarget ? temporaryInvoiceTarget.id : '',
        getCustomerRef(),
        temporaryInvoiceRecipientRef ? temporaryInvoiceRecipientRef : '',
        temporaryCustomGroupValues ? getFormatedCustomFieldsForSave(usergroupCustomFields, temporaryCustomGroupValues, true) : '',
      );
      setIndicator(false);
      closeModal(true);
    } catch (e) {
      if (e.message === 'Session expired') {
        sessionExpired();
      }
      setIndicator(false);
      closeModal();
    }
  };

  const getCcemails = ccEmails => typeof ccEmails === 'string' ? ccEmails.split(',').map(email => email.trim()) : [];

  const isGoingPrebook = (aPrebook) =>
    aPrebook.interpretationDate - Date.now() <= (aPrebook.leewayBefore * 1000);

  return (
    <StyledContainer
      padding={layout.padding}
      colors={colors}
    >
      {
        page === 'CustomerReference' ? (
          <>
            <StyledCustomerReference
              key={page}
              active={page === 'CustomerReference'}
              editableRefField={editableRefField}
              onValueSelected={(value) => {
                setUpdatedCustomerRef(value);
              }}
              value={updatedCustomerRef !== null ? updatedCustomerRef : temporaryCustomerRef}
              onInvoiceRecipientRefSelected={setUpdatedInvoiceRecipientRef}
              invoiceRecipientRefValue={updatedInvoiceRecipientRef !== null ? updatedInvoiceRecipientRef : temporaryInvoiceRecipientRef}
              onNext={() => {
                setTemporaryCustomerRef(updatedCustomerRef);
                setTemporaryInvoiceRecipientRef(updatedInvoiceRecipientRef);
                setPage('Summary');
                setEditableRefField('all');
              }}
              isNoticeInterpretation={prebook.presence === 'notice'}
              usergroupCustomFields={usergroupCustomFields}
              customFieldsValue={updatedCustomGroupValues !== null ? updatedCustomGroupValues : temporaryCustomGroupValues}
              setCustomFieldsValue={(obj) => {
                setUpdatedCustomGroupValues({
                  ...updatedCustomGroupValues,
                  ...obj,
                });
              }}
            />
            <ButtonPanel>
              <RoundButton
                darkButton
                disabled={updatedCustomerRef === temporaryCustomerRef && updatedInvoiceRecipientRef === temporaryInvoiceRecipientRef && JSON.stringify(updatedCustomGroupValues) === JSON.stringify(temporaryCustomGroupValues)}
                color={colors.tulkaMainColor}
                padding={layout.buttonPadding}
                label={i18n('update')}
                onClick={() => {
                  setTemporaryCustomerRef(updatedCustomerRef);
                  setTemporaryInvoiceRecipientRef(updatedInvoiceRecipientRef);
                  setTemporaryCustomGroupValues(updatedCustomGroupValues);
                  setPage('Summary');
                  setEditableRefField('all');
                }}
              />
              <RoundButton
                warning={true}
                color={colors.tulkaMainColor}
                padding={layout.buttonPadding}
                label={i18n('cancel')}
                onClick={() => {
                  setPage('Summary');
                  setUpdatedCustomerRef(temporaryCustomerRef);
                  setUpdatedInvoiceRecipientRef(temporaryInvoiceRecipientRef);
                  setEditableRefField('all');
                }}
              />
            </ButtonPanel>
          </>
        ) : null
      }
      {
        page === 'InvoiceTargeting' ? (
          <>
            <StyledInvoiceTargeting
              key={page}
              onValueSelected={(value) => {
                setUpdatedInvoiceTarget(value);
              }}
              value={updatedInvoiceTarget !== null ? updatedInvoiceTarget : temporaryInvoiceTarget}
            />
            <ButtonPanel>
              <RoundButton
                darkButton
                disabled={temporaryInvoiceTarget === updatedInvoiceTarget}
                color={colors.tulkaMainColor}
                padding={layout.buttonPadding}
                label={i18n('update')}
                onClick={() => {
                  setTemporaryInvoiceTarget(updatedInvoiceTarget);
                  setUsergroupCustomFields(updatedInvoiceTarget?.customFields);
                  setUsergroupInvoiceRef(updatedInvoiceTarget?.invoiceRefMandatory);
                  setUpdatedCustomGroupValues(updatedInvoiceTarget?.id === prebook?.invoiceUserGroup?.id ? initialCustomGroupValues : {});
                  setTemporaryCustomGroupValues(updatedInvoiceTarget?.id === prebook?.invoiceUserGroup?.id ? initialCustomGroupValues : {});
                  setPage('Summary');
                }}
              />
              <RoundButton
                warning={true}
                color={colors.tulkaMainColor}
                padding={layout.buttonPadding}
                label={i18n('cancel')}
                onClick={() => {
                  setPage('Summary');
                  setUpdatedInvoiceTarget(temporaryInvoiceTarget);
                }}
              />
            </ButtonPanel>
          </>
        ) : null
      }
      {
        page === 'CustomerInfo' ? (
          <>
            <CustomerInfo
              editableField={editableCustomerInfoFields}
              customerData={updatedCustomerName !== null ? updatedCustomerName : temporaryCustomerName}
              setCustomerData={setUpdatedCustomerName}
              customerDataBirthday={updatedCustomerBirthday !== null ? updatedCustomerBirthday : temporaryCustomerBirthday}
              setCustomerDataBirthday={setUpdatedCustomerBirthday}
            />
            <ButtonPanel>
              <RoundButton
                darkButton
                disabled={updatedCustomerName === temporaryCustomerName && (JSON.stringify(updatedCustomerBirthday) === JSON.stringify(temporaryCustomerBirthday))}
                color={colors.tulkaMainColor}
                padding={layout.buttonPadding}
                label={i18n('update')}
                onClick={() => {
                  setTemporaryCustomerName(updatedCustomerName);
                  setTemporaryCustomerBirthday(updatedCustomerBirthday);
                  setPage('Summary');
                }}
              />
              <RoundButton
                warning={true}
                color={colors.tulkaMainColor}
                padding={layout.buttonPadding}
                label={i18n('cancel')}
                onClick={() => {
                  setPage('Summary');
                  setUpdatedCustomerName(temporaryCustomerName);
                  setUpdatedCustomerBirthday(temporaryCustomerBirthday);
                }}
              />
            </ButtonPanel>
          </>
        ) : null
      }
      {
        page === 'Summary' ? (
          <>
            <PrebookingSummaryPage
              locationPath={window.location.pathname}
              readOnly={true}
              clonePrebook={clonePrebook}
              editPrebook={editPrebook}
              isBillingSummary={isBillingSummary}
              editableFields={editableFields}
              onEditPage={onEditPage}
              date={moment(prebook.interpretationDate)}
              time={moment(prebook.interpretationDate)}
              duration={prebook.durationEstimated / 60}
              languageName={prebook.interpretedLanguage ? prebook.interpretedLanguage.name : ''}
              targetLanguageName={prebook.targetLanguage ? prebook.targetLanguage.name : ''}
              skills={prebook.skills}
              favoriteNames={prebook.wishedInterpreterName ? prebook.wishedInterpreterName : prebook.favoriteNames}
              callType={prebook.callType}
              invoiceUserGroupName={(temporaryInvoiceTarget && temporaryInvoiceTarget.name) || (prebook.invoiceUserGroup && prebook.invoiceUserGroup.name) || ''}
              invoiceRef={temporaryCustomerRef}
              invoiceRecipientRef={temporaryInvoiceRecipientRef}
              comment={prebook.comments}
              subject={prebook.subject}
              message={prebook.messageToInterpreter}
              presence={prebook.presence}
              locationCity={prebook.locationCity}
              streetAddress={prebook.streetAddress}
              postcode={prebook.postcode}
              interpreterPhoneNumber={prebook.hotlinePhoneNumber}
              interpreterEmail={prebook.interpreter && prebook.callType === 'data' && prebook.presence === 'external' ? prebook.interpreter.email : ''}
              orderNumber={prebook.orderNumber}
              cancelledByUser={prebook.cancelledByUser}
              cancelReason={prebook.cancelReason}
              customerName={prebook.customer?.name}
              customerId={prebook.customer?.id}
              group={(prebook.userGroup && prebook.userGroup.name) ? prebook.userGroup.name : ''}
              ongoing={ongoing}
              joinTheCall={joinTheCall}
              isNoticeInterpretation={prebook.presence === 'notice' || prebook.callType === 'notice'}
              customerData={temporaryCustomerName}
              customerDataBirthday={temporaryCustomerBirthday}
              alternativeContactPerson={prebook.alternativeContactPerson}
              mainPhoneNumber={prebook.mainPhoneNumber}
              phoneNumbers={prebook.phoneNumbers}
              messageToCustomerService={prebook.messageToCustomerService}
              contentData={prebook.contentData}
              isInterpreterPreview={isInterpreterPreview}
              customGroupFields={usergroupCustomFields}
              usergroupInvoiceRef={usergroupInvoiceRef}
              customGroupValues={temporaryCustomGroupValues}
              ccEmails={getCcemails(prebook.ccEmails)}
              patientName={prebook.patientName}
              interpreterName={prebook.interpreter?.name}
            />
            {
              editableFields && editableFields.length > 0 ? (
                <ButtonPanel>
                  <RoundButton
                    darkButton
                    disabled={(!isRequiredCustomFieldsFilled(usergroupCustomFields, temporaryCustomGroupValues) || !isValidDateInCustomFields(usergroupCustomFields, temporaryCustomGroupValues)) || (
                      temporaryInvoiceTarget?.id === prebook.invoiceUserGroup?.id &&
                      temporaryCustomerRef === prebook.invoiceRef &&
                      temporaryInvoiceRecipientRef === prebook.invoiceRecipientRef &&
                      temporaryCustomerName === prebook.customerData &&
                      JSON.stringify(temporaryCustomerBirthday) === JSON.stringify(initialCustomerBirthday) &&
                      JSON.stringify(temporaryCustomGroupValues) === JSON.stringify(initialCustomGroupValues)
                    )}
                    color={colors.tulkaMainColor}
                    padding={layout.buttonPadding}
                    label={i18n('update_prebook')}
                    onClick={confirmPrebook}
                  />
                  <RoundButton
                    warning={true}
                    color={colors.tulkaMainColor}
                    padding={layout.buttonPadding}
                    label={i18n('cancel')}
                    onClick={() => {
                      amplitude.getInstance().logEvent('Cancel updating billing of prebook', {
                        'prebooking id': prebook.id
                      });
                      if (
                        (temporaryCustomerRef === prebook.invoiceRef) &&
                        (temporaryInvoiceRecipientRef === prebook.invoiceRecipientRef) &&
                        (temporaryInvoiceTarget && prebook.invoiceUserGroup) &&
                        (temporaryInvoiceTarget?.id === prebook.invoiceUserGroup?.id) &&
                        (temporaryCustomerName === prebook.customerData) &&
                        (JSON.stringify(temporaryCustomerBirthday) === JSON.stringify(initialCustomerBirthday)) &&
                        (JSON.stringify(temporaryCustomGroupValues) === JSON.stringify(initialCustomGroupValues))
                      ) {
                        closeModal();
                      } else {
                        confirmationDialog('prebookPreviewModal');
                      }
                    }}
                  />
                </ButtonPanel>
              ) : (
                <ButtonPanel>
                  <StyledClipboardButton
                    data-test='copyInformationBtn'
                    data-clipboard-text={getInfoForCopy(prebook)}
                    darkButton
                    color={colors.tulkaMainColor}
                    padding={layout.buttonPadding}
                    fontSize={layout.fontSize}
                    toUpperCase={true}
                  >
                    {i18n('copy_information')}
                  </StyledClipboardButton>
                  {!prebook.cancelledAt && !(previewOnly || prebook.presence === 'notice' || isGoingPrebook(prebook)) && prebook.interpretationDate - 2 * 60 * 1000 > Date.now() ? (
                    <RoundButton
                      data-test='cancelPrebookBtn'
                      label={i18n('cancel_prebook')}
                      onClick={() => {
                        const cancelForFree = prebook.interpretationDate - (prebook.cancelForFreeSeconds * 1000) > Date.now();
                        amplitude.getInstance().logEvent('Cancel prebooking tapped', {
                          'prebooking id': prebook.id,
                          'cancel for free': cancelForFree ? 'yes' : 'no',
                          'presence': prebook.presence
                        });

                        confirmationDialog();
                      }}
                      warning
                      color={colors.tulkaMainColor}
                      padding={layout.buttonPadding}
                    />
                  ) : null}
                </ButtonPanel>
              )
            }
          </>
        ) : null
      }
      {indicator ? (
        <Spinner
          overlay
        />
      ): null}
    </StyledContainer>
  );
};

export default PrebookSummary;
