import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import i18n from '../i18n';

import ThemeContext from '../contexts/ThemeContext';
import AppContext from '../contexts/AppContext';
import FunctionsContext from '../contexts/FunctionsContext';

import {
  getAvailability,
  setAvailability
} from '../services/userService';

import { showError } from '../services/toastService';
import { logAnalytics } from '../services/analyticsLogger';
import { isLuonaHost } from '../utils/luonaSpecificData';

const AvailableContainer = styled.div`
  margin-bottom: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Timer = styled.div`
  color: #ffffff;
  text-align: center;
`;

const DescriptionBlock = styled.div`
  font-size: 16px;
  border-radius: 12px;
  color: ${({ color }) => (color)};
`;

const AvailableBlock = () => {
  const { colors } = useContext(ThemeContext);
  const { user } = useContext(AppContext);
  const { updateUser } = useContext(FunctionsContext);
  const [ loading, setLoading ] = useState(false);
  const [ isUserInterpreter ] = useState(user.type === 'Interpreter');
  const [ availableUntil, setAvailableUntil ] = useState(user.availableUntil);
  const [ timerInterval ] = useState(1000);
  const [ availableLeft, setAvailableLeft ] = useState(0);

  const heandleAvailability = async (res) => {
    const now = Date.now();
    const endedAt = moment(res.endedAt).valueOf() || now;
    if (res.online) {
      user.availableUntil = endedAt;
      await updateUser(user);
      return;
    }
    if (!res.online) {
      user.availableUntil = 0;
      await updateUser(user);
      return;
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getAvailability();
        heandleAvailability(res);
      } catch (e) {
        console.log('ERROR: ', e);
        showError(i18n('error500'));
      }
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAvailableUntil(user.availableUntil || 0);
 }, [user.availableUntil]);

  useEffect(() => {
   setAvailableLeft(Math.max(availableUntil - Date.now(), 0));

   const interval = availableUntil ? setInterval(() => {
     const millisLeft = availableUntil - Date.now();

     if (millisLeft <= 0) {
       setAvailableUntil(0);
     } else {
       setAvailableLeft(millisLeft);
     }
   }, timerInterval) : 0;
   return () => clearInterval(interval);
 }, [availableUntil, timerInterval]);

  const PurpleSwitch = withStyles({
    switchBase: {
      color: colors.error,
      '&$checked': {
        color: colors.acceptColor,
      },
      '&$checked + $track': {
        backgroundColor: colors.acceptColor,
      },
    },
    checked: {},
    track: {
      backgroundColor: colors.error
    },
  })(Switch);

  const handleChange = async (event) => {
    if (!loading) {
      setLoading(true);
      const isAvailable = event.target.checked;
      try {
        const res = await setAvailability(isAvailable);
        const eventName = isAvailable ? 'Became online' : 'Became offline';
        logAnalytics(eventName, {
          userId: user.id
        });
        heandleAvailability(res);
        setLoading(false);
      } catch (e) {
        console.log('ERROR: ', e);
        showError(i18n('error500'));
        setLoading(false);
      }
    }
  };

  const getText = (isAvailable) => {
    if (isAvailable) {
      return i18n('availability_yes');
    }
    return i18n('availability_no');
  };

  return (
    <>
    {isUserInterpreter && (
      <AvailableContainer>
        {!isLuonaHost() && (
          <DescriptionBlock color='#ffffff'>{i18n('interpreter_status')}</DescriptionBlock>
        )}
        <Row>
          <PurpleSwitch
             disabled={loading}
             checked={!!availableUntil}
             onChange={handleChange}
           />
           {availableUntil > 0 ? (<Timer>{moment.utc(availableLeft).format('HH:mm:ss')}</Timer>) : null}
        </Row>
         <DescriptionBlock color={availableUntil ? colors.acceptColor : colors.error }>{getText(availableUntil)}</DescriptionBlock>
      </AvailableContainer>
    ) }
    </>
  );
};

export default AvailableBlock;
