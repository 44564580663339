const gray = '#888d9b'; // '#a8adbb';
const semiGray = '#b8bdcb';
const midGray = '#d8ddeb';
const lightGray = '#f6f9fc';
const blueGray = '#434a5e';
const webOverlay = 'rgba(21, 21, 41, 0.9)';

const deviceSizes = {
  tablet: '768px'
};

const devicesMedia = {
  tablet: `(min-width: ${deviceSizes.tablet})`
};

const modalOverlay = {
  zIndex: 999,
  backgroundColor: webOverlay
};

const modalContent = {
  width: '100%',
  maxWidth: '660px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  padding: 0,
  borderRadius: '15px',
  border: 'none',
  overflow: 'initial',
  display: 'flex'
};

const Layout = {
  color: {
    gray,
    lightGray,
    blueGray,
    midGray,
    semiGray
  },
  devicesMedia,
  viewportWidth: () => '100%',
  viewportHeight: () => '100%',
  margin: 20,
  elevation: 2,
  topMargin: 0,
  padding: '8',
  smallButtonPadding: '25',
  buttonPadding: '72',
  transition: 'all 80ms linear',
  logoHeaderHeight: 40,
  bigHeaderFontSize: 34,
  headerFontSize: 26,
  bigFontSize: 18,
  fontSize: 16,
  mediumFontSize: 14,
  smallFontSize: 11,
  myTulkaSmallFontSize: 8,
  borderRadius: 4,

  modalDialogStyles: {
    overlay: modalOverlay,
    content: {
      ...modalContent,
      height: '100%',
      maxHeight: '600px',
      
    }
  },
  modalConfirmationStyles: {
    overlay: modalOverlay,
    content: {
      ...modalContent,
      height: '90%',
    }
  },
};

export default Layout;
