import { useState, useContext } from 'react';
import styled from 'styled-components';
import i18n from '../i18n';
import { RoundButton } from './Buttons';

import {
  ContainerStepBox,
  ContentStepBox,
  MainStepsHeader,
  StepsHeader,
  FlexRow
} from './basicComponents';

import { logAnalytics } from '../services/analyticsLogger';
import { getUserFromLocalstorage } from '../utils/localStorage';

import FunctionsContext from '../contexts/FunctionsContext';

const StyledMainStepsHeader = styled(MainStepsHeader)`
  margin-bottom: 32px;
`;

const StyledFlexRow = styled(FlexRow)`
  justify-content: center;
`;

const StyledButton = styled(RoundButton)`
  @media (max-width: 768px) {
    order: 2
  }
`;

const FinalStep = () => {
    const { login } = useContext(FunctionsContext);
    const getLinkToStore = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return 'https://a.tulka.com';
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'https://i.tulka.com';
      }
      return null;
  };

  const [user] = useState(getUserFromLocalstorage());
  const [link] = useState(getLinkToStore());
  logAnalytics('Self-registration final step', {
    userId: user?.id
  });

  return (
    <ContainerStepBox>
      <ContentStepBox>
        <StyledMainStepsHeader>{i18n('account_created')}</StyledMainStepsHeader>
        <StepsHeader>{i18n('you_can_now_login_to_the_tulka_application_using_your_credentials')}</StepsHeader>
        <StepsHeader>{i18n('we_have_also_sent_you_instructions_for_use_by_email')}</StepsHeader>
      </ContentStepBox>
      <StyledFlexRow>
        {link && (
          <StyledButton
            data-test='finalStepOpenMobAppBtn'
            padding={25}
            iconAfter={'launch'}
            label={i18n('open_mobile_app')}
            disabled={!link}
            onClick={() => {
              logAnalytics('Self-registration open mobile app clicked', {
                link,
                userId: user?.id
              });
              window.location.href = link;
            }}
            darkButton={false}
          />
        )}
        <RoundButton
          data-test='finalStepOpenLoginToWebBtn'
          padding='25'
          label={i18n('login_to_web')}
          onClick={() => {
            logAnalytics('Self-registration login to web clicked', {
              userId: user?.id
            });
            login(null, null, user);
          }}
          darkButton={true}
        />
      </StyledFlexRow>
    </ContainerStepBox>
  );
};

export default FinalStep;
