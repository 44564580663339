import i18n from '../i18n';

const pageType = {
  PAGE: 0,
  CARD: 1,
  BREAK: 2
};

export default (user) => {
  const pages = [];

  if (user) {
    if (user.type === 'Interpreter'){
      pages.push({
        name: i18n('number_of_interpretations'),
        subPages: [{ id: 'wpKng', type: pageType.PAGE }]
      });
      pages.push({
        name: i18n('interpretation_breakdown_previous_month'),
        subPages: [{ id: 'R6Opq', type: pageType.PAGE }]
      });
    }

    if (user.role === 'supervisor' || user.role === 'admin' || user.role === 'invoicing') {
      pages.push({
        name: i18n('use_of_interpretation_report'),
        subPages: [{ id: 'aMkBR', type: pageType.PAGE }]
      });
    }

    /* Vantaan MAPA */
    if (user.userGroup?.id === 'fade4e9d-9bb6-4b87-a1e3-15d41c0cf936') {
      pages.push({
        name: i18n('billing_report'),
        subPages: [
          { id: 'DkJ7k', type: pageType.CARD, width: '100%', height: 1200 },
          { id: 'brk7', type: pageType.BREAK },
          { id: 'pYY6N', type: pageType.CARD, width: '100%', height: 1200 }
        ]
      });
    }

    /* Uuudemman TE Keskus */
    if (user.userGroup?.id === '5bc6de2b-2f14-4a81-9a3a-ff5c430dd39a') {
      pages.shift();
      pages.push({
        name: i18n('use_of_interpretation_report'),
        subPages: [{ id: 'epGpr', type: pageType.PAGE }]
      });
    }

    /* A-tulkkaus */
    if (user.userGroup?.id === '3c67594f-ba71-44db-b59b-7735a0378ccf') {
      pages.push({
        name: i18n('customer_reporting_report'),
        subPages: [
          { id: 'aK03M', type: pageType.CARD },
          { id: 'dLL3X', type: pageType.CARD }
        ]
      });
    }

    if (user.userGroup?.id === '3c67594f-ba71-44db-b59b-7735a0378ccf' && (user.role === 'supervisor' || user.role === 'admin')) {
      pages.push({
        name: i18n('data_export_report'),
        subPages: [{ id: 'az1MY', type: pageType.CARD },
        { id: 'dPnJ2', type: pageType.CARD },
        { id: 'dBD3X', type: pageType.CARD }]
      });
    }

    /*Mometra*/
    if (user.userGroup?.id === '8784ed3c-8523-45b6-bc07-49716b6bd442') {
      pages.push({
        name: i18n('customer_reporting_report'),
        subPages: [{ id: 'b8kJj', type: pageType.PAGE }]
      });
    }

    if (user.userGroup?.id === '8784ed3c-8523-45b6-bc07-49716b6bd442' && (user.role === 'admin' || user.role === 'invoicing')) {
      pages.push({
        name: i18n('monetra_billing_report'),
        subPages: [{ id: 'dNV1L', type: pageType.PAGE }]
      });
    }

    if (user.userGroup?.id === '8784ed3c-8523-45b6-bc07-49716b6bd442' && (user.role === 'admin' || user.role === 'invoicing')) {
      pages.push({
        name: i18n('monetra_payroll_report'),
        subPages: [{ id: 'aOW9g', type: pageType.PAGE }]
      });
    }

    /* give invocing and superwisors invoicing views if not mometra or atulkkaus or wantaa mapa momentra and atulkkaus has their own pages*/
    if (user.userGroup?.id !== '8784ed3c-8523-45b6-bc07-49716b6bd442' && user.userGroup?.id !== '3c67594f-ba71-44db-b59b-7735a0378ccf' && user.userGroup?.id !== 'fade4e9d-9bb6-4b87-a1e3-15d41c0cf936' && (user.role === 'admin' || user.role === 'invoicing')) {
      pages.push({
        name: i18n('billing_report'),
        subPages: [
          { id: 'QWkXL', type: pageType.CARD, width: '100%', height: 500 },
          { id: 'brk2', type: pageType.BREAK },
          { id: 'G6RE0', type: pageType.CARD, width: '100%', height: 1200 },
          { id: 'brk3', type: pageType.BREAK }
        ]
      });
      pages.push({
        name: i18n('data_export_report'),
        subPages: [
          { id: 'b6gEO', type: pageType.CARD },
          { id: 'e7jGQ', type: pageType.CARD }
        ]
      });
    }

    /* Aritesti */
    if (user.id === 'c6d43c27-60e7-494c-ba98-06f08a174098') {
      pages.push({
        name: 'ARITESTI',
        subPages: [
          { id: 'az1MY', type: pageType.CARD },
          { id: 'dPnJ2', type: pageType.CARD },
          { id: 'dBD3X', type: pageType.CARD }
        ]
      });
    }
  }

  return pages;
};
