import { useRef, useCallback, useState, useContext } from 'react';
import * as VideoExpress from '@vonage/video-express';
import i18n from '../i18n';
import AppContext from '../contexts/AppContext';
import { showError } from '../utils/msgbox';
import { logAnalytics } from '../services/analyticsLogger';
import { setLeftDate } from '../utils/rejoinChatroom';

export default function useRoom() {
  let roomRef = useRef(null);
  let publisherOptionsRef = useRef(null);
  const [ participants, setParticipants ] = useState([]);
  const [ wrappParticipants, setWrappParticipants ] = useState([]);
  const [ cameaCreated, setCameaCreated ] = useState(false);
  const [ networkStatus, setNetworkStatus ] = useState(null);
  const { user } = useContext(AppContext);

  const addParticipants = ({ participant }) => {
    setParticipants((prev) => {
      return [...prev, participant];
    });
  };

  const addWrappParticipants = ({ participant }) => {
    setWrappParticipants((prev) => {
      return [...prev, participant];
    });
  };

  const removeWrappParticipants = ({ participant }) => {
    setParticipants((prev) =>
      prev.filter((prevparticipant) => prevparticipant.id !== participant.id)
    );
  };

  const leaveOfCall = useCallback(() => {
    if (roomRef && roomRef.current) {
      roomRef.current.leave();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isExistParticipant = (existParticipants, newPartcipant) => {
    return !!existParticipants.find((participant) => {
      return participant.userId === newPartcipant.userId;
    });
  };

  const startRoomListeners = useCallback((handleLeave, heandleLeaveParticipant) => {
    if (roomRef.current) {
      roomRef.current.camera.on('created', () => {
        logAnalytics('Vonage event camera created', {
          userId: user?.id
        });
        setCameaCreated(true);
      });
      roomRef.current.on('connected', () => {
      });
      roomRef.current.on('signal:participant', (event) => {
        const participant = JSON.parse(event.data);
        if (!isExistParticipant(participants, participant)) {
          addParticipants({ participant: {...participant, isMe: user._id === participant.userId || user.id === participant.userId}});
        }
      });
      roomRef.current.on('disconnected', (reason) => {
        console.log('disconnected');
        if (reason === 'networkDisconnected') {
          showError(i18n('networkDisconnectedMessage'));
        }
        logAnalytics('Vonage event disconnected', {
          userId: user?.id,
          ...(reason ? {
            reason: reason
          } : {})
        });
        leaveOfCall();
        handleLeave();
      });
      roomRef.current.camera.on('created', () => {
      });
      roomRef.current.on('activeSpeakerChanged', () => {
      });

      roomRef.current.on('reconnected', () => {
        logAnalytics('Vonage event reconnected', {
          userId: user?.id
        });
        setNetworkStatus({status: 'success', message: 'meeting_reconnected'});
      });
      roomRef.current.on('reconnecting', () => {
        logAnalytics('Vonage event reconnecting', {
          userId: user?.id
        });
        setNetworkStatus({status: 'warning', message: 'meeting_reconnecting'});
      });
      roomRef.current.on('participantJoined', (participant) => {
        console.log('participantJoined', participant);
        logAnalytics('Vonage event participant joined', {
          name: participant?.name,
          userId: user?.id
        });
        addWrappParticipants({ participant: participant });
      });
      roomRef.current.on('participantLeft', (participant, reason) => {
        console.log('Room: participant left', participant, reason);
        logAnalytics('Vonage event participant left', {
          name: participant?.name,
          userId: user?.id
        });
        setLeftDate();
        removeWrappParticipants({ participant: participant });
        console.log('Room: participant left', participant, reason);
        heandleLeaveParticipant();
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createCall = useCallback(
    async (
      handleLeave,
      heandleLeaveParticipant,
      { apikey, sessionId, token },
      roomContainer,
      userName,
      publisherOptions
    ) => {
      if (!apikey || !sessionId || !token) {
        throw new Error('Check your credentials');
      }
      roomRef.current = new VideoExpress.Room({
        apiKey: apikey,
        sessionId: sessionId,
        token: token,
        roomContainer: 'roomContainer',
        maxVideoParticipantsOnScreen: 25,
        participantName: userName,
        managedLayoutOptions: {
          layoutMode: 'grid'
        }
      });
      startRoomListeners(handleLeave, heandleLeaveParticipant);
      console.log('publisherOptions:', publisherOptions);
      publisherOptionsRef.current = Object.assign({}, publisherOptions, {
         name: userName
       });
       console.log('publisherOptionsRef.current:', publisherOptionsRef.current);
      roomRef.current
        .join({ publisherProperties: publisherOptionsRef.current })
        .catch((e) => {
          console.log('ERROR from OPENTOCK: ', e);
          if (e.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
            showError(i18n('no_access_to_your_camera_or_microphone'));
          } else {
            logAnalytics('Error createCall from VideoExpress', {
              userId: user?.id,
              error: e
            });
            showError(i18n('requestErrorMessageReasonPoorInternet'));
          }
          leaveOfCall();
          handleLeave();
        });
    },
    [startRoomListeners] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    createCall,
    room: roomRef.current,
    leaveOfCall,
    participants,
    wrappParticipants,
    cameaCreated,
    networkStatus
  };
}
