import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { roundToNextMin, getStartTime } from '../utils/timeUtils';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import i18n from '../i18n';

import ThemeContext from '../contexts/ThemeContext';
import AppContext from '../contexts/AppContext';

const MAX_MINUTES_STEP = 60;

const GlobalStyle = createGlobalStyle`
  .rc-time-picker-panel {
    background-color: transparent !important;
    .rc-time-picker-panel-inner {
      margin-top: 3px;
      border: none;
      box-shadow: none;
      background-color: transparent;
      max-width: ${({ maxWidth }) => (maxWidth)}%;
      & .rc-time-picker-panel-input-wrap {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: none;
        padding: 0;
        background-color: ${({ backgroundColor }) => (backgroundColor)};
        & input {
          font-size: ${({ fontSize }) => (fontSize)}px;
          height: 50px;
          border: 1px solid transparent;
          line-height: 1.5;
          color: ${({ mainColor }) => (mainColor)};
          background-color: transparent;
          padding: ${({ padding }) => (padding * 2)}px;
        }
        & .rc-time-picker-panel-input-invalid {
          border-color: ${({ warningColor }) => (warningColor)};
          width: calc(660px - 94px);
          max-width: calc(100vw - 94px);
        }
      }
      & .rc-time-picker-panel-combobox {
        flex: 1;
        display: flex;
        border: ${({ borderColor }) => ('1px solid ' + borderColor)};
        border-top: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: ${({ backgroundColor }) => (backgroundColor)};
        & .rc-time-picker-panel-select {
          width: 58px;
          border: ${({ borderColor }) => ('1px solid ' + borderColor)};
          border-bottom: 0;
          border-top: 0;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
          & ul {
            & li {
              padding: 0 0 0 20px;
              height: 30px;
              line-height: 30px;
              color: ${({ mainColor }) => (mainColor)};
              &.rc-time-picker-panel-select-option-selected {
                background: ${({ mainColor }) => (mainColor)};
                color: white;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
`;

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  margin-top: ${({ margin }) => (margin / 2)}px;
  margin-bottom: ${({ margin }) => (margin / 2)}px;
  flex: 1;
  align-self: stretch;
  & input {
    height: 50px;
    flex: 1;
    font-size: ${({ fontSize }) => (fontSize)}px;
    text-align: left;
    align-self: stretch;
    color: ${({ color }) => (color)};
    padding: ${({ padding }) => (padding * 2)}px;
    border: none;
    border-radius: 6px;
    background-color: ${({ backgroundColor }) => (backgroundColor)};
  }
  & .rc-time-picker-clear {
    top: 14px;
    right: 14px;
  }
`;

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const getDisabledHours = (date) => {
  const today = moment();
  if (moment(date).isSame(today, 'day')) {
    return [...Array(roundToNextMin(moment(), 15).add(2, 'hours').hour()).keys()];
  }
  return [];
};

const PrebookingTimePage = ({ date, time, onTimeChanged }) => {
  const { user } = useContext(AppContext);
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const prebookingStartingTimeMinutes = user.userGroup?.prebookingStartingTimeMinutes || 15;

  return (
    <StyledContainer>
      <GlobalStyle
        fontSize={layout.fontSize}
        color={colors.webGray}
        mainColor={colors.tulkaMainColor}
        padding={layout.padding}
        borderColor={colors.webMediumGray}
        backgroundColor={colors.webLightGray}
        warningColor={colors.errorColor}
        maxWidth={prebookingStartingTimeMinutes < MAX_MINUTES_STEP ? 100 : 55}
      />
      <ChatWizardHeader
        title={i18n('prebooking_time_header')}
      />
      <ContentBox data-test='timePickerWrapChatWizard'>
        <StyledTimePicker
          allowEmpty={false}
          fontSize={layout.fontSize}
          margin={layout.margin}
          padding={layout.padding}
          color={colors.webGray}
          mainColor={colors.tulkaMainColor}
          backgroundColor={colors.webLightGray}
          value={getStartTime(date, time, prebookingStartingTimeMinutes)}
          minuteStep={prebookingStartingTimeMinutes}
          showMinute={prebookingStartingTimeMinutes < MAX_MINUTES_STEP}
          showSecond={false}
          focusOnOpen={true}
          onOpen={()=>{
            setTimeout(() => {
              const element = document.getElementsByClassName('rc-time-picker-panel-input')[0];
              if (element) {
                element.setAttribute('maxLength', 5);
              }
            }, 500);
          }}
          onChange={onTimeChanged}
          popupStyle={{
            backgroundColor: colors.webLightGray
          }}
          hideDisabledOptions={true}
          disabledHours={() => getDisabledHours(date)}
          inputReadOnly={false}
        />
      </ContentBox>
    </StyledContainer>
  );
};

PrebookingTimePage.propTypes = {
  onTimeChanged: PropTypes.func.isRequired,
  time: PropTypes.object
};

export default PrebookingTimePage;
