export const formatSelactableLanguages = (aSelectableLanguages, aPrebooking, aPresence, aTargetLanguages, aSelectedTargetLangId, aLanguagePairs) => {
  let selectableLangsList = aSelectableLanguages.filter(lang => {
    return (
      (
        aPrebooking &&
        (
          (aPresence !== 'onsite' && lang.prebooking) ||
          (aPresence === 'onsite' && lang.onSite)
        )
      ) || (!aPrebooking && lang.onDemand)
    ) && !lang.nonLanguage && lang.languageTag !== 'prebooking' && !lang.prebookingId;
  });
  if (aTargetLanguages.length > 1) {
    selectableLangsList.forEach(lang => {
      lang.availability = aLanguagePairs[`${aSelectedTargetLangId}:${lang.id}`];
    });
  } else {
    selectableLangsList.forEach(lang => {
      lang.availability = aLanguagePairs[`${aTargetLanguages[0].id}:${lang.id}`];
    });
  }
  return selectableLangsList.filter(lang => lang.availability !== undefined);
};

export const getSelectableLangs = (user, aSelectableLangs, aSelectableLanguages) => {
  if (Date.now() - user.languagesTimestamp > 24 * 60 * 60 * 1000) {
    // TODO: Need to check
    // Sentry.addBreadcrumb({message: 'Wait for the most recent list'});
    if (aSelectableLangs !== []) {
      return [];
    }
  } else if (aSelectableLanguages.some(lang => lang.languageTag === 'prebooking')) {
    if (Date.now() - user.languagesTimestamp > 60 * 1000) {
      // TODO: Need to check
      // Sentry.addBreadcrumb({message: 'Wait for the most recent list (prebooking)'});
      if (aSelectableLangs !== []) {
        return [];
      }
    }
  }
  return null;
};

export const getLanguagePairs = (languagePairs) => {
  // TODO: different skills
  return languagePairs.reduce((memo, pair) => {
    const newValue = pair.availability || 99;
    const key = `${pair.targetLanguage.id}:${pair.interpretedLanguage.id}`;
    if (!memo[key] || memo[key] < newValue) {
      memo[key] = newValue;
    }
    return memo;
  }, {});
};

export const getTargetLanguages = (userGroup, targetLanguages, selectedTargetLanguageId) => {
  if (selectedTargetLanguageId && targetLanguages.length > 1 && (userGroup && userGroup.showTargetLanguages)) {
    const foundTargetLanguage = targetLanguages?.find(l => l.id === selectedTargetLanguageId);
    if (foundTargetLanguage) {
      return foundTargetLanguage;
    }
    return targetLanguages[0];
  }
  return undefined;
};

export const getSelectableLanguage = (aSelectableLanguages, selectedLanguageId) => {
  const langIndex = aSelectableLanguages.findIndex(lang => lang.id === selectedLanguageId);
  if (langIndex > 0) {
    const selectedLanguage = [...aSelectableLanguages].splice(langIndex, 1)[0];
    return [selectedLanguage, ...aSelectableLanguages];
  }
  return aSelectableLanguages;
};
