import React, { useContext } from 'react';
import Spinner from 'react-spinkit';
import styled from 'styled-components';

import ThemeContext from '../contexts/ThemeContext';

const StyledSpinner = styled(Spinner)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: sk-fade-in 0;
  & > div {
    width: 35px;
    height: 35px;
    color: ${({ color }) => (color)};
  }
  & > div:not(:first-child) {
    margin-right: 10px;
  }
  & > div:not(:last-child) {
    margin-left: 10px;
  }
  ${({ spinneroverlay }) => {
    return spinneroverlay ? `
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: rgba(255, 255, 255, .8);
    ` : '';
  }}
`;

const StyledOverlaySpinner = styled(StyledSpinner)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, .8);
`;

const CustomSpinner = ({
  overlay, color
}) => {
  const { colors } = useContext(ThemeContext);

  if (overlay) {
    return (
      <StyledOverlaySpinner
        name="three-bounce"
        color={color || colors.containerColor}
      />
    );
  }

  return (
    <StyledSpinner
      name="three-bounce"
      color={color || colors.containerColor}
    />
  );
};

export default CustomSpinner;
