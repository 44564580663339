import i18n from '../i18n';
import styled from 'styled-components';
import { WizardList, WizardItem } from './WizardList';
import { WizardStepContainer, WizardStepTitle } from './WizardStep';

const ContentContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const ChooseInterpreterStep = ({selectedChoiceInterpreter, setChoice, handleNext}) => {

  return (
    <WizardStepContainer>
      <ContentContainer>
        <WizardStepTitle 
          data-test='wizardStepTitleForChooseInterpreterStep' 
          label={i18n('selectable_interpreter')}/>
        <WizardList data-test='wizardListForLanguageStep'>
          <WizardItem
            data-test='needInterpreterButtonYes'
            key='yes'
            selectable={selectedChoiceInterpreter}
            label={i18n('yes')}
            handleNext={() => {
              setChoice(true);
              handleNext();
            }}
          />
          <WizardItem
            data-test='needInterpreterButtonNo'
            key='no'
            selectable={!selectedChoiceInterpreter}
            label={i18n('no')}
            handleNext={() => {
              setChoice(false);
              handleNext();
            }}
          />
        </WizardList>
      </ContentContainer>
    </WizardStepContainer>
  );
};

export default ChooseInterpreterStep;