import React from 'react';
import styled from 'styled-components';
import i18n from '../i18n';

import {
  CenteredBlockContainer
} from '../components/basicComponents';

import ChromeLogo from '../images/chromelogo.png';

const StyledDialog = styled.div`
  width: 100%;
  max-width: 300px;
`;

const StyledTitle = styled.h2`
  text-align: center;
`;

const StyledLink = styled.a`
  display: block;
  text-align: center;
  margin: 0px auto;
`;

const StyledTip = styled.h3`
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 40px;
  text-align: center;
`;

const StyledLogo = styled.img`
  position: relative;
  margin: auto;
  font-size: 0px;
  display: block;
  width: 128px;
  height: 128px;
`;

const DownloadChrome = () => (
  <CenteredBlockContainer>
    <StyledDialog>
      <StyledTitle>
        {i18n('onlyChromeSupported')}
      </StyledTitle>
      <StyledLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.google.com/chrome/"
      >
        <StyledLogo
          src={ChromeLogo}
          alt="Chrome"
        />
      </StyledLink>
      <StyledTip>
        {i18n('pleaseUseChrome')}
      </StyledTip>
      <StyledLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.google.com/chrome/"
      >
        {i18n('downloadChrome')}
      </StyledLink>
    </StyledDialog>
  </CenteredBlockContainer>
);

export default DownloadChrome;
