import React, { useContext, useMemo, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table';
import moment from 'moment';
import addDurationCapability from 'moment-duration-format';

import i18n, { langCode } from '../i18n';
import Icon from './Icon';
import HeaderText from './HeaderText';
import Spinner from './Spinner';
import { Input } from './Input';
import DropdownMenu from './DropdownMenu';

import {
  getPrebookings,
} from '../services/prebookService';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import PrebookTypeIcon from './PrebookTypeIcon';
import StatusBadge from './StatusBadge';
import { SimpleTooltip } from './Tooltip';
import {
  BookingsTableContainer,
  BookingTableThead,
  BookingTableTh,
  BookingTableTr,
  BookingTableTd,
  TrText,
  HeaderContainer,
  SortIconContainer,
  BookingsFilterSelect,
} from './basicComponents';

import { parseNoticeMessage, parseNoticeCustomerData } from '../utils/prebooksHelpers';

addDurationCapability(moment);

const StyledBookingTableTd = styled(BookingTableTd)`
  &:first-child {
    text-transform: capitalize;
    width: 120px;
  }
`;

const StyledInput = styled(Input)`
  margin-bottom: ${({ margin }) => (margin)}px;
  background-color: rgba(228, 228, 255, 0.5);
`;

const StyledTopFilterBlock = styled.div`
  flex: 1;
  display: flex;
  padding: 10px 10px;
`;

const SwitchContainer = styled.span`
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: flex-end;
  padding: 0;
  margin: 0 0 0 40px;
  overflow: hidden;
  background-color: rgba(228, 228, 255, 0.5);
  border-radius: 6px;
`;

const SwitchContainerItem = styled.div`
  display: inline-block;
  background-color: ${({ active, backgroundColor }) => active ? backgroundColor : 'none'};
  color: ${({ active }) => active ? '#ffffff' : 'default'};
  line-height: 50px;
  padding: 0 20px;
  user-select: none;
  cursor: pointer;
  border: ${({ active }) => active ? '1px solid #000' : 'none'};
`;

const SwitchList = ({ items, activeItem, onChange, colors }) => {
  return (
    <SwitchContainer>
      {items.map(({ name, key }) => (
        <SwitchContainerItem
          key={key}
          onClick={() => onChange(key)}
          active={key === activeItem}
          backgroundColor={colors.tulkaMainColor}
        >
          {name}
        </SwitchContainerItem>
      ))}
    </SwitchContainer>
  );
};

function GlobalFilter({
  globalFilter,
  setGlobalFilter,
}) {
  const { layout } = useContext(ThemeContext);

  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(aValue => {
    setGlobalFilter(aValue || undefined);
  }, 200);

  return (
    <StyledInput
      margin={layout.padding}
      type="text"
      placeholder={i18n('search_for')}
      value={value || ''}
      onChange={(updatedValue) => {
        setValue(updatedValue);
        onChange(updatedValue);
      }}
    />
  );
}

function StatusColumnFilter({
  column: { filterValue = '', setFilter, preFilteredRows, id },
  ...rest
}) {
  const { layout } = useContext(ThemeContext);

  const options = React.useMemo(() => {
    const optionsSet = new Set();
    preFilteredRows.forEach(row => {
      optionsSet.add(row.values[id]);
    });
    return [...optionsSet.values()].filter(value => {
      return value && value.length > 0;
    });
  }, [id, preFilteredRows]);

  const statusTypes = {
    confirmed: i18n('confirmed'),
    pending: i18n('unconfirmed_prebooking'),
    completed: i18n('completed_prebooking'),
    delivered: i18n('delivered'),
    not_able_to_reach: i18n('not_able_to_reach'),
    cancelled: i18n('cancelled'),
  };

  return (
    <BookingsFilterSelect
      fontSize={layout.smallFontSize}
      value={filterValue}
      data-test={id + 'Select'}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      {...rest}
    >
      <option value="active">{i18n('active')}</option>
      {options.map((option, i) => {
        return (
          <option key={i} value={option}>
            {statusTypes[option] || option}
          </option>
        );
      })}
      <option value="">{i18n('all')}</option>
    </BookingsFilterSelect>
  );
}

function SelectColumnFilter({
  column: { filterValue = '', setFilter, preFilteredRows, id },
  ...rest
}) {
  const { layout } = useContext(ThemeContext);

  const options = React.useMemo(() => {
    const optionsSet = new Set();
    preFilteredRows.forEach(row => {
      optionsSet.add(row.values[id]);
    });
    return [...optionsSet.values()].filter(value => {
      return value && value.length > 0;
    });
  }, [id, preFilteredRows]);

  const callTypeSelectionLocalized = {
    external: i18n('presence_conference'),
    onsite: i18n('presence_on_site'),
    remote: i18n('presence_remote'),
    notice: i18n('presence_notice'),
    data: i18n('call_type_video'),
    call: i18n('call_type_call'),
  };

  return (
    <BookingsFilterSelect
      fontSize={layout.smallFontSize}
      value={filterValue}
      data-test={id + 'Select'}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      {...rest}
    >
      <option value="">{i18n('all')}</option>
      {options.map((option, i) => {
        if (id === 'callType') {
          return (
            <option key={i} value={option}>
              {callTypeSelectionLocalized[option] || option}
            </option>
          );
        }
        return (
          <option key={i} value={option}>
            {option}
          </option>
        );
      })}
    </BookingsFilterSelect>
  );
}

const getClassName = (columnId) => {
  if (columnId === 'invoiceRef') {
    return 'invoiceRef';
  }
  if (columnId === 'invoiceRecipientRef') {
    return 'invoiceRecipientRef';
  }
  if (columnId === 'customerName') {
    return 'customerName';
  }
  if (columnId === 'invoiceUserGroup') {
    return 'invoiceUserGroup';
  }
  return '';
};

const BookingsTable = ({ openPreview, active, openCansel }) => {
  moment.locale(langCode);

  const { user } = useContext(AppContext);
  const { colors, layout } = useContext(ThemeContext);
  const {
    sessionExpired
  } = useContext(FunctionsContext);

  const [switchKey, setSwitchKey] = React.useState('all');
  const _isMounted = useRef(true); // Initial value _isMounted = true
  const [today] = useState(moment());
  const [tomorrow] = useState(moment().add(1, 'day'));
  const [filterItems] = useState([{
    name: i18n('all'),
    key: 'all'
  },{
    name: i18n('personal'),
    key: 'personal'
  }]);

  useEffect(() => {
    return () => { // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, []);

  const [prebookings, setPrebookings] = useState([]);
  const [indicator, setIndicator] = useState(false);

  const getPreparedPrebookings = async (personal = true) => {
    setIndicator(true);
    try {
      const result = await getPrebookings(personal, user.type);
      if (_isMounted.current) {
        const preparedPrebookings = result.prebookings.map((prebooking) => {
          if (prebooking.presence === 'notice') {
            const parsedNoticeMessage = parseNoticeMessage(prebooking.messageToInterpreter);
            const parsedNoticeCustomerData = parseNoticeCustomerData(prebooking.invoiceRef);

            return {
              ...prebooking,
              customerData: parsedNoticeCustomerData.customerData,
              customerDataBirthday: parsedNoticeCustomerData.customerBirthday,// ? new Date(parsedNoticeCustomerData.customerBirthday) : new Date(),
              alternativeContactPerson: parsedNoticeMessage.alternativeContactPerson,
              mainPhoneNumber: parsedNoticeMessage.phoneNumbers[0],
              phoneNumbers: parsedNoticeMessage.phoneNumbers?.slice(1)?.join(' '),
              messageToCustomerService: prebooking.comments,
              contentData: parsedNoticeMessage.contentData,
            };
          }
          return prebooking;
        });
        setPrebookings(preparedPrebookings || []);
        setIndicator(false);
      }
    } catch (e) {
      if (e.message === 'Session expired') {
        sessionExpired();
      }
      if (_isMounted.current) {
        setIndicator(false);
      }
    }
  };

  useEffect(() => {
    if (active !== false) {
      getPreparedPrebookings(switchKey === 'personal');
    }
  }, [switchKey, active]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const func = (ev) => {
      if (ev && ev.detail) {
        setPrebookings([ev.detail, ...prebookings]);
      }
    };
    document.addEventListener('prebookCreated', func);
    return () => {
      document.removeEventListener('prebookCreated', func);
    };
  }, [prebookings]);

  useEffect(() => {
    const func = (ev) => {
      if (ev && ev.detail) {
        setPrebookings(prebookings.map((prebook) => {
          if (prebook.id === ev.detail.id) {
            prebook.cancelledAt = new Date();
            prebook.prencelledBy = {
              _id: user.id,
              name: user.name,
            };
          }
          return prebook;
        }));
      }
    };
    document.addEventListener('prebookCancelled', func);
    return () => {
      document.removeEventListener('prebookCancelled', func);
    };
  }, [user, prebookings]);

  useEffect(() => {
    const func = (ev) => {
      if (ev && ev.detail) {
        setPrebookings(prebookings.map((prebook) => {
          if (prebook.id === ev.detail.prebook.id) {
            return { ...prebook, ...ev.detail.prebook };
          }
          return prebook;
        }));
      }
    };
    document.addEventListener('prebookUpdated', func);
    return () => {
      document.removeEventListener('prebookUpdated', func);
    };
  }, [user, prebookings]);

  const getPrebookStatus = (presence, interpreter, durationActual, cancelledAt) => {
    if (cancelledAt) {
      return 'cancelled';
    }
    if (presence === 'notice') {
      if (durationActual > 0) {
        return 'delivered';
      }
      if (durationActual === 0) {
        return 'not_able_to_reach';
      }
    }
    return (interpreter) ? 'confirmed' : 'pending';
  };

  const data = React.useMemo(() => {
    return prebookings.map((booking) => ({
      id: booking.id,
      date: new Date(booking.interpretationDate),
      callType: (booking.presence === 'onsite' || booking.presence === 'notice' || (booking.presence === 'external' && booking.callType === 'data')) ? booking.presence : booking.callType,
      language: booking.interpretedLanguage.name,
      status: getPrebookStatus(booking.presence, booking?.interpreter, booking.durationActual, booking.cancelledAt),
      durationEstimated: booking.durationEstimated,
      leewayBefore: booking.leewayBefore,
      interpreter: booking.interpreter,
      orderNumber: booking.orderNumber,
      customerName: (booking.customer && booking.customer.name) ? booking.customer.name : '',
      invoiceRef: booking.invoiceRef || '',
      invoiceUserGroup: (booking.invoiceUserGroup && booking.invoiceUserGroup.name) ? booking.invoiceUserGroup.name : '',
      invoiceRecipientRef: booking.invoiceRecipientRef ? booking.invoiceRecipientRef : '',
      hotlinePhoneNumber: booking.hotlinePhoneNumber
    }));
  }, [ prebookings ]);

  const columns = React.useMemo(() => {
    const titles = [
      {
        Header: i18n('time'),
        accessor: 'date',
        Cell: ({ cell: { value, row }}) => {
          const date = moment(value);
          let dateString = date.format('DD MMMM HH:mm');
          const now = value - Date.now() <= (row.original.leewayBefore * 1000) && row.original.interpreter;
          const time = date.format('HH:mm');

          if (now) {
            dateString = i18n('now').toUpperCase() + ` ${time}`;
          } else if (date.year() === today.year() && date.month() === today.month() && date.date() === today.date()) {
            dateString = i18n('today') + ` ${time}`;
          } else if (date.year() === tomorrow.year() && date.month() === tomorrow.month() && date.date() === tomorrow.date()) {
            dateString = i18n('tomorrow') + ` ${time}`;
          }

          return dateString;
        },
        sortType: 'datetime'
      },
      {
        Header: i18n('language_title'),
        accessor: 'language',
        sortType: 'basic'
      },
      {
        Header: i18n('status'),
        accessor: 'status',
        Filter: StatusColumnFilter,
        filter: (rows, id, filterValue) => {
          return rows.filter((row) => {
            if (filterValue === 'active') {
              return row?.values?.status !== 'cancelled';
            }
            return row?.values?.status === filterValue;
          });
        },
        sortType: 'basic',
        Cell: ({ cell: { value } }) => (
          <StatusBadge
            status={value}
          />
        )
      },
      {
        Header: i18n('prebooking_duration'),
        accessor: 'durationEstimated',
        sortType: 'basic',
        Cell: ({ cell: { value }}) => {
          return moment.duration(value, 'seconds').format('hh:mm', {
            trim: false
          });
        },
      },
      {
        Header: i18n('call_type_title'),
        accessor: 'callType',
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ cell: { value }}) => (
          <PrebookTypeIcon
            type={value}
          />
        ),
      },
      {
        Header: i18n('interpreter_phone_number'),
        accessor: 'hotlinePhoneNumber',
        sortType: 'basic',
      }
    ];

    titles.push({
      Header: i18n('invoice_user_group'),
      accessor: 'invoiceUserGroup',
      Filter: SelectColumnFilter,
      filter: 'includes',
      sortType: 'basic',
    });

    titles.push({
      Header: i18n('customer'),
      accessor: 'customerName',
      Filter: SelectColumnFilter,
      filter: 'includes',
      sortType: 'basic',
    });

    titles.push({
      Header: i18n('customerInvoiceNotes'),
      accessor: 'invoiceRef',
      Filter: SelectColumnFilter,
      filter: 'includes',
      sortType: 'basic',
      Cell: ({ cell: { value, row }}) => {
        if (row.original.callType === 'notice') {
          return '';
        }
        return value;
      },
    });

    titles.push({
      Header: i18n('alternative_cost_center'),
      accessor: 'invoiceRecipientRef',
      Filter: SelectColumnFilter,
      filter: 'includes',
      sortType: 'basic',
    });

    if (data.some((elem) => elem.orderNumber)) {
      titles.push({
        Header: i18n('reservation_number'),
        accessor: 'orderNumber',
        sortType: 'basic'
      });
    }

    titles.push({
      Header: '',
      accessor: 'actionMenu',
      Cell: (props) => {
        return (
          <DropdownMenu
            row={props.row}
            icon="more_vert"
            iconColor={colors.webDarkBlue}
            hoverBackgroundColor={colors.webDarkBlue}
            openPrebookPreview={openPrebookPreview}
            openCanselPreview={openCanselPreview}
          />
        );
      },
    });
    return titles;
  }, [data, today, tomorrow, openPrebookPreview, openCanselPreview]); // eslint-disable-line react-hooks/exhaustive-deps

  const tableInstance = useTable({ columns, data, initialState: {
    filters: [
    ],
  }}, useFilters, useGlobalFilter, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {
      globalFilter
    },
    preGlobalFilteredRows,
    setAllFilters,
    setGlobalFilter,
  } = tableInstance;

  useEffect(() => {
    setAllFilters([]);
  }, [switchKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const isUsergroupCustomer = useMemo(() => {
    return user && user.role === 'usergroup_customer';
  }, [user]);

  const openCanselPreview = row => {
    if (row && row.original && row.original.id) {
      const prebook = prebookings.find((aPrebook) => aPrebook.id === row.original.id);
      openCansel(prebook);
    }
  };

  const openPrebookPreview = (row, settingMode) => {
    if (row && row.original && row.original.id) {
      const prebook = prebookings.find((aPrebook) => aPrebook.id === row.original.id);
      openPreview(prebook, settingMode);
    }
  };

  const body = useMemo(() => {
    return rows.map(row => {
      prepareRow(row);
      return (
        <BookingTableTr
          {...row.getRowProps()}
          onClick={(event) => {
            if (event.target.nodeName.toLowerCase() !== 'i' && event.target.nodeName.toLowerCase() !== 'button' && event.target.className.toLowerCase() !== 'dropdownitem' && event.target.nodeName.toLowerCase() !== 'li') {
              openPrebookPreview(row, false, false);
            }
          }}
        >
          {row.cells.map(cell => {
            return (
              <StyledBookingTableTd
                className={getClassName(cell.column.id)}
                {...cell.getCellProps()}
              >
                {
                  getClassName(cell.column.id) ? (
                    <SimpleTooltip
                      direction="top"
                      text={cell.render('Cell')}
                    >
                      {cell.render('Cell')}
                    </SimpleTooltip>
                  ) : cell.render('Cell')
                }
              </StyledBookingTableTd>
            );
          })}
        </BookingTableTr>
      );
    });
  }, [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <StyledTopFilterBlock>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {isUsergroupCustomer ? (
          <SwitchList
            colors={colors}
            items={filterItems}
            activeItem={switchKey}
            onChange={setSwitchKey}
          />
        ) : null}
      </StyledTopFilterBlock>
      <BookingsTableContainer
        fontSize={layout.smallFontSize}
        {...getTableProps()}
      >
        <BookingTableThead>
          {headerGroups.map(headerGroup => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()} valign="top">
                {headerGroup.headers.map((column, index) => {
                  return (
                    <BookingTableTh
                      className={getClassName(column.id)}
                      key={index}
                    >
                      <TrText
                        fontSize={layout.smallFontSize}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                        <SortIconContainer>
                          <Icon
                            icon='arrows-v'
                            iconLib='fontAwesome'
                            iconColor={colors.webDarkBlue}
                            iconSize={12}
                            withoutContainer={true}
                          />
                          <Icon
                            icon='sort'
                            iconLib='material'
                            iconColor={colors.webDarkBlue}
                            iconSize={16}
                            withoutContainer={true}
                          />
                        </SortIconContainer>
                      </TrText>
                      <div>
                        {column.Filter ? column.render('Filter') : null}
                      </div>
                    </BookingTableTh>
                  );
                })}
              </tr>
            );
          })}
        </BookingTableThead>
        <tbody {...getTableBodyProps()}>
          {body}
        </tbody>
      </BookingsTableContainer>
      {(rows.length === 0) && (
        <HeaderContainer>
          {indicator ? (
            <Spinner/>
          ) : (
            <HeaderText
              text={i18n('no_prebookings')}
              fontSize={layout.headerFontSize}
            />
          )}
        </HeaderContainer>
      )}
    </div>
  );
};

export default BookingsTable;
