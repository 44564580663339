import requestService from './requestService';

const endpoints = {
  preferredInterpreters: 'user/searchInterpreters',
  interpreters: '/user/getInterpreters',
  availability: 'matching/availability'
};

const formatParametrs = (parametrsArray) => {
  return parametrsArray.filter(param => param).join('&');
};

export const getPreferredInterpreters = async() => {
  try {
    return requestService.get(`${endpoints.preferredInterpreters}`, null, {apiType: 'platform'});
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getInterpreters = async(getLanguages, languageId) => {
  const languageQuery = getLanguages ? 'getLanguages=true' : '';
  const seqrchQuery = languageId ? `language=${encodeURIComponent(languageId)}` : '';

  try {
    return requestService.get(`${endpoints.interpreters}`, formatParametrs([languageQuery, seqrchQuery]), {apiType: 'platform-v2'});
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getAvailabilityInterpreters = async (languageId, skillId) => {
  try {
    const res = await requestService.postWithJson(`${endpoints.availability}`, {}, {languageId, skillId}, { apiType: 'platform'});
    return res;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
