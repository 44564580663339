import { useContext } from 'react';
import ThemeContext from '../contexts/ThemeContext';
import styled from 'styled-components';
import { WizardStepContainer } from './WizardStep';
import { StyledWizardLinkItem } from './WizardList';

const ContentContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  ul {
    list-style: none;
  }
  li {
    text-align: left;
  }
  li.right {
    text-align: right;
  }
  h3 {
    color: inherit;
    font-weight: bold;
  }
`;

const LuonaRedirectStep = () => {
  const { colors } = useContext(ThemeContext);

  return (
    <WizardStepContainer>
      <ContentContainer>
        <ul>
          <li>
            <p>English:</p>
            <p>Starting from December 1st 2023, you will be able to access DigiVOK using a new link.</p>
          </li>
          <li>
            <p>Suomi:</p>
            <p>DigiVOKiin pääset 1.12.2023 alkaen uuden linkin kautta.</p>
          </li>
          <li>
            <p>Українська:</p>
            <p>Починаючи з 1 грудня 2023 року, ви зможете отримати доступ до DigiVOK за новим посиланням.</p>
          </li>
          <li>
            <p>Русский:</p>
            <p>Начиная с 1 декабря 2023 года, вы сможете получить доступ к DigiVOK по новой ссылке.</p>
          </li>
          <li className='right'>
            <p dir="rtl">عربي:</p>
            <p dir="rtl">نم اًءدب1 ديسمبر 2023، ستتمكن من الوصول إلى DigiVOK باستخدام رابط جديد.</p>
          </li>
          <li className='right'>
            <p dir="rtl">سۆرانی:</p>
            <p dir="rtl">لە يەک ەمی کانوون ی دووەمی ٢٠٢٣ەوە دەتوان ي ت  ب ە ب ەکارهێ نان ی ب ەست ەرێ کی نوێ  بچي ت ە ناو دي جيڤۆکە وە .</p>
          </li>
        </ul>
        <StyledWizardLinkItem
          color={colors.luonaMainBrightColor}
          target='_blank'
          href='https://luona.fi/en/digivok'
        >
          luona.fi/digivok
        </StyledWizardLinkItem>
      </ContentContainer>
    </WizardStepContainer>
  );
};

export default LuonaRedirectStep;