import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import FunctionsContext from '../contexts/FunctionsContext';

import i18n from '../i18n';
import UsergroupSelect from './UsergroupSelect';
import Spinner from './Spinner';
import { RadioInput } from './Input';

import {
  getUsergroups,
} from '../services/usergroupService';

const StyledLabel = styled.div`
  font-size: 16px;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

// const StyledLink = styled.span`
//   white-space: nowrap;
//   height: auto;
//   align-self: center;
//   cursor: pointer;
//   color: white;
//   font-weight: normal;
//   border-radius: 50px;
//   border: ${({ backgroundColor }) => ('1px solid ' + backgroundColor)};
//   background-color: ${({ backgroundColor }) => (backgroundColor)};
//   padding: 4px 12px;
//   transition: ${({ transition }) => (transition)};
//   &:hover {
//     color: ${({ backgroundColor }) => (backgroundColor)};
//     background-color: transparent;
//   }
// `;

const StyledOptionContainer = styled.div`
  height: 30px;
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 15px;
`;

const StyledSelectContainer = styled.div`
  padding: 5px 0;
  box-sizing: border-box;
`;

const StyledOptionText = styled.span`
  max-width: 100%;
  padding: 4px 0;
  word-break: break-all;
  display: inline-flex;
  align-items: center;
`;

const StyledUserGroupList = styled.div`
  padding: 10px 0;
`;

const HomeUserGroupSelector = ({ id, selectedUsergroup, setSelectedUsergroup, pureList, ...rest }) => { //openInviteDialog, isUserGroupSaved,
  const functionsContext = useContext(FunctionsContext);

  const [openOther, setOpenOther] = React.useState(false);
  const [userGroups, setUserGroup] = React.useState([]);

  const [indicator, setIndicator] = React.useState(false);
  const [isSearchAllowed, setIsSearchAllowed] = React.useState(true);

  const containerRef = React.useRef(null);
  const scrollContainerRef = React.useRef(null);

  useEffect(() => {
    let updated = true;
    if (selectedUsergroup && selectedUsergroup.id && updated) {
      const selectedGroupInGroup = userGroups.find((group) => {
        return group.id === selectedUsergroup.id;
      });
      if (selectedGroupInGroup && selectedGroupInGroup.customFields && !selectedUsergroup.customFields) {
        setSelectedUsergroup(selectedGroupInGroup);
      }
      setOpenOther(!selectedGroupInGroup);
    }
    return () => updated = false;
  }, [userGroups, selectedUsergroup, setSelectedUsergroup, setOpenOther]);

  const getUserGroups = async (query) => {
    try {
      const response = await getUsergroups(query, pureList);
      if (!pureList && !Array.isArray(response)) {
        setIsSearchAllowed(response.options?.allowSearch);
        if (!response.options?.allowSearch && selectedUsergroup) {
          setSelectedUsergroup(null);
        }
      }
      return Array.isArray(response) ? response : response.groups;
    } catch (e) {
      if (e.message === 'Session expired') {
        functionsContext.sessionExpired();
      }
      return [];
    }
  };

  const loadUserGroups = async () => {
    setIndicator(true);
    try {
      const result = await getUserGroups();
      if (result.length > 1) {
        result.sort((a, b) => {
          return a.id === selectedUsergroup?.id ? -1 : b.id === selectedUsergroup?.id ? 1 : 0;
        });
      }
      setUserGroup(result);
      setIndicator(false);
    } catch (e) {
      setIndicator(false);
    }
  };

  useEffect(() => {
    let updated = false;
    if (!updated) {
      loadUserGroups();
    }
    return () => {
      updated = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (indicator) {
    return (
      <Spinner/>
    );
  }

  // {
  //   (selectedUsergroup?.id === userGroup?.id  && openInviteDialog && isUserGroupSaved) ? (
  //     <StyledLink
  //       transition={Layout.transition}
  //       backgroundColor={colors.containerColor}
  //       onClick={openInviteDialog}
  //     >
  //       {i18n('invite_to_group')}
  //     </StyledLink>
  //   ) : null
  // }

  return (
    <StyledUserGroupList ref={containerRef} {...rest} data-test='settingsUserGroupList' >
      {
        userGroups && userGroups.map((userGroup) => {
          return (
            <StyledOptionContainer
              key={userGroup.id}
              data-test={userGroup.id + 'UserGroup'}
            >
              <RadioInput
                id={`${id}-${userGroup.id}`}
                checked={!openOther && (selectedUsergroup && selectedUsergroup.id === userGroup.id)}
                name={`${id}-userGroup`}
                label={(
                  <StyledLabel
                    htmlFor={`${id}-${userGroup?.id}`}
                  >
                    <StyledOptionText>
                      {userGroup.name}
                      {`${userGroup.invoiceRecipientRef ? `(${userGroup.invoiceRecipientRef})` : ''}`}
                    </StyledOptionText>
                  </StyledLabel>
                )}
                value={userGroup.id}
                onChange={() => {
                  setOpenOther(false);
                  setSelectedUsergroup(userGroup);
                }}
              />
            </StyledOptionContainer>
          );
        })
      }
      {
        isSearchAllowed ? (
          <>
            <StyledOptionContainer data-test='otherCostCenterChatWizard'>
              <RadioInput
                id={`${id}-other`}
                checked={openOther}
                name={`${id}-userGroup`}
                label={(
                  <StyledOptionText>
                    {i18n('other')}
                  </StyledOptionText>
                )}
                value="other"
                onChange={() => {
                  setOpenOther(true);
                  setSelectedUsergroup(undefined);
                }}
              />
            </StyledOptionContainer>
            {
              openOther ? (
                <StyledSelectContainer ref={scrollContainerRef}>
                  <UsergroupSelect
                    data-test={rest['data-test']}
                    containerRef={containerRef}
                    scrollContainerRef={scrollContainerRef}
                    selected={selectedUsergroup}
                    onChange={setSelectedUsergroup}
                    getSelectableItems={getUserGroups}
                  />
                </StyledSelectContainer>
              ) : null
            }
          </>
        ) : null
      }
    </StyledUserGroupList>
  );
};

export default HomeUserGroupSelector;
