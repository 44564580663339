import React, { useContext } from 'react';
import styled from 'styled-components';

import ThemeContext from '../contexts/ThemeContext';

const StyledHeader = styled.span`
  line-height: ${({ fontSize }) => (fontSize + 8)}px;
  text-align: left;
  font-size: ${({ fontSize }) => (fontSize)}px;
  color: ${({ color }) => (color)};
  white-space: pre-line;
`;

const HeaderText = ({ text, color, fontSize, ...rest }) => {
  const themeContext = useContext(ThemeContext);
  const colors = themeContext.colors;

  return (
    <StyledHeader
      fontSize={fontSize}
      color={color ? color : colors.webDarkBlue}
      {...rest}
    >
      {text}
    </StyledHeader>
  );
};

export default HeaderText;
