import React, { useContext } from 'react';
import ThemeContext from '../contexts/ThemeContext';
import Clipboard from 'react-clipboard.js';
import i18n from '../i18n';
import styled from 'styled-components';
import { showSuccess } from '../services/toastService';

const StyledWizardList = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const commonItemStyles = `
  display: inline-block;
  width: 100%;
  max-width: 430px;
  padding: 10px;
  border-radius: 16px;
  cursor: pointer;
  :not(:last-child){
    margin-bottom: 10px;
  }
`;

const StyledWizardItem = styled.div`
  ${commonItemStyles};
  background: ${({ selectable, color }) => selectable && color};
  color: ${({ selectable }) => selectable && '#fff'};
  border: 1px solid ${({ color }) => color};
  :hover {
    background: ${({ color }) => color};
    color: #fff;
  }
`;

export const StyledWizardLinkItem = styled.a`
  ${commonItemStyles};
  background: ${({ selectable, color }) => selectable && color};
  color: ${({ selectable }) => selectable && '#fff'};
  border: 1px solid ${({ color }) => color};
  :hover {
    background: ${({ color }) => color};
    color: #fff;
  }
`;

const EmailClipboard = styled(Clipboard)`
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
`;

export const WizardList = ({ children, ...rest }) => {
  return <StyledWizardList {...rest}>{children}</StyledWizardList>;
};

export const WizardItem = ({ selectable, label, type, link, handleNext, email, ...rest }) => {
  const { colors } = useContext(ThemeContext);
  if (type === 'link') {
    return (
      <StyledWizardLinkItem
        selectable={selectable}
        color={colors.luonaMainBrightColor}
        target='_blank'
        href={link}
        {...rest}
      >
        {label}
      </StyledWizardLinkItem>
    );
  }
  return (
    <StyledWizardItem
      selectable={selectable}
      color={colors.luonaMainBrightColor}
      onClick={handleNext}
      {...rest}
    >
      {label}
      {email && (
        <EmailClipboard
          data-clipboard-text={email}
          button-title={i18n('copy_email')}
          onSuccess={() => showSuccess(i18n('successfully_copied'))}
        >
          {email}
        </EmailClipboard>
      )}
    </StyledWizardItem>
  );
};
