import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Icon from './Icon';
import hexToRgbA from '../utils/hexToRgbA';
import { isPhoneNumberValid } from '../utils/prebooksHelpers';
import i18n from '../i18n';

import ThemeContext from '../contexts/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const baseInputStyles = css`
  flex: 1;
  display: flex;
  font-size: ${({ fontSize }) => fontSize}px;
  text-align: left;
  align-self: stretch;
  width: 100%;
  color: ${({ color }) => color};
  padding: ${({ padding }) => (padding ? padding * 2 : 0)}px;
  border-radius: 6px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 1px solid ${({ backgroundColor }) => backgroundColor};
    -webkit-text-fill-color: ${({ color }) => color};
    -webkit-box-shadow: 0 0 0 1000px ${({ backgroundColor }) => hexToRgbA(backgroundColor, 1)} inset;
    box-shadow: 0 0 0 1000px ${({ backgroundColor }) => hexToRgbA(backgroundColor, 1)} inset;
    transition: ${({ backgroundColor }) => hexToRgbA(backgroundColor, 1)} 5000s
      ease-in-out 0s;
  }
  &::-webkit-input-placeholder {
    color: ${({ lightTextColor }) => lightTextColor};
  }
  &::-moz-placeholder {
    color: ${({ lightTextColor }) => lightTextColor};
  }
  &:-ms-input-placeholder {
    color: ${({ lightTextColor }) => lightTextColor};
  }
  &:-moz-placeholder {
    color: ${({ lightTextColor }) => lightTextColor};
  }
  &[type='password'] {
    -webkit-text-security: square !important;
  }
`;

const StyledInput = styled.input`
  ${baseInputStyles}
  ${({ transition }) => (transition ? `transition: ${transition};` : '')}
  height: ${({ height }) => (height ? height : '50')}px;
  border: ${({ borderColor }) => '1px solid ' + borderColor};
`;

const StyledTextarea = styled.textarea`
  ${baseInputStyles}
  height: ${({ height }) => (height ? height : '88')}px;
  border: ${({ borderColor }) => '1px solid ' + borderColor};
  resize: vertical;
`;

const InputTitle = styled.div`
  flex: 1;
  display: flex;
  font-size: ${({ fontSize }) => fontSize}px;
  text-align: left;
  color: ${({ color }) => color};
  background-color: transparent;
  ${({ inline }) => (inline ? 'display: inline;' : '')}
  align-items: center;
  font-weight: ${({ notBoldInputTitle }) =>
    notBoldInputTitle ? 'normal' : '600'};
  margin-bottom: 5px;
`;

const InputSubTitle = styled.p`
  font-size: .8em;
  text-align: left;
  color: ${({ color }) => color};
  background-color: transparent;
  align-items: center;
  font-weight: ${({ notBoldInputTitle }) =>
    notBoldInputTitle ? 'normal' : '600'};
  margin-bottom: 5px;
`;

const StyledMaxAmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: ${({ fontSize }) => fontSize}px;
`;

const StyledRadioInput = styled.input`
  display: none;
  &:checked + ${StyledCustomRadioLabel} {
    &:after {
      transform: scale(1);
      transition: all .2s cubic-bezier(.35,.9,.4,.9);
      opacity: 1;
    }
  }
`;

const StyledRadioLabel = styled.label`
  width: 100%;
  height: auto;
  line-height: normal;
  position: relative;
  top: 0;
  margin: 0 0 0 5px;
  font-size: 16px;
  line-height: 21px;
  color: ${({ color }) => color};
  transition: ${({ transition }) => transition};
  span {
    color: ${({ color }) => color};
  }
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
    span {
      color: ${({ hoverColor }) => hoverColor};
    }
  }
  &:hover {
    ${StyledCustomRadioLabel}:after {
      transform: scale(3.6);
    }
  }
`;

const StyledCustomRadioLabel = styled.span`
  position: relative;
  display: block;
  float: left;
  margin: 3px 8px 3px 0;
  width: 20px;
  height: 20px;
  border: 2px solid ${({ color }) => color};
  border-radius: 100%;
  -webkit-tap-highlight-color: transparent;
  line-height: 21px;
  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${({ color }) => color};
    transform: scale(0);
    transition: all .2s ease;
    opacity: .08;
    pointer-events: none;
  }
`;

const StyledMyTulkaInput = styled.input`
  ${baseInputStyles}
  height: 38px;
  min-height: 38px;
  border-radius: 18px;
  border: none;
  font-weight: 600;
`;

const StyledMyTulkaInputWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 38px;
  min-height: 38px;
  border-radius: 18px;
  border: none;
  overflow: hidden;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const StyledMyTulkaInputWithIcon = styled(StyledMyTulkaInput)`
  height: 38px;
  min-height: 38px;
  border: none;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
`;

const StyledMyTulkaInputIcon = styled(Icon)`
  margin: 0 8px 0 14px;
`;

const ErrorBlock = styled.div`
  width: 100%;
  font-size: ${({ fontSize }) => fontSize}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  display: inline-block;
  position: relative;
  border-radius: 6px;
  padding: 4px 8px;
  letter-spacing: 0px;
  margin-top: 6px;
  &::after {
    color: ${({ backgroundColor }) => backgroundColor};
    border-right: 5px solid transparent;
    border-bottom: 6px solid;
    border-left: 5px solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    left: calc(50% - 5px);
    top: -6px;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const RedText = styled.span`
  color: ${({ color }) => color};
`;
export const StyledLuonaInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;
`;
const StyledErrorIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;
const StyledLuonaInput = styled.input`
  width: 100%;
  font-family: inherit;
  font-size: 16px;
  border: 2px solid ${({ color }) => color};
  color: ${({ error }) => (error ? '#ff0000' : '#000')};
  border-radius: 6px;
  height: 50px;
  min-height: 50px;
  padding: 10px;
  padding-right: 30px;
  background-color: #ffffff;
  ::placeholder {
    font-family: inherit;
    color: ${({ color }) => color};
    font-size: 0.8rem;
  }
`;
const StyledLuonaSelect = styled.select`
  width: 100%;
  font-family: inherit;
  font-size: 16px;
  border-radius: 6px;
  border: 2px solid ${({ color }) => color};
  color: #000;
  padding: 10px;
  background-color: #ffffff;
  margin: 0;
  margin-bottom: 15px;
`;
const StyledLuonaOption = styled.option`
  font-family: inherit;
  color: ${({ color }) => color};
`;
const StyledLuonaInputLabel = styled.label`
  font-family: inherit;
  font-weight: 300;
  font-size: 18px;
  display: block;
  margin: 0;
  position: static;
  color: #000;
  height: 1.4rem;
  line-height: 1.2rem;
`;
const StyledLuonaForm = styled.form`
  width: 300px;
  display: flex;
  flex-flow: column;
  margin-top: 12px;
  .requestError {
    text-align: center;
  }
`;
const StyledFormTitle = styled.h2`
  font-family: inherit;
  font-weight: 400;
  color: ${({ brightColor }) => brightColor};
  text-transform: uppercase;
  text-align: ${({ position }) => position};
  margin: 0;
`;

const LuonaForm = ({ handleSubmit, children }) => {
  return <StyledLuonaForm onSubmit={handleSubmit}>{children}</StyledLuonaForm>;
};
const LuonaFormTitle = ({ title, position = 'left', ...rest }) => {
  const { colors } = useContext(ThemeContext);
  return (
    <StyledFormTitle
      position={position}
      brightColor={colors.luonaMainBrightColor}
      {...rest}
    >
      {title}
    </StyledFormTitle>
  );
};
const LuonaInputLabel = ({ htmlFor, label, ...rest }) => {
  return (
    <StyledLuonaInputLabel {...rest} htmlFor={htmlFor}>{label}</StyledLuonaInputLabel>
  );
};
const LuonaInput = ({
  name,
  type,
  title,
  handleChange,
  value,
  placeholder,
  error,
  ...rest
}) => {
  const { colors } = useContext(ThemeContext);
  return (
    <StyledLuonaInputWrapper>
      <StyledLuonaInput
        id={name}
        color={colors.luonaMainBrightColor}
        type={type}
        name={name}
        title={title}
        value={value}
        required
        placeholder={placeholder}
        onChange={handleChange}
        error={error}
        {...rest}
      />
      {error && (
        <StyledErrorIconWrapper>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size='1x'
            color={colors.luonaMainBrightColor}
          />
        </StyledErrorIconWrapper>
      )}
    </StyledLuonaInputWrapper>
  );
};

const StyledAgreementCheckbox = styled.input`
  width: 24px;
  height: 24px;
`;

const LuonaAgreementBlock = ({
  agreeText,
  linkName,
  linkPath,
  agreeTerms,
  handleAgreeChange,
  componentName,
  rtl
}) => {
  const { colors } = useContext(ThemeContext);
  return (
    <AgreementBlock data-test={`agreementBlockFor${componentName}`} rtl={rtl}>
      <StyledAgreementCheckbox
        data-test={`agreementCheckboxFor${componentName}`}
        type='checkbox'
        defaultChecked={agreeTerms}
        onClick={handleAgreeChange}
      />
      <AgreementText>
        {agreeText}
        <StyledLuonaPolicyLink
          data-test={`policyLinkFor${componentName}`}
          href={linkPath}
          target='_blank'
          rel='noreferrer'
          color={colors.luonaMainBrightColor}
        >
          {linkName}
        </StyledLuonaPolicyLink>
      </AgreementText>
    </AgreementBlock>
  );
};
const AgreementBlock = styled.div`
  display: flex;
  align-items: unset;
  margin-top: 12px;
  color: inherit;
  text-align: left;
  ${({rtl}) => rtl && 'justify-content: flex-end;'}
`;
const AgreementText = styled.p`
  margin: 0;
  margin-left: 10px;
  font-weight: 300;
  color: inherit;
`;
const StyledLuonaPolicyLink = styled.a`
  color: ${({ color }) => color};
  font-weight: 300;
`;
const LuonaOption = ({ value, label, disabled }) => {
  const { colors } = useContext(ThemeContext);
  return (
    <StyledLuonaOption
      value={value}
      color={colors.luonaMainBrightColor}
      disabled={disabled}
    >
      {label}
    </StyledLuonaOption>
  );
};
const LuonaSelect = ({ name, handleChange, value, title, children }) => {
  const { colors } = useContext(ThemeContext);
  return (
    <StyledLuonaSelect
      name={name}
      id={name}
      value={value}
      title={title}
      color={colors.luonaMainBrightColor}
      required
      selected
      onChange={handleChange}
    >
      {children}
    </StyledLuonaSelect>
  );
};
const Required = () => {
  const { colors } = useContext(ThemeContext);
  return <RedText color={colors.error}>*</RedText>;
};

const Error = ({ message }) => {
  const { colors } = useContext(ThemeContext);
  return <RedText color={colors.error}>{message}</RedText>;
};

const RadioInput = ({ id, name, value, onChange, label, checked, ...rest }) => {
  const { colors, layout } = useContext(ThemeContext);

  return (
    <StyledRadioLabel
      color={colors.webDarkBlue}
      hoverColor={colors.tulkaMainColor}
      transition={layout.transition}
      htmlFor={id}
      {...rest}
    >
      <StyledRadioInput
        type='radio'
        id={id}
        checked={checked}
        name={name}
        value={value}
        onChange={onChange}
      />
      <StyledCustomRadioLabel color={colors.tulkaMainColor} />
      {label}
    </StyledRadioLabel>
  );
};

const Input = ({
  title,
  subTitle,
  type,
  active,
  noDelay,
  onChange,
  onNext,
  height,
  maxLength,
  fontSize,
  notBoldInputTitle,
  required,
  readOnly,
  inlineTitle,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const textInput = useRef(null);

  useEffect(() => {
    if (active) {
      const timer = setTimeout(
        () => {
          textInput.current.focus();
        },
        noDelay ? 0 : 900
      );
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [active, noDelay]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onNext();
    }
  };

  if (type === 'textarea') {
    return (
      <>
        {title ? (
          <InputTitle
            color={colors.webDarkBlue}
            notBoldInputTitle={notBoldInputTitle}
            fontSize={fontSize || layout.fontSize}
            inline={inlineTitle}
          >
            {title}
            {required ? <Required /> : ''}
          </InputTitle>
        ) : null}
        <StyledTextarea
          type={type}
          height={height}
          fontSize={fontSize || layout.fontSize}
          color={colors.webGray}
          lightTextColor={colors.webMediumGray}
          backgroundColor={colors.webLightGray}
          borderColor={colors.webDefaultInputBorderColor}
          padding={layout.padding}
          maxLength={maxLength}
          ref={textInput}
          onChange={(event) => onChange(event.target.value)}
          onKeyDown={onNext ? handleKeyDown : null}
          readOnly={readOnly}
          {...rest}
        />
        {maxLength ? (
          <StyledMaxAmountContainer fontSize={layout.smallFontSize}>
            {rest.value?.length || 0}/{maxLength}
          </StyledMaxAmountContainer>
        ) : null}
      </>
    );
  }

  return (
    <>
      {title ? (
        <InputTitle
          color={colors.webDarkBlue}
          notBoldInputTitle={notBoldInputTitle}
          fontSize={fontSize || layout.fontSize}
        >
          {title}
          {required ? <Required /> : ''}
        </InputTitle>
      ) : null}
      {subTitle ? (<InputSubTitle color={colors.webGray}>{subTitle}</InputSubTitle>) : null}
      <StyledInput
        type={type}
        height={height}
        color={colors.webGray}
        lightTextColor={colors.webMediumGray}
        backgroundColor={colors.webLightGray}
        borderColor={colors.webDefaultInputBorderColor}
        padding={layout.padding}
        maxLength={maxLength}
        ref={textInput}
        onChange={(event) => onChange(event.target.value)}
        onKeyDown={onNext ? handleKeyDown : null}
        fontSize={fontSize || layout.fontSize}
        readOnly={readOnly}
        {...rest}
      />
    </>
  );
};

const RefInput = forwardRef(
  (
    {
      title,
      type,
      active,
      noDelay,
      onChange,
      onNext,
      height,
      maxLength,
      fontSize,
      notBoldInputTitle,
      required,
      readOnly,
      ...rest
    },
    ref
  ) => {
    const themeContext = useContext(ThemeContext);

    const colors = themeContext.colors;
    const layout = themeContext.layout;

    const textInput = useRef(null);

    useEffect(() => {
      if (active) {
        const timer = setTimeout(
          () => {
            textInput.current.focus();
          },
          noDelay ? 0 : 900
        );
        return () => {
          clearTimeout(timer);
        };
      }
      return () => {};
    }, [active, noDelay]);

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onNext();
      }
    };

    return (
      <>
        {title ? (
          <InputTitle
            color={colors.webDarkBlue}
            notBoldInputTitle={notBoldInputTitle}
            fontSize={fontSize || layout.fontSize}
          >
            {title}
            {required ? <Required /> : ''}
          </InputTitle>
        ) : null}
        <StyledInput
          type={type}
          height={height}
          color={colors.webGray}
          lightTextColor={colors.webMediumGray}
          backgroundColor={colors.webLightGray}
          borderColor={colors.webDefaultInputBorderColor}
          padding={layout.padding}
          maxLength={maxLength}
          ref={ref ? ref : textInput}
          onChange={onChange}
          onKeyDown={onNext ? handleKeyDown : null}
          fontSize={fontSize || layout.fontSize}
          readOnly={readOnly}
          {...rest}
        />
      </>
    );
  }
);

const PhoneNumberInput = ({
  title,
  active,
  noDelay,
  onChange,
  onNext,
  height,
  fontSize,
  notBoldInputTitle,
  required,
  value,
  onFocus,
  ...rest
}) => {
  const [error, setError] = useState(false);
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const textInput = useRef(null);

  useEffect(() => {
    if (active) {
      const timer = setTimeout(
        () => {
          textInput.current.focus();
        },
        noDelay ? 0 : 900
      );
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [active, noDelay]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onNext();
    }
  };

  return (
    <>
      {title ? (
        <InputTitle
          color={colors.webDarkBlue}
          notBoldInputTitle={notBoldInputTitle}
          fontSize={fontSize || layout.fontSize}
        >
          {title}
          {required ? <Required /> : ''}
        </InputTitle>
      ) : null}
      <StyledInput
        type='text'
        value={value}
        height={height}
        color={colors.webGray}
        lightTextColor={colors.webMediumGray}
        backgroundColor={colors.webLightGray}
        borderColor={error ? colors.error : colors.webDefaultInputBorderColor}
        transition={layout.transition}
        padding={layout.padding}
        maxLength='12'
        ref={textInput}
        onChange={(event) =>
          onChange(event?.target?.value.replace(/[^\d]/, ''))
        }
        onFocus={(event) => {
          setError(false);
          onFocus(event);
        }}
        onBlur={() => {
          if (!isPhoneNumberValid(value)) {
            setError(true);
          }
        }}
        onKeyDown={onNext ? handleKeyDown : null}
        fontSize={fontSize || layout.fontSize}
        {...rest}
      />
      {error ? <Error message={i18n('has_to_be_a_phone_number')} /> : null}
    </>
  );
};

const MyTulkaInputBlock = ({ icon, iconLib, iconColor, ...rest }) => {
  if (icon) {
    return (
      <StyledMyTulkaInputWrapper backgroundColor={rest.backgroundColor}>
        <StyledMyTulkaInputIcon
          icon={icon}
          iconLib={iconLib}
          iconSize={18}
          iconColor={iconColor}
        />
        <StyledMyTulkaInputWithIcon {...rest} />
      </StyledMyTulkaInputWrapper>
    );
  }
  return <StyledMyTulkaInput {...rest} />;
};

const MyTulkaInput = ({
  icon,
  iconLib,
  active,
  error,
  title,
  titleFontSize,
  onChange,
  fontSize,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  const textInput = useRef(null);

  useEffect(() => {
    if (active) {
      textInput.current.focus();
    }
  }, [active]);

  return (
    <>
      {title ? (
        <InputTitle
          color={colors.webDarkBlue}
          fontSize={titleFontSize || layout.fontSize}
        >
          {title}
        </InputTitle>
      ) : null}
      <StyledInputContainer>
        <MyTulkaInputBlock
          ref={textInput}
          onChange={(event) => onChange(event.target.value)}
          fontSize={fontSize || layout.fontSize}
          color={error ? colors.webMyTulkaErrorText : colors.webDarkBlue}
          lightTextColor={colors.webMediumGray}
          backgroundColor={colors.webMyTulkaGrayColor}
          padding={layout.padding}
          icon={icon}
          iconLib={iconLib}
          iconColor={error ? colors.webMyTulkaErrorText : colors.webDarkBlue}
          {...rest}
        />
        {error ? (
          <ErrorBlock
            color={colors.webMyTulkaErrorText}
            fontSize={titleFontSize || layout.fontSize}
            backgroundColor={colors.webMyTulkaErrorBackground}
          >
            {error}
          </ErrorBlock>
        ) : null}
      </StyledInputContainer>
    </>
  );
};

Input.propTypes = {
  active: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

Input.defaultProps = {
  active: false,
  type: 'text',
  placeholder: '',
  value: '',
};

const WizardInput = styled.input`
  width: 100%;
  padding: 10px 30px 10px 10px;
  color: #616060;
  border-radius: 16px;
  border: 1px solid rgb(255, 81, 31);
  background-color: #fff;
  box-sizing: border-box;
  &::placeholder {
    color: #dddddd;
  }
  ${({rtl}) => rtl && 'text-align: right;'}
`;

const StyledWizardIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  ${({rtl}) => rtl && 'right: 0; left: 15px;'}
`;

const StyledWizardLabel = styled(StyledLuonaInputLabel)`
  margin-top: 12px;
  font-size: 14px;
  text-align: left;
  padding-left: 10px;
  color: inherit;
  ${({rtl}) => rtl && 'text-align: right;'}
`;

const WizardLabel = ({label, ...rest}) => {
  const themeContext = useContext(ThemeContext);
  const colors = themeContext.colors;
  return (
    <StyledWizardLabel {...rest} color={colors.luonaMainBrightColor}>
      {label}
    </StyledWizardLabel>
  );
};

const InputWizardWithIconContainer = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
`;

const InputWizardWithIcon = ({ placeholder, value, type, onChange, touched, error, ...props }) => {
  const themeContext = useContext(ThemeContext);
  const colors = themeContext.colors;

  return (
    <InputWizardWithIconContainer {...props}>
      <WizardInput
        {...props}
        type={type}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder} />
      { (touched || error ) && <StyledWizardIcon
          aria-hidden="true"
          icon={error ? 'close' : 'check' }
          iconLib='material'
          iconSize={18}
          iconColor={error ? colors.errorColor : colors.webSuccessColor }
          rtl={props.rtl}
        /> }
    </InputWizardWithIconContainer>
  );
};

const StepInput = styled(Input)`
  background-color: #F5F8FB;
  min-width: 250px;
  color: rgb(21, 21, 41);
  &::placeholder {
    font-size: 14px;
  }
`;

const CheckboxContainer = styled.label`
  text-transform: capitalize;
  line-height: 1.7;
  display: flex;
  align-items: center;
  position: static;
  margin: 0;
  height:  ${({ size }) => size ? `${size}px` : 'auto'};
  width:  ${({ size }) => size ? `${size}px` : 'auto'};
  cursor: pointer;
`;

const StyledCheckboxInput = styled.input`
  height:  ${({ size }) => size ? `${size}px` : 'auto'};
  width:  ${({ size }) => size ? `${size}px` : 'auto'};
  cursor: pointer;
`;

const CheckboxButton = ({handleChange, checked, children, size}) => {
  return (
    <CheckboxContainer>
      <StyledCheckboxInput
        type='checkbox'
        checked={checked}
        onChange={handleChange}
        size={size}
      />
      {children}
    </CheckboxContainer>
  );
};

export {
  Input,
  RefInput,
  MyTulkaInput,
  RadioInput,
  Required,
  InputTitle,
  PhoneNumberInput,
  LuonaInput,
  LuonaAgreementBlock,
  LuonaSelect,
  LuonaOption,
  LuonaInputLabel,
  LuonaForm,
  LuonaFormTitle,
  WizardLabel,
  InputWizardWithIcon,
  StepInput,
  CheckboxButton
};
