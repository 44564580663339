import React from 'react';
import i18n from '../i18n';
import Header from '../components/header';
import { showError, showInfo } from '../utils/msgbox';
import PropTypes from 'prop-types';
import amplitude from 'amplitude-js';
import moment from 'moment';

import AppContext from '../contexts/AppContext';

import {
  getFeedbacks,
} from '../services/feedbackService';

class FeedbackList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      feedback: []
    };
  }

  componentDidMount() {
    amplitude.getInstance().logEvent('Feedback list');
    this.refresh();
  }

  refresh = async () => {
    this.setState({refreshing: true});

    try {
      const response = await getFeedbacks();

      this.setState({
        feedback: response.filter(item => item.starsUser > 0),
        refreshing: false
      });
    } catch (e) {
      this.setState({refreshing: false});

      let responseStatus = '';

      if (e.response) {
        responseStatus = e.response.status;
      }

      console.log(e);
      console.log('Get feedbacks failed: ' + responseStatus);

      amplitude.getInstance().logEvent('Get feedbacks failed', {
        'HTTP status code': String(responseStatus)
      });

      showError(i18n('failedToFetchFeedbacks'));
    }
  };

  onName = (name, date) => {
    amplitude.getInstance().logEvent('Feedback list usergroup tap');
    showInfo(moment(date).format('L LT') + ': ' + name);
  };

  renderItem = item => {
    const starRow = (
      <ul className="stars starRow">
        {[1, 2, 3, 4, 5].map(i => (<li className={'star ' + (i <= item.starsUser ? 'selected' : '') + ' compactStar'} key={'star_' + Math.random() + '#' + i}></li>))}
      </ul>
    );

    return (
      <tr className="starRow" key={'rating_' + Math.random() + item.date}>
        <td className="dateCol">
          <div>{moment(item.date).format('L')}</div>
          <div>{moment(item.date).format('LT')}</div>
        </td>
        <td className="starCol">
          {starRow}
        </td>
        <td className="nameCol">
          <a href="#!" title={item.name} onClick={() => this.onName(item.name, item.date)}>
            {item.name}
          </a>
        </td>
      </tr>
    );
  };

  keyExtractor = item => 'key' + item.date;

  render() {
    const rows = this.state.feedback.map(this.renderItem);

    return (
      <span>
        <Header user={this.props.appContext.user} noButtons={true} onBack={this.props.functionsContext.onBack} />
        <div className="dialog ratings">
          <table className="ratingsTable">
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </span>
    );
  }
}

const ContextWrapper = ({ ...props }) => (
  <AppContext.Consumer>
    {appContext => (
      <FeedbackList appContext={appContext} {...props} />
    )}
  </AppContext.Consumer>
);

export default ContextWrapper;

FeedbackList.propTypes = {
  appContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};
