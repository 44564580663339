import moment from 'moment-timezone';
import { URL, PLATFORM, VERSION, INTEGRATION } from '../API';
import { manifest } from '../Manifest';
import { langCode, getLang } from '../i18n';

const Headers = {
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
};

class RequestService {
  constructor() {
    // this.user = null;
    //
    // const userJson = localStorage.getItem('user');
    // if (userJson !== null) {
    //   try {
    //     this.user = JSON.parse(userJson);
    //   } catch (e) {
    //     console.error('Failed to parse user.', e);
    //   }
    // }
  }

  // TODO: need to update, use localstorage in any request not so good idea
  getToken() {
    const userJson = localStorage.getItem('user');
    if (userJson !== null) {
      try {
        const user = JSON.parse(userJson);
        return user?.token || user?.recaptchaToken;
      } catch (e) {
        console.error('Failed to parse user.', e);
      }
    }
    return '';
  }

  getManufacturer(browser) {
    switch (browser.name) {
      case 'Chrome':
        return 'Google';
      case 'Chromium':
        return 'Google';
      case 'Seamonkey':
        return 'Mozilla Foundation';
      case 'Firefox':
        return 'Firefox';
      case 'Opera':
        return 'Opera Software';
      case 'Safari':
        return 'Apple';
      case 'Internet Explorer':
        return 'Microsoft';
      default:
        return '';
    }
  }

  getBrowser() {
    let match =
      navigator.userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

    if (/trident/i.test(match[1])) {
      const version = /\brv[ :]+(\d+)/g.exec(navigator.userAgent) || [];
      return {
        name: 'Internet Explorer',
        version: version[1] || '',
      };
    } else if (match[1] === 'Chrome') {
      const version = navigator.userAgent.match(/\bOPR|Edge\/(\d+)/);
      if (version !== null) {
        return {
          name: 'Opera',
          version: version[1],
        };
      }
    }

    match = match[2]
      ? [match[1], match[2]]
      : [navigator.appName, navigator.appVersion, '-?'];

    const version = navigator.userAgent.match(/version\/(\d+)/i);
    if (version !== null) {
      match.splice(1, 1, version[1]);
    }

    return {
      name: match[0],
      version: match[1],
    };
  }

  getCountry() {
    if (langCode) {
      return langCode.toUpperCase();
    }
    return 'FI';
  }

  getDefaultParamsJson() {
    const browser = this.getBrowser();
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;

    let connectionType = 'Unknown';

    switch (((connection ? connection.type : '') || '').toLowerCase()) {
      case 'wifi':
        connectionType = 'WiFi';
        break;
      case 'ethernet':
        connectionType = 'WiFi';
        break;
      case 'wimax':
        connectionType = 'WiFi';
        break;
      default:
        connectionType = 'Unknown';
        break;
    }

    if (connectionType === 'Unknown') {
      switch (
        ((connection ? connection.effectiveType : '') || '').toLowerCase()
      ) {
        case 'slow-2g':
        case '2g':
          connectionType = '2G';
          break;
        case '3g':
          connectionType = '3G';
          break;
        case '4g':
          connectionType = '4G';
          break;
        default:
          connectionType = 'Unknown';
          break;
      }
    }

    return {
      platform: 'web',
      appVersion: manifest.appVersion,
      osVersion: browser.version,
      deviceCode: navigator.userAgent,
      manufacturer: this.getManufacturer(browser),
      brand: browser.name,
      locale: getLang(),
      country: this.getCountry(),
      timezone: moment.tz.guess(),
      timeZoneOffset: -new Date().getTimezoneOffset(),
      connectionType: connectionType,
    };
  }

  getDefaultParams() {
    const json = this.getDefaultParamsJson();

    return (
      'platform=' +
      json.platform +
      '&app_version=' +
      encodeURIComponent(json.appVersion) +
      '&os_version=' +
      encodeURIComponent(json.osVersion) +
      '&device_code=' +
      encodeURIComponent(json.deviceCode) +
      '&manufacturer=' +
      encodeURIComponent(json.manufacturer) +
      '&brand=' +
      encodeURIComponent(json.brand) +
      '&locale=' +
      encodeURIComponent(json.locale) +
      '&country=' + 
      encodeURIComponent(json.country) +
      '&time_zone_offset=' +
      encodeURIComponent(json.timeZoneOffset) +
      '&connection_type=' +
      encodeURIComponent(json.connectionType)
    );
  }

  getPutParams(params, body) {
    if (!body && !params) {
      return `?${this.getDefaultParams()}`;
    }
    if (params) {
      return `?${params}`;
    }
    return '';
  }

  getUrl(apiType) {
    if (apiType === 'platform') {
      return PLATFORM;
    }
    if (apiType === 'platform-v2') {
      return PLATFORM + 'api/v1';
    }
    if (apiType ==='integration') {
      return INTEGRATION;
    }
    return URL + VERSION;
  }

  async get(url, params, config = {}) {
    const token = this.getToken();
    const res = await fetch(`${this.getUrl(config.apiType)}${url}?${config.withoutDefaultParams ? '' : this.getDefaultParams()}${params ? '&' + params : ''}`, {
      method: 'GET',
      headers: Object.assign(Headers, {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        ...(token ? {
          'x-token': token
        } : {}),
      })
    });

    if (res.status === 401 || res.status === 403) {
      throw new Error('Session expired');
    }

    if (res.status === 410) {
      throw new Error('Too late');
    }

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return await res.json();
  }

  async post(url, params, body, config = {}) {
    const token = this.getToken();
    const res = await fetch(
      `${this.getUrl(config.apiType)}${url}?${params ? params + '&' : ''}${
        config?.defaultParamsToBody ? '' : this.getDefaultParams()
      }`,
      {
        method: 'POST',
        headers: Object.assign(Headers, {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...(token
            ? {
                'x-token': token,
              }
            : {}),
        }),
        ...(body || config?.defaultParamsToBody
          ? {
              body: `${body ? body + '&' : ''}${
                config?.defaultParamsToBody ? this.getDefaultParams() : ''
              }`,
            }
          : {}),
      }
    );

    if (res.status === 401 || res.status === 403) {
      throw new Error('Session expired');
    }

    if (res.status === 402) {
      throw new Error('No minutes left');
    }

    if (res.status === 410) {
      throw new Error('Too late');
    }

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    try {
      return await res.json();
    } catch (e) {
      console.log('e', e);
      return true;
    }
  }

  async putWithJson(url, params, body, config = {}) {
    const token = this.getToken();
    const res = await fetch(`${this.getUrl(config.apiType)}${url}` + this.getPutParams(params, body), {
      method: 'PUT',
      mode: 'cors',
      headers: Object.assign(Headers, {
        'Content-Type': 'application/json',
        ...(token ? {
          'x-token': token
        } : {}),
      }),
      body: JSON.stringify(body)
    });
    if (res.status === 401 || res.status === 403) {
      throw new Error('Session expired');
    }

    if (res.status === 410) {
      throw new Error('Too late');
    }

    if (!res.ok) {
      const result = await res.text();
      throw new Error(result || res.statusText);
    }

    try {
      return await res.json();
    } catch (e) {
      console.log('e', e);
      return true;
    }
  }

  async postWithJson(url, params, body, config = {}) {
    const token = this.getToken();
    const res = await fetch(`${this.getUrl(config.apiType)}${url}?${this.getDefaultParams()}`, {
      method: 'POST',
      mode: 'cors',
      headers: Object.assign(Headers, {
        'Content-Type': 'application/json',
        ...(token
          ? {
              'x-token': token,
            }
          : {}),
      }),
      body: JSON.stringify(body),
    });
    if (res.status === 401 || res.status === 403) {
      throw new Error('Session expired');
    }
    if (res.status === 410) {
      throw new Error('Too late');
    }
    if (!res.ok) {
      const result = await res.text();
      try {
        const text = JSON.parse(result);
        throw new Error(text?.error || result?.statusText || result);
      } catch (err) {
        throw new Error(result?.statusText || result);
      }
    }
    try {
      return await res.json();
    } catch (e) {
      console.log('e', e);
      return true;
    }
  }

  async put(url, params, body, config = {}) {
    const token = this.getToken();
    const res = await fetch(
      `${config.apiType === 'platform-v2' ? PLATFORM + 'api/v1' : URL + VERSION}${url}` +
        this.getPutParams(params, body),
      {
        method: 'PUT',
        headers: Object.assign(Headers, {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...(token
            ? {
                'x-token': token,
              }
            : {}),
        }),
        ...(body || params
          ? {
              body: `${body ? body + '&' : ''}${this.getDefaultParams()}`,
            }
          : {}),
      }
    );

    if (res.status === 401 || res.status === 403) {
      throw new Error('Session expired');
    }

    if (res.status === 409) {
      throw new Error('username is not unique');
    }

    if (res.status === 410) {
      throw new Error('Too late');
    }

    if (!res.ok) {
      const result = await res.text();
      throw new Error(result || res.statusText);
    }

    return await res.ok;
  }
}

export default new RequestService();
