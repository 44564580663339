import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from 'react-sidebar';

import ThemeContext from '../contexts/ThemeContext';

import LeftSidebar from '../components/LeftSidebar';

const mql = window.matchMedia('(min-width: 1080px)');

const MainContent = styled.div`
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const withLeftSideBar = (Component) => {

  const WrapperComponent = (props) => {
    const themeContext = useContext(ThemeContext);
    const colors = themeContext.colors;

    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [sidebarDocked, setSidebarDocked] = React.useState(mql.matches);

    function mediaQueryChanged() {
      setSidebarDocked(mql.matches);
      setSidebarOpen(false);
    }

    useEffect(() => {
      mql.addListener(mediaQueryChanged);
      return () => {
        mql.removeListener(mediaQueryChanged);
      };
    }, []);

    return (
      <Sidebar
        sidebar={<LeftSidebar/>}
        docked={sidebarDocked}
        open={sidebarOpen}
        onSetOpen={setSidebarOpen}
        defaultSidebarWidth={250}
        styles={{
          sidebar: {
            zIndex: 130,
            width: '250px',
            overflow: 'hidden auto',
            background: colors.tulkaMainColor,
          },
          overlay: {
            zIndex: 120,
          },
          content: {
            height: '100%',
            overflow: 'hidden'
          }
        }}
      >
        <MainContent
          backgroundColor={colors.webBackground}
        >
          <Component
            sidebarDocked={sidebarDocked}
            toggleSideBar={(status) => {
              if (status) {
                setSidebarOpen(status);
              }
              setSidebarOpen(!sidebarOpen);
            }}
            {...props}
          />
        </MainContent>
      </Sidebar>
    );
  };

  return WrapperComponent;
};

export default withLeftSideBar;
