import requestService from './requestService';
import { isInterpretationPrebookNeeded } from '../utils/luonaSpecificData';

const endpoints = {
  createChatRoom: 'chatroom/multistart',
  joinToChatRoom: '/chatroom/join',
  getCredentials: '/chatroom/getCredentials'
};

const getBody = (genericLanguageId, targetLanguageId, languageId, skill, prebooking) => {
  const defaultBody = {
    callType: 'data',
    ...requestService.getDefaultParamsJson()
  };
  if (prebooking) {
    const chatRooms = [
      {
        prebookingId: prebooking.id,
        languageId: prebooking.interpretedLanguage.id,
        targetLanguageId: prebooking.targetLanguage.id,
      }
    ];
    return {...defaultBody, chatRooms };
  }
  const chatRooms = [{
    languageId: genericLanguageId,
    targetLanguageId: targetLanguageId,
    skillIds: [skill],
    prebookingId: null,
    favoriteInterpreterId: null,
    invoiceUserGroupId: null,
    invoiceRef: ''
  }];

  if (isInterpretationPrebookNeeded(languageId)) {
    chatRooms.push({
      languageId: languageId,
      targetLanguageId: targetLanguageId,
      skillIds: [],
      prebookingId: null,
      favoriteInterpreterId: null,
      invoiceUserGroupId: null,
      invoiceRef: ''
    });
  }
  return {...defaultBody, chatRooms };
};
export const getRoomById = async(selectedLangs, prebooking) => {
  const { genericLanguageId, targetLanguageId, languageId, skill } = selectedLangs;
  const body = getBody(genericLanguageId, targetLanguageId, languageId, skill, prebooking);
  try {
    const res = await requestService.postWithJson(`${endpoints.createChatRoom}`, {}, body, { apiType: 'platform'});
    console.log('REST create: ', JSON.stringify(res, null, 4));
    return res;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const joinMeetingRoom = async() => {
  try {
    const res = await requestService.get(`${endpoints.joinToChatRoom}`, null, {
    apiType: 'platform-v2',
    });
    console.log('REST join: ', JSON.stringify(res, null, 4));
    return res;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const getCredentialsByChatRoomId = async(chatRoomId) => {
  try {
    const res = await requestService.get(`${endpoints.getCredentials}`, `chatRoomId=${chatRoomId}`, {
    apiType: 'platform-v2',
    });
    console.log('REST join: ', JSON.stringify(res, null, 4));
    return res;
  } catch (e) {
    console.error(e);
    throw e;
  }
};