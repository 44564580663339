import React, { useContext, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

import LogoImage from '../images/logo.png';
import ParadigmaLogoImage from '../images/paradigma_logo.png';
import { isLuonaHost } from '../utils/luonaSpecificData';
import i18n from '../i18n';
import { manifest } from '../Manifest';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import LeftSidebarMenuItem from '../components/LeftSidebarMenuItem';
import UserProfile from '../components/UserProfile';
import extranetPagesConfigurator from '../utils/extranetPagesConfigurator';

import { useLocaleStorage } from '../utils/localStorage';

import {
  getEulaUrl,
} from '../services/eulaService';
import AccountDataConfirmation from './AccountDataConfirmation';

const Sidebar = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #ffffff;
`;

const Logo = styled.img`
  width: ${({fullSize}) => fullSize ? '100%' : '70px'};
`;

const LogoContainer = styled.div`
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
`;

const BottomButtons = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
`;

const StyledFullAvailablrHeight = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

const StyledVersionLabel = styled.span`
  padding: 0 32px;
  font-size: 10px;
`;

const getMenuItemsDependOnRole = (user, invoiceCount) => {
  const additionalPages = [];
  if (user?.role === 'admin') {
    additionalPages.push({
      to: '/interpreters',
      text: i18n('calendars'),
      icon: 'event',
      font: 'material'
    });
  }
  if (user?.type === 'Interpreter') {
    return [{
      to: '/',
      text: i18n('my_bookings'),
      icon: 'event',
      font: 'material'
    }, {
      to: '/reports',
      text: i18n('reports'),
      icon: 'assignment',
      font: 'material'
    },
    ...additionalPages,
    {
      to: '/settings',
      text: i18n('settings'),
      icon: 'settings',
      font: 'material'
    }];
  }
  const pages = extranetPagesConfigurator(user);
  if (pages && pages.length > 0) {
    return [{
      to: '/',
      text: i18n('reports'),
      icon: 'assignment',
      font: 'material'
    }, {
      to: '/booking',
      text: i18n('my_bookings'),
      icon: 'event',
      font: 'material'
    }, {
      to: '/billing',
      text: i18n('billing'),
      icon: 'grading',
      font: 'material',
      count: invoiceCount
    },
    ...additionalPages,
    {
      to: '/settings',
      text: i18n('settings'),
      icon: 'settings',
      font: 'material'
    }];
  }
  return [{
    to: '/',
    text: i18n('my_bookings'),
    icon: 'event',
    font: 'material'
  }, {
    to: '/billing',
    text: i18n('billing'),
    icon: 'grading',
    font: 'material',
    count: invoiceCount
  }, {
    to: '/reports',
    text: i18n('reports'),
    icon: 'assignment',
    font: 'material'
  }, {
    to: '/settings',
    text: i18n('settings'),
    icon: 'settings',
    font: 'material'
  }];
};

const LeftSidebar = () => {
  const appContext = useContext(AppContext);
  const { colors, layout } = useContext(ThemeContext);
  const { logout } = useContext(FunctionsContext);
  const isLuona = () => {
    return !!localStorage.getItem('luonaId');
  };

  const [user, setUser] = useState(appContext.user);
  const [currentPath, setCurrentPath] = useState(appContext.history.location.pathname);
  const [appVersion] = useState(manifest.appVersion);
  
  const [timeToShowConfirmation, setTimeToShowConfirmation] = useLocaleStorage('timeToShowConfirmation', null);
  const timeToNextShowConfirmation = 24 * 60 * 60 * 1000;
  const isTimeToShowConfirmation = useMemo(() => {
    let timeDiff = timeToShowConfirmation ? Date.now() - timeToShowConfirmation : 0;
    return timeToShowConfirmation === null || timeDiff >= timeToNextShowConfirmation;
  }, [timeToShowConfirmation, timeToNextShowConfirmation]); 

  const [accountInformationConfirmation, setAccountInformationConfirmationIsOpen] = React.useState(
    !user?.profileVerifiedAt &&
    isTimeToShowConfirmation &&
    !isLuona()
  );
  // eslint-disable-next-line no-unused-vars
  const [currentUserGroup, setCurrentUserGroup] = React.useState(user.userGroup ? {
    id: user.userGroup.id,
    name: user.userGroup.name
  } : {});

  const [menuItems, setMenuItems] = useState(getMenuItemsDependOnRole(appContext.user, appContext.invoiceCount));

  useEffect(() => {
    let updated = false;
    if (!updated) {
      setMenuItems(getMenuItemsDependOnRole(appContext.user, appContext.invoiceCount));
    }
    return () => {
      updated = true;
    };
  }, [appContext.user, appContext.invoiceCount]);

  useEffect(() => {
    let updated = false;
    if (!updated) {
      setCurrentPath(appContext.history.location.pathname);
    }
    return () => {
      updated = true;
    };
  }, [appContext.history.location.pathname]);

  useEffect(() => {
    let updated = false;
    if (!updated) {
      setUser(appContext.user);
      setCurrentUserGroup(appContext.user.userGroup ? {
        id: appContext.user.userGroup.id,
        name: appContext.user.userGroup.name
      } : {});
    }
    return () => {
      updated = true;
    };
  }, [appContext.user]);

  const isParadigma = isLuonaHost();
  const logo = isParadigma ? ParadigmaLogoImage : LogoImage;
  const altText = isParadigma ? 'Paradigmasoft' : 'Tulka';

  return useMemo(() => {
    return (
      <Sidebar colors={colors}>
        <Modal
          isOpen={accountInformationConfirmation}
          style={layout.modalConfirmationStyles}
          onRequestClose={() => { 
            setAccountInformationConfirmationIsOpen(false);
            setTimeToShowConfirmation(Date.now());
          }}
        >
          <AccountDataConfirmation
            closeModal={() => {
              setAccountInformationConfirmationIsOpen(false);
              setTimeToShowConfirmation(null);
              if (currentPath === '/settings') {
                appContext.history.go(0);
              }
            }}
          />
        </Modal>    
        <LogoContainer>
          <Logo src={logo} alt={altText} fullSize={isParadigma}/>
        </LogoContainer>
        {user ? (
          <UserProfile/>
        ) : null}
        <StyledFullAvailablrHeight>
          {menuItems.map((props) => (
            <LeftSidebarMenuItem key={props.to} currentPath={currentPath} dataTest={props.text + 'Nav'} {...props} />
          ))}
        </StyledFullAvailablrHeight>
        <BottomButtons>
          <LeftSidebarMenuItem onClick={() => {
            const termsURL = getEulaUrl(user);
            const win = window.open(termsURL, '_blank');
            win.focus();
          }}
          text={i18n('termsOfUse')}
          icon="description"
          font="material"
          dataTest={'termsURLNav'}
          />
          <LeftSidebarMenuItem onClick={() => {
            logout();
          }}
          text={i18n('logout')}
          icon="logout"
          font="material"
          dataTest={'logoutNav'}
          />
        </BottomButtons>
        <StyledVersionLabel>{appVersion}</StyledVersionLabel>
      </Sidebar>
    );
  }, [
    accountInformationConfirmation,
    appContext.history,
    appVersion,
    colors,
    layout,
    currentPath,
    setTimeToShowConfirmation,
    logout,
    menuItems,
    user,
    altText,
    isParadigma, 
    logo
  ]);
};

export default LeftSidebar;
