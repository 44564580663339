import OT from '@opentok/client';
import i18n from './i18n';

const dimensions = {
  insertMode: 'append',
  width: '100%',
  height: '100%',
  audioBitrate: 64000,
  frameRate: 30,
  showControls: false,
  resolution: '1280x720'
};

class OpenTok {
  constructor() {
    this.session = null;
    this.publisher = null;
    this.subscriber = null;
    this.connection = null;
    this.callbacks = null;
    this.restartApp = null;

    this.initializingPromise = null;

    OT.on('exception', event => {
      console.log('ERROR: ', event);
    });
  }

  async disconnect() {
    this.callbacks = null;

    if (this.initializingPromise !== null) {
      await this.initializingPromise;
    }

    this.clearAll();
  }

  setCallbacks(callbacks) {
    this.callbacks = callbacks;
  }

  clearAll() {
    if (Boolean(this.session) && Boolean(this.publisher) && Boolean(this.publisher.session)) {
      try {
        this.session.unpublish(this.publisher);
      } catch (e) {
        console.log(e);
      }
    }

    if (this.publisher) {
      try {
        this.publisher.destroy();
      } catch (e) {
        console.log(e);
      }
      this.publisher = null;
    }

    if (this.session) {
      try {
        this.session.disconnect();
      } catch (e) {
        console.log(e);
      }

      this.session = null;
    }

    this.connection = null;
    this.subscriber = null;
  }

  getStats() {
    return new Promise((resolve, reject) => {
      if (!this.subscriber) {
        reject(new Error('No subscriber'));
        return;
      }

      this.subscriber.getStats((error, stats) => {
        if (error) {
          reject(error);
        } else {
          resolve(stats);
        }
      });
    });
  }

  reportAudioPacketLoss() {
    if (!this.connection) {
      console.error('No connection where to send the signal.');
      return;
    }

    this.session.signal({
      type: 'reportAudioPacketLoss',
      to: this.connection,
      data: 'true'
    }, err => {
      if (err) {
        console.warn(err);
      } else {
        console.info('Signal sent: reportAudioPacketLoss');
      }
    });
  }

  toggleCamera(enable) {
    if (this.publisher) {
      this.publisher.publishVideo(enable);
    }
  }

  toggleAudio(enable) {
    if (this.publisher) {
      this.publisher.publishAudio(enable);
    }
  }

  sendSignal(data) {
    if (!this.connection) {
      console.error('No connection where to send the signal.');
      return;
    }

    this.session.signal({
      to: this.connection,
      type: 'changeOrientation',
      data
    }, err => {
      if (err) {
        console.warn(err);
      } else {
        console.info('Signal sent with data', data);
      }
    });
  }

  async subscribeToStream(toStream) {
    if (this.session === null) {
      return;
    }

    this.subscriber = this.session.subscribe(toStream, 'subscriber', dimensions, error => {
      if (error) {
        console.error('Failed to subscribe to a session: ', error);

        this.clearAll();

        if (this.callbacks) {
          this.callbacks.failedToConnect();
        }
        return;
      }

      let subscriberName = null;

      try {
        const parsedData = JSON.parse(toStream.connection.data);
        subscriberName = parsedData.username;
      } catch (err) {
        subscriberName = toStream.connection.data;
      }

      console.log('Subscribed to a session: ' + subscriberName);

      if (this.callbacks) {
        this.callbacks.connected(subscriberName);
      }
    });
  }

  async enumDevices() {
    return await new Promise((resolve, reject) => {
      OT.getDevices((error, devices) => {
        if (error) {
          reject(error);
        }

        const res = {
          audioDevices: devices.filter(device => device.kind === 'audioInput'),
          videoDevices: devices.filter(device => device.kind === 'videoInput')
        };

        if (res.audioDevices.length === 0) {
          res.audioDevices = [{deviceId: '', label: i18n('default')}];
        }

        if (res.videoDevices.length === 0) {
          res.videoDevices = [{deviceId: '', label: i18n('default')}];
        }

        resolve(res);
      });
    });
  }

  async continueCreatingOTSession(apiKey, token, sessionId, publishVideo) {
    console.log('initSession');

    this.clearAll();
    this.session = OT.initSession(apiKey, sessionId);

    this.session.on('streamCreated', event => {
      console.log('streamCreated: ', event);

      if (this.initializingPromise) {
        setTimeout(() => this.subscribeToStream(event.stream), 5000);
      } else {
        this.subscribeToStream(event.stream);
      }
    });

    this.session.on('streamDestroyed', event => { // Subscriber
      console.log('session.streamDestroyed (' + event.type + ': ', event);
      this.clearAll();

      if (this.callbacks) {
        this.callbacks.disconnected();
      }
    });

    this.session.on('signal:changeOrientation', event => {
      console.log('signal:changeOrientation: ', event);

      if (this.callbacks) {
        this.callbacks.changeOrientation(event.data === 'LANDSCAPE');
      }
    });

    this.session.on('signal:reportAudioPacketLoss', event => {
      console.log('signal:reportAudioPacketLoss: ', event);

      if (this.callbacks) {
        this.callbacks.audioPacketLoss();
      }
    });

    this.session.on('sessionDisconnected', event => { // Locally
      console.log('sessionDisconnected: ', event);
      this.clearAll();

      if (this.callbacks) {
        this.callbacks.disconnected(true);
      }
    });

    this.session.on('connectionDestroyed', event => { // Remote
      console.log('connectionDestroyed: ', event);
      this.clearAll();

      if (this.callbacks) {
        if (event.reason === 'networkDisconnected') {
          this.callbacks.networkTerminated();
        } else {
          this.callbacks.disconnected();
        }
      }
    });

    this.session.on('archiveStarted', event => {
      console.log('Event', event);
      // event.id
      // event.name
    });

    this.session.on('archiveStopped', event => {
      console.log('Event', event);
      // event.id
      // event.name
    });

    this.session.on('connectionCreated', event => {
      console.log('connectionCreated: ', event);

      if (this.callbacks) {
        if (!this.connection || this.connection.connectionId === event.connection.connectionId) {
          this.callbacks.connectionCreated();
        } else {
          this.callbacks.subscriberDidConnectToStream(event.connection.data);
        }
      }
    });

    this.session.on('sessionConnected', event => {
      console.log('sessionConnected: ', event);
    });

    this.session.on('sessionReconnected', event => {
      console.log('sessionReconnected: ', event, this.session);

      if (this.session === null && this.restartApp !== null) {
        OT.reportIssue((error, issueId) => {
          console.log('Restarting the app...', error, issueId);
          this.restartApp();
        });
      } else if (this.callbacks) {
        this.callbacks.reconnected();
      }
    });

    this.session.on('sessionReconnecting', event => {
      console.log('sessionReconnecting: ', event, this.session);

      if (this.callbacks) {
        this.callbacks.reconnecting();
      }
    });

    const devices = await this.enumDevices();

    let audioSource = localStorage.getItem('selectedAudioDevice');
    let videoSource = localStorage.getItem('selectedVideoDevice');

    if (!devices.audioDevices.find(device => device.deviceId === audioSource)) {
      audioSource = null;
    }

    if (!devices.videoDevices.find(device => device.deviceId === videoSource)) {
      videoSource = null;
    }

    if (videoSource === null) {
      try {
        const stream = await OT.getUserMedia({facingMode: 'user'});
        if (stream.getVideoTracks().length > 0) {
          videoSource = stream.getVideoTracks()[0];
        }
      } catch (e) {
        console.error('Failed to get video tracks.', e);
      }
    }

    this.initializingPromise = new Promise((resolve, reject) => {
      console.log('initPublisher: ', {
        audioSource,
        videoSource
      });

      this.publisher = OT.initPublisher('publisher', Object.assign(dimensions, {
        audioSource,
        videoSource,
        publishVideo
      }), error => {
        if (error) {
          console.error('Failed to init a publisher: ', error);
          this.clearAll();
          reject(error);
          this.initializingPromise = null;
          return;
        }

        console.log('Publisher initialized');

        this.connection = this.session.connect(token, e => {
          if (e) {
            console.error('Failed to connect to a session: ', e);
            reject(e);
            this.initializingPromise = null;
            return;
          }

          console.log('Connected. Publishing to a stream');

          this.session.publish(this.publisher, err => {
            if (err) {
              console.error('Failed to publish a stream: ', err);
              this.clearAll();
              reject(err);
              this.initializingPromise = null;
              return;
            }

            console.log('Stream published');
            resolve();
            this.initializingPromise = null;
          });
        });
      });

      this.publisher.on('publisher.streamDestroyed', event => {
        console.log('streamDestroyed (' + event.reason + ': ', event);

        this.clearAll();
      });
    });

    await this.initializingPromise;
  }

  async createOTSession(apiKey, token, sessionId, publishVideo) {
    if (this.initializingPromise !== null) {
      await this.initializingPromise;
    }

    await this.continueCreatingOTSession(apiKey, token, sessionId, publishVideo);
  }
}

export const opentok = new OpenTok();
