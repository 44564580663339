import styled from 'styled-components';

export const MainHeader = styled.div`
  flex: 1 1 auto;
  font-size: 26px;
  padding-left: ${({ sidebarDocked, padding }) =>
    sidebarDocked ? 0 : padding * 2}px;
  color: ${({ color }) => color};
`;

export const MainContentTopBar = styled.div`
  width: 100%;
  min-height: 100px;
  border-bottom: 1px solid ${({ borderBottomColor }) => borderBottomColor};
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
`;

export const MainContentTopBarButtons = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  & > button:not(:last-child) {
    margin-right: 20px;
  }
`;

export const ContainerWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const FullBackgroundImageContainer = styled.div`
  height: 100%;
  display: flex;
  background: ${({ blendMode }) => `linear-gradient(${blendMode},${blendMode})`},
    ${({ backgroundImage }) => `url(${backgroundImage})`} no-repeat center
      center fixed;
  background-size: cover;
`;

export const CenteredBlockContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 40px 0;
`;

export const VersionLabel = styled.div`
  color: ${({ color }) => color};
  display: block;
  text-align: center;
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: 600;
  margin-top: 16px;
`;

export const LogoImg = styled.img`
  width: 145px;
  height: auto;
  position: relative;
  margin: 8px auto 0;
  display: block;
  margin-bottom: 46px;
`;

export const DialogBlock = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 10px;
  padding: 16px 32px;
  box-sizing: border-box;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const BookingsTableContainer = styled.table`
  padding: 0 10px 8px;
  width: 100%;
  border: none;
  border-spacing: 0 8px;
  & > th,
  td {
    border: none;
  }
  font-size: ${({ fontSize }) => fontSize}px;
`;

export const BookingTableThead = styled.thead`
  &:after {
    content: '@';
    display: block;
    line-height: 6px;
    text-indent: -99999px;
  }
`;

export const BookingTableTh = styled.th`
  text-align: left;
  padding: 0 2px;
  line-height: 12px;
  max-width: 145px;
  &:first-child {
    padding-left: 8px;
  }
  &:last-child {
    padding-right: 8px;
  }
  &:hover {
    cursor: pointer;
  }
  &.invoiceRecipientRef,
  &.customerName {
    max-width: 190px;
  }
  &.invoiceRef {
    max-width: 115px;
  }
  &.customerName {
    max-width: 95px;
  }
  &.type {
    & > div {
      width: ${({ lang }) => (lang === 'fi' ? '75px' : '55px')};
      justify-content: center;
      & > div {
        align-self: center;
      }
    }
  }
`;

export const BookingTableTr = styled.tr`
  cursor: pointer;
  transition: ${({ transition }) => transition};
  &:hover {
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
`;

export const BookingTableTd = styled.td`
  height: 70px;
  background: #ffffff;
  padding: 0 8px;
  max-width: 145px;
  ${({ flexItem }) =>
    flexItem
      ? `
    & > div {
      flex: 1;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      white-space: nowrap;
    }
  `
      : ''}
  &:first-child {
    padding-left: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  &:last-child {
    padding-right: 10px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  &.invoiceRef,
  &.invoiceRecipientRef,
  &.invoiceUserGroup,
  &.customerName {
    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &.customerName {
    max-width: 95px;
  }
  &.invoiceRef {
    max-width: 115px;
  }
  &.invoiceRecipientRef {
    max-width: 190px;
  }
`;

export const TrText = styled.div`
  font-size: ${({ fontSize }) => fontSize}px;
  min-height: 38px;
  line-height: 12px;
  display: flex;
  align-items: center;
  transition: ${({ transition }) => transition};
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
    span {
      i {
        color: ${({ hoverColor }) => hoverColor} !important;
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SortIconContainer = styled.span`
  padding-left: 3px;
  white-space: nowrap;
  & i,
  & span {
    vertical-align: middle;
  }
`;

export const BookingsFilterSelect = styled.select`
  margin: 10px 0;
  padding: 6px 8px;
  font-size: ${({ fontSize }) => fontSize}px;
  height: 32px;
`;

// Self-selfRegistration
export const ContainerStepBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  min-width: 288px;
  padding: 32px;
  overflow: auto;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const ContentStepBox = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

export const StepsCount = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: #8A8A94;
`;

export const MainStepsHeader = styled.div`
  font-size: 20px;
  line-height: 150%;
  color: #151529;
  margin-bottom: 16px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
  }
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

export const StepsHeader = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: rgb(21, 21, 41);
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const StepLable = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: #151529;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  gap: 16px;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 0;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const FlexItem = styled.div`
  flex: 1;
`;

export const StyledErrorMessage = styled.div`
  color: ${({ color }) => (color)};
  font-size: 12px;
  margin-top: 4px;
`;
