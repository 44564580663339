import { useContext, useEffect, useState, useMemo } from 'react';
import ThemeContext from '../contexts/ThemeContext';
import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import { Input } from './Input';
import ActionList from './ActionList';
import ActionListItem from './ActionListItem';
import i18n from '../i18n';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { showInfo } from '../services/toastService';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(Input)`
  margin-bottom: ${({ margin }) => (margin)}px;
`;

const StyledText = styled.p`
    text-align: center;
`;

const SearchSelector = props => {
    return (
        <ActionList>
            <ActionListItem 
                key='any_favorite'
                data-test='NoPreferenceItem'
                icon={'diamond'}
                iconLib={'material'}
                title={i18n('any_favorite')}
                onClick={() => props.onPreferredInterpreterSelected({})}
                selected={props.anySelected}
            />
            <ActionListItem
                key='search_preferred_interpreter'
                data-test='NeedToSearchPreferredInterpretesItem'
                icon={'search'}
                iconLib={'material'}
                title={i18n('search_preferred_interpreter')}
                onClick={() => props.onSearchPreferredInterpreters()}
                selected={!props.isSearchAllowed}
            />
        </ActionList>
    );
};

const PreferredInterpretersList = props => {
    const { colors, layout } = useContext(ThemeContext);
    const [searchValue, setSearchValue] = useState('');
    const [preferredInterpreters, setPreferredInterpreters] = useState(props.selectablePreferredInterpreters);
    const [interpretersForShow, setInterpretersForShow] = useState([]);

    const onChange = (text) => setSearchValue(text.toLowerCase());

    const isSearchValid = searchValue?.length >= 2;
    const isShowHistoryISLimited = isSearchValid && preferredInterpreters?.length < 3;

    useEffect(() => {
        setPreferredInterpreters(props.selectablePreferredInterpreters?.filter(spi => {
            if (isSearchValid) {
                return spi.name.toLowerCase().includes(searchValue);
            }
            return spi.name.toLowerCase().startsWith(searchValue);
        }));
        if (searchValue) {
            onChange(searchValue);
        }
    }, [searchValue, props.selectablePreferredInterpreters, isSearchValid]);

    useEffect(() => {
        if (searchValue && searchValue.length >= 2) {
            const filtered = preferredInterpreters?.filter(pi => pi.name.toLowerCase().includes(searchValue.toLowerCase()));
            setInterpretersForShow(filtered);
        } else {
            setInterpretersForShow([]);
        }
    }, [searchValue, preferredInterpreters]);
    
    const handleClick = (pi) => {
        props.onPreferredInterpreterSelected(pi);
        showInfo(i18n('not_guarantee'));
    };

    const itemTitle = useMemo(() => {
        if (props.error) {
            return i18n('failed_to_fetch_preferred_interpreters');
        }
        if (!props.selectablePreferredInterpreters.length || !interpretersForShow.length) {
            return i18n('no_options');
        }
        return '';
    }, [props.error, props.selectablePreferredInterpreters.length,interpretersForShow.length]);

    return (
        <>
            <StyledInput 
                data-test='searchPreferredInterpreterWizard'
                margin={layout.padding}
                type='text'
                placeholder={i18n('search')}
                value={searchValue}
                onChange={onChange}
            />
            <ActionList>
                {preferredInterpreters.length ? interpretersForShow?.map(pi => {
                    let rightIcon = null;
                        return (
                            <ActionListItem 
                                key={pi.id}
                                data-test={pi.name + 'PreferredInterpreterWizard'}
                                id={pi.id}
                                title={pi.name}
                                subtitle={''}
                                rightIcon={rightIcon}
                                rightIconLib='entypo'
                                rightIconColor={pi?.availability === 1 ? colors.cancelColor : colors.contentBoxBorderColorLight2020}
                                onClick={() => handleClick(pi)}
                                selected={props.preferredInterpreter?.id === pi.id}
                            />
                        );
                }) : (
                    <ActionListItem 
                        key={() => uuid()}
                        title={itemTitle}
                        onClick={() => props.onPreferredInterpreterSelected({})}
                        selected={true}
                    />
                )}
            </ActionList>
            {isShowHistoryISLimited && (
                <StyledText>{i18n('history_is_limited')}</StyledText>
            )}
        </>
    );
};

const PreferredInterpreterPage = props => {
    const [isSearchAllowed, setIsSearchAllowed] = useState(true);

    return (
        <StyledContainer>
            <ChatWizardHeader title={props.title}/>
            <ContentBox overlapping={true}>
                {isSearchAllowed ? (
                    <SearchSelector 
                        anySelected={props.anySelected}
                        onPreferredInterpreterSelected={props.onPreferredInterpreterSelected} 
                        isSearchAllowed={isSearchAllowed}
                        onSearchPreferredInterpreters={() => setIsSearchAllowed(false)}
                    />
                ) : (
                    <PreferredInterpretersList {...props}/>
                )}
            </ContentBox>
        </StyledContainer>
    );
};

export default PreferredInterpreterPage;