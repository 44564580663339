import styled from 'styled-components';

export const WizardStepContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 800px;
  height: 100%;
`;

 const StyledWizardStepTitle = styled.h2`
  color: inherit;
  text-align: center;
  margin: 0;
`;

export const WizardStepTitle = ({ label, ...rest }) => {
  return <StyledWizardStepTitle {...rest}>{label}</StyledWizardStepTitle>;
};
