import React, { useContext } from 'react';
import styled from 'styled-components';

import HelpButton from './HelpButton';

import ThemeContext from '../contexts/ThemeContext';

const TextBlock = styled.div`
  flex: 1;
  padding: 40px 40px 10px 40px;
  text-align: left;
  color: ${({ color }) => (color)};
  font-size: ${({ fontSize }) => (fontSize)}px;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  flex: 0 1 auto;

  ${({ logoHead }) => logoHead ? `
    background-color: #151529;
    flex: 0 1 auto;
    justify-content: center;
    padding: 16px;
    overflow: hidden;
  ` : null}
`;

const StyledLogo = styled.img`
  width: auto;
  height: ${({ height }) => (height)}px;
`;

const LogoHeader = props => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  if (props.hidden) {
    return null;
  }

  const logo = props.title ? null : (
    <StyledLogo
      height={layout.logoHeaderHeight}
      src={colors.headerLogo2020.default}
    />
  );

  const title = props.title ? (
    <TextBlock
      fontSize={layout.fontSize}
      color={colors.webGray}
    >
      {props.title}
    </TextBlock>
  ) : null;

  return (
    <StyledLogoContainer layout={layout} logoHead={!props.title}>
      {props.helpId ? (
        <HelpButton
          helpId={props.helpId}
          color={colors.panelColor2020}
        />
      ) : null}
      {logo}
      {title}
    </StyledLogoContainer>
  );
};

export default LogoHeader;
