import React, { useContext } from 'react';
import styled from 'styled-components';

import ThemeContext from '../contexts/ThemeContext';

import HelpButton from './HelpButton';
import HeaderText from './HeaderText';
import Icon from './Icon';

import { CustomTooltip } from './Tooltip';

import i18n from '../i18n';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  padding: ${({ noPadding }) => noPadding ? '0' : '10px 40px 10px 40px'};
  color: ${({ color }) => (color)};
  flex: 0 1 auto;
  justify-content: space-between;
  align-items: center;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  flex: 0 1 auto;
  align-items: center;
`;

const StyledTitle = styled.div`
  color: ${({ color }) => (color)};
  font-size: ${({ fontSize }) => (fontSize)}px;
  cursor: pointer;
  margin-right: ${({ margin }) => (margin)}px;
  &:hover {
    i, span {
      color: ${({ hoverColor }) => (hoverColor)} !important;
    }
  }
`;

const ChatWizardHeader = ({
  helpId,
  clonePrebook,
  editPrebook,
  title,
  noPadding = false,
  alignHelpButtonBottom,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);

  const colors = themeContext.colors;
  const layout = themeContext.layout;

  return (
    <StyledContainer
      color={colors.webDarkBlue}
      noPadding={noPadding}
    >
      <HeaderText
        text={title}
        fontSize={layout.headerFontSize}
        {...rest}
      />
      {helpId ? (
        <HelpButton
          helpId={helpId}
          alignBottom={alignHelpButtonBottom}
          color={colors.contentBoxBorderColor2020}
        />
      ) : null}
      <StyledRow>
        {editPrebook ? (
          <CustomTooltip
            direction="bottom"
            text={i18n('change_reservation_description')}
          >
            <StyledTitle
              data-test='editBtn'
              onClick={() => editPrebook(true) }
              color={colors.webDarkBlue}
              fontSize={layout.smallFontSize}
              hoverColor={colors.tulkaMainColor}
              margin={10}
            >
              <StyledRow>
                <Icon
                  icon="edit"
                  iconLib="material"
                  iconColor={colors.webDarkBlue}
                  iconSize={24}
                />
                <span>{i18n('change_reservation')}</span>
              </StyledRow>
            </StyledTitle>
          </CustomTooltip>
        ) : null}
        {clonePrebook ? (
          <CustomTooltip
            direction="bottom"
            text={i18n('copy_reservation_description')}
          >
            <StyledTitle
              data-test='contentCopyBtn'
              onClick={() => clonePrebook(true) }
              color={colors.webDarkBlue}
              fontSize={layout.mediumFontSize}
              icon="content_copy"
              hoverColor={colors.tulkaMainColor}
            >
              <StyledRow>
                <Icon
                  icon="content_copy"
                  iconLib="material"
                  iconColor={colors.webDarkBlue}
                  iconSize={24}
                />
                <span>{i18n('copy_reservation')}</span>
              </StyledRow>
            </StyledTitle>
          </CustomTooltip>
        ) : null}
      </StyledRow>
    </StyledContainer>
  );
};

export default ChatWizardHeader;
